import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Transition, Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import Popup from "../../components/Popup";
import { CurrentTab, UserRightsFilter, UserTypeFilter } from "./Index";

interface Props {
  workSpaceId?: string;
  currentTab: CurrentTab;
  searchParams: (
    key: string,
    type?: string,
    filter?: string | undefined
  ) => "" | URLSearchParams;
  showViewAllModal: boolean;
  reportType:
    | "statusWiseTaskReport"
    | "statusWiseAndTaskTypeWiseReport"
    | "userWiseReport";
  reportData: any[];
  onClose: () => void;
  selectedUser: { name: string; _id: string };
  userTypeFilter?: UserTypeFilter;
  userRightsFilter?: UserRightsFilter;
  currentUser?: { _id: string; name: string };
  userType?: { _id: string; name: string };
  getUserIdFromUserName?: (userName: string) => string | undefined;
}

class ViewAllModal extends Component<Props> {
  render() {
    return (
      <Transition.Root show={this.props.showViewAllModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={this.props.onClose}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black/30" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block w-full max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl space-y-3">
                <div className="flex justify-between items-center">
                  <Dialog.Title
                    as="h3"
                    className="text-base font-medium leading-6 text-gray-900"
                  >
                    {this.props.currentTab} Task Summary -{" "}
                    {this.props.reportType === "statusWiseTaskReport" ? (
                      <>
                        Statuswise{" "}
                        <span className="text-sm font-normal capitalize">
                          (for all periods)
                        </span>
                      </>
                    ) : (
                      "Task and Period Wise"
                    )}
                  </Dialog.Title>
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={this.props.onClose}
                  >
                    <XMarkIcon className="w-6 h-6" />
                  </button>
                </div>
                <div className="bg-white shadow rounded-md sm:overflow-hidden">
                  <div className="flex flex-col max-h-[80vh]">
                    <div id="tab-scroll" className="overflow-x-auto">
                      <div className="inline-block min-w-full align-middle">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className={`px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider text-left`}
                                >
                                  {this.props.reportType === "userWiseReport"
                                    ? "User"
                                    : "Period"}
                                </th>
                                <th
                                  scope="col"
                                  className={`px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider text-center whitespace-nowrap`}
                                >
                                  Total Tasks
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {this.props.reportData.map(
                                (record: any, index: number) => (
                                  <tr
                                    key={`report-${record.statusName}-${index}`}
                                    className={
                                      index % 2 === 0
                                        ? "bg-white"
                                        : "bg-gray-50"
                                    }
                                  >
                                    {this.props.reportType ===
                                    "userWiseReport" ? (
                                      <>
                                        <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize">
                                          {record.userName}
                                        </td>
                                        <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize text-center">
                                          <Link
                                            className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                                            to={`/${
                                              this.props.workSpaceId
                                            }/tasks/list?${
                                              this.props.currentTab === "Common"
                                                ? ""
                                                : this.props.searchParams(
                                                    "type",
                                                    this.props.currentTab
                                                  )
                                            }${
                                              this.props.userType?._id ===
                                              "current"
                                                ? record.userName ===
                                                  "Unassigned"
                                                  ? `&${this.props.searchParams(
                                                      "user",
                                                      record.userName
                                                    )}`
                                                  : `&${this.props.searchParams(
                                                      "userName",
                                                      record.userName
                                                    )}`
                                                : `&${this.props.searchParams(
                                                    "otherUser",
                                                    this.props.getUserIdFromUserName?.(
                                                      record.userName
                                                    )
                                                  )}`
                                            }`}
                                          >
                                            {record.count}
                                          </Link>
                                        </td>
                                      </>
                                    ) : (
                                      <>
                                        <td className="max-w-[20ch] px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize">
                                          <Popup
                                            content={record.statusName}
                                            className="w-full max-w-fit truncate"
                                          >
                                            {record.statusName}
                                          </Popup>
                                        </td>
                                        <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize text-center">
                                          {this.props.reportType ===
                                          "statusWiseAndTaskTypeWiseReport" ? (
                                            record.count
                                          ) : (
                                            <Link
                                              className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                                              to={`/${
                                                this.props.workSpaceId
                                              }/tasks/list?${
                                                this.props.currentTab ===
                                                "Common"
                                                  ? ""
                                                  : this.props.searchParams(
                                                      "type",
                                                      this.props.currentTab
                                                    )
                                              }&${this.props.searchParams(
                                                "status",
                                                record.statusName
                                              )}${
                                                this.props.userTypeFilter ===
                                                  "otherUser" ||
                                                this.props.userTypeFilter ===
                                                  "any"
                                                  ? `&${this.props.searchParams(
                                                      "otherUser",
                                                      this.props
                                                        .userRightsFilter ===
                                                        "employee"
                                                        ? this.props.currentUser
                                                            ?._id
                                                        : this.props
                                                            .selectedUser._id
                                                    )}`
                                                  : this.props
                                                      .userRightsFilter ===
                                                      "employee" &&
                                                    this.props
                                                      .userTypeFilter ===
                                                      "currentUser"
                                                  ? `&${this.props.searchParams(
                                                      "user",
                                                      this.props.currentUser
                                                        ?._id
                                                    )}`
                                                  : `&${this.props.searchParams(
                                                      "user",
                                                      this.props.selectedUser
                                                        ._id
                                                    )}`
                                              }`}
                                            >
                                              {record.count}
                                            </Link>
                                          )}
                                        </td>
                                      </>
                                    )}
                                  </tr>
                                )
                              )}
                              <tr className="bg-white">
                                <td className="px-3 py-2 whitespace-nowrap text-gray-900 font-medium text-sm capitalize">
                                  Total
                                </td>
                                <td className="px-3 py-2 whitespace-nowrap text-gray-900 font-semibold text-sm capitalize text-center">
                                  {this.props.reportData.reduce(
                                    (total: number, record: any) =>
                                      total + record.count,
                                    0
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}

export default ViewAllModal;
