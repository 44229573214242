import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";

export type MessageModalType = "success" | "info" | "warning" | "error";
export type MessageModalData = {
  title: string;
  message: string;
  otherData?: string;
  type: MessageModalType;
};

interface MessageModalProps {
  show: boolean;
  data: MessageModalData;
  confirmText: string;
  handleConfirm: () => void;
  confirmButtonColor?: string;
  closeText?: string;
  hideModal?: () => void;
  handleClose: () => void;
  closeButtonColor?: string;
}

export default function MessageModal(props: MessageModalProps) {
  const {
    show,
    hideModal,
    data: { title, message, otherData, type },
    confirmText,
    handleConfirm,
    confirmButtonColor,
    closeText,
    handleClose,
    closeButtonColor
  } = props;

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={hideModal ? hideModal : handleClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div
                    className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10 ${
                      type === "success" || type === "info"
                        ? "bg-blue-100"
                        : type === "warning"
                        ? "bg-yellow-100"
                        : "bg-red-100"
                    }`}
                  >
                    {type === "success" ? (
                      <CheckCircleIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    ) : type === "info" ? (
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-blue-600"
                        aria-hidden="true"
                      />
                    ) : type === "warning" ? (
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-yellow-500"
                        aria-hidden="true"
                      />
                    ) : (
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left space-y-3 w-full">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-2 space-y-2">
                      <p className="text-sm">{message}</p>
                    </div>
                    <div className="sm:grid grid-flow-col gap-4">
                      {otherData && (
                        <p className="text-sm whitespace-pre-line">
                          {otherData}
                        </p>
                      )}
                      <div className="mt-5 sm:mt-2 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className={`${
                            confirmButtonColor
                              ? confirmButtonColor
                              : type === "success" || type === "info"
                              ? "bg-blue-600 hover:bg-blue-700"
                              : type === "warning"
                              ? "bg-yellow-600 hover:bg-yellow-700"
                              : "bg-red-600 hover:bg-red-700"
                          } inline-flex gap-2 w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-32 whitespace-nowrap`}
                          onClick={handleConfirm}
                        >
                          <span className="w-full"></span>
                          {confirmText}
                          <span className="w-full"></span>
                        </button>
                        {closeText ? (
                          <button
                            type="button"
                            className={`mt-3 inline-flex gap-2 w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm sm:mt-0 sm:w-32 whitespace-nowrap ${
                              closeButtonColor
                                ? `${closeButtonColor} text-white`
                                : "bg-white hover:bg-gray-50 text-gray-900 ring-1 ring-inset ring-gray-300"
                            }`}
                            onClick={handleClose}
                          >
                            <span className="w-full"></span>
                            {closeText}
                            <span className="w-full"></span>
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
