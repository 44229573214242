import React from "react";
// Import to Display skeleton while loading data
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// Pagination
import { compose } from "redux";
import { connect, ConnectedProps } from "react-redux";
// Link backend
import agent from "../../../agent";
// Dashboard import
import Dashboard from "../../../components/Dashboard";
// Redux Notify
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../../store/types";
import { withRouter } from "../../../helpers/withRouter";
import "../../style.css";
import TagManager from "react-gtm-module";
import Icon from "../../../components/Icon";
import MultiSelect from "../../../components/MultiSelect";
import { quarterTillCurrentQuarter as quarters } from "../../../helpers/timePeriod";
import Popup from "../../../components/Popup";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Return task overview"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: (payload: any) => dispatch({ type: UPDATE_COMMON, payload }),
  onNotify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class ReturnTaskOverview extends React.Component<any, PropsFromRedux> {
  state: {
    loading: boolean;
    workSpaceId: string;
    quarters: any;
    headers: any;
    returnTaskTypes: any;
    selectedReturnTaskTypes: any;
    records: any;
    totalRecords: number;
    chunk: number;
    skip: number;
    currIndex: number;
    selectedFilter: any;
  };

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      workSpaceId: (this.props as any).params.firmId,
      quarters: quarters().reverse(),
      headers: ["name"],
      returnTaskTypes: [],
      selectedReturnTaskTypes: null,
      records: [
        {
          name: "Aesiu yfuerwyruiryw tuwryutv wyeiuot;owruy wruefi wiuefuiew feuf uewfeuf hawehfuhesjfh a;shf dgdj sh",
          "jan 23 to mar 23": 3,
          "oct 22 to dec 22": 13,
          "jul 22 to sep 22": 34,
          "apr 22 to jun 22": 4
        },
        {
          name: "B",
          "jan 23 to mar 23": 3,
          "oct 22 to dec 22": 7,
          "jul 22 to sep 22": 0,
          "apr 22 to jun 22": 4
        },
        {
          name: "C",
          "jan 23 to mar 23": 3,
          "oct 22 to dec 22": 5,
          "jul 22 to sep 22": 0,
          "apr 22 to jun 22": 4
        },
        {
          name: "D",
          "jan 23 to mar 23": 3,
          "oct 22 to dec 22": 0,
          "jul 22 to sep 22": 0,
          "apr 22 to jun 22": 4
        },
        {
          name: "E",
          "jan 23 to mar 23": 3,
          "oct 22 to dec 22": 0,
          "jul 22 to sep 22": 0,
          "apr 22 to jun 22": 4
        }
      ],
      totalRecords: 4,
      chunk: 3,
      skip: 0,
      currIndex: 0,
      selectedFilter: null
    };
  }

  getTaskTypes = () => {
    agent.RecurringTask.getTaskTypes(this.state.workSpaceId, "", "")
      .then((res: any) => {
        this.setState({
          returnTaskTypes: res.tasks
        });
      })
      .catch((err: any) => {
        (this.props as any).addNotification(
          "Error",
          "Error getting task types",
          "error"
        );
      });
  };

  handleReturnTaskTypeChange = (selected: any) => {
    this.setState({
      selectedReturnTaskTypes: selected
    });
  };

  getMoreQuarters = () => {
    const quarters = this.state.quarters;
    if (quarters.length > this.state.skip) {
      this.setState({
        headers: [
          ...this.state.headers,
          ...quarters.slice(
            this.state.skip,
            this.state.currIndex * this.state.chunk + this.state.chunk
          )
        ],
        skip: this.state.currIndex * this.state.chunk + this.state.chunk,
        currIndex: this.state.currIndex + 1
      });
    } else {
      this.setState({
        headers: [...this.state.headers.splice(0, 1), ...quarters]
      });
    }
  };

  handleFilterChange = (selected: any) => {
    this.setState({
      selectedFilter: selected
    });
  };

  componentDidMount() {
    this.getTaskTypes();
    this.getMoreQuarters();
  }

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard>
        <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">
            Return Task Overview
          </h1>
        </div>
        <div className="lg:mx-8 flex items-end justify-between gap-4 mt-6">
          <MultiSelect
            // heading="Select Return Task Type"
            items={this.state.returnTaskTypes.map((returntype: any) => {
              return {
                ...returntype,
                _id: returntype._id,
                name: returntype.name
              };
            })}
            selected={{
              name: this.state.selectedReturnTaskTypes?.name
            }}
            type="return-type"
            onChange={this.handleReturnTaskTypeChange}
            placeholder="Select Return Task Type"
          />
          <div className="flex items-center gap-x-3">
            <button
              type="button"
              className="relative whitespace-nowrap inline-flex items-center sm:px-4 px-3 py-2 border border-transparent shadow-sm sm:text-sm text-xs font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
            >
              <Icon name="outline/download" className="h-4 w-4 mr-2" />
              Export
            </button>
            <button
              type="button"
              className="relative whitespace-nowrap inline-flex items-center sm:px-4 px-3 py-2 border border-transparent shadow-sm sm:text-sm text-xs font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none disabled:bg-indigo-600 disabled:cursor-not-allowed"
              onClick={this.getMoreQuarters}
              disabled={this.state.quarters.length <= this.state.skip}
            >
              Get More Quarters
            </button>
          </div>
        </div>
        <div className={"max-w-full mx-auto px-4 sm:px-6 md:px-8"}>
          {/* Task Overview Table */}
          <div className="mt-6 flex flex-col max-h-screen">
            <div id="table-scroll" className="overflow-auto">
              <div className="inline-block min-w-full py-2 align-middle">
                <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                  <table className="min-w-full border-separate border shadow-sm">
                    <thead className="bg-gray-50">
                      <tr>
                        {this.state.headers.map((header: any, index: any) => (
                          <th
                            key={`${header}-${index}`}
                            style={{ zIndex: 8 }}
                            scope="col"
                            className={`sticky top-0 border-b border-gray-300 bg-gray-50 px-4 py-3 min-w-[15rem] text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6 whitespace-nowrap ${
                              index === 0 ? "text-left" : "text-center"
                            }`}
                          >
                            <div>{header}</div>
                            <div className="mt-2 max-w-[12rem] mx-auto">
                              {index !== 0 && (
                                <MultiSelect
                                  items={(this.props as any)?.status?.map(
                                    (status: any) => {
                                      return {
                                        ...status,
                                        _id: status._id,
                                        name: status.name
                                      };
                                    }
                                  )}
                                  selected={{
                                    name: this.state.selectedFilter?.name
                                  }}
                                  type="return-type"
                                  onChange={this.handleFilterChange}
                                  placeholder="Filter by status"
                                />
                              )}
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {this.state.totalRecords === 0 ? (
                        <tr className="w-full bg-white">
                          <td
                            colSpan={this.state.headers.length}
                            className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900"
                          >
                            No record found matching your search criteria
                          </td>
                        </tr>
                      ) : !this.state.loading && this.state.records ? (
                        this.state.records?.map((record: any, index: any) => (
                          <tr
                            key={record?._id}
                            className={`text-center ${
                              index % 2 === 0 ? "bg-white" : "bg-gray-100"
                            }`}
                          >
                            {this.state.headers.map(
                              (header: any, index: any) => (
                                <td
                                  key={`${record?._id}-${header}-${index}`}
                                  className={`px-3 py-3 whitespace-wrap text-sm font-medium text-gray-900 sm:pl-6 ${
                                    index === 0
                                      ? "max-w-[15rem] text-left"
                                      : "text-center"
                                  }`}
                                >
                                  {index === 0 ? (
                                    <Popup
                                      content={
                                        record[header.toLowerCase()] ?? "-"
                                      }
                                      className="w-full max-w-fit"
                                    >
                                      <p className="truncate">
                                        {record[header.toLowerCase()] ?? "-"}
                                      </p>
                                    </Popup>
                                  ) : (
                                    record[header.toLowerCase()] ?? "-"
                                  )}
                                </td>
                              )
                            )}
                          </tr>
                        ))
                      ) : (
                        [...Array(5)].map((e, i) => (
                          <tr key={i} className="bg-white">
                            {[...Array(this.state.headers.length)].map(
                              (e, i) => (
                                <td
                                  key={i}
                                  className={`px-3 py-3 whitespace-wrap text-sm font-medium text-gray-900 ${
                                    i === 0 ? "text-left" : "text-center"
                                  }`}
                                >
                                  <Skeleton />
                                </td>
                              )
                            )}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    );
  }
}

export default compose(
  connector,
  withRouter
)(ReturnTaskOverview) as React.ComponentType;
