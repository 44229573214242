export function getDeviceName() {
  const userAgent = navigator.userAgent;
  const mobileRegex =
    /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i;

  if (mobileRegex.test(userAgent)) return "mobile";
  return "computer";
}

export function getOSName() {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.indexOf("win") > -1) return "windows";
  if (userAgent.indexOf("mac") > -1) return "macos";
  if (userAgent.indexOf("linux") > -1) return "linux";
  if (userAgent.indexOf("android") > -1) return "android";
  if (
    userAgent.indexOf("iphone") > -1 ||
    userAgent.indexOf("ipad") > -1 ||
    userAgent.indexOf("ipod") > -1
  )
    return "ios";

  return "unknown";
}

export function getBrowserName() {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.indexOf("chrome") > -1) return "chrome";
  if (userAgent.indexOf("firefox") > -1) return "firefox";
  if (userAgent.indexOf("safari") > -1) return "safari";
  if (userAgent.indexOf("opera") > -1) return "opera";
  if (userAgent.indexOf("msie") > -1) return "internet explorer";

  return "unknown";
}

export function isWebView() {
  const userAgent = navigator.userAgent;

  // Android WebView
  const isAndroidWebView = /; wv/.test(userAgent) && /Build\//.test(userAgent);

  // iOS WebView
  const isIOSWebView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(
    userAgent
  );

  return isAndroidWebView || isIOSWebView;
}
