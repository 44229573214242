import { Component } from "react";
import Button from "../../components/Button";
import MultiSelectCheckbox from "../../components/MultiSelectCheckbox";
import capitalize from "../../helpers/capitalize";
import { formatDate } from "../../helpers/formatDate";
import agent from "../../agent";
import timeTillMidnight, {
  timeFromMidnight
} from "../../helpers/timeTillMidnight";
import { formatClientName } from "../../helpers/formatClientName";
import { Client } from "../../helpers/types";

const trackingTypeOptions = ["automatic", "manual", "both"] as const;
const otherThanTaskLogTypeOptions = [
  "client",
  "status",
  "tag",
  "customfield",
  "group",
  "contactperson"
] as const;
const logTypeOptions = ["All", "Task", ...otherThanTaskLogTypeOptions] as const;

const filteredLogTypeOptions = (report: string): LogType[] => {
  return [logTypeOptions[1]];
  // return report === "client-wise-report"
  //   ? [logTypeOptions[1]]
  //   : [...logTypeOptions];
};

const changeTypeOptions = ["all", "add", "update", "delete"] as const;
const groupByOptions = ["none", "user", "client", "report"] as const;

const filteredGroupByOptions = (reportType: string): GroupBy[] => {
  return reportType === "activity-logs"
    ? [...groupByOptions].filter(item => item !== "client")
    : [...groupByOptions].filter(item => item !== "report");
};

export type TrackingType = (typeof trackingTypeOptions)[number];
export type OtherThanTaskLogType = (typeof otherThanTaskLogTypeOptions)[number];
export type LogType = (typeof logTypeOptions)[number];
export type ChangeType = (typeof changeTypeOptions)[number];
export type GroupBy = (typeof groupByOptions)[number];

export interface Filter {
  dateFrom: string;
  dateTo: string;
  trackingType: TrackingType;
  changeType: ChangeType[];
  logTypes: LogType[];
  users: any[];
  selectedUsers: any[];
  clients: any[];
  selectedClients: any[];
}

interface Props {
  groupBy: GroupBy;
  handleGroupByChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  generateReport: (filter: Filter, download: boolean) => void;
  resetList: () => void;
  onCancel: () => void;
  params: { firmId: string; report: string; reportType: string };
  rights: { role: string };
  users: any[];
  addNotification: (title: string, message: string, type: string) => void;
}

interface State {
  loading: boolean;
  users: any[];
  selectedUsers: any[];
  clients: any[];
  selectedClients: (Client | { _id: "allClients"; name: "All" })[];
  dateFrom: string;
  dateTo: string;
  trackingType: TrackingType;
  logTypes: LogType[];
  changeType: ChangeType[];
}

class ReportFilters extends Component<Props, State> {
  state: State = {
    loading: false,
    users: [],
    selectedUsers: [{ _id: "allUsers", name: "All" }],
    clients: [],
    selectedClients: [{ _id: "allClients", name: "All" }],
    dateFrom: formatDate(new Date(), true),
    dateTo: formatDate(new Date(), true),
    trackingType: "both",
    logTypes: ["Task"],
    // (this.props as any).params.report === "client-wise-report"
    //   ? ["Task"]
    //   : [filteredLogTypeOptions(this.props?.params?.report)[1]],
    changeType: [...changeTypeOptions]
  };

  onCancel = () => {
    this.props.onCancel();
    this.setState({
      selectedClients: [],
      selectedUsers: [],
      dateFrom: "",
      dateTo: "",
      trackingType: "both"
    });
  };

  componentDidMount(): void {
    this.getAllClients();
    this.props?.users?.length > 0 &&
      this.setState({
        users: [{ _id: "allUsers", name: "All" }, ...(this.props as any).users],
        selectedUsers: [
          { _id: "allUsers", name: "All" },
          ...(this.props as any).users
        ]
      });
  }

  componentDidUpdate(prevProps: any, prevState: State) {
    const prevFirmId = prevProps.params?.firmId;
    const currFirmId = (this.props as any).params?.firmId;
    if (prevFirmId !== currFirmId) {
      this.getAllClients();
    }
    if (
      prevProps.users !== (this.props as any).users &&
      (this.props as any).users.length > 0
    ) {
      this.setState({
        users: [{ _id: "allUsers", name: "All" }, ...(this.props as any).users],
        selectedUsers: [
          { _id: "allUsers", name: "All" },
          ...(this.props as any).users
        ]
      });
    }

    if (
      prevState.selectedUsers !== this.state.selectedUsers ||
      prevState.selectedClients !== this.state.selectedClients ||
      prevState.dateFrom !== this.state.dateFrom ||
      prevState.dateTo !== this.state.dateTo ||
      prevState.trackingType !== this.state.trackingType ||
      prevState.logTypes !== this.state.logTypes ||
      prevState.changeType !== this.state.changeType
    ) {
      this.props.resetList();
    }
  }

  getAllClients = () => {
    this.setState({ loading: true });
    agent.Clients.getClientList(
      (this.props as any).params?.firmId,
      0,
      100000,
      "",
      true
    )
      .then((res: any) => {
        this.setState({
          clients: [{ _id: "allClients", name: "All" }, ...res.clients],
          selectedClients: [{ _id: "allClients", name: "All" }, ...res.clients],
          loading: false
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).addNotification(
          "Error",
          "Error while fetching clients",
          "error"
        );
      });
  };

  handleUserChange = (selectedUser: any) => {
    const { users, selectedUsers } = this.state;
    const userNotAlreadySelected =
      selectedUsers.findIndex((item: any) => item._id === selectedUser._id) ===
      -1;
    const allUsersSelected =
      selectedUsers.findIndex((item: any) => item._id === "allUsers") !== -1;

    if (userNotAlreadySelected) {
      if (
        selectedUser._id === "allUsers" ||
        selectedUsers.length === users.length - 2
      ) {
        this.setState({ selectedUsers: users });
      } else {
        this.setState({ selectedUsers: [...selectedUsers, selectedUser] });
      }
    } else {
      if (selectedUser._id === "allUsers") {
        this.setState({
          selectedUsers: []
        });
      } else if (
        allUsersSelected &&
        selectedUser._id !== "allUsers" &&
        selectedUsers.length === users.length
      ) {
        this.setState({
          selectedUsers: selectedUsers.filter(
            (item: any) =>
              item._id !== "allUsers" && item._id !== selectedUser._id
          )
        });
      } else {
        this.setState({
          selectedUsers: selectedUsers.filter(
            (item: any) => item._id !== selectedUser._id
          )
        });
      }
    }
  };

  handleClientChange = (selectedClient: any) => {
    const { clients, selectedClients } = this.state;
    const clientNotAlreadySelected =
      selectedClients.findIndex(
        (item: any) => item._id === selectedClient._id
      ) === -1;
    const allClientsSelected =
      selectedClients.findIndex((item: any) => item._id === "allClients") !==
      -1;

    if (clientNotAlreadySelected) {
      if (
        selectedClient._id === "allClients" ||
        selectedClients.length === clients.length - 2
      ) {
        this.setState({ selectedClients: clients });
      } else {
        this.setState({
          selectedClients: [...selectedClients, selectedClient]
        });
      }
    } else {
      if (selectedClient._id === "allClients") {
        this.setState({
          selectedClients: []
        });
      } else if (
        allClientsSelected &&
        selectedClient._id !== "allClients" &&
        selectedClients.length === clients.length
      ) {
        this.setState({
          selectedClients: selectedClients.filter(
            (item: any) =>
              item._id !== "allClients" && item._id !== selectedClient._id
          )
        });
      } else {
        this.setState({
          selectedClients: selectedClients.filter(
            (item: any) => item._id !== selectedClient._id
          )
        });
      }
    }
  };

  handleDateFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ dateFrom: e.target.value });
  };

  handleDateToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ dateTo: e.target.value });
  };

  handleTrackingTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ trackingType: e.target.value as TrackingType });
  };

  handleLogTypeChange = (selectedLogType: { _id: LogType }) => {
    const { logTypes } = this.state;
    const logTypeNotAlreadySelected =
      logTypes.findIndex((item: any) => item === selectedLogType._id) === -1;
    const allLogTypesSelected =
      logTypes.findIndex((item: any) => item === "All") > -1;
    if (logTypeNotAlreadySelected) {
      if (
        selectedLogType._id === "All" ||
        logTypes.length === logTypeOptions.length - 2
      ) {
        this.setState({
          logTypes: filteredLogTypeOptions((this.props as any).params.report)
        });
      } else if (allLogTypesSelected) {
        this.setState({
          logTypes: logTypes.filter(
            item => item !== "All" && item !== selectedLogType._id
          )
        });
      } else {
        this.setState({ logTypes: [...logTypes, selectedLogType._id] });
      }
    } else {
      if (selectedLogType._id === "All") {
        this.setState({
          logTypes: []
        });
      } else if (allLogTypesSelected) {
        this.setState({
          logTypes: logTypes.filter(
            item => item !== "All" && item !== selectedLogType._id
          )
        });
      } else {
        this.setState({
          logTypes: logTypes.filter(item => item !== selectedLogType._id)
        });
      }
    }
  };

  handleChangeTypeChange = (selectedChangeType: { _id: ChangeType }) => {
    const { changeType } = this.state;
    const changeTypeNotAlreadySelected =
      changeType.findIndex((item: any) => item === selectedChangeType._id) ===
      -1;
    const allChangeTypesSelected =
      changeType.findIndex((item: any) => item === "all") > -1;

    if (changeTypeNotAlreadySelected) {
      if (
        selectedChangeType._id === "all" ||
        changeType.length === changeTypeOptions.length - 2
      ) {
        this.setState({
          changeType: [...changeTypeOptions]
        });
      } else if (allChangeTypesSelected) {
        this.setState({
          changeType: changeType.filter(
            item => item !== "all" && item !== selectedChangeType._id
          )
        });
      } else {
        this.setState({ changeType: [...changeType, selectedChangeType._id] });
      }
    } else {
      if (selectedChangeType._id === "all") {
        this.setState({
          changeType: []
        });
      } else if (allChangeTypesSelected) {
        this.setState({
          changeType: changeType.filter(
            item => item !== "all" && item !== selectedChangeType._id
          )
        });
      } else {
        this.setState({
          changeType: changeType.filter(item => item !== selectedChangeType._id)
        });
      }
    }
  };

  getUTCTimeRange = (dateFrom: string, dateTo: string) => {
    //function to subtract the minutes from the date to get the correct UTC time
    function subtractHours(date: Date) {
      // to return the different in time zone with the UTC
      const timeZoneDifference = new Date().getTimezoneOffset();

      date.setMinutes(date.getMinutes() + timeZoneDifference);
      return date;
    }

    //to get the correct date and time in UTC before hitting API
    const UTCdatefrom = subtractHours(timeFromMidnight(dateFrom));
    const UTCdateto = subtractHours(timeTillMidnight(dateTo));

    return { UTCdatefrom, UTCdateto };
  };

  generateReport = () => {
    const { UTCdatefrom, UTCdateto } = this.getUTCTimeRange(
      this.state.dateFrom,
      this.state.dateTo
    );
    const filter = {
      dateFrom: UTCdatefrom.toISOString(),
      dateTo: UTCdateto.toISOString(),
      trackingType: this.state.trackingType,
      changeType: this.state.changeType,
      logTypes: this.state.logTypes,
      users: this.state.users,
      selectedUsers: this.state.selectedUsers,
      clients: this.state.clients,
      selectedClients: this.state.selectedClients
    };
    this.props.generateReport(filter, false);
  };

  render() {
    return (
      <div className="space-y-6 px-6 my-6 bg-white shadow rounded-md">
        <dl className="divide-y-2 divide-gray-200">
          {((this.props as any).params.report === "user-wise-report" ||
            (this.props as any).params.report === "advance-report") && (
            <div className="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-base font-medium text-gray-700">
                Select User
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                <MultiSelectCheckbox
                  type="user-filter"
                  placeholder="Select User"
                  items={this.state.users.map(user => ({
                    ...user,
                    name: user.name,
                    _id: user._id
                  }))}
                  selected={this.state.selectedUsers.map(user => ({
                    ...user,
                    name: user.name,
                    _id: user._id
                  }))}
                  onChange={this.handleUserChange}
                />
              </dd>
            </div>
          )}
          {((this.props as any).params.report === "client-wise-report" ||
            (this.props as any).params.report === "advance-report") && (
            <div className="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-base font-medium text-gray-700">
                Select Client
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                <MultiSelectCheckbox
                  type="client-filter"
                  placeholder="Select Client"
                  items={this.state.clients.map(client => ({
                    ...client,
                    name: formatClientName(client),
                    _id: client._id
                  }))}
                  selected={this.state.selectedClients.map(client => ({
                    ...client,
                    name: client.name,
                    _id: client._id
                  }))}
                  onChange={this.handleClientChange}
                />
              </dd>
            </div>
          )}
          {(this.props as any).params.reportType === "activity-logs" && (
            <>
              {(this.props as any).params.report !== "client-wise-report" && (
                <div className="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-base font-medium text-gray-700">
                    Show Report For
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                    <MultiSelectCheckbox
                      // type="report-for-filter"
                      type="logType"
                      placeholder="Show Report For"
                      items={filteredLogTypeOptions(
                        (this.props as any).params.report
                      ).map(type => ({
                        name: capitalize(type),
                        _id: type
                      }))}
                      selected={this.state.logTypes.map(type => ({
                        name: capitalize(type),
                        _id: type
                      }))}
                      onChange={this.handleLogTypeChange}
                    />
                  </dd>
                </div>
              )}
              <div className="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-base font-medium text-gray-700">
                  Select Change Type
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                  <MultiSelectCheckbox
                    type="change-type-filter"
                    placeholder="Select Log Type"
                    items={changeTypeOptions.map(type => ({
                      name: capitalize(type),
                      _id: type
                    }))}
                    selected={this.state.changeType.map(type => ({
                      name: capitalize(type),
                      _id: type
                    }))}
                    onChange={this.handleChangeTypeChange}
                  />
                </dd>
              </div>
            </>
          )}
          {(this.props as any).params.report === "advance-report" && (
            <>
              {(this.props as any).params.reportType === "time-tracking" && (
                <div className="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-base font-medium text-gray-700">
                    Select Type
                  </dt>
                  <dd className="col-span-2 mt-1 text-sm text-gray-900 sm:mt-0 flex items-center gap-4 md:gap-16">
                    {trackingTypeOptions.map((type, index) => (
                      <div key={type} className="flex items-center gap-3">
                        <input
                          id={type}
                          name="trackingType"
                          type="radio"
                          value={type}
                          checked={this.state.trackingType === type}
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                          onChange={this.handleTrackingTypeChange}
                        />
                        <label
                          htmlFor={type}
                          className="text-sm font-medium text-gray-700 cursor-pointer capitalize"
                        >
                          {type === "both" ? type : type + " Time Tracking"}
                        </label>
                      </div>
                    ))}
                  </dd>
                </div>
              )}
              <div className="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-base font-medium text-gray-700">
                  Group by
                </dt>
                <dd className="col-span-2 mt-1 text-sm text-gray-900 sm:mt-0 flex items-center gap-4 md:gap-16">
                  {filteredGroupByOptions(
                    (this.props as any).params.reportType
                  ).map(
                    (item: GroupBy) =>
                      item !== "none" && (
                        <div key={item} className="flex items-center gap-3">
                          <input
                            id={item}
                            name="groupBy"
                            type="radio"
                            value={item}
                            checked={this.props.groupBy === item}
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                            onChange={this.props.handleGroupByChange}
                          />
                          <label
                            htmlFor={item}
                            className="text-sm capitalize font-medium text-gray-700 cursor-pointer"
                          >
                            {item}
                          </label>
                        </div>
                      )
                  )}
                </dd>
              </div>
            </>
          )}
          {(this.props as any).params.report !== "currently-working-report" && (
            <>
              <div className="py-5 sm:grid sm:grid-cols-3 sm:gap-4 items-center">
                <dt className="text-base font-medium text-gray-700">
                  Select Time Period
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 grid grid-cols-2 gap-6">
                  <div>
                    <label
                      htmlFor="start-time"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Date From
                    </label>
                    <input
                      id="start-time"
                      type="date"
                      className="w-full text-sm border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                      max={this.state.dateTo}
                      value={this.state.dateFrom}
                      onChange={this.handleDateFromChange}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="stop-time"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Date To
                    </label>
                    <input
                      id="stop-time"
                      type="date"
                      className="w-full text-sm border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                      min={this.state.dateFrom}
                      value={this.state.dateTo}
                      onChange={this.handleDateToChange}
                    />
                  </div>
                </dd>
              </div>
              <div className="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-base font-medium text-gray-700"></dt>
                <dd className="w-full mt-1 text-sm text-gray-900 sm:mt-0 flex items-center gap-8 justify-center">
                  <Button
                    type="link"
                    to={`/${(this.props as any).params?.firmId}/reports/list`}
                    name="Cancel"
                    onClick={this.onCancel}
                    className="w-32 inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2 px-4 text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:text-sm"
                  />
                  {((((this.props as any).params.report ===
                    "client-wise-report" ||
                    (this.props as any).params.report === "user-wise-report" ||
                    (this.props as any).params.report === "advance-report") &&
                    (this.props?.rights?.role === "admin" ||
                      this.props?.rights?.role === "manager")) ||
                    (this.props as any).params.report.includes("your")) && (
                    <Button
                      name="Generate"
                      onClick={this.generateReport}
                      icon="document-report"
                      disabled={
                        this.state.loading ||
                        this.state.dateFrom === "" ||
                        this.state.dateTo === "" ||
                        ((this.props as any).params.report ===
                        "user-wise-report"
                          ? this.state.selectedUsers.length === 0
                          : (this.props as any).params.report ===
                            "client-wise-report"
                          ? this.state.selectedClients.length === 0
                          : (this.props as any).params.report ===
                              "advance-report" &&
                            this.state.selectedClients.length === 0 &&
                            this.state.selectedUsers.length === 0) ||
                        ((this.props as any).params.reportType ===
                          "activity-logs" &&
                          (this.state.logTypes.length === 0 ||
                            this.state.changeType.length === 0))
                      }
                    />
                  )}
                </dd>
              </div>
            </>
          )}
        </dl>
      </div>
    );
  }
}

export default ReportFilters;
