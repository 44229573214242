import { Component, Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Icon from "../../components/Icon";
import MultiSelect from "../../components/MultiSelect";
import ViewAllModal from "./ViewAllModal";
import { Link } from "react-router-dom";
import capitalize from "../../helpers/capitalize";
import { CurrentTab, UserRightsFilter, UserTypeFilter } from "./Index";

interface Props {
  state: {
    currentTab: CurrentTab;
    returnTaskTypes: any[];
    selectedReturnTaskTypes: any;
    selectedPeriod: any;
    loading: boolean;
    userWiseReport: any[];
    period: any[];
    statusWiseAndTaskTypeWiseReport: any[];
    workSpaceId?: string;
    selectedUser: { _id: string; name: string };
    userTypeFilter: UserTypeFilter;
    userRightsFilter: UserRightsFilter;
  };
  currentUser?: { _id: string; name: string };
  onReturnTypeChange: (item: any) => void;
  onPeriodChange: (item: any) => void;
  searchParams: (
    key: string,
    type?: string,
    filter?: string | undefined
  ) => "" | URLSearchParams;
}

interface State {
  showFilter: boolean;
  showViewAllModal: boolean;
}

class StatusWiseAndTaskTypeWiseReport extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showFilter: false,
      showViewAllModal: false
    };
  }

  showFilter = () => {
    this.setState({ showFilter: true });
    this.props.onReturnTypeChange(this.props.state.selectedReturnTaskTypes);
    this.props.onPeriodChange(this.props.state.selectedPeriod);
  };

  showViewAllModal = () => {
    this.setState({ showViewAllModal: true });
  };

  render() {
    return (
      <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow hover:shadow-xl">
        <ViewAllModal
          workSpaceId={this.props.state.workSpaceId}
          currentTab={this.props.state.currentTab}
          selectedUser={this.props.state.selectedUser}
          searchParams={this.props.searchParams}
          showViewAllModal={this.state.showViewAllModal}
          userTypeFilter={this.props.state.userTypeFilter}
          userRightsFilter={this.props.state.userRightsFilter}
          currentUser={this.props.currentUser}
          reportType="statusWiseAndTaskTypeWiseReport"
          reportData={this.props.state.statusWiseAndTaskTypeWiseReport}
          onClose={() => this.setState({ showViewAllModal: false })}
        />
        <header className="py-3 px-4 md:px-6 flex gap-x-4 gap-y-3 justify-between items-center">
          <h3 className="text-base leading-6 font-semibold text-gray-900 capitalize">
            {this.props.state.currentTab} Task Summary
          </h3>
          <button
            className="flex items-center gap-2 px-0.5 py-0.5 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:ring-2 hover:ring-offset-2 hover:ring-indigo-500"
            onClick={this.showFilter}
          >
            <span className="text-sm">
              {this.props.state.selectedReturnTaskTypes ||
              this.props.state.selectedPeriod
                ? `${this.props.state.selectedReturnTaskTypes?.name ?? ""} ${
                    this.props.state.selectedPeriod?.name ?? ""
                  }`
                : "Select task filter"}
            </span>
            <Icon name="outline/filter" className="w-5 h-5" />
          </button>

          <Transition.Root show={this.state.showFilter} as={Fragment}>
            <Dialog
              as="div"
              className="fixed inset-0 z-10 overflow-y-auto"
              onClose={() => {
                this.setState({ showFilter: false });
              }}
            >
              <div className="min-h-screen px-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-black/5" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                  className="inline-block h-screen align-middle"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl space-y-3">
                    <div className="flex justify-between items-center">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Filter
                      </Dialog.Title>
                      <button
                        type="button"
                        className="text-gray-400 hover:text-gray-500"
                        onClick={() => {
                          this.setState({ showFilter: false });
                        }}
                      >
                        <XMarkIcon className="w-6 h-6" />
                      </button>
                    </div>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Filter the tasks by the following criteria.
                      </p>
                    </div>

                    <div className="flex justify-evenly gap-x-2 h-[13rem]">
                      <MultiSelect
                        heading="Task Type"
                        items={this.props.state.returnTaskTypes.map(
                          (returntype: any) => {
                            return {
                              ...returntype,
                              _id: returntype._id,
                              name: `${returntype.name}`
                            };
                          }
                        )}
                        selected={{
                          name: this.props.state.selectedReturnTaskTypes?.name
                        }}
                        type="return-type"
                        onChange={this.props.onReturnTypeChange}
                        placeholder="Select Return Type"
                      />
                      <MultiSelect
                        heading="Period"
                        items={this.props.state.period?.map((item: any) => {
                          return {
                            ...item,
                            _id: item._id,
                            name: item.name
                          };
                        })}
                        selected={{
                          name: this.props.state.selectedPeriod?.name
                        }}
                        type="period"
                        onChange={this.props.onPeriodChange}
                        placeholder="Select Period"
                      />
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </header>
        <div className="p-4">
          <div className="bg-white shadow rounded-md sm:overflow-hidden">
            <div className="flex flex-col">
              <div id="tab-scroll" className="overflow-x-auto">
                <div className="inline-block min-w-full align-middle">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className={`px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider text-left`}
                          >
                            Period
                          </th>
                          <th
                            scope="col"
                            className={`px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider text-center`}
                          >
                            Total Tasks
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {!this.props.state.loading ? (
                          this.props.state.statusWiseAndTaskTypeWiseReport
                            .length > 0 ? (
                            <>
                              {this.props.state.statusWiseAndTaskTypeWiseReport
                                .slice(0, 5)
                                .map((record: any, index: number) => (
                                  <tr
                                    key={`statusWiseAndTaskTypeWiseReport-${record.statusName}-${index}`}
                                    className={
                                      index % 2 === 0
                                        ? "bg-white"
                                        : "bg-gray-50"
                                    }
                                  >
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize">
                                      {record.statusName}
                                    </td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize text-center">
                                      <Link
                                        className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                                        to={`/${
                                          this.props.state.workSpaceId
                                        }/tasks/list?${
                                          this.props.state.currentTab ===
                                          "Common"
                                            ? ""
                                            : this.props.searchParams(
                                                "type",
                                                this.props.state.currentTab
                                              )
                                        }&${this.props.searchParams(
                                          "name",
                                          this.props.state
                                            .selectedReturnTaskTypes?.name
                                        )}&${this.props.searchParams(
                                          "periodType",
                                          capitalize(
                                            this.props.state
                                              .selectedReturnTaskTypes
                                              ?.periodType ??
                                              this.props.state
                                                .selectedReturnTaskTypes?.period
                                          )
                                        )}&${this.props.searchParams(
                                          "period",
                                          this.props.state.selectedPeriod?.name
                                        )}&${this.props.searchParams(
                                          "status",
                                          record.statusName
                                        )}&${
                                          this.props.state.userTypeFilter ===
                                            "otherUser" ||
                                          this.props.state.userTypeFilter ===
                                            "any"
                                            ? `&${this.props.searchParams(
                                                "otherUser",
                                                this.props.state
                                                  .userRightsFilter ===
                                                  "employee"
                                                  ? this.props.currentUser?._id
                                                  : this.props.state
                                                      .selectedUser._id
                                              )}`
                                            : this.props.state
                                                .userRightsFilter ===
                                                "employee" &&
                                              this.props.state
                                                .userTypeFilter ===
                                                "currentUser"
                                            ? `&${this.props.searchParams(
                                                "user",
                                                this.props.currentUser?._id
                                              )}`
                                            : `&${this.props.searchParams(
                                                "user",
                                                this.props.state.selectedUser
                                                  ._id
                                              )}`
                                        }`}
                                      >
                                        {record.count}
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                              <tr className="bg-white">
                                <td className="px-3 py-2 whitespace-nowrap text-gray-900 font-medium text-sm capitalize">
                                  Total
                                </td>
                                <td className="px-3 py-2 whitespace-nowrap text-gray-900 font-semibold text-sm capitalize text-center">
                                  {this.props.state.statusWiseAndTaskTypeWiseReport.reduce(
                                    (total: number, record: any) =>
                                      total + record.count,
                                    0
                                  )}
                                </td>
                              </tr>
                              {this.props.state.statusWiseAndTaskTypeWiseReport
                                .length > 5 && (
                                <tr className="bg-white">
                                  <td
                                    colSpan={2}
                                    className="px-3 py-2 whitespace-nowrap text-gray-900 font-semibold capitalize text-center"
                                  >
                                    <button
                                      className="text-sm inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 font-medium text-indigo-700 hover:bg-indigo-200 focus:bg-indigo-200 focus:outline-none"
                                      onClick={this.showViewAllModal}
                                    >
                                      View All
                                    </button>
                                  </td>
                                </tr>
                              )}
                            </>
                          ) : (
                            <tr className="bg-white">
                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize">
                                No Record Found
                              </td>
                            </tr>
                          )
                        ) : (
                          [...Array(5)].map((e, i) => (
                            <tr
                              key={`tr-statusWiseAndTaskTypeWiseReport-${i}`}
                              className="bg-white"
                            >
                              {[...Array(2)].map((e, i) => (
                                <td
                                  key={`td-statusWiseAndTaskTypeWiseReport-${i}`}
                                  className="px-2 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                >
                                  <Skeleton />
                                </td>
                              ))}
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StatusWiseAndTaskTypeWiseReport;
