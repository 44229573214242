import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { connect, ConnectedProps } from "react-redux";
// Link backend
import agent from "../../agent";
// Dashboard import
import Dashboard from "../../components/Dashboard";
// Redux Notify
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import { compose } from "redux";
import { withRouter, WithRouterProps } from "../../helpers/withRouter";
import Icon from "../../components/Icon";
// TOdo
import TodoForm from "./TodoForm";
import TodoList from "./TodoList";
import AddEditList from "./AddEditList";
import DeleteModal from "../../components/DeleteModal";
import Skeleton from "react-loading-skeleton";
import { Navigate } from "react-router";
import { PlusIcon } from "@heroicons/react/24/outline";
import Button from "../../components/Button";
import MultiSelectCheckbox from "../../components/MultiSelectCheckbox";
import { User } from "../../helpers/types";
import { AppDispatch, RootState } from "../../store";
import { NotifyType } from "../../store/reducers/notification";
import { CommonAction } from "../../store/reducers/common";
import Switch from "../../components/switch";

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateCommon: (payload: CommonAction["payload"]) =>
    dispatch({ type: UPDATE_COMMON, payload }),
  onNotify: (title: string, message: string, type: NotifyType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = Partial<PropsFromRedux & WithRouterProps> & {};

export type TodoList = {
  _id: string;
  workSpaceId: string;
  name: string;
  order: number;
  owner: string;
  users: string[];
  oldId: number;
  type?: "default";
};

export type TodoTemp = {
  workSpaceId?: string;
  toDoListId?: string;
  toDo: string;
  description: string;
  date: string;
  star: boolean;
  reminderDate: Date;
  recurring: boolean;
  recurringPeriodCount: number;
};

export type TodoEditData = TodoTemp & { toDoId: string; order?: number };

export type Todo = TodoTemp & {
  _id: string;
  toDoListId: string;
  toDoListName: string;
  order: number;
  recurringPeriod: string;
  status: string;
  completionTime: Date;
  oldId: number;
};
export let todoList: any[] = [];

type TodoCount = {
  next7Days: number;
  overdue: number;
  starred: number;
  today: number;
  withoutDueDate: number;
};

export let todoCount: TodoCount;

export const getTodoCount = (
  workSpaceId: string | undefined,
  setLoading: any
) => {
  setLoading(true);

  workSpaceId &&
    agent.Todo.count(workSpaceId)
      .then((response: TodoCount) => {
        todoCount = response;
      })
      .catch(error => {
        return error;
      })
      .finally(() => {
        setLoading(false);
      });
};

export const getTodoList = (
  workSpaceId: string | undefined,
  setLoading: (loading: boolean) => void,
  setTodoList?: (todoList: TodoList[]) => void
) => {
  if (workSpaceId) {
    setLoading(true);
    agent.Todo.getAllTodoListOfUser(workSpaceId)
      .then((response: any) => {
        todoList = response.toDoList;
        setTodoList?.(response.toDoList);
      })
      .catch(error => {
        return error;
      })
      .finally(() => {
        setLoading(false);
      });
  }
};

type State = {
  loading: boolean;
  rotate: boolean;
  showBackDrop: boolean;
  searchText: string;
  totalRecords: number;
  displayTodosDetails: any;
  typingTimeout: NodeJS.Timeout | null;
  modalOpen: boolean;
  showDeleteModal: boolean;
  toDoList: TodoList[];
  usersInTodoList: string[];
  toDoListId?: string;
  workSpaceId?: string;
  list?: string;
  oririnalTodos: any;
  todos: Todo[] | null;
  status: boolean;
  todoTemp: TodoTemp;
  active: boolean;
  showShareModal: boolean;
  listDeleted: boolean;
  selectedRow: Todo | null;
  sortByDate: boolean;
  sortOrder: "asc" | "desc";
};

class Todos extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      rotate: false,
      showBackDrop: false,
      searchText: "",
      totalRecords: 0,
      displayTodosDetails: [],
      typingTimeout: null,
      modalOpen: false,
      showDeleteModal: false,
      status: false,
      oririnalTodos: null,
      todos: null,
      toDoList: [],
      usersInTodoList: [],
      toDoListId: this.props.params?.toDoListId,
      workSpaceId: this.props.params?.firmId,
      list: "",
      todoTemp: {
        workSpaceId: this.props.params?.firmId,
        toDo: "",
        description: "",
        date: "",
        star: false,
        reminderDate: new Date(),
        recurring: false,
        recurringPeriodCount: 1
      },
      active: true,
      showShareModal: false,
      listDeleted: false,
      selectedRow: null,
      sortByDate: false,
      sortOrder: "desc"
    };
  }

  handleSortByDateChange = (sort: boolean) => {
    this.setState({ sortByDate: sort });
  };

  handleSortOrderChange = () => {
    this.setState({
      sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc"
    });
  };

  sort = (array: Todo[] | null) => {
    if (!array) return [];

    const arrayToSort = [...array];

    return this.state.sortByDate && this.state.toDoListId
      ? arrayToSort.sort((a, b) => {
          if (this.state.sortOrder === "asc") {
            return new Date(a.date).getTime() - new Date(b.date).getTime();
          } else {
            return new Date(b.date).getTime() - new Date(a.date).getTime();
          }
        })
      : arrayToSort;
  };

  openAddListModal = () => {
    this.props.updateCommon?.({
      currentModal: { modalName: "ADD_LIST_MODAL", fetchAgain: false }
    });
  };

  openEditListModal = (list: any) => {
    this.props.updateCommon?.({
      currentModal: {
        modalName: "EDIT_LIST_MODAL",
        fetchAgain: false,
        data: list
      }
    });
  };

  closeModal = (fetchAgain: boolean) => {
    this.props.updateCommon?.({
      currentModal: { modalName: "", fetchAgain }
    });
  };

  setLoading = (loading: boolean) => {
    this.setState({ loading: loading });
  };

  setTodoList = (toDoList: any[]) => {
    this.setState({ toDoList });
  };

  onActionClick = (e: any) => {
    this.setState({
      showBackDrop: true,
      rotate: true
    });
  };

  onDropdownClick = () => {
    this.setState({
      rotate: false,
      showBackDrop: false,
      showShareModal: false
    });
  };

  openDeleteModal = (todolist: any) => {
    this.setState({ selectedRow: todolist, showBackDrop: false });
    this.deleteModalSetOpen(true);
  };
  deleteModalSetOpen = (open: boolean) => {
    this.setState({
      showDeleteModal: open
    });
  };

  setTodoData = (data: TodoTemp) => {
    this.setState({
      todoTemp: {
        workSpaceId: this.props.params?.firmId,
        toDoListId: this.state.toDoListId,
        ...data
      }
    });
  };

  getTodos = (forSearch: boolean, status: boolean, toDoListId?: string) => {
    const searchText = forSearch ? this.state.searchText : "";
    this.setState({ loading: true });
    this.props.params?.firmId &&
      toDoListId &&
      agent.Todo.getAllTodoOfList(
        this.props.params?.firmId,
        toDoListId,
        status ? "completed" : "pending",
        searchText
      )
        .then((response: any) => {
          this.setState({
            oririnalTodos: response.todos,
            todos: this.sort(response.todos),
            totalRecords: response.todos.length,
            loading: false
          });
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          this.props.onNotify?.(
            "Could not load Todos",
            err?.response?.data?.message || err?.error || err,
            "danger"
          );
        });
  };

  getCompletedTodos = () => {
    this.setState({ searchText: "" });
    if (this.state.status) {
      this.setState({ status: false });
    } else {
      this.setState({ status: true });
    }
  };

  handleSearchTextChange = (ev: any) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      searchText: ev.target.value,
      typingTimeout: setTimeout(() => {
        if (this.state.list) {
          this.getTodoSpecificCases(this.state.list, this.state.status, true);
        } else {
          this.getTodos(true, this.state.status, this.state.toDoListId);
        }
      }, 700)
    });
  };

  addTodo = (todoData: TodoTemp) => {
    this.setState({ loading: true });
    agent.Todo.create(todoData)
      .then((res: any) => {
        this.setState({
          loading: false,
          todoTemp: {
            toDo: "",
            description: "",
            date: "",
            star: false,
            reminderDate: new Date(),
            recurring: false,
            recurringPeriodCount: 1
          }
        });
        this.props.onNotify?.("Success", res.message, "success");
        this.getTodos(false, this.state.status, this.state.toDoListId);
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        this.props.onNotify?.(
          "Could not create Todo",
          err?.response?.data?.message || err?.error || err,
          "danger"
        );
      });
  };

  editTodo = (todoData: TodoEditData, message?: string) => {
    this.setState({ loading: true });
    agent.Todo.edit(todoData)
      .then((res: any) => {
        this.setState({ loading: false });
        this.props.onNotify?.(
          "Success",
          message === "star"
            ? "Todo Stared Successfully"
            : message === "unstar"
            ? "Todo Unstared Successfully"
            : res.message,
          "success"
        );
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        this.props.onNotify?.(
          "Could not edit Todo",
          err?.response?.data?.message || err?.error || err,
          "danger"
        );
      });
  };

  getTodoSpecificCases = (
    type: string | undefined,
    status: boolean,
    forSearch: boolean
  ) => {
    const searchText = forSearch ? this.state.searchText : "";
    this.setState({ loading: true });

    this.props.params?.firmId &&
      type &&
      agent.Todo.todoSpecificCases(
        this.props.params?.firmId,
        type,
        status ? "completed" : "pending",
        searchText
      )
        .then((res: any) => {
          this.setState({
            loading: false,
            todos: res.todos,
            totalRecords: res.todos.length
          });
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          this.props.onNotify?.(
            "Could not get Todos",
            err?.response?.data?.message || err?.error || err,
            "danger"
          );
        });
  };

  handleTodoListUserChange = (selectedUser: User) => {
    const selectedUserId = selectedUser._id;
    const usersInTodoList = this.state.usersInTodoList;
    if (usersInTodoList.includes(selectedUserId)) {
      const index = usersInTodoList.indexOf(selectedUserId);
      usersInTodoList.splice(index, 1);
    } else {
      usersInTodoList.push(selectedUserId);
    }

    this.setState({ usersInTodoList }, () => {
      this.addUserToTodoList(usersInTodoList);
    });
  };

  addUserToTodoList = (userIds: string[]) => {
    this.setState({ loading: true });
    agent.Todo.addUserToTodoList({
      toDoListId: this.state.toDoListId,
      users: userIds,
      workSpaceId: this.props.params?.firmId
    })
      .then((res: any) => {
        this.setState({
          loading: false,
          showBackDrop: false,
          showShareModal: false
        });
        this.props.onNotify?.(
          "Successfully shared list",
          res.message,
          "success"
        );
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        this.props.onNotify?.(
          "Could not add user to Todo List",
          err?.response?.data?.message || err?.error || err,
          "danger"
        );
      });
  };

  getUpdatedTodos = () => {
    if (this.props.params?.list !== undefined) {
      this.getTodoSpecificCases(
        this.props.params?.list,
        this.state.status,
        this.state.searchText.length > 0 ? true : false
      );
    } else if (this.props.params?.toDoListId !== undefined) {
      this.getTodos(
        this.state.searchText.length > 0 ? true : false,
        this.state.status,
        this.props.params?.toDoListId
      );
    }
  };

  componentDidMount() {
    document.title = "To Do - TaxPido PMS";
    this.setState({
      toDoListId: this.props.params?.toDoListId
    });
    this.props.params?.firmId &&
      getTodoList(this.props.params?.firmId, this.setLoading, this.setTodoList);
    getTodoCount(this.props.params?.firmId, this.setLoading);
    if (this.props.params?.list) {
      this.setState({ list: this.props.params?.list, status: false });
      this.getTodoSpecificCases(this.props.params?.list, false, false);
    } else {
      this.setState({ toDoListId: this.props.params?.toDoListId, list: "" });
      this.getTodos(false, this.state.status, this.props.params?.toDoListId);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const prevFirmId = prevProps.params?.firmId;
    const currFirmId = this.props.params?.firmId;
    if (prevFirmId !== currFirmId && currFirmId) {
      getTodoList(currFirmId, this.setLoading, this.setTodoList);
      getTodoCount(this.props.params?.firmId, this.setLoading);
      this.props.navigate?.(`/${currFirmId}/todo/list/starred`);
      this.props.params?.list &&
        this.getTodoSpecificCases(this.props.params?.list, false, false);
    }

    if (
      prevProps.params?.toDoListId !== this.props.params?.toDoListId &&
      this.props.params?.toDoListId
    ) {
      this.setState({
        todos: null,
        toDoListId: this.props.params?.toDoListId,
        list: "",
        status: false
      });
      this.getTodos(false, this.state.status, this.props.params?.toDoListId);
    } else if (
      prevProps.params?.list !== this.props.params?.list &&
      this.props.params?.list
    ) {
      this.setState({
        todos: null,
        toDoListId: "",
        list: this.props.params?.list,
        status: false
      });
      this.getTodoSpecificCases(this.props.params?.list, false, false);
    } else if (
      prevProps.params?.list !== this.props.params?.list &&
      this.props.params?.list
    ) {
      this.setState({
        todos: null,
        toDoListId: this.props.params?.toDoListId,
        list: "",
        status: false
      });
      this.getTodos(false, this.state.status, this.props.params?.toDoListId);
    } else if (prevProps.params?.list !== this.props.params?.list) {
      this.setState({
        todos: null,
        list: this.props.params?.list,
        toDoListId: "",
        status: false
      });
      this.getTodoSpecificCases(this.props.params?.list, false, false);
    } else if (
      prevState.listDeleted !== this.state.listDeleted &&
      this.state.listDeleted
    ) {
      this.setState({ listDeleted: false });
    }

    if (prevState.status !== this.state.status) {
      this.getTodos(false, this.state.status, this.props.params?.toDoListId);
    }

    if (
      prevState.toDoList !== this.state.toDoList &&
      this.state.toDoList &&
      this.state.toDoListId
    ) {
      this.setState({
        usersInTodoList:
          this.state.toDoList.find(list => list._id === this.state.toDoListId)
            ?.users || []
      });
    }
    if (
      prevState.sortByDate !== this.state.sortByDate ||
      prevState.sortOrder !== this.state.sortOrder
    ) {
      this.setState({
        todos: this.state.sortByDate
          ? this.sort(this.state.todos)
          : this.state.oririnalTodos
      });
    }
  }

  render() {
    return (
      <Dashboard>
        <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
          <div className="mx-auto flex justify-between items-center gap-4 flex-wrap">
            <h1 className="text-2xl font-semibold text-gray-900 capitalize">
              {(this.state?.list === "next7Days"
                ? "week"
                : this.state?.list === "withoutDueDate"
                ? "Without Due Date"
                : this.state?.list) ||
                todoList?.filter(
                  list => list?._id === this.state?.toDoListId
                )[0]?.name}
            </h1>
            {this.state.toDoListId && (
              <div className="w-full lg:w-auto flex gap-4 items-center justify-between lg:justify-start">
                <div className="lg:sr-only">Share List</div>
                <MultiSelectCheckbox
                  placeholder="Share List"
                  items={this.props.users || []}
                  selected={
                    this.props.users
                      ?.filter(user => {
                        return this.state.usersInTodoList.includes(user._id);
                      })
                      .map(user => ({
                        name: user.name,
                        _id: user._id
                      })) || []
                  }
                  type="todo-users"
                  onChange={this.handleTodoListUserChange}
                />
              </div>
            )}
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-6 relative">
            {(this.props?.currentModal?.modalName === "ADD_LIST_MODAL" ||
              this.props?.currentModal?.modalName === "EDIT_LIST_MODAL") && (
              <AddEditList
                props={this.props}
                closeModal={this.closeModal}
                loading={this.state.loading}
                setLoading={this.setLoading}
                getTodoList={(firmId, setLoading) =>
                  getTodoList(firmId, setLoading, this.setTodoList)
                }
                todoList={todoList}
              />
            )}
            <div
              className={`${
                this.state?.toDoListId ? "hidden" : ""
              } md:block w-fit`}
            >
              <Button
                name="Add List"
                icon={PlusIcon}
                onClick={this.openAddListModal}
              />
            </div>

            <div className="w-full">
              <input
                id="search"
                name="search"
                type="search"
                value={this.state.searchText}
                onChange={this.handleSearchTextChange}
                placeholder="Search by ToDo Name or Description"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 text-sm"
              />
            </div>
            {this.state.toDoListId ? (
              <Menu as="div" className="place-self-end relative">
                {/* {this.state.showShareModal && (
                  <div className="absolute z-[100] w-fit top-12 py-2 overscroll-none rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <optgroup
                      label="&nbsp;&nbsp;&nbsp;Select User"
                      className="text-sm"
                    >
                      {this.props?.users?.map((user: any) => (
                        <option
                          key={user._id}
                          value={user._id}
                          onClick={() => this.addUserToTodoList(user._id)}
                          className="py-3 pl-3 text-sm hover:bg-indigo-600 hover:text-white text-gray-900 cursor-pointer"
                        >
                          {user.name}
                        </option>
                      ))}
                    </optgroup>
                  </div>
                )} */}
                <Menu.Button
                  onClick={this.onActionClick}
                  className="relative w-fit inline-flex gap-x-2 items-center justify-between px-2 py-2 sm:pl-4 sm:pr-2 sm:py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:bg-indigo-700"
                >
                  <Icon
                    name="outline/settings"
                    className={`h-5 w-5`}
                    aria-hidden="true"
                  />
                  <span>Actions</span>
                  <Icon
                    name="dropdown-arrow"
                    className={`h-5 w-5 ${
                      this.state.rotate ? "rotate-90" : ""
                    }`}
                    aria-hidden="true"
                  />
                </Menu.Button>
                {this.state.showBackDrop && (
                  <div
                    className="fixed top-0 left-0 z-10 w-full h-screen"
                    onClick={this.onDropdownClick}
                  ></div>
                )}

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <div className="absolute z-[100] right-0">
                    <Menu.Items className="overscroll-none mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item as={"div"} className="md:hidden">
                          <Button
                            name="Add List"
                            icon={PlusIcon}
                            onClick={() => {
                              this.openAddListModal();
                              this.onDropdownClick();
                            }}
                            className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                          />
                        </Menu.Item>
                        <Menu.Item>
                          <Button
                            name="Edit Name"
                            onClick={() => {
                              this.openEditListModal(
                                todoList.filter(
                                  list => list._id === this.state.toDoListId
                                )
                              );
                              this.onDropdownClick();
                            }}
                            className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                            icon="edit"
                          />
                        </Menu.Item>
                        {/* <Menu.Item>
                          <Button
                            className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                            onClick={() => {
                              this.setState({
                                showShareModal: true,
                                showBackDrop: true
                              });
                            }}
                            name="Share List"
                            icon="share"
                          />
                        </Menu.Item> */}
                        <Menu.Item>
                          <Button
                            className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                            onClick={() => {
                              this.openDeleteModal(
                                todoList?.filter(
                                  list => list?._id === this.state?.toDoListId
                                )[0]
                              );
                              this.onDropdownClick();
                            }}
                            name="Delete List"
                            icon="delete"
                          />
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </div>
                </Transition>
              </Menu>
            ) : (
              <span
                aria-hidden="true"
                className="hidden md:inline-block sm:w-36"
              >
                &#8203;
              </span>
            )}
            {this.state.showDeleteModal ? (
              <DeleteModal
                type={"todo list"}
                state={this.state}
                onLoad={() => {
                  this.props.params?.firmId &&
                    getTodoList(
                      this.props.params?.firmId,
                      this.setLoading,
                      this.setTodoList
                    );
                  this.setState({
                    status: false,
                    listDeleted: true
                  });
                }}
                deleteModalSetOpen={this.deleteModalSetOpen}
              />
            ) : (
              !this.state.showDeleteModal &&
              this.state.listDeleted && (
                <Navigate
                  to={`/${this.props.params?.firmId}/todo/list/starred`}
                />
              )
            )}
          </div>
          {this.state.list === "" && (
            <>
              <div className="relative flex flex-col lg:flex-row gap-4 justify-between items-start max-w-full mx-auto mt-6">
                <div className="flex gap-3 h-5 items-center">
                  <input
                    id="status"
                    name="status"
                    type="checkbox"
                    checked={this.state.status}
                    onChange={this.getCompletedTodos}
                    className="h-4 w-4 rounded border-gray-400 cursor-pointer text-indigo-600 focus:ring-indigo-500"
                  />
                  <label
                    htmlFor="status"
                    className="text-sm font-medium cursor-pointer text-gray-700"
                  >
                    Show Completed Todos
                  </label>
                </div>
                <div className="flex gap-6 justify-between w-full lg:w-auto items-center">
                  <div className="flex gap-3 items-center">
                    <input
                      id="sortByDate"
                      name="sortByDate"
                      type="checkbox"
                      checked={this.state.sortByDate}
                      onChange={() =>
                        this.handleSortByDateChange(!this.state.sortByDate)
                      }
                      className="h-4 w-4 rounded border-gray-400 cursor-pointer text-indigo-600 focus:ring-indigo-500"
                    />
                    <label
                      htmlFor="sortByDate"
                      className="text-sm font-medium cursor-pointer text-gray-700"
                    >
                      Sort by Date
                    </label>
                  </div>
                  {this.state.sortByDate && (
                    <div className="flex items-center gap-2">
                      <span>Old first</span>
                      <Switch
                        openIcon="subtract"
                        closeIcon="add"
                        label={"Sort by Date"}
                        enabled={this.state.sortOrder === "desc"}
                        onChange={this.handleSortOrderChange}
                      />
                      <span>New first</span>
                    </div>
                  )}
                </div>
              </div>
              <TodoForm
                todoTemp={this.state.todoTemp}
                setTodoData={this.setTodoData}
                addTodo={this.addTodo}
                todos={this.state.todos}
                todoListId={this.state.toDoListId}
              />
            </>
          )}
          {!this.state.loading && this.state.todos ? (
            this.state.totalRecords > 0 ? (
              <TodoList
                todos={this.state.todos}
                setTodoData={this.setTodoData}
                getUpdatedTodos={this.getUpdatedTodos}
                editTodo={this.editTodo}
                loading={this.state.loading}
                setLoading={this.setLoading}
                onNotify={this.props.onNotify}
                workSpaceId={this.props.params?.firmId}
                toDoListId={this.state.toDoListId}
                searchText={this.state.searchText}
                customSort={this.state.sortByDate}
              />
            ) : this.state.totalRecords === 0 &&
              this.state.searchText.length > 0 ? (
              <div className="mt-3 shadow-sm ring-2 ring-black ring-opacity-5">
                <ul className="shadow-sm ring-2 ring-black ring-opacity-5">
                  <li className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 w-full bg-white mt-3">
                    No record found matching your search criteria
                  </li>
                </ul>
              </div>
            ) : (
              <div className="text-center w-full max-w-2xl mx-auto my-10 border-2 border-gray-300 border-dashed p-16 rounded-lg">
                <Icon
                  name="outline/document-add"
                  className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                  strokeWidth="1"
                />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  No Todos Entry
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Get started by adding a new Todo.
                </p>
              </div>
            )
          ) : (
            <>
              <div className="mt-4 space-y-4 sm:hidden">
                {[...Array(5)].map((e, i) => (
                  <div key={i} className="bg-white shadow-md rounded-lg">
                    <div
                      key={i}
                      className={`whitespace-wrap text-sm font-medium text-gray-900 px-4 py-4 sm:px-6 grid gap-4 grid-cols-2 items-center relative ${
                        i === 0 ? "text-left" : "text-center"
                      }`}
                    >
                      <span className="px-2 absolute top-0 -translate-y-1/2 -right-4 w-24">
                        <Skeleton
                          height="1rem"
                          className="w-full bg-green-100"
                        />
                      </span>
                      {[...Array(4)].map((e, i) => (
                        <Skeleton
                          key={`contact-person-skeleton-${i}`}
                          height="1rem"
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-4 hidden sm:block">
                {[...Array(5)].map((e, i) => (
                  <div key={i} className="bg-white flex">
                    {[...Array(2)].map((e, i) => (
                      <span key={i} className="flex-1 w-3/10 px-6 py-3">
                        <Skeleton />
                      </span>
                    ))}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </Dashboard>
    );
  }
}

export default compose(connector, withRouter)(Todos) as React.ComponentType;
