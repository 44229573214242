// For Drag And Drop
import update from "immutability-helper";
import { useCallback, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import agent from "../../agent";
import TodoListItem from "./TodoListItem";

const TodoList = (props: any) => {
  const {
    todos,
    setTodoData,
    getUpdatedTodos,
    editTodo,
    loading,
    setLoading,
    onNotify,
    workSpaceId,
    toDoListId,
    searchText,
    customSort
  } = props;

  interface Item {
    id: number;
    toDo: string;
  }

  const [todoArray, setTodoArray] = useState(todos);

  useEffect(() => {
    setTodoArray(todos);
  }, [todos]);

  const moveItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setTodoArray((prevArray: Item[]) =>
        update(prevArray, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevArray[dragIndex] as Item]
          ]
        })
      );
      const newOrder = (): number => {
        if (hoverIndex === 0) {
          return todos[hoverIndex].order + 512;
        } else if (hoverIndex === todos.length - 1) {
          return todos[hoverIndex].order - 512;
        } else if (dragIndex > hoverIndex) {
          return Math.floor(
            (todos[hoverIndex].order + todos[hoverIndex - 1].order) / 2
          );
        } else if (dragIndex < hoverIndex) {
          return Math.ceil(
            (todos[hoverIndex].order + todos[hoverIndex + 1].order) / 2
          );
        }
        return todos[dragIndex].order;
      };
      agent.Todo.reorder(workSpaceId, todos[dragIndex]?._id, newOrder());

      getUpdatedTodos();
    },
    [getUpdatedTodos, todos, workSpaceId]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className={`${
          toDoListId ? "mt-3" : "mt-6"
        } sm:shadow-sm sm:ring-2 sm:ring-black sm:ring-opacity-5`}
      >
        <ul className="space-y-4 sm:space-y-0 sm:shadow-sm sm:ring-2 sm:ring-black sm:ring-opacity-5">
          {todoArray.map((todo: any, index: number) => (
            <TodoListItem
              key={todo?._id}
              todo={todo}
              moveItem={moveItem}
              index={index}
              setTodoData={setTodoData}
              getUpdatedTodos={getUpdatedTodos}
              editTodo={editTodo}
              loading={loading}
              setLoading={setLoading}
              onNotify={onNotify}
              toDoListId={toDoListId}
              searchText={searchText}
              customSort={customSort}
            />
          ))}
        </ul>
      </div>
    </DndProvider>
  );
};

export default TodoList;
