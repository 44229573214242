import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import agent from "../../agent";
import Icon from "../../components/Icon";

import { ADD_NOTIFICATION } from "../../store/types";
import { compose } from "redux";
import { withRouter } from "../../helpers/withRouter";

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

export type ModalType =
  | "addDocument"
  | "editDocument"
  | "addKeptAt"
  | "editKeptAt"
  | undefined;

interface Props {
  modalType: ModalType;
  showModal: boolean;
  closeModal: any;
  showParentModal?: any;
  closeParentModal?: any;
  selectedRow?: any;
  onLoad?: any;
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface State {
  loading: boolean;
  workSpaceId: string;
  name: string;
}

class AddEditModal extends React.Component<Props & PropsFromRedux, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      workSpaceId: (this.props as any).currentFirm._id,
      name: ""
    };
  }

  onKeyUpFunction = (event: any) => {
    if (event.keyCode === 27) {
      this.props.closeModal(false);
    }

    if (event.keyCode === 13) {
      this.handleSave();
    }
  };

  addDocument = () => {
    if (this.state.name === "") {
      (this.props as any).addNotification(
        "Name cannot be empty",
        "Please enter a name for the Document",
        "error"
      );
      return;
    } else {
      this.setState({ loading: true });
      agent.RegisterInOut.addDocument(this.state.workSpaceId, this.state.name)
        .then((res: any) => {
          (this.props as any).addNotification(
            "Success",
            "Document added successfully",
            "success"
          );
          this.props.onLoad();
          this.props.closeModal();
          this.props.showParentModal && this.props.showParentModal();
        })
        .catch((err: any) => {
          (this.props as any).addNotification(
            "Error",
            typeof err?.response?.data?.message === "object"
              ? "Error adding document"
              : err?.response?.data?.message ||
                  err?.response?.data?.error ||
                  err?.message,
            "danger"
          );
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  };

  editDocument = () => {
    if (this.state.name === "") {
      (this.props as any).addNotification(
        "Name cannot be empty",
        "Please enter a name for the Document",
        "error"
      );
      return;
    } else {
      this.setState({ loading: true });
      agent.RegisterInOut.editDocument(
        this.state.workSpaceId,
        this.props.selectedRow._id,
        this.state.name
      )
        .then((res: any) => {
          (this.props as any).addNotification(
            "Success",
            "Document updated successfully",
            "success"
          );
          this.props.onLoad();
          this.props.closeModal();
        })
        .catch((err: any) => {
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.error || err?.message || err,
            "danger"
          );
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  };

  addKeptAt = () => {
    if (this.state.name === "") {
      (this.props as any).addNotification(
        "Error",
        "Please enter a name for the Kept At",
        "error"
      );
      return;
    } else {
      this.setState({ loading: true });
      agent.RegisterInOut.addKeptAt(this.state.workSpaceId, this.state.name)
        .then((res: any) => {
          (this.props as any).addNotification(
            "Success",
            "KeptAt added successfully",
            "success"
          );
          this.props.onLoad();
          this.props.closeModal();
          this.props.showParentModal && this.props.showParentModal();
        })
        .catch((err: any) => {
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.error || err?.message || err,
            "danger"
          );
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  };

  editKeptAt = () => {
    if (this.state.name === "") {
      (this.props as any).addNotification(
        "Error",
        "Please enter a name for the Kept At",
        "error"
      );
      return;
    } else {
      this.setState({ loading: true });
      agent.RegisterInOut.editKeptAt(
        this.state.workSpaceId,
        this.props.selectedRow._id,
        this.state.name
      )
        .then((res: any) => {
          (this.props as any).addNotification(
            "Success",
            "KeptAt updated successfully",
            "success"
          );
          this.props.onLoad();
          this.props.closeModal();
        })
        .catch((err: any) => {
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.error || err?.message || err,
            "danger"
          );
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  };

  handleSave = () => {
    switch (this.props.modalType) {
      case "addDocument":
        return this.addDocument();
      case "addKeptAt":
        return this.addKeptAt();
      case "editDocument":
        return this.editDocument();
      case "editKeptAt":
        return this.editKeptAt();
      default:
        return;
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyUpFunction, false);
    this.props.modalType === "editDocument" &&
      this.setState({ name: this.props.selectedRow.name });
    this.props.modalType === "editKeptAt" &&
      this.setState({ name: this.props.selectedRow.name });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyUpFunction, false);
  }

  render() {
    return (
      <>
        <Transition.Root show={this.props.showModal} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={() => null}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-2 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div className="flex justify-between items-center">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      {this.props.modalType === "addDocument"
                        ? "Add Document"
                        : this.props.modalType === "addKeptAt"
                        ? "Add KeptAt"
                        : this.props.modalType === "editDocument"
                        ? "Edit Document"
                        : this.props.modalType === "editKeptAt"
                        ? "Edit KeptAt"
                        : ""}
                    </h3>
                    {this.props.closeParentModal && (
                      <Link
                        to={`/${this.state.workSpaceId}/register-in-out/${
                          (this.props.modalType === "addDocument" &&
                            "document") ||
                          (this.props.modalType === "addKeptAt" && "kept-at")
                        }`}
                        onClick={() => this.props.closeModal()}
                        className="text-gray-400 hover:text-gray-500 text-sm"
                      >
                        <span
                          onClick={() => {
                            this.props.closeModal();
                            this.props.closeParentModal();
                          }}
                        >
                          View{" "}
                          {this.props.modalType === "addDocument" &&
                            "Documents"}
                          {this.props.modalType === "addKeptAt" && "Kept At"}{" "}
                          List
                        </span>
                      </Link>
                    )}
                  </div>
                  <div>
                    <form onSubmit={e => e.preventDefault()}>
                      <div className="mt-2">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Name
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            value={this.state.name}
                            onChange={e =>
                              this.setState({ name: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:justify-end">
                        <button
                          type="button"
                          className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-100 focus:bg-gray-50  focus:outline-none sm:w-32 sm:text-sm"
                          onClick={this.props.closeModal}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          disabled={this.state.loading}
                          className={
                            "mt-3 sm:ml-4 w-full inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:bg-indigo-800 sm:mt-0 sm:w-32 sm:text-sm"
                          }
                          onClick={this.handleSave}
                        >
                          <span className="w-full flex justify-end">
                            {this.state.loading ? (
                              <Icon name="loading" className="mr-2" />
                            ) : null}
                          </span>
                          <span>Save</span>
                          <span className="w-full"></span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

export default compose(
  connector,
  withRouter
)(AddEditModal) as React.ComponentType<Props>;
