import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { connect, ConnectedProps } from "react-redux";
import agent from "../../agent";

import { ADD_NOTIFICATION } from "../../store/types";
import { compose } from "redux";
import { withRouter } from "../../helpers/withRouter";
import MultiSelect from "../../components/MultiSelect";
import { MessageType } from "../../helpers/types";
import Icon from "../../components/Icon";
import { XMarkIcon } from "@heroicons/react/20/solid";
import ReactQuill from "react-quill";
import { modules } from "../../components/Editor";
import { decodeHtml, parseHtml } from "../../helpers/htmlToString";

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props {
  type: string;
  showModal: boolean;
  closeModal: () => void;
  onLoad: () => void;
  selectedRow?: any;
}

interface State {
  loading: boolean;
  name: string;
  messageType: MessageType;
  template: string;
  emailSubject: string;
  emailHtml: string;
  maxCharCount: number;
}

class Add extends React.Component<Props & PropsFromRedux, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      name: "",
      messageType: "",
      template: "",
      emailSubject: "",
      emailHtml: "",
      maxCharCount: 160
    };
  }

  messageTypes = ["Whatsapp", "Email" /* , "SMS" */];

  handlemessageTypeChange = (value: any) => {
    this.setState({ messageType: value.name });
  };

  handleEmailSubjectChange = (e: any) => {
    this.setState({ emailSubject: e.target.value });
  };

  handleEmailHtmlChange = (value: any) => {
    const parsedValue = parseHtml(value);
    this.setState({ emailHtml: value, template: parsedValue });
  };

  addTemplate = () => {
    const workSpaceId = (this.props as any).params.firmId;
    const { name, template, emailSubject, emailHtml, messageType } = this.state;
    const type = messageType.toUpperCase() as MessageType;
    this.setState({ loading: true });
    agent.Messages.createTemplate(
      workSpaceId,
      name,
      type,
      template,
      type === ("EMAIL" as MessageType) ? emailSubject : "",
      type === ("EMAIL" as MessageType) ? emailHtml : template
    )
      .then((res: any) => {
        (this.props as any).addNotification(
          "Success",
          "Template added successfully",
          "success"
        );
        this.props.onLoad();
        this.props.closeModal();
      })
      .catch((err: any) => {
        (this.props as any).addNotification(
          "Error",
          err.response.data.message,
          "error"
        );
        this.setState({ loading: false });
      });
  };

  editTemplate = () => {
    const workSpaceId = (this.props as any).params.firmId;
    const { name, template, emailSubject, emailHtml, messageType } = this.state;
    const type = messageType.toUpperCase() as MessageType;

    this.setState({ loading: true });
    agent.Messages.updateTemplate(
      workSpaceId,
      name,
      messageType.toUpperCase() as MessageType,
      this.props.selectedRow._id,
      template,
      type === ("EMAIL" as MessageType) ? emailSubject : "",
      type === ("EMAIL" as MessageType) ? emailHtml : ""
    )
      .then((res: any) => {
        (this.props as any).addNotification(
          "Success",
          "Template updated successfully",
          "success"
        );
        this.props.onLoad();
        this.props.closeModal();
      })
      .catch((err: any) => {
        (this.props as any).addNotification(
          "Error",
          err.response.data.message,
          "error"
        );
        this.setState({ loading: false });
      });
  };

  handleSave = () => {
    if (this.props.type === "add") {
      this.addTemplate();
    } else {
      this.editTemplate();
    }
  };

  componentDidMount() {
    if (this.props.type === "edit") {
      this.setState({
        name: this.props.selectedRow.name,
        messageType: this.props.selectedRow.type,
        template: this.props.selectedRow.template,
        emailSubject: this.props.selectedRow.emailSubject,
        emailHtml: decodeHtml(this.props.selectedRow.emailHtml)
      });
    }
  }

  render() {
    return (
      <>
        <Transition.Root show={this.props.showModal} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={() => null}
          >
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg mx-6 px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6 space-y-4">
                  <div className="flex justify-between items-center gap-4">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Select Message Template
                    </h3>
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={this.props.closeModal}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div>
                    <MultiSelect
                      type="select message template"
                      placeholder="Select Message Template"
                      items={this.messageTypes.map(messageType => ({
                        _id: messageType.toUpperCase(),
                        name: messageType
                      }))}
                      selected={{ name: this.state.messageType }}
                      onChange={this.handlemessageTypeChange}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label htmlFor="name" className="text-sm font-medium">
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Enter name of template"
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      value={this.state.name}
                      onChange={e => this.setState({ name: e.target.value })}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center justify-between">
                      <label htmlFor="template" className="text-sm font-medium">
                        Template
                      </label>
                    </div>
                    {this.state.messageType.toLowerCase() === "email" ? (
                      <>
                        <textarea
                          id="emailSubject"
                          name="emailSubject"
                          rows={1}
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                          placeholder="Enter Email Subject"
                          value={this.state.emailSubject}
                          onChange={this.handleEmailSubjectChange}
                        />
                        <ReactQuill
                          theme="snow"
                          modules={modules}
                          defaultValue=""
                          placeholder="Write your Email template here"
                          value={this.state.emailHtml}
                          onChange={this.handleEmailHtmlChange}
                          className="h-[8rem] mb-16"
                        />
                      </>
                    ) : (
                      <textarea
                        name="template"
                        id="template"
                        placeholder="Write your message template here"
                        rows={6}
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                        value={this.state.template}
                        onChange={e =>
                          this.setState({ template: e.target.value })
                        }
                      />
                    )}
                    {this.state.messageType === "SMS" && (
                      <div
                        className={`text-xs ${
                          this.state.template.length > 0
                            ? "visible"
                            : "invisible"
                        }`}
                      >
                        <span className="text-gray-600">
                          {this.state.template.length}/
                          {this.state.maxCharCount *
                            Math.ceil(
                              this.state.template.length /
                                this.state.maxCharCount
                            )}
                        </span>{" "}
                        <span className="text-gray-800">
                          Count as{" "}
                          {Math.ceil(
                            this.state.template.length / this.state.maxCharCount
                          )}{" "}
                          SMS ({this.state.maxCharCount} is character limit per
                          sms)
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="ml-auto flex items-center justify-end gap-4">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-50 disabled:bg-gray-50 focus:outline-none sm:w-32 sm:text-sm"
                      onClick={this.props.closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="sm:ml-4 w-full inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 disabled:bg-indigo-700 focus:outline-none sm:mt-0 sm:w-32 sm:text-sm disabled:cursor-not-allowed disabled:opacity-50"
                      onClick={this.handleSave}
                      disabled={
                        this.state.loading ||
                        !this.state.name ||
                        !this.state.messageType ||
                        this.state.messageType === "Email"
                          ? !this.state.emailHtml && !this.state.template
                          : !this.state.template
                      }
                    >
                      <span className="w-full flex justify-end">
                        {this.state.loading ? (
                          <Icon name="loading" className="mr-2" />
                        ) : null}
                      </span>
                      <span>Save</span>
                      <span className="w-full"></span>
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

export default compose(
  connector,
  withRouter
)(Add) as React.ComponentType<Props>;
