import {
  PropsWithChildren,
  ComponentProps,
  forwardRef,
  Ref,
  ReactNode
} from "react";
import { cn } from "../lib/className";

type TableProps<THeader extends string> = TableHeaderProps<THeader> & {
  headerComponent?: JSX.Element;
  children?: ReactNode;
};

const TableComponent = function <THeader extends string>(
  props: TableProps<THeader>,
  tableRef: Ref<HTMLTableElement>
) {
  const { headers, headerComponent, children } = props;

  return (
    <div className="grid rounded-md border bg-white shadow sm:overflow-hidden">
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table
              ref={tableRef}
              className="min-w-full divide-y divide-gray-300"
            >
              {headers.length > 0 ? (
                <TableHeader {...props} />
              ) : (
                headerComponent
              )}
              <tbody className="divide-y divide-gray-200 bg-white">
                {children}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Table = forwardRef(TableComponent) as <THeader extends string>(
  props: TableProps<THeader> & { ref?: Ref<HTMLTableElement> }
) => JSX.Element;

type TableHeaderProps<THeader extends string> = {
  headers: readonly THeader[];
  headerClassName?: string;
};

export const TableHeader = <THeader extends string>({
  headers,
  headerClassName
}: TableHeaderProps<THeader>) => {
  return (
    <thead>
      <tr className="bg-gray-200">
        {headers.map((header, index) => (
          <TableHead
            key={index}
            className={cn("whitespace-nowrap", headerClassName)}
          >
            <div className="inline-flex gap-4">{header}</div>
          </TableHead>
        ))}
      </tr>
    </thead>
  );
};

type TableHeadProps = PropsWithChildren<
  { className?: string } & ComponentProps<"th">
>;

export const TableHead = ({ children, className, ...rest }: TableHeadProps) => {
  return (
    <th
      className={cn(
        "px-6 py-3 text-left text-sm font-semibold text-gray-900",
        className
      )}
      {...rest}
    >
      {children}
    </th>
  );
};

type TableRowProps = PropsWithChildren<
  { className?: string } & ComponentProps<"tr">
>;

export const TableRow = ({ children, className, ...rest }: TableRowProps) => {
  return (
    <tr className={cn("even:bg-gray-50", className)} {...rest}>
      {children}
    </tr>
  );
};

type TableCellProps = PropsWithChildren<
  { className?: string; border?: boolean } & ComponentProps<"td">
>;

export const TableCell = ({
  children,
  className,
  border = false,
  ...rest
}: TableCellProps) => {
  return (
    <td
      className={cn(
        "whitespace-nowrap px-6 py-4 text-sm text-gray-800",
        border && "border-2 border-gray-200",
        className
      )}
      {...rest}
    >
      {children}
    </td>
  );
};
