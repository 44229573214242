import { Component } from "react";
import Skeleton from "react-loading-skeleton";
import Dashboard from "../../components/Dashboard";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Update Logs"
  },
  dataLayerName: "PageDataLayer"
};

const skeleton = (row: number, col: number) => {
  return [...Array(row)].map((item, index) => (
    <div
      key={index}
      className="py-4 sm:grid sm:grid-cols-3 items-center sm:gap-4 sm:py-5"
    >
      {[...Array(col)].map((item, index) => (
        <Skeleton key={index} />
      ))}
    </div>
  ));
};

class UpdateLogs extends Component {
  state: {
    loading: boolean;
  };

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false
    };
  }

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8 my-6 space-y-6">
        <div className="bg-gray-50 px-4 py-6 divide-y-2 divide-gray-200 rounded-lg shadow">
          <h2 className="text-lg font-medium leading-6 text-gray-900">
            Coming Soon
          </h2>
          <div className="mt-6">
            {this.state.loading ? (
              skeleton(5, 3)
            ) : (
              <ul className="mt-6 space-y-3 list-disc pl-6">
                <li>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                </li>
                <li>
                  Doloremque nostrum rem rerum a perferendis voluptatum ducimus!
                </li>
                <li>
                  Consequatur sunt ipsum nostrum voluptates ducimus minima iure?
                </li>
                <li>Voluptatum nihil culpa molestias cumque ut, quas nobis.</li>
                <li>A nobis quae, blanditiis dolorum natus vitae eligendi!</li>
              </ul>
            )}
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-6 divide-y-2 divide-gray-200 rounded-lg shadow">
          <h2 className="text-lg font-medium leading-6 text-gray-900">
            Update Logs
          </h2>
          <div className="mt-6">
            {this.state.loading ? (
              skeleton(2, 3)
            ) : (
              <ul className="mt-6 space-y-3 list-disc pl-6">
                <li>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                </li>
                <li>
                  Doloremque nostrum rem rerum a perferendis voluptatum ducimus!
                </li>
                <li>
                  Consequatur sunt ipsum nostrum voluptates ducimus minima iure?
                </li>
                <li>Voluptatum nihil culpa molestias cumque ut, quas nobis.</li>
                <li>A nobis quae, blanditiis dolorum natus vitae eligendi!</li>
              </ul>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default UpdateLogs;
