import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import agent from "../../agent";

import { ADD_NOTIFICATION } from "../../store/types";
import { compose } from "redux";
import { withRouter } from "../../helpers/withRouter";
import MultiSelect from "../../components/MultiSelect";
import { MessageType } from "../../helpers/types";
import Button from "../../components/Button";
import ReactQuill from "react-quill";
import { modules } from "../../components/Editor";
import { decodeHtml } from "../../helpers/htmlToString";

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props {
  messageType: MessageType;
  showModal: boolean;
  closeModal: () => void;
  selectTemplate: (template: { [key: string]: string }) => void;
  maxCharCount: number;
}

interface State {
  loading: boolean;
  templateList: { [key: string]: string | boolean | number }[];
  selectedTemplate: { [key: string]: string };
}

class TemplateSelectorModal extends React.Component<
  Props & PropsFromRedux,
  State
> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      templateList: [],
      selectedTemplate: { template: "", name: "" }
    };
  }

  getTemplateList = () => {
    const workSpaceId = (this.props as any).params.firmId;
    const messageType = this.props.messageType.toUpperCase() as MessageType;

    this.setState({ loading: true });
    agent.Messages.getTemplatesList(workSpaceId, messageType)
      .then((res: any) => {
        this.setState({
          templateList: res.messageTemplates,
          loading: false
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).addNotification(
          "Error",
          typeof err?.response?.data?.message === "object"
            ? "Error while fetching templates"
            : err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  handleTemplateChange = (template: { [key: string]: string }) => {
    this.setState({ selectedTemplate: template });
  };

  selectTemplate = () => {
    this.props.selectTemplate(this.state.selectedTemplate);
    this.props.closeModal();
  };

  componentDidMount() {
    this.getTemplateList();
  }

  render() {
    return (
      <>
        <Transition.Root show={this.props.showModal} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={() => null}
          >
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg mx-6 px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6 space-y-4">
                  <div className="flex justify-between items-center gap-4">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Select Message Template
                    </h3>
                    <Link
                      to={`/${
                        (this.props as any).params.firmId
                      }/bulk-message-template/list`}
                      onClick={this.props.closeModal}
                      className="text-gray-400 hover:text-gray-500 text-sm"
                    >
                      <span onClick={this.props.closeModal}>
                        View Templates List
                      </span>
                    </Link>
                  </div>
                  <div>
                    <MultiSelect
                      type="select message template"
                      placeholder="Select Message Template"
                      items={this.state.templateList.map(template => ({
                        ...template,
                        _id: template._id,
                        name: template.name
                      }))}
                      selected={{ name: this.state.selectedTemplate.name }}
                      onChange={this.handleTemplateChange}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor="message"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Message Preview
                      </label>
                    </div>
                    {this.props.messageType.toLowerCase() === "email" ? (
                      <div className="mb-4">
                        <textarea
                          id="emailSubject"
                          name="emailSubject"
                          rows={1}
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                          placeholder="Select Email Template to see preview here"
                          value={this.state.selectedTemplate.emailSubject}
                          readOnly
                        />
                        <ReactQuill
                          theme="snow"
                          modules={modules}
                          defaultValue={""}
                          value={decodeHtml(
                            this.state.selectedTemplate.emailHtml
                          )}
                          placeholder="Select Email Template to see preview here"
                          readOnly
                          className="h-[8rem] mb-20"
                        />
                      </div>
                    ) : (
                      <textarea
                        id="message"
                        name="message"
                        rows={6}
                        readOnly
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="Select Message Template to see preview here"
                        value={this.state.selectedTemplate.template}
                      />
                    )}
                    {this.props.messageType === "SMS" && (
                      <div
                        className={`text-xs ${
                          this.state.selectedTemplate.template.length > 0
                            ? "visible"
                            : "invisible"
                        }`}
                      >
                        <span className="text-gray-600">
                          {this.state.selectedTemplate.template.length}/
                          {this.props.maxCharCount *
                            Math.ceil(
                              this.state.selectedTemplate.template.length /
                                this.props.maxCharCount
                            )}
                        </span>{" "}
                        <span className="text-gray-800">
                          Count as{" "}
                          {Math.ceil(
                            this.state.selectedTemplate.template.length /
                              this.props.maxCharCount
                          )}{" "}
                          SMS ({this.props.maxCharCount} is character limit per
                          sms)
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="w-fit ml-auto flex items-center gap-4">
                    <Button
                      name="Cancel"
                      onClick={this.props.closeModal}
                      className="w-full px-4 inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-100 focus:bg-gray-50  focus:outline-none sm:text-sm"
                    />
                    <Button name="Select" onClick={this.selectTemplate} />
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

export default compose(
  connector,
  withRouter
)(TemplateSelectorModal) as React.ComponentType<Props>;
