import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { connect, ConnectedProps } from "react-redux";
import agent from "../../agent";
import Icon from "../../components/Icon";
import MultiSelect from "../../components/MultiSelect";
import MultiSelectCheckbox from "../../components/MultiSelectCheckbox";
import capitalize from "../../helpers/capitalize";
import { ADD_NOTIFICATION } from "../../store/types";

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const actionType = [
  { name: "Change Status", value: "STATUS" },
  { name: "Change Due Date", value: "DUEDATE" },
  { name: "Change User", value: "USER" },
  { name: "Delete Task", value: "DELETE" }
];

const userType = [
  { name: "Currently Working", value: "CURRENTLY_WORKING" },
  { name: "Other User", value: "OTHER_USER" }
];

const userActionType = [
  { name: "Add User", value: "ADD_USER" },
  { name: "Replace User", value: "REPLACE_USER" },
  { name: "Remove User", value: "REMOVE_USER" }
];

type Props = {
  state: any;
  bulkActionModalSetOpen: (open: boolean) => void;
  onLoad: () => void;
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class BulkActionModal extends React.Component<Props, PropsFromRedux> {
  state: {
    logging: boolean;
    type: any;
    dueDate: string;
    status: any;
    selectedStatus: any;
    selectedUserType: any;
    selectedUserAction: any;
    selectedOldUser: any;
    selectedUser: any;
    selecteOtherOldUser: any;
    selectedOtherUser: any;
  };

  constructor(props: any) {
    super(props);

    this.state = {
      logging: false,
      type: null,
      dueDate: "",
      status: (this.props as any)?.status || [],
      selectedStatus: null,
      selectedUserType: null,
      selectedUserAction: null,
      selectedOldUser: null,
      selectedUser: null,
      selecteOtherOldUser: [],
      selectedOtherUser: []
    };
  }

  onKeyUpFunction = (event: any) => {
    if (event.keyCode === 27) {
      this.setOpen(false);
    }

    if (event.keyCode === 13) {
      this.bulkActions();
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyUpFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyUpFunction, false);
  }

  deleteTasks = () => {
    const workSpaceId = (this.props as any)?.currentFirm._id;
    const taskIds = this.props.state.selectedTasks.map((item: any) => item._id);
    this.setState({ logging: true });
    agent.Tasks.deleteTask(workSpaceId, taskIds)
      .then(() => {
        (this.props as any).addNotification(
          "Success!",
          "Tasks Deleted Successfully.",
          "success"
        );
        this.setState({ logging: false });
        this.setOpen(false);
        this.onLoad();
      })
      .catch((err: any) => {
        this.setState({ logging: false });
        (this.props as any).addNotification(
          "Error",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  changeTaskDueDate = () => {
    const workSpaceId = (this.props as any)?.currentFirm._id;
    const taskIds = this.props.state.selectedTasks.map((item: any) => item._id);
    const dueDate = this.state.dueDate
      ? new Date(this.state.dueDate).toISOString()
      : "";

    // console.log({ dueDate, taskIds, tasks: this.props.state.selectedTasks });
    if (dueDate) {
      this.setState({ logging: true });
      agent.Tasks.changeDueDate(workSpaceId, taskIds, dueDate)
        .then(() => {
          (this.props as any).addNotification(
            "Success!",
            "Tasks Due Date Changed Successfully.",
            "success"
          );
          this.setState({ logging: false });
          this.setOpen(false);
          this.onLoad();
        })
        .catch((err: any) => {
          // console.log({ err });
          this.setState({ logging: false });
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    } else {
      if (!dueDate) {
        (this.props as any).addNotification(
          "Error",
          "Please select due date.",
          "danger"
        );
      }
    }
  };

  changeTaskStatus = () => {
    const workSpaceId = (this.props as any)?.currentFirm._id;
    const taskIds = this.props.state.selectedTasks.map((item: any) => item._id);
    const statusId = this.state.selectedStatus?._id;
    if (statusId) {
      this.setState({ logging: true });
      agent.Tasks.changeStatus(workSpaceId, taskIds, statusId)
        .then((response: any) => {
          const tasksNotCompletedIds = response?.tasksNotCompletedIds;
          if (tasksNotCompletedIds?.length > 0) {
            const selectedTasks = this.props.state.selectedTasks.filter(
              (task: any) => tasksNotCompletedIds.indexOf(task._id) > -1
            );

            (this.props as any).addNotification(
              "Could not change task status",
              `Task(s) ${selectedTasks?.map(
                (task: any) => task.name
              )} could not be changed to ${this.state.selectedStatus?.name}`,
              "danger"
            );
          } else {
            (this.props as any).addNotification(
              "Task status changed successfully",
              "All selected tasks status changed successfully.",
              "success"
            );
          }
          this.setState({ logging: false });
          this.setOpen(false);
          this.onLoad();
        })
        .catch((err: any) => {
          this.setState({ logging: false });
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    } else {
      if (!statusId) {
        (this.props as any).addNotification(
          "Error",
          "Please select Status.",
          "danger"
        );
      }
    }
  };

  changeCurrentlyWorkingUser = () => {
    const { selectedUser, selectedUserAction, selectedOldUser } = this.state;
    const workSpaceId = (this.props as any)?.currentFirm._id;
    const taskIds = this.props.state.selectedTasks.map((item: any) => item._id);
    const currentUserId =
      selectedUserAction?.value === "REMOVE_USER" ? "" : selectedUser?._id;
    const isSameUser =
      selectedUserAction?.value === "REPLACE_USER" &&
      selectedUser?._id === selectedOldUser?._id
        ? false
        : true;

    const changeType =
      selectedUserAction?.value === "ADD_USER"
        ? "add"
        : selectedUserAction?.value === "REPLACE_USER"
        ? "replace"
        : selectedUserAction?.value === "REMOVE_USER"
        ? "remove"
        : "";

    const presentUserId =
      selectedUserAction?.value === "REPLACE_USER"
        ? { presentUserId: selectedOldUser?._id }
        : {};

    const actions = { changeType, ...presentUserId };
    if (
      currentUserId !== null &&
      selectedUserAction &&
      selectedUser &&
      isSameUser
    ) {
      this.setState({ logging: true });
      agent.Tasks.changeCurrentUser(
        workSpaceId,
        taskIds,
        currentUserId,
        actions
      )
        .then(() => {
          (this.props as any).addNotification(
            "Success!",
            "Tasks Working User Changed Successfully.",
            "success"
          );
          this.setState({ logging: false });
          this.setOpen(false);
          this.onLoad();
        })
        .catch((err: any) => {
          this.setState({ logging: false });
          // console.log({ err });
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.error || err?.error || err,
            "danger"
          );
        });
    } else {
      if (!currentUserId === null) {
        (this.props as any).addNotification(
          "Error",
          "Please select a User.",
          "danger"
        );
      } else if (!selectedUserAction) {
        (this.props as any).addNotification(
          "Error",
          "Please select user action.",
          "danger"
        );
      } else if (!selectedUser) {
        (this.props as any).addNotification(
          "Error",
          "Please select a User.",
          "danger"
        );
      } else if (!isSameUser) {
        (this.props as any).addNotification(
          "Error",
          "Please select different Users.",
          "danger"
        );
      }
    }
  };

  changeOtherUser = () => {
    const { selectedOtherUser, selectedUserAction, selecteOtherOldUser } =
      this.state;
    const workSpaceId = (this.props as any)?.currentFirm._id;
    const taskIds = this.props.state.selectedTasks.map((item: any) => item._id);
    const userIds = selectedOtherUser?.map((user: any) => user._id);
    const updateType =
      selectedUserAction?.value === "ADD_USER"
        ? "add"
        : selectedUserAction?.value === "REPLACE_USER"
        ? "replace"
        : selectedUserAction?.value === "REMOVE_USER" && "remove";

    const replaceUserIds =
      selectedUserAction?.value === "REPLACE_USER"
        ? { replaceUserIds: selecteOtherOldUser?.map((user: any) => user._id) }
        : {};
    const actions = { updateType, ...replaceUserIds };

    this.setState({ logging: true });
    agent.Tasks.updateTaskUsers(workSpaceId, taskIds, userIds, actions)
      .then(() => {
        (this.props as any).addNotification(
          "Success!",
          "Tasks Users Changed Successfully.",
          "success"
        );
        this.setState({ logging: false });
        this.setOpen(false);
        this.onLoad();
      })
      .catch((err: any) => {
        this.setState({ logging: false });
        // console.log({ err });
        (this.props as any).addNotification(
          "Error",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  userActions = () => {
    if (this.state.selectedUserType === null) {
      (this.props as any).addNotification(
        "Error",
        "Please select user type.",
        "danger"
      );
    } else if (this.state.selectedUserAction === null) {
      (this.props as any).addNotification(
        "Error",
        "Please select type of change.",
        "danger"
      );
    } else {
      switch (this.state.selectedUserType?.value) {
        case "CURRENTLY_WORKING":
          return this.changeCurrentlyWorkingUser();
        case "OTHER_USER":
          return this.changeOtherUser();
      }
    }
  };

  bulkActions = () => {
    if (this.state.type === null) {
      (this.props as any).addNotification(
        "Error",
        "Please select action type.",
        "danger"
      );
    } else {
      switch (this.state.type?.value) {
        case "DELETE":
          return this.deleteTasks();
        case "DUEDATE":
          return this.changeTaskDueDate();
        case "STATUS":
          return this.changeTaskStatus();
        case "USER":
          return this.userActions();
        default:
          return;
      }
    }
  };

  onLoad = () => {
    (this.props as any).onLoad();
  };

  setOpen = (open: boolean) => {
    (this.props as any).bulkActionModalSetOpen(open);
  };

  updateState = (field: string) => (ev: any) => {
    this.setState({
      [field]: capitalize(ev.target.value)
    });
  };

  onTypeChange = (item: any) => {
    this.setState({
      type: item,
      selectedStatus: null,
      selectedUserType: null,
      selectedUserAction: null,
      selectedOldUser: null,
      selectedUser: null,
      selecteOtherOldUser: [],
      selectedOtherUser: []
    });
  };

  onStatusChange = (item: any) => {
    this.setState({
      selectedStatus: item,
      selectedUserType: null,
      selectedUserAction: null,
      selectedOldUser: null,
      selectedUser: null,
      selecteOtherOldUser: [],
      selectedOtherUser: []
    });
  };

  onUserTypeChange = (item: any) => {
    this.setState({
      selectedUserType: item,
      selectedUserAction: null,
      selectedOldUser: null,
      selectedUser: null,
      selecteOtherOldUser: [],
      selectedOtherUser: []
    });
  };

  onUserActionChange = (item: any) => {
    this.setState({
      selectedUserAction: item,
      selectedOldUser: null,
      selectedUser: null,
      selecteOtherOldUser: [],
      selectedOtherUser: []
    });
  };

  onOldUserChange = (user: any) => {
    this.setState({
      selectedOldUser: user,
      selecteOtherOldUser: [],
      selectedOtherUser: []
    });
  };

  onUserChange = (user: any) => {
    this.setState({
      selectedUser: user,
      selecteOtherOldUser: [],
      selectedOtherUser: []
    });
  };

  onOtherOldUserChange = (user: any) => {
    const { selecteOtherOldUser } = this.state;
    const index = selecteOtherOldUser.findIndex(
      (item: any) => item?._id === user?._id
    );
    if (index === -1) {
      this.setState({
        selecteOtherOldUser: [...selecteOtherOldUser, user]
      });
    } else {
      const updatedUsers = selecteOtherOldUser.filter(
        (item: any) => item._id !== user._id
      );
      this.setState({ selecteOtherOldUser: updatedUsers });
    }
  };

  onOtherUserChange = (user: any) => {
    const { selectedOtherUser } = this.state;
    const index = selectedOtherUser.findIndex(
      (item: any) => item?._id === user?._id
    );
    if (index === -1) {
      this.setState({
        selectedOtherUser: [...selectedOtherUser, user]
      });
    } else {
      const updatedUsers = selectedOtherUser.filter(
        (item: any) => item._id !== user._id
      );
      this.setState({ selectedOtherUser: updatedUsers });
    }
  };

  render() {
    return (
      <>
        <Transition.Root
          show={(this.props as any)?.state?.showBulkActionModal}
          as={Fragment}
        >
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={() => null}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div>
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Bulk Action
                      </h3>
                    </div>
                    <div>
                      {/* <form onSubmit={(e) => e.preventDefault()}> */}
                      <div className="mt-4">
                        <div className="mb-4">
                          <label
                            htmlFor="company_website"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Type of Bulk Action
                            <span className="text-red-600">*</span>
                          </label>
                          <div className="mt-1">
                            <MultiSelect
                              items={actionType?.map((type: any) => {
                                return {
                                  ...type,
                                  _id: type.name,
                                  name: type.name
                                };
                              })}
                              type="bulAction"
                              selected={{
                                name: this.state.type?.name
                              }}
                              onChange={this.onTypeChange}
                              placeholder="Select Type of Bulk Action"
                            />
                          </div>
                        </div>
                        {this.state.type?.value === "STATUS" && (
                          <div className="mb-4">
                            <label
                              htmlFor="comment"
                              className="block text-sm font-medium text-gray-700"
                            >
                              New Status
                            </label>
                            <div className="mt-1">
                              <MultiSelect
                                items={this.state.status?.map((item: any) => {
                                  const gstClients =
                                    this.props.state.selectedTasks.filter(
                                      (task: any) =>
                                        task?.type === "GST" &&
                                        task.name !== "PMT-06"
                                    );
                                  if (
                                    gstClients.length > 0 &&
                                    item.name.toLowerCase() !== "completed"
                                  ) {
                                    return {
                                      ...item,
                                      _id: item._id,
                                      name: item.name
                                    };
                                  } else if (gstClients.length === 0) {
                                    return {
                                      ...item,
                                      _id: item._id,
                                      name: item.name
                                    };
                                  }
                                  return [];
                                })}
                                type="status"
                                selected={{
                                  name: this.state.selectedStatus?.name
                                }}
                                onChange={this.onStatusChange}
                                placeholder="Select New Status"
                              />
                            </div>
                          </div>
                        )}

                        {this.state.type?.value === "DUEDATE" && (
                          <div className="mb-4">
                            <label
                              htmlFor="dueDate"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Select New Due Date
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="date"
                                name="dueDate"
                                value={this.state.dueDate}
                                onChange={this.updateState("dueDate")}
                                id="person_birth"
                                className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                                placeholder="Birth Date"
                              />
                            </div>
                          </div>
                        )}

                        {this.state.type?.value === "USER" && (
                          <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">
                              User Type
                            </label>
                            <div className="mt-1">
                              <MultiSelect
                                items={userType?.map((item: any) => {
                                  return {
                                    ...item,
                                    _id: item.name,
                                    name: item.name
                                  };
                                })}
                                type="usertype"
                                selected={{
                                  name: this.state.selectedUserType?.name
                                }}
                                onChange={this.onUserTypeChange}
                                placeholder="Select User Type"
                              />
                            </div>
                          </div>
                        )}

                        {this.state.selectedUserType !== null && (
                          <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">
                              Type of User Change
                            </label>
                            <div className="mt-1">
                              <MultiSelect
                                items={userActionType?.map((item: any) => {
                                  return {
                                    ...item,
                                    _id: item.name,
                                    name: item.name
                                  };
                                })}
                                type="usertypechange"
                                selected={{
                                  name: this.state.selectedUserAction?.name
                                }}
                                onChange={this.onUserActionChange}
                                placeholder="Select Type of User Change"
                              />
                            </div>
                          </div>
                        )}

                        {this.state.selectedUserType?.value ===
                          "CURRENTLY_WORKING" &&
                          this.state.selectedUserAction?.value ===
                            "REPLACE_USER" && (
                            <div className="mb-4">
                              <label className="block text-sm font-medium text-gray-700">
                                Select Old User
                              </label>
                              <div className="mt-1">
                                <MultiSelect
                                  items={(this.props as any)?.users?.map(
                                    (item: any) => {
                                      return {
                                        ...item,
                                        _id: item._id,
                                        name: item.name
                                      };
                                    }
                                  )}
                                  type="alluser"
                                  selected={{
                                    name: this.state.selectedOldUser?.name
                                  }}
                                  onChange={this.onOldUserChange}
                                  placeholder="Select Old User"
                                />
                              </div>
                            </div>
                          )}

                        {this.state.selectedUserType?.value ===
                          "CURRENTLY_WORKING" &&
                          this.state.selectedUserAction !== null && (
                            <div className="mb-4">
                              <label className="block text-sm font-medium text-gray-700">
                                {this.state.selectedUserAction?.value ===
                                "REMOVE_USER"
                                  ? "Select User"
                                  : "Select New User"}
                              </label>
                              <div className="mt-1">
                                <MultiSelect
                                  items={(this.props as any)?.users?.map(
                                    (item: any) => {
                                      return {
                                        ...item,
                                        _id: item._id,
                                        name: item.name
                                      };
                                    }
                                  )}
                                  type="newuser"
                                  selected={{
                                    name: this.state.selectedUser?.name
                                  }}
                                  onChange={this.onUserChange}
                                  placeholder={
                                    this.state.selectedUserAction?.value ===
                                    "REMOVE_USER"
                                      ? "Select User"
                                      : "Select New User"
                                  }
                                />
                              </div>
                            </div>
                          )}

                        {this.state.selectedUserType?.value ===
                          "CURRENTLY_WORKING" &&
                          this.state.selectedUserAction?.value ===
                            "ADD_USER" && (
                            <p className="block text-sm font-medium text-red-700">
                              Note: Use this option to add new currently working
                              user to the selected task. This option will work
                              only when the selected task does not have any
                              currently working user.
                            </p>
                          )}

                        {this.state.selectedUserType?.value ===
                          "CURRENTLY_WORKING" &&
                          this.state.selectedUserAction?.value ===
                            "REPLACE_USER" && (
                            <p className="block text-sm font-medium text-red-700">
                              Note: Use this option to replace currently working
                              user from the selected task.
                            </p>
                          )}

                        {this.state.selectedUserType?.value ===
                          "CURRENTLY_WORKING" &&
                          this.state.selectedUserAction?.value ===
                            "REMOVE_USER" && (
                            <p className="block text-sm font-medium text-red-700">
                              Note: Use this option to remove currently working
                              user from the selected task.
                            </p>
                          )}

                        {this.state.selectedUserType?.value === "OTHER_USER" &&
                          this.state.selectedUserAction?.value ===
                            "REPLACE_USER" && (
                            <div className="mb-4">
                              <label
                                htmlFor="company_website"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Select Old User
                              </label>
                              <MultiSelectCheckbox
                                items={(this.props as any)?.users?.map(
                                  (user: any) => ({
                                    ...user,
                                    _id: user._id,
                                    name: user.name
                                  })
                                )}
                                selected={this.state.selecteOtherOldUser}
                                type="users"
                                onChange={this.onOtherOldUserChange}
                                placeholder={"Select Old User"}
                              />
                            </div>
                          )}

                        {this.state.selectedUserType?.value === "OTHER_USER" &&
                          this.state.selectedUserAction !== null && (
                            <div className="mb-4">
                              <label
                                htmlFor="company_website"
                                className="block text-sm font-medium text-gray-700"
                              >
                                {this.state.selectedUserAction?.value ===
                                "REMOVE_USER"
                                  ? "Select User"
                                  : "Select New User"}
                              </label>
                              <MultiSelectCheckbox
                                items={(this.props as any)?.users?.map(
                                  (user: any) => ({
                                    ...user,
                                    _id: user._id,
                                    name: user.name
                                  })
                                )}
                                selected={this.state.selectedOtherUser}
                                type="users"
                                onChange={this.onOtherUserChange}
                                placeholder={
                                  this.state.selectedUserAction?.value ===
                                  "REMOVE_USER"
                                    ? "Select User"
                                    : "Select New User"
                                }
                              />
                            </div>
                          )}

                        {this.state.selectedUserType?.value === "OTHER_USER" &&
                          this.state.selectedUserAction?.value ===
                            "ADD_USER" && (
                            <p className="block text-sm font-medium text-red-700">
                              Note: Use this option to add new user to the
                              selected task.
                            </p>
                          )}

                        {this.state.selectedUserType?.value === "OTHER_USER" &&
                          this.state.selectedUserAction?.value ===
                            "REPLACE_USER" && (
                            <p className="block text-sm font-medium text-red-700">
                              Note: Use this option to remove the allocated user
                              to the selected task and allocate new user to the
                              selected task.
                            </p>
                          )}

                        {this.state.selectedUserType?.value === "OTHER_USER" &&
                          this.state.selectedUserAction?.value ===
                            "REMOVE_USER" && (
                            <p className="block text-sm font-medium text-red-700">
                              Note: Use this option to remove currently working
                              user from the selected task. Make sure at least
                              one user is remain allocated to the task.
                            </p>
                          )}

                        {this.state.type?.value === "DELETE" && (
                          <p className="block text-sm font-medium text-gray-700">
                            Are you sure you want to delete the selected task?
                          </p>
                        )}
                      </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:justify-end">
                        <button
                          type="button"
                          className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:w-32 sm:text-sm"
                          onClick={() => this.setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          disabled={this.state.logging}
                          className={`mt-3 sm:ml-4 w-full inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 text-base font-medium text-white focus:outline-none sm:mt-0 sm:w-32 sm:text-sm ${
                            this.state.type?.value === "DELETE"
                              ? "hover:bg-red-700 bg-red-600"
                              : "hover:bg-indigo-700 bg-indigo-600"
                          }`}
                          onClick={this.bulkActions}
                        >
                          <span className="w-full flex justify-end">
                            {this.state.logging ? (
                              <Icon name="loading" className="mr-2" />
                            ) : null}
                          </span>
                          <span className="whitespace-nowrap">
                            {this.state.type?.value === "DELETE"
                              ? "Delete"
                              : "Update"}
                          </span>
                          <span className="w-full"></span>
                        </button>
                      </div>
                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

export default connector(BulkActionModal);
