import { Dialog, Transition } from "@headlessui/react";
import React, { ChangeEvent, Fragment } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ADD_NOTIFICATION } from "../../store/types";
import Icon from "../../components/Icon";
import agent from "../../agent";

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props {
  onLoad: (forSearch: boolean) => void;
  editModalSetOpen?: (open: boolean) => void;
  state: {
    showEditModal: boolean;
    selectedRow: {
      _id: string;
      comment: string;
    };
  };
}

interface State {
  loading: boolean;
  comment: string;
}

class EditCommentModal extends React.Component<Props & PropsFromRedux, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      comment: this.props.state.selectedRow.comment
    };
  }

  onKeyUpFunction = (event: any) => {
    if (event.keyCode === 27) {
      this.setOpen(false);
    }

    if (event.keyCode === 13) {
      this.editComment();
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyUpFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyUpFunction, false);
  }

  setOpen = (open: boolean) => {
    (this.props as any).editModalSetOpen(open);
  };

  onLoad = () => {
    (this.props as any).onLoad();
  };

  handleCommentChange = (ev: ChangeEvent<HTMLTextAreaElement>) =>
    this.setState({ comment: ev.target.value });

  editComment = () => {
    const { _id } = this.props.state.selectedRow;
    const { comment } = this.state;
    const workSpaceId = (this.props as any)?.currentFirm._id;
    if (_id && comment) {
      this.setState({ loading: true });
      agent.TimeTracking.editComment(workSpaceId, _id, comment)
        .then((res: any) => {
          this.onLoad();
          this.setOpen(false);
          (this.props as any).addNotification(
            "Comment edited",
            "Comment edited successfully",
            "success"
          );
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          (this.props as any).addNotification(
            "Could not save comment",
            typeof err?.response?.data?.message === "object"
              ? "Something went wrong"
              : err?.response?.data?.message ||
                  err?.response?.data?.error ||
                  err?.message,
            "error"
          );
        });
    } else {
      (this.props as any).addNotification(
        "Comment cannot be empty",
        "Please enter a comment",
        "error"
      );
    }
  };

  render() {
    return (
      <>
        <Transition.Root
          show={(this.props as any).state.showEditModal}
          as={Fragment}
          appear
        >
          <Dialog
            as="div"
            className="fixed z-40 inset-0 overflow-y-auto"
            onClose={() => null}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div>
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Edit Tag
                      </h3>
                    </div>
                    <div>
                      <form onSubmit={e => e.preventDefault()}>
                        <div className="mt-4">
                          <div>
                            <label
                              htmlFor="comment"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Comment
                            </label>
                            <div className="mt-1">
                              <textarea
                                rows={4}
                                name="comment"
                                id="comment"
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                value={this.state.comment}
                                onChange={this.handleCommentChange}
                                placeholder="Edit comment"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="mt-5 sm:mt-4 sm:flex sm:justify-end">
                          <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:w-32 sm:text-sm"
                            onClick={() => this.setOpen(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            disabled={this.state.loading}
                            className={
                              "mt-3 sm:ml-4 w-full inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:mt-0 sm:w-32 sm:text-sm"
                            }
                            onClick={this.editComment}
                          >
                            <span className="w-full flex justify-end">
                              {this.state.loading ? (
                                <Icon name="loading" className="mr-2" />
                              ) : null}
                            </span>
                            <span>Update</span>
                            <span className="w-full"></span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

export default connector(EditCommentModal);
