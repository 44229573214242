import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

type ShowHidePasswordProps = {
  showPassword: boolean;
  onClick: () => void;
};

const ShowHidePassword = ({ showPassword, onClick }: ShowHidePasswordProps) => {
  return (
    <div className="absolute z-10 right-2">
      <button type="button" onClick={onClick} className="py-1">
        {showPassword ? (
          <>
            <span className="sr-only">Hide Password</span>
            <EyeIcon className="w-5" />
          </>
        ) : (
          <>
            <span className="sr-only">Show Password</span>
            <EyeSlashIcon className="w-5" />
          </>
        )}
      </button>
    </div>
  );
};

export default ShowHidePassword;
