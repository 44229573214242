import React, { Fragment } from "react";
import { connect, ConnectedProps } from "react-redux";
import agent from "../../agent";
import Dashboard, { getAllTagsList } from "../../components/Dashboard";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import TagManager from "react-gtm-module";
import Icon from "../../components/Icon";
import MultiSelectCheckbox from "../../components/MultiSelectCheckbox";
import { withRouter, WithRouterProps } from "../../helpers/withRouter";
import { compose } from "redux";
import AddCustomField from "../CustomField/Add";
import { Menu, Transition } from "@headlessui/react";
import MultiSelect from "../../components/MultiSelect";
import AddPerson from "../ContactPerson/Add";
import EditPersonModal from "../ContactPerson/Edit";
import AddClientGroups from "../Groups/Add";
import EditGroupModal from "../Groups/Edit";
import AddTag from "../Tags/Add";
import { formatDateAndTime } from "../../helpers/formatDate";
import validGSTIN from "../../helpers/GSTValidationFunction";
import { validPAN, validTAN } from "../../helpers/regex";
import {
  clientTypesList,
  designationList,
  itrReturns,
  advanceTaxReturns,
  returnFileList,
  ReturnsType
} from "../../constants/clients";
import ValidateGSTIN from "./ValidateGSTIN";
import Popup from "../../components/Popup";
import { AppDispatch, RootState } from "../../store";
import { User } from "../../helpers/types";
import { CommonAction } from "../../store/reducers/common";
import { NotifyType } from "../../store/reducers/notification";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Add Client"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateCommon: (payload: CommonAction["payload"]) =>
    dispatch({ type: UPDATE_COMMON, payload }),
  onNotify: (title: string, message: string, type: NotifyType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type ClientType = (typeof clientTypesList)[number];

interface Props extends Partial<PropsFromRedux & WithRouterProps> {
  users: User[];
  tags: any;
}

// Helper function to check if advance tax is applicable
export const isAdvanceTaxApplicable = (
  item: ReturnsType,
  type: ClientType | ""
) => {
  if (item !== "Advance Tax - One Instalment") return false;
  const applicableTypes = ["Individual", "Hindu Undivided Family", "Firm", ""];
  return !applicableTypes.includes(type);
};

//Main Classs
class AddClient extends React.Component<Props> {
  state: {
    logging: boolean;
    taxesApplicable: any;
    showGstinModal: boolean;
    gstLoading: boolean;
    govtGstIsPresent: boolean;
    tab: string;
    type: { name: ClientType; _id: ClientType } | null;
    name: string;
    tradeName: string;
    defUsers: any;
    showTagModal: boolean;
    tags: any;
    notes: string;
    dob: string;
    companyCIN: string;
    LLP_RegNo: string;
    companyINC_Date: string;
    fileNo: string;
    gstin: string;
    typingTimeout: number;
    qrmpPreferance: any;
    gstUsername: string;
    gstPassword: string;
    inputGstType: string;
    iconType_gst: string;
    eWayBillUsername: string;
    eWayPassword: string;
    iconType_bill: string;
    inputBillType: string;
    gstinStatus: string;
    registrationType: string;
    regDate: string;
    canDate: string;
    registeredAddress: string;
    showAdditionalBussiness: boolean;
    additionalPlaceOfBussiness: any;
    stateJurisdiction: string;
    centralJurisdiction: string;
    filedITreturns: ReturnsType[];
    pan: string;
    itPortalPassword: string;
    inputPortalType: string;
    iconType_portal: string;
    tracesUsername: string;
    inputTraceType: string;
    tracesPassword: string;
    iconType_trace: string;
    inputTraceTaxpayerType: string;
    tracesTaxPayerPassword: string;
    iconType_traceTaxpayer: string;
    tan: string;
    inputTanType: string;
    iconType_tan: string;
    tanPassword: string;
    mcaV2Username: string;
    inputMcaV2Type: string;
    mcaV2Password: string;
    iconType_mcaV2: string;
    mcaV3Username: string;
    inputMcaV3Type: string;
    mcaV3Password: string;
    iconType_mcaV3: string;
    dgftUsername: string;
    inputDgftType: string;
    dgftPassword: string;
    iconType_dgft: string;
    customFields: any;
    showFieldModal: boolean;
    contactPerson: any;
    updatingSelectedPerson: boolean;
    showBackDrop: boolean;
    posX: any;
    posY: any;
    showPersonForm: boolean;
    personList: any;
    selectedPerson: any;
    designation: any;
    isPrimary: boolean;
    showPersonModal: boolean;
    showEditPersonModal: boolean;
    groupList: any;
    showGroupForm: boolean;
    showEditGroupModal: boolean;
    selectedGroup: any;
    desc: string;
    showGroupModal: boolean;
    clientGroup: any;
    showAllCustomField: boolean;
    originalCustomField: any;
    searchCustomField: string;
    showRequiredFieldsOnly: boolean;
  };

  constructor(props: any) {
    super(props);

    this.state = {
      logging: false,
      taxesApplicable: ["income-tax", "other"],
      showGstinModal: false,
      gstLoading: false,
      govtGstIsPresent: false,
      tab: "basic",
      type: null,
      name: "",
      tradeName: "",
      defUsers: [],
      showTagModal: false,
      tags: [],
      notes: "",
      dob: "",
      companyCIN: "",
      LLP_RegNo: "",
      companyINC_Date: "",
      fileNo: "",
      gstin: "",
      typingTimeout: 0,
      qrmpPreferance: [],
      gstUsername: "",
      gstPassword: "",
      inputGstType: "password",
      iconType_gst: "eye-open",
      eWayBillUsername: "",
      eWayPassword: "",
      iconType_bill: "eye-open",
      inputBillType: "password",
      gstinStatus: "",
      registrationType: "",
      regDate: "",
      canDate: "",
      registeredAddress: "",
      showAdditionalBussiness: false,
      additionalPlaceOfBussiness: [],
      stateJurisdiction: "",
      centralJurisdiction: "",
      filedITreturns: [],
      pan: "",
      itPortalPassword: "",
      inputPortalType: "password",
      iconType_portal: "eye-open",
      tracesUsername: "",
      inputTraceType: "password",
      tracesPassword: "",
      iconType_trace: "eye-open",
      inputTraceTaxpayerType: "password",
      tracesTaxPayerPassword: "",
      iconType_traceTaxpayer: "eye-open",
      tan: "",
      inputTanType: "password",
      iconType_tan: "eye-open",
      tanPassword: "",
      mcaV2Username: "",
      inputMcaV2Type: "password",
      mcaV2Password: "",
      iconType_mcaV2: "eye-open",
      mcaV3Username: "",
      inputMcaV3Type: "password",
      mcaV3Password: "",
      iconType_mcaV3: "eye-open",
      dgftUsername: "",
      inputDgftType: "password",
      dgftPassword: "",
      iconType_dgft: "eye-open",
      customFields: [],
      showFieldModal: false,
      contactPerson: [],
      updatingSelectedPerson: false,
      showBackDrop: false,
      posX: null,
      posY: null,
      showPersonForm: false,
      personList: [],
      selectedPerson: null,
      designation: null,
      isPrimary: false,
      showPersonModal: false,
      showEditPersonModal: false,
      groupList: [],
      showGroupForm: false,
      showEditGroupModal: false,
      selectedGroup: null,
      desc: "",
      showGroupModal: false,
      clientGroup: [],
      showAllCustomField: true,
      originalCustomField: [],
      searchCustomField: "",
      showRequiredFieldsOnly: false
    };
  }

  toggleRequiredFields = () => {
    this.setState({
      showRequiredFieldsOnly: !this.state.showRequiredFieldsOnly
    });
  };

  onKeyUpFunction = (event: any) => {
    if (
      event.keyCode === 13 &&
      !this.state.showGroupModal &&
      !this.state.showEditGroupModal &&
      !this.state.showPersonModal &&
      !this.state.showEditPersonModal &&
      !this.state.showFieldModal &&
      !this.state.showGstinModal &&
      !this.state.showTagModal
    ) {
      this.addClient();
    }
  };

  getCustomField = () => {
    const workSpaceId = (this.props as any).params?.firmId;
    const active = true;
    const searchText = "";
    const skip = 0;
    const limit = 100000;
    agent.CustomField.getCustomFieldList(
      workSpaceId,
      active,
      searchText,
      limit,
      skip
    )
      .then((response: any) => {
        const filterCustomField = response.customFields.filter(
          (item: any) => item.applicableFor === "client"
        );
        const formatCustomField = filterCustomField.map((item: any) => {
          return {
            customFieldId: item._id,
            customFieldName: item.name,
            customFieldType: item.type,
            stringValue: "",
            description: item.description
          };
        });

        this.setState({
          customFields: formatCustomField,
          originalCustomField: formatCustomField
        });
      })
      .catch((err: any) => {
        (this.props as any).addNotification(
          "Could not load Custom Field Details",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  getPersonsList = () => {
    const workSpaceId = (this.props as any).params?.firmId;
    const searchText = "";
    const active = true;
    const limit = 100000;
    const skip = 0;
    agent.ContactPerson.getPersonList(
      workSpaceId,
      active,
      searchText,
      limit,
      skip
    )
      .then((response: any) => {
        const currentPerson = this.state.contactPerson?.map((item: any) => {
          const person = response.contactPerson.find(
            (person: any) => person._id === item._id
          );
          return { ...item, ...person };
        });
        this.setState({
          personList: response.contactPerson,
          contactPerson: currentPerson
        });
      })
      .catch((err: any) => {
        (this.props as any).onNotify(
          "Could not load Contact Person",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  getGroupList = () => {
    const workSpaceId = (this.props as any).params?.firmId;
    const active = true;
    const searchText = "";
    agent.ClientGroups.getClientGroupList(
      workSpaceId,
      active,
      searchText,
      0,
      100000
    )
      .then((response: any) => {
        const filterGroups = response.groups.filter((group: any) =>
          this.state.clientGroup.some((item: any) => item._id === group._id)
        );
        this.setState({
          groupList: response.groups,
          clientGroup: filterGroups
        });
      })
      .catch((err: any) => {
        (this.props as any).onNotify(
          "Could not load Firm Details",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  componentDidMount() {
    this.getCustomField();
    this.getPersonsList();
    this.getGroupList();
    document.title = "Add Clients - TaxPido PMS";
    document.addEventListener("keydown", this.onKeyUpFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyUpFunction, false);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const prevFirmId = prevProps.params.firmId;
    const currFirmId = (this.props as any).params.firmId;
    if (prevFirmId !== currFirmId) {
      this.getCustomField();
      this.getPersonsList();
      this.getGroupList();
    }

    const prevModal = prevProps.currentModal;
    const currentModal = (this.props as any)?.currentModal;
    if (
      (prevModal?.modalName === "ADD_TAG_MODAL" &&
        prevModal?.modalName !== currentModal?.modalName &&
        currentModal?.modalName === "" &&
        currentModal?.fetchAgain) ||
      (prevState.showTagModal !== this.state.showTagModal &&
        !this.state.showTagModal)
    ) {
      getAllTagsList(
        (this.props as any).currentFirm._id,
        (this.props as any).updateCommon,
        (this.props as any).onNotify
      );
    }
  }

  addClient = () => {
    const workSpaceId = (this.props as any).params?.firmId;
    const {
      taxesApplicable,
      name,
      type,
      tradeName,
      defUsers,
      tags,
      notes,
      dob,
      companyCIN,
      LLP_RegNo,
      companyINC_Date,
      fileNo,
      gstin,
      pan,
      tan,
      tanPassword,
      itPortalPassword,
      tracesUsername,
      tracesPassword,
      tracesTaxPayerPassword,
      mcaV2Username,
      mcaV2Password,
      mcaV3Username,
      mcaV3Password,
      dgftUsername,
      dgftPassword,
      eWayBillUsername,
      eWayPassword,
      gstUsername,
      gstPassword,
      gstinStatus,
      qrmpPreferance,
      registrationType,
      regDate,
      canDate,
      registeredAddress,
      additionalPlaceOfBussiness,
      stateJurisdiction,
      centralJurisdiction,
      filedITreturns,
      customFields,
      contactPerson,
      clientGroup
    } = this.state;
    const checkGSTIN = !taxesApplicable.includes("gst")
      ? true
      : gstin === ""
      ? false
      : true;
    const isgstinValid = !taxesApplicable.includes("gst")
      ? true
      : validGSTIN(gstin);
    const checkPAN = pan === "" ? true : validPAN.test(pan);
    const checkTAN = tan === "" ? true : validTAN.test(tan);
    const checkFiledITreturns = this.verifyFiledITreturns();
    const checkRegDate = !taxesApplicable.includes("gst")
      ? true
      : regDate === ""
      ? false
      : true;

    const isITRPresent = !taxesApplicable.includes("income-tax")
      ? true
      : this.verifyITRreturns();

    const data = {
      name,
      type: type?.name,
      tradeName,
      defUsers: defUsers.map((user: any) => user._id),
      tags: tags.map((tag: any) => tag._id),
      notes,
      dob: dob ? new Date(dob).toISOString() : "",
      companyCIN,
      LLP_RegNo,
      companyINC_Date: companyINC_Date
        ? new Date(companyINC_Date).toISOString()
        : "",
      fileNo,
      status: true,
      gstApplicable: taxesApplicable.includes("gst"),
      itApplicable: taxesApplicable.includes("income-tax"),
      othersApplicable: true,
      gstin,
      pan,
      tan,
      tanPassword,
      itPortalPassword,
      tracesUsername,
      tracesPassword,
      tracesTaxPayerPassword,
      mcaV2Username,
      mcaV2Password,
      mcaV3Username,
      mcaV3Password,
      dgftUsername,
      dgftPassword,
      eWayBillUsername,
      eWayPassword,
      gstUsername,
      gstPassword,
      gstinStatus,
      qrmpPreferance,
      registrationType,
      regDate: regDate ? new Date(regDate).toISOString() : "",
      canDate: canDate ? new Date(canDate).toISOString() : "",
      registeredAddress,
      additionalPlaceOfBussiness,
      stateJurisdiction,
      centralJurisdiction,
      filedITreturns,
      customFields: customFields.map((item: any /* , index: number */) => ({
        customFieldId: item.customFieldId,
        value: item.stringValue
      })),
      contactPerson: contactPerson.map((person: any) => ({
        personId: person._id,
        designation: person.designation ?? "",
        isPrimary: person.isPrimary
      })),
      groupIds: clientGroup.map((group: any) => group._id)
    };

    if (
      (this.state.gstinStatus === "cancelled" ||
        this.state.gstinStatus === "suspended") &&
      this.state.canDate === ""
    ) {
      return this.props.onNotify?.(
        "Cancellation Date is Empty",
        "Cancellation Date in GST Details is required for cancelled or suspended GSTIN",
        "danger"
      );
    }

    if (
      type?.name &&
      name &&
      defUsers.length > 0 &&
      checkGSTIN &&
      isgstinValid &&
      checkRegDate &&
      checkPAN &&
      checkTAN &&
      isITRPresent
    ) {
      if (!checkFiledITreturns && taxesApplicable.includes("income-tax")) {
        (this.props as any).onNotify(
          "Waring",
          "You have not selected TDS return file for this client type",
          "warn"
        );
      }
      this.setState({ logging: true });
      agent.Clients.addClient(workSpaceId, data)
        .then((response: any) => {
          this.setState({ logging: false });
          (this.props as any).onNotify(
            "Client Added Successfully",
            "Client Added Successfully",
            "success"
          );
          (this.props as any).navigate(`/${workSpaceId}/clients/list`);
        })
        .catch((err: any) => {
          this.setState({ logging: false });
          (this.props as any).onNotify(
            "Could not add Client",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );

          // console.log("ADD CLIENT ERROR------>", { err });
        });
    } else {
      if (type === null) {
        (this.props as any).onNotify(
          "Client Type is Empty",
          "Client Type in Basic Details is required",
          "danger"
        );
      } else if (name === "") {
        (this.props as any).onNotify(
          "Name Field is Empty",
          "Name Field in Basic Details is required",
          "danger"
        );
      } else if (defUsers.length === 0) {
        (this.props as any).onNotify(
          "Users is Empty",
          "Users in Basic Details is required",
          "danger"
        );
      } else if (!checkGSTIN) {
        (this.props as any).onNotify(
          "GSTIN is Empty",
          "GSTIN in GST Details is required",
          "danger"
        );
      } else if (!isgstinValid) {
        (this.props as any).onNotify(
          "GSTIN is Invalid",
          "GSTIN in GST Details is Invalid",
          "danger"
        );
      } else if (!checkRegDate) {
        (this.props as any).onNotify(
          "Registration Date is Empty",
          "Registration Date in GST Details is required",
          "danger"
        );
      } else if (!checkPAN) {
        (this.props as any).onNotify(
          "PAN is Invalid",
          "PAN in Income Tax Details is Invalid",
          "danger"
        );
      } else if (!checkTAN) {
        (this.props as any).onNotify(
          "TAN is Invalid",
          "TAN in Income Tax Details is Invalid",
          "danger"
        );
      } else if (!isITRPresent) {
        (this.props as any).onNotify(
          "ITR not selected",
          "Select ITR from file return",
          "danger"
        );
      }
    }
  };

  verifyFiledITreturns = () => {
    const { type, filedITreturns } = this.state;
    if (
      type?.name !== "Hindu Undivided Family" &&
      type?.name !== "Individual"
    ) {
      if (
        filedITreturns.includes("TDS Return - Salary") ||
        filedITreturns.includes("TDS Return - Non Salary") ||
        filedITreturns.includes("TDS Return - Non Resident")
      ) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  };

  verifyITRreturns = () => {
    const { filedITreturns } = this.state;
    if (filedITreturns.length > 0) {
      return true;
    }
    return false;
  };

  //Update the logic so user can add IT client without ITR
  /* verifyITRreturns = () => {
    const { filedITreturns } = this.state;
    if (
      filedITreturns.includes("ITR - Unaudited") ||
      filedITreturns.includes("ITR - Stat Audit") ||
      filedITreturns.includes("ITR - Income Tax Audit")
    ) {
      return true;
    }
    return false;
  }; */

  handleReturnTypeSelection = (returnType: ReturnsType) => {
    const { filedITreturns } = this.state;
    const selectedIndex = filedITreturns.findIndex(item => item === returnType);
    const isItr = itrReturns.findIndex(item => item === returnType) !== -1;
    const isAdvanceTaxReturn =
      advanceTaxReturns.findIndex(item => item === returnType) !== -1;

    if (selectedIndex === -1) {
      if (isItr) {
        const itrReturn = filedITreturns.filter(
          item => !itrReturns.includes(item)
        );
        this.setState({ filedITreturns: [...itrReturn, returnType] });
      } else if (isAdvanceTaxReturn) {
        const advanceTaxReturn = filedITreturns.filter(
          item => !advanceTaxReturns.includes(item)
        );
        this.setState({ filedITreturns: [...advanceTaxReturn, returnType] });
      } else {
        this.setState({ filedITreturns: [...filedITreturns, returnType] });
      }
    } else {
      const removedReturn = filedITreturns.filter(item => item !== returnType);
      this.setState({ filedITreturns: removedReturn });
    }
  };

  showGstinValidationModal = (show: boolean) => {
    this.taxesApplicableHandler("gst");
    this.setState({ showGstinModal: show });
  };

  editGstinValidationModal = (show: boolean) => {
    //this.taxesApplicableHandler("gst");
    this.setState({ showGstinModal: show });
  };
  taxesApplicableHandler = (tax: string) => {
    const isTaxPresent = this.state.taxesApplicable.includes(tax);
    if (isTaxPresent) {
      const removeTax: any = this.state.taxesApplicable.filter(
        (name: string) => name !== tax
      );
      if (
        (this.state.tab === "gst" && tax === "gst") ||
        (this.state.tab === "income-tax" && tax === "income-tax")
      ) {
        this.setState({
          taxesApplicable: removeTax,
          tab: "basic"
        });
      } else {
        this.setState({ taxesApplicable: removeTax });
        if (tax === "gst") {
          this.setState({ govtGstIsPresent: false });
        }
      }
    } else {
      const taxesApplicableList = this.state.taxesApplicable;
      this.setState({ taxesApplicable: [...taxesApplicableList, tax] });
      tax === "income-tax" && this.tabClickHandler(tax);
    }
  };

  tabClickHandler = (tab: string) => {
    this.setState({ tab });
  };

  cancelHandler = () => {
    const firmId = (this.props as any).params?.firmId;
    (this.props as any).navigate(`/${firmId}/clients/list`);
  };

  updateState = (field: string) => (ev: any) => {
    if (field === "pan") {
      this.setState({ pan: ev.target.value.slice(0, 10).toUpperCase() });
    } else if (field === "tan") {
      this.setState({ tan: ev.target.value.slice(0, 10).toUpperCase() });
    } else {
      this.setState({ [field]: ev.target.value });
    }
  };

  onViewPassword = (type: string) => {
    if (type === "gst") {
      if (this.state.inputGstType === "password") {
        this.setState({ inputGstType: "text", iconType_gst: "eye-close" });
      } else {
        this.setState({ inputGstType: "password", iconType_gst: "eye-open" });
      }
    } else if (type === "bill") {
      if (this.state.inputBillType === "password") {
        this.setState({ inputBillType: "text", iconType_bill: "eye-close" });
      } else {
        this.setState({ inputBillType: "password", iconType_bill: "eye-open" });
      }
    } else if (type === "portal") {
      if (this.state.inputPortalType === "password") {
        this.setState({
          inputPortalType: "text",
          iconType_portal: "eye-close"
        });
      } else {
        this.setState({
          inputPortalType: "password",
          iconType_portal: "eye-open"
        });
      }
    } else if (type === "trace") {
      if (this.state.inputTraceType === "password") {
        this.setState({
          inputTraceType: "text",
          iconType_trace: "eye-close"
        });
      } else {
        this.setState({
          inputTraceType: "password",
          iconType_trace: "eye-open"
        });
      }
    } else if (type === "tracesTaxpayer") {
      if (this.state.inputTraceTaxpayerType === "password") {
        this.setState({
          inputTraceTaxpayerType: "text",
          iconType_traceTaxpayer: "eye-close"
        });
      } else {
        this.setState({
          inputTraceTaxpayerType: "password",
          iconType_traceTaxpayer: "eye-open"
        });
      }
    } else if (type === "tan") {
      if (this.state.inputTanType === "password") {
        this.setState({
          inputTanType: "text",
          iconType_tan: "eye-close"
        });
      } else {
        this.setState({
          inputTanType: "password",
          iconType_tan: "eye-open"
        });
      }
    } else if (type === "mcaV2") {
      if (this.state.inputMcaV2Type === "password") {
        this.setState({
          inputMcaV2Type: "text",
          iconType_mcaV2: "eye-close"
        });
      } else {
        this.setState({
          inputMcaV2Type: "password",
          iconType_mcaV2: "eye-open"
        });
      }
    } else if (type === "mcaV3") {
      if (this.state.inputMcaV3Type === "password") {
        this.setState({
          inputMcaV3Type: "text",
          iconType_mcaV3: "eye-close"
        });
      } else {
        this.setState({
          inputMcaV3Type: "password",
          iconType_mcaV3: "eye-open"
        });
      }
    } else if (type === "dgft") {
      if (this.state.inputDgftType === "password") {
        this.setState({
          inputDgftType: "text",
          iconType_dgft: "eye-close"
        });
      } else {
        this.setState({
          inputDgftType: "password",
          iconType_dgft: "eye-open"
        });
      }
    }
  };

  updateCustomField = (customFieldId: string) => (ev: any) => {
    const updatedField = this.state.customFields.map(
      (item: any, index: number) =>
        item.customFieldId === customFieldId
          ? { ...item, stringValue: ev.target.value }
          : item
    );
    const updatedOriginalCustomField = this.state.originalCustomField.map(
      (item: any, index: number) =>
        item.customFieldId === customFieldId
          ? { ...item, stringValue: ev.target.value }
          : item
    );
    this.setState({
      customFields: updatedField,
      originalCustomField: updatedOriginalCustomField
    });
  };

  removeCustomFieldSelection = (customFieldId: string) => {
    const updatedField = this.state.customFields.map((item: any, i: number) =>
      item.customFieldId === customFieldId ? { ...item, stringValue: "" } : item
    );
    const updatedOriginalCustomField = this.state.originalCustomField.map(
      (item: any, i: number) =>
        item.customFieldId === customFieldId
          ? { ...item, stringValue: "" }
          : item
    );
    this.setState({
      customFields: updatedField,
      originalCustomField: updatedOriginalCustomField
    });
  };

  onUserChange = (user: any) => {
    const { defUsers } = this.state;
    const index = defUsers.findIndex((item: any) => item._id === user._id);
    if (index === -1) {
      this.setState({ defUsers: [...defUsers, user] });
    } else {
      const updatedUsers = defUsers.filter(
        (item: any) => item._id !== user._id
      );
      this.setState({ defUsers: updatedUsers });
    }
  };

  onTagChange = (tag: any) => {
    const { tags } = this.state;
    const index = tags.findIndex((item: any) => item._id === tag._id);
    if (index === -1) {
      this.setState({ tags: [...tags, tag] });
    } else {
      const updatedTag = tags.filter((item: any) => item._id !== tag._id);
      this.setState({ tags: updatedTag });
    }
  };

  closeFieldModal = () => {
    this.setState({ showFieldModal: false });
  };

  closePersonModal = () => {
    this.setState({ showPersonModal: false });
  };

  closeGroupModal = () => {
    this.setState({ showGroupModal: false });
  };

  closeTagModal = () => {
    this.setState({ showTagModal: false });
  };

  openModal = (modalType: string) => {
    if (modalType === "customField") {
      this.setState({ showFieldModal: true });
    } else if (modalType === "contactPerson") {
      this.setState({ showPersonModal: true });
    } else if (modalType === "clientGroup") {
      this.setState({ showGroupModal: true });
    } else if (modalType === "tag") {
      this.setState({ showTagModal: true });
    }
  };

  openEditModal = (type: "person" | "group", data: any) => {
    this.setState({ selectedRow: data });
    switch (type) {
      case "person":
        this.editPersonModalSetOpen(true);
        break;
      case "group":
        this.editGroupModalSetOpen(true);
        break;
    }
  };

  editPersonModalSetOpen = (open: boolean) => {
    this.setState({ showEditPersonModal: open });
  };

  editGroupModalSetOpen = (open: boolean) => {
    this.setState({ showEditGroupModal: open });
  };

  customFieldData = (newField: any) => {
    this.setState({
      customFields: [...this.state.customFields, newField],
      originalCustomField: [...this.state.originalCustomField, newField]
    });
  };

  contactPersonData = (newPerson: any) => {
    this.setState({
      personList: [...this.state.personList, newPerson],
      selectedPerson: newPerson
    });
  };

  clientGroupData = (newGroup: any) => {
    this.setState({
      groupList: [...this.state.groupList, newGroup],
      selectedGroup: newGroup
    });
  };

  tagData = (newTag: any) => {
    this.setState({
      tags: [...this.state.tags, newTag]
    });
  };

  onActionClick = (e: any) => {
    const screenClosness = window.innerHeight - e.clientY;
    const positionY = screenClosness < 50 ? e.clientY - 50 : e.clientY;
    this.setState({
      posX: e.clientX,
      posY: positionY,
      showBackDrop: true
    });
  };

  onDropdownClick = () => {
    this.setState({ showBackDrop: false });
  };

  showForm = (formType: string, show: boolean) => {
    if (formType === "person") {
      this.setState({ showPersonForm: show });
    } else if (formType === "group") {
      this.setState({ showGroupForm: show });
    }
  };

  onPersonChange = (person: any) => {
    this.setState({ selectedPerson: person });
  };

  onDesignationChange = (designation: any) => {
    this.setState({ designation });
  };

  onGroupChange = (group: any) => {
    this.setState({ selectedGroup: group });
  };

  //on custom field search text change (without hitting search API)

  handleCustomSearchTextChange = (ev: any) => {
    ev.target.value !== ""
      ? this.setState({
          searchCustomField: ev.target.value,
          customFields: this.state.originalCustomField.filter(
            (item: any) =>
              item.customFieldName
                .toLowerCase()
                .includes(ev.target.value.toLowerCase()) ||
              item.description
                .toLowerCase()
                .includes(ev.target.value.toLowerCase())
          )
        })
      : this.setState({
          searchCustomField: ev.target.value,
          customFields: this.state.originalCustomField
        });
  };

  handleShowAllCustomFields = (ev: any) => {
    this.setState({
      showAllCustomField: true,
      customFields: this.state.originalCustomField
    });
  };

  handleHideBlankCustomFields = (ev: any) => {
    this.setState({
      showAllCustomField: false,
      customFields: this.state.originalCustomField.filter(
        (item: any) => item.stringValue !== ""
      )
    });
  };

  isPrimaryHandler = () => {
    const { contactPerson, isPrimary } = this.state;
    const isPrimaryPresent = contactPerson.findIndex(
      (person: any) => person.isPrimary === true
    );
    if (isPrimaryPresent === -1) {
      this.setState({
        isPrimary: !isPrimary
      });
    } else {
      const updatePerson = contactPerson.map((person: any) =>
        person.isPrimary === true ? { ...person, isPrimary: false } : person
      );
      this.setState({
        contactPerson: updatePerson,
        isPrimary: !isPrimary
      });
    }
  };

  addPerson = () => {
    const { selectedPerson, designation, isPrimary, contactPerson } =
      this.state;
    //if (selectedPerson === null || designation === null) {
    if (selectedPerson === null) {
      if (selectedPerson === null) {
        (this.props as any).onNotify(
          "Person Not Selected",
          "Please select a person",
          "danger"
        );
      } /* else if (designation === null) {
        (this.props as any).onNotify(
          "Designation Not Selected",
          "Please select a designation",
          "danger"
        );
      } */
    } else {
      const index = contactPerson.findIndex(
        (person: any) => person._id === selectedPerson._id
      );

      if (index === -1) {
        const person = {
          ...selectedPerson,
          designation: designation?.name,
          isPrimary
        };
        this.setState({
          updatingSelectedPerson: false,
          contactPerson: [...this.state.contactPerson, person],
          selectedPerson: null,
          designation: null,
          isPrimary: false
        });
      } else {
        (this.props as any).onNotify(
          "Person Already Added.",
          "Please select different person",
          "danger"
        );
      }
    }
  };

  addGroup = () => {
    const { selectedGroup, clientGroup } = this.state;
    if (selectedGroup === null) {
      (this.props as any).onNotify(
        "Group Not Selected",
        "Please select a group",
        "danger"
      );
    } else {
      const index = clientGroup.findIndex(
        (group: any) => group._id === selectedGroup._id
      );
      if (index === -1) {
        this.setState({
          clientGroup: [...this.state.clientGroup, selectedGroup],
          selectedGroup: null
        });
      } else {
        (this.props as any).onNotify(
          "Group Already Added.",
          "Please select different group",
          "danger"
        );
      }
    }
  };

  emailTableContent = (emails: any) => {
    const primaryEmail = emails?.find((email: any) => email.isPrimary === true);
    const visibleEmail = primaryEmail
      ? primaryEmail?.emailId ?? ""
      : emails?.[0]?.emailId ?? "";
    const otherEmailLength =
      emails?.length - 1 > 0 ? `+ ${emails?.length - 1}` : "";
    return { visibleEmail, otherEmailLength };
  };

  mobileTableContent = (mobiles: any) => {
    const primaryMobile = mobiles?.find(
      (mobile: any) => mobile.isPrimary === true
    );
    const visibleNumber = primaryMobile
      ? primaryMobile?.mobileNumber
        ? `${primaryMobile?.mobileNumber}`
        : ""
      : mobiles?.[0]?.mobileNumber
      ? `${mobiles?.[0]?.mobileNumber}`
      : "";
    const otherMobileLength =
      mobiles?.length - 1 > 0 ? `+ ${mobiles?.length - 1}` : "";
    return { visibleNumber, otherMobileLength };
  };

  editSelectedContent = (type: string, index: number) => {
    const { contactPerson } = this.state;
    if (type === "contactPerson") {
      const person = contactPerson[index];
      this.setState({
        showBackDrop: false,
        updatingSelectedPerson: true,
        contactPerson: contactPerson.filter(
          (person: any, i: number) => i !== index
        ),
        selectedPerson: person,
        designation: { name: person.designation ?? "" },
        isPrimary: person.isPrimary
      });
    }
  };

  deleteContentFromTable = (tableType: string, index: number) => {
    const { contactPerson, clientGroup } = this.state;
    if (tableType === "contactPerson") {
      const updatedPerson = contactPerson.filter(
        (person: any, i: number) => i !== index
      );

      this.setState({
        contactPerson: updatedPerson,
        showBackDrop: false
      });
      (this.props as any).onNotify(
        "Person Deleted",
        "Successfully deleted person",
        "success"
      );
    } else if (tableType === "clientGroup") {
      const updatedGroup = clientGroup.filter(
        (group: any, i: number) => i !== index
      );
      this.setState({
        clientGroup: updatedGroup,
        showBackDrop: false
      });
      (this.props as any).onNotify(
        "Group Deleted",
        "Successfully deleted group",
        "success"
      );
    }
  };

  clientTypeChange = (item: { name: ClientType }) => {
    if (
      item.name.endsWith("Company") &&
      this.state.filedITreturns.includes("ITR - Unaudited")
    ) {
      this.setState({
        filedITreturns: this.state.filedITreturns.filter(
          item => item !== "ITR - Unaudited"
        )
      });

      this.props.onNotify?.(
        "Invalid ITR Return Selected",
        "You have setup ITR - Unaudited for this client whereas it should be either ITR - Stat Audit or ITR - Income Tax Audit.",
        "warn"
      );
    }

    this.setState({ type: item });
  };

  handleGstinChange = (ev: any) => {
    this.editGstinValidationModal(true);
  };

  /* handleGstinChange = (ev: any) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      gstin: ev.target.value,
      typingTimeout: setTimeout(() => {
        this.getQRMPData();
        // this.getGSTdetails(ev.target.value);
      }, 700)
    });
  }; */

  toggleAdditionalBusiness = () => {
    this.setState({
      showAdditionalBussiness: !this.state.showAdditionalBussiness
    });
  };

  getQRMPData = () => {
    const workSpaceId = (this.props as any).params.firmId;
    const { gstin } = this.state;
    const isGstinValid = validGSTIN(gstin);
    if (isGstinValid) {
      agent.Clients.getQRMPDetails(workSpaceId, gstin)
        .then((response: any) => {
          this.setState({ qrmpPreferance: response.reverse() });
        })
        .catch((err: any) => {
          (this.props as any).onNotify(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    }
  };

  getGSTdetails = (gstin: string) => {
    const workSpaceId = (this.props as any).params.firmId;
    const isGstinValid = validGSTIN(gstin);
    if (isGstinValid) {
      this.setState({ gstLoading: true });
      agent.Clients.getGSTdetails(workSpaceId, gstin)
        .then((response: any) => {
          const data = response?.data;
          //let clientType = { _id: data?.ctb, name: data?.ctb };
          let clientType = { _id: "", name: "" };
          let newClientTypeList = clientTypesList.map((item: any) =>
            item.toLowerCase()
          );
          if (newClientTypeList.includes(data?.ctb.toLowerCase())) {
            clientType = { _id: data?.ctb, name: data?.ctb };
          } else if (data?.ctb.toLowerCase() === "proprietorship") {
            clientType = { _id: "Individual", name: "Individual" };
          } else if (
            data?.ctb?.toLowerCase() === "public sector undertaking" ||
            data?.ctb?.toLowerCase() === "public sector bank"
          ) {
            clientType = {
              _id: "Public Limited Company",
              name: "Public Limited Company"
            };
          } else if (data?.ctb.toLowerCase() === "partnership") {
            clientType = { _id: "Firm", name: "Firm" };
          }

          this.setState(
            {
              gstLoading: false,
              showGstinModal: false,
              gstin: gstin,
              name: this.formatName(data?.lgnm),
              tradeName: this.formatName(data?.name),
              type: clientType,
              additionalPlaceOfBussiness: data?.apob,
              gstinStatus: data?.status.toLowerCase(),
              registrationType: data?.gstRegType.toLowerCase(),
              regDate: this.formatDate(data?.rgdt),
              canDate: this.formatDate(data?.cxdt),
              stateJurisdiction: data?.stj,
              centralJurisdiction: data?.ctj,
              govtGstIsPresent: clientType.name ? true : false,
              registeredAddress: data?.address
            },
            () => {
              this.getQRMPData();
            }
          );
          if (this.state.taxesApplicable.includes("income-tax")) {
            this.setState({
              pan: gstin.slice(2, 12)
            });
          }
          (this.props as any).onNotify(
            "GSTIN Details",
            "GST details autofetched successfully from GST Server",
            "success"
          );
        })
        .catch((err: any) => {
          this.setState({ gstLoading: false });
          (this.props as any).onNotify(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    }
  };

  formatName = (name: string) => {
    const splitName = name.split(" ").filter(name => name !== "");
    const formatedName = splitName.map(
      (item: string) => item[0].toUpperCase() + item.slice(1).toLowerCase()
    );
    return formatedName.join(" ");
  };

  formatDate = (date: string) => {
    const splitDate = date.split("/");
    return date ? `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}` : "";
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard>
        {this.state.showGstinModal && (
          <ValidateGSTIN
            state={this.state}
            onLoad={this.getGSTdetails}
            showGstinValidationModal={this.showGstinValidationModal}
          />
        )}
        {this.state.showFieldModal && (
          <AddCustomField
            showFieldModal={this.state.showFieldModal}
            closeModal={this.closeFieldModal}
            customFieldData={this.customFieldData}
            from="client"
          />
        )}

        {this.state.showPersonModal && (
          <AddPerson
            showPersonModal={this.state.showPersonModal}
            closeModal={this.closePersonModal}
            contactPersonData={this.contactPersonData}
          />
        )}
        {this.state.showEditPersonModal && (
          <EditPersonModal
            state={{
              ...this.state,
              showEditModal: this.state.showEditPersonModal
            }}
            onLoad={this.getPersonsList}
            editModalSetOpen={this.editPersonModalSetOpen}
          />
        )}

        {this.state.showGroupModal && (
          <AddClientGroups
            showGroupModal={this.state.showGroupModal}
            closeModal={this.closeGroupModal}
            clientGroupData={this.clientGroupData}
          />
        )}
        {this.state.showEditGroupModal && (
          <EditGroupModal
            state={{
              ...this.state,
              showEditModal: this.state.showEditGroupModal
            }}
            onLoad={this.getGroupList}
            editModalSetOpen={this.editGroupModalSetOpen}
          />
        )}

        {this.state.showTagModal && (
          <AddTag
            closeModal={this.closeTagModal}
            showTagModal={this.state.showTagModal}
            tagData={this.tagData}
          />
        )}
        <div className="w-full mx-auto px-4 sm:px-6 md:px-8 gstadd">
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form onSubmit={e => e.preventDefault()}>
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  <div className="flex sm:flex-row flex-col sm:items-start sm:justify-between">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Add Client
                    </h3>
                    <div className="sm:flex mt-2 sm:mt-0">
                      <button
                        type="button"
                        className="w-full whitespace-nowrap inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:w-32 sm:text-sm"
                        onClick={this.cancelHandler}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        disabled={this.state.logging}
                        className={
                          "mt-3 sm:ml-4 w-full inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:mt-0 sm:w-40 sm:text-sm"
                        }
                        onClick={this.addClient}
                      >
                        <span className="w-full flex justify-end">
                          {this.state.logging ? (
                            <Icon name="loading" className="mr-2" />
                          ) : null}
                        </span>
                        <span className="whitespace-nowrap">Save Client</span>
                        <span className="w-full"></span>
                      </button>
                    </div>
                  </div>

                  {/* Tax Applicable Checkbox */}
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 lg:col-span-2">
                      <label
                        htmlFor="company_website"
                        className="block font-medium text-gray-700"
                      >
                        Taxes Applicable <span className="text-red-600">*</span>
                      </label>
                      <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10 mt-2">
                        <div className="relative flex items-start">
                          <div className="flex h-5 items-center">
                            <input
                              id="gst"
                              aria-describedby="gst-taxesApplicable"
                              name="gst"
                              type="checkbox"
                              className="h-4 w-4 rounded text-indigo-600 focus:ring-indigo-500 border-gray-400"
                              checked={this.state.taxesApplicable.includes(
                                "gst"
                              )}
                              onChange={() =>
                                this.state.taxesApplicable.includes("gst")
                                  ? this.taxesApplicableHandler("gst")
                                  : this.showGstinValidationModal(true)
                              }
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              htmlFor="gst"
                              className="font-medium text-gray-700"
                            >
                              GST
                            </label>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex h-5 items-center">
                            <input
                              id="income-tax"
                              aria-describedby="income-tax-taxesApplicable"
                              name="income-tax"
                              type="checkbox"
                              className="h-4 w-4 rounded text-indigo-600 focus:ring-indigo-500 border-gray-400"
                              checked={this.state.taxesApplicable.includes(
                                "income-tax"
                              )}
                              onChange={() =>
                                this.taxesApplicableHandler("income-tax")
                              }
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              htmlFor="income-tax"
                              className="font-medium text-gray-700"
                            >
                              Income Tax
                            </label>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex h-5 items-center">
                            <input
                              disabled
                              id="other"
                              aria-describedby="other-taxesApplicable"
                              name="other"
                              type="checkbox"
                              className="h-4 w-4 rounded text-indigo-600 focus:ring-indigo-500 border-gray-400"
                              defaultChecked={this.state.taxesApplicable.includes(
                                "other"
                              )}
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              htmlFor="other"
                              className="font-medium text-gray-700"
                            >
                              Other
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-3 lg:col-span-1 mx-auto lg:mx-0 lg:ml-auto self-end">
                      <button
                        type="button"
                        onClick={this.toggleRequiredFields}
                        className="text-indigo-600 text-center whitespace-nowrap font-medium text-sm cursor-pointer flex items-center gap-x-2 w-fit underline underline-offset-2"
                      >
                        {this.state.showRequiredFieldsOnly
                          ? "Show All Fields"
                          : "Show required fields only"}
                      </button>
                    </div>
                  </div>
                  {/* All Tabs */}
                  <div>
                    <div className="sm:hidden">
                      <label htmlFor="tabs" className="sr-only">
                        Select a tab
                      </label>
                      <select
                        id="tabs"
                        name="tabs"
                        className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                        value={this.state.tab}
                        onChange={e => this.tabClickHandler(e.target.value)}
                      >
                        {this.state.taxesApplicable.includes("gst") && (
                          <option value={"gst"}>GST Details</option>
                        )}
                        {this.state.taxesApplicable.includes("income-tax") && (
                          <option value={"income-tax"}>
                            Income Tax Details
                          </option>
                        )}
                        <option value={"basic"}>Basic Details</option>
                        <option value={"contact"}>Contact Persons</option>
                        <option value={"group"}>Client Groups</option>
                        <option value={"custom"}>Custom Fields</option>
                        <option value={"loginDetails"}>Login Details</option>

                        {/* ))} */}
                      </select>
                    </div>
                    <div className="hidden sm:block">
                      <div className="border-b border-gray-200">
                        <nav className="-mb-px flex" aria-label="Tabs">
                          <button
                            type="button"
                            onClick={() => this.tabClickHandler("basic")}
                            className={`inline-flex items-center justify-center w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm ${
                              this.state.tab === "basic"
                                ? "border-indigo-500 text-indigo-600"
                                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                            }
                            `}
                          >
                            <Icon
                              name="outline/document-add"
                              className="h-4 w-4 mr-1"
                            />
                            Basic Details
                          </button>
                          {this.state.taxesApplicable.includes("gst") && (
                            <button
                              type="button"
                              onClick={() => this.tabClickHandler("gst")}
                              className={`inline-flex items-center justify-center w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm ${
                                this.state.tab === "gst"
                                  ? "border-indigo-500 text-indigo-600"
                                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                              }
                            `}
                            >
                              <Icon
                                name="outline/document-text"
                                className="h-4 w-4 mr-1"
                              />
                              GST Details
                            </button>
                          )}
                          {this.state.taxesApplicable.includes(
                            "income-tax"
                          ) && (
                            <button
                              type="button"
                              onClick={() => this.tabClickHandler("income-tax")}
                              className={`inline-flex items-center justify-center w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm ${
                                this.state.tab === "income-tax"
                                  ? "border-indigo-500 text-indigo-600"
                                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                              }
                            `}
                            >
                              <Icon
                                name="outline/document-report"
                                className="h-4 w-4 mr-1"
                              />
                              Income Tax Details
                            </button>
                          )}

                          <button
                            type="button"
                            onClick={() => this.tabClickHandler("contact")}
                            className={`inline-flex items-center justify-center w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm ${
                              this.state.tab === "contact"
                                ? "border-indigo-500 text-indigo-600"
                                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                            }
                            `}
                          >
                            <Icon
                              name="outline/user"
                              className="h-4 w-4 mr-1"
                            />
                            Contact Persons
                          </button>
                          <button
                            type="button"
                            onClick={() => this.tabClickHandler("group")}
                            className={`inline-flex items-center justify-center w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm ${
                              this.state.tab === "group"
                                ? "border-indigo-500 text-indigo-600"
                                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                            }
                            `}
                          >
                            <Icon
                              name="outline/users"
                              className="h-4 w-4 mr-1"
                            />
                            Client Groups
                          </button>
                          <button
                            type="button"
                            onClick={() => this.tabClickHandler("custom")}
                            className={`inline-flex items-center justify-center w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm ${
                              this.state.tab === "custom"
                                ? "border-indigo-500 text-indigo-600"
                                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                            }
                            `}
                          >
                            <Icon
                              name="outline/settings"
                              className="h-4 w-4 mr-1"
                            />
                            Custom Fields
                          </button>
                          <button
                            type="button"
                            onClick={() => this.tabClickHandler("loginDetails")}
                            className={`inline-flex items-center justify-center w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm ${
                              this.state.tab === "loginDetails"
                                ? "border-indigo-500 text-indigo-600"
                                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                            }
                            `}
                          >
                            <Icon
                              name="outline/settings"
                              className="h-4 w-4 mr-1"
                            />
                            Login Details
                          </button>
                        </nav>
                      </div>
                    </div>
                  </div>
                  {/* Basic Details Form */}
                  {this.state.tab === "basic" && (
                    <>
                      <div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Basic Details
                        </h3>
                      </div>
                      <div className="space-y-6 sm:space-y-5">
                        {!this.state.showRequiredFieldsOnly && (
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="file"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              File No
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <input
                                type="text"
                                name="file"
                                id="file"
                                autoComplete="off"
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                placeholder="File No."
                                value={this.state.fileNo}
                                onChange={this.updateState("fileNo")}
                              />
                            </div>
                          </div>
                        )}
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="type"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Type of Client{" "}
                            <span className="text-red-600">*</span>
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                            <MultiSelect
                              items={clientTypesList.map(item => {
                                return {
                                  _id: item,
                                  name: item
                                };
                              })}
                              selected={{
                                name: this.state.type?.name
                              }}
                              type="type"
                              onChange={this.clientTypeChange}
                              placeholder="Select Ownership Type"
                              disabled={this.state.govtGstIsPresent}
                            />
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Name <span className="text-red-600">*</span>
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <input
                              type="text"
                              name="first-name"
                              id="first-name"
                              autoComplete="off"
                              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                              placeholder="Name"
                              value={this.state.name}
                              onChange={this.updateState("name")}
                            />
                          </div>
                        </div>
                        {!this.state.showRequiredFieldsOnly && (
                          <>
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                              <label
                                htmlFor="trade-name"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                Trade Name
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                  type="text"
                                  name="trade-name"
                                  id="trade-name"
                                  autoComplete="off"
                                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                  placeholder="Trade Name"
                                  value={this.state.tradeName}
                                  onChange={this.updateState("tradeName")}
                                />
                              </div>
                            </div>
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                              <label
                                htmlFor="dob"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                Date of Birth/Date of Incorporation
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                  type="date"
                                  name="dob"
                                  id="dob"
                                  autoComplete="off"
                                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                  placeholder="DOB/DOI"
                                  value={this.state.dob}
                                  onChange={this.updateState("dob")}
                                />
                              </div>
                            </div>
                          </>
                        )}
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="user"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Other Users <span className="text-red-600">*</span>
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                            <MultiSelectCheckbox
                              items={this.props?.users?.map((user: any) => {
                                return {
                                  ...user,
                                  _id: user._id,
                                  name: user.name
                                };
                              })}
                              selected={this.state.defUsers}
                              type="usercheckbox"
                              onChange={this.onUserChange}
                              placeholder="Select Other Users"
                            />
                          </div>
                        </div>
                        {!this.state.showRequiredFieldsOnly && (
                          <>
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                              <label
                                htmlFor="tag"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                Tags
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:flex sm:gap-4 sm:items-center">
                                <div className={"sm:w-80 md:max-w-xs w-full"}>
                                  <MultiSelectCheckbox
                                    items={this.props?.tags?.map((tag: any) => {
                                      return {
                                        ...tag,
                                        _id: tag._id,
                                        name: tag.name
                                      };
                                    })}
                                    selected={this.state.tags}
                                    type="usercheckbox"
                                    onChange={this.onTagChange}
                                    placeholder="Select Tags"
                                  />
                                </div>
                                <button
                                  type="button"
                                  className="mt-3 sm:mt-0 inline-flex items-center w-fit py-2 px-2 border border-transparent shadow-sm text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none"
                                  onClick={() => this.openModal("tag")}
                                >
                                  <Icon name="add" className="h-3 w-3 mr-1" />
                                  New Tag
                                </button>
                              </div>
                            </div>
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                              <label
                                htmlFor="about"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                Notes
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <textarea
                                  id="about"
                                  name="about"
                                  rows={4}
                                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  value={this.state.notes}
                                  onChange={this.updateState("notes")}
                                  placeholder="Add Notes..."
                                />
                              </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                              <label
                                htmlFor="cin"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                CIN
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                  type="text"
                                  name="cin"
                                  id="cin"
                                  autoComplete="off"
                                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                  placeholder="CIN"
                                  value={this.state.companyCIN}
                                  onChange={this.updateState("companyCIN")}
                                />
                              </div>
                            </div>
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                              <label
                                htmlFor="llp"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                LLP
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                  type="text"
                                  name="llp"
                                  id="llp"
                                  autoComplete="off"
                                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                  placeholder="LLP"
                                  value={this.state.LLP_RegNo}
                                  onChange={this.updateState("LLP_RegNo")}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}

                  {/* GST Details Form  */}
                  {this.state.tab === "gst" && (
                    <>
                      <div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          GST Details
                        </h3>
                      </div>
                      <div className="space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="gstin"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            GSTIN <span className="text-red-600">*</span>
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <input
                              type="text"
                              name="gstin"
                              id="gstin"
                              autoComplete="off"
                              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                              placeholder="GSTIN"
                              value={this.state.gstin}
                              onChange={this.handleGstinChange}
                            />
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            GSTIN Status
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <fieldset className="mt-2">
                              <legend className="sr-only">GSTIN Status</legend>
                              <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                <div className="flex items-center">
                                  <input
                                    id={"active_gstin"}
                                    name="active_gstin"
                                    type="radio"
                                    value={"active"}
                                    disabled={this.state.govtGstIsPresent}
                                    checked={
                                      this.state.gstinStatus === "active"
                                    }
                                    className={`focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 ${
                                      this.state.govtGstIsPresent
                                        ? "cursor-not-allowed"
                                        : "cursor-pointer"
                                    }`}
                                    onChange={this.updateState("gstinStatus")}
                                  />
                                  <label
                                    htmlFor={"active_gstin"}
                                    className={`ml-3 block text-sm font-medium text-gray-700 ${
                                      this.state.govtGstIsPresent
                                        ? "cursor-not-allowed"
                                        : "cursor-pointer"
                                    } `}
                                  >
                                    Active
                                  </label>
                                </div>
                                <div className="flex items-center">
                                  <input
                                    id={"inactive_gstin"}
                                    name="inactive_gstin"
                                    type="radio"
                                    value={"cancelled"}
                                    disabled={this.state.govtGstIsPresent}
                                    checked={
                                      this.state.gstinStatus === "cancelled" ||
                                      this.state.gstinStatus === "suspended"
                                    }
                                    className={`focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 ${
                                      this.state.govtGstIsPresent
                                        ? "cursor-not-allowed"
                                        : "cursor-pointer"
                                    }`}
                                    onChange={this.updateState("gstinStatus")}
                                  />
                                  <label
                                    htmlFor={"inactive_gstin"}
                                    className={`ml-3 block text-sm font-medium text-gray-700 ${
                                      this.state.govtGstIsPresent
                                        ? "cursor-not-allowed"
                                        : "cursor-pointer"
                                    } `}
                                  >
                                    Cancelled / Suspended
                                  </label>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Registration Type
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <fieldset className="mt-2">
                              <legend className="sr-only">
                                {" "}
                                Registration Type
                              </legend>
                              <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                <div className="flex items-center">
                                  <input
                                    id={"regular"}
                                    name="regular"
                                    type="radio"
                                    value={"regular"}
                                    disabled={this.state.govtGstIsPresent}
                                    checked={
                                      this.state.registrationType === "regular"
                                    }
                                    className={`focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 ${
                                      this.state.govtGstIsPresent
                                        ? "cursor-not-allowed"
                                        : "cursor-pointer"
                                    }`}
                                    onChange={this.updateState(
                                      "registrationType"
                                    )}
                                  />
                                  <label
                                    htmlFor={"regular"}
                                    className={`ml-3 block text-sm font-medium text-gray-700 ${
                                      this.state.govtGstIsPresent
                                        ? "cursor-not-allowed"
                                        : "cursor-pointer"
                                    } `}
                                  >
                                    Regular
                                  </label>
                                </div>
                                <div className="flex items-center">
                                  <input
                                    id={"composition"}
                                    name="composition"
                                    type="radio"
                                    value={"composition"}
                                    disabled={this.state.govtGstIsPresent}
                                    checked={
                                      this.state.registrationType ===
                                      "composition"
                                    }
                                    className={`focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 ${
                                      this.state.govtGstIsPresent
                                        ? "cursor-not-allowed"
                                        : "cursor-pointer"
                                    }`}
                                    onChange={this.updateState(
                                      "registrationType"
                                    )}
                                  />
                                  <label
                                    htmlFor={"composition"}
                                    className={`ml-3 block text-sm font-medium text-gray-700 ${
                                      this.state.govtGstIsPresent
                                        ? "cursor-not-allowed"
                                        : "cursor-pointer"
                                    } `}
                                  >
                                    Composition
                                  </label>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="registration"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Registration Date{" "}
                            <span className="text-red-600">*</span>
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <input
                              type="date"
                              name="registration"
                              id="registration"
                              disabled={this.state.govtGstIsPresent}
                              className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm ${
                                this.state.govtGstIsPresent
                                  ? "cursor-not-allowed"
                                  : ""
                              }`}
                              value={this.state.regDate}
                              onChange={this.updateState("regDate")}
                            />
                          </div>
                        </div>

                        {!this.state.showRequiredFieldsOnly && (
                          <>
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                              <label
                                htmlFor="can-date"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                Cancellation Date
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                  type="date"
                                  name="can-date"
                                  id="can-date"
                                  disabled={this.state.govtGstIsPresent}
                                  className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm ${
                                    this.state.govtGstIsPresent
                                      ? "cursor-not-allowed"
                                      : ""
                                  }`}
                                  value={this.state.canDate}
                                  onChange={this.updateState("canDate")}
                                />
                              </div>
                            </div>
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                              <label
                                htmlFor="registeredAddress"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                Registered Address
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <textarea
                                  id="registeredAddress"
                                  name="registeredAddress"
                                  rows={4}
                                  disabled={this.state.govtGstIsPresent}
                                  className={`"block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
                                    this.state.govtGstIsPresent
                                      ? "cursor-not-allowed"
                                      : ""
                                  }`}
                                  value={this.state.registeredAddress}
                                  onChange={this.updateState(
                                    "registeredAddress"
                                  )}
                                  placeholder="Add Registered Address..."
                                />
                              </div>
                            </div>
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                              <label
                                htmlFor="additionalPlaceOfBussiness"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                Additional Place of Business
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <div className="flex items-center gap-4">
                                  <p className="block text-sm text-gray-700 sm:mt-px sm:pt-2">
                                    No. of Additional Places of Business -{" "}
                                    <span className="font-bold">
                                      {
                                        this.state.additionalPlaceOfBussiness
                                          .length
                                      }
                                    </span>
                                  </p>
                                  {this.state.additionalPlaceOfBussiness
                                    ?.length > 0 && (
                                    <button
                                      type="button"
                                      className="mt-3 inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none"
                                      onClick={this.toggleAdditionalBusiness}
                                    >
                                      {this.state.showAdditionalBussiness
                                        ? "Hide"
                                        : "Show"}
                                    </button>
                                  )}
                                </div>
                                {this.state.showAdditionalBussiness && (
                                  <div className="border border-gray-400 py-3 px-4 my-4">
                                    <ol className="list-decimal list-inside">
                                      {this.state.additionalPlaceOfBussiness.map(
                                        (address: any) => (
                                          <li className="text-sm text-gray-700 my-3">
                                            {address}
                                          </li>
                                        )
                                      )}
                                    </ol>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                              <label
                                htmlFor="stateJurisdiction"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                State Jurisdiction
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                  type="text"
                                  name="stateJurisdiction"
                                  id="stateJurisdiction"
                                  autoComplete="off"
                                  className={`"block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm ${
                                    this.state.govtGstIsPresent
                                      ? "cursor-not-allowed"
                                      : ""
                                  }`}
                                  placeholder="State Jurisdiction"
                                  disabled={this.state.govtGstIsPresent}
                                  value={this.state.stateJurisdiction}
                                  onChange={this.updateState(
                                    "stateJurisdiction"
                                  )}
                                />
                              </div>
                            </div>
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                              <label
                                htmlFor="centralJurisdiction"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                Central Jurisdiction
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                  type="text"
                                  name="centralJurisdiction"
                                  id="centralJurisdiction"
                                  autoComplete="off"
                                  className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm ${
                                    this.state.govtGstIsPresent
                                      ? "cursor-not-allowed"
                                      : ""
                                  }`}
                                  placeholder="Central Jurisdiction"
                                  disabled={this.state.govtGstIsPresent}
                                  value={this.state.centralJurisdiction}
                                  onChange={this.updateState(
                                    "centralJurisdiction"
                                  )}
                                />
                              </div>
                            </div>
                            <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                              <p className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                QRMP Preference
                              </p>
                              <div className="max-w-full mx-auto">
                                <div className="mt-6 flex flex-col">
                                  <div
                                    id="table-scroll"
                                    className="overflow-auto"
                                  >
                                    <div className="inline-block min-w-full py-2 align-middle">
                                      <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                                        <table className="min-w-full border-collapse border shadow-sm">
                                          <thead className="bg-gray-50">
                                            <tr>
                                              <th
                                                style={{ zIndex: 8 }}
                                                scope="col"
                                                className="sticky top-0 border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                                              >
                                                QUARTER
                                              </th>
                                              <th
                                                style={{ zIndex: 8 }}
                                                scope="col"
                                                className="sticky top-0 border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                                              >
                                                YEAR
                                              </th>

                                              <th
                                                style={{ zIndex: 8 }}
                                                scope="col"
                                                className="sticky top-0 border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                                              >
                                                PREFERENCE
                                              </th>

                                              <th
                                                style={{ zIndex: 8 }}
                                                scope="col"
                                                className="sticky top-0 border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                                              >
                                                LAST CHECKED
                                              </th>
                                            </tr>
                                          </thead>
                                          {this.state.qrmpPreferance?.length ===
                                          0 ? (
                                            <tbody>
                                              <tr>
                                                <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                                                  No QRMP Detail Found
                                                </td>
                                              </tr>
                                            </tbody>
                                          ) : (
                                            <tbody className="bg-white">
                                              {this.state.qrmpPreferance?.map(
                                                (item: any, index: any) => (
                                                  <tr
                                                    key={index}
                                                    className={
                                                      index % 2 === 0
                                                        ? undefined
                                                        : "bg-gray-100"
                                                    }
                                                  >
                                                    <td className="w-2/12 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900">
                                                      {item.quarter}
                                                    </td>
                                                    <td className="w-3/12 whitespace-nowrap px-4 py-3 text-sm text-gray-500 relative">
                                                      {item.year}
                                                    </td>
                                                    <td className="w-3/12 px-4 py-3 whitespace-nowrap font-bold text-sm text-gray-900">
                                                      {item.preference === "Q"
                                                        ? "Quarterly"
                                                        : item.preference ===
                                                          "M"
                                                        ? "Monthly"
                                                        : item.preference ===
                                                          "Y"
                                                        ? "Yearly"
                                                        : item.preference}
                                                    </td>
                                                    <td className="w-3/12 px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                                                      {formatDateAndTime(
                                                        item.lastChecked
                                                      )}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          )}
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}

                  {/* Income Tax Details Form */}
                  {this.state.tab === "income-tax" && (
                    <>
                      <div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Income Tax Details
                        </h3>
                      </div>

                      <div className="space-y-6 sm:space-y-5">
                        <div className="pt-2 sm:pt-5 sm:border-t sm:border-gray-200 ">
                          <div role="group" aria-labelledby="label-email">
                            <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4">
                              <div
                                className="text-sm font-medium text-gray-700 sm:text-gray-700"
                                id="label-email"
                              >
                                Returns You File in IT{" "}
                                <span className="text-red-600">*</span>
                              </div>

                              <div className="mt-4 sm:col-span-2 sm:mt-0">
                                <div className="max-w-lg space-y-4">
                                  {returnFileList.map(item => {
                                    return (
                                      <fieldset
                                        disabled={
                                          isAdvanceTaxApplicable(
                                            item,
                                            this.state.type?.name ?? ""
                                          ) ||
                                          (this.state.type?.name.endsWith(
                                            "Company"
                                          ) &&
                                            item === "ITR - Unaudited")
                                        }
                                        className="flex h-5 items-center gap-3 text-sm disabled:cursor-not-allowed disabled:opacity-50 group"
                                      >
                                        <input
                                          id={item}
                                          name={item}
                                          type="checkbox"
                                          className="h-4 w-4 rounded border-gray-400 text-indigo-600 focus:ring-indigo-500 cursor-pointer group-disabled:cursor-not-allowed"
                                          checked={this.state.filedITreturns.includes(
                                            item
                                          )}
                                          onChange={() =>
                                            this.handleReturnTypeSelection(item)
                                          }
                                        />
                                        <label
                                          htmlFor={item}
                                          className="font-medium text-gray-700 cursor-pointer group-disabled:cursor-not-allowed"
                                        >
                                          {item}
                                        </label>
                                      </fieldset>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {!this.state.showRequiredFieldsOnly && (
                          <>
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                              <label
                                htmlFor="pan"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                PAN
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                  type="text"
                                  name="pan"
                                  id="pan"
                                  disabled={this.state.govtGstIsPresent}
                                  autoComplete="off"
                                  className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm ${
                                    this.state.govtGstIsPresent
                                      ? "cursor-not-allowed"
                                      : ""
                                  }`}
                                  placeholder="PAN"
                                  value={this.state.pan}
                                  onChange={this.updateState("pan")}
                                />
                              </div>
                            </div>
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                              <label
                                htmlFor="tan"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                TAN
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                  type="text"
                                  name="tan"
                                  id="tan"
                                  autoComplete="off"
                                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                  placeholder="TAN"
                                  value={this.state.tan}
                                  onChange={this.updateState("tan")}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}

                  {/* Contact Person Form*/}
                  {this.state.tab === "contact" && (
                    <>
                      <div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Contact Person Details
                        </h3>
                      </div>
                      {!this.state.showRequiredFieldsOnly ? (
                        <>
                          <div className="space-y-6 sm:space-y-5">
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                              <label
                                htmlFor="tag"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                Person
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:flex sm:gap-4 sm:items-center">
                                <div className={"sm:w-80 md:max-w-xs w-full"}>
                                  <MultiSelect
                                    items={this.state.personList?.map(
                                      (person: any) => {
                                        return {
                                          ...person,
                                          _id: person._id,
                                          name: person.name
                                        };
                                      }
                                    )}
                                    selected={{
                                      name: this.state.selectedPerson?.name
                                    }}
                                    type="person"
                                    onChange={this.onPersonChange}
                                    placeholder="Select Person"
                                  />
                                </div>
                                <button
                                  type="button"
                                  className="mt-3 sm:mt-0 inline-flex items-center w-fit py-2 px-2 border border-transparent shadow-sm text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none"
                                  onClick={() =>
                                    this.openModal("contactPerson")
                                  }
                                >
                                  <Icon
                                    name="add"
                                    className="h-3.5 w-3.5 mr-1"
                                  />
                                  New Contact Person
                                </button>
                              </div>
                            </div>
                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                              <label
                                htmlFor="type"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                Designation
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                                <div>
                                  <MultiSelect
                                    items={designationList?.map(
                                      (designation: any) => {
                                        return {
                                          _id: designation,
                                          name: designation
                                        };
                                      }
                                    )}
                                    selected={{
                                      name: this.state.designation?.name
                                    }}
                                    type="designation"
                                    onChange={this.onDesignationChange}
                                    placeholder="Select Designation"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="pt-2 sm:pt-5 sm:border-t sm:border-gray-200 ">
                              <div role="group" aria-labelledby="label-email">
                                <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4">
                                  <div className="text-sm font-medium text-gray-700 sm:text-gray-700">
                                    Is Primary
                                  </div>

                                  <div className="mt-4 sm:col-span-2 sm:mt-0">
                                    <div className="max-w-lg space-y-4">
                                      <div className="relative flex items-start">
                                        <div className="flex h-5 items-center">
                                          <input
                                            id={"primary"}
                                            name={"primary"}
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-400 text-indigo-600 focus:ring-indigo-500"
                                            checked={this.state.isPrimary}
                                            onChange={this.isPrimaryHandler}
                                          />
                                        </div>
                                        <div className="ml-3 text-sm">
                                          <label
                                            htmlFor={"primary"}
                                            className="font-medium text-gray-700"
                                          >
                                            Select as Primary
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                              <label className="hidden sm:block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                {""}
                              </label>
                              <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <button
                                  type="button"
                                  onClick={this.addPerson}
                                  className={
                                    "inline-flex justify-center w-20 sm:w-32 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                                  }
                                >
                                  {this.state.updatingSelectedPerson
                                    ? "Update"
                                    : "Add"}
                                </button>
                              </div>
                            </div>
                          </div>

                          {/* Contact Person Table */}
                          <div
                            className={
                              "sm:border-t mt-2 sm:border-gray-200 max-w-full mx-auto"
                            }
                          >
                            <div className="mt-6 flex flex-col max-h-screen">
                              <div
                                id="table-scroll"
                                className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8 overflow-auto"
                              >
                                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                  <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                                    <table className="min-w-full border-collapse border shadow-sm">
                                      <thead className="bg-gray-50">
                                        <tr>
                                          <th
                                            style={{ zIndex: 8 }}
                                            scope="col"
                                            className="sticky top-0 border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6"
                                          >
                                            NAME
                                          </th>
                                          <th
                                            style={{ zIndex: 8 }}
                                            scope="col"
                                            className="sticky top-0 border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6"
                                          >
                                            EMAIL
                                          </th>

                                          <th
                                            style={{ zIndex: 8 }}
                                            scope="col"
                                            className="sticky top-0 border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6"
                                          >
                                            MOBILE
                                          </th>

                                          <th
                                            style={{ zIndex: 8 }}
                                            scope="col"
                                            className="sticky top-0 border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6"
                                          >
                                            DESIGNATION
                                          </th>

                                          <th
                                            style={{ zIndex: 8 }}
                                            scope="col"
                                            className="sticky top-0 border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6"
                                          >
                                            IS PRIMARY
                                          </th>

                                          <th
                                            style={{ zIndex: 8 }}
                                            scope="col"
                                            className="sticky top-0 border-b border-gray-300 bg-gray-50 px-4 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6"
                                          >
                                            ACTIONS
                                          </th>
                                        </tr>
                                      </thead>
                                      {this.state.contactPerson?.length ===
                                      0 ? (
                                        <tbody>
                                          <tr>
                                            <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                                              No Contact Person Added
                                            </td>
                                          </tr>
                                        </tbody>
                                      ) : (
                                        <tbody className="bg-white">
                                          {this.state.contactPerson?.map(
                                            (person: any, index: any) => (
                                              <tr
                                                key={`${person._id}-${index}`}
                                                className={
                                                  index % 2 === 0
                                                    ? undefined
                                                    : "bg-gray-100"
                                                }
                                              >
                                                <td className="w-2/12 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6">
                                                  {/* <button
                                                className="hover:text-blue-600  group flex items-center gap-4 underline underline-offset-2"
                                                onClick={() =>
                                                  this.openEditModal(
                                                    "person",
                                                    person
                                                  )
                                                }
                                              > */}
                                                  {person.name}{" "}
                                                  {/* <Icon
                                                  name="edit"
                                                  className="text-blue-600 h-4 w-4 inline-block"
                                                />
                                              </button> */}
                                                </td>
                                                <td className="w-3/12 px-6 py-3 text-sm relative">
                                                  <div className="flex items-center gap-2">
                                                    <span className="text-gray-500 hover:underline cursor-pointer">
                                                      {this.emailTableContent(
                                                        person.email
                                                      )?.visibleEmail ?? "-"}
                                                    </span>
                                                    <span className="text-gray-400 text-xs">
                                                      {
                                                        this.emailTableContent(
                                                          person.email
                                                        )?.otherEmailLength
                                                      }
                                                    </span>
                                                  </div>
                                                </td>
                                                <td className="w-2/12 px-6 py-3 whitespace-nowrap font-bold text-sm text-gray-900">
                                                  <div className="flex items-center gap-2">
                                                    <span className="text-gray-900 hover:underline cursor-pointer">
                                                      {this.mobileTableContent(
                                                        person.mobile
                                                      )?.visibleNumber ?? "-"}
                                                    </span>
                                                    <span className="text-gray-400 text-xs">
                                                      {
                                                        this.mobileTableContent(
                                                          person.mobile
                                                        )?.otherMobileLength
                                                      }
                                                    </span>
                                                  </div>
                                                </td>
                                                <td className="w-4/10 px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                                                  {person.designation ?? "-"}
                                                </td>
                                                <td className="w-4/10 px-6 py-3 whitespace-nowrap font-bold text-sm text-gray-900">
                                                  {person.isPrimary
                                                    ? "Yes"
                                                    : "No"}
                                                </td>
                                                <td className="w-4/10 px-6 py-3 mx-4 text-center whitespace-nowrap text-sm text-gray-500">
                                                  <Menu
                                                    as="div"
                                                    className="inline-block"
                                                  >
                                                    <Menu.Button
                                                      onClick={
                                                        this.onActionClick
                                                      }
                                                    >
                                                      <span className="sr-only">
                                                        Open options
                                                      </span>
                                                      <Icon
                                                        name="horizontal-dots"
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                      />
                                                    </Menu.Button>
                                                    {this.state
                                                      .showBackDrop && (
                                                      <div
                                                        className="fixed top-0 left-0 z-10 w-full h-screen"
                                                        onClick={
                                                          this.onDropdownClick
                                                        }
                                                      ></div>
                                                    )}

                                                    <Transition
                                                      as={Fragment}
                                                      enter="transition ease-out duration-100"
                                                      enterFrom="transform opacity-0 scale-95"
                                                      enterTo="transform opacity-100 scale-100"
                                                      leave="transition ease-in duration-75"
                                                      leaveFrom="transform opacity-100 scale-100"
                                                      leaveTo="transform opacity-0 scale-95"
                                                    >
                                                      <div
                                                        style={{
                                                          position: "fixed",
                                                          zIndex: 100,
                                                          top: `${this.state.posY}px`,
                                                          left: `${
                                                            this.state.posX -
                                                            230
                                                          }px`,
                                                          margin: "0.5rem"
                                                        }}
                                                      >
                                                        <Menu.Items className="overscroll-none mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                          <div className="py-1">
                                                            <Menu.Item>
                                                              <button
                                                                type="button"
                                                                className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                                                                onClick={() =>
                                                                  this.editSelectedContent(
                                                                    "contactPerson",
                                                                    index
                                                                  )
                                                                }
                                                              >
                                                                <Icon
                                                                  name="edit"
                                                                  className="h-5 w-5 mr-2"
                                                                />
                                                                <span>
                                                                  Edit
                                                                </span>
                                                              </button>
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                              <button
                                                                type="button"
                                                                className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                                                                onClick={() =>
                                                                  this.deleteContentFromTable(
                                                                    "contactPerson",
                                                                    index
                                                                  )
                                                                }
                                                              >
                                                                <Icon
                                                                  name="delete"
                                                                  className="h-5 w-5 mr-2"
                                                                />
                                                                <span>
                                                                  Delete
                                                                </span>
                                                              </button>
                                                            </Menu.Item>
                                                          </div>
                                                        </Menu.Items>
                                                      </div>
                                                    </Transition>
                                                  </Menu>
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      )}
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="space-y-6 sm:space-y-5">
                          <div className="pt-2 sm:pt-5 sm:border-t sm:border-gray-200 ">
                            No Required Fields
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {/*  Client Group  */}
                  {this.state.tab === "group" && (
                    <>
                      <div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Client Group Details
                        </h3>
                      </div>
                      {!this.state.showRequiredFieldsOnly ? (
                        <>
                          {this.state.clientGroup.length < 3 ? (
                            <div className="space-y-6 sm:space-y-5">
                              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                <label
                                  htmlFor="tag"
                                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                  Group
                                </label>
                                <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:flex sm:gap-4 sm:items-center sm:justify-between space-y-4 sm:space-y-0">
                                  <div className="flex items-center justify-between gap-4">
                                    <div
                                      className={"sm:w-80 md:max-w-xs flex-1"}
                                    >
                                      <MultiSelect
                                        items={this.state.groupList?.map(
                                          (group: any) => {
                                            return {
                                              ...group,
                                              _id: group._id,
                                              name: group.name
                                            };
                                          }
                                        )}
                                        selected={{
                                          name: this.state.selectedGroup?.name
                                        }}
                                        type="group"
                                        onChange={this.onGroupChange}
                                        placeholder="Select Group"
                                      />
                                    </div>
                                    <Popup
                                      content={
                                        this.state.selectedGroup?.description
                                      }
                                      className={`w-fit ${
                                        this.state.selectedGroup?.description
                                          ? "visible"
                                          : "invisible"
                                      }`}
                                    >
                                      <Icon
                                        name="information-circle"
                                        className="w-5 h-5 text-gray-600 mt-1"
                                      />
                                    </Popup>
                                    <button
                                      type="button"
                                      onClick={this.addGroup}
                                      className={
                                        "inline-flex justify-center w-20 sm:w-32 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                                      }
                                    >
                                      Save
                                    </button>
                                  </div>
                                  <button
                                    type="button"
                                    className="mt-3 sm:mt-0 inline-flex items-center w-fit py-2 px-2 border border-transparent shadow-sm text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none"
                                    onClick={() =>
                                      this.openModal("clientGroup")
                                    }
                                  >
                                    <Icon
                                      name="add"
                                      className="h-3.5 w-3.5 mr-1"
                                    />
                                    New Client Group
                                  </button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <p className="block text-sm font-medium text-gray-700 -mt-5">
                              Note: You cannot add more than 3 groups in a
                              client.
                            </p>
                          )}

                          {/* Client Group Table */}
                          <div
                            className={
                              "sm:border-t mt-2 sm:border-gray-200 max-w-full mx-auto"
                            }
                          >
                            <div className="mt-6 flex flex-col max-h-screen">
                              <div
                                id="table-scroll"
                                className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8 overflow-auto"
                              >
                                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                  <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                                    <table
                                      className="min-w-full border-separate border shadow-sm"
                                      style={{ borderSpacing: 0 }}
                                    >
                                      <thead className="bg-gray-50">
                                        <tr>
                                          <th
                                            style={{ zIndex: 8 }}
                                            scope="col"
                                            className="sticky top-0 border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6"
                                          >
                                            NAME
                                          </th>
                                          <th
                                            style={{ zIndex: 8 }}
                                            scope="col"
                                            className="sticky top-0 border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6"
                                          >
                                            DESCRIPTION
                                          </th>

                                          <th
                                            style={{ zIndex: 8 }}
                                            scope="col"
                                            className="sticky top-0 border-b border-gray-300 bg-gray-50 px-4 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6"
                                          >
                                            ACTIONS
                                          </th>
                                        </tr>
                                      </thead>
                                      {this.state.clientGroup.length === 0 ? (
                                        <tbody>
                                          <tr>
                                            <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                                              No Client Group Added
                                            </td>
                                          </tr>
                                        </tbody>
                                      ) : (
                                        <tbody className="bg-white">
                                          {this.state.clientGroup?.map(
                                            (group: any, index: any) => (
                                              <tr
                                                key={group._id}
                                                className={
                                                  index % 2 === 0
                                                    ? undefined
                                                    : "bg-gray-100"
                                                }
                                              >
                                                <td className="w-4/12 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6">
                                                  {/* <button
                                                className="hover:text-blue-600 group flex items-center gap-4 underline underline-offset-2"
                                                onClick={() =>
                                                  this.openEditModal(
                                                    "group",
                                                    group
                                                  )
                                                }
                                              > */}
                                                  {group.name}{" "}
                                                  {/* <Icon
                                                  name="edit"
                                                  className="text-blue-600 h-4 w-4 inline-block"
                                                />
                                              </button> */}
                                                </td>
                                                <td className="w-6/12 px-6 py-3 text-sm text-gray-500 relative">
                                                  <Popup
                                                    content={group.description}
                                                    className="w-full max-w-fit"
                                                  >
                                                    <p className="truncate">
                                                      {group.description
                                                        ? group.description
                                                        : "-"}
                                                    </p>
                                                  </Popup>
                                                </td>

                                                <td className="w-4/10 px-6 py-3 mx-4 text-center whitespace-nowrap text-sm text-gray-900">
                                                  <Menu
                                                    as="div"
                                                    className="inline-block"
                                                  >
                                                    <Menu.Button
                                                      onClick={
                                                        this.onActionClick
                                                      }
                                                    >
                                                      <span className="sr-only">
                                                        Open options
                                                      </span>
                                                      <Icon
                                                        name="horizontal-dots"
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                      />
                                                    </Menu.Button>
                                                    {this.state
                                                      .showBackDrop && (
                                                      <div
                                                        className="fixed top-0 left-0 z-10 w-full h-screen"
                                                        onClick={
                                                          this.onDropdownClick
                                                        }
                                                      ></div>
                                                    )}

                                                    <Transition
                                                      as={Fragment}
                                                      enter="transition ease-out duration-100"
                                                      enterFrom="transform opacity-0 scale-95"
                                                      enterTo="transform opacity-100 scale-100"
                                                      leave="transition ease-in duration-75"
                                                      leaveFrom="transform opacity-100 scale-100"
                                                      leaveTo="transform opacity-0 scale-95"
                                                    >
                                                      <div
                                                        style={{
                                                          position: "fixed",
                                                          zIndex: 100,
                                                          top: `${this.state.posY}px`,
                                                          left: `${
                                                            this.state.posX -
                                                            230
                                                          }px`,
                                                          margin: "0.5rem"
                                                        }}
                                                      >
                                                        <Menu.Items className="overscroll-none mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                          <div className="py-1">
                                                            <Menu.Item>
                                                              <button
                                                                type="button"
                                                                className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                                                                onClick={() =>
                                                                  this.deleteContentFromTable(
                                                                    "clientGroup",
                                                                    index
                                                                  )
                                                                }
                                                              >
                                                                <Icon
                                                                  name="delete"
                                                                  className="h-5 w-5 mr-2"
                                                                />
                                                                <span>
                                                                  Delete
                                                                </span>
                                                              </button>
                                                            </Menu.Item>
                                                          </div>
                                                        </Menu.Items>
                                                      </div>
                                                    </Transition>
                                                  </Menu>
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      )}
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="space-y-6 sm:space-y-5">
                          <div className="pt-2 sm:pt-5 sm:border-t sm:border-gray-200 ">
                            No Required Fields
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {/* Custom Fields Details */}
                  {this.state.tab === "custom" && (
                    <>
                      <div className="mb-6 grid grid-cols-3 gap-4">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Custom Fields Details
                        </h3>

                        {!this.state.showRequiredFieldsOnly && (
                          <>
                            <div className="w-90 sm:w-80">
                              <input
                                id="search"
                                name="search"
                                type="search"
                                value={this.state.searchCustomField}
                                placeholder="Search by Custom Field Name or Description"
                                className="appearance-none  block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 text-xs sm:text-sm"
                                onChange={this.handleCustomSearchTextChange}
                              />
                            </div>

                            <div className="grid grid-cols-2 justify-items-center">
                              <button
                                type="button"
                                className="inline-flex my-auto w-fit py-2 px-2 border border-transparent shadow-sm text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none"
                                onClick={
                                  this.state.showAllCustomField === true
                                    ? this.handleHideBlankCustomFields
                                    : this.handleShowAllCustomFields
                                }
                              >
                                {this.state.showAllCustomField === true ? (
                                  <>
                                    <Icon
                                      name="subtract"
                                      className="h-3.5 w-3.5 mr-1"
                                    />
                                    Hide Blank
                                  </>
                                ) : (
                                  <>
                                    <Icon
                                      name="add"
                                      className="h-3.5 w-3.5 mr-1"
                                    />
                                    Show All
                                  </>
                                )}
                              </button>

                              <button
                                type="button"
                                className="flex my-auto w-fit py-2 px-2 border border-transparent shadow-sm text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none"
                                onClick={() => this.openModal("customField")}
                              >
                                <Icon name="add" className="h-3.5 w-3.5 mr-1" />
                                New Custom Field
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                      {!this.state.showRequiredFieldsOnly ? (
                        <div className="space-y-6 sm:space-y-5">
                          {this.state.customFields.map(
                            (field: any, index: any) => {
                              return (
                                <div
                                  key={field.customFieldId}
                                  className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                                >
                                  <label
                                    htmlFor={field.customFieldId}
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                  >
                                    {field.customFieldName}
                                  </label>
                                  {field.customFieldType === "date" && (
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <div className="flex items-center gap-2">
                                        <input
                                          type="date"
                                          name={field.customFieldId}
                                          id={field.customFieldId}
                                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                          value={field.stringValue}
                                          onChange={this.updateCustomField(
                                            field.customFieldId
                                          )}
                                        />
                                        {field?.description && (
                                          <Popup content={field?.description}>
                                            <Icon
                                              name="information-circle"
                                              className="w-5 h-5 text-gray-600 mt-1"
                                            />
                                          </Popup>
                                        )}
                                      </div>
                                    </div>
                                  )}

                                  {field.customFieldType === "shorttext" && (
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <div className="flex items-center gap-2">
                                        <input
                                          type="text"
                                          name={field.customFieldId}
                                          id={field.customFieldId}
                                          autoComplete="off"
                                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                          value={field.stringValue}
                                          onChange={this.updateCustomField(
                                            field.customFieldId
                                          )}
                                        />
                                        {field?.description && (
                                          <Popup content={field?.description}>
                                            <Icon
                                              name="information-circle"
                                              className="w-5 h-5 text-gray-600 mt-1"
                                            />
                                          </Popup>
                                        )}
                                      </div>
                                    </div>
                                  )}

                                  {field.customFieldType === "longtext" && (
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <div className="flex items-end gap-2">
                                        <textarea
                                          name={field.customFieldId}
                                          id={field.customFieldId}
                                          rows={4}
                                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                          value={field.stringValue}
                                          onChange={this.updateCustomField(
                                            field.customFieldId
                                          )}
                                        />
                                        {field?.description && (
                                          <Popup content={field?.description}>
                                            <Icon
                                              name="information-circle"
                                              className="w-5 h-5 text-gray-600 mt-1"
                                            />
                                          </Popup>
                                        )}
                                      </div>
                                    </div>
                                  )}

                                  {field.customFieldType === "boolean" && (
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <div className="flex items-center flex-col sm:flex-row gap-3 sm:gap-6">
                                        <fieldset className="mt-2">
                                          <legend className="sr-only">
                                            {field.customFieldName}
                                          </legend>
                                          <div className="flex items-center space-y-0 space-x-10">
                                            <div className="flex items-center">
                                              <input
                                                id={`Yes-${field.customFieldId}`}
                                                name={field.customFieldId}
                                                type="radio"
                                                value="Yes"
                                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                                                checked={
                                                  field.stringValue === "Yes"
                                                }
                                                onChange={this.updateCustomField(
                                                  field.customFieldId
                                                )}
                                              />
                                              <label
                                                htmlFor={`Yes-${field.customFieldId}`}
                                                className="ml-3 block text-sm font-medium text-gray-700 cursor-pointer"
                                              >
                                                Yes
                                              </label>
                                            </div>
                                            <div className="flex items-center">
                                              <input
                                                id={`No-${field.customFieldId}`}
                                                name={field.customFieldId}
                                                type="radio"
                                                value={"No"}
                                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                                                checked={
                                                  field.stringValue === "No"
                                                }
                                                onChange={this.updateCustomField(
                                                  field.customFieldId
                                                )}
                                              />
                                              <label
                                                htmlFor={`No-${field.customFieldId}`}
                                                className="ml-3 block text-sm font-medium text-gray-700 cursor-pointer"
                                              >
                                                No
                                              </label>
                                            </div>
                                          </div>
                                        </fieldset>
                                        {field?.description && (
                                          <Popup content={field?.description}>
                                            <Icon
                                              name="information-circle"
                                              className="w-5 h-5 text-gray-600 mt-1"
                                            />
                                          </Popup>
                                        )}
                                        <button
                                          type="button"
                                          className="text-gray-700 hover:text-indigo-700 block text-sm underline mt-2"
                                          onClick={() =>
                                            this.removeCustomFieldSelection(
                                              field.customFieldId
                                            )
                                          }
                                        >
                                          Remove Selection
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      ) : (
                        <div className="space-y-6 sm:space-y-5">
                          <div className="pt-2 sm:pt-5 sm:border-t sm:border-gray-200 ">
                            No Required Fields
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {/* Login Details */}
                  {this.state.tab === "loginDetails" &&
                    (!this.state.showRequiredFieldsOnly ? (
                      <>
                        <h2 className="text-lg font-medium leading-6 text-gray-900">
                          GST Login Details
                        </h2>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="gst-username"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            GST Username
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <input
                              type="text"
                              name="gst-username"
                              id="gst-username"
                              autoComplete="off"
                              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                              placeholder="GST Username"
                              value={this.state.gstUsername}
                              onChange={this.updateState("gstUsername")}
                            />
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="gst-password"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            GST Password
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <div className="relative w-full sm:max-w-xs mt-1 flex rounded-md shadow-sm">
                              <input
                                name="gst-password"
                                type={this.state.inputGstType}
                                value={this.state.gstPassword}
                                onChange={this.updateState("gstPassword")}
                                id="gst-password"
                                className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                                placeholder="GST Password"
                                autoComplete="off"
                              />
                              <button
                                type="button"
                                onClick={() => this.onViewPassword("gst")}
                              >
                                <Icon
                                  name={this.state.iconType_gst}
                                  className="h-6 w-6 text-gray-500 absolute top-2 right-3"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="bill-username"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            E-way Bill Username
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <input
                              type="text"
                              name="bill-username"
                              id="bill-username"
                              autoComplete="off"
                              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                              placeholder="E-way Bill Username"
                              value={this.state.eWayBillUsername}
                              onChange={this.updateState("eWayBillUsername")}
                            />
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="bill-password"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            E-way Bill Password
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <div className="relative w-full sm:max-w-xs mt-1 flex rounded-md shadow-sm">
                              <input
                                name="bill-password"
                                type={this.state.inputBillType}
                                value={this.state.eWayPassword}
                                onChange={this.updateState("eWayPassword")}
                                id="bill-password"
                                className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                                placeholder="E-way Bill Password"
                                autoComplete="off"
                              />
                              <button
                                type="button"
                                onClick={() => this.onViewPassword("bill")}
                              >
                                <Icon
                                  name={this.state.iconType_bill}
                                  className="h-6 w-6 text-gray-500 absolute top-2 right-3"
                                />
                              </button>
                            </div>
                          </div>
                        </div>

                        <h2 className="text-lg font-medium leading-6 text-gray-900">
                          IT Login Details
                        </h2>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="pan"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            PAN
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0 flex items-center gap-2">
                            <input
                              type="text"
                              name="pan"
                              id="pan"
                              disabled={this.state.govtGstIsPresent}
                              autoComplete="off"
                              className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm ${
                                this.state.govtGstIsPresent
                                  ? "cursor-not-allowed"
                                  : ""
                              }`}
                              placeholder="PAN"
                              value={this.state.pan}
                              onChange={this.updateState("pan")}
                            />
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="it-password"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            IT Portal Password
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <div className="relative w-full sm:max-w-xs mt-1 flex rounded-md shadow-sm">
                              <input
                                name="it-password"
                                type={this.state.inputPortalType}
                                value={this.state.itPortalPassword}
                                onChange={this.updateState("itPortalPassword")}
                                id="it-password"
                                className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                                placeholder="IT Portal Password"
                                autoComplete="off"
                              />
                              <button
                                type="button"
                                onClick={() => this.onViewPassword("portal")}
                              >
                                <Icon
                                  name={this.state.iconType_portal}
                                  className="h-6 w-6 text-gray-500 absolute top-2 right-3"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="tan"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            TAN
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0 flex items-center gap-2">
                            <input
                              type="text"
                              name="tan"
                              id="tan"
                              autoComplete="off"
                              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                              placeholder="TAN"
                              value={this.state.tan}
                              onChange={this.updateState("tan")}
                            />
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="tanPassword"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            IT Portal Deductor Password
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <div className="relative w-full sm:max-w-xs mt-1 flex rounded-md shadow-sm">
                              <input
                                name="tanPassword"
                                type={this.state.inputTanType}
                                value={this.state.tanPassword}
                                onChange={this.updateState("tanPassword")}
                                id="tanPassword"
                                className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                                placeholder="IT Portal Deductor Password"
                                autoComplete="off"
                              />
                              <button
                                type="button"
                                onClick={() => this.onViewPassword("tan")}
                              >
                                <Icon
                                  name={this.state.iconType_tan}
                                  className="h-6 w-6 text-gray-500 absolute top-2 right-3"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="trace-username"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Traces Username
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <input
                              type="text"
                              name="trace-username"
                              id="trace-username"
                              autoComplete="off"
                              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                              placeholder="Traces Username"
                              value={this.state.tracesUsername}
                              onChange={this.updateState("tracesUsername")}
                            />
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="tracesPassword"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Traces Deductor Password
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <div className="relative w-full sm:max-w-xs mt-1 flex rounded-md shadow-sm">
                              <input
                                name="tracesPassword"
                                type={this.state.inputTraceType}
                                value={this.state.tracesPassword}
                                onChange={this.updateState("tracesPassword")}
                                id="tracesPassword"
                                className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                                placeholder="Traces Deductor Password"
                                autoComplete="off"
                              />
                              <button
                                type="button"
                                onClick={() => this.onViewPassword("trace")}
                              >
                                <Icon
                                  name={this.state.iconType_trace}
                                  className="h-6 w-6 text-gray-500 absolute top-2 right-3"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="tracesTaxPayerPassword"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Traces Taxpayer Password
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <div className="relative w-full sm:max-w-xs mt-1 flex rounded-md shadow-sm">
                              <input
                                name="tracesTaxPayerPassword"
                                type={this.state.inputTraceTaxpayerType}
                                value={this.state.tracesTaxPayerPassword}
                                onChange={this.updateState(
                                  "tracesTaxPayerPassword"
                                )}
                                id="tracesTaxPayerPassword"
                                className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                                placeholder="Traces Taxpayer Password"
                                autoComplete="off"
                              />
                              <button
                                type="button"
                                onClick={() =>
                                  this.onViewPassword("tracesTaxpayer")
                                }
                              >
                                <Icon
                                  name={this.state.iconType_traceTaxpayer}
                                  className="h-6 w-6 text-gray-500 absolute top-2 right-3"
                                />
                              </button>
                            </div>
                          </div>
                        </div>

                        <h2 className="text-lg font-medium leading-6 text-gray-900">
                          Other Login Details
                        </h2>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="mcaV2Username"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            MCA V2 Username
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <input
                              type="text"
                              name="mcaV2Username"
                              id="mcaV2Username"
                              autoComplete="off"
                              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                              placeholder="MCA V2 Username"
                              value={this.state.mcaV2Username}
                              onChange={this.updateState("mcaV2Username")}
                            />
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="mcaV2Password"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            MCA V2 Password
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <div className="relative w-full sm:max-w-xs mt-1 flex rounded-md shadow-sm">
                              <input
                                name="mcaV2Password"
                                type={this.state.inputMcaV2Type}
                                value={this.state.mcaV2Password}
                                onChange={this.updateState("mcaV2Password")}
                                id="mcaV2Password"
                                className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                                placeholder="MCA V2 Password"
                                autoComplete="off"
                              />
                              <button
                                type="button"
                                onClick={() => this.onViewPassword("mcaV2")}
                              >
                                <Icon
                                  name={this.state.iconType_mcaV2}
                                  className="h-6 w-6 text-gray-500 absolute top-2 right-3"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="mcaV3Username"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            MCA V3 Username
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <input
                              type="text"
                              name="mcaV3Username"
                              id="mcaV3Username"
                              autoComplete="off"
                              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                              placeholder="MCA V3 Username"
                              value={this.state.mcaV3Username}
                              onChange={this.updateState("mcaV3Username")}
                            />
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="mcaV3Password"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            MCA V3 Password
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <div className="relative w-full sm:max-w-xs mt-1 flex rounded-md shadow-sm">
                              <input
                                name="mcaV3Password"
                                type={this.state.inputMcaV3Type}
                                value={this.state.mcaV3Password}
                                onChange={this.updateState("mcaV3Password")}
                                id="mcaV3Password"
                                className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                                placeholder="MCA V3 Password"
                                autoComplete="off"
                              />
                              <button
                                type="button"
                                onClick={() => this.onViewPassword("mcaV3")}
                              >
                                <Icon
                                  name={this.state.iconType_mcaV3}
                                  className="h-6 w-6 text-gray-500 absolute top-2 right-3"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="dgftUsername"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            DGFT Username
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <input
                              type="text"
                              name="dgftUsername"
                              id="dgftUsername"
                              autoComplete="off"
                              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                              placeholder="DGFT Username"
                              value={this.state.dgftUsername}
                              onChange={this.updateState("dgftUsername")}
                            />
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="dgftPassword"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            DGFT Password
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <div className="relative w-full sm:max-w-xs mt-1 flex rounded-md shadow-sm">
                              <input
                                name="dgftPassword"
                                type={this.state.inputDgftType}
                                value={this.state.dgftPassword}
                                onChange={this.updateState("dgftPassword")}
                                id="dgftPassword"
                                className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                                placeholder="DGFT Password"
                                autoComplete="off"
                              />
                              <button
                                type="button"
                                onClick={() => this.onViewPassword("dgft")}
                              >
                                <Icon
                                  name={this.state.iconType_dgft}
                                  className="h-6 w-6 text-gray-500 absolute top-2 right-3"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="space-y-6 sm:space-y-5">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Login Details
                        </h3>
                        <div>
                          <div className="pt-2 sm:pt-5 sm:border-t sm:border-gray-200 ">
                            No Required Fields
                          </div>
                        </div>
                      </div>
                    ))}
                </div>

                {/* <div className="px-4 py-3 bg-gray-50 sm:hidden block">
                  <button
                    type="button"
                    className="w-full inline-flex whitespace-nowrap justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:w-32 sm:text-sm"
                    onClick={this.cancelHandler}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    disabled={this.state.logging}
                    className={
                      "mt-3 sm:ml-4 w-full inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:mt-0 sm:w-32 sm:text-sm"
                    }
                    onClick={this.addClient}
                  >
                    <span className="w-full flex justify-end">
                      {this.state.logging ? (
                        <Icon name="loading" className="mr-2" />
                      ) : null}
                    </span>
                    <span className="whitespace-nowrap">Save Client</span>
                    <span className="w-full"></span>
                  </button>
                </div> */}
              </div>
            </form>
          </div>
        </div>
      </Dashboard>
    );
  }
}

export default compose(connector, withRouter)(AddClient) as React.ComponentType;
