import React, { Fragment } from "react";
// Import to Display skeleton while loading data
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// Pagination
import Pagination from "../../components/Pagination";
import { connect, ConnectedProps } from "react-redux";
// Routing imports
// Link backend
import agent from "../../agent";
// Dashboard import
import Dashboard, { getAllStatusList } from "../../components/Dashboard";
// Icons and styling
import Icon from "../../components/Icon";
// Redux Notify
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import "../style.css";
import TagManager from "react-gtm-module";
import { Menu, Transition } from "@headlessui/react";
import InActiveModal from "../../components/InActiveModal";
import ActiveModal from "../../components/ActiveModal";
import DeleteModal from "../../components/DeleteModal";
import EditStatusModal from "./Edit";
import { withRouter } from "../../helpers/withRouter";
import { compose } from "redux";
import { colorsList } from "../../constants/colors";
import LogsModal from "../../components/LogsModal";
import Popup from "../../components/Popup";
// import InactiveTagModal from "./InactiveTagModal";
// import ActiveTagModal from "./ActiveTagModal";
// import EditTag from "./Edit";
// import DeleteTagModal from "./DeleteTagModal";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Status List"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: (payload: any) => dispatch({ type: UPDATE_COMMON, payload }),
  onNotify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class Status extends React.Component<PropsFromRedux> {
  state: {
    loading: boolean;
    posX: any;
    posY: any;
    hoverX: any;
    hoverY: any;
    showBackDrop: boolean;
    searchText: string;
    status: any;
    totalRecords: number;
    displayStatusDetails: any;
    selectedGstId: string;
    modalOpen: boolean;
    typingTimeout: number;
    selectedRow: any;
    showDeleteModal: boolean;
    showActiveModal: boolean;
    showInActiveModal: boolean;
    showEditModal: boolean;
    showLogsModal: boolean;
    active: boolean;
    skip: number;
    currPage: number;
    chunkSize: number;
  };

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      posX: null,
      posY: null,
      hoverX: null,
      hoverY: null,
      showBackDrop: false,
      searchText: "",
      status: [],
      totalRecords: 0,
      displayStatusDetails: [],
      selectedGstId: "",
      modalOpen: false,
      typingTimeout: 0,
      selectedRow: undefined,
      showDeleteModal: false,
      showActiveModal: false,
      showInActiveModal: false,
      showEditModal: false,
      showLogsModal: false,
      active: true,
      skip: 0,
      currPage: 0,
      chunkSize: 25
    };
  }

  //Get Firm Data

  getStatusList = (forSearch: boolean) => {
    const workSpaceId = (this.props as any).params?.firmId;
    const searchText = forSearch ? this.state.searchText : "";
    const active = this.state.active;
    let limit: number = this.state.chunkSize;
    let skip: number = this.state.chunkSize * this.state.currPage;
    this.setState({ loading: true });
    agent.Status.getStatusList(workSpaceId, active, searchText, limit, skip)
      .then((response: any) => {
        // const status = response.status;
        this.setState({
          status: response.status,
          loading: false,
          totalRecords: response.count,
          displayStatusDetails: response.status
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).onNotify(
          "Could not load Status Details",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  // onMount Load data
  componentDidMount() {
    document.title = "Status - TaxPido PMS";
    this.getStatusList(false);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const prevFirmId = prevProps.params?.firmId;
    const currFirmId = (this.props as any).params?.firmId;

    if (prevFirmId !== currFirmId) {
      this.setState({ searchText: "" });
      this.getStatusList(false);
      getAllStatusList(
        (this.props as any).params.firmId,
        (this.props as any).updateCommon,
        (this.props as any).onNotify
      );
    }

    const prevModal = prevProps.currentModal;
    const currentModal = (this.props as any)?.currentModal;

    if (
      prevModal?.modalName === "ADD_STATUS_MODAL" &&
      prevModal?.modalName !== currentModal?.modalName &&
      currentModal?.modalName === "" &&
      currentModal?.fetchAgain
    ) {
      this.getStatusList(false);
      getAllStatusList(
        (this.props as any).params.firmId,
        (this.props as any).updateCommon,
        (this.props as any).onNotify
      );
    }

    if (prevState.active !== this.state.active) {
      this.setState({ searchText: "" });
      this.getStatusList(false);
    }
  }

  handlePageClick = (data: any) => {
    this.setState({ currPage: data.selected }, () => {
      this.state.searchText
        ? this.getStatusList(true)
        : this.getStatusList(false);
    });
  };

  handleItemPerPage = (value: any) => {
    this.setState({ chunkSize: value.name, currPage: 0 }, () =>
      this.state.searchText
        ? this.getStatusList(true)
        : this.getStatusList(false)
    );
  };

  /* fetchRequired = () => {
    this.setState({ requireFetch: true });
  }; */

  onActionClick = (e: any) => {
    const screenClosness = window.innerHeight - e.clientY;
    const positionY = screenClosness < 145 ? e.clientY - 145 : e.clientY;
    this.setState({
      posX: e.clientX,
      posY: positionY,
      showBackDrop: true
    });
  };

  onDescriptionHover = (e: any) => {
    const pos = e.target.getClientRects()[0];
    this.setState({ hoverX: pos.x, hoverY: pos.y });
  };

  onDropdownClick = () => {
    this.setState({ showBackDrop: false });
  };

  handleSearchTextChange = (ev: any) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      searchText: ev.target.value,
      currPage: 0,
      typingTimeout: setTimeout(() => {
        this.getStatusList(true);
      }, 700)
    });
  };

  updateActive = () => {
    this.setState({ active: !this.state.active, currPage: 0 });
  };

  openAddStatusModal = () => {
    const statusRights = (this.props as any)?.rights?.statusRights;
    const createRight = statusRights.create;
    if (createRight) {
      (this.props as any).updateCommon({
        currentModal: { modalName: "ADD_STATUS_MODAL", fetchAgain: false }
      });
    } else {
      (this.props as any).onNotify(
        "Rights Not Avilable",
        "Ask Admin to change your user rights.",
        "danger"
      );
    }
  };

  closeModal = (fetchAgain: boolean) => {
    (this.props as any).updateCommon({
      currentModal: { modalName: "", fetchAgain }
    });
  };

  openActiveModal = (status: any) => {
    this.setState({ selectedRow: status, showBackDrop: false });
    this.activeModalSetOpen(true);
  };

  activeModalSetOpen = (open: boolean) => {
    this.setState({
      showActiveModal: open
    });
  };

  openInActiveModal = (status: any) => {
    this.setState({ selectedRow: status, showBackDrop: false });
    this.inActiveModalSetOpen(true);
  };

  inActiveModalSetOpen = (open: boolean) => {
    this.setState({
      showInActiveModal: open
    });
  };

  openDeleteModal = (status: any) => {
    this.setState({ selectedRow: status, showBackDrop: false });
    this.deleteModalSetOpen(true);
  };

  deleteModalSetOpen = (open: boolean) => {
    this.setState({
      showDeleteModal: open
    });
  };

  openEditModal = (status: any) => {
    const statusRights = (this.props as any)?.rights?.statusRights;
    const editRight = statusRights.edit;
    if (editRight) {
      this.setState({ selectedRow: status, showBackDrop: false });
      this.editModalSetOpen(true);
    } else {
      (this.props as any).onNotify(
        "Rights Not Avilable",
        "Ask Admin to change your user rights.",
        "danger"
      );
    }
  };

  editModalSetOpen = (open: boolean) => {
    this.setState({
      showEditModal: open
    });
  };

  openLogsModal = (tag: any) => {
    this.setState({ selectedRow: tag, showBackDrop: false });
    this.logsModalSetOpen(true);
  };

  logsModalSetOpen = (open: boolean) => {
    this.setState({
      showLogsModal: open
    });
  };

  getStatusColor = (statusColor: string) => {
    const color = colorsList?.find((color: any) => color.name === statusColor);
    return color;
  };

  onLoad = () => {
    this.setState({ searchText: "" });
    this.getStatusList(false);
    getAllStatusList(
      (this.props as any).params.firmId,
      (this.props as any).updateCommon,
      (this.props as any).onNotify
    );
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard>
        <div className="gsts">
          {this.state.showEditModal && (
            <EditStatusModal
              state={this.state}
              onLoad={() => {
                this.getStatusList(true);
                getAllStatusList(
                  (this.props as any).params.firmId,
                  (this.props as any).updateCommon,
                  (this.props as any).onNotify
                );
              }}
              editModalSetOpen={this.editModalSetOpen}
            />
          )}

          {this.state.showInActiveModal && (
            <InActiveModal
              type={"status"}
              state={this.state}
              onLoad={this.onLoad}
              inActiveModalSetOpen={this.inActiveModalSetOpen}
            />
          )}

          {this.state.showActiveModal && (
            <ActiveModal
              type={"status"}
              state={this.state}
              onLoad={this.onLoad}
              activeModalSetOpen={this.activeModalSetOpen}
            />
          )}
          {this.state.showDeleteModal && (
            <DeleteModal
              type={"status"}
              state={this.state}
              onLoad={this.onLoad}
              deleteModalSetOpen={this.deleteModalSetOpen}
            />
          )}

          {this.state.showLogsModal && (
            <LogsModal
              type={"status"}
              showLogsModal={this.state.showLogsModal}
              selectedRow={this.state.selectedRow}
              setOpen={this.logsModalSetOpen}
            />
          )}

          <div className="max-w-full mx-auto lg:mx-8">
            <h1 className="text-2xl font-semibold text-gray-900">Status</h1>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-6">
              <div className="w-fit">
                <button
                  type="button"
                  className="relative whitespace-nowrap inline-flex items-center sm:px-4 px-3 py-2 border border-transparent shadow-sm sm:text-sm text-xs font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                  onClick={this.openAddStatusModal}
                >
                  {(this.props as any)?.rights?.statusRights.create ? (
                    <Icon name="add" className="h-4 w-4 mr-2" />
                  ) : (
                    <Icon name="outline/lock-closed" className="h-4 w-4 mr-2" />
                  )}
                  Add Status
                </button>
              </div>

              {(this.state.totalRecords > 0 ||
                this.state.searchText.length > 0) && (
                <div className="w-full md:w-80">
                  <input
                    id="search"
                    name="search"
                    type="search"
                    value={this.state.searchText}
                    placeholder="Search by Status Name or Status Description"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 text-xs sm:text-sm"
                    onChange={this.handleSearchTextChange}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="relative flex items-start max-w-full mx-auto mt-6 lg:mx-8">
            <div className="flex h-5 items-center">
              <input
                id="comments"
                aria-describedby="comments-description"
                name="comments"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-400 cursor-pointer text-indigo-600 focus:ring-indigo-500"
                checked={this.state.active === false}
                onChange={this.updateActive}
              />
            </div>
            <div className="ml-3 text-sm">
              <label
                htmlFor="comments"
                className="font-medium cursor-pointer text-gray-700"
              >
                Show Inactive Status
              </label>
            </div>
          </div>

          {/* Status List Cards */}
          <div className="sm:hidden px-2 sm:px-6 mt-6">
            {!this.state.loading ? (
              this.state.totalRecords > 0 ||
              this.state.active === false ||
              this.state.searchText.length > 0 ? (
                <ul className="divide-y divide-gray-200 space-y-4">
                  {(this.state.active === false &&
                    this.state.totalRecords === 0) ||
                  (this.state.searchText && this.state.totalRecords === 0) ? (
                    <div className="bg-white shadow-md rounded-lg">
                      <div className="px-4 py-4 sm:px-6">
                        <p className="text-sm text-gray-800 text-center font-semibold">
                          {this.state.searchText &&
                            "No record found matching your search criteria"}
                          {this.state.active === false &&
                            this.state.searchText === "" &&
                            "No record of inactive Status"}
                        </p>
                      </div>
                    </div>
                  ) : (
                    this.state.displayStatusDetails.map(
                      (status: any, index: any) => (
                        <li
                          key={status._id}
                          className="text-sm bg-white shadow-md rounded-lg"
                        >
                          <div className="px-4 py-4 space-y-2">
                            <div className="flex justify-between">
                              <p className="text-sm text-gray-800 font-semibold w-[10ch] flex-grow break-words">
                                <span className="font-bold">Name - </span>
                                <span
                                  className="hover:underline"
                                  onClick={() => this.openEditModal(status)}
                                >
                                  {status.name}
                                </span>
                              </p>
                              <div className="justify-self-end">
                                <StatusListOptions
                                  {...this.props}
                                  state={this.state}
                                  smallScreen={true}
                                  status={status}
                                  onActionClick={this.onActionClick}
                                  onDropdownClick={this.onDropdownClick}
                                  openLogsModal={this.openLogsModal}
                                  openEditModal={this.openEditModal}
                                  openInActiveModal={this.openInActiveModal}
                                  openActiveModal={this.openActiveModal}
                                  openDeleteModal={this.openDeleteModal}
                                />
                              </div>
                            </div>
                            <p>
                              <span className="font-bold">Color - </span>
                              <span
                                style={{
                                  background: this.getStatusColor(status.color)
                                    ? this.getStatusColor(status.color)!.value
                                    : "#fff"
                                }}
                                className="inline-block h-3 w-3 rounded-sm mr-2 overflow-hidden"
                              ></span>
                              <span>{status.color}</span>
                            </p>
                            <p>
                              <span className="font-bold">Description - </span>
                              <span className="break-words">
                                {status.description ? status.description : "-"}
                              </span>
                            </p>
                          </div>
                        </li>
                      )
                    )
                  )}
                </ul>
              ) : (
                <div className="text-center w-full max-w-2xl mx-auto my-10 border-2 border-gray-300 border-dashed p-16 rounded-lg">
                  <Icon
                    name="outline/document-add"
                    className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                    strokeWidth="1"
                  />
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    No Status Entry
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Get started by adding a new Status.
                  </p>
                  <div className="mt-6">
                    <button
                      type="button"
                      className="relative whitespace-nowrap inline-flex items-center sm:px-4 px-3 py-2 border border-transparent shadow-sm sm:text-sm text-xs font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                      onClick={this.openAddStatusModal}
                    >
                      {(this.props as any)?.rights?.statusRights.create ? (
                        <Icon name="add" className="h-4 w-4 mr-2" />
                      ) : (
                        <Icon
                          name="outline/lock-closed"
                          className="h-4 w-4 mr-2"
                        />
                      )}
                      Add Status
                    </button>
                  </div>
                </div>
              )
            ) : (
              <div className="space-y-4">
                {[...Array(5)].map((e, i) => (
                  <div key={i} className="bg-white shadow-md rounded-lg">
                    <div
                      key={i}
                      className={`whitespace-wrap text-sm font-medium text-gray-900 px-4 py-4 sm:px-6`}
                    >
                      {[...Array(3)].map((e, i) => (
                        <Skeleton
                          key={"skeleton" + i}
                          height="1rem"
                          className="my-2"
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          {/* Status List Table */}
          <div className="hidden sm:block">
            {!this.state.loading && this.state.displayStatusDetails ? (
              this.state.totalRecords > 0 ||
              this.state.searchText.length > 0 ||
              this.state.active === false ? (
                <div className="max-w-full mx-auto">
                  <div className="mt-6 lg:mx-8 flex flex-col">
                    <div id="table-scroll" className="overflow-auto">
                      <div className="inline-block min-w-full py-2 px-1 align-middle">
                        <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                          <table className="min-w-full border-collapse border shadow-sm">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  style={{ zIndex: 6 }}
                                  scope="col"
                                  className="sticky top-0 border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6"
                                >
                                  STATUS NAME
                                </th>
                                <th
                                  style={{ zIndex: 6 }}
                                  scope="col"
                                  className="sticky top-0 border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6"
                                >
                                  DESCRIPTION
                                </th>
                                <th
                                  style={{ zIndex: 6 }}
                                  scope="col"
                                  className="sticky top-0 border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6"
                                >
                                  COLOR
                                </th>
                                <th
                                  style={{ zIndex: 6 }}
                                  scope="col"
                                  className="sticky top-0 border-b border-gray-300 bg-gray-50 px-4 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6"
                                >
                                  ACTIONS
                                </th>
                              </tr>
                            </thead>
                            {(this.state.active === false &&
                              this.state.totalRecords === 0) ||
                            (this.state.searchText &&
                              this.state.totalRecords === 0) ? (
                              <tbody>
                                <tr>
                                  <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {this.state.searchText &&
                                      "No record found matching your search criteria"}
                                    {this.state.active === false &&
                                      this.state.searchText === "" &&
                                      "No record of inactive status"}
                                  </td>
                                </tr>
                              </tbody>
                            ) : (
                              <tbody className="bg-white">
                                {this.state.displayStatusDetails?.map(
                                  (status: any, index: any) => (
                                    <tr
                                      key={status._id}
                                      className={
                                        index % 2 === 0
                                          ? undefined
                                          : "bg-gray-100"
                                      }
                                    >
                                      <td className="max-w-[20ch] whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                        <Popup
                                          content={status.name}
                                          className="w-full max-w-fit"
                                        >
                                          <p
                                            className="hover:underline font-bold truncate"
                                            onClick={() =>
                                              this.openEditModal(status)
                                            }
                                          >
                                            {status.name}
                                          </p>
                                        </Popup>
                                      </td>
                                      <td className="max-w-[20ch] whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                        <Popup
                                          content={status.description}
                                          className="w-full max-w-fit"
                                        >
                                          <p className="truncate">
                                            {status.description
                                              ? status.description
                                              : "-"}
                                          </p>
                                        </Popup>
                                      </td>
                                      <td className="max-w-fit px-6 py-3 whitespace-nowrap text-sm text-gray-900 font-bold">
                                        <div className="flex items-center">
                                          <span
                                            style={{
                                              background: this.getStatusColor(
                                                status.color
                                              )
                                                ? this.getStatusColor(
                                                    status.color
                                                  )!.value
                                                : "#fff"
                                            }}
                                            className="inline-block h-3 w-3 rounded-sm mr-2 overflow-hidden"
                                          ></span>
                                          <span>{status.color}</span>
                                        </div>
                                      </td>
                                      <td className="w-3/10 px-6 py-3 mx-4 text-center whitespace-nowrap text-sm text-gray-500">
                                        <StatusListOptions
                                          {...this.props}
                                          state={this.state}
                                          smallScreen={false}
                                          status={status}
                                          onActionClick={this.onActionClick}
                                          onDropdownClick={this.onDropdownClick}
                                          openLogsModal={this.openLogsModal}
                                          openEditModal={this.openEditModal}
                                          openInActiveModal={
                                            this.openInActiveModal
                                          }
                                          openActiveModal={this.openActiveModal}
                                          openDeleteModal={this.openDeleteModal}
                                        />
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-center w-full max-w-2xl mx-auto my-10 border-2 border-gray-300 border-dashed p-16 rounded-lg">
                  <Icon
                    name="outline/document-add"
                    className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                    strokeWidth="1"
                  />
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    No Status Entry
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Get started by adding a new Status.
                  </p>
                  <div className="mt-6">
                    <button
                      type="button"
                      className="relative whitespace-nowrap inline-flex items-center sm:px-4 px-3 py-2 border border-transparent shadow-sm sm:text-sm text-xs font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                      onClick={this.openAddStatusModal}
                    >
                      {(this.props as any)?.rights?.statusRights.create ? (
                        <Icon name="add" className="h-4 w-4 mr-2" />
                      ) : (
                        <Icon
                          name="outline/lock-closed"
                          className="h-4 w-4 mr-2"
                        />
                      )}
                      Add Status
                    </button>
                  </div>
                </div>
              )
            ) : (
              <div className={"max-w-full mx-auto lg:mx-8"}>
                <div className="py-6">
                  <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                          <table className="overflow-scroll table-auto w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="w-2/12 px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                                >
                                  STATUS NAME
                                </th>
                                <th
                                  scope="col"
                                  className="w-4/12 px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                                >
                                  DESCRIPTION
                                </th>
                                <th
                                  scope="col"
                                  className="w-2/12 px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                                >
                                  COLOR
                                </th>
                                <th
                                  scope="col"
                                  className="w-2/12 px-4 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider"
                                >
                                  ACTIONS
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {[...Array(5)].map((e, i) => (
                                <tr key={i} className="bg-white">
                                  {[...Array(4)].map((e, i) => (
                                    <td
                                      key={i}
                                      className="w-3/10 px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                    >
                                      <Skeleton />
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Pagination
            displayRecords={this.state.displayStatusDetails}
            totalRecords={this.state.totalRecords}
            currPage={this.state.currPage}
            chunkSize={this.state.chunkSize}
            handlePageClick={this.handlePageClick}
            handleItemPerPage={this.handleItemPerPage}
            className="my-4"
          />
        </div>
      </Dashboard>
    );
  }
}

interface StatusListOptionsProps {
  state: any;
  smallScreen: boolean;
  onActionClick: any;
  status: any;
  onDropdownClick: any;
  openLogsModal: any;
  openEditModal: any;
  openInActiveModal: any;
  openActiveModal: any;
  openDeleteModal: any;
}

class StatusListOptions extends React.Component<StatusListOptionsProps> {
  render() {
    return (
      <Menu as="div" className="inline-block">
        <Menu.Button onClick={this.props.onActionClick}>
          <span className="sr-only">Open options</span>
          {this.props.smallScreen ? (
            <Icon name="vertical-dots" className="h-4 w-4" aria-hidden="true" />
          ) : (
            <Icon
              name="horizontal-dots"
              className="h-5 w-5"
              aria-hidden="true"
            />
          )}
        </Menu.Button>
        {this.props.state.showBackDrop && (
          <div
            className="fixed top-0 left-0 z-10 w-full h-screen"
            onClick={this.props.onDropdownClick}
          ></div>
        )}
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div
            style={{
              position: "fixed",
              zIndex: 100,
              top: `${this.props.state.posY}px`,
              left: `${this.props.state.posX - 230}px`,
              margin: "0.5rem"
            }}
          >
            <Menu.Items className="overscroll-none mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  <button
                    className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                    onClick={() => this.props.openEditModal(this.props.status)}
                  >
                    <Icon name="edit" className="h-5 w-5 mr-2" />
                    <span>Edit</span>
                  </button>
                </Menu.Item>
                <Menu.Item>
                  {this.props.status.active ? (
                    <button
                      className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                      onClick={() =>
                        this.props.openInActiveModal(this.props.status)
                      }
                    >
                      <Icon name="warning" className="h-5 w-5 mr-2" />
                      <span>Mark Inactive</span>
                    </button>
                  ) : (
                    <button
                      className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                      onClick={() =>
                        this.props.openActiveModal(this.props.status)
                      }
                    >
                      <Icon name="warning" className="h-5 w-5 mr-2" />
                      <span>Mark Active</span>
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  <button
                    className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                    onClick={() =>
                      this.props.openDeleteModal(this.props.status)
                    }
                  >
                    <Icon name="delete" className="h-5 w-5 mr-2" />
                    <span>Delete</span>
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                    onClick={() => this.props.openLogsModal(this.props.status)}
                  >
                    <Icon
                      name="outline/document-report"
                      className="h-5 w-5 mr-2"
                    />
                    <span>Activity</span>
                  </button>
                </Menu.Item>
              </div>
            </Menu.Items>
          </div>
        </Transition>
      </Menu>
    );
  }
}

export default compose(connector, withRouter)(Status) as React.ComponentType;
