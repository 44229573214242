export const decodeHtml = (html: string) => {
  const doc = new DOMParser().parseFromString(html, "text/html").documentElement
    .textContent;
  return html && doc ? doc : "";
};

export const parseHtml = (string: string) => {
  // parse html and remove all html tags and replace line breaks with \n
  const parsedValue = string
    .replace(/(<([^>]+)>)/gi, "")
    .replace(/(\r\n|\n|\r)/gm, "\\n");
  return parsedValue;
};
