import { useState } from "react";
import { Modal } from "../../components/HeadlessUI/Modal";
import Pagination from "../../components/Pagination";
import { Results } from "../../components/Results";
import { Table, TableRow, TableCell } from "../../components/Table";
import { ItemsPerPage, itemsPerPage } from "../../constants/itemsPerPage";
import { formatDateAsDdMmmYyyy } from "../../helpers/formatDate";
import { GstDetails } from "../../helpers/types";

type GstinDetailsProps = {
  gstinDetails: GstDetails;
  loading: boolean;
  fetchGstReturnAndQrmpDetails: () => void;
  gstLastUpdated: string;
};

export function GstinDetails({ gstinDetails, ...rest }: GstinDetailsProps) {
  const [
    showAdditionalPlaceOfBusinessModal,
    setShowAdditionalPlaceOfBusinessModal
  ] = useState(false);

  const handleModalToggle = () => {
    setShowAdditionalPlaceOfBusinessModal(!showAdditionalPlaceOfBusinessModal);
  };

  return (
    <>
      <Modal
        show={showAdditionalPlaceOfBusinessModal}
        title="Additional Place Of Business"
        closeText="Close"
        handleClose={handleModalToggle}
        ModalContent={
          <AdditionalPlaceOfBusinessTable
            AdditionalPlaceOfBusiness={gstinDetails.additionalPlaceOfBusiness}
          />
        }
        className="max-w-6xl"
      />

      <Results
        heading="GSTIN Details"
        mainDetails={[
          { title: "Legal Name", value: gstinDetails.legalName },
          { title: "Trade Name", value: gstinDetails.tradeName },
          {
            title: "Registration Date",
            value: formatDateAsDdMmmYyyy(gstinDetails.registrationDate)
          },
          {
            title: `Status${
              gstinDetails.cancellationDate ? " (Cancellation date)" : ""
            }`,
            value: `${gstinDetails.gstnStatus}${
              gstinDetails.cancellationDate &&
              ` (${formatDateAsDdMmmYyyy(gstinDetails.cancellationDate)})`
            }`
          },
          {
            title: "Entity Type",
            value: gstinDetails.constitutionOfBusiness
          },
          {
            title: "Dealer Type",
            value: gstinDetails.taxpayerType
          },
          {
            title: "Principal Place Of Business",
            value: gstinDetails.principalPlaceOfBusiness.natureOfPlaceOfBusiness
          },
          {
            title: "Principal Business Address",
            value: gstinDetails.principalPlaceOfBusiness.address
          },
          {
            title: "Additional Place Of Business",
            value: (
              <button
                onClick={handleModalToggle}
                disabled={gstinDetails.additionalPlaceOfBusiness.length === 0}
                className="relative inline-flex items-center gap-4 px-8 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:bg-indigo-700 focus:outline-none disabled:bg-indigo-500 disabled:cursor-not-allowed"
              >
                {gstinDetails.additionalPlaceOfBusiness.length}
              </button>
            )
          },
          {
            title: "E Invoice Status",
            value: gstinDetails.einvoiceStatus
          },
          {
            title: "State Jurisdiction - (code)",
            value: `${gstinDetails.stateJurisdiction} - (${gstinDetails.stateJurisdictionCode})`
          },
          {
            title: "Centre Jurisdiction - (code)",
            value: `${gstinDetails.centreJurisdiction} - (${gstinDetails.centreJurisdictionCode})`
          }
        ]}
        {...rest}
      />
    </>
  );
}

function AdditionalPlaceOfBusinessTable({
  AdditionalPlaceOfBusiness
}: {
  AdditionalPlaceOfBusiness: GstDetails["additionalPlaceOfBusiness"];
}) {
  const [{ currPage, chunkSize }, setPagination] = useState<{
    currPage: number;
    chunkSize: ItemsPerPage["name"];
  }>({
    currPage: 0,
    chunkSize: itemsPerPage[1].name
  });

  const handlePagination = ({ selected: page }: { selected: number }) => {
    setPagination(prev => ({ ...prev, currPage: page }));
    const start = page * chunkSize;
    const end = start + chunkSize;
    setdetailsToShow([...AdditionalPlaceOfBusiness.slice(start, end)]);
  };

  const handleItemsPerPage = (value: ItemsPerPage) => {
    setPagination(prev => ({ ...prev, chunkSize: value.name }));
    setdetailsToShow([...AdditionalPlaceOfBusiness.slice(0, value.name)]);
  };

  const [detailsToShow, setdetailsToShow] = useState([
    ...AdditionalPlaceOfBusiness.slice(0, chunkSize)
  ]);

  return (
    <>
      <Table headers={["Place of Business", "Address"]}>
        {detailsToShow.map(place => (
          <TableRow key={place.address}>
            <TableCell className="max-w-[45ch] whitespace-break-spaces">
              {place.natureOfPlaceOfBusiness}
            </TableCell>
            <TableCell className="max-w-prose whitespace-break-spaces">
              {place.address}
            </TableCell>
          </TableRow>
        ))}
      </Table>
      <Pagination
        currPage={currPage}
        chunkSize={chunkSize}
        handlePageClick={handlePagination}
        displayRecords={AdditionalPlaceOfBusiness}
        totalRecords={AdditionalPlaceOfBusiness.length}
        handleItemPerPage={handleItemsPerPage}
      />
    </>
  );
}
