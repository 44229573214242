import { Fragment, useEffect, useState } from "react";
import Switch from "../../../components/switch";
import { withRouter, WithRouterProps } from "../../../helpers/withRouter";
import { compose } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { ADD_NOTIFICATION } from "../../../store/types";
import agent from "../../../agent";
import Icon from "../../../components/Icon";
import ErrorRow from "./ErrorRow";
import { downloadFile } from "../../../helpers/downloadFile";
import { formatDateAndTime } from "../../../helpers/formatDate";
import MessageModal from "../../../components/MessageModal";
import { AppDispatch, RootState } from "../../../store";
import { NotifyType } from "../../../store/reducers/notification";

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addNotification: (title: string, message: string, type: NotifyType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends Partial<PropsFromRedux & WithRouterProps> {
  importErrors: any;
  requestId: string;
  requestDate: string;
  logs: {
    rowsWithErr: number;
    rowsWithoutErr: number;
  };
  setLogs: any;
  goBack: () => void;
  showCancelModal: (show: boolean) => void;
  startImport: () => void;
  importing: boolean;
  getImportErrors: () => void;
  setErrorRecordDownloadStatus: (status: boolean) => void;
}

function ImportErrors(props: Props) {
  const {
    importErrors,
    requestId,
    requestDate,
    logs,
    setLogs,
    goBack,
    showCancelModal,
    startImport,
    importing,
    getImportErrors,
    setErrorRecordDownloadStatus
  } = props;

  const headers = [
    "Row",
    "Client Name",
    "Trade Name",
    "File no.",
    "GSTIN",
    "PAN",
    "Error",
    "Update"
  ];
  const workSpaceId = props.params?.firmId;

  const [loading, setLoading] = useState(false);
  const [resolveImportErrors, setResolveImportErrors] = useState(false);
  const [columnErrors, setColumnErrors] = useState<any>([]);
  const [errLogs, setErrLogs] = useState<any>({});
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const getColumnErrors = (column: string) => {
    setLoading(true);
    setColumnErrors([]);
    workSpaceId &&
      agent.Clients.getColumnErrors(
        workSpaceId,
        requestId,
        encodeURIComponent(column)
      )
        .then((res: any) => {
          setLoading(false);
          setColumnErrors(res.errors);

          setErrLogs(res.errLog);
        })
        .catch((err: any) => {
          setLoading(false);
          props.addNotification?.(
            "Error",
            `Error fetching ${column} errors`,
            "danger"
          );
        });
  };

  const [customFieldLists, setCustomFieldLists] = useState<any>([]);
  const [contactPersonList, setContactPersonList] = useState<any>([]);
  const [groupLists, setGroupLists] = useState<any>([]);

  const getCustomFieldList = () => {
    setLoading(true);
    workSpaceId &&
      agent.CustomField.getCustomFieldList(workSpaceId, true, "", 100000, 0)
        .then((response: any) => {
          setCustomFieldLists(response.customFields);
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
          props.addNotification?.(
            "Could not load Custom Field List",
            err?.response?.data?.error || err?.error || err,
            "danger"
          );
        });
  };

  const getContactPersonList = () => {
    setLoading(true);
    workSpaceId &&
      agent.ContactPerson.getPersonList(workSpaceId, true, "", 25, 0)
        .then((response: any) => {
          setContactPersonList(response.contactPerson);
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
          props.addNotification?.(
            "Could not load Contact Person List",
            err?.response?.data?.erroe || err?.erroe || err,
            "danger"
          );
        });
  };

  const getGroupList = () => {
    setLoading(true);
    workSpaceId &&
      agent.ClientGroups.getClientGroupList(workSpaceId, true, "", 0, 100000)
        .then((response: any) => {
          setGroupLists(response.groups);
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
          props.addNotification?.(
            "Could not load Group List",
            err?.response?.data?.error || err?.message || err,
            "danger"
          );
        });
  };

  const [show, setShow] = useState("");

  const showHide = (row: string) => {
    setShow(row);
    row !== "" && getColumnErrors(row);
    switch (row) {
      case "Custom Fields":
        getCustomFieldList();
        break;
      case "Contact Person" || "Primary Contact Person":
        getContactPersonList();
        break;
      case "Group 1" || "Group 2" || "Group 3":
        getGroupList();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    Object.keys(importErrors).length > 0 &&
      showHide(Object.keys(importErrors)[0]);
  }, [importErrors]);

  const openMessageModal = () => {
    logs?.rowsWithoutErr && setShowConfirmationPopup(true);
  };
  const handleImport = () => {
    startImport();
    logs?.rowsWithErr && getErrorReport();
  };
  const handleImportClick = () =>
    logs?.rowsWithErr ? openMessageModal() : handleImport();

  const getErrorReport = () => {
    workSpaceId &&
      agent.Clients.getErrorReport(workSpaceId, requestId)
        .then((res: any) => {
          setErrorRecordDownloadStatus(true);
          downloadFile(
            res,
            `Client Import Error Report ${formatDateAndTime(requestDate)}.xlsx`
          );
        })
        .catch((err: any) => {
          props.addNotification?.(
            "Error",
            "Error downloading error report",
            "danger"
          );
        });
  };

  return (
    <>
      <MessageModal
        show={showConfirmationPopup}
        data={{
          title: "Import Client",
          message: `${
            logs?.rowsWithErr > 0
              ? `There are ${logs?.rowsWithErr} Client with errors. Do you want to resolve errors or import Client?`
              : `Do you want to import ${logs?.rowsWithoutErr} Client?`
          }`,
          type: logs.rowsWithErr > 0 ? "warning" : "info"
        }}
        confirmText="Import"
        handleConfirm={handleImport}
        closeText="Resolve"
        handleClose={() => {
          setShowConfirmationPopup(false);
          setResolveImportErrors(true);
        }}
      />
      <div className="grid gap-y-6">
        <div className="bg-white py-2 rounded-b-lg overflow-hidden">
          <div className="flex flex-col">
            <div className="sm:flex-auto mx-6 py-2 border-2 border-gray-200 rounded-md">
              <h2 className="leading-6 font-semibold text-gray-900 mb-4 px-4">
                Import Validation Summary
              </h2>
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider w-1/3">
                      Clients Ready to Import
                    </th>
                    <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider w-1/3">
                      Clients Having Errors
                    </th>
                    <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider w-1/3">
                      Total Clients
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="px-6 py-3 text-center text-sm font-medium text-gray-700 uppercase tracking-wider">
                      <button
                        className={
                          logs?.rowsWithoutErr ? "text-blue-500 underline" : ""
                        }
                        onClick={handleImportClick}
                      >
                        {logs?.rowsWithoutErr ?? 0}
                      </button>
                    </td>
                    <td className="px-6 py-3 text-center text-sm font-medium text-gray-700 uppercase tracking-wider">
                      <button
                        className={
                          logs?.rowsWithErr ? "text-blue-500 underline" : ""
                        }
                        onClick={() =>
                          logs?.rowsWithErr && setResolveImportErrors(true)
                        }
                      >
                        {logs?.rowsWithErr ?? 0}
                      </button>
                    </td>
                    <td className="px-6 py-3 text-center text-sm font-medium text-gray-700 uppercase tracking-wider">
                      {(logs?.rowsWithErr ?? 0) + (logs?.rowsWithoutErr ?? 0)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {!resolveImportErrors && (
              <div className="px-4 py-3 sm:px-6 grid items-center grid-cols-1 xl:grid-flow-col gap-y-4">
                <p className="text-[13px] font-medium text-gray-600">
                  {logs.rowsWithoutErr > 0 && logs.rowsWithErr > 0
                    ? "Note:- Click on Import button to start importing clients or Resolve Errors by clicking Resolve button."
                    : logs.rowsWithoutErr > 0 && !logs.rowsWithErr
                    ? "Note:- Click on Import button to start importing clients."
                    : "Note:- Click on Resolve button to resolve errors."}
                </p>

                {/* Action Buttons */}
                <div className="justify-self-end self-end flex gap-4 items-center">
                  <BackAndCancelButton
                    goBack={goBack}
                    showCancelModal={showCancelModal}
                    loading={loading}
                  />
                  {logs.rowsWithErr > 0 && (
                    <button
                      className={`inline-flex gap-2 justify-center py-2 px-4 border shadow-sm text-sm font-medium rounded-md  focus:outline-none ${
                        logs.rowsWithoutErr > 0
                          ? "border-gray-300 bg-white disabled:bg-white hover:bg-gray-50 hover:hover:bg-gray-100"
                          : "border-indigo-600 bg-indigo-600 text-white hover:bg-indigo-700"
                      }`}
                      onClick={() => setResolveImportErrors(true)}
                      disabled={loading === true}
                    >
                      {loading ? <Icon name="loading" /> : null}
                      Resolve Errors ({logs?.rowsWithErr})
                    </button>
                  )}
                  {logs.rowsWithoutErr > 0 && (
                    <button
                      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 disabled:bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                      onClick={handleImportClick}
                      disabled={loading === true}
                    >
                      {loading ? <Icon name="loading" /> : null}
                      Import ({logs?.rowsWithoutErr})
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {resolveImportErrors && (
        <div className="bg-white pt-6 mb-3 rounded-b-lg overflow-x-auto">
          <div className="flex items-center justify-between">
            <div className="sm:flex-auto px-4 sm:px-6 mb-3">
              <h2 className="text-lg leading-6 font-medium text-gray-900">
                Import Errors
              </h2>
            </div>
            {/* {logs?.rowsWithErr > 0 && (
					<div className="relative flex gap-6 items-center max-w-full lg:mx-10">
						<div className="flex gap-2 h-5 items-center">
							<input
								id="groupByColumns"
								name="groupByColumns"
								type="checkbox"
								className="h-4 w-4 rounded border-gray-400 cursor-pointer text-indigo-600 focus:ring-indigo-500"
								defaultChecked={true}
								// checked={state.active === false}
								// onChange={updateActive}
							/>
							<label
								htmlFor="groupByColumns"
								className="font-medium cursor-pointer text-gray-700 text-sm"
							>
								Group by Columns
							</label>
						</div>
						<div className="flex gap-2 h-5 items-center">
							<input
								id="groupByRows"
								name="groupByRows"
								type="checkbox"
								className="h-4 w-4 rounded border-gray-400 cursor-pointer peer disabled:cursor-auto text-indigo-600 focus:ring-indigo-500"
								disabled
								// checked={state.active === false}
								// onChange={updateActive}
							/>
							<label
								htmlFor="groupByRows"
								className="font-medium cursor-pointer text-gray-700 text-sm peer-disabled:cursor-auto"
							>
								Group by Rows
							</label>
						</div>
					</div>
				)} */}
          </div>
          <div className="mt-4 flex flex-col">
            <div className="overflow-x-auto">
              <div className="block min-w-full align-middle">
                <div className="overflow-hidden ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  {Object.keys(importErrors)?.length > 0 ? (
                    Object.keys(importErrors).map(
                      (errorField: any, index: number) => (
                        <div key={`${errorField}-table`}>
                          <div
                            className="bg-gray-50 px-4 text-left text-sm font-semibold text-gray-900 sm:px-6 border-t border-gray-200 divide-x divide-gray-200"
                            onClick={() =>
                              showHide(show === errorField ? "" : errorField)
                            }
                          >
                            <span className="grid grid-cols-2 gap-4 pt-2 pb-2">
                              <span className="flex gap-x-3 items-center">
                                <span
                                  className={`${
                                    show && show === errorField
                                      ? "font-bold"
                                      : "font-semibold"
                                  }`}
                                >
                                  {errorField}
                                </span>{" "}
                                <span className="text-red-500">
                                  ({importErrors[errorField].count})
                                </span>
                              </span>
                              <span className="flex gap-3 items-center place-self-end mr-4">
                                {show === errorField && loading && (
                                  <Icon
                                    name="loading"
                                    className="h-3.5 w-3.5"
                                  />
                                )}
                                <span>Hide</span>
                                <Switch
                                  openIcon="subtract"
                                  closeIcon="add"
                                  label={"Hide Show Import Errors"}
                                  enabled={show === errorField}
                                  onChange={() =>
                                    showHide(
                                      show === errorField ? "" : errorField
                                    )
                                  }
                                />
                                <span>Show</span>
                              </span>
                            </span>
                          </div>
                          {show === errorField && (
                            <div
                              className="justify-center mx-6 my-1 overflow-auto border-2 border-gray-300 rounded-md"
                              id="table-scroll"
                            >
                              <table className="bg-white min-w-full">
                                {columnErrors?.map(
                                  (column: any, index: number) => (
                                    <Fragment
                                      key={`${errorField}-${column.error}-row`}
                                    >
                                      <thead className="bg-gray-50 divide-x divide-gray-200">
                                        <tr className="border-b-2">
                                          <th
                                            colSpan={headers?.length}
                                            scope="colgroup"
                                            className="py-3.5 whitespace-nowrap text-sm text-gray-900 font-semibold sm:px-6 pl-6 text-left"
                                          >
                                            Error Type - {column?.error}{" "}
                                            <span className="text-red-500">
                                              ({errLogs[column?.error]})
                                            </span>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody
                                        className={`divide-y divide-gray-200 ${
                                          index !== columnErrors.length - 1 &&
                                          "border-b-2"
                                        }`}
                                      >
                                        <tr className="divide-x divide-gray-200">
                                          {headers.find(
                                            (header: any, index: number) => {
                                              header === errorField &&
                                                headers.splice(
                                                  headers.length - 2,
                                                  0,
                                                  ...headers.splice(index, 1)
                                                );
                                              return;
                                            }
                                          )}
                                          {headers.map(
                                            (header: any, index: number) => (
                                              <td
                                                key={`${errorField}-${header}`}
                                                className={`px-4 py-3.5 whitespace-nowrap text-sm font-medium text-gray-900 sm:px-6 text-center
																								} ${index !== headers.length - 2 && header === "Error" ? "hidden" : ""}`}
                                              >
                                                {header}
                                              </td>
                                            )
                                          )}
                                        </tr>
                                        {column?.rows?.map((row: any) => {
                                          return (
                                            <ErrorRow
                                              key={row?._id}
                                              row={row}
                                              headers={headers}
                                              requestId={requestId}
                                              importErrors={importErrors}
                                              errorField={errorField}
                                              errLogs={errLogs}
                                              err={column?.error}
                                              getColumnErrors={() =>
                                                getColumnErrors(errorField)
                                              }
                                              getImportErrors={getImportErrors}
                                              logs={logs}
                                              setLogs={setLogs}
                                              customFieldLists={
                                                customFieldLists
                                              }
                                              contactPersonList={
                                                contactPersonList
                                              }
                                              groupLists={groupLists}
                                            />
                                          );
                                        })}
                                      </tbody>
                                    </Fragment>
                                  )
                                )}
                              </table>
                            </div>
                          )}
                        </div>
                      )
                    )
                  ) : (
                    <div className="bg-gray-50 p-4 text-left text-sm font-semibold text-gray-900 sm:px-6 border-t border-gray-200 divide-x divide-gray-200">
                      All Errors Resolved Click Import to Import the Clients.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Action Buttons */}
          <div className="px-4 py-4 text-right sm:px-6 border-t-2 border-t-gray-200">
            <div className="flex items-center gap-4 justify-end mb-2">
              <BackAndCancelButton
                goBack={goBack}
                showCancelModal={showCancelModal}
                loading={loading}
              />
              <button
                className="inline-flex gap-2 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 disabled:bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                onClick={handleImportClick}
                disabled={importing === true || !logs.rowsWithoutErr}
              >
                {importing ? <Icon name="loading" /> : null}
                Import ({logs?.rowsWithoutErr})
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default compose(
  connector,
  withRouter
)(ImportErrors) as React.ComponentType<any>;

function BackAndCancelButton({
  goBack,
  showCancelModal,
  loading
}: {
  goBack: () => void;
  showCancelModal: (value: boolean) => void;
  loading: boolean;
}) {
  return (
    <>
      <button
        type="button"
        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 disabled:bg-white hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
        disabled={loading === true}
        onClick={goBack}
      >
        Go Back
      </button>
      <button
        type="button"
        className="bg-red-600 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white disabled:bg-white hover:bg-red-700 focus:bg-red-700 focus:outline-none"
        disabled={loading === true}
        onClick={() => showCancelModal(true)}
      >
        Delete
      </button>
    </>
  );
}
