import { Component } from "react";
import { ADD_NOTIFICATION } from "../../store/types";
import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "../../helpers/withRouter";
import { compose } from "redux";
import Dashboard from "../../components/Dashboard";
import { Link } from "react-router-dom";
import Switch from "../../components/switch";
import Icon from "../../components/Icon";

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const yourTimeReport = {
  heading: (report: string) => `Your ${report} Report`,
  desc: (report: string) => `Shows your ${report} against various tasks`,
  link: (report: string) => `your-${report}-report`
};
const currentlyWorkingReport = {
  heading: () => "Currently Working Report",
  desc: () => `Shows the task on which users are currently working`,
  link: () => "currently-working-report"
};
const userWiseReport = {
  heading: () => "User Wise Report",
  desc: (report: string) => `Shows ${report} by users`,
  link: () => "user-wise-report"
};
const clientWiseReport = {
  heading: () => "Client Wise Report",
  desc: (report: string) => `Shows ${report} by clients`,
  link: () => "client-wise-report"
};
const advancedReport = {
  heading: () => "Advance Report",
  desc: (report: string) =>
    `Shows all ${report} by users with advance filters options`,
  link: () => "advance-report"
};

interface State {
  loading: boolean;
  reportGroups: { [key: string]: boolean };
  reports: {
    heading: (report: string) => string;
    desc: (report: string) => string;
    link: (report: string) => string;
  }[];
}

class Reports extends Component<PropsFromRedux, State> {
  constructor(props: any) {
    const admin = (props as any)?.rights?.role === "admin";
    const manager = (props as any)?.rights?.role === "manager";
    const managerCanViewAll =
      (props as any)?.rights?.taskRights?.view === "all tasks";

    super(props);
    this.state = {
      loading: false,
      reportGroups: {
        "Time Tracking": true,
        "Activity Logs": true
      },
      reports: [
        yourTimeReport,
        // ...(admin || manager ? [currentlyWorkingReport] : []),
        ...(admin || manager /*  && managerCanViewAll */
          ? [userWiseReport, clientWiseReport, advancedReport]
          : [])
      ]
    };
  }

  showHide = (id: string) =>
    this.setState({
      reportGroups: {
        ...this.state.reportGroups,
        [id]: !this.state.reportGroups[id]
      }
    });

  componentDidMount(): void {
    document.title = "Reports - TaxPido PMS";
  }

  componentDidUpdate(prevProps: unknown, prevState: State): void {
    if (
      (this.props as any)?.rights?.role !== (prevProps as any)?.rights?.role
    ) {
      const admin = (this.props as any)?.rights?.role === "admin";
      const manager = (this.props as any)?.rights?.role === "manager";
      const managerCanViewAll =
        (this.props as any)?.rights?.taskRights?.view ===
        "all tasks other than those alloted to admins only";

      this.setState({
        reports: [
          yourTimeReport,
          // ...(admin || manager ? [currentlyWorkingReport] : []),
          ...(admin || manager /*  && managerCanViewAll */
            ? [userWiseReport, clientWiseReport, advancedReport]
            : [])
        ]
      });
    }
  }

  render() {
    return (
      <Dashboard>
        <div className="max-w-full mx-auto lg:mx-8">
          <h1 className="text-2xl font-semibold text-gray-900">Reports</h1>

          <div className="space-y-6 mt-6">
            {Object.entries(this.state.reportGroups).map(([key, value]) => (
              <div
                key={key}
                className={`${
                  value ? "py-6" : "py-4"
                } px-6 rounded-md bg-white shadow-md`}
              >
                <div
                  className="grid grid-cols-2 gap-4 bg-white rounded-lg cursor-pointer"
                  onClick={() => this.showHide(key)}
                >
                  <div className="flex gap-x-3 items-center">
                    <h2 className="text-xl font-semibold text-gray-900">
                      {key}
                    </h2>
                  </div>
                  <span className="flex gap-3 items-center place-self-end mr-4">
                    {value && this.state.loading && (
                      <Icon name="loading" className="h-3.5 w-3.5" />
                    )}
                    <span>Hide</span>
                    <Switch
                      openIcon="subtract"
                      closeIcon="add"
                      label={`Expand Collapse ${key} Report Group`}
                      enabled={value}
                      onChange={() => this.showHide(key)}
                    />
                    <span>Show</span>
                  </span>
                </div>
                {value && (
                  <div className="pt-8 grid grid-cols-2 lg:grid-cols-3 gap-4">
                    {this.state.reports.map(report => (
                      <Link
                        key={report?.link("report")}
                        to={`/${(this.props as any).params.firmId}/reports/${key
                          .toLowerCase()
                          .replace(" ", "-")}/${report?.link(
                          key?.toLowerCase() === "time tracking"
                            ? "time"
                            : "activity"
                        )}`}
                        className="bg-white hover:shadow-lg rounded-lg px-4 md:px-6 py-4 grid xl:grid-flow-col gap-4 border-2"
                      >
                        <div className="space-y-3">
                          <h2 className="text-base leading-6 font-semibold text-gray-900 capitalize">
                            {report?.heading(
                              key?.toLowerCase() === "time tracking"
                                ? "time"
                                : "activity"
                            )}
                          </h2>
                          <p className="text-sm leading-5 text-gray-500">
                            {report?.desc(
                              key?.toLowerCase() === "time tracking"
                                ? "time tracked"
                                : "activity"
                            )}
                          </p>
                        </div>
                        <div className="justify-self-center xl:place-self-end max-w-fit inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                          View
                        </div>
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </Dashboard>
    );
  }
}

export default compose(connector, withRouter)(Reports) as React.ComponentType;
