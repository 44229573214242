import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { connect, ConnectedProps } from "react-redux";
import agent from "../../agent";
import Icon from "../../components/Icon";
import MultiSelect from "../../components/MultiSelect";
import { formatClientName } from "../../helpers/formatClientName";
import { formatDate } from "../../helpers/formatDate";
import { Client } from "../../helpers/types";
import { ADD_NOTIFICATION } from "../../store/types";
import AddModal from "./AddModal";

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const amountRegex = /^\d+(\.\d{0,2})?$/;

type Props = {
  props: any;
  closeModal: (fetchAgain: boolean) => void;
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class AddReceipt extends React.Component<Props, PropsFromRedux> {
  state: {
    logging: boolean;
    parentModalVisible: boolean;
    showAddModal: boolean;
    modalType: string;
    workSpaceId: string;
    clientList: Client[];
    sourceList: any[];
    typeList: any[];
    categoryList: any[];
    addTemp: {
      date: string;
      amount: string;
      type: string;
      sourceId: string;
      clientId: string;
      categoryId: string;
      notes: string;
      isVerified: boolean;
    };
  };

  constructor(props: any) {
    super(props);

    this.state = {
      logging: false,
      parentModalVisible: true,
      showAddModal: false,
      modalType: "",
      workSpaceId: (this.props as any).currentFirm._id,
      clientList: [],
      sourceList: [],
      typeList: [
        { name: "Paid" },
        { name: "Received" },
        { name: "Payable" },
        { name: "Receivable" }
      ],
      categoryList: [],
      addTemp: {
        date: new Date().toISOString(),
        type: "",
        amount: "",
        sourceId: "",
        clientId: "",
        categoryId: "",
        notes: "",
        isVerified: false
      }
    };
  }

  onKeyUpFunction = (event: KeyboardEvent) => {
    if (!this.state.showAddModal) {
      if (event.code === "Escape") {
        this.closeReceiptModal(false);
      }

      if (event.code === "Enter" && !event.shiftKey) {
        this.addReceipt();
      }
    }
  };

  getClients = () => {
    this.setState({ logging: true });
    agent.Clients.getClientList(this.state.workSpaceId, 0, 1000, "", true)
      .then((res: any) => {
        this.setState({
          clientList: res.clients,
          logging: false,
          addTemp: {
            ...this.state.addTemp,
            clientId: res.count === 1 ? res.clients[0]._id : ""
          }
        });
      })
      .catch((err: any) => {
        this.setState({ logging: false });
        (this.props as any).addNotification(
          "Error",
          "Error while fetching clients",
          "error"
        );
      });
  };

  getCategoryList = () => {
    this.setState({ logging: true });
    agent.Receipt.getCategoryList(this.state.workSpaceId, true, "", 0, 100000)
      .then((res: any) => {
        this.setState({
          categoryList: res.categories,
          logging: false,
          addTemp: {
            ...this.state.addTemp,
            categoryId: res.count === 1 ? res.categories[0]._id : ""
          }
        });
      })
      .catch((err: any) => {
        this.setState({ logging: false });
        (this.props as any).onNotify(
          "Could not load Category Details",
          err?.response?.data?.error || err?.message || err,
          "danger"
        );
      });
  };

  getSourceList = () => {
    this.setState({ logging: true });
    agent.Receipt.getSourceList(this.state.workSpaceId, true, "", 0, 100000)
      .then((res: any) => {
        this.setState({
          sourceList: res.sources,
          logging: false,
          addTemp: {
            ...this.state.addTemp,
            sourceId: res.count === 1 ? res.sources[0]._id : ""
          }
        });
      })
      .catch((err: any) => {
        this.setState({ logging: false });
        (this.props as any).onNotify(
          "Could not load Source Details",
          err?.response?.data?.error || err?.message || err,
          "danger"
        );
      });
  };

  updateState = (field: string) => (ev: any) => {
    this.setState({
      addTemp: {
        ...this.state.addTemp,
        [field]:
          field === "date"
            ? new Date(ev.target.value).toISOString()
            : ev.target.value
      }
    });
  };

  onAmountChange = (e: any) => {
    if (e.target.value === "" || amountRegex.test(e.target.value)) {
      this.setState({
        addTemp: {
          ...this.state.addTemp,
          [e.target.name]: e.target.value
        }
      });
    }
  };

  onAmountBlur = (e: any) => {
    if (e.target.value === "") {
      return;
    } else if (e.target.value && amountRegex.test(e.target.value)) {
      this.setState({
        addTemp: {
          ...this.state.addTemp,
          [e.target.name]:
            e.target.value.split(".")[1] > 0
              ? e.target.value.split(".")[1].length < 2
                ? e.target.value.split(".")[0] +
                  "." +
                  e.target.value.split(".")[1] +
                  "0"
                : e.target.value.split(".")[0] +
                  "." +
                  e.target.value.split(".")[1]
              : e.target.value.split(".")[0] + ".00"
        }
      });
    }
  };

  addReceipt = () => {
    if (!this.state.addTemp.date) {
      (this.props as any).addNotification(
        "Empty Receipt Date Field",
        "Receipt Date Field is Required!.",
        "danger"
      );
    } else if (this.state.addTemp.amount === "") {
      (this.props as any).addNotification(
        "Empty Receipt Amount Field",
        "Receipt Amount Field is Required!.",
        "danger"
      );
    } else if (this.state.addTemp.type === "") {
      (this.props as any).addNotification(
        "Empty Receipt Type Field",
        "Receipt Type Field is Required!.",
        "danger"
      );
    } else if (this.state.addTemp.sourceId === "") {
      (this.props as any).addNotification(
        "Empty Receipt Source Field",
        "Receipt Source Field is Required!.",
        "danger"
      );
    } else {
      this.setState({ logging: true });
      agent.Receipt.addReceipt({
        ...this.state.addTemp,
        workSpaceId: this.state.workSpaceId
      })
        .then((res: any) => {
          this.setState({ logging: false });
          (this.props as any).addNotification(
            "Success",
            "Receipt Added Successfully!.",
            "success"
          );
          this.closeReceiptModal(true);
        })
        .catch((err: any) => {
          this.setState({ logging: false });
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.error || err?.message || err,
            "danger"
          );
        });
    }
  };

  closeReceiptModal = (fetchAgain: boolean) => {
    this.props.closeModal(fetchAgain);
  };

  openAddModal = (type: string) => {
    this.setState({
      showAddModal: true,
      modalType: type,
      showBackDrop: false,
      parentModalVisible: false
    });
  };

  closeAddModal = () => {
    this.setState({
      showAddModal: false,
      modalType: "",
      parentModalVisible: true
    });
  };

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyUpFunction, false);
    this.getClients();
    this.getCategoryList();
    this.getSourceList();
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyUpFunction, false);
  }

  render() {
    return (
      <>
        {this.state.showAddModal && (
          <AddModal
            modalType={this.state.modalType}
            showParentModal={() => this.setState({ parentModalVisible: true })}
            closeParentModal={() => this.closeReceiptModal(false)}
            showModal={this.state.showAddModal}
            closeModal={this.closeAddModal}
            onLoad={
              this.state.modalType === "addSource"
                ? this.getSourceList
                : this.getCategoryList
            }
          />
        )}
        <Transition.Root
          show={
            (this.props as any)?.currentModal?.modalName ===
              "ADD_RECEIPT_MODAL" && this.state.parentModalVisible
          }
          as={Fragment}
        >
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={() => null}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div>
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Add Receipt
                      </h3>
                    </div>
                    <div>
                      <form onSubmit={e => e.preventDefault()}>
                        <div className="mt-4">
                          <label
                            htmlFor="date"
                            className="mt-2 block text-sm font-medium text-gray-700"
                          >
                            Date <span className="text-red-500">*</span>
                          </label>
                          <div className="my-1">
                            <input
                              type="date"
                              name="date"
                              id="date"
                              value={formatDate(this.state.addTemp.date, true)}
                              onChange={this.updateState("date")}
                              required
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                          <label
                            htmlFor="amount"
                            className="mt-2 block text-sm font-medium text-gray-700"
                          >
                            Amount <span className="text-red-500">*</span>
                          </label>
                          <div className="my-1">
                            <input
                              type="number"
                              name="amount"
                              id="amount"
                              title="Receipt amount should be a valid number with up to 2 decimal places"
                              placeholder="Amount"
                              value={this.state.addTemp.amount}
                              onChange={this.onAmountChange}
                              onBlur={this.onAmountBlur}
                              required
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                          <label
                            htmlFor="type"
                            className="mt-2 block text-sm font-medium text-gray-700"
                          >
                            Type <span className="text-red-500">*</span>
                          </label>
                          <div className="my-1">
                            <MultiSelect
                              items={this.state.typeList?.map((item: any) => {
                                return {
                                  ...item,
                                  _id: item.name,
                                  name: item.name
                                };
                              })}
                              selected={{
                                name: this.state.addTemp.type
                              }}
                              type="type"
                              onChange={selected => {
                                this.setState({
                                  addTemp: {
                                    ...this.state.addTemp,
                                    type: selected.name
                                  }
                                });
                              }}
                              placeholder="Select Type"
                            />
                          </div>
                          <label
                            htmlFor="source"
                            className="mt-2 block text-sm font-medium text-gray-700"
                          >
                            Source <span className="text-red-500">*</span>
                          </label>
                          <div className="my-1">
                            <MultiSelect
                              items={this.state.sourceList}
                              selected={{
                                name:
                                  this.state.sourceList.find(
                                    item =>
                                      item._id === this.state.addTemp?.sourceId
                                  )?.name || ""
                              }}
                              type="type"
                              onChange={(selected: any) => {
                                this.setState({
                                  addTemp: {
                                    ...this.state.addTemp,
                                    sourceId: selected._id
                                  }
                                });
                              }}
                              placeholder="Select Source"
                            />
                          </div>
                          <div className="my-2">
                            <button
                              type="button"
                              className="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:bg-indigo-200 focus:outline-none"
                              onClick={() => this.openAddModal("addSource")}
                            >
                              <Icon name="add" className="h-3.5 w-3.5 mr-1" />
                              Add Source
                            </button>
                          </div>
                          <label
                            htmlFor="client"
                            className="mt-2 block text-sm font-medium text-gray-700"
                          >
                            Client
                          </label>
                          <div className="my-1">
                            <MultiSelect
                              items={this.state.clientList.map(item => {
                                return {
                                  ...item,
                                  _id: item._id,
                                  name: formatClientName(item)
                                };
                              })}
                              selected={{
                                name:
                                  formatClientName(
                                    this.state.clientList.find(
                                      item =>
                                        item._id ===
                                        this.state.addTemp?.clientId
                                    )
                                  ) || ""
                              }}
                              type="type"
                              onChange={(selected: any) => {
                                this.setState({
                                  addTemp: {
                                    ...this.state.addTemp,
                                    clientId: selected._id
                                  }
                                });
                              }}
                              placeholder="Select Client"
                            />
                          </div>
                          <label
                            htmlFor="category"
                            className="mt-2 block text-sm font-medium text-gray-700"
                          >
                            Category
                          </label>
                          <div className="my-1">
                            <MultiSelect
                              items={this.state.categoryList}
                              selected={{
                                name:
                                  this.state.categoryList.find(
                                    item =>
                                      item._id ===
                                      this.state.addTemp?.categoryId
                                  )?.name || ""
                              }}
                              type="type"
                              onChange={(selected: any) => {
                                this.setState({
                                  addTemp: {
                                    ...this.state.addTemp,
                                    categoryId: selected._id
                                  }
                                });
                              }}
                              placeholder="Select Category"
                            />
                          </div>
                          <div className="my-2">
                            <button
                              type="button"
                              className="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:bg-indigo-200 focus:outline-none"
                              onClick={() => this.openAddModal("addCategory")}
                            >
                              <Icon name="add" className="h-3.5 w-3.5 mr-1" />
                              Add Category
                            </button>
                          </div>
                          <label
                            htmlFor="notes"
                            className="mt-2 block text-sm font-medium text-gray-700"
                          >
                            Notes
                          </label>
                          <div className="my-1">
                            <textarea
                              id="notes"
                              name="notes"
                              rows={3}
                              placeholder="Notes"
                              value={this.state.addTemp.notes}
                              onChange={this.updateState("notes")}
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              onKeyDown={e => {
                                if (e.key === "Enter" && !e.shiftKey) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            <span className="text-xs mt-3">
                              Shift + Enter to move to the next line
                            </span>
                          </div>
                          <div className="my-2">
                            <label
                              htmlFor="isVerified"
                              className="inline-flex items-center cursor-pointer"
                            >
                              <input
                                id="isVerified"
                                name="isVerified"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-400 cursor-pointer text-indigo-600 focus:ring-indigo-500"
                                checked={this.state.addTemp.isVerified}
                                onChange={() =>
                                  this.setState({
                                    addTemp: {
                                      ...this.state.addTemp,
                                      isVerified: !this.state.addTemp.isVerified
                                    }
                                  })
                                }
                              />
                              <span className="ml-2 text-sm text-gray-700">
                                Verified
                              </span>
                            </label>
                          </div>
                        </div>

                        <div className="mt-5 sm:mt-4 sm:flex sm:justify-end">
                          <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:bg-gray-100 focus:outline-none sm:w-32 sm:text-sm"
                            onClick={() => this.closeReceiptModal(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            disabled={this.state.logging}
                            className={
                              "mt-3 sm:ml-4 w-full inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:bg-indigo-800 focus:outline-none sm:mt-0 sm:w-32 sm:text-sm"
                            }
                            onClick={this.addReceipt}
                          >
                            <span className="w-full flex justify-end">
                              {this.state.logging ? (
                                <Icon name="loading" className="mr-2 w-4 h-4" />
                              ) : null}
                            </span>
                            <span>Save</span>
                            <span className="w-full"></span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

export default connector(AddReceipt);
