import { Menu, Transition } from "@headlessui/react";

import { Component, Fragment } from "react";
import Icon from "../../components/Icon";
import Popup from "../../components/Popup";
import { formatDateTimeString } from "../../helpers/formatDate";
import DeleteModal from "../../components/DeleteModal";
import EditCommentModal from "../TimeTracking/EditCommentModal";
import { minutesToHour } from "../../helpers/minutesToHour";

interface Props {
  logs: any[];
  users: any[];
  onLoad: () => void;
  params: { report: string };
  showTimeInHrMin: boolean;
}

interface State {
  showBackDrop: boolean;
  showEditModal: boolean;
  showDeleteModal: boolean;
  selectedRow: any;
  posY: number;
  posX: number;
}

const timeTrackingReportheaders = [
  "type",
  "Client Name",
  "User Name",
  "Task",
  "Start Time",
  "End Time",
  "Total Time",
  "Comment",
  "Action"
] as const;

class TimeTrackingReport extends Component<Props, State> {
  state: State = {
    showBackDrop: false,
    showEditModal: false,
    showDeleteModal: false,
    selectedRow: {},
    posY: 0,
    posX: 0
  };

  headers = () => {
    const report = this.props.params.report;
    return report === "your-time-report"
      ? timeTrackingReportheaders.filter(item => item !== "User Name")
      : report === "currently-working-report"
      ? timeTrackingReportheaders.filter(item => item !== "Client Name")
      : report === "user-wise-report" ||
        report === "client-wise-report" ||
        report === "advance-report"
      ? timeTrackingReportheaders
      : [];
  };

  onActionClick = (e: any) => {
    const screenClosness = window.innerHeight - e.clientY;
    const positionY = screenClosness < 145 ? e.clientY - 70 : e.clientY;
    this.setState({
      posX: e.clientX,
      posY: positionY,
      showBackDrop: true
    });
  };

  onDropdownClick = () => {
    this.setState({ showBackDrop: false });
  };

  openEditModal = (item: { _id: string }) => {
    this.setState({ selectedRow: item, showBackDrop: false });
    this.editModalSetOpen(true);
  };

  editModalSetOpen = (open: boolean) => {
    this.setState({ showEditModal: open });
  };

  openDeleteModal = (item: { _id: string }) => {
    this.setState({ selectedRow: item, showBackDrop: false });
    this.deleteModalSetOpen(true);
  };

  deleteModalSetOpen = (open: boolean) => {
    this.setState({ showDeleteModal: open });
  };

  render() {
    const { logs, onLoad } = this.props;

    return (
      <>
        {this.state.showEditModal && (
          <EditCommentModal
            state={this.state}
            onLoad={onLoad}
            editModalSetOpen={this.editModalSetOpen}
          />
        )}
        {this.state.showDeleteModal && (
          <DeleteModal
            type="time tracking"
            state={this.state}
            onLoad={onLoad}
            deleteModalSetOpen={this.deleteModalSetOpen}
          />
        )}
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              {this.headers().map((header: string, index: number) => (
                <th
                  key={`th-${header}-${index}`}
                  scope="col"
                  className={`px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider ${
                    header === "Action" ? "text-center" : "text-left"
                  }`}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {logs.map((item: any, index: number) => {
              const userName = this.props?.users?.find(
                (user: any) => user._id === item.userId
              )?.name;

              return (
                <tr
                  key={`${this.props.params.report}-${index}`}
                  className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                >
                  <td className="whitespace-nowrap py-2 px-4 text-sm text-gray-800">
                    {item.type.charAt(0).toUpperCase()}
                  </td>
                  {this.props.params.report !== "currently-working-report" && (
                    <td className="max-w-[12ch] whitespace-nowrap py-2 px-4 text-sm text-gray-800">
                      <Popup
                        content={item?.clientName ?? "-"}
                        className="w-full max-w-fit"
                      >
                        <p className="hover:underline font-bold truncate">
                          {item?.clientName ?? "-"}
                        </p>
                      </Popup>
                    </td>
                  )}
                  {this.props.params.report !== "your-time-report" && (
                    <td className="max-w-[12ch] whitespace-nowrap py-2 px-4 text-sm text-gray-800">
                      <Popup
                        content={userName ?? "-"}
                        className="w-full max-w-fit"
                      >
                        <p className="hover:underline font-bold truncate">
                          {userName ?? "-"}
                        </p>
                      </Popup>
                    </td>
                  )}
                  <td className="max-w-[12ch] whitespace-nowrap py-2 px-4 text-sm text-gray-800">
                    <Popup
                      content={`${item?.taskName ?? "-"} ${
                        item?.period ? `(${item?.period})` : ""
                      }`}
                      className="w-full max-w-fit"
                    >
                      <p className="hover:underline font-bold truncate">
                        {`${item?.taskName ?? "-"} ${
                          item?.period ? `(${item?.period})` : ""
                        }`}
                      </p>
                    </Popup>
                  </td>
                  <td className="whitespace-nowrap py-2 px-4 text-sm text-gray-800">
                    {formatDateTimeString(item.startTime) ?? "-"}
                  </td>
                  <td className="whitespace-nowrap py-2 px-4 text-sm text-gray-800">
                    {item.stopTime ? formatDateTimeString(item.stopTime) : "-"}
                  </td>
                  <td className="whitespace-nowrap py-2 px-4 text-sm text-gray-800">
                    {this.props.showTimeInHrMin && item.totalTime
                      ? `${minutesToHour(item.totalTime)}` ?? "-"
                      : item.totalTime + " mins" ?? "-"}
                  </td>
                  <td className="max-w-[15ch] whitespace-nowrap py-2 px-4 text-sm text-gray-800">
                    <Popup
                      content={item.comment ?? "-"}
                      className="w-full max-w-fit"
                    >
                      <p className="hover:underline font-bold truncate">
                        {item.comment ?? "-"}
                      </p>
                    </Popup>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize text-center">
                    <Menu as="div" className="inline-block relative">
                      <Menu.Button onClick={this.onActionClick}>
                        <span className="sr-only">Open options</span>
                        <Icon
                          name="horizontal-dots"
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                      {this.state.showBackDrop && (
                        <div
                          className="fixed top-0 left-0 z-10 w-full h-screen"
                          onClick={this.onDropdownClick}
                        ></div>
                      )}
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <div
                          style={{
                            position: "fixed",
                            zIndex: 100,
                            top: `${this.state.posY}px`,
                            left: `${this.state.posX - 150}px`,
                            margin: "0.5rem"
                          }}
                        >
                          <Menu.Items className="overscroll-none mt-1 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                <button
                                  className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                                  onClick={() => this.openEditModal(item)}
                                >
                                  <Icon name="edit" className="h-5 w-5 mr-2" />
                                  <span>Edit comment</span>
                                </button>
                              </Menu.Item>
                              <Menu.Item>
                                <button
                                  className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                                  onClick={() => this.openDeleteModal(item)}
                                >
                                  <Icon
                                    name="delete"
                                    className="h-5 w-5 mr-2"
                                  />
                                  <span>Delete</span>
                                </button>
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </div>
                      </Transition>
                    </Menu>
                  </td>
                </tr>
              );
            })}
            <tr className="bg-gray-50">
              <td
                colSpan={
                  this.props.params.report === "your-time-report" ||
                  this.props.params.report === "currently-working-report"
                    ? 5
                    : 6
                }
                className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-semibold capitalize"
              >
                Total
              </td>
              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-semibold capitalize">
                {this.props.showTimeInHrMin
                  ? minutesToHour(
                      logs.reduce(
                        (counter, item) => counter + (item.totalTime ?? 0),
                        0
                      )
                    )
                  : logs.reduce(
                      (counter, item) => counter + (item.totalTime ?? 0),
                      0
                    ) + " mins"}
              </td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
}

export default TimeTrackingReport;
