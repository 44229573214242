import { XMarkIcon } from "@heroicons/react/24/outline";
import { Component } from "react";

interface Props {
  message: string;
  fullWidth?: boolean;
  color?: "red" | "yellow";
  onClick?: () => void;
  closeIcon?: boolean;
  onClose?: () => void;
}

class Message extends Component<Props> {
  dangerouslySetInnerHTML = {
    __html: this.props.message.replace(
      "Click here",
      `<span class="text-blue-600 cursor-pointer underline">Click here</span>`
    )
  };

  render() {
    return (
      <div
        className={`border-l-4 ${
          this.props.color === "red"
            ? "bg-red-100 border-red-500 text-black"
            : "bg-yellow-100 border-yellow-500 text-yellow-700"
        } ${
          this.props.fullWidth
            ? "max-w-screen-2xl lg:mx-8"
            : "max-w-7xl mx-auto lg:mx-8"
        } px-4 mb-4 flex gap-6 justify-between`}
        role="alert"
      >
        <p
          className={`font-bold grow py-2 ${
            this.props.onClick ? "cursor-pointer" : ""
          }`}
          onClick={this.props.onClick}
          dangerouslySetInnerHTML={this.dangerouslySetInnerHTML}
        ></p>
        {this.props.closeIcon && (
          <button onClick={this.props.onClose} className="text-xl">
            <XMarkIcon className="h-6 w-6" />
          </button>
        )}
      </div>
    );
  }
}

export default Message;
