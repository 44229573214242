import React from "react";
import ReactQuill from "react-quill";
import { connect, ConnectedProps } from "react-redux";
import { ADD_NOTIFICATION } from "../store/types";
import "react-quill/dist/quill.snow.css";
import Icon from "./Icon";

export const modules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link"],
    ["clean"]
  ]
};

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

type Props = {
  loading?: boolean;
  initialValue: string;
  onLoad?: (forSearch: boolean) => void;
  placeholder?: any;
  cancelHandler: () => void;
  saveHandler: (value: string) => void;
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class Editor extends React.Component<Props, PropsFromRedux> {
  state: {
    value: string;
  };

  constructor(props: any) {
    super(props);

    this.state = {
      value: props.initialValue
    };
  }

  onEditorChange = (editedValue: any) => {
    this.setState({ value: editedValue });
  };

  onCancelClick = () => {
    this.props.cancelHandler();
  };

  onSaveClick = () => {
    this.props.saveHandler(this.state.value);
  };

  render() {
    // console.log(typeof this.state.value, this.state.value);
    return (
      <div>
        <ReactQuill
          theme="snow"
          modules={modules}
          defaultValue={this.state.value}
          onChange={this.onEditorChange}
          placeholder={this.props.placeholder || "Start Typing..."}
          className="h-[8rem] mb-20 lg:mb-16"
        />
        <div>
          <div className="w-full mt-3 flex justify-end">
            <button
              type="button"
              className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none w-28 text-sm"
              onClick={this.onCancelClick}
            >
              Cancel
            </button>
            <button
              type="button"
              disabled={this.props.loading}
              className={
                "ml-4 inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 font-medium text-white hover:bg-indigo-700 focus:outline-none mt-0 w-28 text-sm"
              }
              onClick={this.onSaveClick}
            >
              <span className="w-full flex justify-end">
                {this.props.loading ? (
                  <Icon name="loading" className="mr-2 w-4 h-4" />
                ) : null}
              </span>
              <span>Save</span>
              <span className="w-full"></span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default connector(Editor);
