import { Component, Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import Skeleton from "react-loading-skeleton";
import {
  monthTillLastMonth,
  quarterTillLastQuarter,
  yearTillLastYear
} from "../../helpers/timePeriod";
import { Link } from "react-router-dom";
import capitalize from "../../helpers/capitalize";
import { CurrentTab, UserRightsFilter, UserTypeFilter } from "./Index";

interface Props {
  state: {
    currentTab: CurrentTab;
    selectedUser: { _id: string; name: string };
    loading: boolean;
    taskWiseAndPeriodWiseReport: any;
    workSpaceId?: string;
    showStatusWiseUserWiseReport: boolean;
    selectedTask: string;
    selectedTaskPeriod: string;
    statusWiseAndUserWiseReport: any;
    userTypeFilter: UserTypeFilter;
    userRightsFilter: UserRightsFilter;
  };
  currentUser?: { _id: string; name: string };
  searchParams: (
    key: string,
    filter?: string | undefined
  ) => "" | URLSearchParams;
  setShowStatusWiseUserWiseReportModalOpen: (value: boolean) => void;
  setStatusWiseUserWiseReport: (task: string, period: string) => void;
}

interface State {
  periodType: string;
}

class TaskWiseUserWIseReport extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      periodType: ""
    };
  }

  lastThreeMonths = monthTillLastMonth().slice(-3).reverse();
  lastThreeQuarters = quarterTillLastQuarter().slice(-3).reverse();
  lastThreeYears = yearTillLastYear().slice(-3).reverse();

  monthlyTaskSummaryTableHeaders = [
    "task",
    ...this.lastThreeMonths,
    "Other",
    "Total"
  ];
  quarterlyTaskSummaryTableHeaders = [
    "task",
    ...this.lastThreeQuarters,
    "Other",
    "Total"
  ];
  yearlyTaskSummaryTableHeaders = [
    "task",
    ...this.lastThreeYears,
    "Other",
    "Total"
  ];

  render() {
    return (
      <>
        <div className="space-y-4">
          {Object.keys(this.props.state.taskWiseAndPeriodWiseReport).map(
            (task, index) => (
              <div
                key={`taskWiseAndPeriodWiseReport-${task}`}
                className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow hover:shadow-xl"
              >
                <header className="py-3 px-4 md:px-6">
                  <h3 className="text-base leading-6 font-semibold text-gray-900 capitalize">
                    {this.props.state.currentTab} Task Summary for {task} Task
                  </h3>
                </header>
                <div className="p-4">
                  <div className="bg-white shadow rounded-md sm:overflow-hidden">
                    <div className="flex flex-col">
                      <div id="tab-scroll" className="overflow-x-auto">
                        <div className="inline-block min-w-full align-middle">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                              <tr>
                                {(task === "monthly"
                                  ? this.monthlyTaskSummaryTableHeaders
                                  : task === "quarterly"
                                  ? this.quarterlyTaskSummaryTableHeaders
                                  : task === "yearly"
                                  ? this.yearlyTaskSummaryTableHeaders
                                  : []
                                ).map((key, index) => (
                                  <th
                                    key={`th-taskWiseAndPeriodWiseReport-${key}-${index}`}
                                    scope="col"
                                    className={`px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider ${
                                      index === 0 ? "text-left" : "text-center"
                                    }`}
                                  >
                                    {key === "Other" ? "Older" : key}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {!this.props.state.loading ? (
                                this.props.state.taskWiseAndPeriodWiseReport[
                                  task as keyof object
                                ].length > 0 ? (
                                  this.props.state.taskWiseAndPeriodWiseReport[
                                    task as keyof object
                                  ].map(
                                    (
                                      taskWiseAndPeriodWiseReport: any,
                                      index: number
                                    ) => (
                                      <tr
                                        key={`tr-taskWiseAndPeriodWiseReport-${index}`}
                                        className={`${
                                          index % 2 === 0
                                            ? "bg-white"
                                            : "bg-gray-50"
                                        }`}
                                      >
                                        {(task === "monthly"
                                          ? this.monthlyTaskSummaryTableHeaders
                                          : task === "quarterly"
                                          ? this
                                              .quarterlyTaskSummaryTableHeaders
                                          : task === "yearly"
                                          ? this.yearlyTaskSummaryTableHeaders
                                          : []
                                        ).map((key, index, arr) => (
                                          <td
                                            key={`td-taskWiseAndPeriodWiseReport-${key}-${index}`}
                                            className={`px-4 py-4 whitespace-nowrap text-sm ${
                                              index === 0
                                                ? "text-left"
                                                : "text-center"
                                            } ${
                                              index % 2 === 0 ? "font-bold" : ""
                                            }`}
                                          >
                                            <span
                                              className={
                                                index > 0 &&
                                                index < arr.length - 1 &&
                                                taskWiseAndPeriodWiseReport[
                                                  key as keyof object
                                                ] > 0
                                                  ? "text-indigo-600 hover:text-indigo-900 cursor-pointer"
                                                  : ""
                                              }
                                              onClick={() => {
                                                if (
                                                  index > 0 &&
                                                  index < arr.length - 1 &&
                                                  taskWiseAndPeriodWiseReport[
                                                    key as keyof object
                                                  ] > 0
                                                ) {
                                                  this.props.setShowStatusWiseUserWiseReportModalOpen(
                                                    true
                                                  );
                                                  this.props.setStatusWiseUserWiseReport(
                                                    taskWiseAndPeriodWiseReport.task,
                                                    key
                                                  );
                                                  this.setState({
                                                    periodType: task
                                                  });
                                                }
                                              }}
                                            >
                                              {taskWiseAndPeriodWiseReport[
                                                key as keyof object
                                              ] || "-"}
                                            </span>
                                          </td>
                                        ))}
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <tr className="bg-white">
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize">
                                      No Record Found
                                    </td>
                                  </tr>
                                )
                              ) : (
                                [...Array(4)].map((e, i) => (
                                  <tr
                                    key={`tr-taskWiseAndPeriodWiseReport-${i}`}
                                    className="bg-white"
                                  >
                                    {[
                                      ...Array(
                                        this.monthlyTaskSummaryTableHeaders
                                          .length
                                      )
                                    ].map((e, i) => (
                                      <td
                                        key={`td-taskWiseAndPeriodWiseReport-${i}`}
                                        className="px-2 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                      >
                                        <Skeleton />
                                      </td>
                                    ))}
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
        <Transition.Root
          show={this.props.state.showStatusWiseUserWiseReport}
          as={Fragment}
        >
          <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            onClose={() => null}
          >
            <div className="min-h-screen px-4 text-center flex items-center justify-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black/40" />
              </Transition.Child>

              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block md:ml-60 p-4 my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-2xl space-y-3 border-2 border-gray-500">
                  <div className="flex justify-between items-center">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-medium leading-6 text-gray-900"
                    >
                      Details of {this.props.state.selectedTask} Task for{" "}
                      {this.props.state.selectedTaskPeriod}
                    </Dialog.Title>
                    <button
                      type="button"
                      className="text-gray-400 hover:text-gray-500"
                      onClick={() => {
                        this.props.setShowStatusWiseUserWiseReportModalOpen(
                          false
                        );
                      }}
                    >
                      <XMarkIcon className="w-6 h-6" />
                    </button>
                  </div>
                  <div className="bg-white rounded-md w-[32rem] min-w-max">
                    <div className="flex flex-col xl:flex-row justify-between gap-4">
                      <div
                        id="tab-scroll"
                        className={`overflow-x-auto w-full min-w-[30rem] max-w-[32rem] shadow ring-1 ring-black ring-opacity-5 md:rounded-lg`}
                      >
                        <div className="inline-block min-w-full align-middle">
                          <div className="overflow-hidden md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    className={`px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider text-left`}
                                  >
                                    Status
                                  </th>
                                  <th
                                    scope="col"
                                    className={`px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider text-center`}
                                  >
                                    Total Tasks
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="bg-white divide-y divide-gray-200">
                                {this.props.state
                                  .statusWiseAndUserWiseReport?.[0]?.statusWise
                                  .length > 0 ? (
                                  this.props.state.statusWiseAndUserWiseReport[0].statusWise.map(
                                    (record: any, index: number) => (
                                      <tr
                                        key={`statusWiseAndUserWiseReport-${index}-${record.statusName}`}
                                        className={
                                          index % 2 === 0
                                            ? "bg-white"
                                            : "bg-gray-50"
                                        }
                                      >
                                        <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize">
                                          {record.statusName}
                                        </td>
                                        <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize text-center">
                                          <Link
                                            className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                                            to={`/${
                                              this.props.state.workSpaceId
                                            }/tasks/list?${
                                              this.props.state.currentTab ===
                                              "Common"
                                                ? ""
                                                : this.props.searchParams(
                                                    "type",
                                                    this.props.state.currentTab
                                                  )
                                            }&${this.props.searchParams(
                                              "name",
                                              this.props.state.selectedTask
                                            )}&${this.props.searchParams(
                                              "periodType",
                                              capitalize(this.state.periodType)
                                            )}${
                                              this.props.state.selectedTaskPeriod?.toLowerCase() ===
                                              "other"
                                                ? `&${this.props.searchParams(
                                                    "otherPeriod",
                                                    this.state.periodType.toLowerCase()
                                                  )}`
                                                : `&${this.props.searchParams(
                                                    "period",
                                                    this.props.state
                                                      .selectedTaskPeriod
                                                  )}`
                                            }&${this.props.searchParams(
                                              "status",
                                              record.statusName
                                            )}${
                                              this.props.state
                                                .userTypeFilter ===
                                                "otherUser" ||
                                              this.props.state
                                                .userTypeFilter === "any"
                                                ? `&${this.props.searchParams(
                                                    "otherUser",
                                                    this.props.state
                                                      .userRightsFilter ===
                                                      "employee"
                                                      ? this.props.currentUser
                                                          ?._id
                                                      : this.props.state
                                                          .selectedUser._id
                                                  )}`
                                                : this.props.state
                                                    .userRightsFilter ===
                                                    "employee" &&
                                                  this.props.state
                                                    .userTypeFilter ===
                                                    "currentUser"
                                                ? `&${this.props.searchParams(
                                                    "user",
                                                    this.props.currentUser?._id
                                                  )}`
                                                : `&${this.props.searchParams(
                                                    "user",
                                                    this.props.state
                                                      .selectedUser._id
                                                  )}`
                                            }`}
                                          >
                                            {record.count}
                                          </Link>
                                        </td>
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <tr className="bg-white">
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize">
                                      No Record Found
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {this.props.state.userRightsFilter === "admin" && (
                        <div
                          id="tab-scroll"
                          className="overflow-x-auto w-full min-w-[30rem] max-w-[32rem] shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
                        >
                          <div className="inline-block min-w-full align-middle">
                            <div className="overflow-hidden md:rounded-lg">
                              <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <th
                                      scope="col"
                                      className={`px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider text-left`}
                                    >
                                      Working User
                                    </th>
                                    <th
                                      scope="col"
                                      className={`px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider text-center`}
                                    >
                                      Total Tasks
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                  {this.props.state
                                    .statusWiseAndUserWiseReport?.[0]?.userWise
                                    .length > 0 ? (
                                    this.props.state.statusWiseAndUserWiseReport[0].userWise.map(
                                      (record: any, index: number) => (
                                        <tr
                                          key={`statusWiseAndUserWiseReport-${record.userName}-${index}`}
                                          className={
                                            index % 2 === 0
                                              ? "bg-white"
                                              : "bg-gray-50"
                                          }
                                        >
                                          <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize">
                                            {record.userName}
                                          </td>
                                          <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize text-center">
                                            <Link
                                              className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                                              to={`/${
                                                this.props.state.workSpaceId
                                              }/tasks/list?${
                                                this.props.state.currentTab ===
                                                "Common"
                                                  ? ""
                                                  : this.props.searchParams(
                                                      "type",
                                                      this.props.state
                                                        .currentTab
                                                    )
                                              }&${this.props.searchParams(
                                                "name",
                                                this.props.state.selectedTask
                                              )}&${this.props.searchParams(
                                                "periodType",
                                                capitalize(
                                                  this.state.periodType
                                                )
                                              )}${
                                                this.props.state.selectedTaskPeriod?.toLowerCase() ===
                                                "other"
                                                  ? `&${this.props.searchParams(
                                                      "otherPeriod",
                                                      this.state.periodType.toLowerCase()
                                                    )}`
                                                  : `&${this.props.searchParams(
                                                      "period",
                                                      this.props.state
                                                        .selectedTaskPeriod
                                                    )}`
                                              }${
                                                record.userName === "Unassigned"
                                                  ? `&${this.props.searchParams(
                                                      "user",
                                                      record.userName
                                                    )}`
                                                  : `&${this.props.searchParams(
                                                      "userName",
                                                      record.userName
                                                    )}`
                                              }`}
                                            >
                                              {record.count}
                                            </Link>
                                          </td>
                                        </tr>
                                      )
                                    )
                                  ) : (
                                    <tr className="bg-white">
                                      <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize">
                                        No Record Found
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

export default TaskWiseUserWIseReport;
