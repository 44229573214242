import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { todoCount } from "../Todo/Index";

type TodoListNumbersProps = {
  firmId: string | undefined;
  loading: boolean;
};

export const todoLists = [
  { listname: "Starred", value: "starred" },
  { listname: "Due Today", value: "today" },
  { listname: "Due This Week", value: "next7Days" },
  { listname: "Overdue", value: "overdue" },
  { listname: "Without Due Date", value: "withoutDueDate" }
] as const;

export function TodoCountCards({ firmId, loading }: TodoListNumbersProps) {
  return (
    <div className="flex flex-wrap gap-2 lg:gap-x-6 items-stretch w-full">
      {!loading
        ? todoLists.map(({ listname, value }) => (
            <Link
              key={`dueDaysHeading-${value}`}
              to={`/${firmId}/todo/list/${value}`}
              className={`divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow basis-32 flex-grow shrink focus:outline-none hover:shadow-xl focus:shadow-2xl focus:ring-2 focus:ring-offset-2  ${
                value === "overdue"
                  ? "text-red-600 focus:ring-red-600"
                  : value === "withoutDueDate"
                  ? "text-red-800 focus:ring-red-800"
                  : value === "starred"
                  ? "text-yellow-600 focus:ring-yellow-500"
                  : value === "today"
                  ? "text-green-600 focus:ring-green-600"
                  : "text-indigo-600 focus:ring-indigo-600"
              }`}
            >
              <div className="flex flex-col items-center gap-3 justify-between px-3 py-4">
                <span className={`text-6xl font-medium capitalize`}>
                  {todoCount?.[value]}
                </span>
                <span className="capitalize text-gray-600 font-medium text-center">
                  {listname}
                </span>
              </div>
            </Link>
          ))
        : [...Array(5)].map((_, index) => (
            <div
              key={`dueDaysSkeleton-${index}`}
              className={`divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow basis-32 flex-grow shrink focus:outline-none hover:shadow-xl focus:shadow-2xl focus:ring-2 focus:ring-offset-2`}
            >
              <div className="flex flex-col items-stretch justify-between p-3 gap-y-2">
                <Skeleton />
                <Skeleton className="text-5xl" />
                <Skeleton />
              </div>
            </div>
          ))}
    </div>
  );
}

export function TodoCountTable({ firmId, loading }: TodoListNumbersProps) {
  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow hover:shadow-xl">
      {/* <header className="py-3 px-4 md:px-6">
        <h3 className="text-base leading-6 font-semibold text-gray-900 capitalize">
          Your Todos
        </h3>
      </header> */}
      <div className="p-4">
        <div className="bg-white shadow rounded-md sm:overflow-hidden">
          <div className="flex flex-col">
            <div id="tab-scroll" className="overflow-x-auto">
              <div className="inline-block min-w-full align-middle">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className={`px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider text-left`}
                        >
                          List Name
                        </th>
                        <th
                          scope="col"
                          className={`px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider text-center`}
                        >
                          Todo Count
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {!loading
                        ? todoLists.map(({ listname, value }) => (
                            <tr
                              key={listname}
                              className="even:bg-white odd:bg-gray-50"
                            >
                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize">
                                {listname}
                              </td>
                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize text-center">
                                <Link
                                  className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                                  to={`/${firmId}/todo/list/${value}`}
                                >
                                  {todoCount?.[value]}
                                </Link>
                              </td>
                            </tr>
                          ))
                        : [...Array(5)].map((e, i) => (
                            <tr
                              key={`tr-statusWiseTaskReport-${i}`}
                              className="bg-white"
                            >
                              {[...Array(2)].map((e, i) => (
                                <td
                                  key={`td-statusWiseTaskReport-${i}`}
                                  className="px-2 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                >
                                  <Skeleton />
                                </td>
                              ))}
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
