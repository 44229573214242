import { LOGIN, LOGOUT } from "../types";

const initialAuthState = {
  isAuthenticated: false
};

type AuthState = typeof initialAuthState;

export interface AuthAction {
  type: typeof LOGIN | typeof LOGOUT;
  payload?: Partial<AuthState>;
}

const authReducer = (state = initialAuthState, action: AuthAction) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        ...action.payload
      };
    case LOGOUT:
      return initialAuthState;
    default:
      return state;
  }
};

export default authReducer;
