import React, { Fragment } from "react";
// Import to Display skeleton while loading data
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// Pagination
import Pagination from "../../components/Pagination";
import { connect, ConnectedProps } from "react-redux";
// Link backend
import agent from "../../agent";
// Dashboard import
import Dashboard, {
  getAllUsersList,
  getSelfDetails
} from "../../components/Dashboard";
// Icons and styling
import Icon from "../../components/Icon";
// Redux Notify
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import "../style.css";
import TagManager from "react-gtm-module";
import { Menu, Transition } from "@headlessui/react";
import DeleteModal from "../../components/DeleteModal";
import { withRouter } from "../../helpers/withRouter";
import { compose } from "redux";
import InvitationModal from "../../components/InvitationModal";
import ActiveModal from "../../components/ActiveModal";
import InActiveModal from "../../components/InActiveModal";
import LogsModal from "../../components/LogsModal";
import Button from "../../components/Button";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Users List"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: (payload: any) => dispatch({ type: UPDATE_COMMON, payload }),
  onNotify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class Clients extends React.Component<any, PropsFromRedux> {
  state: {
    loading: boolean;
    posX: any;
    posY: any;
    showBackDrop: boolean;
    searchText: string;
    users: any;
    totalRecords: number;
    displayUserDetails: any;
    selectedGstId: string;
    selectedFirm: any;
    modalOpen: boolean;
    typingTimeout: number;
    selectedRow: any;
    hoverX: any;
    hoverY: any;
    showDeleteModal: boolean;
    showEditModal: boolean;
    active: boolean;
    sentLoading: boolean;
    invitationSentDetails: any;
    invitationSentTotalRecords: number;
    showInvitationModal: boolean;
    showActiveModal: boolean;
    showInActiveModal: boolean;
    showLogsModal: boolean;
    skip: 0;
    currPage: 0;
    chunkSize: 25;
  };

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      posX: null,
      posY: null,
      showBackDrop: false,
      searchText: "",
      users: [],
      totalRecords: 0,
      displayUserDetails: [],
      selectedGstId: "",
      selectedFirm: undefined,
      modalOpen: false,
      typingTimeout: 0,
      selectedRow: undefined,
      hoverX: null,
      hoverY: null,
      showDeleteModal: false,
      showEditModal: false,
      active: true,
      sentLoading: false,
      invitationSentDetails: [],
      invitationSentTotalRecords: 0,
      showInvitationModal: false,
      showActiveModal: false,
      showInActiveModal: false,
      showLogsModal: false,
      skip: 0,
      currPage: 0,
      chunkSize: 25
    };
  }

  userListHeaders = ["USER NAME", "SHORT NAME", "EMAIL", "STATUS", "ACTIONS"];

  userSelfDetailsHeaders = [
    "USER NAME",
    "SHORT NAME",
    "EMAIL",
    "STATUS",
    "ACTIONS"
  ];

  firmInvitationSentHeaders = [
    "FIRM NAME",
    "SEND TO",
    "USER ACCESS RIGHT",
    "STATUS",
    "ACTIONS"
  ];

  //Get User Data

  getUsersList = (forSearch: boolean) => {
    const workSpaceId = (this.props as any).params?.firmId;
    const searchText = forSearch ? this.state.searchText : "";
    const active = this.state.active;
    const includeCurrent = false;
    this.setState({ loading: true });
    agent.User.getUserList(workSpaceId, active, searchText, includeCurrent)
      .then((response: any) => {
        // console.log({ response });
        this.setState({
          users: response.users,
          loading: false,
          totalRecords: response.users.length,
          displayUserDetails: response.users.slice(
            this.state.currPage * this.state.chunkSize,
            this.state.currPage * this.state.chunkSize + this.state.chunkSize
          )
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).onNotify(
          "Could not load Firm Details",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  getInvitaionSentList = () => {
    const workSpaceId = (this.props as any).params?.firmId;
    this.setState({ sentLoading: true });
    agent.Firm.listofInvitationSent(workSpaceId)
      .then((response: any) => {
        this.setState({
          invitationSentDetails: response.invitations,
          sentLoading: false,
          invitationSentTotalRecords: response.invitations.length
        });
      })
      .catch((err: any) => {
        this.setState({ sentLoading: false });
        (this.props as any).onNotify(
          "Could not load Sent Invitaion Lists",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  // onMount Load data
  componentDidMount() {
    document.title = "Users - TaxPido PMS";
    this.getUsersList(false);
    this.getInvitaionSentList();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const prevFirmId = prevProps.params?.firmId;
    const currFirmId = (this.props as any).params?.firmId;

    if (prevFirmId !== currFirmId) {
      this.setState({ searchText: "" });
      getSelfDetails(
        (this.props as any).currentFirm._id,
        (this.props as any).updateCommon,
        (this.props as any).onNotify
      );
      this.getUsersList(false);
      this.getInvitaionSentList();
    }

    if (prevState.active !== this.state.active) {
      this.setState({ searchText: "" });
      this.getUsersList(false);
    }

    if (prevState.currPage !== this.state.currPage) {
      this.setState({
        displayUserDetails: this.state.users.slice(
          this.state.currPage * this.state.chunkSize,
          this.state.currPage * this.state.chunkSize + this.state.chunkSize
        )
      });
    }
  }

  handlePageClick = (data: any) => {
    this.setState({
      currPage: data.selected
    });
  };

  fetchRequired = () => {
    this.setState({ requireFetch: true });
  };

  onFirmChange = (item: any) => {
    this.setState({ selectedFirm: item });
  };

  onActionClick = (e: any, menuHeight: number) => {
    // const screenClosness = window.innerHeight - e.clientY;
    // const positionY =
    //   screenClosness < menuHeight ? e.clientY - menuHeight : e.clientY;
    this.setState({
      posX: e.clientX,
      posY: e.clientY,
      showBackDrop: true
    });
  };

  onDropdownClick = () => {
    this.setState({ showBackDrop: false });
  };

  handleSearchTextChange = (ev: any) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      searchText: ev.target.value,
      currPage: 0,
      typingTimeout: setTimeout(() => {
        this.getUsersList(true);
      }, 700)
    });
  };

  updateActive = () => {
    this.setState({ active: !this.state.active, currPage: 0 });
  };

  openAddUserPage = () => {
    const clientRights = (this.props as any)?.rights?.userRights;
    const createRight = clientRights.add;
    const currentFirmId = (this.props as any).params?.firmId;
    if (createRight) {
      (this.props as any).navigate(`/${currentFirmId}/user/add`);
    } else {
      (this.props as any).onNotify(
        "Rights Not Avilable",
        "Ask Admin to change your user rights.",
        "danger"
      );
    }
  };

  openDeleteModal = (user: any) => {
    this.setState({ selectedRow: user, showBackDrop: false });
    this.deleteModalSetOpen(true);
  };

  deleteModalSetOpen = (open: boolean) => {
    this.setState({
      showDeleteModal: open
    });
  };

  openRevokeModal = (item: any) => {
    this.setState({
      selectedRow: item,
      showBackDrop: false
    });
    this.invitationModalSetOpen(true);
  };

  invitationModalSetOpen = (open: boolean) => {
    this.setState({
      showInvitationModal: open
    });
  };

  openEditModal = (user: any) => {
    const userRights = (this.props as any)?.rights?.userRights;
    const editRight = userRights.edit;
    const currentFirmId = (this.props as any).params?.firmId;
    if (editRight) {
      (this.props as any).updateCommon({ editUser: user });
      (this.props as any).navigate(`/${currentFirmId}/user/edit`);
    } else {
      this.setState({ showBackDrop: false });
      (this.props as any).onNotify(
        "Rights Not Avilable",
        "Ask Admin to change your user rights.",
        "danger"
      );
    }
  };

  editModalSetOpen = (open: boolean) => {
    this.setState({
      showEditModal: open
    });
  };

  openActiveModal = (user: any) => {
    this.setState({ selectedRow: user, showBackDrop: false });
    this.activeModalSetOpen(true);
  };

  activeModalSetOpen = (open: boolean) => {
    this.setState({
      showActiveModal: open
    });
  };

  openInActiveModal = (user: any) => {
    this.setState({ selectedRow: user, showBackDrop: false });
    this.inActiveModalSetOpen(true);
  };

  inActiveModalSetOpen = (open: boolean) => {
    this.setState({
      showInActiveModal: open
    });
  };

  openLogsModal = (tag: any) => {
    this.setState({ selectedRow: tag, showBackDrop: false });
    this.logsModalSetOpen(true);
  };

  logsModalSetOpen = (open: boolean) => {
    this.setState({
      showLogsModal: open
    });
  };

  showSelfRights = () => {
    const currentFirmId = (this.props as any).params?.firmId;
    (this.props as any).navigate(`/${currentFirmId}/user/self-rights`);
  };

  isFirmOwner = (userId: string) => {
    const currentFirm = (this.props as any)?.currentFirm;
    const owner = currentFirm?.superAdminId;
    return owner === userId ? true : false;
  };

  onLoad = () => {
    this.setState({ searchText: "" });
    this.getUsersList(false);
    getAllUsersList(
      (this.props as any).currentFirm._id,
      (this.props as any).updateCommon,
      (this.props as any).onNotify
    );
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard>
        <div className="mb-8 space-y-6">
          {this.state.showDeleteModal && (
            <DeleteModal
              type={"user"}
              state={this.state}
              onLoad={this.onLoad}
              deleteModalSetOpen={this.deleteModalSetOpen}
            />
          )}
          {this.state.showInvitationModal && (
            <InvitationModal
              heading={"Revoke Invitation"}
              type={"Revoke"}
              state={this.state}
              onLoad={this.getInvitaionSentList}
              invitationModalSetOpen={this.invitationModalSetOpen}
            />
          )}
          {this.state.showInActiveModal && (
            <InActiveModal
              type={"user"}
              state={this.state}
              onLoad={this.onLoad}
              inActiveModalSetOpen={this.inActiveModalSetOpen}
            />
          )}

          {this.state.showActiveModal && (
            <ActiveModal
              type={"user"}
              state={this.state}
              onLoad={this.onLoad}
              activeModalSetOpen={this.activeModalSetOpen}
            />
          )}

          {this.state.showLogsModal && (
            <LogsModal
              type={"user"}
              showLogsModal={this.state.showLogsModal}
              selectedRow={this.state.selectedRow}
              setOpen={this.logsModalSetOpen}
            />
          )}

          <div className="max-w-full mx-auto lg:mx-8">
            <h1 className="text-2xl font-semibold text-gray-900">Users</h1>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-6">
              <div className="w-fit">
                <button
                  type="button"
                  className="relative whitespace-nowrap inline-flex items-center sm:px-4 px-3 py-2 border border-transparent shadow-sm sm:text-sm text-xs font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                  onClick={this.openAddUserPage}
                >
                  {(this.props as any)?.rights?.userRights.add ? (
                    <Icon name="add" className="h-4 w-4 mr-2" />
                  ) : (
                    <Icon name="outline/lock-closed" className="h-4 w-4 mr-2" />
                  )}
                  Add User
                </button>
              </div>

              {(this.state.totalRecords > 0 ||
                this.state.searchText.length > 0) && (
                <div className="w-full md:w-80 md:col-span-2">
                  <input
                    id="search"
                    name="search"
                    type="search"
                    value={this.state.searchText}
                    placeholder="Search by User Name"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 text-xs sm:text-sm"
                    onChange={this.handleSearchTextChange}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="relative flex items-start max-w-full mx-auto lg:mx-8">
            <div className="flex h-5 items-center">
              <input
                id="comments"
                aria-describedby="comments-description"
                name="comments"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-400 cursor-pointer text-indigo-600 focus:ring-indigo-500"
                checked={this.state.active === false}
                onChange={this.updateActive}
              />
            </div>
            <div className="ml-3 text-sm">
              <label
                htmlFor="comments"
                className="font-medium cursor-pointer text-gray-700"
              >
                Show Inactive Users
              </label>
            </div>
          </div>

          {/* Users List*/}
          <div className="max-w-full mx-auto lg:mx-8">
            {/* Users List Cards*/}
            <div className="sm:hidden px-2 sm:px-6 mt-6">
              {!this.state.loading ? (
                this.state.totalRecords > 0 ||
                this.state.active === false ||
                this.state.searchText.length > 0 ? (
                  <ul className="divide-y divide-gray-200 space-y-4">
                    {(this.state.active === false &&
                      this.state.totalRecords === 0) ||
                    (this.state.searchText && this.state.totalRecords === 0) ? (
                      <div className="bg-white shadow-md rounded-lg">
                        <div className="px-4 py-4 sm:px-6">
                          <p className="text-sm text-gray-800 text-center font-semibold">
                            {this.state.searchText &&
                              "No record found matching your search criteria"}
                            {this.state.active === false &&
                              this.state.searchText === "" &&
                              "No record of inactive users"}
                          </p>
                        </div>
                      </div>
                    ) : (
                      this.state.displayUserDetails.map(
                        (user: any, index: any) => (
                          <li
                            key={user._id}
                            className="bg-white shadow-md rounded-lg"
                          >
                            <div className="px-4 py-4 sm:px-6 grid gap-2 grid-cols-2 relative">
                              <span className="rounded-full bg-green-100 px-2 text-xs font-bold leading-5 text-green-800 absolute -translate-y-1/2 -right-4 shadow uppercase">
                                {user.active ? "Active" : "Inactive"}
                              </span>
                              <p className="text-gray-800 font-semibold mt-auto">
                                <span className="w-8 h-8 p-2 rounded-full cursor-default inline-flex items-center justify-center bg-gray-700 text-white mr-3 text-sm xs:text-base">
                                  {user.shortname}
                                </span>
                                <span className="text-sm xs:text-lg font-bold capitalize text-indigo-600">
                                  {user.name}
                                </span>
                              </p>
                              <div className="justify-self-end mt-1">
                                <UserListOptions
                                  {...this.props}
                                  state={this.state}
                                  smallScreen={true}
                                  user={user}
                                  onActionClick={this.onActionClick}
                                  onDropdownClick={this.onDropdownClick}
                                  isFirmOwner={this.isFirmOwner}
                                  openLogsModal={this.openLogsModal}
                                  openEditModal={this.openEditModal}
                                  openInActiveModal={this.openInActiveModal}
                                  openActiveModal={this.openActiveModal}
                                  openDeleteModal={this.openDeleteModal}
                                />
                              </div>
                              <div className="text-sm text-gray-800 mt-auto col-span-2 max-w-full">
                                <p className="font-bold truncate">
                                  {user.email}
                                </p>
                              </div>
                            </div>
                          </li>
                        )
                      )
                    )}
                  </ul>
                ) : (
                  <div className="text-center w-full max-w-2xl mx-auto my-10 border-2 border-gray-300 border-dashed p-16 rounded-lg bg-gray-100">
                    <Icon
                      name="outline/document-add"
                      className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                      strokeWidth="1"
                    />
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      No Users Entry
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Get started by adding a new User.
                    </p>
                    <div className="mt-6">
                      <Button
                        name="Add User"
                        onClick={this.openAddUserPage}
                        icon={
                          (this.props as any)?.rights?.userRights.add
                            ? "add"
                            : "outline/lock-closed"
                        }
                      />
                    </div>
                  </div>
                )
              ) : (
                <div className="space-y-4">
                  {[...Array(5)].map((e, i) => (
                    <div key={i} className="bg-white shadow-md rounded-lg">
                      <div
                        key={i}
                        className={`whitespace-wrap text-sm font-medium text-gray-900 px-4 py-4 sm:px-6 grid gap-4 grid-cols-2 items-center relative ${
                          i === 0 ? "text-left" : "text-center"
                        }`}
                      >
                        <span className="px-2 absolute top-0 -translate-y-1/2 -right-4 w-24">
                          <Skeleton
                            height="1rem"
                            className="w-full bg-green-100"
                          />
                        </span>
                        {[...Array(4)].map((e, i) => (
                          <Skeleton height="1rem" />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Users List Table*/}
            <div className="hidden sm:block">
              {this.state.totalRecords > 0 ||
              this.state.active === false ||
              this.state.searchText.length > 0 ? (
                <div className="mt-6">
                  <div id="table-scroll" className="overflow-auto">
                    <div className="inline-block min-w-full py-2 px-1 align-middle">
                      <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                        <table className="min-w-full border-collapse border shadow-sm">
                          <thead className="bg-gray-50">
                            <tr>
                              {this.userListHeaders.map((header, index) => (
                                <th
                                  key={header}
                                  scope="col"
                                  className={`sticky top-0 z-[8] whitespace-nowrap border-b font-bold border-gray-300 bg-gray-50 px-4 py-3 text-xs text-gray-500 uppercase tracking-wider sm:pl-6 ${
                                    index === this.userListHeaders.length - 1
                                      ? "text-center"
                                      : "text-left"
                                  }`}
                                >
                                  {header}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody className="bg-white">
                            {!this.state.loading ? (
                              (this.state.active === false &&
                                this.state.totalRecords === 0) ||
                              (this.state.searchText &&
                                this.state.totalRecords === 0) ? (
                                <tr>
                                  <td
                                    colSpan={this.userListHeaders.length}
                                    className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900"
                                  >
                                    {this.state.searchText &&
                                      "No record found matching your search criteria"}
                                    {this.state.active === false &&
                                      this.state.searchText === "" &&
                                      "No record of inactive users"}
                                  </td>
                                </tr>
                              ) : (
                                this.state.displayUserDetails?.map(
                                  (user: any, index: any) => (
                                    <tr
                                      key={user._id}
                                      className={
                                        index % 2 === 0
                                          ? "bg-white"
                                          : "bg-gray-100"
                                      }
                                    >
                                      <td className="w-3/12 whitespace-nowrap py-4 pl-4 pr-3 font-bold text-sm text-gray-900 sm:pl-6">
                                        {user.name}
                                      </td>
                                      <td className="w-3/10 px-6 py-3 text-sm text-gray-500 relative">
                                        {user.shortname}
                                      </td>
                                      <td className="w-4/10 px-6 py-3 whitespace-nowrap font-bold text-sm text-gray-900">
                                        {user.email}
                                      </td>
                                      <td className="w-4/10 px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                                        {user.active ? "Active" : "Inactive"}
                                      </td>
                                      <td className="w-4/10 px-6 py-3 mx-4 text-center whitespace-nowrap text-sm text-gray-900">
                                        <UserListOptions
                                          {...this.props}
                                          state={this.state}
                                          smallScreen={false}
                                          user={user}
                                          onActionClick={this.onActionClick}
                                          onDropdownClick={this.onDropdownClick}
                                          isFirmOwner={this.isFirmOwner}
                                          openLogsModal={this.openLogsModal}
                                          openEditModal={this.openEditModal}
                                          openInActiveModal={
                                            this.openInActiveModal
                                          }
                                          openActiveModal={this.openActiveModal}
                                          openDeleteModal={this.openDeleteModal}
                                        />
                                      </td>
                                    </tr>
                                  )
                                )
                              )
                            ) : (
                              [...Array(5)].map((e, i) => (
                                <tr key={i} className="bg-white">
                                  {[...Array(this.userListHeaders.length)].map(
                                    (e, i) => (
                                      <td
                                        key={i}
                                        className="w-3/10 px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                      >
                                        <Skeleton />
                                      </td>
                                    )
                                  )}
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-center w-full max-w-2xl mx-auto my-10 border-2 border-gray-300 border-dashed p-16 rounded-lg bg-gray-100">
                  <Icon
                    name="outline/document-add"
                    className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                    strokeWidth="1"
                  />
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    No Users Entry
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Get started by adding a new User.
                  </p>
                  <div className="mt-6">
                    <Button
                      name="Add User"
                      onClick={this.openAddUserPage}
                      icon={
                        (this.props as any)?.rights?.userRights.add
                          ? "add"
                          : "outline/lock-closed"
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <Pagination
            displayRecords={this.state.displayUserDetails}
            totalRecords={this.state.totalRecords}
            currPage={this.state.currPage}
            chunkSize={this.state.chunkSize}
            hidechunkSizeSelector={true}
            handlePageClick={this.handlePageClick}
            className="my-4"
          />

          {/* User Self Details */}
          <div className="max-w-full mx-auto lg:mx-8">
            <h1 className="text-2xl font-semibold text-gray-900">Yourself</h1>
            {/* User Self Details Cards */}
            <div className="sm:hidden px-2 sm:px-6 mt-6">
              <ul className="divide-y divide-gray-200 space-y-4">
                <li className="bg-white shadow-md rounded-lg">
                  <div className="px-4 py-4 sm:px-6 grid gap-2 grid-cols-2 relative">
                    <span className="rounded-full bg-green-100 px-2 text-xs font-bold leading-5 text-green-800 absolute -translate-y-1/2 -right-4 shadow uppercase">
                      {this.props?.currentUser?.active ? "Active" : "Inactive"}
                    </span>
                    <p className="text-gray-800 font-semibold mt-auto">
                      <span className="w-8 h-8 p-2 rounded-full cursor-default inline-flex items-center justify-center bg-gray-700 text-white mr-3 text-sm xs:text-base">
                        {this.props?.currentUser?.shortname}
                      </span>
                      <span className="text-sm xs:text-lg font-bold capitalize text-indigo-600">
                        {this.props?.currentUser?.name}
                      </span>
                    </p>
                    <div className="justify-self-end mt-1">
                      <UserSelfDetailsOptions
                        {...this.props}
                        smallScreen={true}
                        state={this.state}
                        onActionClick={this.onActionClick}
                        onDropdownClick={this.onDropdownClick}
                        showSelfRights={this.showSelfRights}
                      />
                    </div>
                    <div className="text-sm text-gray-800 mt-auto col-span-2 max-w-full">
                      <p className="font-bold truncate">
                        {this.props?.currentUser?.email}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            {/* User Self Details Table */}
            <div className="mt-6 hidden sm:block">
              <div id="table-scroll" className="overflow-auto">
                <div className="inline-block min-w-full py-2 px-1 align-middle">
                  <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                    <table className="min-w-full border-collapse border shadow-sm">
                      <thead className="bg-gray-50">
                        <tr>
                          {this.userSelfDetailsHeaders.map((header, index) => (
                            <th
                              key={header}
                              scope="col"
                              className={`sticky top-0 z-[8] whitespace-nowrap border-b font-bold border-gray-300 bg-gray-50 px-4 py-3 text-xs text-gray-500 uppercase tracking-wider sm:pl-6 ${
                                index === this.userSelfDetailsHeaders.length - 1
                                  ? "text-center"
                                  : "text-left"
                              }`}
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        <tr>
                          <td className="w-3/12 whitespace-nowrap py-4 pl-4 pr-3 font-bold text-sm text-gray-900 sm:pl-6">
                            {this.props?.currentUser?.name}
                          </td>
                          <td className="w-3/10 px-6 py-3 text-sm text-gray-500 relative">
                            {this.props?.currentUser?.shortname}
                          </td>
                          <td className="w-4/10 px-6 py-3 whitespace-nowrap font-bold text-sm text-gray-900">
                            {this.props?.currentUser?.email}
                          </td>
                          <td className="w-4/10 px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                            {this.props?.currentUser?.active
                              ? "Active"
                              : "Inactive"}
                          </td>
                          <td className="w-4/10 px-6 py-3 mx-4 text-center whitespace-nowrap text-sm text-gray-900">
                            <UserSelfDetailsOptions
                              {...this.props}
                              smallScreen={false}
                              state={this.state}
                              onActionClick={this.onActionClick}
                              onDropdownClick={this.onDropdownClick}
                              showSelfRights={this.showSelfRights}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* User Sent Invitation */}
          <div className="max-w-full mx-auto lg:mx-8">
            <h1 className="text-2xl font-semibold text-gray-900">
              User Invitation Sent
            </h1>
            {/* User Invitation Sent Cards*/}
            <div className="sm:hidden px-2 sm:px-6 mt-6">
              {!this.state.sentLoading ? (
                this.state.invitationSentDetails.length > 0 ? (
                  <ul className="divide-y divide-gray-200 space-y-4">
                    {this.state.invitationSentDetails.map(
                      (invitation: any, index: any) => (
                        <li
                          key={invitation._id}
                          className="bg-white shadow-md rounded-lg"
                        >
                          <div className="px-4 py-4 sm:px-6 grid gap-2 grid-cols-2 relative">
                            <span className="rounded-full bg-green-100 px-2 text-xs font-bold leading-5 text-green-800 absolute -translate-y-1/2 -right-4 shadow uppercase">
                              {invitation.status}
                            </span>
                            <p className="text-sm text-gray-800 font-semibold mt-auto">
                              <span>Name - </span>
                              <span className="font-bold">
                                {invitation.workSpaceId.name}
                              </span>
                            </p>
                            <div className="justify-self-end mt-1">
                              <InvitationSentOptions
                                {...this.props}
                                smallScreen={true}
                                state={this.state}
                                invitation={invitation}
                                onActionClick={this.onActionClick}
                                openRevokeModal={this.openRevokeModal}
                              />
                            </div>
                            <div className="text-sm text-gray-800 mt-auto col-span-2 max-w-full">
                              <p>
                                <span>Role - </span>
                                <span className="font-bold capitalize text-indigo-600">
                                  {invitation.userAccessRole[0].toUpperCase() +
                                    invitation.userAccessRole.slice(1)}
                                </span>
                              </p>
                              <p className="font-bold truncate">
                                {invitation.email}
                              </p>
                            </div>
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                ) : (
                  <div className="bg-white shadow-md rounded-lg">
                    <div className="px-4 py-4 sm:px-6">
                      <p className="text-sm text-gray-800 text-center font-semibold">
                        No Invitations Sent
                      </p>
                    </div>
                  </div>
                )
              ) : (
                <div className="space-y-4">
                  {[...Array(5)].map((e, i) => (
                    <div key={i} className="bg-white shadow-md rounded-lg">
                      <div
                        key={i}
                        className={`whitespace-wrap text-sm font-medium text-gray-900 px-4 py-4 sm:px-6 grid gap-4 grid-cols-2 items-center relative ${
                          i === 0 ? "text-left" : "text-center"
                        }`}
                      >
                        <span className="px-2 absolute top-0 -translate-y-1/2 -right-4 w-24">
                          <Skeleton
                            height="1rem"
                            className="w-full bg-green-100"
                          />
                        </span>
                        {[...Array(4)].map((e, i) => (
                          <Skeleton height="1rem" />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* User Invitation Sent Table*/}
            <div className="mt-6 hidden sm:block">
              <div id="table-scroll" className="overflow-auto">
                <div className="inline-block min-w-full py-2 px-1 align-middle">
                  <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                    <table className="min-w-full border-collapse border shadow-sm">
                      <thead className="bg-gray-50">
                        <tr>
                          {this.firmInvitationSentHeaders.map(
                            (header, index) => (
                              <th
                                key={header}
                                scope="col"
                                className={`sticky top-0 z-[8] whitespace-nowrap border-b font-bold border-gray-300 bg-gray-50 px-4 py-3 text-xs text-gray-500 uppercase tracking-wider sm:pl-6 ${
                                  index ===
                                  this.firmInvitationSentHeaders.length - 1
                                    ? "text-center"
                                    : "text-left"
                                }`}
                              >
                                {header}
                              </th>
                            )
                          )}
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {!this.state.sentLoading ? (
                          this.state.invitationSentDetails.length === 0 ? (
                            <tr>
                              <td
                                colSpan={this.firmInvitationSentHeaders.length}
                                className="pl-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900"
                              >
                                <div className="w-fit m-0">
                                  No Invitations Sent
                                  <span className="invisible">hide</span>
                                </div>
                              </td>
                            </tr>
                          ) : (
                            this.state.invitationSentDetails?.map(
                              (invitation: any, index: any) => (
                                <tr
                                  key={invitation._id}
                                  className={
                                    index % 2 === 0 ? undefined : "bg-gray-100"
                                  }
                                >
                                  <td className="w-3/12 whitespace-nowrap py-4 pl-4 pr-3 font-bold text-sm text-gray-900 sm:pl-6">
                                    {invitation.workSpaceId.name}
                                  </td>
                                  <td className="w-4/10 px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                                    {invitation.email}
                                  </td>
                                  <td className="w-2/10 px-6 py-3 whitespace-nowrap text-sm font-bold text-gray-900">
                                    {invitation.userAccessRole[0].toUpperCase() +
                                      invitation.userAccessRole.slice(1)}
                                  </td>
                                  <td className="w-2/10 px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                                    {invitation.status}
                                  </td>
                                  <td className="w-2/10 px-6 py-3 text-center whitespace-nowrap text-sm text-gray-900">
                                    <InvitationSentOptions
                                      {...this.props}
                                      smallScreen={false}
                                      state={this.state}
                                      invitation={invitation}
                                      onActionClick={this.onActionClick}
                                      openRevokeModal={this.openRevokeModal}
                                    />
                                  </td>
                                </tr>
                              )
                            )
                          )
                        ) : (
                          [...Array(5)].map((e, i) => (
                            <tr key={i} className="bg-white">
                              {[
                                ...Array(this.firmInvitationSentHeaders.length)
                              ].map((e, i) => (
                                <td
                                  key={i}
                                  className="w-3/10 px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                >
                                  <Skeleton />
                                </td>
                              ))}
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    );
  }
}

interface OptionsProps {
  smallScreen: boolean;
  state: any;
  onActionClick: any;
}

interface UserListOptionsProps extends OptionsProps {
  user: any;
  onDropdownClick: any;
  isFirmOwner: any;
  openLogsModal: any;
  openEditModal: any;
  openInActiveModal: any;
  openActiveModal: any;
  openDeleteModal: any;
}

class UserListOptions extends React.Component<UserListOptionsProps> {
  render() {
    return (
      <Menu as="div" className={"inline-block"}>
        <Menu.Button onClick={(e: any) => this.props.onActionClick(e, 125)}>
          <span className="sr-only">Open options</span>
          {this.props.smallScreen ? (
            <Icon name="vertical-dots" className="h-4 w-4" aria-hidden="true" />
          ) : (
            <Icon
              name="horizontal-dots"
              className="h-5 w-5"
              aria-hidden="true"
            />
          )}
        </Menu.Button>
        {this.props.state.showBackDrop && (
          <div
            className="fixed top-0 left-0 z-10 w-full h-screen"
            onClick={this.props.onDropdownClick}
          ></div>
        )}

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div
            style={{
              position: "fixed",
              zIndex: 100,
              top: `${this.props.state.posY}px`,
              left: `${this.props.state.posX - 230}px`,
              margin: "0.5rem"
            }}
          >
            <Menu.Items className="overscroll-none mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {this.props.isFirmOwner(this.props.user._id) ? (
                  <Menu.Item>
                    <button
                      className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                      onClick={() => this.props.openLogsModal(this.props.user)}
                    >
                      <Icon
                        name="outline/document-report"
                        className="h-5 w-5 mr-2"
                      />
                      <span>Activity</span>
                    </button>
                  </Menu.Item>
                ) : (
                  <>
                    <Menu.Item>
                      <button
                        className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                        onClick={() =>
                          this.props.openEditModal(this.props.user)
                        }
                      >
                        <Icon name="edit" className="h-5 w-5 mr-2" />
                        <span>Edit</span>
                      </button>
                    </Menu.Item>
                    <Menu.Item>
                      {this.props.user.active ? (
                        <button
                          className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                          onClick={() =>
                            this.props.openInActiveModal(this.props.user)
                          }
                        >
                          <Icon name="warning" className="h-5 w-5 mr-2" />
                          <span>Mark Inactive</span>
                        </button>
                      ) : (
                        <button
                          className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                          onClick={() =>
                            this.props.openActiveModal(this.props.user)
                          }
                        >
                          <Icon name="warning" className="h-5 w-5 mr-2" />
                          <span>Mark Active</span>
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      <button
                        className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                        onClick={() =>
                          this.props.openDeleteModal(this.props.user)
                        }
                      >
                        <Icon name="delete" className="h-5 w-5 mr-2" />
                        <span>Delete</span>
                      </button>
                    </Menu.Item>
                  </>
                )}
              </div>
            </Menu.Items>
          </div>
        </Transition>
      </Menu>
    );
  }
}

interface UserSelfDetailsOptionsProps extends OptionsProps {
  onDropdownClick: any;
  showSelfRights: any;
}

class UserSelfDetailsOptions extends React.Component<UserSelfDetailsOptionsProps> {
  render() {
    return (
      <Menu as="div" className={"inline-block"}>
        <Menu.Button onClick={(e: any) => this.props.onActionClick(e, 45)}>
          <span className="sr-only">Open options</span>
          {this.props.smallScreen ? (
            <Icon name="vertical-dots" className="h-4 w-4" aria-hidden="true" />
          ) : (
            <Icon
              name="horizontal-dots"
              className="h-5 w-5"
              aria-hidden="true"
            />
          )}
        </Menu.Button>
        {this.props.state.showBackDrop && (
          <div
            className="fixed top-0 left-0 z-10 w-full h-screen"
            onClick={this.props.onDropdownClick}
          ></div>
        )}

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div
            style={{
              position: "fixed",
              zIndex: 100,
              top: `${this.props.state.posY}px`,
              left: `${this.props.state.posX - 230}px`,
              margin: "0.5rem"
            }}
          >
            <Menu.Items className="overscroll-none mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  <button
                    className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                    onClick={this.props.showSelfRights}
                  >
                    <Icon
                      name="outline/document-report"
                      className="h-5 w-5 mr-2"
                    />
                    <span>Show Rights</span>
                  </button>
                </Menu.Item>
              </div>
            </Menu.Items>
          </div>
        </Transition>
      </Menu>
    );
  }
}

interface InvitationSentOptionsProps extends OptionsProps {
  invitation: any;
  openRevokeModal: any;
}

class InvitationSentOptions extends React.Component<InvitationSentOptionsProps> {
  state: {
    showBackDrop: boolean;
  };
  constructor(props: any) {
    super(props);

    this.state = {
      showBackDrop: false
    };
  }

  render() {
    return (
      <Menu as="div" className="inline-block">
        <Menu.Button onClick={(e: any) => this.props.onActionClick(e, 55)}>
          <span className="sr-only">Open options</span>
          {this.props.smallScreen ? (
            <Icon name="vertical-dots" className="h-4 w-4" aria-hidden="true" />
          ) : (
            <Icon
              name="horizontal-dots"
              className="h-5 w-5"
              aria-hidden="true"
            />
          )}
        </Menu.Button>
        {this.state.showBackDrop && (
          <div
            className="fixed top-0 left-0 z-10 w-full h-screen"
            onClick={() =>
              this.setState({
                showBackDrop: false
              })
            }
          ></div>
        )}

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div
            style={{
              position: "fixed",
              top: `${this.props.state.posY}px`,
              left: `${this.props.state.posX - 230}px`,
              zIndex: 100,
              margin: "0.5rem"
            }}
          >
            <Menu.Items className="overscroll-none mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  <button
                    className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                    onClick={() =>
                      this.props.openRevokeModal(this.props.invitation)
                    }
                  >
                    <Icon name="warning" className="h-5 w-5 mr-2" />
                    <span>Revoke Invitation</span>
                  </button>
                </Menu.Item>
              </div>
            </Menu.Items>
          </div>
        </Transition>
      </Menu>
    );
  }
}

export default compose(connector, withRouter)(Clients) as React.ComponentType;
