import React, { Component } from "react";
import { Popover, ArrowContainer } from "react-tiny-popover";

interface Props {
  content: any;
  children: any;
  className?: string;
  capitalize?: boolean;
}

interface State {
  isPopoverOpen: boolean;
  popupArrowColor: string;
}

export default class Popup extends Component<Props, State> {
  state: State = {
    isPopoverOpen: false,
    popupArrowColor: ""
  };

  popupRef = React.createRef<HTMLDivElement>();

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevState.isPopoverOpen !== this.state.isPopoverOpen &&
      this.popupRef.current
    ) {
      this.setState({
        popupArrowColor: window.getComputedStyle(this.popupRef.current)
          .backgroundColor
      });
    }
  }

  render() {
    return !this.props.content ||
      this.props.content === "" ||
      this.props.content === " " ||
      this.props.content === "-" ? (
      <div className={this.props.className}>{this.props.children}</div>
    ) : (
      <Popover
        isOpen={this.state.isPopoverOpen}
        positions={["top", "bottom", "left", "right"]} // preferred positions by priority
        padding={4}
        containerStyle={{ zIndex: "9999" }}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer // for showing the arrow below the popover
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={this.state.popupArrowColor}
            arrowSize={7}
            className="popover-arrow-container"
            arrowClassName="popover-arrow"
          >
            <div
              ref={this.popupRef}
              className={`bg-stone-600 text-white rounded-md px-2 py-1 text-sm whitespace-pre-line max-w-xs break-words ${
                this.props.capitalize && "capitalize"
              }`}
            >
              {this.props.content}
            </div>
          </ArrowContainer>
        )}
      >
        <div
          className={this.props.className}
          onMouseOver={() => this.setState({ isPopoverOpen: true })}
          onMouseLeave={() => this.setState({ isPopoverOpen: false })}
        >
          {this.props.children}
        </div>
      </Popover>
    );
  }
}
