import { Component, Fragment } from "react";
import { connect, ConnectedProps } from "react-redux";
import Button from "../../components/Button";
import Switch from "../../components/switch";
import { ADD_NOTIFICATION } from "../../store/types";
import { askForNotificationPermission } from "../../firebase";
import ConfirmationPopup from "../../components/ConfirmationPopup";
import agent from "../../agent";
import { withRouter } from "../../helpers/withRouter";
import { compose } from "redux";

//Redux mapping

const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const headers = ["", "E-mail", "Push", "In App"];

type NotificationType = "push" | "email" | "inApp";

type NotificationTypeGroup = {
  [key in NotificationType]: boolean;
};

type TaskNotificationGroups = {
  taskAssigned: NotificationTypeGroup;
  dueDateChange: NotificationTypeGroup;
  statusChange: NotificationTypeGroup;
  starMarkUnmark: NotificationTypeGroup;
  taskNameDescriptionComment: NotificationTypeGroup;
  userUpdate: NotificationTypeGroup;
  deleteTask: NotificationTypeGroup;
};

type OtherNotificationGroups = {
  addEditClient: NotificationTypeGroup;
  addEditContactPerson: NotificationTypeGroup;
  addEditDeleteCustomField: NotificationTypeGroup;
  addEditDeleteStatus: NotificationTypeGroup;
  addEditDeleteTag: NotificationTypeGroup;
  addEditDeleteUsers: NotificationTypeGroup;
  addEditGroups: NotificationTypeGroup;
  addEditRecurringTasks: NotificationTypeGroup;
};

export type NotificationSettingsType = {
  taskSettings: {
    currentUser: TaskNotificationGroups;
    otherUser: TaskNotificationGroups;
  };
  _id?: string;
} & OtherNotificationGroups;

type TaskSubGroup = keyof NotificationSettingsType["taskSettings"];

const nameFromKeys = (key: string) => {
  switch (key) {
    case "push":
      return "Push";
    case "email":
      return "E-mail";
    case "inApp":
      return "In App";
    case "taskSettings":
      return "Tasks";
    case "currentUser":
      return "Current User";
    case "otherUser":
      return "Other User";
    case "taskAssigned":
      return "Task Assigned to you as other user";
    case "dueDateChange":
      return "Due Date Change";
    case "statusChange":
      return "Status Change";
    case "starMarkUnmark":
      return "Star Mark/Unmark";
    case "taskNameDescriptionComment":
      return "Task Name, Description and Comment";
    case "userUpdate":
      return "User Update";
    case "deleteTask":
      return "Delete Task";
    case "addEditClient":
      return "Client";
    case "addEditContactPerson":
      return "Contact Person";
    case "addEditDeleteCustomField":
      return "Custom Field";
    case "addEditDeleteStatus":
      return "Status";
    case "addEditDeleteTag":
      return "Tag";
    case "addEditDeleteUsers":
      return "Users";
    case "addEditGroups":
      return "Groups";
    case "addEditRecurringTasks":
      return "Recurring Tasks";
    default:
      return "";
  }
};

interface Props {
  params?: { firmId?: string };
}

interface State {
  showPopup: boolean;
  notificationSettings: NotificationSettingsType;
  allTaskNotificationSettings: NotificationTypeGroup;
}

class NotificationSettings extends Component<Props & PropsFromRedux, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      showPopup: false,
      notificationSettings: {
        taskSettings: {
          currentUser: {
            taskAssigned: {
              push: false,
              email: false,
              inApp: false
            },
            dueDateChange: {
              push: false,
              email: false,
              inApp: false
            },
            statusChange: {
              push: false,
              email: false,
              inApp: false
            },
            starMarkUnmark: {
              push: false,
              email: false,
              inApp: false
            },
            taskNameDescriptionComment: {
              push: false,
              email: false,
              inApp: false
            },
            userUpdate: {
              push: false,
              email: false,
              inApp: false
            },
            deleteTask: {
              push: false,
              email: false,
              inApp: false
            }
          },
          otherUser: {
            taskAssigned: {
              push: false,
              email: false,
              inApp: false
            },
            dueDateChange: {
              push: false,
              email: false,
              inApp: false
            },
            statusChange: {
              push: false,
              email: false,
              inApp: false
            },
            starMarkUnmark: {
              push: false,
              email: false,
              inApp: false
            },
            taskNameDescriptionComment: {
              push: false,
              email: false,
              inApp: false
            },
            userUpdate: {
              push: false,
              email: false,
              inApp: false
            },
            deleteTask: {
              push: false,
              email: false,
              inApp: false
            }
          }
        },
        addEditClient: {
          push: false,
          email: false,
          inApp: false
        },
        addEditContactPerson: {
          push: false,
          email: false,
          inApp: false
        },
        addEditDeleteCustomField: {
          push: false,
          email: false,
          inApp: false
        },
        addEditDeleteStatus: {
          push: false,
          email: false,
          inApp: false
        },
        addEditDeleteTag: {
          push: false,
          email: false,
          inApp: false
        },
        addEditDeleteUsers: {
          push: false,
          email: false,
          inApp: false
        },
        addEditGroups: {
          push: false,
          email: false,
          inApp: false
        },
        addEditRecurringTasks: {
          push: false,
          email: false,
          inApp: false
        }
      },
      allTaskNotificationSettings: {
        push: false,
        email: false,
        inApp: false
      }
    };
  }

  handlePopupConfirm = () => {
    this.setState({ showPopup: false });
    window.open(
      "https://pms.TaxPido.com/support/knowledgebase/notification",
      "_blank"
    );
  };

  handlePopupCancel = () => {
    this.setState({ showPopup: false });
  };

  onEnablePushNotification = () => {
    localStorage.removeItem("sentToServer");
    localStorage.removeItem("notification");

    Notification.permission === "default"
      ? askForNotificationPermission()
      : this.setState({ showPopup: true });
  };

  componentDidMount(): void {
    this.getNotificationSettings();
  }

  componentDidUpdate(
    prevProps: Props & PropsFromRedux,
    prevState: State
  ): void {
    const prevFirmId = prevProps?.params?.firmId;
    const currentFirmId = this.props?.params?.firmId;
    if (prevFirmId !== currentFirmId) {
      this.getNotificationSettings();
    }
  }

  removeAutoGeneratedFields = <
    T extends {
      [key: string]: any;
    }
  >(
    obj: T
  ) => {
    delete obj._id;
    delete obj.userId;
    delete obj.__v;

    for (const key in obj) {
      if (key.startsWith("_")) {
        delete obj[key as keyof NotificationSettingsType];
      }
      if (obj.hasOwnProperty(key)) {
        delete obj[key]._id;
      }
    }
    for (const key in obj.taskSettings) {
      for (const key2 in obj.taskSettings[key])
        if (obj.taskSettings[key].hasOwnProperty(key2)) {
          delete obj.taskSettings[key][key2]._id;
        }
    }
    return obj;
  };

  getNotificationSettings = () => {
    const workspaceId = (this.props as any)?.params?.firmId;
    agent.Notifications.getNotificationsPreferences(workspaceId)
      .then((res: NotificationSettingsType) => {
        const allPushEnabledForTask = Object.values(
          res.taskSettings.currentUser
        ).every(item => item.push === true);
        const allEmailEnabledForTask = Object.values(
          res.taskSettings.currentUser
        ).every(item => item.email === true);
        const allInAppEnabledForTask = Object.values(
          res.taskSettings.currentUser
        ).every(item => item.inApp === true);

        this.setState({
          notificationSettings: this.removeAutoGeneratedFields(res),
          allTaskNotificationSettings: {
            push: allPushEnabledForTask,
            email: allEmailEnabledForTask,
            inApp: allInAppEnabledForTask
          }
        });
      })
      .catch(err => {
        // User has not configured notification settings yet so we will show default settings and not show any error message
        err?.response?.data?.message === "Notification settings not found!"
          ? (this.props as any).addNotification(
              "No notification settings configured",
              "Please configure notification settings",
              "info"
            )
          : // User has configured notification settings but we could not fetch them so we will show error message
            (this.props as any).addNotification(
              "Could not fetch notification settings",
              err?.response?.data?.message || "Something went wrong",
              "danger"
            );
      });
  };

  setNotificationSettings = (newSettings: NotificationSettingsType) => {
    const workspaceId = this.props?.params?.firmId;
    workspaceId &&
      agent.Notifications.changePreferences(workspaceId, newSettings)
        .then((res: { message: string }) => {
          (this.props as any).addNotification(
            "Notification settings updated",
            res.message,
            "success"
          );
          this.getNotificationSettings();
        })
        .catch(err => {
          (this.props as any).addNotification(
            "Could not update notification settings",
            err?.response?.data?.message || "Something went wrong",
            "error"
          );
        });
  };

  toggleAllTaskNotificationSettings = (type: NotificationType) => {
    const { notificationSettings, allTaskNotificationSettings } = this.state;
    const { taskSettings } = notificationSettings;
    const { currentUser, otherUser } = taskSettings;

    Object.entries(currentUser).forEach(([key, value]) => {
      currentUser[key as keyof TaskNotificationGroups] = {
        ...value,
        [type]: !allTaskNotificationSettings[type]
      };
    });
    Object.entries(otherUser).forEach(([key, value]) => {
      otherUser[key as keyof TaskNotificationGroups] = {
        ...value,
        [type]: !allTaskNotificationSettings[type]
      };
    });

    const newAllTaskNotificationSettings = {
      ...notificationSettings,
      taskSettings: {
        ...taskSettings,
        currentUser: { ...currentUser },
        otherUser: { ...otherUser }
      }
    };
    this.setNotificationSettings(newAllTaskNotificationSettings);
  };

  toggleTaskNotificationSettings = (
    group: TaskSubGroup,
    subGroup: keyof TaskNotificationGroups,
    type: NotificationType
  ) => {
    const { notificationSettings } = this.state;
    const { taskSettings } = notificationSettings;

    const newNotificationSettings = {
      ...notificationSettings,
      taskSettings: {
        ...taskSettings,
        [group]: {
          ...taskSettings[group],
          [subGroup]: {
            ...taskSettings[group][subGroup],
            [type]: !taskSettings[group][subGroup][type]
          }
        }
      }
    };
    this.setNotificationSettings(newNotificationSettings);
  };

  toggleOtherNotificationSettings = (
    group: keyof OtherNotificationGroups,
    type: NotificationType
  ) => {
    const { notificationSettings } = this.state;

    const newNotificationSettings = {
      ...notificationSettings,
      [group]: {
        ...notificationSettings[group],
        [type]: !notificationSettings[group][type]
      }
    };
    this.setNotificationSettings(newNotificationSettings);
  };

  render() {
    return (
      <div className="mt-8 space-y-6">
        {this.state.showPopup && (
          <ConfirmationPopup
            title="You have blocked notifications"
            message={`Please enable Notifications in your browser. Check this support article for more details.`}
            confirmText="Open Support Article"
            onConfirm={this.handlePopupConfirm}
            cancelText="Cancel"
            onCancel={this.handlePopupCancel}
          />
        )}
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Enable Notifications
          </h3>
          {/* <Button
            name="Enable push notifications"
            icon="outline/bell"
            onClick={this.onEnablePushNotification}
          /> */}
        </div>
        <div className={"max-w-full mx-auto pb-8 min-h-full"}>
          {/* Notification Settings list table */}
          <div
            id="table-scroll"
            className="justify-center my-1 overflow-auto border-2 border-gray-300 rounded-md"
          >
            <table className="min-w-full divide-y divide-gray-400">
              <thead className="bg-gray-100">
                <tr>
                  {headers.map(header => (
                    <th
                      key={header}
                      className="px-4 py-4 whitespace-nowrap text-sm text-gray-900 font-bold text-center"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-300">
                {Object.entries(this.state.notificationSettings)?.map(
                  ([mainGroupsName, value]) =>
                    nameFromKeys(mainGroupsName) &&
                    (mainGroupsName === "taskSettings" ? (
                      // For Task Notification Settings
                      <Fragment key={mainGroupsName}>
                        <tr className="bg-gray-100">
                          <td
                            colSpan={headers.length}
                            className="px-4 py-4 whitespace-nowrap text-sm text-gray-900 font-bold"
                          >
                            {nameFromKeys(mainGroupsName)}
                          </td>
                        </tr>
                        {/* For All Task Notification Settings */}
                        <tr className="odd:bg-gray-50">
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-medium">
                            Enable/Disable All
                          </td>
                          {Object.entries(
                            this.state.allTaskNotificationSettings
                          )?.map(([type, value]) => (
                            <td
                              key={type}
                              className="px-4 py-4 whitespace-nowrap text-sm text-gray-900 font-bold text-center"
                            >
                              <Switch
                                label={nameFromKeys(type)}
                                enabled={value}
                                onChange={() =>
                                  this.toggleAllTaskNotificationSettings(
                                    type as NotificationType
                                  )
                                }
                              />
                            </td>
                          ))}
                        </tr>
                        {/* For Task Notification Settings Groups */}
                        {Object.entries(value)?.map(
                          ([subGroupUnderTaskName, subGroupsUnderTask]: [
                            string,
                            TaskNotificationGroups
                          ]) =>
                            nameFromKeys(subGroupUnderTaskName) && (
                              <Fragment key={subGroupUnderTaskName}>
                                <tr className="bg-gray-100">
                                  <td
                                    colSpan={headers.length}
                                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-bold"
                                  >
                                    {nameFromKeys(subGroupUnderTaskName)}
                                  </td>
                                </tr>
                                {/* For Task Notification Settings items */}
                                {Object.entries(subGroupsUnderTask)?.map(
                                  ([subGroupName, subGroupValue]) => (
                                    <tr
                                      key={subGroupName}
                                      className="odd:bg-gray-50"
                                    >
                                      <td className="px-8 py-4 whitespace-nowrap text-sm text-gray-900 font-medium">
                                        {nameFromKeys(subGroupName)}
                                      </td>
                                      {/* For Task Notification Settings items toggles */}
                                      {Object.entries(subGroupValue)?.map(
                                        ([type, value]) =>
                                          nameFromKeys(type) && (
                                            <td
                                              key={type}
                                              className="px-4 py-4 whitespace-nowrap text-sm text-gray-900 font-bold text-center"
                                            >
                                              <Switch
                                                label={nameFromKeys(type)}
                                                enabled={value}
                                                onChange={() =>
                                                  this.toggleTaskNotificationSettings(
                                                    subGroupUnderTaskName as TaskSubGroup,
                                                    subGroupName as keyof TaskNotificationGroups,
                                                    type as NotificationType
                                                  )
                                                }
                                              />
                                            </td>
                                          )
                                      )}
                                    </tr>
                                  )
                                )}
                              </Fragment>
                            )
                        )}
                      </Fragment>
                    ) : (
                      // For Other Notification Settings
                      <Fragment key={mainGroupsName}>
                        <tr className="bg-gray-100">
                          <td
                            colSpan={headers.length}
                            className="px-4 py-4 whitespace-nowrap text-sm text-gray-900 font-bold"
                          >
                            {nameFromKeys(mainGroupsName)}
                          </td>
                        </tr>
                        <tr className="odd:bg-gray-50">
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-medium">
                            Add/Edit/Delete
                          </td>
                          {/* For Other Notification Settings items toggles */}
                          {Object.entries(value as NotificationTypeGroup)?.map(
                            ([type, value]) =>
                              nameFromKeys(type) && (
                                <td
                                  key={type}
                                  className="px-4 py-4 whitespace-nowrap text-sm text-gray-900 font-bold text-center"
                                >
                                  <Switch
                                    label={nameFromKeys(type)}
                                    enabled={value}
                                    onChange={() =>
                                      this.toggleOtherNotificationSettings(
                                        mainGroupsName as keyof OtherNotificationGroups,
                                        type as NotificationType
                                      )
                                    }
                                  />
                                </td>
                              )
                          )}
                        </tr>
                      </Fragment>
                    ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
export default compose(
  connector,
  withRouter
)(NotificationSettings) as React.ComponentType;
