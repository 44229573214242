import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ADD_NOTIFICATION } from "../../../store/types";
import { WithRouterProps } from "../../../helpers/withRouter";
import { RootState } from "../../../store";
import { ImportRequest } from "./Index";

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends Partial<PropsFromRedux & WithRouterProps> {
  showRequestModal: boolean;
  setOpen: (value: boolean) => void;
  importRequestDetails: ImportRequest | null;
  onClose?: () => void;
}
type State = {
  estimatedTimeForOneRecord: number;
};

class RequestProgress extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      estimatedTimeForOneRecord: 1
    };
  }

  render() {
    return (
      <>
        <Transition.Root show={this.props.showRequestModal} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={() => null}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-xl leading-6 font-medium text-gray-900"
                      >
                        QRMP Request Status
                      </Dialog.Title>
                    </div>
                  </div>
                  <div>
                    <div className="mt-4 text-sm font-medium text-gray-900">
                      <p>
                        <span className="font-medium">Current Status:</span>{" "}
                        <span>{this.props.importRequestDetails?.status}</span>
                      </p>
                      <p>
                        <span className="font-medium">Period:</span>{" "}
                        <span>{this.props.importRequestDetails?.period}</span>
                      </p>
                      <p>
                        <span className="font-medium">Created at:</span>{" "}
                        <span>
                          {this.props.importRequestDetails
                            ? new Date(
                                this.props.importRequestDetails?.createdAt
                              ).toLocaleDateString("en-In") +
                              " - " +
                              new Date(
                                this.props.importRequestDetails?.createdAt
                              ).toLocaleTimeString("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit"
                              })
                            : "Calculating..."}
                        </span>
                      </p>
                      <p>
                        <span className="font-medium">Last Updated at:</span>{" "}
                        <span>
                          {this.props.importRequestDetails
                            ? new Date(
                                this.props.importRequestDetails?.updatedAt
                              ).toLocaleDateString("en-In") +
                              " - " +
                              new Date(
                                this.props.importRequestDetails?.updatedAt
                              ).toLocaleTimeString("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit"
                              })
                            : "Calculating..."}
                        </span>
                      </p>
                    </div>
                    <div className="mt-6" aria-hidden="true">
                      <div className="bg-gray-200 rounded-full overflow-hidden">
                        <div
                          className="h-2 bg-indigo-600 rounded-full"
                          style={{
                            transformOrigin: "left",
                            transform: `scaleX(${
                              (((this.props.importRequestDetails?.result
                                ?.alreadyFiled ?? 0) +
                                (this.props.importRequestDetails?.result
                                  ?.statusUpdated ?? 0)) /
                                (this.props.importRequestDetails?.result
                                  ?.totalToRecordsToUpdate ?? 1)) *
                              100
                            }%)`
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex justify-between">
                      {this.props.importRequestDetails?.status !==
                        "Completed" && (
                        <p className="mt-2 text-sm text-left">
                          <span className="font-medium">Estimated time:</span>{" "}
                          <span>
                            {this.props.importRequestDetails
                              ? this.props.importRequestDetails?.result
                                  ?.totalToRecordsToUpdate *
                                  this.state.estimatedTimeForOneRecord >
                                60
                                ? `${Math.floor(
                                    (this.props.importRequestDetails?.result
                                      ?.totalToRecordsToUpdate *
                                      this.state.estimatedTimeForOneRecord) /
                                      60
                                  )} min ${
                                    (this.props.importRequestDetails?.result
                                      ?.totalToRecordsToUpdate *
                                      this.state.estimatedTimeForOneRecord) %
                                    60
                                  } sec`
                                : `0 min ${
                                    this.props.importRequestDetails?.result
                                      ?.totalToRecordsToUpdate *
                                    this.state.estimatedTimeForOneRecord
                                  } sec`
                              : "Calculating..."}
                          </span>
                        </p>
                      )}
                      <p className="mt-2 text-sm text-right ml-auto">
                        <span className="font-medium">Processed -</span>{" "}
                        <span>
                          {this.props.importRequestDetails
                            ? `${
                                this.props.importRequestDetails?.result
                                  ?.alreadyFiled +
                                this.props.importRequestDetails?.result
                                  ?.statusUpdated
                              }/${
                                this.props.importRequestDetails?.result
                                  ?.totalToRecordsToUpdate
                              }`
                            : "Calculating..."}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="mt-4">
                    <p className="text-xs text-gray-500">
                      Note:- You can close this page if you want. Your request
                      will be processed in background and you will get an email
                      once the status get updated.
                    </p>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      disabled={
                        this.props.importRequestDetails?.status !== "Completed"
                      }
                      className={
                        this.props.importRequestDetails?.status === "Completed"
                          ? "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                          : "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-300 cursor-not-allowed"
                      }
                      onClick={() => {
                        this.props.setOpen(false);
                        this.props.onClose && this.props.onClose();
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

export default connector(RequestProgress);
