import React, { ChangeEvent, ChangeEventHandler } from "react";
import agent from "../../agent";
import { connect, ConnectedProps } from "react-redux";
import { ADD_NOTIFICATION } from "../../store/types";
import { WithRouterProps, withRouter } from "../../helpers/withRouter";
import { compose } from "redux";
import TableMultiSelect from "../../components/TableMultiSelect";
import Switch from "../../components/switch";
import Icon from "../../components/Icon";
import { RootState } from "../../store";
import { BeforeAfterType, RecurringTask, RecurringTaskSettings } from "./Index";

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends Partial<PropsFromRedux & WithRouterProps> {
  smallScreen: boolean;
  task: RecurringTask;
  index: number;
  recurringTaskSetting?: RecurringTaskSettings;
}

interface State {
  loading: boolean;
  typingTimeout: NodeJS.Timeout | null;
  isError: boolean;
  message: string;
  recurringTypes: {
    _id: string;
    name: BeforeAfterType;
  }[];
  recurring: boolean;
  days: number;
  beforeAfter: BeforeAfterType;
}

class TaskItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      recurringTypes: [
        /* {
          _id: `${this.props.task._id}-before`,
          name: "before"
        }, */
        {
          _id: `${this.props.task._id}-after`,
          name: "after"
        }
      ],
      typingTimeout: null,
      isError: false,
      message: this.props.recurringTaskSetting?.messageToShow ?? "",
      recurring: this.props.recurringTaskSetting?.nextDate ? true : false,
      beforeAfter:
        "recurringType" in this.props.task
          ? this.props.task.recurringType
          : "after",
      days: this.props.recurringTaskSetting?.days
        ? this.props.recurringTaskSetting?.days
        : this.props.task.type === "OTHER"
        ? this.getTaskDays(this.props.task)
        : this.props.task.recurringDays
    };
  }

  getTaskDays = (task: RecurringTask) => {
    switch (task.periodType.toLowerCase()) {
      case "monthly":
        return 7;
      case "quarterly":
        return 15;
      case "half-yearly":
        return 30;
      case "yearly":
        return 30;
      default:
        return 7;
    }
  };

  addRecurringTask = (recurring: boolean) => {
    const recurringDays =
      this.props.task.type === "OTHER"
        ? this.getTaskDays(this.props.task)
        : this.props.task.recurringDays;

    if (!this.state.days) {
      const errMessage = "Please enter a number of days";
      this.setState({
        message: errMessage,
        isError: true,
        recurring: false,
        days: recurringDays
      });
      (this.props as any).addNotification("Error", errMessage, "error");
      return;
    } else if (
      (this.state.days < 0 || this.state.days > 28) &&
      this.props.task.periodType === "monthly"
    ) {
      const errMessage =
        "In case of monthly tasks, days should be greater than 0 and less than or equal to 28";
      this.setState({
        message: errMessage,
        isError: true,
        recurring: false,
        days: recurringDays
      });
      (this.props as any).addNotification("Error", errMessage, "error");
      return;
    } else if (
      (this.state.days < 0 || this.state.days > 88) &&
      this.props.task.periodType === "quarterly"
    ) {
      const errMessage =
        "In case of quarterly tasks, days should be greater than 0 and less than or equal to 88";
      this.setState({
        message: errMessage,
        isError: true,
        recurring: false,
        days: recurringDays
      });
      (this.props as any).addNotification("Error", errMessage, "error");
      return;
    } else if (
      (this.state.days < 0 || this.state.days > 180) &&
      this.props.task.periodType === "half-yearly"
    ) {
      const errMessage =
        "In case of half-yearly tasks, days should be greater than 0 and less than or equal to 180";
      this.setState({
        message: errMessage,
        isError: true,
        recurring: false,
        days: recurringDays
      });
      (this.props as any).addNotification("Error", errMessage, "error");
      return;
    } else if (
      (this.state.days < 0 || this.state.days > 360) &&
      this.props.task.periodType === "yearly"
    ) {
      const errMessage =
        "In case of yearly tasks, days should be greater than 0 and less than or equal to 360";
      this.setState({
        message: errMessage,
        isError: true,
        recurring: false,
        days: recurringDays
      });
      (this.props as any).addNotification("Error", errMessage, "error");
      return;
    } else {
      this.setState({ loading: true, isError: false });
      agent.RecurringTask.addRecurringTask(
        (this.props as any).params.firmId,
        this.props.task.name,
        this.state.beforeAfter,
        this.state.days,
        recurring
      )
        .then(res => {
          this.setState({
            loading: false,
            message: res.messageToShow,
            isError: false
          });
        })
        .catch(err => {
          this.setState({
            loading: false,
            message: err.response.data?.error,
            recurring: false,
            isError: true
          });
          (this.props as any).addNotification(
            "Error adding recurring task",
            err.response.data?.error,
            "error"
          );
        });
    }
  };

  handleAdd = (e: ChangeEvent<HTMLInputElement>) => {
    const numberOnlyRegex = /^[0-9\b]*$/;
    if (e.target.value === "" || numberOnlyRegex.test(e.target.value)) {
      this.setState({ days: e.target.valueAsNumber });
    }
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      typingTimeout: setTimeout(() => {
        this.addRecurringTask(this.state.recurring);
      }, 1000)
    });
  };

  // componentDidMount() {
  //   this.props.recurringTaskSetting?.active &&
  //     this.addRecurringTask(this.props.recurringTaskSetting?.active);
  // }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevState.recurring !== this.state.recurring ||
      prevState.beforeAfter !== this.state.beforeAfter
    ) {
      this.addRecurringTask(this.state.recurring);
    }
  }

  render() {
    return this.props.smallScreen ? (
      <li key={this.props.task._id} className="bg-white shadow-md rounded-lg">
        <div className="px-4 py-4 sm:px-6 grid gap-4 grid-cols-2 relative">
          <p className="text-sm text-gray-800 font-semibold mt-auto">
            <span>Name - </span>
            <span className="font-bold">
              {this.props.task.name}{" "}
              {this.props.task.name
                .toLowerCase()
                .includes(this.props.task.periodType?.toLowerCase())
                ? ""
                : `(${this.props.task?.periodType})`}
            </span>
          </p>
          <div className="justify-self-end mt-1">
            <Switch
              openIcon=""
              closeIcon=""
              label={"Set recurring on/off"}
              enabled={this.state.recurring}
              onChange={() => {
                this.setState({ recurring: !this.state.recurring });
              }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <div>After / Before - </div>
            <div className="relative rounded-md">
              <span
                className={
                  !this.state.recurring
                    ? "absolute z-[5] isolate inset-0 cursor-not-allowed backdrop-filter backdrop-brightness-95"
                    : ""
                }
              ></span>
              <TableMultiSelect
                placeholder="Select Task Type"
                type={`recurringType-${this.props.task._id}`}
                items={this.state.recurringTypes}
                selected={{
                  name: this.state.beforeAfter
                }}
                onChange={selected => {
                  this.setState({
                    beforeAfter: selected.name
                  });
                }}
              />
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <span>Days - </span>
            <span className="font-bold capitalize text-indigo-600">
              <input
                type="number"
                min="1"
                max="365"
                disabled={!this.state.recurring}
                value={this.state.days}
                onChange={this.handleAdd}
                className="w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 invalid:border-red-500 invalid:outline-red-400 focus:invalid:ring-red-500"
              />
            </span>
          </div>
          <div className="text-sm text-gray-800 mt-auto max-w-full col-span-2">
            <span>Message - </span>
            <span
              className={`font-bold ${
                this.state.isError ? "text-red-500" : ""
              }`}
            >
              {this.state.loading ? (
                <Icon name="loading" className="inline ml-2" />
              ) : (
                this.state.message ?? ""
              )}
            </span>
          </div>
        </div>
      </li>
    ) : (
      <tr
        key={this.props.task._id}
        className={this.props.index % 2 === 0 ? "" : "bg-gray-100"}
      >
        <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm text-gray-900 font-bold sm:pl-6">
          <span>{this.props.task.name}</span>{" "}
          <span className="capitalize">
            {" "}
            {this.props.task.name
              .toLowerCase()
              .includes(this.props.task.periodType?.toLowerCase())
              ? ""
              : `(${this.props.task?.periodType})`}
          </span>
        </td>
        <td className="px-3 py-3 text-sm text-gray-500 relative text-center">
          <Switch
            openIcon=""
            closeIcon=""
            label={"Set recurring on/off"}
            enabled={this.state.recurring}
            onChange={() => {
              this.setState({ recurring: !this.state.recurring });
            }}
          />
        </td>
        <td className="px-3 py-3 whitespace-nowrap font-semibold text-sm text-gray-900">
          <div className="relative rounded-md">
            <div
              className={
                !this.state.recurring
                  ? "absolute z-[5] isolate inset-0 cursor-not-allowed backdrop-filter backdrop-brightness-95"
                  : ""
              }
            ></div>
            <TableMultiSelect
              placeholder="Select Task Type"
              type={`recurringType-${this.props.task._id}`}
              items={this.state.recurringTypes}
              selected={{
                name: this.state.beforeAfter
              }}
              onChange={selected => {
                this.setState({
                  beforeAfter: selected.name
                });
              }}
            />
          </div>
        </td>
        <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900">
          <input
            type="number"
            min="1"
            max="365"
            disabled={!this.state.recurring}
            value={this.state.days}
            onChange={this.handleAdd}
            className="w-20 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 invalid:border-red-500 invalid:outline-red-400 focus:invalid:ring-red-500"
          />
        </td>
        {this.props.task.type === "OTHER" && (
          <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900 text-center">
            {this.props.task.clientsCount ?? 0}
          </td>
        )}
        <td className="min-w-[13rem] px-3 py-3 text-sm text-gray-900">
          <span className={this.state.isError ? "text-red-500" : ""}>
            {this.state.loading ? (
              <Icon name="loading" />
            ) : (
              this.state.message ?? ""
            )}
          </span>
        </td>
      </tr>
    );
  }
}

export default compose(
  connector,
  withRouter
)(TaskItem) as React.ComponentType<Props>;
