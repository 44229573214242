import { Dialog, Transition } from "@headlessui/react";
import React, { ChangeEvent, Fragment } from "react";
import { connect, ConnectedProps } from "react-redux";
import agent from "../../agent";
import Icon from "../../components/Icon";
import capitalize from "../../helpers/capitalize";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import { getAllTagsList } from "../../components/Dashboard";
import { RootState } from "../../store";

//Redux mapping
const mapStateToProps = (state: RootState) => ({ ...state.common });

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: (payload: any) => dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: string) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

type Props = {
  closeModal: (fetchAgain: boolean) => void;
  showTagModal?: boolean;
  tagData?: (newTag: any) => void;
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type State = {
  logging: boolean;
  name: string;
  description: string;
};

class AddTag extends React.Component<Props, State> {
  state: State = {
    logging: false,
    name: "",
    description: ""
  };

  onKeyUpFunction = (event: any) => {
    if (event.keyCode === 27) {
      this.closeTagModal(false);
    }

    if (event.keyCode === 13) {
      this.addTag();
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyUpFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyUpFunction, false);
  }

  addTag = () => {
    const workSpaceId = (this.props as any).currentFirm._id;
    const name = this.state.name;
    const description = this.state.description;
    if (name !== "") {
      this.setState({ logging: true });
      agent.Tag.addTag(name, description, workSpaceId)
        .then((response: any) => {
          getAllTagsList(
            workSpaceId,
            (this.props as any).updateCommon,
            (this.props as any).addNotification
          );
          this.setState({ logging: false });

          (this.props as any).addNotification(
            "Tag Added",
            "Successfully added a tag.",
            "success"
          );
          if (this.props.showTagModal) {
            const newTag = { _id: response.tagid, name, description };
            this.sendTag(newTag);
          }
          this.closeTagModal(true);
        })
        .catch((err: any) => {
          this.setState({ logging: false });
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    } else {
      if (!name) {
        (this.props as any).addNotification(
          "Empty Tag Name Field",
          "Tag Name Field is Required!.",
          "danger"
        );
      }
    }
  };

  closeTagModal = (fetchAgain: boolean) => {
    this.props.closeModal(fetchAgain);
  };

  sendTag = (newTag: any) => {
    this.props.tagData!(newTag);
  };

  updateState =
    (field: "name" | "description") =>
    (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      this.setState({
        ...this.state,
        [field]: capitalize(ev.target.value)
      });
    };

  render() {
    return (
      <>
        <Transition.Root
          show={
            (this.props as any)?.currentModal?.modalName === "ADD_TAG_MODAL" ||
            this.props.showTagModal
          }
          as={Fragment}
        >
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={() => null}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div>
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Add Tag
                      </h3>
                    </div>
                    <div>
                      <form onSubmit={e => e.preventDefault()}>
                        <div className="mt-4">
                          <div className="mb-4">
                            <label
                              htmlFor="company_website"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Name <span className="text-red-600">*</span>
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="text"
                                name="company_website"
                                value={this.state.name}
                                onChange={this.updateState("name")}
                                id="company_website"
                                className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                                placeholder="Name"
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor="comment"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Description
                            </label>
                            <div className="mt-1">
                              <textarea
                                rows={4}
                                name="comment"
                                id="comment"
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                value={this.state.description}
                                onChange={this.updateState("description")}
                                placeholder="Add Description..."
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mt-5 sm:mt-4 sm:flex sm:justify-end">
                          <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:w-32 sm:text-sm"
                            onClick={() => this.closeTagModal(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            disabled={this.state.logging}
                            className={
                              "mt-3 sm:ml-4 w-full inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:mt-0 sm:w-32 sm:text-sm"
                            }
                            onClick={this.addTag}
                          >
                            <span className="w-full flex justify-end">
                              {this.state.logging ? (
                                <Icon name="loading" className="mr-2" />
                              ) : null}
                            </span>
                            <span>Save</span>
                            <span className="w-full"></span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

export default connector(AddTag);
