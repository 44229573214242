import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../store/types";

import {
  dueDateList,
  DueDateListOptionType,
  DueDateType,
  getDaysInMonth,
  getDueDateFromDays
} from "../helpers/calendar";
import timeTillMidnight, {
  timeFromMidnight
} from "../helpers/timeTillMidnight";
import TableMultiSelect from "./TableMultiSelect";
import { RootState } from "../store";
import { WithRouterProps } from "../helpers/withRouter";

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: (payload: any) => dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = Partial<PropsFromRedux & WithRouterProps> &
  (
    | {
        showShortcuts: true;
        selectedDueDate: DueDateListOptionType;
        setSelectedDueDate: (selected: DueDateListOptionType) => void;
      }
    | {
        showShortcuts?: false;
        selectedDueDate?: undefined;
        setSelectedDueDate?: undefined;
      }
  ) & {
    onAddClick: (from: string, to: string, forOverDue: boolean) => void;
    onCancelClick: () => void;
    position: string;
    selectedFromDate: string;
    selectedToDate: string;
  };

class Calender extends React.Component<Props> {
  state: {
    fromMonth: number;
    toMonth: number;
    fromYear: number;
    toYear: number;
    fromDays: any;
    toDays: any;
    selectFromDate: string;
    selectToDate: string;
  };

  constructor(props: any) {
    super(props);

    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const { selectedFromDate, selectedToDate } = props;
    const fromMonth = selectedFromDate
      ? new Date(selectedFromDate).getMonth()
      : currentMonth;
    const fromYear = selectedFromDate
      ? new Date(selectedFromDate).getFullYear()
      : currentYear;
    const toMonth = selectedToDate
      ? new Date(selectedToDate).getMonth()
      : currentMonth;
    const toYear = selectedToDate
      ? new Date(selectedToDate).getFullYear()
      : currentYear;
    const fromDays = getDaysInMonth(fromMonth, fromYear);
    const toDays = selectedToDate ? getDaysInMonth(toMonth, toYear) : fromDays;

    this.state = {
      fromMonth: fromMonth,
      toMonth: toMonth,
      fromYear: fromYear,
      toYear: toYear,
      fromDays: fromDays,
      toDays: toDays,
      selectFromDate: props.selectedFromDate || new Date().toISOString(),
      selectToDate: props.selectedToDate || new Date().toISOString()
    };
  }

  classNames = (...classes: any) => {
    return classes.filter(Boolean).join(" ");
  };

  /* fromNextClick = () => {
    const { fromMonth, fromYear } = this.state;
    if (fromMonth === 11) {
      const days = getDaysInMonth(0, fromYear + 1);
      this.setState({
        fromMonth: 0,
        fromYear: fromYear + 1,
        fromDays: days
      });
    } else {
      const days = getDaysInMonth(fromMonth + 1, fromYear);
      this.setState({
        fromMonth: fromMonth + 1,
        fromDays: days
      });
    }
  };

  fromPreviousClick = () => {
    const { fromMonth, fromYear } = this.state;
    if (fromMonth === 0) {
      const days = getDaysInMonth(11, fromYear - 1);
      this.setState({
        fromMonth: 11,
        fromYear: fromYear - 1,
        fromDays: days
      });
    } else {
      const days = getDaysInMonth(fromMonth - 1, fromYear);
      this.setState({
        fromMonth: fromMonth - 1,
        fromDays: days
      });
    }
  };

  toNextClick = () => {
    const { toMonth, toYear } = this.state;
    if (toMonth === 11) {
      const days = getDaysInMonth(0, toYear + 1);
      this.setState({
        toMonth: 0,
        toYear: toYear + 1,
        toDays: days
      });
    } else {
      const days = getDaysInMonth(toMonth + 1, toYear);
      this.setState({
        toMonth: toMonth + 1,
        toDays: days
      });
    }
  };

  toPreviousClick = () => {
    const { toMonth, toYear } = this.state;
    if (toMonth === 0) {
      const days = getDaysInMonth(11, toYear - 1);
      this.setState({
        toMonth: 11,
        toYear: toYear - 1,
        toDays: days
      });
    } else {
      const days = getDaysInMonth(toMonth - 1, toYear);
      this.setState({
        toMonth: toMonth - 1,
        toDays: days
      });
    }
  }; */

  onFromDateClick = (date: string) => {
    this.setState({ selectFromDate: date });
  };

  onToDateClick = (date: string) => {
    this.setState({ selectToDate: date });
  };

  onDueDateClick = (days: DueDateType) => {
    const { from, to } = getDueDateFromDays(days);
    const isOverDue =
      days === "overdue" ||
      days === "overdueupto7days" ||
      days === "overduemorethan7days";

    this.props.onAddClick(from, to, isOverDue);
  };

  onAddDateRangeClick = () => {
    const { selectFromDate, selectToDate } = this.state;
    const fromDate = timeFromMidnight(new Date(selectFromDate)).toISOString();
    const toDate = timeTillMidnight(new Date(selectToDate)).toISOString();
    if (fromDate < toDate || fromDate === toDate) {
      this.props.onAddClick(fromDate, toDate, false);
    } else {
      (this.props as any).addNotification(
        "Invalid Date Range",
        "Please add a valid range",
        "danger"
      );
    }
  };

  render() {
    return (
      <>
        <div
          className="fixed w-screen h-screen top-0 left-0 bg-transparent opacity-40 z-10"
          onClick={this.props.onCancelClick}
        ></div>
        <div
          className={`bg-white shadow-lg p-4 overflow-auto rounded border border-gray-300 mt-8 z-20 absolute ${this.props.position}`}
        >
          <div className="flex sm:flex-row flex-col items-stretch gap-3">
            {this.props.showShortcuts && (
              <div className="border border-gray-200 bg-gray-50 shadow p-3">
                <div className="min-w-[14rem]">
                  <p className="text-sm text-gray-800 font-bold mb-3">Period</p>
                  <TableMultiSelect
                    type="dueDate"
                    items={[...dueDateList]}
                    onChange={(selected: DueDateListOptionType) => {
                      this.props.setSelectedDueDate?.(selected);
                      this.onDueDateClick(selected.days);
                    }}
                    selected={this.props.selectedDueDate}
                  />
                </div>
              </div>
            )}
            <div className="border border-gray-200 bg-gray-50 shadow p-3">
              <p className="text-sm text-gray-800 font-bold mb-2">From</p>
              <input
                type="date"
                value={this.state.selectFromDate?.split("T")[0] || ""}
                onChange={e => this.onFromDateClick(e.target.value)}
                max={this.state.selectToDate?.split("T")[0] || ""}
                id="search_due_date_from"
                className="focus:ring-indigo-500 focus:border-indigo-500 inline-block rounded-md sm:text-sm border-gray-300 w-full"
                placeholder="Select Due Date"
                autoFocus
              />
              {/* <div className="flex items-center justify-between mt-1">
                <button
                  type="button"
                  className="flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                  onClick={this.fromPreviousClick}
                >
                  <span className="sr-only">Previous month</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <h2 className="font-semibold text-gray-900">
                  {months[this.state.fromMonth]} {this.state.fromYear}
                </h2>
                <button
                  type="button"
                  className="flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                  onClick={this.fromNextClick}
                >
                  <span className="sr-only">Next month</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-4 grid grid-cols-7 border-b border-gray-200 text-center text-xs leading-6 text-gray-500">
                <div>S</div>
                <div>M</div>
                <div>T</div>
                <div>W</div>
                <div>T</div>
                <div>F</div>
                <div>S</div>
              </div>
              <div className="grid grid-cols-7 w-80 text-sm">
                {this.state.fromDays.map((item: any, dayIdx: number) => (
                  <div key={dayIdx + "From"} className="py-2">
                    <button
                      type="button"
                      disabled={item === undefined}
                      onClick={() => this.onFromDateClick(item?.value)}
                      className={this.classNames(
                        `${
                          item ? "hover:bg-gray-200" : ""
                        } mx-auto flex h-8 w-8 items-center justify-center rounded-full`,
                        item &&
                          this.state.selectFromDate === item.value &&
                          "font-semibold bg-indigo-600 text-white hover:bg-indigo-600",
                        item && item.isToday && "text-indigo-600 font-semibold"
                      )}
                    >
                      {item
                        ? item.date.startsWith("0")
                          ? item.date.replace("0", "")
                          : item.date
                        : ""}
                    </button>
                  </div>
                ))}
              </div> */}
            </div>
            <div className="border border-gray-200 bg-gray-50 shadow p-3">
              <p className="text-sm text-gray-800 font-bold mb-2">To</p>
              <input
                type="date"
                value={this.state.selectToDate?.split("T")[0] || ""}
                onChange={e => this.onToDateClick(e.target.value)}
                min={this.state.selectFromDate?.split("T")[0] || ""}
                id="search_due_date_to"
                className="focus:ring-indigo-500 focus:border-indigo-500 inline-block rounded-md sm:text-sm border-gray-300 w-full"
                placeholder="Select Due Date"
              />
              {/* <div className="flex items-center justify-between mt-2">
                <button
                  type="button"
                  className="flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                  onClick={this.toPreviousClick}
                >
                  <span className="sr-only">Previous month</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <h2 className="font-semibold text-gray-900">
                  {months[this.state.toMonth]} {this.state.toYear}
                </h2>
                <button
                  type="button"
                  className="ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                  onClick={this.toNextClick}
                >
                  <span className="sr-only">Next month</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-4 grid grid-cols-7 border-b border-gray-200 text-center text-xs leading-6 text-gray-500">
                <div>S</div>
                <div>M</div>
                <div>T</div>
                <div>W</div>
                <div>T</div>
                <div>F</div>
                <div>S</div>
              </div>
              <div className="grid grid-cols-7 w-80 text-sm">
                {this.state.toDays.map((item: any, dayIdx: number) => (
                  <div key={dayIdx + "To"} className={"py-2"}>
                    <button
                      type="button"
                      disabled={item === undefined}
                      onClick={() => this.onToDateClick(item?.value)}
                      className={this.classNames(
                        `${
                          item ? "hover:bg-gray-200" : ""
                        } mx-auto flex h-8 w-8 items-center justify-center rounded-full`,
                        item &&
                          this.state.selectToDate === item.value &&
                          "font-semibold bg-indigo-600 text-white hover:bg-indigo-600",
                        item && item.isToday && "text-indigo-600 font-semibold"
                      )}
                    >
                      {item
                        ? item.date.startsWith("0")
                          ? item.date.replace("0", "")
                          : item.date
                        : ""}
                    </button>
                  </div>
                ))}
              </div> */}
            </div>
          </div>
          <div className="w-full mt-5 flex justify-end">
            <button
              type="button"
              className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none w-28 text-sm"
              onClick={this.props.onCancelClick}
            >
              Cancel
            </button>
            <button
              disabled={!this.state.selectFromDate || !this.state.selectToDate}
              type="button"
              className={`ml-4 inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 font-medium text-white hover:bg-indigo-700 focus:outline-none w-28 text-sm ${
                !this.state.selectFromDate || !this.state.selectToDate
                  ? "opacity-70 cursor-not-allowed"
                  : ""
              }`}
              onClick={this.onAddDateRangeClick}
            >
              Add
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default connector(Calender);
