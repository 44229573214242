import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import agent from "../agent";
import { connect, ConnectedProps } from "react-redux";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../store/types";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Icon from "./Icon";

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: (payload: any) => dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type,
      },
    }),
});

type Props = {
  onLoad?: (forSearch: boolean) => void;
  inActiveModalSetOpen?: (open: boolean) => void;
  state?: any;
  type?: any;
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class InActiveModal extends React.Component<Props, PropsFromRedux> {
  state: {
    logging: boolean;
  };
  myRef: React.RefObject<HTMLButtonElement>;

  constructor(props: any) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      logging: false,
    };
  }

  onKeyUpFunction = (event: any) => {
    if (event.keyCode === 27) {
      this.setOpen(false);
    }

    if (event.keyCode === 13) {
      this.inActiveRow();
    }
  };

  componentDidMount() {
    this.myRef.current!.focus();
    document.addEventListener("keydown", this.onKeyUpFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyUpFunction, false);
  }

  makeTagInactive = () => {
    const workSpaceId = (this.props as any)?.currentFirm._id;
    const tagId = this.props.state.selectedRow._id;
    if (tagId !== undefined) {
      this.setState({ logging: true });
      agent.Tag.makeTagInactive(tagId, workSpaceId)
        .then((response: any) => {
          (this.props as any).addNotification(
            "Tag Inactive",
            "Successfully Inactivated a tag.",
            "success"
          );
          this.setState({ logging: false });
          this.setOpen(false);
          this.onLoad();
        })
        .catch((err: any) => {
          this.setState({ logging: false });
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    }
  };

  makeStatusInactive = () => {
    const workSpaceId = (this.props as any)?.currentFirm._id;
    const statusId = this.props.state.selectedRow._id;
    if (statusId !== undefined) {
      this.setState({ logging: true });
      agent.Status.makeStatusInactive(statusId, workSpaceId)
        .then((response: any) => {
          (this.props as any).addNotification(
            "Status Inactive",
            "Successfully Inactivated a status.",
            "success"
          );
          this.setState({ logging: false });
          this.setOpen(false);
          this.onLoad();
        })
        .catch((err: any) => {
          this.setState({ logging: false });
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    }
  };

  makeCustomFieldInactive = () => {
    const workSpaceId = (this.props as any)?.currentFirm._id;
    const customFieldId = this.props.state.selectedRow._id;
    if (customFieldId !== undefined) {
      this.setState({ logging: true });
      agent.CustomField.makeCustomFieldInactive(customFieldId, workSpaceId)
        .then((response: any) => {
          (this.props as any).addNotification(
            "Field Inactive",
            "Successfully Inactivated a field.",
            "success"
          );
          this.setState({ logging: false });
          this.setOpen(false);
          this.onLoad();
        })
        .catch((err: any) => {
          this.setState({ logging: false });
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    }
  };

  makePersonInactive = () => {
    const workSpaceId = (this.props as any)?.currentFirm._id;
    const contactPersonId = this.props.state.selectedRow._id;
    if (contactPersonId !== undefined) {
      this.setState({ logging: true });
      agent.ContactPerson.makePersonInactive(contactPersonId, workSpaceId)
        .then((response: any) => {
          (this.props as any).addNotification(
            "Person Inactive",
            "Successfully Inactivated a person.",
            "success"
          );
          this.setState({ logging: false });
          this.setOpen(false);
          this.onLoad();
        })
        .catch((err: any) => {
          this.setState({ logging: false });
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    }
  };

  makeGroupInactive = () => {
    const workSpaceId = (this.props as any)?.currentFirm._id;
    const groupId = this.props.state.selectedRow._id;
    if (groupId !== undefined) {
      this.setState({ logging: true });
      agent.ClientGroups.makeClientGroupInactive(workSpaceId, groupId)
        .then((response: any) => {
          (this.props as any).addNotification(
            "Group Inactive",
            "Successfully Inactivated a group.",
            "success"
          );
          this.setState({ logging: false });
          this.setOpen(false);
          this.onLoad();
        })
        .catch((err: any) => {
          this.setState({ logging: false });
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    }
  };

  makeFirmInactive = () => {
    const workSpaceId = this.props.state.selectedRow._id;
    if (workSpaceId !== undefined) {
      this.setState({ logging: true });
      agent.Firm.makeFirmInactive(workSpaceId)
        .then((response: any) => {
          (this.props as any).addNotification(
            "Firm Inactive",
            "Successfully Inactivated a firm.",
            "success"
          );
          const currentSelectedFirm = (this.props as any).currentFirm;
          if (currentSelectedFirm?._id === workSpaceId) {
            const firms = (this.props as any)?.firms;
            const filterFirm = firms?.filter(
              (item: any) => item._id !== workSpaceId
            );
            // console.log("filterFirm", filterFirm, firms);
            (this.props as any).updateCommon({
              currentFirm: filterFirm[0],
            });
          }
          this.setState({ logging: false });
          this.setOpen(false);
          this.onLoad();
        })
        .catch((err: any) => {
          // console.log("err", err);
          this.setState({ logging: false });
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    }
  };

  makeClientInactive = () => {
    const workSpaceId = (this.props as any)?.currentFirm._id;
    const clientId = this.props.state.selectedRow._id;
    if (clientId !== undefined) {
      this.setState({ logging: true });
      agent.Clients.makeClientInactive(workSpaceId, clientId)
        .then((response: any) => {
          (this.props as any).addNotification(
            "Client Inactive",
            "Successfully Inactivated a client.",
            "success"
          );
          this.setState({ logging: false });
          this.setOpen(false);
          this.onLoad();
        })
        .catch((err: any) => {
          this.setState({ logging: false });
          // console.log({ err });
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    }
  };

  makeUserInactive = () => {
    const workSpaceId = (this.props as any)?.currentFirm._id;
    const userId = this.props.state.selectedRow._id;
    if (userId !== undefined) {
      this.setState({ logging: true });
      agent.User.makeUserInactive(workSpaceId, userId)
        .then((response: any) => {
          (this.props as any).addNotification(
            "User Inactive",
            "Successfully Inactivated a user.",
            "success"
          );
          this.setState({ logging: false });
          this.setOpen(false);
          this.onLoad();
        })
        .catch((err: any) => {
          this.setState({ logging: false });
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    }
  };

  makeReceiptSourceInActive = () => {
    const workSpaceId = (this.props as any)?.currentFirm._id;
    const sourceId = this.props.state.selectedRow._id;
    if (sourceId !== undefined) {
      this.setState({ logging: true });
      agent.Receipt.makeSourceInActive(sourceId, workSpaceId)
        .then((response: any) => {
          (this.props as any).addNotification(
            "Source Inactive",
            "Successfully Inactivated a source.",
            "success"
          );
          this.setState({ logging: false });
          this.setOpen(false);
          this.onLoad();
        })
        .catch((err: any) => {
          this.setState({ logging: false });
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    }
  };

  makeReceiptCategoryInActive = () => {
    const workSpaceId = (this.props as any)?.currentFirm._id;
    const categoryId = this.props.state.selectedRow._id;
    if (categoryId !== undefined) {
      this.setState({ logging: true });
      agent.Receipt.makeCategoryInActive(categoryId, workSpaceId)
        .then((response: any) => {
          (this.props as any).addNotification(
            "Category Inactive",
            "Successfully Inactivated a category.",
            "success"
          );
          this.setState({ logging: false });
          this.setOpen(false);
          this.onLoad();
        })
        .catch((err: any) => {
          this.setState({ logging: false });
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    }
  };

  inActiveRow = () => {
    switch (this.props.type) {
      case "tag":
        return this.makeTagInactive();
      case "status":
        return this.makeStatusInactive();
      case "field":
        return this.makeCustomFieldInactive();
      case "person":
        return this.makePersonInactive();
      case "group":
        return this.makeGroupInactive();
      case "firm":
        return this.makeFirmInactive();
      case "client":
        return this.makeClientInactive();
      case "user":
        return this.makeUserInactive();
      case "source":
        return this.makeReceiptSourceInActive();
      case "category":
        return this.makeReceiptCategoryInActive();
      default:
        return;
    }
  };

  setOpen = (open: boolean) => {
    (this.props as any).inActiveModalSetOpen(open);
  };

  onLoad = () => {
    (this.props as any).onLoad();
  };

  render() {
    return (
      <>
        <Transition.Root
          show={(this.props as any).state.showInActiveModal}
          as={Fragment}
        >
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={undefined}
            onClose={() => null}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                    <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                      <button
                        id={"close"}
                        type="button"
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => this.setOpen(false)}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-medium text-gray-900"
                        >
                          Mark Inactive
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you want to mark {this.props.type} '
                            {this.props.state.selectedRow.name}' as inactive?
                          </p>
                          {this.props.type === "firm" && (
                            <p className="text-red-600 mt-2 text-sm font-medium">
                              Note: All the pending invitations sent to the user
                              will be canceled once the firm is marked inactive
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="mt-5 sm:mt-4 sm:flex sm:justify-end">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:w-32 sm:text-sm"
                        onClick={() => this.setOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        ref={this.myRef}
                        disabled={this.state.logging}
                        className={
                          "mt-3 sm:ml-4 w-full inline-flex items-center justify-center rounded-md border border-transparent shadow-sm py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:mt-0 sm:w-40 sm:text-sm"
                        }
                        onClick={this.inActiveRow}
                      >
                        <span className="w-full flex justify-end">
                          {this.state.logging ? (
                            <Icon name="loading" className="mr-1.5" />
                          ) : null}
                        </span>
                        <span className="whitespace-nowrap">Mark Inactive</span>
                        <span className="w-full"></span>
                      </button>
                    </div>

                    {/* <div className="mt-5 sm:mt-4 sm:flex sm:justify-end">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:w-auto sm:text-sm"
                        onClick={this.inActiveRow}
                      >
                        {this.state.logging ? <Icon name="loading" /> : null}
                        Mark Inactive
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base sm:ml-3 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => this.setOpen(false)}
                      >
                        Cancel
                      </button>
                    </div> */}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

export default connector(InActiveModal);
