import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import config from "./config";
import { MessageType, TaksType } from "./helpers/types";
import {
  RegisterMode,
  RegisterType
} from "./pages/RegisterInOut/AddEditRegisterInOut";
import { createSearchParams } from "react-router-dom";
import { ChangeType, LogType } from "./pages/Reports/ReportFilters";
import { BooleanString } from "./pages/RegisterInOut";
import { NotificationSettingsType } from "./pages/Profile/NotificationSettings";
import { FrequencyType } from "./pages/Clients/QRMP/Index";
import {
  DscExpiryDateOptionItem,
  DscPasswordOptionItem
} from "./pages/ContactPerson/DSC";
import { PurchaseType } from "./pages/Subscription";
import store, { dispatch } from "./store";

axios.defaults.baseURL = config.baseURL;

const responseBody = (res: AxiosResponse) => res.data;

const axiosOptions = { withCredentials: true };

/* For refreshing authtoken when expired */
axios.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    if (
      error.response?.status === 401 &&
      error.response?.data?.tokenType === "auth" &&
      !originalRequest._retry
    ) {
      originalRequest._retry = false;
      const response = await axios.post(
        "/auth/verify/getAuthToken",
        {},
        axiosOptions
      );
      if (response.status === 200) {
        return axios(originalRequest);
      }
    }
    if (
      error.response?.status === 401 &&
      error.response?.data?.tokenType === "refresh" &&
      !originalRequest._retry
    ) {
      localStorage.setItem("loggedIn", "false");
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(response => {
  const { data } = response;

  if (
    !store.getState().common.showMaintenance &&
    typeof data === "object" &&
    "maintenance" in data &&
    data.maintenance &&
    !response.config.url?.includes("/api/version")
  ) {
    const { upcomingMaintenanceMessage, maintenanceRunningMessage } = data;

    dispatch({
      type: "UPDATE_COMMON",
      payload: {
        showMaintenance: true,
        upcomingMaintenanceMessage,
        maintenanceRunningMessage
      }
    });
    return Promise.reject({ message: maintenanceRunningMessage });
  }
  return response;
});

const requests = {
  get: (url: string) => axios.get(url, axiosOptions).then(responseBody),
  getBlob: (url: string, options: AxiosRequestConfig) =>
    axios.get(url, { ...options, ...axiosOptions }),
  post: (url: string, body?: any) =>
    axios.post(url, body, axiosOptions).then(responseBody),
  put: (url: string, body: any) =>
    axios.put(url, body, axiosOptions).then(responseBody),
  patch: (url: string, body: any) =>
    axios.patch(url, body, axiosOptions).then(responseBody),
  delete: (url: string, body?: any) =>
    axios.delete(url, { ...axiosOptions, data: body }).then(responseBody)
};

const Agency = {
  Client: {
    list: () => requests.get("/gsts")
  }
};

export type LogoutType = "allOther" | "session" | "current";
type LogoutBody = { type: LogoutType; sessionId?: string };

const Auth = {
  //Login
  login: (username: string, password: string) =>
    requests.post("/auth/login", { username, password }),
  //Signup
  signup: (
    name: string,
    email: string,
    password: string,
    mobileNumber: string,
    invited: boolean
  ) =>
    requests.post("/auth/signup", {
      name,
      email,
      password,
      mobileNumber,
      invited
    }),
  verifySignupOTP: (userId: string, otp: number) =>
    requests.post("/auth/verify/signup/otp", { userId, otp }),
  resendOTP: (userId: string) => requests.post("/auth/resendotp", { userId }),
  verifySignupToken: (token: string) =>
    requests.post("/auth/verify/signup/token", { token }),
  //Forgot Password
  forgotPassword: (email: string) =>
    requests.post("/auth/forgotPassword", { email }),
  verifyResetOtp: (userId: string, otp: number, password: string) =>
    requests.post("/auth/verify/reset/otp", { userId, otp, password }),
  verifyResetToken: (token: string, password: string) =>
    requests.post("/auth/verify/reset/token", { token, password }),
  verifyRecaptcha: (recaptchaToken: string) =>
    requests.post("/auth/verify/recaptcha", { recaptchaToken }),
  // For checking admin
  checkAdmin: (userEmail: string) =>
    requests.get(`/auth/checkadmin?email=${userEmail}`),
  logout: (logoutBody: LogoutBody) =>
    requests.delete("/auth/logout", logoutBody),
  changePassword: (
    oldPassword: string,
    newPassword: string,
    logoutFromAllOtherDevices: boolean
  ) =>
    requests.patch("/auth/changePassword", {
      oldPassword,
      newPassword,
      logoutFromAllOtherDevices
    }),
  sessions: () => requests.get("/auth/sessions")
};

const Firm = {
  getFirms: () => requests.get("/workspace/list"),
  addFirm: (name: string) => requests.post("/workspace/add", { name }),
  editFirm: (workSpaceId: string, name: string) =>
    requests.patch("/workspace/updatename", { workSpaceId, name }),
  makeFirmInactive: (workSpaceId: string) =>
    requests.patch("/workspace/inactive", { workSpaceId }),
  makeFirmActive: (workSpaceId: string) =>
    requests.patch("/workspace/active", { workSpaceId }),
  listofInvitationSent: (workSpaceId: string) =>
    requests.get(`/workspace/listofinvitationsent?workSpaceId=${workSpaceId}`),
  listofInvitationReceived: () =>
    requests.get(`/workspace/listofinvitationreceived`),
  leaveFirm: (workSpaceId: string) =>
    requests.post("/workspace/leaveworkspace", { workSpaceId }),
  revokeInvitation: (workSpaceId: string, invitationId: string) =>
    requests.post("/workspace/revokeinvitation", { workSpaceId, invitationId }),
  rejectInvitation: (workSpaceId: string, invitationId: string) =>
    requests.post("/workspace/rejecttinvitation", {
      workSpaceId,
      invitationId
    }),
  acceptInvitation: (workSpaceId: string, invitationId: string) =>
    requests.post("/workspace/acceptinvitation", { workSpaceId, invitationId }),
  addUser: (workSpaceId: string, userEmail: string) =>
    requests.post("/workspace/adduser", { workSpaceId, userEmail }),
  deleteFirm: (workSpaceId: string) =>
    requests.delete(`/workspace/delete?workSpaceId=${workSpaceId}`, {})
};

const Tag = {
  getTagList: (
    workSpaceId: string,
    active: boolean,
    searchText: string,
    limit: number,
    skip: number
  ) =>
    requests.get(
      `/tag/list?workSpaceId=${workSpaceId}&active=${active}&skip=${skip}&limit=${limit}&searchText=${encodeURIComponent(
        searchText
      )}`
    ),
  addTag: (name: string, description: string, workSpaceId: string) =>
    requests.post("/tag/add", { name, description, workSpaceId }),
  editTag: (
    name: string,
    description: string,
    tagId: string,
    workSpaceId: string
  ) => requests.put("/tag/edittag", { name, description, tagId, workSpaceId }),
  makeTagInactive: (id: string, workSpaceId: string) =>
    requests.put("/tag/inactive", { id, workSpaceId }),
  makeTagActive: (id: string, workSpaceId: string) =>
    requests.put("/tag/active", { id, workSpaceId }),
  deleteTag: (id: string, workSpaceId: string) =>
    requests.delete("/tag/delete", { id, workSpaceId })
};

const Status = {
  getStatusList: (
    workSpaceId: string,
    active: boolean,
    searchText: string,
    limit: number,
    skip: number
  ) =>
    requests.get(
      `/status/list?workSpaceId=${workSpaceId}&active=${active}&skip=${skip}&limit=${limit}&searchText=${encodeURIComponent(
        searchText
      )}`
    ),
  addStatus: (
    name: string,
    color: string,
    description: string,
    workSpaceId: string,
    tasks: string[]
  ) =>
    requests.post("/status/add", {
      name,
      color,
      description,
      workSpaceId,
      tasks
    }),
  editStatus: (
    statusId: string,
    name: string,
    color: string,
    description: string,
    workSpaceId: string,
    tasks: string[]
  ) =>
    requests.put("/status/edit", {
      statusId,
      name,
      color,
      description,
      workSpaceId,
      tasks
    }),
  makeStatusInactive: (id: string, workSpaceId: string) =>
    requests.put("/status/inactive", { id, workSpaceId }),
  makeStatusActive: (id: string, workSpaceId: string) =>
    requests.put("/status/active", { id, workSpaceId }),
  statusTaskList: (workSpaceId: string) =>
    requests.get(`/status/listoftaskforstatus?workSpaceId=${workSpaceId}`),
  deleteStatus: (id: string, workSpaceId: string) =>
    requests.delete("/status/delete", { id, workSpaceId })
};

const User = {
  addFirebaseToken: (
    token: string,
    type: string,
    browser: string,
    device: string
  ) => requests.post("/user/addpushtoken", { token, type, browser, device }),
  getSelfDetails: (workSpaceId: string) =>
    requests.get(`/user/getselfdetailsforworkspace?workSpaceId=${workSpaceId}`),
  getUserList: (
    workSpaceId: string,
    active: boolean,
    searchText: string,
    includeCurrent: boolean
  ) =>
    requests.get(
      `/user/list?workSpaceId=${workSpaceId}&active=${active}&searchText=${encodeURIComponent(
        searchText
      )}&includeCurrent=${includeCurrent}`
    ),
  addUser: (
    workSpaceId: string,
    email: string,
    role: string,
    userRightsList: any
  ) =>
    requests.post("workspace/adduser", {
      workSpaceId,
      email,
      role,
      userRightsList
    }),

  editUser: (
    workSpaceId: string,
    email: string,
    role: string,
    userRightsList: any
  ) =>
    requests.post("workspace/edituser", {
      workSpaceId,
      email,
      role,
      userRightsList
    }),
  updateUserName: (name: string) =>
    requests.patch("/user/changename", { name }),
  updateUserShortName: (shortName: string) =>
    requests.patch("/user/changeshortname", { shortName }),
  getUserRights: (workSpaceId: string, userId?: string) =>
    requests.get(
      `/user/rights?workSpaceId=${workSpaceId}${
        userId ? `&userId=${userId}` : ""
      }`
    ),
  makeUserInactive: (workSpaceId: string, userId: string) =>
    requests.patch("/user/inactive", { workSpaceId, userId }),
  makeUserActive: (workSpaceId: string, userId: string) =>
    requests.patch("/user/active", { workSpaceId, userId }),
  removeUser: (workSpaceId: string, userId: string) =>
    requests.post("/workspace/removeuser", { workSpaceId, userId })
};

const ContactPerson = {
  getPersonList: (
    workSpaceId: string,
    active: boolean,
    searchText: string,
    limit: number,
    skip: number
  ) =>
    requests.get(
      `/contactperson/list?workSpaceId=${workSpaceId}&active=${active}&skip=${skip}&limit=${limit}&searchText=${encodeURIComponent(
        searchText
      )}`
    ),
  getDscList: (
    workSpaceId: string,
    active: boolean,
    skip: number,
    limit: number,
    searchText: string,
    expiryDate: DscExpiryDateOptionItem,
    password: DscPasswordOptionItem,
    download: boolean
  ) => {
    const URL = `/contactperson/dsclist?workSpaceId=${workSpaceId}&active=${active}&skip=${skip}&limit=${limit}&searchText=${encodeURIComponent(
      searchText
    )}&expiryDate=${expiryDate}&password=${password}&download=${download}`;
    return download
      ? requests.getBlob(URL, { responseType: "blob" })
      : requests.get(URL);
  },

  addPerson: (
    workSpaceId: string,
    name: string,
    email: any,
    mobile: any,
    dscPassword: string,
    addressLine1: string,
    addressLine2: string,
    pincode: string,
    city: string,
    state: string,
    customFields: any,
    dscExpiryDate: string,
    dateOfBirth: string,
    dateOfAnniversary: string,
    pan: string,
    din: string,
    allowDuplicates: boolean
  ) =>
    requests.post("/contactperson/add", {
      workSpaceId,
      name,
      email,
      mobile,
      dscPassword,
      addressLine1,
      addressLine2,
      pincode,
      city,
      state,
      customFields,
      dscExpiryDate,
      dateOfBirth,
      dateOfAnniversary,
      pan,
      din,
      allowDuplicates
    }),
  editPerson: (
    contactPersonId: string,
    workSpaceId: string,
    name: string,
    email: any,
    mobile: any,
    dscPassword: string,
    addressLine1: string,
    addressLine2: string,
    pincode: string,
    city: string,
    state: string,
    customFields: any,
    dscExpiryDate: string,
    dateOfBirth: string,
    dateOfAnniversary: string,
    pan: string,
    din: string,
    allowDuplicates: boolean,
    bulkEdit?: boolean,
    bulkEditField?: "name" | "email" | "mobile" | ""
  ) =>
    requests.put("/contactperson/edit", {
      contactPersonId,
      workSpaceId,
      name,
      email,
      mobile,
      dscPassword,
      addressLine1,
      addressLine2,
      pincode,
      city,
      state,
      customFields,
      dscExpiryDate,
      dateOfBirth,
      dateOfAnniversary,
      pan,
      din,
      allowDuplicates,
      bulkEdit,
      bulkEditField
    }),
  getPersonById: (workSpaceId: string, contactPersonId: string) =>
    requests.get(
      `/contactperson/id?contactPersonId=${contactPersonId}&workSpaceId=${workSpaceId}`
    ),
  makePersonActive: (contactPersonId: string, workSpaceId: string) =>
    requests.put("/contactperson/active", { contactPersonId, workSpaceId }),
  makePersonInactive: (contactPersonId: string, workSpaceId: string) =>
    requests.put("/contactperson/inactive", { contactPersonId, workSpaceId }),
  deletePersonField: (contactPersonId: string, workSpaceId: string) =>
    requests.delete(
      `/contactperson/delete?contactPersonId=${contactPersonId}&workSpaceId=${workSpaceId}`,
      {}
    ),
  getPinCodeData: (pincode: string) => requests.get(`/pincode/${pincode}`),

  getBulkUploadSheet: (workSpaceId: string) =>
    requests.getBlob(
      `/bulkupload/contactPerson/sheet?workSpaceId=${workSpaceId}`,
      { responseType: "blob" }
    ),
  validateUpload: (body: any) =>
    requests.post(`/bulkupload/contactPerson/validateSheet`, body),
  updateRowField: (
    workSpaceId: string,
    requestId: string,
    entryId: string,
    columnToUpdate: string,
    value: string
  ) =>
    requests.post(`/bulkupload/contactPerson/fieldUpdate`, {
      workSpaceId,
      requestId,
      entryId,
      columnToUpdate,
      value
    }),
  getColumnErrors: (workSpaceId: string, requestId: string, column: string) =>
    requests.get(
      `/bulkupload/contactPerson/columnError?workSpaceId=${workSpaceId}&requestId=${requestId}&column=${column}`
    ),
  getErrorReport: (workSpaceId: string, requestId: string) =>
    requests.getBlob(
      `/bulkupload/contactPerson/errorReport?workSpaceId=${workSpaceId}&requestId=${requestId}`,
      { responseType: "blob" }
    ),
  getAllBulkUploadRequests: (workSpaceId: string) =>
    requests.get(
      `/bulkupload/contactPerson/requests?workSpaceId=${workSpaceId}`
    ),
  getOnebulkUploadRequest: (workSpaceId: string, requestId: string) =>
    requests.get(
      `/bulkupload/contactPerson/request?workSpaceId=${workSpaceId}&requestId=${requestId}`
    ),
  getErrorColumns: (workSpaceId: string, requestId: string) =>
    requests.get(
      `/bulkupload/contactPerson/errColumns?workSpaceId=${workSpaceId}&requestId=${requestId}`
    ),
  startImport: (workSpaceId: string, requestId: string) =>
    requests.post(`/bulkupload/contactPerson/changeReqStatus`, {
      workSpaceId,
      requestId
    })
};

const CustomField = {
  getCustomFieldList: (
    workSpaceId: string,
    active: boolean,
    searchText: string,
    limit: number,
    skip: number
  ) =>
    requests.get(
      `customfields/list?workSpaceId=${workSpaceId}&active=${active}&limit=${limit}&skip=${skip}&searchText=${encodeURIComponent(
        searchText
      )}`
    ),
  addCustomField: (
    name: string,
    description: string,
    type: string,
    applicableFor: string,
    workSpaceId: string
  ) =>
    requests.post("/customfields/add", {
      name,
      description,
      type,
      applicableFor,
      workSpaceId
    }),
  editCustomField: (
    customFieldId: string,
    name: string,
    description: string,
    type: string,
    applicableFor: string,
    workSpaceId: string
  ) =>
    requests.put("/customfields/edit", {
      customFieldId,
      name,
      description,
      type,
      applicableFor,
      workSpaceId
    }),
  makeCustomFieldInactive: (customFieldId: string, workSpaceId: string) =>
    requests.put("/customfields/inactive", { customFieldId, workSpaceId }),
  makeCustomFieldActive: (customFieldId: string, workSpaceId: string) =>
    requests.put("/customfields/active", { customFieldId, workSpaceId }),
  deleteCustomField: (
    customFieldId: string,
    workSpaceId: string,
    deleteAssociated = false
  ) =>
    requests.delete("/customfields/delete", {
      customFieldId,
      workSpaceId,
      deleteAssociated
    })
};

const ClientGroups = {
  getClientGroupList: (
    workSpaceId: string,
    active: boolean,
    searchText: string,
    skip: number,
    limit: number
  ) =>
    requests.get(
      `/group/list?workSpaceId=${workSpaceId}&active=${active}&searchText=${encodeURIComponent(
        searchText
      )}&skip=${skip}&limit=${limit}`
    ),
  addClientGroup: (name: string, description: string, workSpaceId: string) =>
    requests.post("/group/add", { name, description, workSpaceId }),
  editClientGroup: (
    name: string,
    description: string,
    id: string,
    workSpaceId: string
  ) => requests.post("/group/edit", { id, name, description, workSpaceId }),
  makeClientGroupInactive: (workSpaceId: string, groupId: string) =>
    requests.post("/group/inactive", { workSpaceId, groupId }),
  makeClientGroupActive: (workSpaceId: string, groupId: string) =>
    requests.post("/group/active", { workSpaceId, groupId }),
  deleteClientGroup: (workSpaceId: string, groupId: string) =>
    requests.delete("/group/delete", { workSpaceId, groupId }),
  getParticularGroup: (groupId: string) => requests.get(`/group/${groupId}`),
  getOneGroup: (groupId: string, workSpaceId: string) =>
    requests.get(`/group/id?groupId=${groupId}&workSpaceId=${workSpaceId}`)
};

const Clients = {
  getClientList: (
    workSpaceId: string,
    skip: number,
    limit: number,
    searchText: string,
    active: boolean,
    filters?: any,
    download?: boolean
  ) => {
    const query = JSON.stringify({
      skip,
      limit,
      searchText: encodeURIComponent(searchText),
      active,
      download,
      filters
    });
    return download
      ? requests.getBlob(
          `/client/list?workSpaceId=${workSpaceId}&query=${query}`,
          { responseType: "blob" }
        )
      : requests.get(`/client/list?workSpaceId=${workSpaceId}&query=${query}`);
  },
  getOneClientById: (workSpaceId: string, clientId: string) =>
    requests.get(
      `/client/getClient?workSpaceId=${workSpaceId}&clientId=${clientId}`
    ),
  getQRMPDetails: (workSpaceId: string, gstin: string) =>
    requests.post("/qrmp/qrmp", { workSpaceId, gstin }),
  addClient: (workSpaceId: string, data: any) =>
    requests.post("/client/add", { workSpaceId, ...data }),
  editClient: (workSpaceId: string, data: any, clientId: string) =>
    requests.post("/client/edit", { workSpaceId, clientId, ...data }),
  makeClientInactive: (workSpaceId: string, clientId: string) =>
    requests.post("/client/inactive", { workSpaceId, clientId }),
  makeClientActive: (workSpaceId: string, clientId: string) =>
    requests.post("/client/active", { workSpaceId, clientId }),
  deleteClient: (workSpaceId: string, clientId: string) =>
    requests.post("/client/delete", { workSpaceId, clientId }),
  getBulkUploadSheet: (workSpaceId: string) =>
    requests.getBlob(`/bulkupload/client/sheet?workSpaceId=${workSpaceId}`, {
      responseType: "blob"
    }),
  getAllBulkUploadRequests: (workSpaceId: string) =>
    requests.get(`/bulkupload/client/requests?workSpaceId=${workSpaceId}`),
  getOnebulkUploadRequest: (workSpaceId: string, requestId: string) =>
    requests.get(
      `/bulkupload/client/request?workSpaceId=${workSpaceId}&requestId=${requestId}`
    ),
  deleteImportRequest: (workSpaceId: string, requestId: string) =>
    requests.put(`/bulkupload/client/deleteReqStatus`, {
      workSpaceId,
      requestId
    }),
  validateUpload: (body: any) =>
    requests.post(`/bulkupload/client/validateSheet`, body),
  getErrorColumns: (workSpaceId: string, requestId: string) =>
    requests.get(
      `/bulkupload/client/errColumns?workSpaceId=${workSpaceId}&requestId=${requestId}`
    ),
  getColumnErrors: (workSpaceId: string, requestId: string, column: string) =>
    requests.get(
      `/bulkupload/client/columnError?workSpaceId=${workSpaceId}&requestId=${requestId}&column=${column}`
    ),
  updateRowField: (
    workSpaceId: string,
    requestId: string,
    entryId: string,
    columnToUpdate: string,
    value: string
  ) =>
    requests.post(`/bulkupload/client/fieldUpdate`, {
      workSpaceId,
      requestId,
      entryId,
      columnToUpdate,
      value
    }),
  startImport: (workSpaceId: string, requestId: string) =>
    requests.post(`/bulkupload/client/changeReqStatus`, {
      workSpaceId,
      requestId
    }),
  getErrorReport: (workSpaceId: string, requestId: string) =>
    requests.getBlob(
      `/bulkupload/client/errorReport?workSpaceId=${workSpaceId}&requestId=${requestId}`,
      { responseType: "blob" }
    ),
  getGSTdetails: (workSpaceId: string, gstin: string) =>
    requests.get(`/gst?workSpaceId=${workSpaceId}&gstin=${gstin}`),
  generateExportSheet: (workSpaceId: string) =>
    requests.getBlob(`/client/exportSheet?workSpaceId=${workSpaceId}`, {
      responseType: "blob"
    })
};

const Tasks = {
  getTaskList: (
    workSpaceId: string,
    limit: number,
    skip: number,
    searchText: string,
    filters?: any,
    download?: boolean
  ) => {
    const query = JSON.stringify({
      limit,
      skip,
      searchText: encodeURIComponent(searchText),
      download,
      filters
    });
    return download
      ? requests.getBlob(
          `/task/list?workSpaceId=${workSpaceId}&query=${query}`,
          {
            responseType: "blob"
          }
        )
      : requests.get(`/task/list?workSpaceId=${workSpaceId}&query=${query}`);
  },
  getTaskNamesList: (workSpaceId: string) =>
    requests.get(`/task/getalltasksforsearch?workSpaceId=${workSpaceId}`),
  taskTypeList: (workSpaceId: string, type?: string) =>
    requests.get(
      `/tasktypes/list?workSpaceId=${workSpaceId}&type=${type ? type : ""}`
    ),
  listOtherTask: (workSpaceId: string) =>
    requests.get(`/task/listOtherTasks?workSpaceId=${workSpaceId}`),
  gstClientList: (
    workSpaceId: string,
    limit: number,
    skip: number,
    searchText: string,
    period: string,
    task: string,
    filters: any
  ) =>
    requests.post("/task/getGstClients", {
      workSpaceId,
      limit,
      skip,
      searchText,
      period,
      task,
      filters
    }),
  itClientList: (
    workSpaceId: string,
    limit: number,
    skip: number,
    searchText: string,
    period: string,
    task: string,
    filters: any
  ) =>
    requests.post("/task/getItClients", {
      workSpaceId,
      limit,
      skip,
      searchText,
      period,
      task,
      filters
    }),
  otherClientList: (workSpaceId: string, otherTaskList: any) =>
    requests.post("/task/listClientsForOtherTask", {
      workSpaceId,
      otherTaskList
    }),
  createGstTask: (workSpaceId: string, data: any) =>
    requests.post("/task/createGstTask", { workSpaceId, ...data }),
  createItTask: (workSpaceId: string, data: any) =>
    requests.post("/task/createIncomeTaxTask", { workSpaceId, ...data }),
  createOtherTask: (workSpaceId: string, data: any) =>
    requests.post("/task/createOtherTask", { workSpaceId, ...data }),
  addOtherTask: (workSpaceId: string, data: any) =>
    requests.post("/task/addOtherTask", { workSpaceId, ...data }),
  editOtherTask: (workSpaceId: string, id: string, data: any) =>
    requests.post("/task/editOtherTask", { id, workSpaceId, ...data }),
  deleteOtherTask: (workSpaceId: string, otherTaskId: string) =>
    requests.delete(`/task/deleteOtherTask`, { workSpaceId, otherTaskId }),
  changeTaskStar: (workSpaceId: string, taskIds: any, star: boolean) =>
    requests.post("/task/changeStar", { workSpaceId, taskIds, star }),
  changeDueDate: (workSpaceId: string, taskIds: any, dueDate: string) =>
    requests.post("/task/changeDueDate", { workSpaceId, taskIds, dueDate }),
  changeStatus: (workSpaceId: string, taskIds: any, statusId: string) =>
    requests.post("/task/changeStatus", { workSpaceId, taskIds, statusId }),
  changeCurrentUser: (
    workSpaceId: string,
    taskIds: any,
    currentUserId: string,
    actions: any
  ) =>
    requests.post("/task/changeCurrentUser", {
      workSpaceId,
      taskIds,
      currentUserId,
      ...actions
    }),
  updateTaskUsers: (
    workSpaceId: string,
    taskIds: any,
    userIds: any,
    actions: any
  ) =>
    requests.post("/task/updateTaskUsers", {
      workSpaceId,
      taskIds,
      userIds,
      ...actions
    }),
  getTaskDueDate: (workSpaceId: string, taskName: string, period: string) =>
    requests.get(
      `/tasktypes/duedate?workSpaceId=${workSpaceId}&taskName=${taskName}&period=${period}`
    ),
  deleteTask: (workSpaceId: string, taskIds: any) =>
    requests.delete("/task/deleteTasks", { workSpaceId, taskIds }),
  getTaskfromTaskId: (workSpaceId: string, taskId: string) =>
    requests.get(
      `/task/taskfromtaskid?workSpaceId=${workSpaceId}&taskId=${taskId}`
    ),
  getTaskComment: (
    workSpaceId: string,
    taskId: string,
    limit: number,
    skip: number
  ) =>
    requests.get(
      `/task/getComments?workSpaceId=${workSpaceId}&taskId=${taskId}&limit=${limit}&skip=${skip}`
    ),
  changeDescription: (workSpaceId: string, taskIds: any, description: any) =>
    requests.post("/task/changeDescription", {
      workSpaceId,
      taskIds,
      description
    }),
  addComment: (workSpaceId: string, taskId: string, comment: any) =>
    requests.post("/task/createComment", { workSpaceId, taskId, comment }),
  editComment: (workSpaceId: string, commentId: string, updatedComment: any) =>
    requests.put("/task/editComment", {
      workSpaceId,
      commentId,
      updatedComment
    }),
  deleteComment: (workSpaceId: string, commentId: string) =>
    requests.delete("/task/deleteComment", { workSpaceId, commentId })
};

const ReturnStatus = {
  getGstReturnAndQrmpDetails: (
    gstin: string,
    financialYear: string,
    update?: "returnDetails" | "gstDetails"
  ) =>
    requests.get(
      `/returnstatus?gstin=${gstin}&financialYear=${financialYear}&updateReturnStatus=${
        update === "returnDetails" ? "true" : "false"
      }&updateGstDetails=${update === "gstDetails" ? "true" : "false"}`
    )
};

const Gst = {
  addGst: (gstin: string) => requests.post("/gsts", { gstin }),
  getAll: () => requests.get("/gsts"),
  getGst: (gstin: string) => requests.get(`/gsts/${gstin}`),
  changeName: (body: { id: string; name: string }) =>
    requests.put("/gsts/changeName", body)
};

const Sales = {
  import: (file: any) => requests.post("/sales/import", file),
  startImport: (importRequest: string) =>
    requests.post("/sales/import/start", { importRequest }),
  getImportRequests: (gstId: string, year: string, month: string) =>
    requests.get(
      `/sales/import/requests?gstId=${gstId}&year=${year}&month=${month}`
    ),
  deleteImportData: (importRequest: string) =>
    requests.delete(`/sales/import/deleteFile?importRequest=${importRequest}`),
  getSummary: (month: string, year: string, gstId: string) =>
    requests.get(`/sales/summary?month=${month}&year=${year}&gstId=${gstId}`),
  deleteTransaction: (id: string) => requests.delete(`/sales/${id}`),
  getTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string
  ) =>
    requests.get(
      `/sales/gst?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&transactionType=${encodeURI(
        "Sales"
      )}`
    ),
  getSpecificTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string,
    platform: string
  ) =>
    requests.get(
      `/sales/gst?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&platform=${platform}&transactionType=${encodeURI(
        "Sales"
      )}`
    ),
  getReturnTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string
  ) =>
    requests.get(
      `/sales/gst?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&transactionType=${encodeURI(
        "Sales Return"
      )}`
    ),
  getSpecificReturnTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string,
    platform: string
  ) =>
    requests.get(
      `/sales/gst?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&platform=${platform}&transactionType=${encodeURI(
        "Sales Return"
      )}`
    ),
  exportSalesTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string,
    transactionType: string
  ) =>
    requests.getBlob(
      `/sales/gst/downloadTransaction?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&transactionType=${transactionType}
      `,
      {
        responseType: "blob"
      }
    ),
  exportSpecificSalesTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string,
    platform: string,
    transactionType: string
  ) =>
    requests.getBlob(
      `/sales/gst/downloadTransaction?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&transactionType=${transactionType}&platform=${platform}
    `,
      {
        responseType: "blob"
      }
    )
};

const Purchase = {
  import: (file: any) => requests.post("/purchase/import", file),
  startImport: (importRequest: string) =>
    requests.post("/purchase/import/start", { importRequest }),
  getImportRequests: (gstId: string, year: string, month: string) =>
    requests.get(
      `/purchase/import/requests?gstId=${gstId}&year=${year}&month=${month}`
    ),
  deleteImportData: (importRequest: string) =>
    requests.delete(
      `/purchase/import/deleteFile?importRequest=${importRequest}`
    ),
  getSummary: (month: string, year: string, gstId: string) =>
    requests.get(
      `/purchase/summary?month=${month}&year=${year}&gstId=${gstId}`
    ),
  getTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string
  ) =>
    requests.get(
      `/purchase/gst?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&transactionType=Purchase`
    ),
  getSpecificTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string,
    platform: string
  ) =>
    requests.get(
      `/purchase/gst?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&transactionType=Purchase&platform=${platform}`
    ),
  deleteTransaction: (id: string) => requests.delete(`/purchase/${id}`),
  getReturnTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string
  ) =>
    requests.get(
      `/purchase/gst?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&transactionType=${encodeURI(
        "Purchase Return"
      )}`
    ),
  getSpecificReturnTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string,
    platform: string
  ) =>
    requests.get(
      `/purchase/gst?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&transactionType=${encodeURI(
        "Purchase Return"
      )}&platform=${platform}`
    ),
  exportPurchaseTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string,
    transactionType: string
  ) =>
    requests.getBlob(
      `/purchase/gst/downloadTransaction?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&transactionType=${transactionType}
    `,
      {
        responseType: "blob"
      }
    ),
  exportSpecificPurchaseTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string,
    platform: string,
    transactionType: string
  ) =>
    requests.getBlob(
      `/purchase/gst/downloadTransaction?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&transactionType=${transactionType}&platform=${platform}
  `,
      {
        responseType: "blob"
      }
    )
};

const Returns = {
  iffData: (month: string, year: string, gstId: string) =>
    requests.get(`/sales/iff?month=${month}&year=${year}&gstId=${gstId}`),
  iffJson: (month: string, year: string, gstId: string) =>
    requests.get(`/sales/iffJson?month=${month}&year=${year}&gstId=${gstId}`),
  gstr1Data: (month: string, year: string, gstId: string) =>
    requests.get(`/sales/gstr1?month=${month}&year=${year}&gstId=${gstId}`),
  gstr1Json: (month: string, year: string, gstId: string) =>
    requests.get(`/sales/gstr1Json?month=${month}&year=${year}&gstId=${gstId}`),
  gstr3bData: (month: string, year: string, gstId: string) =>
    requests.get(`/sales/gstr3b?month=${month}&year=${year}&gstId=${gstId}`),
  gstr3bJson: (month: string, year: string, gstId: string) =>
    requests.get(
      `/sales/gstr3bjson?month=${month}&year=${year}&gstId=${gstId}`
    ),
  gstr2import: (file: any) => requests.post("/gstr2/import", file),
  startGstr2Import: (importRequest: string) =>
    requests.post("/gstr2/import/start", { importRequest }),
  getGstr2ImportRequests: (gstId: string, year: string, month: string) =>
    requests.get(
      `gstr2/import/requests?gstId=${gstId}&year=${year}&month=${month}`
    ),
  gstr2distinctTypes: (month: string, year: string, gstId: string) =>
    requests.get(
      `gstr2/distinctTypes?month=${month}&year=${year}&gstId=${gstId}`
    ),
  gstr2Data: (
    month: string,
    year: string,
    gstId: string,
    skip: string,
    limit: string,
    platform: string,
    type: string
  ) =>
    requests.get(
      `gstr2/transactions?month=${month}&year=${year}&gstId=${gstId}&skip=${skip}&limit=${limit}&platform=${platform}&type=${type}`
    ),
  getGstr2Summary: (month: string, year: string, gstId: string) =>
    requests.get(`/gstr2/summary?month=${month}&year=${year}&gstId=${gstId}`),
  bulkCopy: (
    month: string,
    year: string,
    gstId: string,
    platforms: [],
    types: []
  ) =>
    requests.put(
      `/gstr2/bulkCopyTransaction?gstId=${gstId}&month=${month}&year=${year}`,
      { gstId, month, year, platforms, types }
    ),
  copyToPurchase: (id: string, copied: boolean) =>
    requests.put(`gstr2/${id}/copied`, { copied }),
  itcClaimCount: (gstId: string, month: string, year: string) =>
    requests.get(
      `/purchase/getNoOfTransactions?gstId=${gstId}&month=${month}&year=${year}`
    ),
  get2APurchases: (
    gstId: string,
    month: string,
    year: string,
    transactionType: string,
    skip?: string,
    limit?: string
  ) =>
    requests.get(
      `/purchase/2aTransaction?gstId=${gstId}&month=${month}&year=${year}&transactionType=${transactionType}&skip=${skip}&limit=${limit}`
    ),
  get2BPurchases: (
    gstId: string,
    month: string,
    year: string,
    transactionType: string,
    skip?: string,
    limit?: string
  ) =>
    requests.get(
      `/purchase/2bTransaction?gstId=${gstId}&month=${month}&year=${year}&transactionType=${transactionType}&skip=${skip}&limit=${limit}`
    ),
  getUnclaimedPurchases: (
    gstId: string,
    month: string,
    year: string,
    transactionType: string,
    skip?: string,
    limit?: string
  ) =>
    requests.get(
      `/purchase/normalTransactions?gstId=${gstId}&month=${month}&year=${year}&transactionType=${transactionType}&skip=${skip}&limit=${limit}`
    ),
  claimItc: (
    purchaseId: string,
    claim: boolean,
    month: string,
    year: string,
    removeItcFields: boolean
  ) =>
    requests.put(`/purchase/${purchaseId}/claimItc`, {
      claim,
      month,
      year,
      removeItcFields
    })
};

// Requests for todos

const Todo = {
  addList: (list: any) => requests.post("/todo/addtodolist", list),
  getAllTodoOfList: (
    workSpaceId: string,
    toDoListId: string,
    todoStatus: string,
    searchText: string
  ) =>
    requests.get(
      `/todo/todo?workSpaceId=${workSpaceId}&toDoListId=${toDoListId}&status=${todoStatus}&searchText=${encodeURIComponent(
        searchText
      )}`
    ),
  todoSpecificCases: (
    workSpaceId: string,
    type: string,
    todoStatus: string,
    searchText: string
  ) =>
    requests.get(
      `/todo/todospecificcases?workSpaceId=${workSpaceId}&status=${todoStatus}&type=${type}&searchText=${encodeURIComponent(
        searchText
      )}`
    ),
  count: (workSpaceId: string) =>
    requests.get(`/todo/counttodospecificcases?workSpaceId=${workSpaceId}`),
  getAllTodoListOfUser: (workSpaceId: string) =>
    requests.get(`/todo/todolist?workSpaceId=${workSpaceId}`),
  create: (todo: any) => requests.post("/todo/addtodo", todo),
  markAsComplete: (todo: any) => requests.post(`/todo/marktodocompleted`, todo),
  markPending: (todo: any) => requests.post(`/todo/marktodopending`, todo),
  edit: (todo: any) => requests.post("/todo/edittodo", todo),
  changeTodoList: (
    workSpaceId: string,
    newToDoListId: string,
    toDoId: string
  ) =>
    requests.patch(`/todo/changetodolist`, {
      workSpaceId,
      newToDoListId,
      toDoId
    }),
  delete: (todo: any) => requests.post(`/todo/deletetodo`, todo),
  addUserToTodoList: (user: any) =>
    requests.post(`/todo/addusertodolist`, user),
  todoListRename: (list: any) =>
    requests.post(`/todo/todolistchangename`, list),
  deleteList: (workSpaceId: string, toDoListId: string) =>
    requests.post(`/todo/deletetodolist`, { workSpaceId, toDoListId }),
  reorder: (workSpaceId: string, toDoId: string, order: number) =>
    requests.post(`/todo/reordertodo`, {
      workSpaceId,
      toDoId,
      order
    })
};

// Requests for Receipts and Payments

const Receipt = {
  getSourceList: (
    workSpaceId: string,
    status: boolean,
    searchText: string,
    skip: number,
    limit: number
  ) =>
    requests.get(
      `/receiptpayment/listsource?workSpaceId=${workSpaceId}&active=${status}&searchText=${encodeURIComponent(
        searchText
      )}&skip=${skip}&limit=${limit}`
    ),
  addSource: (name: string, description: string, workSpaceId: string) =>
    requests.post("/receiptpayment/addsource", {
      name,
      description,
      workSpaceId
    }),
  editSource: (
    sourceId: string,
    name: string,
    description: string,
    workSpaceId: string
  ) =>
    requests.post("/receiptpayment/editsource", {
      sourceId,
      name,
      description,
      workSpaceId
    }),
  makeSourceActive: (sourceId: string, workSpaceId: string) =>
    requests.post("/receiptpayment/activesource", { sourceId, workSpaceId }),
  makeSourceInActive: (sourceId: string, workSpaceId: string) =>
    requests.post("/receiptpayment/inactivesource", { sourceId, workSpaceId }),
  getCategoryList: (
    workSpaceId: string,
    status: boolean,
    searchText: string,
    skip: number,
    limit: number
  ) =>
    requests.get(
      `/receiptpayment/listcategory?workSpaceId=${workSpaceId}&active=${status}&searchText=${encodeURIComponent(
        searchText
      )}&skip=${skip}&limit=${limit}`
    ),
  addCategory: (name: string, description: string, workSpaceId: string) =>
    requests.post("/receiptpayment/addcategory", {
      name,
      description,
      workSpaceId
    }),
  editCategory: (
    categoryId: string,
    name: string,
    description: string,
    workSpaceId: string
  ) =>
    requests.post("/receiptpayment/editcategory", {
      categoryId,
      name,
      description,
      workSpaceId
    }),
  makeCategoryActive: (categoryId: string, workSpaceId: string) =>
    requests.post("/receiptpayment/activecategory", {
      categoryId,
      workSpaceId
    }),
  makeCategoryInActive: (categoryId: string, workSpaceId: string) =>
    requests.post("/receiptpayment/inactivecategory", {
      categoryId,
      workSpaceId
    }),
  getReceiptList: (
    workSpaceId: string,
    skip: number,
    limit: number,
    dateFrom: string,
    dateTo: string,
    type: string,
    notes: string,
    amountFrom: string,
    amountTo: string,
    clientId: string,
    sourceId: string,
    categoryId: string,
    download: boolean
  ) => {
    const url = `/receiptpayment/listreceiptpayment?workSpaceId=${workSpaceId}&skip=${skip}&limit=${limit}&dateFrom=${dateFrom}&dateTo=${dateTo}&type=${type}&notes=${encodeURIComponent(
      notes
    )}&amountFrom=${amountFrom}&amountTo=${amountTo}${
      clientId !== "" ? "&clientId[]=" + clientId : ""
    }${sourceId !== "" ? "&sourceId[]=" + sourceId : ""}${
      categoryId !== "" ? "&categoryId[]=" + categoryId : ""
    }&download=${download}`;
    return download
      ? requests.getBlob(url, { responseType: "blob" })
      : requests.get(url);
  },
  addReceipt: (receipt: any) => requests.post("/receiptpayment/add", receipt),
  editReceipt: (receipt: any) => requests.post("/receiptpayment/edit", receipt),
  deleteReceipt: (workSpaceId: string, receiptId: string) =>
    requests.delete(
      `/receiptpayment/deleteentry?workSpaceId=${workSpaceId}&entryId=${receiptId}`
    ),
  pendingBalanceReport: (workSpaceId: string, download: boolean) => {
    const url = `/receiptpayment/pedingbalancereport?workSpaceId=${workSpaceId}&download=${download}`;
    return download
      ? requests.getBlob(url, { responseType: "blob" })
      : requests.get(url);
  },
  clientWiseReport: (
    workSpaceId: string,
    clientId: string,
    skip: number,
    limit: number,
    download: boolean
  ) => {
    const url = `/receiptpayment/clientreport?workSpaceId=${workSpaceId}&clientId=${clientId}&skip=${skip}&limit=${limit}&download=${download}`;
    return download
      ? requests.getBlob(url, { responseType: "blob" })
      : requests.get(url);
  }
};

const RecurringTask = {
  getTaskTypes: (workSpaceId: string, type: string, applicableFor: string) =>
    requests.get(
      `/tasktypes/list?workSpaceId=${workSpaceId}&type=${type}&applicableFor=${applicableFor}`
    ),
  addRecurringTask: (
    workSpaceId: string,
    tasktype: string,
    beforeAfter: string,
    days: number,
    active: boolean
  ) =>
    requests.post("/recurringtasks/add", {
      workSpaceId,
      tasktype,
      beforeAfter,
      days,
      active
    }),
  getRecurringTaskSettings: (workSpaceId: string, type: string) =>
    requests.get(
      `/recurringtasks/getrecurringtask?workSpaceId=${workSpaceId}&type=${type}`
    )
};

const Logs = {
  getTagLogs: (workSpaceId: string, tagId: string) => {
    return requests.get(
      `/logs/tagLogs?workSpaceId=${workSpaceId}&tagId=${tagId}`
    );
  },
  getClientLogs: (workSpaceId: string, clientId: string) => {
    return requests.get(
      `/logs/clientLogs?workSpaceId=${workSpaceId}&clientId=${clientId}`
    );
  },
  getStatusLogs: (workSpaceId: string, statusId: string) => {
    return requests.get(
      `/logs/statusLogs?workSpaceId=${workSpaceId}&statusId=${statusId}`
    );
  },
  getCustomFieldLogs: (workSpaceId: string, customFieldId: string) => {
    return requests.get(
      `/logs/customFieldLogs?workSpaceId=${workSpaceId}&customFieldId=${customFieldId}`
    );
  },
  getUserLogs: (
    workSpaceId: string,
    userId: string,
    startDate: string,
    endDate: string
  ) => {
    return requests.get(
      `/logs/userLogs?workSpaceId=${workSpaceId}&userId=${userId}&startDate=${startDate}&endDate=${endDate}`
    );
  },
  getGroupLogs: (workSpaceId: string, groupId: string) => {
    return requests.get(
      `/logs/groupLogs?workSpaceId=${workSpaceId}&groupId=${groupId}`
    );
  },
  getContactPersonLogs: (workSpaceId: string, contactPersonId: string) => {
    return requests.get(
      `/logs/contactPersonLogs?workSpaceId=${workSpaceId}&contactPersonId=${contactPersonId}`
    );
  },
  getTaskLogs: (workSpaceId: string, taskId: string) => {
    return requests.get(
      `/logs/taskLogs?workSpaceId=${workSpaceId}&taskId=${taskId}`
    );
  }
};

const Reports = {
  getTaskLogsReport: (
    workSpaceId: string,
    dateFrom: string,
    dateTo: string,
    skip: number,
    limit: number,
    download: boolean,
    type: ChangeType[],
    userIds: string[],
    clientIds: string[]
  ) => {
    const baseURL = `/logs/taskLogsReport?workSpaceId=${workSpaceId}&dateFrom=${dateFrom}&dateTo=${dateTo}&skip=${skip}&limit=${limit}&download=${download}`;

    const userIdParams =
      userIds.length > 0
        ? `&${createSearchParams({ "userIds[]": userIds })}`
        : "";
    const clientIdParams =
      clientIds.length > 0
        ? `&${createSearchParams({ "clientIds[]": clientIds })}`
        : "";
    const typeParams =
      type.length > 0 ? `&${createSearchParams({ "type[]": type })}` : "";

    const url = `${baseURL + typeParams + userIdParams + clientIdParams}`;

    return download
      ? requests.getBlob(url, { responseType: "blob" })
      : requests.get(url);
  },
  getSettingsLogsReport: (
    workSpaceId: string,
    dateFrom: string,
    dateTo: string,
    skip: number,
    limit: number,
    download: boolean,
    logType: LogType[],
    type: ChangeType[],
    userIds?: string[],
    clientIds?: string[]
  ) => {
    const baseURL = `/logs/settingsLogsReport?workSpaceId=${workSpaceId}&dateFrom=${dateFrom}&dateTo=${dateTo}&skip=${skip}&limit=${limit}&download=${download}`;

    const logTypeParams =
      logType.length > 0
        ? `&${createSearchParams({ "logType[]": logType })}`
        : "";

    const userIdParams =
      userIds && userIds.length > 0
        ? `&${createSearchParams({ "userIds[]": userIds })}`
        : "";
    const clientIdParams =
      clientIds && clientIds.length > 0
        ? `&${createSearchParams({ "clientIds[]": clientIds })}`
        : "";
    const typeParams =
      type.length > 0 ? `&${createSearchParams({ "type[]": type })}` : "";

    const url = `${
      baseURL + logTypeParams + typeParams + userIdParams + clientIdParams
    }`;

    return download
      ? requests.getBlob(url, { responseType: "blob" })
      : requests.get(url);
  }
};

const dashboard = {
  getDueDaysReport: (
    workSpaceId: string,
    taskType: string,
    user: string,
    userType: string,
    userRights: string
  ) => {
    return requests.get(
      `/dashboard/duedays?workSpaceId=${workSpaceId}&taskType=${taskType}&user=${user}&userType=${userType}&userRights=${userRights}`
    );
  },
  getTaskBasedOnDueDays: (
    workSpaceId: string,
    taskType: string,
    user: string,
    userType: string,
    userRights: string,
    dueType: string
  ) => {
    return requests.get(
      `/dashboard/gettaskonbasisofduedays?workSpaceId=${workSpaceId}&taskType=${taskType}&user=${user}&userType=${userType}&userRights=${userRights}&dueType=${dueType}`
    );
  },
  statuswiseReport: (
    workSpaceId: string,
    taskType: string,
    user: string,
    userType: string,
    userRights: string
  ) => {
    return requests.get(
      `/dashboard/statuswisereport?workSpaceId=${workSpaceId}&taskType=${taskType}&user=${user}&userType=${userType}&userRights=${userRights}`
    );
  },
  userwiseReport: (workSpaceId: string, taskType: string, userType: string) => {
    return requests.get(
      `/dashboard/userwisereport?workSpaceId=${workSpaceId}&taskType=${taskType}&userType=${userType}`
    );
  },
  statuswiseAndTaskwiseReport: (
    workSpaceId: string,
    taskType: string,
    user: string,
    userType: string,
    userRights: string,
    task: string,
    period: string
  ) => {
    return requests.get(
      `/dashboard/statuswiseandtaskwisereport?workSpaceId=${workSpaceId}&taskType=${taskType}&task=${task}&period=${period}&user=${user}&userType=${userType}&userRights=${userRights}`
    );
  },
  taskwiseAndPeriodwiseReport: (
    workSpaceId: string,
    taskType: string,
    user: string,
    userType: string,
    userRights: string
  ) => {
    return requests.get(
      `/dashboard/taskwiseperiodwisereport?workSpaceId=${workSpaceId}&taskType=${taskType}&user=${user}&userType=${userType}&userRights=${userRights}`
    );
  },
  statuswiseAndUserwiseReport: (
    workSpaceId: string,
    taskType: string,
    user: string,
    userType: string,
    userRights: string,
    task: string,
    period: string
  ) => {
    return requests.get(
      `/dashboard/statuswiseanduserwisereport?workSpaceId=${workSpaceId}&taskType=${taskType}&task=${task}&period=${period}&user=${user}&userType=${userType}&userRights=${userRights}`
    );
  },
  assignedTaskReport: (workSpaceId: string, taskType: string) => {
    return requests.get(
      `/dashboard/assignedtaskreport?workSpaceId=${workSpaceId}&taskType=${taskType}`
    );
  }
};

const QRMP = {
  getGstinQrmpList: (
    workSpaceId: string,
    skip: number,
    limit: number,
    searchText: string,
    quarter: string,
    status: FrequencyType[],
    download: boolean
  ) => {
    const qrmpStatus =
      status.length > 0 ? `&${createSearchParams({ "status[]": status })}` : "";

    const url = `/qrmp/listqrmp?workSpaceId=${workSpaceId}&skip=${skip}&limit=${limit}&searchText=${encodeURIComponent(
      searchText
    )}&quarter=${quarter}${qrmpStatus}&download=${download}`;
    return download
      ? requests.getBlob(url, { responseType: "blob" })
      : requests.get(url);
  },
  updateGstinQrmp: (workSpaceId: string, quarter: string, GSTIN: any) => {
    return requests.put(`/qrmp/updateqrmp`, { workSpaceId, quarter, GSTIN });
  },
  getQrmpUpdateRequestDetails: (
    workSpaceId: string,
    importRequestId: string
  ) => {
    return requests.get(
      `/qrmp/importrequestdetails?workSpaceId=${workSpaceId}&importRequestId=${importRequestId}`
    );
  }
};

const Notifications = {
  getNotificationsPreferences: (workSpaceId: string) => {
    return requests.get(`/notification/setting?workSpaceId=${workSpaceId}`);
  },
  changePreferences: (
    workSpaceId: string,
    notificationPreferences: NotificationSettingsType
  ) => {
    return requests.put(`/notification/setting`, {
      workSpaceId,
      ...notificationPreferences
    });
  },
  listInAppNotifications: (
    workSpaceId: string,
    read: boolean,
    skip: number,
    limit: number
  ) => {
    return requests.get(
      `/notification/list?workSpaceId=${workSpaceId}&skip=${skip}&limit=${limit}&read=${read}`
    );
  },
  markAsRead: (workSpaceId: string, notificationId: string) => {
    return requests.put(`/notification/markAsRead`, {
      workSpaceId,
      notificationId
    });
  },
  markAllAsRead: (workSpaceId: string) => {
    return requests.put(`/notification/markAllAsRead`, { workSpaceId });
  },
  deleteNotification: (workSpaceId: string, notificationIds: string[]) => {
    return requests.delete(`/notification/delete`, {
      workSpaceId,
      notificationIds
    });
  }
};

const Messages = {
  getTemplatesList: (workSpaceId: string, type: MessageType) => {
    return requests.get(
      `/messages/getMessageTemplates?workSpaceId=${workSpaceId}&type=${type}`
    );
  },
  createTemplate: (
    workSpaceId: string,
    name: string,
    type: MessageType,
    template: string,
    emailSubject?: string,
    emailHtml?: string
  ) => {
    return type === "EMAIL"
      ? requests.post(`/messages/createMessageTemplate`, {
          workSpaceId,
          name,
          type,
          template,
          emailSubject,
          emailHtml
        })
      : requests.post(`/messages/createMessageTemplate`, {
          workSpaceId,
          name,
          type,
          template
        });
  },
  updateTemplate: (
    workSpaceId: string,
    name: string,
    type: MessageType,
    messageId: string,
    template: string,
    emailSubject?: string,
    emailHtml?: string
  ) => {
    return type === "EMAIL"
      ? requests.post(`/messages/editMessageTemplate`, {
          workSpaceId,
          name,
          template,
          emailSubject,
          emailHtml,
          type,
          messageId
        })
      : requests.post(`/messages/editMessageTemplate`, {
          workSpaceId,
          name,
          template,
          type,
          messageId
        });
  },
  deleteTemplate: (workSpaceId: string, messageId: string) => {
    return requests.delete(`/messages/deleteMessageTemplate`, {
      workSpaceId,
      messageId
    });
  },
  getClientsForMessage: (
    workSpaceId: string,
    limit: number,
    skip: number,
    searchText: string,
    period: string,
    task: string,
    taskType: TaksType,
    filters: any
  ) => {
    return period
      ? requests.post(`/messages/clientsForMessages`, {
          workSpaceId,
          limit,
          skip,
          searchText,
          period,
          task,
          taskType,
          filters
        })
      : requests.post(`/messages/clientsForMessages`, {
          workSpaceId,
          limit,
          skip,
          searchText,
          filters
        });
  }
};

const TimeTracking = {
  start: (workSpaceId: string, taskId: string) => {
    return requests.post(`/timetrack/starttime`, {
      workSpaceId,
      taskId
    });
  },
  stop: (workSpaceId: string, timeTrackingId: string, comment: string) => {
    return requests.post(
      `/timetrack/stoptime/?workSpaceId=${workSpaceId}&id=${timeTrackingId}&comment=${comment}`
    );
  },
  editComment: (workSpaceId: string, timetrackId: string, comment: string) => {
    return requests.put(`/timetrack/editcomment`, {
      workSpaceId,
      timetrackId,
      comment
    });
  },
  deleteTimeTracking: (workSpaceId: string, timeTrackingId: string) => {
    return requests.delete(
      `/timetrack/deletetimetrack?workSpaceId=${workSpaceId}&id=${timeTrackingId}`
    );
  },
  manualTracking: (
    workSpaceId: string,
    taskId: string,
    datas: {
      startTime: string;
      stopTime: string;
      totalTime: number;
      comment: string;
    }[]
  ) => {
    return requests.post(`/timetrack/manualtime`, {
      workSpaceId,
      taskId,
      datas
    });
  },
  currentlyWorking: (workSpaceId: string) => {
    return requests.get(
      `/timetrack/currentlyworking?workSpaceId=${workSpaceId}`
    );
  },
  getTrackingHistory: (
    workSpaceId: string,
    dateFrom: string,
    dateTo: string,
    skip: number,
    limit: number,
    download: boolean,
    clientIdsArray?: string[],
    userIdsArray?: string[],
    trackingType?: string,
    taskIds?: string[]
  ) => {
    const baseURL = `/timetrack/gettimetrackingentries?workSpaceId=${workSpaceId}&dateFrom=${dateFrom}&dateTo=${dateTo}&skip=${skip}&limit=${limit}&download=${download}`;

    const userIdParams =
      userIdsArray && userIdsArray.length > 0
        ? `&${createSearchParams({ "userIds[]": userIdsArray })}`
        : "";
    const clientIdParams =
      clientIdsArray && clientIdsArray.length > 0
        ? `&${createSearchParams({ "clientIds[]": clientIdsArray })}`
        : "";
    const typeParams = trackingType
      ? `&${createSearchParams({ type: trackingType })}`
      : "";
    const taskParams =
      taskIds && taskIds?.length > 0
        ? `&${createSearchParams({ "taskIds[]": taskIds })}`
        : "";

    return download
      ? requests.getBlob(
          `${baseURL}${clientIdParams}${userIdParams}${typeParams}${taskParams}`,
          { responseType: "blob" }
        )
      : requests.get(
          `${baseURL}${clientIdParams}${userIdParams}${typeParams}${taskParams}`
        );
  }
  // getTrackingHistoryForTask: (
  //   workSpaceId: string,
  //   taskId: string,
  //   download: boolean
  // ) => {
  //   return download
  //     ? requests.getBlob(
  //         `/timetrack/alltimetrackingentries?workSpaceId=${workSpaceId}&taskId=${taskId}&download=${download}`,
  //         {
  //           responseType: "blob"
  //         }
  //       )
  //     : requests.get(
  //         `/timetrack/alltimetrackingentries?workSpaceId=${workSpaceId}&taskId=${taskId}&download=${download}`
  //       );
  // }
};

const RegisterInOut = {
  addRegisterEntry: (
    workSpaceId: string,
    date: string,
    client: string,
    type: RegisterType,
    document: string,
    mode: RegisterMode,
    manner: string,
    isOriginal: boolean,
    isReturnable: boolean,
    keptAt: string,
    receivedBy: string,
    futureActionRequire: boolean,
    description: string
  ) => {
    const data_client = client ? { client } : {};
    const data_type = type ? { type } : {};
    const data_document = document ? { document } : {};
    const data_mode = mode ? { mode } : {};
    const data_manner = manner ? { manner } : {};
    const data_keptAt = keptAt ? { keptAt } : {};
    const data_receivedBy = receivedBy ? { receivedBy } : {};
    const data_description = description ? { description } : {};

    return requests.post(`/registerinout/addRegisterInOutEntry`, {
      workSpaceId,
      date,
      ...data_client,
      ...data_type,
      ...data_document,
      ...data_mode,
      ...data_manner,
      ...data_keptAt,
      ...data_receivedBy,
      ...data_description,
      isOriginal,
      isReturnable,
      futureActionRequire
    });
  },
  editRegisterEntry: (
    workSpaceId: string,
    registerId: string,
    date: string,
    client: string,
    type: RegisterType,
    document: string,
    mode: RegisterMode,
    manner: string,
    isOriginal: boolean,
    isReturnable: boolean,
    keptAt: string,
    receivedBy: string,
    futureActionRequire: boolean,
    description: string
  ) => {
    const data_client = client ? { client } : {};
    const data_type = type ? { type } : {};
    const data_document = document ? { document } : {};
    const data_mode = mode ? { mode } : {};
    const data_manner = manner ? { manner } : {};
    const data_keptAt = keptAt ? { keptAt } : {};
    const data_receivedBy = receivedBy ? { receivedBy } : {};
    const data_description = description ? { description } : {};

    return requests.post(
      `/registerinout/editRegisterInOut/${registerId}?workSpaceId=${workSpaceId}`,
      {
        date,
        ...data_client,
        ...data_type,
        ...data_document,
        ...data_mode,
        ...data_manner,
        ...data_keptAt,
        ...data_receivedBy,
        ...data_description,
        isOriginal,
        isReturnable,
        futureActionRequire
      }
    );
  },
  deleteRegisterEntry: (registerId: string, workSpaceId: string) => {
    return requests.delete(
      `/registerinout/deleteRegisterInOut?id=${registerId}&workSpaceId=${workSpaceId}`
    );
  },
  getRegisterEntries: (
    workSpaceId: string,
    download: boolean,
    limit: number,
    skip: number,
    dateFrom: string,
    dateTo: string,
    client: string,
    type: RegisterType,
    document: string,
    mode: "" | "Hard Copy" | "Soft Copy",
    manner: string,
    receivedBy: string,
    isOriginal: BooleanString,
    isReturnable: BooleanString,
    keptAt: string,
    futureActionRequire: BooleanString,
    description: string
  ) => {
    const url = `/registerinout/registerInOutList?workSpaceId=${workSpaceId}&download=${download}&limit=${limit}&skip=${skip}${
      dateFrom ? `&dateFrom=${dateFrom}` : ""
    }${dateTo ? `&dateTo=${dateTo}` : ""}${client ? `&client=${client}` : ""}${
      type ? `&type=${type}` : ""
    }${document ? `&document=${document}` : ""}${mode ? `&mode=${mode}` : ""}${
      manner ? `&manner=${manner}` : ""
    }${receivedBy ? `&receivedBy=${receivedBy}` : ""}${
      isOriginal !== null ? `&isOriginal=${isOriginal}` : ""
    }${isReturnable !== null ? `&isReturnable=${isReturnable}` : ""}${
      keptAt ? `&keptAt=${keptAt}` : ""
    }${
      futureActionRequire !== null
        ? `&futureActionRequire=${futureActionRequire}`
        : ""
    }${description ? `&description=${encodeURIComponent(description)}` : ""}`;

    return download
      ? requests.getBlob(url, {
          responseType: "blob"
        })
      : requests.get(url);
  },
  addDocument: (workSpaceId: string, name: string) => {
    return requests.post(
      `/registerinout/addDocumentType?workSpaceId=${workSpaceId}`,
      { name }
    );
  },
  editDocument: (workSpaceId: string, documentId: string, name: string) => {
    return requests.post(
      `/registerinout/editDocumentType?workSpaceId=${workSpaceId}&id=${documentId}&name=${name}`
    );
  },
  deleteDocument: (workSpaceId: string, documentId: string) => {
    return requests.delete(
      `/registerinout/deleteDocumentType?workSpaceId=${workSpaceId}&id=${documentId}`
    );
  },
  listDocuments: (
    workSpaceId: string,
    searchText: string,
    skip: number,
    limit: number
  ) => {
    return requests.get(
      `/registerinout/listDocumentType?workSpaceId=${workSpaceId}&searchText=${encodeURIComponent(
        searchText
      )}&skip=${skip}&limit=${limit}`
    );
  },
  addKeptAt: (workSpaceId: string, name: string) => {
    return requests.post(
      `/registerinout/addKeptAt?workSpaceId=${workSpaceId}`,
      { name }
    );
  },
  editKeptAt: (workSpaceId: string, keptAtId: string, name: string) => {
    return requests.post(
      `/registerinout/editKeptAt?workSpaceId=${workSpaceId}&id=${keptAtId}&name=${name}`
    );
  },
  deleteKeptAt: (workSpaceId: string, keptAtId: string) => {
    return requests.delete(
      `/registerinout/deleteKeptAt?workSpaceId=${workSpaceId}&id=${keptAtId}`
    );
  },
  listKeptAt: (
    workSpaceId: string,
    searchText: string,
    skip: number,
    limit: number
  ) => {
    return requests.get(
      `/registerinout/listKeptAt?workSpaceId=${workSpaceId}&searchText=${encodeURIComponent(
        searchText
      )}&skip=${skip}&limit=${limit}`
    );
  }
};

const Payment = {
  getCurrentSubscription: (workSpaceId: string) => {
    return requests.get(
      `/payment/currentsubscription?workSpaceId=${workSpaceId}`
    );
  },
  getPlansList: (workSpaceId: string, purchaseType: PurchaseType) => {
    return requests.get(
      `/payment/list?purchaseType=${purchaseType}&workSpaceId=${workSpaceId}`
    );
  },
  getCalculatedPrice: (
    workSpaceId: string,
    planId: string,
    purchaseType: PurchaseType
  ) => {
    return requests.get(
      `/payment/calculateprice?workSpaceId=${workSpaceId}&planId=${planId}&purchaseType=${purchaseType}`
    );
  },
  initializePayment: (
    workSpaceId: string,
    planId: string,
    paymentType: PurchaseType,
    amount: number,
    GSTIN: string,
    legalName: string,
    address: string,
    expiryDate: Date
  ) => {
    return requests.post(`/payment`, {
      workSpaceId,
      planId,
      paymentType,
      amount,
      GSTIN,
      legalName,
      address,
      expiryDate
    });
  },
  convertToFreePlan: (workSpaceId: string) => {
    return requests.post(`/payment/convertfreeplan`, { workSpaceId });
  },
  getOrderList: (workSpaceId: string) => {
    return requests.get(`/order/orderlist?workSpaceId=${workSpaceId}`);
  },
  getPaymentStatus: (order: string) => requests.get(`/order/?orderId=${order}`),
  getClientLimits: (workSpaceId: string) => {
    return requests.get(`/payment/clientlimits?workSpaceId=${workSpaceId}`);
  },
  getInvoices: (workSpaceId: string) => {
    return requests.get(`/payment/getinvoices?workSpaceId=${workSpaceId}`);
  },
  downloadInvoice: (workSpaceId: string, invoiceId: string) => {
    return requests.getBlob(
      `/payment/downloadinvoice?workSpaceId=${workSpaceId}&invoiceId=${invoiceId}`,
      { responseType: "blob" }
    );
  },
  updateOrder: (data: {
    orderId: string;
    razorpayPaymentId: string;
    razorpaySignature: string;
    razorpayOrderId: string;
  }) => {
    return requests.patch(`/payment/updateorder`, data);
  }
};

const FrontendVersion = {
  getFrontendVersion: () => {
    return requests.get(`/version/frontend`);
  }
};

const joinToWaitingList = (email: string) => {
  return requests.get(`/version/waitinglist?email=${email}`);
};

let agent = {
  Agency,
  Auth,
  Firm,
  Tag,
  Status,
  User,
  ContactPerson,
  CustomField,
  ClientGroups,
  Clients,
  Tasks,
  ReturnStatus,
  Gst,
  Sales,
  Purchase,
  Returns,
  Todo,
  Receipt,
  RecurringTask,
  Logs,
  Reports,
  dashboard,
  QRMP,
  Notifications,
  Messages,
  TimeTracking,
  RegisterInOut,
  Payment,
  FrontendVersion,
  joinToWaitingList
};

export default agent;
