import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import { ItemsPerPage, itemsPerPage } from "../constants/itemsPerPage";
import SelectMenu from "./SelectMenu";
import { connect, ConnectedProps } from "react-redux";
import { compose } from "redux";
import { withRouter } from "../helpers/withRouter";
import { ADD_NOTIFICATION } from "../store/types";

//Redux mapping

const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  onNotify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props {
  displayRecords: any[];
  totalRecords: number;
  currPage: number;
  chunkSize: number;
  hidechunkSizeSelector?: boolean;
  handleItemPerPage?: (value: ItemsPerPage) => void;
  handlePageClick: (data: { selected: number }) => void;
  forcePage?: number;
  className?: string;
}

class Pagination extends Component<Props & PropsFromRedux> {
  render() {
    return this.props.displayRecords.length > 0 ? (
      <div
        className={`bg-white px-3 md:px-6 py-3 flex gap-y-2 md:gap-4 flex-col sm:flex-row items-center justify-between border border-gray-200 shadow-sm ${
          (this.props as any)?.location?.pathname.includes("task") ||
          (this.props as any)?.location?.pathname.includes("import") ||
          (this.props as any)?.location?.pathname.includes("messages") ||
          (this.props as any)?.location?.pathname.includes("reports") ||
          (this.props as any)?.location?.pathname.includes("client-profile") ||
          (this.props as any)?.location?.pathname.includes("group-profile") ||
          (this.props as any)?.location?.pathname.includes("bulk-edit")
            ? ""
            : "lg:mx-8"
        } ${this.props.className ?? ""}`}
      >
        <div className="grow flex gap-4 sm:gap-2 md:gap-4 flex-col xs:flex-row items-center justify-between">
          <p className="text-sm text-gray-700">
            Showing{" "}
            <span className="font-medium">
              {this.props.currPage * this.props.chunkSize + 1}
            </span>{" "}
            to{" "}
            <span className="font-medium">
              {(this.props.currPage + 1) * this.props.chunkSize >
              this.props.totalRecords
                ? this.props.totalRecords
                : (this.props.currPage + 1) * this.props.chunkSize}
            </span>{" "}
            of <span className="font-medium">{this.props.totalRecords}</span>{" "}
            results
          </p>
          {!this.props.hidechunkSizeSelector && (
            <div className="flex items-center gap-3">
              <SelectMenu
                label="Items per page"
                items={itemsPerPage}
                selected={{ name: this.props.chunkSize }}
                onChange={this.props.handleItemPerPage ?? (() => {})}
              />
            </div>
          )}
        </div>
        <div id="pagination" className="text-sm text-gray-500 my-2">
          <ReactPaginate
            previousLabel={"Prev"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(
              this.props.totalRecords / this.props.chunkSize
            )}
            marginPagesDisplayed={0}
            pageRangeDisplayed={2}
            onPageChange={this.props.handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
            forcePage={this.props.currPage}
          />
        </div>
      </div>
    ) : null;
  }
}

export default compose(
  connector,
  withRouter
)(Pagination) as React.ComponentType<Props>;
