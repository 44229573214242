import agent from "../agent";
import { NotifyType } from "../store/reducers/notification";

declare global {
  interface Window {
    grecaptcha: {
      ready: (callback: () => void) => void;
      execute: (key: string, options: { action: string }) => Promise<string>;
    };
  }
}

const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

if (!recaptchaKey) {
  console.error(
    "REACT_APP_RECAPTCHA_KEY is missing in .env file or not set. Please add it."
  );
}

export const verifyRecaptchaAndProceed = (
  action: "signup" | "login",
  setLoading: (loading: boolean) => void,
  proceedAction: () => void,
  addNotification?: (title: string, message: string, type: NotifyType) => void
) => {
  if (!recaptchaKey) {
    addNotification?.(
      "Recaptcha Key Missing",
      "Recaptcha key is missing. Please contact the administrator.",
      "danger"
    );

    return;
  }

  window.grecaptcha.ready(() => {
    window.grecaptcha
      .execute(recaptchaKey, { action })
      .then(verifyRecaptcha)
      .catch((err: any) => {
        const errorMessage = err instanceof Error ? err.message : err;

        addNotification?.("Recaptcha Not Verified", errorMessage, "danger");
      });
  });

  function verifyRecaptcha(token: string) {
    setLoading(true);

    agent.Auth.verifyRecaptcha(token)
      .then((response: { success: boolean; message?: string }) => {
        if (response.success) {
          proceedAction();
        } else {
          throw new Error(
            response.message ||
              "Recaptcha is not verified. Please refresh the page and try again."
          );
        }
      })
      .catch(err => {
        const errorMessage =
          err?.response?.data?.message || err?.message || err;
        addNotification?.(
          "Recaptcha Verification Failed",
          errorMessage,
          "danger"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }
};
