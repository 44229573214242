import {
  Component,
  MutableRefObject,
  RefObject,
  createRef,
  ComponentType
} from "react";
import axios from "axios";
import agent from "../agent";
import Icon from "./Icon";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { compose } from "redux";
import { AppDispatch, RootState } from "../store";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../store/types";
import { connect, ConnectedProps } from "react-redux";
import { WithRouterProps, withRouter } from "../helpers/withRouter";
import { NotifyType } from "../store/reducers/notification";
import { CommonAction } from "../store/reducers/common";

interface FrontendVersion {
  frontendVersion: number;
  maintenance: boolean;
  IPList: string[];
  maintenanceRunningMessage: string;
  upcomingMaintenanceMessage: string;
}

const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateCommon: (payload: CommonAction["payload"]) =>
    dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: NotifyType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends Partial<PropsFromRedux & WithRouterProps> {
  showInfoFooter: boolean;
  showHideInfoFooter: () => void;
  infoFooterHeight: number;
}

interface State {
  showMaintenaceMessage: boolean;
  showRefreshMessage: boolean;
  frontendVersion?: FrontendVersion["frontendVersion"];
  ipList: string[];
}

class MessageBar extends Component<Props, State> {
  state: State = {
    showMaintenaceMessage: false,
    showRefreshMessage: false,
    ipList: []
  };

  getFrontEndVersionIntervalRef: MutableRefObject<NodeJS.Timeout | null> =
    createRef();
  toggleInfoButtonRef: RefObject<HTMLDivElement> = createRef();

  componentDidMount() {
    localStorage.getItem("frontendJSVersion") &&
      this.setState({
        frontendVersion: JSON.parse(
          localStorage.getItem("frontendJSVersion") as string
        )
      });
    this.getFrontEndVersion();
    this.getFrontEndVersionIntervalRef.current = setInterval(() => {
      this.getFrontEndVersion();
    }, 1000 * 60 * 10);
  }

  componentWillUnmount() {
    if (this.getFrontEndVersionIntervalRef.current) {
      clearInterval(this.getFrontEndVersionIntervalRef.current);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevState.frontendVersion !== this.state.frontendVersion &&
      !prevState.frontendVersion &&
      this.state.frontendVersion
    ) {
      localStorage.setItem(
        "frontendJSVersion",
        JSON.stringify(this.state.frontendVersion)
      );
    }
    if (
      prevState.frontendVersion !== this.state.frontendVersion &&
      prevState.frontendVersion &&
      this.state.frontendVersion &&
      prevState?.frontendVersion < this.state?.frontendVersion
    ) {
      this.setState({ showRefreshMessage: true });
    }
  }

  getFrontEndVersion() {
    agent.FrontendVersion.getFrontendVersion().then(
      (response: FrontendVersion) => {
        const {
          maintenance,
          frontendVersion,
          upcomingMaintenanceMessage,
          IPList: ipList,
          maintenanceRunningMessage
        } = response;

        this.setState(
          {
            frontendVersion,
            ipList,
            showMaintenaceMessage: !!upcomingMaintenanceMessage
          },
          () => {
            maintenance
              ? this.getIp(response.maintenance)
              : this.props.updateCommon?.({
                  showMaintenance: false,
                  upcomingMaintenanceMessage,
                  maintenanceRunningMessage
                });
          }
        );
      }
    );
    // .catch((error: Error | any) => {
    //   console.log(error);
    // });
  }

  getIp = (maintenance: boolean) => {
    axios
      .get<{ ip: string }>("https://api.ipify.org/?format=json")
      .then(res => {
        const isUserIpAllowed = this.state.ipList.includes(res.data.ip);

        if (maintenance && isUserIpAllowed) {
          this.props.updateCommon?.({ showMaintenance: false });
        } else {
          this.props.updateCommon?.({ showMaintenance: true });
        }
      })
      .catch(err => {
        // console.log(err);
      });
  };

  refreshPage() {
    window.location.reload();
    localStorage.setItem(
      "frontendJSVersion",
      JSON.stringify(this.state.frontendVersion)
    );
  }

  render() {
    const { showInfoFooter, showHideInfoFooter, infoFooterHeight } = this.props;
    const { showMaintenaceMessage, showRefreshMessage } = this.state;

    return (
      <>
        {(showMaintenaceMessage || showRefreshMessage) && (
          <div
            ref={this.toggleInfoButtonRef}
            style={{
              bottom: showInfoFooter
                ? `calc(${infoFooterHeight}px +  ${
                    showRefreshMessage && showMaintenaceMessage
                      ? "8rem"
                      : showRefreshMessage
                      ? "4rem"
                      : "3rem"
                  })`
                : "0"
            }}
            className="md:hidden absolute z-0 left-6 border-2 border-red-500 rounded-t-md bg-red-400 cursor-pointer transition-[bottom] duration-300 ease-in-out"
            onClick={showHideInfoFooter}
          >
            <ChevronDownIcon
              className={`h-8 w-8 transition-transform duration-300 ease-in-out ${
                showInfoFooter ? "" : "rotate-180"
              }`}
            />
          </div>
        )}
        <div
          className={`space-y-3 divide-y fixed bottom-0 inset-x-0 z-10 inline-block w-full text-center bg-red-400 text-white text-sm font-bold transition-transform origin-bottom duration-300 ease-in-out ${
            showInfoFooter ? "" : "scale-y-0"
          }`}
        >
          {showRefreshMessage && (
            <button
              onClick={() => this.refreshPage()}
              className={`pt-2 ${
                showRefreshMessage && !showMaintenaceMessage ? "pb-2" : ""
              }`}
            >
              <p>
                The Content of the page has been updated. Please refresh the
                page to see the latest content.
                <span className="bg-white text-black font-medium ml-10 my-1 rounded-lg px-2 py-1 inline-flex gap-2 items-center">
                  Refresh
                  <Icon name="refresh" className="h-4 w-4 text-inherit" />
                </span>
              </p>
            </button>
          )}
          {showMaintenaceMessage && (
            <p className="py-2">{this.props.upcomingMaintenanceMessage}</p>
          )}
        </div>
      </>
    );
  }
}

export default compose<ComponentType<Props>>(connector, withRouter)(MessageBar);
