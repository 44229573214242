import years from "../constants/years";
import months from "../constants/months";

export const validFinYear = (year: string) => {
  if (years.find(yearObj => yearObj.name === year)) {
    return true;
  }
  return false;
};
export const validFinMonth = (month: string) => {
  if (months.find(monthObj => monthObj.name === month)) {
    return true;
  }
  return false;
};

export function isValidDate(dateString: string) {
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (!regex.test(dateString)) return false;

  const date = new Date(dateString);

  if (!date.getTime()) return false;

  return date.toISOString().slice(0, 10) === dateString;
}
