import { MiddlewareAPI, Dispatch, AnyAction } from "redux";
import { AuthAction } from "../reducers/userAuth";
import { LOGIN, LOGOUT } from "../types";

const authMiddleware =
  (store: MiddlewareAPI<Dispatch<AnyAction>, any>) =>
  (next: Dispatch<AnyAction>) =>
  (action: AuthAction) => {
    if (action.type === LOGIN) {
      localStorage.setItem(
        "loggedIn",
        action.payload?.isAuthenticated ? "true" : "false"
      );
    } else if (action.type === LOGOUT) {
      localStorage.removeItem("loggedIn");
    }

    next(action);
  };

export default authMiddleware;
