import React, { ChangeEvent, Fragment } from "react";
import { connect, ConnectedProps } from "react-redux";
import agent from "../../agent";
import Dashboard from "../../components/Dashboard";
import { ADD_GST, ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import TagManager from "react-gtm-module";
import Icon from "../../components/Icon";
import { withRouter } from "../../helpers/withRouter";
import { compose } from "redux";
import MultiSelect from "../../components/MultiSelect";
import {
  getQuarterFromMonthOrQuarter,
  getTaskPeriod
} from "../../helpers/taskPeriod";
import Skeleton from "react-loading-skeleton";
import {
  TaskClientType,
  clientTypesList,
  filterList,
  returnFileList
} from "../../constants/clients";
import capitalize from "../../helpers/capitalize";
import TableMultiSelectCheckbox from "../../components/TableMultiSelectCheckbox";
import { formatDate } from "../../helpers/formatDate";
import AddEditOtherTask from "./AddEditOtherTask";
import ReactQuill from "react-quill";
import { modules } from "../../components/Editor";
import Pagination from "../../components/Pagination";
import Popup from "../../components/Popup";
import { Menu, Transition } from "@headlessui/react";
import Button from "../../components/Button";
import DeleteModal from "../../components/DeleteModal";
import { FrequencyType } from "../Clients/QRMP/Index";
import MessageModal from "../../components/MessageModal";
import getDueDate from "../../helpers/getDueDate";
import { Client } from "../../helpers/types";
import { AppDispatch, RootState } from "../../store";
import { NotifyType } from "../../store/reducers/notification";
import { CommonAction } from "../../store/reducers/common";
import { maxAllowedDate } from "../ContactPerson/Add";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "gstadd"
  },
  dataLayerName: "PageDataLayer"
};

const periodType = [
  "One Time",
  "Monthly",
  "Quarterly",
  "Half-Yearly",
  "Yearly"
];

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateCommon: (payload: CommonAction["payload"]) =>
    dispatch({ type: UPDATE_COMMON, payload }),
  onNotify: (title: string, message: string, type: NotifyType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = Partial<PropsFromRedux>;

type ClientInAddTask = Client & {
  star: boolean;
  dueDate: string;
};

type DueDate = {
  _id: string;
  id: string;
  returntype: string;
  returnperiod: string;
  duedate: Date;
  turnover: string;
  statecode: string;
};

type State = {
  logging: boolean;
  rotate: boolean;
  showBackDrop: boolean;
  selectedRow: any;
  showDeleteModal: boolean;
  searchText: string;
  clientLoading: boolean;
  quickTask: any;
  createTask: TaskClientType;
  returnLoading: boolean;
  returnTypeList: any;
  returnTypes: any;
  selectedReturnTypes: any;
  period: any;
  selectedPeriod: any;
  tasks: any;
  totalRecords: number;
  displayClientsDetails: ClientInAddTask[];
  selectedClients: ClientInAddTask[];
  typingTimeout: NodeJS.Timeout | null;
  initalFilterSelect: any;
  gstFilterSelect: any;
  incomeTaxFilterSelect: any;
  ownershipFilterSelect: any;
  groupLoading: boolean;
  groupFilterList: any;
  clientGroupFilterSelect: any;
  tagLoading: boolean;
  tagFilterList: any;
  tagsFilterSelect: any;
  registrationStateFilterSelect: any;
  showFilter: boolean;
  itrFileReturnSelect: any;
  filterGstStatus: string;
  filters: any;
  chunkSize: number;
  currPage: number;
  showTableUserDropdown: string;
  editTaskData: any;
  otherTaskModalType: "add" | "edit";
  showOtherTaskModal: boolean;
  customTaskName: string;
  taskDueDate: string;
  selectedOtherPeriod: any;
  description: string;
  showQrmpModal: boolean;
  clientsWithoutQrmp: number;
};

//Main Classs
class AddTask extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      logging: false,
      rotate: false,
      showBackDrop: false,
      selectedRow: null,
      showDeleteModal: false,
      searchText: "",
      clientLoading: false,
      quickTask: null,
      createTask: "",
      returnLoading: false,
      returnTypeList: [],
      returnTypes: [],
      selectedReturnTypes: null,
      period: [],
      selectedPeriod: null,
      tasks: [],
      totalRecords: 0,
      displayClientsDetails: [],
      selectedClients: [],
      typingTimeout: null,
      initalFilterSelect: {
        _id: "ALL",
        name: "ALL"
      },
      gstFilterSelect: null,
      incomeTaxFilterSelect: null,
      ownershipFilterSelect: null,
      groupFilterList: [],
      groupLoading: false,
      clientGroupFilterSelect: [],
      tagsFilterSelect: [],
      tagLoading: false,
      tagFilterList: [],
      registrationStateFilterSelect: null,
      showFilter: false,
      itrFileReturnSelect: null,
      filterGstStatus: "",
      filters: {},
      chunkSize: 500,
      currPage: 0,
      showTableUserDropdown: "",
      editTaskData: null,
      otherTaskModalType: "add",
      showOtherTaskModal: false,
      customTaskName: "",
      taskDueDate: "",
      selectedOtherPeriod: null,
      description: "",
      showQrmpModal: false,
      clientsWithoutQrmp: 0
    };
  }

  onOptionsDropdownClick = () => {
    this.setState({
      rotate: !this.state.rotate,
      showBackDrop: !this.state.showBackDrop
    });
  };

  openDeleteModal = (otherTask: any) => {
    this.setState({ selectedRow: otherTask, showBackDrop: false });
    this.deleteModalSetOpen(true);
  };

  deleteModalSetOpen = (open: boolean) => {
    this.setState({
      showDeleteModal: open
    });
  };

  // Chunk Size for number of table data displayed in each page during pagination
  //chunkSize = 12;
  // Selected pagination value
  //currPage = 0;

  // onKeyUpFunction = (event: any) => {
  //   if (event.keyCode === 13 && !this.state.showOtherTaskModal) {
  //     this.addTask();
  //   }
  // };

  getAllTasksTypes = () => {
    const workSpaceId = (this.props as any).params?.firmId;
    const type = this.state.createTask;
    if (workSpaceId) {
      this.setState({ returnLoading: true });
      agent.Tasks.taskTypeList(workSpaceId, type)
        .then((response: any) => {
          this.setState({
            returnTypes: response.tasks.filter(
              (task: any) => task.type === type
            ),
            returnLoading: false
          });
        })
        .catch((err: any) => {
          this.setState({ returnLoading: false });
          (this.props as any).onNotify(
            "Could not load quick tasks",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    }
  };

  getOtherTaskTypes = () => {
    const workSpaceId = (this.props as any).params?.firmId;
    if (workSpaceId) {
      this.setState({ returnLoading: true });
      agent.Tasks.listOtherTask(workSpaceId)
        .then((response: any) => {
          this.setState({
            returnTypes: response.tasks,
            returnLoading: false
          });
          if (this.state.selectedReturnTypes?._id) {
            const selectedTask = response.tasks.find(
              (task: any) => task._id === this.state.selectedReturnTypes._id
            );
            selectedTask && this.onReturnTypeChange(selectedTask);
          }
        })
        .catch((err: any) => {
          this.setState({ returnLoading: false });
          (this.props as any).onNotify(
            "Could not load other tasks types",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    }
  };

  getGstTaskClientList = (forSearch: boolean) => {
    const { selectedReturnTypes, selectedPeriod, filters } = this.state;
    const workSpaceId = (this.props as any).params?.firmId;
    const limit = this.state.chunkSize;
    const skip = this.state.chunkSize * this.state.currPage;
    const searchText = forSearch ? this.state.searchText : "";
    const period = selectedPeriod?.value ? selectedPeriod?.value : "";
    const task = selectedReturnTypes?.name ? selectedReturnTypes?.name : "";
    if (period && task) {
      this.setState({ clientLoading: true });
      agent.Tasks.gstClientList(
        workSpaceId,
        limit,
        skip,
        searchText,
        period,
        task,
        filters
      )
        .then((response: any) => {
          const clients = response.clients.map((item: any) => ({
            ...item,
            star: false,
            dueDate: ""
          }));
          this.setState(
            {
              clientLoading: false,
              totalRecords: response.count,
              displayClientsDetails: clients
            },
            () => this.getTaskDueDate()
          );
        })
        .catch((err: any) => {
          this.setState({ clientLoading: false });
          (this.props as any).onNotify(
            "Could not load clients",
            Object.keys(err?.response?.data?.error).length === 0
              ? "Try differnt period"
              : err?.response?.data?.error || err?.message || err,
            "danger"
          );
        });
    }
  };

  getItTaskClientList = (forSearch: boolean) => {
    const { selectedReturnTypes, selectedPeriod, filters } = this.state;
    const workSpaceId = (this.props as any).params?.firmId;
    const limit = this.state.chunkSize;
    const skip = this.state.chunkSize * this.state.currPage;
    const searchText = forSearch ? this.state.searchText : "";
    const period = selectedPeriod?.value ? selectedPeriod?.value : "";
    const task = selectedReturnTypes?.name ? selectedReturnTypes?.name : "";
    if (period && task) {
      this.setState({ clientLoading: true });
      agent.Tasks.itClientList(
        workSpaceId,
        limit,
        skip,
        searchText,
        period,
        task,
        filters
      )
        .then((response: any) => {
          const clients = response.clients.map((item: any) => ({
            ...item,
            star: false,
            dueDate: ""
          }));
          this.setState(
            {
              clientLoading: false,
              totalRecords: response.count,
              displayClientsDetails: clients
            },
            () => this.getTaskDueDate()
          );
        })
        .catch((err: any) => {
          this.setState({ clientLoading: false });
          (this.props as any).onNotify(
            "Could not load clients",
            Object.keys(err?.response?.data?.error).length === 0
              ? "Try differnt period"
              : err?.response?.data?.error || err?.message || err,
            "danger"
          );
        });
    }
  };

  getOtherTaskClientList = () => {
    const { selectedReturnTypes } = this.state;
    const workSpaceId = (this.props as any).params?.firmId;
    const taskId = selectedReturnTypes?.name ? selectedReturnTypes?._id : "";
    const otherTaskList = [taskId];
    if (taskId) {
      this.setState({ clientLoading: true });
      agent.Tasks.otherClientList(workSpaceId, otherTaskList)
        .then((response: any) => {
          const clients = response.clients.map((item: any) => ({
            ...item,
            star: false,
            dueDate: ""
          }));
          this.setState(
            {
              clientLoading: false,
              totalRecords: response.count,
              displayClientsDetails: clients
            },
            () => this.getTaskDueDate()
          );
        })
        .catch((err: any) => {
          this.setState({ clientLoading: false });
          (this.props as any).onNotify(
            "Could not load clients",
            Object.keys(err?.response?.data?.error).length === 0
              ? "Try differnt period"
              : err?.response?.data?.error || err?.message || err,
            "danger"
          );
        });
    }
  };

  getTaskDueDate = () => {
    const { selectedReturnTypes, selectedPeriod } = this.state;
    const workSpaceId = (this.props as any).params?.firmId;
    const taskName = selectedReturnTypes?.name ? selectedReturnTypes?.name : "";
    const period = selectedPeriod?.value ? selectedPeriod?.value : "";
    if (period && taskName) {
      agent.Tasks.getTaskDueDate(workSpaceId, taskName, period)
        .then((response: { duedate: DueDate[] }) => {
          // console.log({ response });
          const clients = this.state.displayClientsDetails;
          let updatedClients: any = [];
          if (response.duedate.length === 0) {
            updatedClients = clients;
          }
          // Condition for setting up due date for all the task other than GSTR3B Quarterly which does not depend on the state
          else if (taskName !== "GSTR-3B (Quarterly)") {
            clients.forEach((client: any) => {
              const clientDueDate = response.duedate[0];
              updatedClients.push({
                ...client,
                dueDate: formatDate(clientDueDate?.duedate, true)
              });
            });
            // Condition for setting up due date for all the task other than GSTR3B Quarterly which depend on the state
          } else {
            clients.forEach((client: any) => {
              const isStateCodeAbsent = response.duedate.findIndex(
                (date: any) => date.statecode === null || []
              );

              if (isStateCodeAbsent === 0) {
                const clientStateCode = client?.gstin.slice(0, 2);
                const clientDueDate = response.duedate.find((item: any) =>
                  item.statecode.includes(clientStateCode)
                );

                if (clientDueDate) {
                  updatedClients.push({
                    ...client,
                    dueDate: formatDate(clientDueDate?.duedate, true)
                  });
                } else {
                  updatedClients.push(client);
                }
              } else {
                updatedClients.push({
                  ...client,
                  dueDate: formatDate(response.duedate[0]?.duedate, true)
                });
              }
            });
          }

          this.setState({
            displayClientsDetails: updatedClients,
            taskDueDate: formatDate(response.duedate[0].duedate, true) || ""
          });
        })
        .catch((err: any) => {
          (this.props as any).onNotify(
            "Could not load Due Date",
            err?.response?.data?.error || err?.message || err,
            "danger"
          );
        });
    }
  };

  getClientsList = (forSearch: boolean) => {
    const workSpaceId = (this.props as any).params?.firmId;
    const active = true;
    const limit = this.state.chunkSize;
    const skip = this.state.chunkSize * this.state.currPage;
    const searchText = forSearch ? this.state.searchText : "";
    this.setState({ clientLoading: true });
    agent.Clients.getClientList(
      workSpaceId,
      skip,
      limit,
      searchText,
      active,
      this.state.filters
    )
      .then((response: any) => {
        const clients = response.clients.map((item: any) => ({
          ...item,
          star: false,
          dueDate: ""
        }));

        this.setState({
          clientLoading: false,
          totalRecords: response.count,
          displayClientsDetails: clients
        });
      })
      .catch((err: any) => {
        this.setState({ clientLoading: false });
        (this.props as any).onNotify(
          "Could not load Client Details",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  getGroupList = () => {
    const workSpaceId = (this.props as any).params?.firmId;
    const searchText = "";
    const active = true;
    this.setState({ groupLoading: true });
    agent.ClientGroups.getClientGroupList(
      workSpaceId,
      active,
      searchText,
      0,
      100000
    )
      .then((response: any) => {
        this.setState({
          groupFilterList: response.groups,
          groupLoading: false
        });
      })
      .catch((err: any) => {
        this.setState({ groupLoading: false });
        (this.props as any).onNotify(
          "Could not load Firm Details",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  getQRMPlist = () => {
    const workSpaceId = (this.props as any).params?.firmId;
    const skip = 0;
    const limit = 100000;
    const searchText = "";
    const frequency: FrequencyType[] = ["N", "NA"];

    const selectedQuarter = getQuarterFromMonthOrQuarter(
      this.state.selectedPeriod.value
    );

    agent.QRMP.getGstinQrmpList(
      workSpaceId,
      skip,
      limit,
      searchText,
      selectedQuarter,
      frequency,
      false
    )
      .then(res => {
        res.count &&
          this.setState({ showQrmpModal: true, clientsWithoutQrmp: res.count });
      })
      .catch(err => {
        (this.props as any).onNotify("Error", err.response.data.error, "error");
      });
  };

  openQrmpPage = () => {
    const workSpaceId = (this.props as any).params?.firmId;

    const selectedQuarter = getQuarterFromMonthOrQuarter(
      this.state.selectedPeriod.value
    );

    (this.props as any).navigate(
      `/${workSpaceId}/clients/qrmp?quarter=${selectedQuarter}&frequency=N,NA`
    );
  };

  handleCloseQrmpModal = () => this.setState({ showQrmpModal: false });

  componentDidMount() {
    document.title = "Add Tasks - TaxPido PMS";
    // document.addEventListener("keydown", this.onKeyUpFunction, false);
  }

  componentWillUnmount() {
    // document.removeEventListener("keydown", this.onKeyUpFunction, false);
  }

  componentDidUpdate(prevProps: any, prevState: typeof this.state) {
    const prevFirmId = prevProps.params.firmId;
    const currFirmId = (this.props as any).params.firmId;

    if (prevFirmId !== currFirmId) {
      this.setState({
        createTask: "",
        selectedReturnTypes: null,
        selectedPeriod: null,
        selectedClients: [],
        selectedOtherPeriod: null,
        taskDueDate: "",
        searchText: "",
        displayClientsDetails: [],
        totalRecords: 0,
        currPage: 0,
        chunkSize: 25,
        clientLoading: false
      });
    }

    const createTask = this.state.createTask;
    const prevCreateTask = prevState.createTask;
    if (createTask !== prevCreateTask && createTask !== "other") {
      this.getAllTasksTypes();
    }

    if (createTask !== prevCreateTask && createTask === "other") {
      this.getOtherTaskTypes();
      this.state.searchText
        ? this.getClientsList(true)
        : this.getClientsList(false);
      this.setState({
        selectedPeriod: null,
        selectedClients: [],
        selectedOtherPeriod: null,
        taskDueDate: ""
      });
    }
    if (
      prevState.initalFilterSelect?.name !==
        this.state.initalFilterSelect?.name &&
      this.state.initalFilterSelect?.name === "Client Group"
    ) {
      this.getGroupList();
    }
    const clientRights = (this.props as any)?.rights?.clientRights;
    const importClientRight = clientRights?.importClient;
    const taskToCheckQrmp = [
      "GSTR-1 (Monthly)",
      "GSTR-3B (Monthly)",
      "GSTR-1 (Quarterly)",
      "GSTR-3B (Quarterly)",
      "IFF"
    ];

    if (
      prevState.selectedPeriod !== this.state.selectedPeriod &&
      this.state.selectedPeriod &&
      this.state.createTask === "GST" &&
      importClientRight &&
      taskToCheckQrmp.includes(this.state.selectedReturnTypes?.name)
    ) {
      this.getQRMPlist();
    }
  }

  handlePageClick = (data: any) => {
    this.setState({ currPage: data.selected }, () => {
      if (this.state.createTask === "GST") {
        this.state.searchText
          ? this.getGstTaskClientList(true)
          : this.getGstTaskClientList(false);
      } else if (this.state.createTask === "IT") {
        this.state.searchText
          ? this.getItTaskClientList(true)
          : this.getItTaskClientList(false);
      } else if (this.state.createTask === "other") {
        this.state.searchText
          ? this.getClientsList(true)
          : this.getClientsList(false);
      }
    });
  };

  handleItemPerPage = (value: any) => {
    this.setState({ chunkSize: value.name, currPage: 0 }, () => {
      if (this.state.createTask === "GST") {
        this.state.searchText
          ? this.getGstTaskClientList(true)
          : this.getGstTaskClientList(false);
      } else if (this.state.createTask === "IT") {
        this.state.searchText
          ? this.getItTaskClientList(true)
          : this.getItTaskClientList(false);
      } else if (this.state.createTask === "other") {
        this.state.searchText
          ? this.getClientsList(true)
          : this.getClientsList(false);
      }
    });
  };

  handleSearchTextChange = (ev: any) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      searchText: ev.target.value,
      currPage: 0,
      typingTimeout: setTimeout(() => {
        if (this.state.createTask === "GST") {
          this.getGstTaskClientList(true);
        } else if (this.state.createTask === "IT") {
          this.getItTaskClientList(true);
        } else if (this.state.createTask === "other") {
          this.getClientsList(true);
        }
      }, 700)
    });
  };

  addGstTask = () => {
    const workSpaceId = (this.props as any).params?.firmId;
    const { selectedReturnTypes, selectedPeriod, createTask, selectedClients } =
      this.state;
    const name = selectedReturnTypes?.name ? selectedReturnTypes.name : "";
    const period = selectedPeriod?.name ? selectedPeriod?.name : "";

    const miscData = selectedClients.map(item => ({
      clientId: item._id,
      star: item.star,
      users: item.defUsers.map(user => user.userId),
      currentUserId: "",
      dueDate: item.dueDate || ""
    }));

    const dueDateMissingInClient = miscData.some(item => !item.dueDate);

    if (dueDateMissingInClient) {
      return this.props.onNotify?.(
        "Due Date is mandatory",
        "Please add Due Date in all clients",
        "danger"
      );
    }

    const data = {
      name,
      period,
      miscData
    };

    if (createTask && name && period && miscData.length > 0) {
      this.setState({ logging: true });
      agent.Tasks.createGstTask(workSpaceId, data)
        .then((response: any) => {
          (this.props as any).onNotify(
            "Task Created Successfully",
            "Task Created Successfully",
            "success"
          );
          this.setState({ logging: false });
          (this.props as any).navigate(`/${workSpaceId}/tasks/list`);
          (this.props as any).updateCommon({
            taskCreated: true,
            taskCreatedData: response.result
          });
        })
        .catch((err: any) => {
          (this.props as any).onNotify(
            "Could not create task",
            Object.keys(err?.response?.data?.error).length === 0
              ? "Try adding with differnt period"
              : err?.response?.data?.message ||
                  err?.response?.data?.error ||
                  err?.message ||
                  err,
            "danger"
          );
          this.setState({ logging: false });
        });
    } else if (!createTask) {
      (this.props as any).onNotify(
        "Please select Create Task Type",
        "Please select Create Task Type",
        "danger"
      );
    } else if (!name) {
      (this.props as any).onNotify(
        "Please select Return type",
        "Please select Return type",
        "danger"
      );
    } else if (!period) {
      (this.props as any).onNotify(
        "Please select Period",
        "Please select Period",
        "danger"
      );
    } else if (miscData.length === 0) {
      (this.props as any).onNotify(
        "Please select at least one client",
        "Please select at least one client",
        "danger"
      );
    }
  };

  addItTask = () => {
    const workSpaceId = (this.props as any).params?.firmId;
    const {
      selectedReturnTypes,
      selectedPeriod,
      createTask,
      taskDueDate,
      selectedClients
    } = this.state;
    const name = selectedReturnTypes?.name ? selectedReturnTypes.name : "";
    const period = selectedPeriod?.name ? selectedPeriod?.name : "";
    const miscData: any = selectedClients.map((item: any) => ({
      clientId: item._id,
      star: item.star,
      users: item.defUsers.map((user: any) => user.userId),
      currentUserId: ""
    }));

    const dueDate = taskDueDate ? new Date(taskDueDate).toISOString() : "";

    const data = {
      name,
      period,
      miscData,
      dueDate
    };
    if (createTask && name && period && dueDate && miscData.length > 0) {
      this.setState({ logging: true });
      agent.Tasks.createItTask(workSpaceId, data)
        .then((response: any) => {
          (this.props as any).onNotify(
            "Task Created Successfully",
            "Task Created Successfully",
            "success"
          );
          this.setState({ logging: false });
          (this.props as any).navigate(`/${workSpaceId}/tasks/list`);
          (this.props as any).updateCommon({
            taskCreated: true,
            taskCreatedData: response.result
          });
        })
        .catch((err: any) => {
          // console.log("err", err);
          (this.props as any).onNotify(
            "Could not create task",
            Object.keys(err?.response?.data?.error).length === 0
              ? "Try adding with differnt period"
              : err?.response?.data?.message ||
                  err?.response?.data?.error ||
                  err?.message ||
                  err,
            "danger"
          );
          this.setState({ logging: false });
        });
    } else if (!createTask) {
      (this.props as any).onNotify(
        "Please select Create Task Type",
        "Please select Create Task Type",
        "danger"
      );
    } else if (!name) {
      (this.props as any).onNotify(
        "Please select Return type",
        "Please select Return type",
        "danger"
      );
    } else if (!period) {
      (this.props as any).onNotify(
        "Please select Period",
        "Please select Period",
        "danger"
      );
    } else if (!dueDate) {
      (this.props as any).onNotify(
        "Please add Due Date",
        "Due Date is mandatory",
        "danger"
      );
    } else if (miscData.length === 0) {
      (this.props as any).onNotify(
        "Please select at least one client",
        "Please select at least one client",
        "danger"
      );
    }
  };

  convertHtmlToText = (value: string) => {
    const plainText = value.replace(/<\/?[^>]+>/gi, "");
    const finalPlainText = plainText.replace(/&(nbsp|amp|quot|lt|gt);/g, " ");
    return finalPlainText;
  };

  addOtherTask = () => {
    const workSpaceId = (this.props as any).params?.firmId;
    const {
      selectedReturnTypes,
      selectedPeriod,
      createTask,
      taskDueDate,
      selectedClients,
      selectedOtherPeriod
    } = this.state;

    const name = selectedReturnTypes
      ? selectedReturnTypes.name
      : this.state.customTaskName;

    const period =
      selectedOtherPeriod?.name === "One Time"
        ? { period: "One Time" }
        : selectedPeriod?.name
        ? { period: selectedPeriod?.name }
        : {};
    const plainText = this.convertHtmlToText(this.state.description);
    const description = this.state.description
      ? {
          description: {
            text: plainText,
            html: this.state.description
          }
        }
      : {};

    const miscData: any = selectedClients.map((item: any) => ({
      clientId: item._id,
      star: item.star,
      users: item.defUsers.map((user: any) => user.userId),
      currentUserId: ""
    }));

    const dueDate = taskDueDate ? new Date(taskDueDate).toISOString() : "";

    const data = {
      name,
      dueDate,
      miscData,
      ...description,
      ...period
    };

    if (createTask && name && dueDate && miscData.length > 0 && period.period) {
      this.setState({ logging: true });
      agent.Tasks.createOtherTask(workSpaceId, data)
        .then((response: any) => {
          (this.props as any).onNotify(
            "Task Created Successfully",
            "Task Created Successfully",
            "success"
          );
          this.setState({ logging: false });
          (this.props as any).navigate(`/${workSpaceId}/tasks/list`);
          (this.props as any).updateCommon({
            taskCreated: true,
            taskCreatedData: response.result
          });
        })
        .catch((err: any) => {
          (this.props as any).onNotify(
            "Could not create task",
            Object.keys(err?.response?.data?.error).length === 0
              ? "Try adding with differnt period"
              : err?.response?.data?.message ||
                  err?.response?.data?.error ||
                  err?.message ||
                  err,
            "danger"
          );
          this.setState({ logging: false });
        });
    } else if (!createTask) {
      (this.props as any).onNotify(
        "Please select Create Task Type",
        "Please select Create Task Type",
        "danger"
      );
    } else if (!name) {
      (this.props as any).onNotify(
        "Please select Other Task",
        "Please select Other Task",
        "danger"
      );
    } else if (!dueDate) {
      (this.props as any).onNotify(
        "Please add Due Date",
        "Due Date is mandatory",
        "danger"
      );
    } else if (miscData.length === 0) {
      (this.props as any).onNotify(
        "Please select at least one client",
        "Please select at least one client",
        "danger"
      );
    } else if (!period?.period) {
      (this.props as any).onNotify(
        "Please select Period",
        "Period is mandatory for this task",
        "danger"
      );
    }
  };

  addTask = () => {
    switch (this.state.createTask) {
      case "GST":
        return this.addGstTask();
      case "IT":
        return this.addItTask();
      case "other":
        return this.addOtherTask();
      default:
        break;
    }
  };

  cancelHandler = () => {
    const firmId = (this.props as any).params?.firmId;
    (this.props as any).navigate(`/${firmId}/tasks/list`);
  };

  updateState = (field: string) => (ev: any) => {
    this.resetFilter();
    this.toggleFilter(false);
    if (field === "createTask") {
      this.setState({
        createTask: ev.target.value,
        selectedReturnTypes: null,
        selectedPeriod: null,
        selectedClients: [],
        taskDueDate: ""
      });
    } else if (field === "taskDueDate") {
      this.setState({
        taskDueDate: ev.target.value
      });
      this.changeAllClientDate(ev);
    } else {
      this.setState({
        [field]: capitalize(ev.target.value)
      } as Pick<State, keyof State>);
    }
  };

  onPeriodChange = (item: any) => {
    if (this.state.createTask === "other") {
      const dueDate = getDueDate(
        this.state.selectedOtherPeriod.name.toLowerCase(),
        item.name,
        this.state.selectedReturnTypes?.beforeAfter === "after",
        this.state.selectedReturnTypes?.dueDays
      );

      this.setState({
        selectedPeriod: item,
        taskDueDate: formatDate(dueDate, true)
      });
    } else {
      this.setState(
        {
          selectedPeriod: item,
          selectedClients: [],
          clientLoading: false,
          totalRecords: 0,
          displayClientsDetails: [],
          searchText: ""
        },
        () => {
          if (this.state.createTask === "GST") {
            this.getGstTaskClientList(false);
          } else if (this.state.createTask === "IT") {
            this.getItTaskClientList(false);
          }
        }
      );
    }
  };

  onTaskChange = (item: any) => {
    this.setState({ quickTask: item, createTask: item.type });
  };

  onReturnTypeChange = (item: any) => {
    if (this.state.createTask === "other") {
      let period = getTaskPeriod(item.period.toLowerCase(), "", "", item.name);
      this.setState({
        selectedReturnTypes: item,
        selectedOtherPeriod: { _id: item.period, name: item.period },
        period,
        selectedPeriod: null,
        selectedClients: [],
        taskDueDate: "",
        customTaskName: ""
      });
    } else {
      const startPeriod = item?.startPeriod ? item.startPeriod : "";
      const endPeriod = item?.endPeriod ? item.endPeriod : "";
      let period = getTaskPeriod(
        item.periodType,
        startPeriod,
        endPeriod,
        item.name
      );
      // if (item?.name === "IFF" || item?.name === "PMT-06") {
      //   period = period.filter((p: any) => !p?.name?.includes("Dec"));
      // }
      this.setState({
        selectedReturnTypes: item,
        period,
        selectedPeriod: null,
        selectedClients: [],
        customTaskName: ""
      });
      item?.periodType?.toLowerCase() === "yearly" &&
        this.onPeriodChange(period[1]);
    }
  };

  onClientChange = (client: any) => {
    const { selectedClients } = this.state;
    const clientIndex = selectedClients.findIndex(
      (c: any) => c._id === client._id
    );
    if (clientIndex === -1) {
      this.setState({ selectedClients: [...selectedClients, client] });
    } else {
      const removeClient = selectedClients.filter(
        (c: any) => c._id !== client._id
      );
      this.setState({ selectedClients: removeClient });
    }
  };

  onSelectAllClients = () => {
    const { displayClientsDetails, selectedClients } = this.state;
    if (selectedClients.length === displayClientsDetails.length) {
      this.setState({ selectedClients: [] });
    } else {
      this.setState({ selectedClients: displayClientsDetails });
    }
  };

  changeClientDate =
    (client: Client) => (ev: ChangeEvent<HTMLInputElement>) => {
      const { displayClientsDetails, selectedClients } = this.state;
      const updateSelectedClients = selectedClients.map(item =>
        item._id === client._id ? { ...item, dueDate: ev.target.value } : item
      );

      const updateClientDueDate = displayClientsDetails.map(item =>
        item._id === client._id ? { ...item, dueDate: ev.target.value } : item
      );

      this.setState({
        displayClientsDetails: updateClientDueDate,
        selectedClients: updateSelectedClients
      });
    };

  changeAllClientDate = (ev: ChangeEvent<HTMLInputElement>) => {
    const { displayClientsDetails, selectedClients } = this.state;
    const updateSelectedClients = selectedClients.map((item: any) => ({
      ...item,
      dueDate: ev.target.value
    }));

    const updateClientDueDate = displayClientsDetails.map((item: any) => ({
      ...item,
      dueDate: ev.target.value
    }));

    this.setState({
      displayClientsDetails: updateClientDueDate,
      selectedClients: updateSelectedClients
    });
  };

  onStarClick = (client: any) => {
    const { displayClientsDetails, selectedClients } = this.state;
    const updateSelectedClients = selectedClients.map((item: any) =>
      item._id === client._id ? { ...item, star: !item.star } : item
    );

    const updateClientStar = displayClientsDetails.map((item: any) =>
      item._id === client._id ? { ...item, star: !item.star } : item
    );

    this.setState({
      displayClientsDetails: updateClientStar,
      selectedClients: updateSelectedClients
    });
  };

  toggleFilter = (open: boolean) => {
    this.setState({ showFilter: open });
  };

  onFilterChange = (item: any) => {
    if (item.name === "ALL") {
      this.setState(
        {
          initalFilterSelect: item,
          gstFilterSelect: null,
          incomeTaxFilterSelect: null,
          itrFileReturnSelect: null,
          ownershipFilterSelect: null,
          clientGroupFilterSelect: null,
          tagsFilterSelect: null,
          registrationStateFilterSelect: null,
          filters: {}
        },
        () => this.getGstTaskClientList(false)
      );
    } else if (item.name === "Only Other Client") {
      this.setState(
        {
          initalFilterSelect: item,
          gstFilterSelect: null,
          incomeTaxFilterSelect: null,
          itrFileReturnSelect: null,
          ownershipFilterSelect: null,
          clientGroupFilterSelect: null,
          tagsFilterSelect: null,
          registrationStateFilterSelect: null,
          filters: { otherClient: true }
        },
        () =>
          this.state.createTask === "GST"
            ? this.getGstTaskClientList(false)
            : this.state.searchText
            ? this.getClientsList(true)
            : this.getClientsList(false)
      );
    } else {
      this.setState({
        initalFilterSelect: item,
        gstFilterSelect: null,
        incomeTaxFilterSelect: null,
        itrFileReturnSelect: null,
        ownershipFilterSelect: null,
        clientGroupFilterSelect: null,
        tagsFilterSelect: null,
        registrationStateFilterSelect: null
      });
    }
  };

  onGstFilterChange = (item: any) => {
    if (item.name === "ALL GST") {
      this.setState(
        {
          gstFilterSelect: item,
          registrationStateFilterSelect: null,
          filterGstStatus: "",
          filters: {
            gst: {
              sub: [{ key: "gstApplicable", value: "true" }]
            }
          }
        },
        () => this.getGstTaskClientList(false)
      );
    } else if (item.name === "GST Composition Clients") {
      this.setState(
        {
          gstFilterSelect: item,
          registrationStateFilterSelect: null,
          filterGstStatus: "",
          filters: {
            gst: {
              sub: [{ key: "registrationType", value: "composition" }]
            }
          }
        },
        () => this.getGstTaskClientList(false)
      );
    } else if (item.name === "GST Regular Clients") {
      this.setState(
        {
          gstFilterSelect: item,
          registrationStateFilterSelect: null,
          filterGstStatus: "",
          filters: {
            gst: {
              sub: [{ key: "registrationType", value: "regular" }]
            }
          }
        },
        () => this.getGstTaskClientList(false)
      );
    } else if (item.name === "GST Audit Applicable") {
      this.setState(
        {
          gstFilterSelect: item,
          registrationStateFilterSelect: null,
          filterGstStatus: "",
          filters: {
            gst: {
              sub: [{ key: "gstApplicable", value: "true" }]
            }
          }
        },
        () => this.getGstTaskClientList(false)
      );
    } else if (item.name === "GSTR-9 Compulsory") {
      this.setState(
        {
          gstFilterSelect: item,
          registrationStateFilterSelect: null,
          filterGstStatus: "",
          filters: {
            gst: {
              sub: [{ key: "gstr9_compulsory", value: "true" }]
            }
          }
        },
        () => this.getGstTaskClientList(false)
      );
    } else if (item.name === "GSTR-9C Applicable") {
      this.setState(
        {
          gstFilterSelect: item,
          registrationStateFilterSelect: null,
          filterGstStatus: "",
          filters: {
            gst: {
              sub: [{ key: "gstr9c_compulsory", value: "true" }]
            }
          }
        },
        () => this.getGstTaskClientList(false)
      );
    } else {
      this.setState({
        gstFilterSelect: item,
        registrationStateFilterSelect: null,
        filterGstStatus: ""
      });
    }
  };

  onGstStatusChange = (event: any) => {
    this.setState(
      {
        filterGstStatus: event.target.value,
        filters: {
          gst: {
            sub: [{ key: "gstinStatus", value: event.target.value }]
          }
        }
      },
      () => this.getGstTaskClientList(false)
    );
  };

  onIncomeTaxFilterChange = (item: any) => {
    if (item.name === "Income Tax Audit Applicable") {
      this.setState(
        {
          incomeTaxFilterSelect: item,
          itrFileReturnSelect: null,
          filters: {
            incomeTax: {
              sub: [{ key: "itApplicable", value: "true" }]
            }
          }
        },
        () => this.getGstTaskClientList(false)
      );
    } else {
      this.setState({ incomeTaxFilterSelect: item, itrFileReturnSelect: null });
    }
  };

  onItrRuturnFilterChange = (item: any) => {
    this.setState(
      {
        itrFileReturnSelect: item,
        filters: {
          incomeTax: {
            sub: [
              {
                key: "filedITreturns",
                value: item.name
              }
            ]
          }
        }
      },
      () => this.getGstTaskClientList(false)
    );
  };

  onOwnershipFilterChange = (item: any) => {
    this.setState(
      {
        ownershipFilterSelect: item,
        filters: {
          ownershipType: { sub: [{ key: "type", value: item.name }] }
        }
      },
      () =>
        this.state.createTask === "GST"
          ? this.getGstTaskClientList(false)
          : this.state.searchText
          ? this.getClientsList(true)
          : this.getClientsList(false)
    );
  };

  onClientGroupFilterChange = (item: any) => {
    this.setState(
      {
        clientGroupFilterSelect: item,
        filters: {
          clientGroup: [item._id]
        }
      },
      () =>
        this.state.createTask === "GST"
          ? this.getGstTaskClientList(false)
          : this.state.searchText
          ? this.getClientsList(true)
          : this.getClientsList(false)
    );
  };

  onTagsFilterChange = (item: any) => {
    this.setState(
      {
        tagsFilterSelect: item,
        filters: {
          tags: [item._id]
        }
      },
      () =>
        this.state.createTask === "GST"
          ? this.getGstTaskClientList(false)
          : this.state.searchText
          ? this.getClientsList(true)
          : this.getClientsList(false)
    );
  };

  onRegistrationStateFilterChange = (item: any) => {
    this.setState({ registrationStateFilterSelect: item });
  };

  resetFilter = () => {
    this.setState({
      initalFilterSelect: { _id: "ALL", name: "ALL" },
      gstFilterSelect: null,
      incomeTaxFilterSelect: null,
      ownershipFilterSelect: null,
      clientGroupFilterSelect: [],
      tagsFilterSelect: [],
      registrationStateFilterSelect: null,
      itrFileReturnSelect: null,
      filterGstStatus: ""
    });
  };

  userList = (users: any) => {
    const allUsers = users.map((user: any) => ({ ...user, _id: user.userId }));
    const slicedUsers = allUsers.slice(0, 3);
    return { allUsers, slicedUsers };
  };

  onTableUserClick = (clientId: string) => {
    this.setState({
      showTableUserDropdown: clientId
    });
  };

  onTableCloseDropdown = () => {
    this.setState({
      showTableUserDropdown: ""
    });
  };

  onTableUserChange = (user: any) => {
    const { displayClientsDetails, selectedClients, showTableUserDropdown } =
      this.state;

    function addOrRemoveUser(users: any) {
      const userIndex = users.findIndex(
        (item: any) => item.userId === user.userId
      );
      if (userIndex !== -1) {
        users = users.filter((item: any) => item.userId !== user.userId);
      } else {
        users = [...users, user];
      }
      return users;
    }

    const updateSelectedClients = selectedClients.map((item: any) =>
      item._id === showTableUserDropdown
        ? { ...item, defUsers: addOrRemoveUser(item.defUsers) }
        : item
    );

    const updateClientUser = displayClientsDetails.map((item: any) =>
      item._id === showTableUserDropdown
        ? { ...item, defUsers: addOrRemoveUser(item.defUsers) }
        : item
    );

    this.setState({
      displayClientsDetails: updateClientUser,
      selectedClients: updateSelectedClients
    });
  };

  showOtherTaskModal = (show: boolean) => {
    this.setState({ showOtherTaskModal: show, otherTaskModalType: "add" });
  };

  editSelectedTask = (task: any) => {
    this.setState({
      otherTaskModalType: "edit",
      editTaskData: task,
      showOtherTaskModal: true
    });
  };

  removeSelectedTask = () => {
    this.setState({
      selectedReturnTypes: null,
      selectedClients: [],
      taskDueDate: "",
      selectedPeriod: null,
      selectedOtherPeriod: null
    });
  };

  onOtherPeriodChange = (item: any) => {
    let period = getTaskPeriod(item.name.toLowerCase(), "", "", "");
    this.setState({
      selectedOtherPeriod: item,
      period,
      selectedPeriod: null
    });
  };

  onDescriptionChange = (editedValue: any) => {
    this.setState({ description: editedValue });
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard>
        {this.state.showOtherTaskModal && (
          <AddEditOtherTask
            type={this.state.otherTaskModalType}
            editTaskData={this.state.editTaskData}
            taskName={this.state.customTaskName}
            showModal={this.state.showOtherTaskModal}
            closeModal={this.showOtherTaskModal}
            onLoad={this.getOtherTaskTypes}
          />
        )}
        {this.state.showDeleteModal && (
          <DeleteModal
            type={"otherTask"}
            state={this.state}
            deleteModalSetOpen={this.deleteModalSetOpen}
            onLoad={() => {
              this.getOtherTaskTypes();
              this.removeSelectedTask();
            }}
          />
        )}
        {this.state.showQrmpModal && (
          <MessageModal
            show={this.state.showQrmpModal}
            handleConfirm={this.openQrmpPage}
            confirmText="Update QRMP"
            handleClose={this.handleCloseQrmpModal}
            closeText="Cancel"
            data={{
              title: "QRMP",
              message: `There are ${this.state.clientsWithoutQrmp} clients without QRMP status. Do you want to update QRMP status for these?`,
              type: "info"
            }}
          />
        )}
        <div className="w-full mx-auto px-4 sm:px-6 md:px-8 gstadd">
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form onSubmit={e => e.preventDefault()}>
              <div className="shadow sm:rounded-md">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Add Task
                  </h3>

                  <div className="space-y-6 sm:space-y-5">
                    {/* <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="gst-username"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Quick Task
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                        <div>
                          <MultiSelect
                            items={this.state.tasks.map((task: any) => {
                              return {
                                ...task,
                                _id: task._id,
                                name: task.name,
                              };
                            })}
                            selected={{
                              name: this.state.quickTask?.name,
                            }}
                            type="tasks"
                            onChange={this.onTaskChange}
                            placeholder="Select Task"
                          />
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="relative sm:pt-3 pt-2">
                      <div className="border-t border-gray-200"></div>
                      <div className="absolute -translate-y-1/2 left-1/2 text-xs font-medium bg-white px-1 text-gray-600">
                        Or
                      </div>
                    </div> */}

                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
                      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Create Task
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <fieldset className="mt-2">
                          <legend className="sr-only">Create Task</legend>
                          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <div className="flex items-center">
                              <input
                                id={"gst"}
                                name="gst"
                                type="radio"
                                value={"GST"}
                                checked={this.state.createTask === "GST"}
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                                onChange={this.updateState("createTask")}
                              />
                              <label
                                htmlFor={"gst"}
                                className="ml-3 block text-sm font-medium text-gray-700 cursor-pointer"
                              >
                                GST
                              </label>
                            </div>
                            <div className="flex items-center">
                              <input
                                id={"incomeTax"}
                                name="incomeTax"
                                type="radio"
                                value={"IT"}
                                checked={this.state.createTask === "IT"}
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                                onChange={this.updateState("createTask")}
                              />
                              <label
                                htmlFor={"incomeTax"}
                                className="ml-3 block text-sm font-medium text-gray-700 cursor-pointer"
                              >
                                Income Tax
                              </label>
                            </div>
                            <div className="flex items-center">
                              <input
                                id={"other"}
                                name="other"
                                type="radio"
                                value={"other"}
                                checked={this.state.createTask === "other"}
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                                onChange={this.updateState("createTask")}
                              />
                              <label
                                htmlFor={"other"}
                                className="ml-3 block text-sm font-medium text-gray-700 cursor-pointer"
                              >
                                Other
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>

                    {this.state.createTask !== "" && (
                      <>
                        {this.state.returnLoading ? (
                          <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                            <Icon
                              name="loading"
                              className="text-indigo-600 mx-auto"
                            />
                          </div>
                        ) : (
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="gst-username"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              {this.state.createTask === "other"
                                ? "Other Task Name"
                                : "Return Type"}
                              <span className="text-red-600">*</span>
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0 w-full">
                              <div className="flex sm:items-center sm:flex-row flex-col gap-2">
                                <div className={"sm:w-80 md:max-w-xs w-full"}>
                                  <MultiSelect
                                    items={this.state.returnTypes.map(
                                      (returntype: any) => {
                                        return {
                                          ...returntype,
                                          _id: returntype._id,
                                          name: `${returntype.name}`
                                        };
                                      }
                                    )}
                                    selected={{
                                      name: this.state.selectedReturnTypes?.name
                                    }}
                                    type="return-type"
                                    onChange={this.onReturnTypeChange}
                                    placeholder={
                                      this.state.createTask === "other"
                                        ? "Select Other Task Name"
                                        : "Select Return Type"
                                    }
                                  />
                                </div>
                                {this.state.createTask === "other" &&
                                  this.state.selectedReturnTypes !== null && (
                                    <div className="flex items-center gap-6">
                                      <button
                                        className="relative inline-flex gap-x-2 items-center justify-center whitespace-nowrap rounded border border-transparent bg-red-500 px-2.5 py-2 text-xs font-medium text-white hover:bg-red-600 focus:outline-none"
                                        onClick={this.removeSelectedTask}
                                      >
                                        <Icon
                                          name="delete"
                                          className={`h-4 w-4`}
                                          aria-hidden="true"
                                        />
                                        <span>Remove Task</span>
                                      </button>
                                      <Menu
                                        as="div"
                                        className="inline-block relative"
                                      >
                                        <Menu.Button
                                          onClick={this.onOptionsDropdownClick}
                                          className="relative inline-flex gap-x-2 items-center justify-between px-2 py-2 sm:py-2 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none disabled:bg-indigo-600 w-fit sm:pl-4 sm:pr-2"
                                        >
                                          <Icon
                                            name="outline/settings"
                                            className={`h-4 w-4`}
                                            aria-hidden="true"
                                          />
                                          <span>Options</span>
                                          <Icon
                                            name="dropdown-arrow"
                                            className={`h-4 w-4 ${
                                              this.state.rotate
                                                ? "rotate-90"
                                                : ""
                                            }`}
                                            aria-hidden="true"
                                          />
                                        </Menu.Button>
                                        {this.state.showBackDrop && (
                                          <div
                                            className="fixed top-0 left-0 z-10 w-full h-screen"
                                            onClick={
                                              this.onOptionsDropdownClick
                                            }
                                          ></div>
                                        )}
                                        <Transition
                                          as={Fragment}
                                          enter="transition ease-out duration-100"
                                          enterFrom="transform opacity-0 scale-95"
                                          enterTo="transform opacity-100 scale-100"
                                          leave="transition ease-in duration-75"
                                          leaveFrom="transform opacity-100 scale-100"
                                          leaveTo="transform opacity-0 scale-95"
                                        >
                                          <div className="absolute z-[100] right-0">
                                            <Menu.Items className="overscroll-none mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                              <div className="py-1">
                                                <Menu.Item as="div">
                                                  <Button
                                                    name="Edit Task"
                                                    onClick={() => {
                                                      this.onOptionsDropdownClick();
                                                      this.editSelectedTask(
                                                        this.state
                                                          .selectedReturnTypes
                                                      );
                                                    }}
                                                    icon="edit"
                                                    className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                                                  />
                                                </Menu.Item>
                                                <Menu.Item as="div">
                                                  <Button
                                                    name="Delete Task"
                                                    className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900 disabled:cursor-not-allowed"
                                                    onClick={() => {
                                                      this.onOptionsDropdownClick();
                                                      this.openDeleteModal(
                                                        this.state
                                                          .selectedReturnTypes
                                                      );
                                                    }}
                                                    icon="delete"
                                                  />
                                                </Menu.Item>
                                              </div>
                                            </Menu.Items>
                                          </div>
                                        </Transition>
                                      </Menu>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    {this.state.createTask === "other" && (
                      <>
                        <p className="text-sm text-center text-gray-700 font-bold">
                          OR
                        </p>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
                          <label
                            htmlFor="gst-username"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Create Other Task
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0 w-full">
                            <div className="flex sm:flex-row flex-col gap-2">
                              <div className={"sm:w-80 md:max-w-xs w-full"}>
                                <input
                                  id="name"
                                  name="name"
                                  type="text"
                                  value={this.state.customTaskName}
                                  disabled={
                                    this.state.selectedReturnTypes !== null
                                  }
                                  placeholder="Enter Task Here"
                                  className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 text-xs sm:text-sm ${
                                    this.state.selectedReturnTypes !== null
                                      ? "opacity-70 cursor-not-allowed"
                                      : ""
                                  }`}
                                  onChange={this.updateState("customTaskName")}
                                />
                              </div>

                              {this.state.customTaskName !== "" && (
                                <button
                                  type="button"
                                  className="inline-flex items-center justify-center whitespace-nowrap rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:bg-indigo-200"
                                  onClick={() => this.showOtherTaskModal(true)}
                                >
                                  <Icon name="add" className="h-3 w-3 mr-1" />
                                  Other Task
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {(this.state.selectedReturnTypes !== null ||
                      this.state.createTask === "other") && (
                      <>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="gst-username"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Period <span className="text-red-600">*</span>
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0 w-full">
                            <div className="flex flex-col sm:flex-row gap-2">
                              {this.state.createTask === "other" && (
                                <div className="sm:w-80 md:max-w-xs w-full">
                                  <MultiSelect
                                    items={periodType?.map((item: any) => {
                                      return {
                                        _id: item,
                                        name: item
                                      };
                                    })}
                                    selected={{
                                      name: this.state.selectedOtherPeriod?.name
                                    }}
                                    type="period"
                                    onChange={this.onOtherPeriodChange}
                                    placeholder="Select Period Type"
                                    disabled={
                                      this.state.selectedReturnTypes !== null
                                    }
                                  />
                                </div>
                              )}
                              {this.state.selectedOtherPeriod?.name !==
                                "One Time" && (
                                <div className="sm:w-80 md:max-w-xs w-full">
                                  <MultiSelect
                                    items={this.state.period?.map(
                                      (item: any) => {
                                        return {
                                          ...item,
                                          _id: item._id,
                                          name: item.name
                                        };
                                      }
                                    )}
                                    selected={{
                                      name: this.state.selectedPeriod?.name
                                    }}
                                    type="period"
                                    onChange={this.onPeriodChange}
                                    placeholder="Select Period"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {this.state.createTask !== "GST" && (
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="gst-username"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Due Date <span className="text-red-600">*</span>
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                              <div>
                                <input
                                  type="date"
                                  name="other_due_date"
                                  max={maxAllowedDate}
                                  value={this.state.taskDueDate}
                                  onChange={this.updateState("taskDueDate")}
                                  id="other_due_date"
                                  className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                                  placeholder="Othe Task Due Date"
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        {this.state.createTask === "other" && (
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="gst-username"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Description
                            </label>
                            <div className="mt-1 mb-6 sm:col-span-2 sm:mt-0 w-full">
                              <ReactQuill
                                theme="snow"
                                modules={modules}
                                value={this.state.description}
                                onChange={this.onDescriptionChange}
                                placeholder={"Add Description..."}
                                className="h-32 mb-10"
                              />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {(this.state.selectedPeriod !== null ||
                    this.state.createTask === "other") && (
                    <>
                      <div
                        className={
                          "sm:border-t mt-2 sm:border-gray-200 max-w-full mx-auto"
                        }
                      >
                        <div className="flex items-center justify-between mt-6">
                          <p className="block text-sm font-medium text-gray-700">
                            Clients
                          </p>
                          {(this.state.totalRecords > 0 ||
                            this.state.searchText.length > 0) &&
                            !this.state.showFilter && (
                              <div className="w-72">
                                <input
                                  id="name"
                                  name="name"
                                  type="text"
                                  value={this.state.searchText}
                                  placeholder="Search by File No, Client or Trade Name"
                                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 text-xs sm:text-sm"
                                  onChange={this.handleSearchTextChange}
                                />
                              </div>
                            )}
                          <div>
                            <button
                              type="button"
                              className="text-sm text-gray-600 hover:text-indigo-700 underline"
                              onClick={() =>
                                this.toggleFilter(!this.state.showFilter)
                              }
                            >
                              Filter Clients
                            </button>
                          </div>
                        </div>

                        {this.state.showFilter && (
                          <div className="max-w-full mx-auto mt-4 lg:mx-8 shadow bg-white border px-6 pb-6 mb-10">
                            <h4 className="block text-lg font-medium text-gray-800 py-3">
                              Filter Clients
                            </h4>
                            <div className="space-y-6 sm:space-y-5">
                              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                <label
                                  htmlFor="user"
                                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                  Filter by Clients Type
                                </label>
                                <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                                  <MultiSelect
                                    items={filterList
                                      .initialList(this.state.createTask)
                                      ?.map((item: any, index: number) => {
                                        return {
                                          _id: item,
                                          name: item
                                        };
                                      })}
                                    selected={{
                                      name: this.state.initalFilterSelect?.name
                                    }}
                                    type="filterclients"
                                    onChange={this.onFilterChange}
                                    placeholder="Select Type of Clients"
                                  />
                                </div>
                              </div>
                              {this.state.initalFilterSelect?.name ===
                                "GST" && (
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                  <label
                                    htmlFor="user"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                  >
                                    Filter by GST Type
                                  </label>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                                    <MultiSelect
                                      items={filterList.gstSlectionList?.map(
                                        (item: any, index: number) => {
                                          return {
                                            _id: `${item}-${index}`,
                                            name: item
                                          };
                                        }
                                      )}
                                      selected={{
                                        name: this.state.gstFilterSelect?.name
                                      }}
                                      type="filterclients"
                                      onChange={this.onGstFilterChange}
                                      placeholder="Select GST Type"
                                    />
                                  </div>
                                </div>
                              )}
                              {this.state.initalFilterSelect?.name ===
                                "Income Tax" && (
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                  <label
                                    htmlFor="user"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                  >
                                    Filter by Income Tax Type
                                  </label>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                                    <MultiSelect
                                      items={filterList.incomeTaxSelectionList?.map(
                                        (item: any, index: number) => {
                                          return {
                                            _id: `${item}-${index}`,
                                            name: item
                                          };
                                        }
                                      )}
                                      selected={{
                                        name: this.state.incomeTaxFilterSelect
                                          ?.name
                                      }}
                                      type="filterClients"
                                      onChange={this.onIncomeTaxFilterChange}
                                      placeholder="Select Income Tax Type"
                                    />
                                  </div>
                                </div>
                              )}

                              {this.state.incomeTaxFilterSelect?.name ===
                                "Returns You File in IT" && (
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                  <label
                                    htmlFor="user"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                  >
                                    Filter by Returns You File In IT
                                  </label>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                                    <MultiSelect
                                      items={returnFileList.map(
                                        (item: any, index: number) => {
                                          return {
                                            _id: `${item}-${index}`,
                                            name: item
                                          };
                                        }
                                      )}
                                      selected={{
                                        name: this.state.itrFileReturnSelect
                                          ?.name
                                      }}
                                      type="itrfiles"
                                      onChange={this.onItrRuturnFilterChange}
                                      placeholder="Select ITR Return File"
                                    />
                                  </div>
                                </div>
                              )}

                              {this.state.initalFilterSelect?.name ===
                                "Ownership Type" && (
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                  <label
                                    htmlFor="user"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                  >
                                    Filter by Ownership Type
                                  </label>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                                    <MultiSelect
                                      items={clientTypesList.map(
                                        (item: any, index: number) => {
                                          return {
                                            _id: `${item}-${index}`,
                                            name: item
                                          };
                                        }
                                      )}
                                      selected={{
                                        name: this.state.ownershipFilterSelect
                                          ?.name
                                      }}
                                      type="filterClients"
                                      onChange={this.onOwnershipFilterChange}
                                      placeholder="Select Ownership Type"
                                    />
                                  </div>
                                </div>
                              )}

                              {this.state.initalFilterSelect?.name ===
                                "Client Group" && (
                                <>
                                  {this.state.groupLoading ? (
                                    <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                                      <Icon
                                        name="loading"
                                        className="text-indigo-600 mx-auto"
                                      />
                                    </div>
                                  ) : (
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                      <label
                                        htmlFor="user"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                      >
                                        Filter by Client Group
                                      </label>
                                      <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                                        <MultiSelect
                                          items={this.state.groupFilterList?.map(
                                            (item: any) => {
                                              return {
                                                _id: item._id,
                                                name: item.name
                                              };
                                            }
                                          )}
                                          selected={{
                                            name: this.state
                                              .clientGroupFilterSelect?.name
                                          }}
                                          type="filterClients"
                                          onChange={
                                            this.onClientGroupFilterChange
                                          }
                                          placeholder="Select Client Group"
                                        />
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}

                              {this.state.initalFilterSelect?.name ===
                                "Tags" && (
                                <>
                                  {this.state.tagLoading ? (
                                    <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                                      <Icon
                                        name="loading"
                                        className="text-indigo-600 mx-auto"
                                      />
                                    </div>
                                  ) : (
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                      <label
                                        htmlFor="user"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                      >
                                        Filter by Tags
                                      </label>
                                      <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                                        <MultiSelect
                                          items={(this.props as any)?.tags?.map(
                                            (item: any) => {
                                              return {
                                                ...item,
                                                _id: item._id,
                                                name: item.name
                                              };
                                            }
                                          )}
                                          selected={{
                                            name: this.state.tagsFilterSelect
                                              ?.name
                                          }}
                                          type="filterClients"
                                          onChange={this.onTagsFilterChange}
                                          placeholder="Select Tags"
                                        />
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}

                              {this.state.gstFilterSelect?.name ===
                                "GST Registration State" && (
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                  <label
                                    htmlFor="user"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                  >
                                    Filter by GST Registration State
                                  </label>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                                    <MultiSelect
                                      items={filterList.stateList.map(
                                        (item: any, index: number) => {
                                          return {
                                            _id: `${item}-${index}`,
                                            name: item
                                          };
                                        }
                                      )}
                                      selected={{
                                        name: this.state
                                          .registrationStateFilterSelect?.name
                                      }}
                                      type="filterClients"
                                      onChange={
                                        this.onRegistrationStateFilterChange
                                      }
                                      placeholder="Select GST Registration State"
                                    />
                                  </div>
                                </div>
                              )}

                              {this.state.gstFilterSelect?.name ===
                                "GSTIN Status" && (
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                  <label
                                    htmlFor="user"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                  >
                                    Filter by GST Status
                                  </label>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                                    <fieldset className="mt-2">
                                      <legend className="sr-only">
                                        Create Task
                                      </legend>
                                      <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                        <div className="flex items-center">
                                          <input
                                            id={"active"}
                                            name="active"
                                            type="radio"
                                            value={"Active"}
                                            checked={
                                              this.state.filterGstStatus ===
                                              "Active"
                                            }
                                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                                            onChange={this.onGstStatusChange}
                                          />
                                          <label
                                            htmlFor={"active"}
                                            className="ml-3 block text-sm font-medium text-gray-700 cursor-pointer"
                                          >
                                            Active
                                          </label>
                                        </div>
                                        <div className="flex items-center">
                                          <input
                                            id={"inactive"}
                                            name="inactive"
                                            type="radio"
                                            value={"Inactive"}
                                            checked={
                                              this.state.filterGstStatus ===
                                              "Inactive"
                                            }
                                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                                            onChange={this.onGstStatusChange}
                                          />
                                          <label
                                            htmlFor={"inactive"}
                                            className="ml-3 block text-sm font-medium text-gray-700 cursor-pointer"
                                          >
                                            Inactive
                                          </label>
                                        </div>
                                      </div>
                                    </fieldset>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="pt-6 flex justify-end">
                              <button
                                type="button"
                                className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:w-32 sm:text-sm"
                                onClick={() => this.toggleFilter(false)}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className={
                                  "mt-3 sm:ml-4 w-full inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:mt-0 sm:w-32 sm:text-sm"
                                }
                                onClick={this.resetFilter}
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        )}

                        {!this.state.clientLoading &&
                        this.state.displayClientsDetails ? (
                          <>
                            <div className="mt-6 flex flex-col">
                              <div id="table-scroll" className="overflow-auto">
                                <div className="inline-block min-w-full py-2 px-1 align-middle">
                                  <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                                    <table className="min-w-full border-collapse border shadow-sm">
                                      <thead className="bg-gray-50">
                                        <tr>
                                          <th
                                            style={{ zIndex: 8 }}
                                            scope="col"
                                            className="sticky top-0 border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6"
                                          >
                                            <div className="flex items-center gap-4">
                                              <div>
                                                <input
                                                  type="checkbox"
                                                  className="h-4 w-4 rounded border-gray-400 text-indigo-600 focus:ring-indigo-500"
                                                  checked={
                                                    this.state
                                                      .displayClientsDetails
                                                      .length > 0 &&
                                                    this.state
                                                      .displayClientsDetails
                                                      .length ===
                                                      this.state.selectedClients
                                                        .length
                                                  }
                                                  onChange={
                                                    this.onSelectAllClients
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </th>
                                          <th
                                            style={{ zIndex: 8 }}
                                            scope="col"
                                            className="sticky top-0 border-b whitespace-nowrap border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6"
                                          >
                                            FILE No.
                                          </th>
                                          <th
                                            style={{ zIndex: 8 }}
                                            scope="col"
                                            className="sticky top-0 border-b whitespace-nowrap border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6"
                                          >
                                            CLIENT NAME
                                          </th>
                                          <th
                                            style={{ zIndex: 8 }}
                                            scope="col"
                                            className="sticky top-0 whitespace-nowrap border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6"
                                          >
                                            TRADE NAME
                                          </th>
                                          <th
                                            style={{ zIndex: 8 }}
                                            scope="col"
                                            className="sticky top-0 whitespace-nowrap border-b border-gray-300 bg-gray-50 px-4 py-3 text-start text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6"
                                          >
                                            OTHER USERS
                                          </th>
                                          {this.state.createTask === "GST" && (
                                            <th
                                              style={{ zIndex: 8 }}
                                              scope="col"
                                              className="sticky top-0 whitespace-nowrap border-b border-gray-300 bg-gray-50 px-4 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6"
                                            >
                                              DUE DATE
                                            </th>
                                          )}
                                        </tr>
                                      </thead>
                                      {this.state.totalRecords === 0 ? (
                                        <tbody>
                                          <tr>
                                            <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                                              No Clients Found
                                            </td>
                                          </tr>
                                        </tbody>
                                      ) : (
                                        <tbody className="bg-white">
                                          {this.state.displayClientsDetails?.map(
                                            (client, index) => (
                                              <tr
                                                key={client._id}
                                                className={
                                                  index % 2 === 0
                                                    ? undefined
                                                    : "bg-gray-100"
                                                }
                                              >
                                                <td className="w-1/12 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6">
                                                  <div className="flex items-center gap-3">
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        className="h-4 w-4 rounded border-gray-400 text-indigo-600 focus:ring-indigo-500"
                                                        checked={this.state.selectedClients.some(
                                                          (item: any) =>
                                                            item._id ===
                                                            client._id
                                                        )}
                                                        onChange={() =>
                                                          this.onClientChange(
                                                            client
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                    <div className="h-5 flex gap-x-2 items-center">
                                                      <span className="relative isolate grid place-items-center">
                                                        <input
                                                          type="checkbox"
                                                          name="star"
                                                          id="star"
                                                          checked={client?.star}
                                                          onChange={() =>
                                                            this.onStarClick(
                                                              client
                                                            )
                                                          }
                                                          className="row-span-full col-span-full rounded-full border-transparent z-10 focus:ring-2 focus:ring-yellow-500 cursor-pointer peer !bg-transparent !bg-gradient-to-r from-transparent to-transparent"
                                                        />
                                                        <Icon
                                                          name="outline/star"
                                                          className={`row-span-full col-span-full h-4 w-4 ${
                                                            client?.star
                                                              ? "fill-yellow-500 stroke-yellow-500"
                                                              : "fill-none stroke-gray-500"
                                                          }`}
                                                        />
                                                      </span>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td className="w-2/12 px-6 py-3 whitespace-nowrap text-sm text-gray-500 relative">
                                                  {client?.fileNo
                                                    ? client?.fileNo
                                                    : "-"}
                                                </td>
                                                <td className="w-4/12 max-w-[2rem] px-6 py-3 text-sm font-bold text-gray-900 relative">
                                                  <Popup
                                                    className="w-full max-w-fit cursor-default"
                                                    content={client.name}
                                                  >
                                                    <p className="truncate">
                                                      {client.name ?? "-"}
                                                    </p>
                                                  </Popup>
                                                </td>
                                                <td className="w-4/12 max-w-[2rem] px-6 py-3 text-sm text-gray-500 relative">
                                                  <Popup
                                                    className="w-full max-w-fit cursor-default"
                                                    content={client.tradeName}
                                                  >
                                                    <p className="truncate">
                                                      {client.tradeName}
                                                    </p>
                                                  </Popup>
                                                </td>
                                                <td className="w-4/12 px-6 py-3 text-sm font-bold text-gray-900 relative">
                                                  {this.state
                                                    .showTableUserDropdown ===
                                                  client._id ? (
                                                    this.props.users && (
                                                      <div className="w-36">
                                                        <TableMultiSelectCheckbox
                                                          items={this.props.users?.map(
                                                            (user: any) => {
                                                              return {
                                                                _id: user._id,
                                                                userId:
                                                                  user._id,
                                                                name: user.name,
                                                                shortname:
                                                                  user.shortname
                                                              };
                                                            }
                                                          )}
                                                          selected={
                                                            this.userList(
                                                              client.defUsers
                                                            ).allUsers
                                                          }
                                                          type="users"
                                                          onChange={
                                                            this
                                                              .onTableUserChange
                                                          }
                                                          placeholder="Select Users"
                                                          onBackDropClick={
                                                            this
                                                              .onTableCloseDropdown
                                                          }
                                                        />
                                                      </div>
                                                    )
                                                  ) : (
                                                    <div className="flex items-start">
                                                      <span className="flex items-center gap-2">
                                                        {this.userList(
                                                          client.defUsers
                                                        ).slicedUsers.map(
                                                          (
                                                            user: any,
                                                            index: number
                                                          ) => (
                                                            <Popup
                                                              key={index}
                                                              content={
                                                                user.name
                                                              }
                                                            >
                                                              <button
                                                                className="w-8 h-8 rounded-full bg-gray-700 text-white"
                                                                onClick={() =>
                                                                  this.onTableUserClick(
                                                                    client._id
                                                                  )
                                                                }
                                                              >
                                                                {user.shortname}
                                                              </button>
                                                            </Popup>
                                                          )
                                                        )}
                                                      </span>

                                                      <span>
                                                        {client.defUsers
                                                          .length > 3
                                                          ? `+ ${
                                                              client.defUsers
                                                                .length - 3
                                                            }`
                                                          : ""}
                                                      </span>
                                                    </div>
                                                  )}
                                                </td>
                                                {this.state.createTask ===
                                                  "GST" && (
                                                  <td className="w-6/12 px-6 py-3 text-sm text-gray-500 relative">
                                                    <div>
                                                      <input
                                                        type="date"
                                                        name="dueDate"
                                                        id="clientDueDate"
                                                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                                        max={maxAllowedDate}
                                                        value={client.dueDate}
                                                        onChange={this.changeClientDate(
                                                          client
                                                        )}
                                                      />
                                                    </div>
                                                  </td>
                                                )}
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      )}
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className={"max-w-full mx-auto"}>
                            <div className="py-6">
                              <div className="flex flex-col">
                                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                      <table className="overflow-scroll table-auto w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                          <tr>
                                            <th
                                              scope="col"
                                              className="w-2/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                              <div className="flex items-center gap-4">
                                                <div>
                                                  <input
                                                    type="checkbox"
                                                    className="h-4 w-4 rounded border-gray-400 text-indigo-600 focus:ring-indigo-500"
                                                    disabled
                                                  />
                                                </div>
                                                <div></div>
                                              </div>
                                            </th>
                                            <th
                                              scope="col"
                                              className="w-2/12 whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                              FILE No.
                                            </th>
                                            <th
                                              scope="col"
                                              className="w-2/12 whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                              CLIENT NAME
                                            </th>
                                            <th
                                              scope="col"
                                              className="w-2/12 whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                              TRADE NAME
                                            </th>
                                            <th
                                              scope="col"
                                              className="w-2/12 whitespace-nowrap px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                              OTHER USER
                                            </th>
                                            {this.state.createTask ===
                                              "GST" && (
                                              <th
                                                scope="col"
                                                className="w-2/12 whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                              >
                                                DUE DATE
                                              </th>
                                            )}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {[...Array(6)].map((e, i) => (
                                            <tr key={i} className="bg-white">
                                              {[...Array(5)].map((e, i) => (
                                                <td
                                                  key={i}
                                                  className="w-3/10 px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                                >
                                                  <Skeleton />
                                                </td>
                                              ))}
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {this.state.totalRecords > 0 ? (
                        <Pagination
                          displayRecords={this.state.displayClientsDetails}
                          totalRecords={this.state.totalRecords}
                          currPage={this.state.currPage}
                          chunkSize={this.state.chunkSize}
                          handlePageClick={this.handlePageClick}
                          handleItemPerPage={this.handleItemPerPage}
                          className="my-4"
                        />
                      ) : null}
                    </>
                  )}
                </div>

                <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:justify-end">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:w-32 sm:text-sm"
                    onClick={this.cancelHandler}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    disabled={
                      this.state.logging ||
                      this.state.createTask === "" ||
                      this.state.selectedClients.length === 0
                    }
                    className="mt-3 sm:ml-4 w-full inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:mt-0 sm:w-40 sm:text-sm disabled:opacity-70 disabled:cursor-not-allowed"
                    onClick={this.addTask}
                  >
                    <span className="w-full flex justify-end">
                      {this.state.logging ? (
                        <Icon name="loading" className="mr-2" />
                      ) : null}
                    </span>
                    <span className="whitespace-nowrap">
                      Create {this.state.selectedClients.length || ""} Task
                    </span>
                    <span className="w-full"></span>
                  </button>
                </div>
                <div className="text-sm px-4 sm:px-6 py-2 text-center">
                  Note:- Duplicate tasks (the same task for the same period)
                  will not get created again for the clients and will be ignored
                  by the system
                </div>
              </div>
            </form>
          </div>
        </div>
      </Dashboard>
    );
  }
}

export default compose(connector, withRouter)(AddTask) as React.ComponentType;
