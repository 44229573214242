import { ChangeEvent, Component, ComponentType } from "react";
//Redux
import { connect, ConnectedProps } from "react-redux";
import { compose } from "redux";
import { ADD_NOTIFICATION, LOGIN } from "../../store/types";

import agent from "../../agent";
import Icon from "../../components/Icon";
import { withRouter, WithRouterProps } from "../../helpers/withRouter";
import { AppDispatch, RootState } from "../../store";
import { NotifyType } from "../../store/reducers/notification";
import { AuthAction } from "../../store/reducers/userAuth";

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.notification
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addNotification: (title: string, message: string, type: NotifyType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } }),
  onLogin: (payload: AuthAction["payload"]) =>
    dispatch({ type: LOGIN, payload })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = Partial<PropsFromRedux & WithRouterProps> & {};

type State = {
  otp: string;
  logging: boolean;
};

//Main Class
class SignupOTP extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      otp: "",
      logging: false
    };
  }

  updateState = (field: "otp") => (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [field]: ev.target.value
    });
  };

  verify = () => {
    this.setState({ logging: true });
    const otp = parseInt(this.state.otp, 10);
    const { id } = this.props.params || {};

    if (!id) {
      this.props.addNotification?.(
        "Failed!",
        "Invalid verification link!",
        "danger"
      );
      return;
    }

    agent.Auth.verifySignupOTP(id, otp)
      .then((response: { success: boolean; loggedIn: boolean }) => {
        const { loggedIn } = response;

        this.setState({ logging: false });
        this.props.onLogin?.({ isAuthenticated: loggedIn });

        //Redirect to home page
        this.props.navigate?.("/");
        this.props.addNotification?.(
          "Successful",
          "Account verified successfully!",
          "success"
        );
      })
      .catch(err => {
        this.props.addNotification?.(
          "Failed!",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      })
      .finally(() => {
        this.setState({ logging: false });
      });
  };

  resendOTP = () => {
    const { id } = this.props.params || {};

    if (!id) {
      this.props.addNotification?.(
        "Failed!",
        "Invalid verification link!",
        "danger"
      );
      return;
    }

    this.setState({ logging: true });
    agent.Auth.resendOTP(id)
      .then(response => {
        this.props.addNotification?.(
          "Successful",
          "OTP has been resent successfully on your email address",
          "success"
        );
      })
      .catch(err => {
        this.props.addNotification?.(
          "Failed!",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      })
      .finally(() => {
        this.setState({ logging: false });
      });
  };

  render() {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="/images/Taxpido_logo_black.png"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Verify your account using OTP
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
            <div className="space-y-6">
              <div>
                <label
                  htmlFor="otp"
                  className="block text-sm font-medium text-gray-700"
                >
                  OTP
                </label>
                <div className="mt-1">
                  <input
                    id="otp"
                    name="otp"
                    type="otp"
                    value={this.state.otp}
                    onChange={this.updateState("otp")}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center"></div>

                <button
                  onClick={this.resendOTP}
                  className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                >
                  OTP not received? Click here to resend
                </button>
              </div>

              <div>
                <button
                  onClick={this.verify}
                  disabled={
                    this.state.logging === true
                      ? this.state.otp?.length > 6
                        ? false
                        : true
                      : false
                  }
                  className={
                    !this.state.logging
                      ? "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                      : "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-300 cursor-not-allowed"
                  }
                >
                  {this.state.logging ? <Icon name="loading" /> : null} Verify
                  using OTP
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose<ComponentType<Props>>(connector, withRouter)(SignupOTP);
