import { Component } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import ViewAllModal from "./ViewAllModal";
import { CurrentTab, UserRightsFilter, UserTypeFilter } from "./Index";

interface Props {
  state: {
    currentTab: CurrentTab;
    selectedUser: { _id: string; name: string };
    loading: boolean;
    statusWiseTaskReport: any[];
    workSpaceId?: string;
    userTypeFilter: UserTypeFilter;
    userRightsFilter: UserRightsFilter;
  };
  currentUser?: { _id: string; name: string };
  searchParams: (
    key: string,
    type?: string,
    filter?: string | undefined
  ) => "" | URLSearchParams;
}

interface State {
  showFilter: boolean;
  showViewAllModal: boolean;
}

class StatuswiseTaskSummary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showFilter: false,
      showViewAllModal: false
    };
  }

  showViewAllModal = () => {
    this.setState({ showViewAllModal: true });
  };

  render() {
    return (
      <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow hover:shadow-xl">
        <ViewAllModal
          selectedUser={this.props.state.selectedUser}
          workSpaceId={this.props.state.workSpaceId}
          currentTab={this.props.state.currentTab}
          searchParams={this.props.searchParams}
          showViewAllModal={this.state.showViewAllModal}
          userTypeFilter={this.props.state.userTypeFilter}
          userRightsFilter={this.props.state.userRightsFilter}
          currentUser={this.props.currentUser}
          reportType="statusWiseTaskReport"
          reportData={this.props.state.statusWiseTaskReport}
          onClose={() => this.setState({ showViewAllModal: false })}
        />
        <header className="py-3 px-4 md:px-6">
          <h3 className="text-base leading-6 font-semibold text-gray-900 capitalize">
            {this.props.state.currentTab} Task Summary - Statuswise{" "}
            <span className="text-sm font-normal capitalize">
              (for all periods)
            </span>
          </h3>
        </header>
        <div className="p-4">
          <div className="bg-white shadow rounded-md sm:overflow-hidden">
            <div className="flex flex-col">
              <div id="tab-scroll" className="overflow-x-auto">
                <div className="inline-block min-w-full align-middle">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className={`px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider text-left`}
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className={`px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider text-center`}
                          >
                            Total Tasks
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {!this.props.state.loading ? (
                          this.props.state.statusWiseTaskReport.length > 0 ? (
                            <>
                              {this.props.state.statusWiseTaskReport
                                .slice(0, 5)
                                .map((record: any, index: number) => (
                                  <tr
                                    key={`statusWiseTaskReport-${record.statusName}`}
                                    className={
                                      index % 2 === 0
                                        ? "bg-white"
                                        : "bg-gray-50"
                                    }
                                  >
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize">
                                      {record.statusName}
                                    </td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize text-center">
                                      <Link
                                        className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                                        to={`/${
                                          this.props.state.workSpaceId
                                        }/tasks/list?${
                                          this.props.state.currentTab ===
                                          "Common"
                                            ? ""
                                            : this.props.searchParams(
                                                "type",
                                                this.props.state.currentTab
                                              )
                                        }&${this.props.searchParams(
                                          "status",
                                          record.statusName
                                        )}${
                                          this.props.state.userTypeFilter ===
                                            "otherUser" ||
                                          this.props.state.userTypeFilter ===
                                            "any"
                                            ? `&${this.props.searchParams(
                                                "otherUser",
                                                this.props.state
                                                  .userRightsFilter ===
                                                  "employee"
                                                  ? this.props.currentUser?._id
                                                  : this.props.state
                                                      .selectedUser._id
                                              )}`
                                            : this.props.state
                                                .userRightsFilter ===
                                                "employee" &&
                                              this.props.state
                                                .userTypeFilter ===
                                                "currentUser"
                                            ? `&${this.props.searchParams(
                                                "user",
                                                this.props.currentUser?._id
                                              )}`
                                            : `&${this.props.searchParams(
                                                "user",
                                                this.props.state.selectedUser
                                                  ._id
                                              )}`
                                        }`}
                                      >
                                        {record.count}
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                              <tr className="bg-white">
                                <td className="px-3 py-2 whitespace-nowrap text-gray-900 font-medium text-sm capitalize">
                                  Total
                                </td>
                                <td className="px-3 py-2 whitespace-nowrap text-gray-900 font-semibold text-sm capitalize text-center">
                                  {this.props.state.statusWiseTaskReport.reduce(
                                    (total: number, record: any) =>
                                      total + record.count,
                                    0
                                  )}
                                </td>
                              </tr>
                              {this.props.state.statusWiseTaskReport.length >
                                5 && (
                                <tr className="bg-white">
                                  <td
                                    colSpan={2}
                                    className="px-3 py-2 whitespace-nowrap text-gray-900 font-semibold capitalize text-center"
                                  >
                                    <button
                                      className="text-sm inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 font-medium text-indigo-700 hover:bg-indigo-200 focus:bg-indigo-200 focus:outline-none"
                                      onClick={this.showViewAllModal}
                                    >
                                      View All
                                    </button>
                                  </td>
                                </tr>
                              )}
                            </>
                          ) : (
                            <tr className="bg-white">
                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize">
                                No Record Found
                              </td>
                            </tr>
                          )
                        ) : (
                          [...Array(5)].map((e, i) => (
                            <tr
                              key={`tr-statusWiseTaskReport-${i}`}
                              className="bg-white"
                            >
                              {[...Array(2)].map((e, i) => (
                                <td
                                  key={`td-statusWiseTaskReport-${i}`}
                                  className="px-2 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                >
                                  <Skeleton />
                                </td>
                              ))}
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StatuswiseTaskSummary;
