import React, { Fragment, Component } from "react";
import { Menu, Transition } from "@headlessui/react";
import { connect, ConnectedProps } from "react-redux";
import agent from "../../agent";
import Icon from "../../components/Icon";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import Dashboard from "../../components/Dashboard";
import Pagination from "../../components/Pagination";
import AddModal from "./AddModal";
import Skeleton from "react-loading-skeleton";
import ActiveModal from "../../components/ActiveModal";
import DeleteModal from "../../components/DeleteModal";
import InActiveModal from "../../components/InActiveModal";
import { withRouter } from "../../helpers/withRouter";
import { compose } from "redux";
import Popup from "../../components/Popup";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Receipt Payment Source List"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common,
  ...state.notification
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: (payload: any) => dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class Source extends Component<PropsFromRedux> {
  state: {
    loading: boolean;
    posX: any;
    posY: any;
    hoverX: any;
    hoverY: any;
    showBackDrop: boolean;
    active: boolean;
    workSpaceId: string;
    sourceList: any[];
    searchText: string;
    totalRecords: number;
    typingTimeout: number;
    selectedRow: any;
    modalOpen: boolean;
    modalType: string;
    showAddModal: boolean;
    showDeleteModal: boolean;
    showActiveModal: boolean;
    showInActiveModal: boolean;
    showEditModal: boolean;
    currPage: number;
    chunkSize: number;
  };

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      posX: null,
      posY: null,
      hoverX: null,
      hoverY: null,
      showBackDrop: false,
      active: true,
      workSpaceId: (this.props as any)?.params.firmId,
      sourceList: [],
      searchText: "",
      totalRecords: 0,
      typingTimeout: 0,
      selectedRow: null,
      modalOpen: false,
      modalType: "",
      showAddModal: false,
      showDeleteModal: false,
      showActiveModal: false,
      showInActiveModal: false,
      showEditModal: false,
      currPage: 0,
      chunkSize: 25
    };
  }

  headers = ["name", "description", "action"];

  openAddModal = (type: string) => {
    this.setState({ showAddModal: true, modalType: type, showBackDrop: false });
  };

  closeAddModal = () => {
    this.setState({ showAddModal: false, modalType: "" });
  };

  onActionClick = (e: any) => {
    const screenClosness = window.innerHeight - e.clientY;
    const positionY = screenClosness < 125 ? e.clientY - 125 : e.clientY;
    this.setState({
      posX: e.clientX,
      posY: positionY,
      showBackDrop: true
    });
  };

  onDropdownClick = () => {
    this.setState({ showBackDrop: false });
  };

  updateActive = () => {
    this.setState({ active: !this.state.active, searchText: "", currPage: 0 });
  };

  openActiveModal = (source: any) => {
    this.setState({ selectedRow: source, showBackDrop: false });
    this.activeModalSetOpen(true);
  };

  activeModalSetOpen = (open: boolean) => {
    this.setState({
      showActiveModal: open
    });
  };

  openInActiveModal = (source: any) => {
    this.setState({ selectedRow: source, showBackDrop: false });
    this.inActiveModalSetOpen(true);
  };

  inActiveModalSetOpen = (open: boolean) => {
    this.setState({
      showInActiveModal: open
    });
  };

  openDeleteModal = (source: any) => {
    this.setState({ selectedRow: source, showBackDrop: false });
    this.deleteModalSetOpen(true);
  };

  deleteModalSetOpen = (open: boolean) => {
    this.setState({
      showDeleteModal: open
    });
  };

  openEditModal = (source: any) => {
    this.setState({ selectedRow: source, showBackDrop: false });
    this.editModalSetOpen(true);
    // console.log("edit modal open", source);
  };

  editModalSetOpen = (open: boolean) => {
    this.setState({
      showEditModal: open
    });
  };

  handlePageClick = (data: any) => {
    this.setState({ currPage: data.selected });
  };

  handleItemPerPage = (value: any) => {
    this.setState({ chunkSize: value.name, currPage: 0 });
  };

  getSourceList = (forSearch: boolean) => {
    const workSpaceId = this.state.workSpaceId;
    const searchText = forSearch ? this.state.searchText : "";
    const skip = this.state.currPage * this.state.chunkSize;
    this.setState({ loading: true });
    agent.Receipt.getSourceList(
      workSpaceId,
      this.state.active,
      searchText,
      skip,
      this.state.chunkSize
    )
      .then((res: any) => {
        this.setState({
          totalRecords: res.count,
          sourceList: res.sources,
          loading: false
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).onNotify(
          "Could not load Source Details",
          err?.response?.data?.error || err?.message || err,
          "danger"
        );
      });
  };

  handleSearchTextChange = (ev: any) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      searchText: ev.target.value,
      currPage: 0,
      typingTimeout: setTimeout(() => {
        this.getSourceList(true);
      }, 700)
    });
  };

  openAddReceiptModal = () => {
    this.setState({ modalOpen: true, showBackDrop: false });
    (this.props as any).updateCommon({
      currentModal: { modalName: "ADD_RECEIPT_MODAL", fetchAgain: false }
    });
  };

  componentDidMount() {
    document.title = "Receipts & Payments Source - TaxPido PMS";
    this.getSourceList(false);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const prevFirmId = prevProps.params?.firmId;
    const currFirmId = (this.props as any).params?.firmId;
    if (prevFirmId !== currFirmId) {
      this.getSourceList(false);
    }
    if (prevState.active !== this.state.active) {
      this.getSourceList(false);
    }
    if (
      prevState.currPage !== this.state.currPage ||
      prevState.chunkSize !== this.state.chunkSize
    ) {
      this.state.searchText
        ? this.getSourceList(true)
        : this.getSourceList(false);
    }
  }

  onLoad = () => {
    if (this.state.modalType === "editSource") {
      this.getSourceList(true);
    } else {
      this.setState({ searchText: "" });
      this.getSourceList(false);
    }
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard>
        {this.state.showAddModal && (
          <AddModal
            modalType={this.state.modalType}
            selectedRow={this.state.selectedRow}
            showModal={this.state.showAddModal}
            closeModal={this.closeAddModal}
            onLoad={this.onLoad}
          />
        )}
        {this.state.showInActiveModal && (
          <InActiveModal
            type={"source"}
            state={this.state}
            onLoad={this.onLoad}
            inActiveModalSetOpen={this.inActiveModalSetOpen}
          />
        )}

        {this.state.showActiveModal && (
          <ActiveModal
            type={"source"}
            state={this.state}
            onLoad={this.onLoad}
            activeModalSetOpen={this.activeModalSetOpen}
          />
        )}
        {this.state.showDeleteModal && (
          <DeleteModal
            type={"source"}
            state={this.state}
            onLoad={this.onLoad}
            deleteModalSetOpen={this.deleteModalSetOpen}
          />
        )}
        <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">
            Receipt Sources
          </h1>
        </div>
        <div className="px-4 sm:px-6 md:px-8 flex justify-between mt-6">
          <button
            type="button"
            className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
            onClick={() => this.openAddModal("addSource")}
          >
            <Icon name="add" className="h-4 w-4 mr-2" />
            Add Receipt Source
          </button>
          {(this.state.totalRecords > 0 ||
            this.state.searchText.length > 0) && (
            <div className="w-64 sm:w-80">
              <input
                id="search"
                name="search"
                type="search"
                value={this.state.searchText}
                placeholder="Search by Source Name or Source Description"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 text-sm"
                onChange={this.handleSearchTextChange}
              />
            </div>
          )}
          <span aria-hidden="true" className="sm:w-56">
            &#8203;
          </span>
        </div>

        <div className="relative flex items-start max-w-full mx-auto mt-6 px-4 sm:px-6 md:px-8">
          <div className="flex h-5 items-center">
            <input
              id="active"
              aria-describedby="active-description"
              name="active"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-400 cursor-pointer text-indigo-600 focus:ring-indigo-500"
              checked={this.state.active === false}
              onChange={this.updateActive}
            />
          </div>
          <div className="ml-3 text-sm">
            <label
              htmlFor="active"
              className="font-medium cursor-pointer text-gray-700"
            >
              Show Inactive Receipt Source
            </label>
          </div>
        </div>
        {this.state.totalRecords > 0 || this.state.searchText.length > 0 ? (
          <div className="max-w-full mx-auto">
            <div className="mt-6 lg:mx-8 flex flex-col">
              <div id="table-scroll" className="overflow-auto">
                <div className="inline-block min-w-full py-2 px-1 align-middle">
                  <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                    <table className="min-w-full border-collapse border shadow-sm">
                      <thead className="bg-gray-50 border-b sticky top-0 z-[8]">
                        <tr>
                          {this.headers.map(header => (
                            <th
                              key={`${header}-list`}
                              scope="col"
                              className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${
                                header.toLowerCase() === "action"
                                  ? "flex justify-center"
                                  : ""
                              }`}
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      {this.state.totalRecords === 0 ? (
                        <tr className="w-full bg-white">
                          <td
                            colSpan={this.headers.length}
                            className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900"
                          >
                            No record found matching your search criteria
                          </td>
                        </tr>
                      ) : (
                        <tbody className="bg-white">
                          {!this.state.loading && this.state.sourceList
                            ? this.state.sourceList.map(
                                (source: any, index: any) => (
                                  <tr
                                    key={source._id}
                                    className={
                                      index % 2 === 0
                                        ? undefined
                                        : "bg-gray-100"
                                    }
                                  >
                                    <td className="max-w-[20ch] px-6 py-4 whitespace-nowrap text-sm font-bold text-gray-900">
                                      <Popup
                                        content={source.name}
                                        className="w-fit max-w-full"
                                      >
                                        <p className="truncate">
                                          {source.name}
                                        </p>
                                      </Popup>
                                    </td>
                                    <td className="max-w-[20ch] px-6 py-4 whitespace-nowrap text-sm text-gray-900 break-words">
                                      <Popup
                                        content={source?.description}
                                        className="w-fit max-w-full"
                                      >
                                        <p className="truncate">
                                          {source?.description}
                                        </p>
                                      </Popup>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex justify-center">
                                      <Menu as="div" className="inline-block">
                                        <Menu.Button
                                          onClick={this.onActionClick}
                                        >
                                          <span className="sr-only">
                                            Open options
                                          </span>
                                          <Icon
                                            name="horizontal-dots"
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </Menu.Button>
                                        {this.state.showBackDrop && (
                                          <div
                                            className="fixed top-0 left-0 z-10 w-full h-screen"
                                            onClick={this.onDropdownClick}
                                          ></div>
                                        )}

                                        <Transition
                                          as={Fragment}
                                          enter="transition ease-out duration-100"
                                          enterFrom="transform opacity-0 scale-95"
                                          enterTo="transform opacity-100 scale-100"
                                          leave="transition ease-in duration-75"
                                          leaveFrom="transform opacity-100 scale-100"
                                          leaveTo="transform opacity-0 scale-95"
                                        >
                                          <div
                                            style={{
                                              position: "fixed",
                                              zIndex: 100,
                                              top: `${this.state.posY}px`,
                                              left: `${
                                                this.state.posX - 230
                                              }px`,
                                              margin: "0.5rem"
                                            }}
                                          >
                                            <Menu.Items className="overscroll-none mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                              <div className="py-1">
                                                <Menu.Item>
                                                  <button
                                                    className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                                                    onClick={() => {
                                                      this.openAddModal(
                                                        "editSource"
                                                      );
                                                      this.setState({
                                                        selectedRow: source
                                                      });
                                                    }}
                                                  >
                                                    <Icon
                                                      name="edit"
                                                      className="h-5 w-5 mr-2"
                                                    />
                                                    <span>Edit</span>
                                                  </button>
                                                </Menu.Item>
                                                <Menu.Item>
                                                  {source.active ? (
                                                    <button
                                                      className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                                                      onClick={() =>
                                                        this.openInActiveModal(
                                                          source
                                                        )
                                                      }
                                                    >
                                                      <Icon
                                                        name="warning"
                                                        className="h-5 w-5 mr-2"
                                                      />
                                                      <span>Mark Inactive</span>
                                                    </button>
                                                  ) : (
                                                    <button
                                                      className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                                                      onClick={() =>
                                                        this.openActiveModal(
                                                          source
                                                        )
                                                      }
                                                    >
                                                      <Icon
                                                        name="warning"
                                                        className="h-5 w-5 mr-2"
                                                      />
                                                      <span>Mark Active</span>
                                                    </button>
                                                  )}
                                                </Menu.Item>
                                                {/* <Menu.Item>
																							<button
																								className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
																								onClick={() =>
																									this.openDeleteModal(source)
																								}
																							>
																								<Icon
																									name="delete"
																									className="h-5 w-5 mr-2"
																								/>
																								<span>Delete</span>
																							</button>
																						</Menu.Item> */}
                                              </div>
                                            </Menu.Items>
                                          </div>
                                        </Transition>
                                      </Menu>
                                    </td>
                                  </tr>
                                )
                              )
                            : [...Array(5)].map((e, i) => (
                                <tr key={i} className="bg-white">
                                  {[...Array(this.headers.length)].map(
                                    (e, i) => (
                                      <td
                                        key={i}
                                        className="w-3/10 px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                      >
                                        <Skeleton />
                                      </td>
                                    )
                                  )}
                                </tr>
                              ))}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center my-10 border-2 border-gray-300 border-dashed p-16 md:mx-40 sm:mx-0 rounded-lg">
            <Icon
              name="outline/document-add"
              className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
              strokeWidth="1"
            />
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              No Source Entry
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Get started by adding a new Source.
            </p>
            <div className="mt-6">
              <button
                type="button"
                className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                onClick={() => this.setState({ showAddModal: true })}
              >
                <Icon name="add" className="h-4 w-4 mr-2" />
                Add Receipt Source
              </button>
            </div>
          </div>
        )}
        <Pagination
          displayRecords={this.state.sourceList}
          totalRecords={this.state.totalRecords}
          currPage={this.state.currPage}
          chunkSize={this.state.chunkSize}
          handlePageClick={this.handlePageClick}
          handleItemPerPage={this.handleItemPerPage}
          className="my-4"
        />
      </Dashboard>
    );
  }
}

export default compose(connector, withRouter)(Source) as React.ComponentType;
