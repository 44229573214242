import React from "react";
// Import to Display skeleton while loading data
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { connect, ConnectedProps } from "react-redux";
// Routing imports
// Link backend
import agent from "../../agent";
// Dashboard import
import Dashboard from "../../components/Dashboard";
// Icons and styling
import Icon from "../../components/Icon";
// Redux Notify
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import "../style.css";

import { withRouter, WithRouterProps } from "../../helpers/withRouter";
import { compose } from "redux";

import { downloadFile } from "../../helpers/downloadFile";
import { Link } from "react-router-dom";
import { formatDate } from "../../helpers/formatDate";
import convertNumber from "../../helpers/convertNumber";
import MultiSelect from "../../components/MultiSelect";
import Popup from "../../components/Popup";
import TagManager from "react-gtm-module";
import Pagination from "../../components/Pagination";
import { ItemsPerPage, itemsPerPage } from "../../constants/itemsPerPage";
import { AppDispatch, RootState } from "../../store";
import { NotifyType } from "../../store/reducers/notification";
import { CommonAction } from "../../store/reducers/common";
import { formatClientName } from "../../helpers/formatClientName";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Receipt Payment Client Wise Report"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateCommon: (payload: CommonAction["payload"]) =>
    dispatch({ type: UPDATE_COMMON, payload }),
  onNotify: (title: string, message: string, type: NotifyType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends Partial<PropsFromRedux & WithRouterProps> {}

type State = {
  logging: boolean;
  downloading: boolean;
  receiptList: any;
  balance: string;
  totalRecords: number;
  clientList: any[];
  selectedClientId: string;
  currPage: number;
  chunkSize: number;
};

class ClientWiseReport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      logging: false,
      downloading: false,
      receiptList: [],
      balance: "",
      totalRecords: 0,
      clientList: [],
      selectedClientId: "",
      currPage: 0,
      chunkSize: itemsPerPage[0].name
    };
  }

  headers = [
    "DATE",
    "AMOUNT",
    "TYPE",
    "SOURCE",
    "CLIENT",
    "CATEGORY",
    "NOTES",
    "VERIFIED"
  ];

  getClientsList = () => {
    this.setState({ logging: true });
    this.props.params?.firmId &&
      agent.Clients.getClientList(this.props.params?.firmId, 0, 1000, "", true)
        .then((res: any) => {
          this.setState({
            clientList: res.clients,
            logging: false,
            selectedClientId: res.count === 1 ? res.clients[0]._id : ""
          });
        })
        .catch((err: any) => {
          this.setState({ logging: false });
          this.props.onNotify?.(
            "Error",
            "Error while fetching clients",
            "danger"
          );
        });
  };

  handlePageClick = (data: { selected: number }) => {
    this.setState({ currPage: data.selected });
  };

  handleItemPerPage = (value: ItemsPerPage) => {
    this.setState({ chunkSize: value.name, currPage: 0 });
  };

  getClientWiseReport = (download: boolean) => {
    if (this.state.selectedClientId === "") {
      this.props.onNotify?.("Error", "Please select a client", "danger");
      return;
    } else {
      const skip = this.state.currPage * this.state.chunkSize;

      this.setState({ logging: true, downloading: download });
      this.props.params?.firmId &&
        agent.Receipt.clientWiseReport(
          this.props.params?.firmId,
          this.state.selectedClientId,
          skip,
          this.state.chunkSize,
          download
        )
          .then((res: any) => {
            download
              ? downloadFile(res, "ClientWiseReport.xlsx")
              : this.setState({
                  receiptList: res.entries,
                  totalRecords: res.count,
                  balance: res.balance
                });
          })
          .catch((err: any) => {
            this.setState({ logging: false });
            this.props.onNotify?.(
              "Error",
              "Error while fetching client wise report",
              "danger"
            );
          })
          .finally(() => {
            this.setState({ logging: false, downloading: false });
          });
    }
  };

  componentDidMount() {
    this.getClientsList();
    document.title = "Client Wise Report - TaxPido PMS";
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const prevFirmId = prevProps.params?.firmId;
    const currFirmId = this.props.params?.firmId;
    if (prevFirmId !== currFirmId) {
      this.getClientsList();
      this.setState({ receiptList: [] });
    }
    if (
      prevState.selectedClientId !== this.state.selectedClientId &&
      this.state.selectedClientId
    ) {
      this.getClientWiseReport(false);
    }

    if (
      prevState.currPage !== this.state.currPage ||
      prevState.chunkSize !== this.state.chunkSize
    ) {
      this.getClientWiseReport(false);
    }
  }

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard>
        <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">
            Client Wise Report
          </h1>
        </div>
        <div className="px-4 sm:px-6 md:px-8 flex justify-between mt-6 items-center">
          <Link
            to={`/${this.props.params?.firmId}/receipts-payments/list`}
            className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
          >
            <Icon name="arrow-left" className="h-4 w-4 mr-2" />
            Back to Receipts
          </Link>
          <div className="w-auto">
            <MultiSelect
              items={this.state.clientList.map(item => {
                return {
                  ...item,
                  _id: item._id,
                  name: formatClientName(item)
                };
              })}
              selected={{
                name:
                  formatClientName(
                    this.state.clientList.find(
                      item => item._id === this.state.selectedClientId
                    )
                  ) || ""
              }}
              type="type"
              onChange={(selected: any) => {
                this.setState({ selectedClientId: selected._id });
              }}
              placeholder="Select Client"
            />
          </div>
          <button
            type="button"
            className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
            onClick={() => this.getClientWiseReport(true)}
          >
            {this.state.downloading ? (
              <Icon name="loading" className="h-4 w-4 mr-2" />
            ) : (
              <Icon name="outline/download" className="h-4 w-4 mr-2" />
            )}
            Download Report
          </button>
        </div>
        {this.state.selectedClientId && parseInt(this.state.balance) !== 0 && (
          <div className="relative flex items-start max-w-full mx-auto mt-6 lg:mx-8 px-2">
            <p>
              {parseInt(this.state.balance) > 0 ? "Receiveable" : "Payable"}{" "}
              Balance :- {convertNumber(parseInt(this.state.balance))}
            </p>
          </div>
        )}

        {this.state.receiptList.length > 0 ? (
          <div className="max-w-full mx-auto">
            <div className="mt-6 lg:mx-8 flex flex-col">
              <div id="table-scroll" className="overflow-auto">
                <div className="inline-block min-w-full py-2 px-1 align-middle">
                  <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                    <table className="min-w-full border-collapse border shadow-sm">
                      <thead className="bg-gray-50">
                        <tr>
                          {this.headers.map(header => (
                            <th
                              key={header}
                              style={{ zIndex: 8 }}
                              scope="col"
                              className="sticky top-0 border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6"
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {!this.state.logging && this.state.receiptList
                          ? this.state.receiptList?.map(
                              (receipt: any, index: any) => (
                                <tr
                                  key={receipt?._id}
                                  className={
                                    index % 2 === 0 ? undefined : "bg-gray-100"
                                  }
                                >
                                  <td className="w-3/12 whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 font-bold">
                                    {formatDate(receipt?.date, false)}
                                  </td>
                                  <td className="w-3/12 whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                    {convertNumber(receipt?.amount)}
                                  </td>
                                  <td className="w-3/12 whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 font-bold">
                                    {receipt?.type}
                                  </td>
                                  <td className="w-3/12 whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                    {receipt?.sourceName}
                                  </td>
                                  <td className="w-3/12 whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 font-bold">
                                    {receipt?.clientName}
                                  </td>
                                  <td className="w-3/12 whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                    {receipt?.categoryName}
                                  </td>
                                  <td className="w-4/12 px-6 py-3 text-sm text-gray-900 relative font-bold">
                                    <Popup
                                      content={receipt?.notes}
                                      className="w-full max-w-fit"
                                    >
                                      <p className="truncate">
                                        {receipt?.notes ? receipt?.notes : "-"}
                                      </p>
                                    </Popup>
                                  </td>
                                  <td className="w-4/10 px-6 py-3 whitespace-nowrap text-sm text-gray-900">
                                    {receipt?.isVerified ? "Verified" : ""}
                                  </td>
                                </tr>
                              )
                            )
                          : [...Array(5)].map((e, i) => (
                              <tr key={i} className="bg-white">
                                {[...Array(this.headers.length)].map((e, i) => (
                                  <td
                                    key={i}
                                    className="w-3/10 px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                  >
                                    <Skeleton />
                                  </td>
                                ))}
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : this.state.selectedClientId !== "" &&
          this.state.receiptList.length === 0 ? (
          <div className="text-center my-10 border-2 border-gray-300 border-dashed p-16 md:mx-40 sm:mx-0 rounded-lg">
            <Icon
              name="outline/document-add"
              className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
              strokeWidth="1"
            />
            <h3 className="text-sm font-medium text-gray-900">
              No Receipt Found for selected Client.
            </h3>
          </div>
        ) : (
          <div className="text-center my-10 border-2 border-gray-300 border-dashed p-16 md:mx-40 sm:mx-0 rounded-lg">
            <h3 className="text-sm font-medium text-gray-900">
              No Client Selected
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Get started by selecting a Client.
            </p>
          </div>
        )}
        <Pagination
          displayRecords={this.state.receiptList}
          totalRecords={this.state.totalRecords}
          currPage={this.state.currPage}
          chunkSize={this.state.chunkSize}
          handlePageClick={this.handlePageClick}
          handleItemPerPage={this.handleItemPerPage}
          className="my-4"
        />
      </Dashboard>
    );
  }
}

export default compose(
  connector,
  withRouter
)(ClientWiseReport) as React.ComponentType;
