import { ChangeEvent, Component } from "react";
import Popup from "../../components/Popup";
// Import to Display skeleton while loading data
import "react-loading-skeleton/dist/skeleton.css";
import { connect, ConnectedProps } from "react-redux";
import { compose } from "redux";
import { withRouter, WithRouterProps } from "../../helpers/withRouter";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import { RootState } from "../../store";
import agent from "../../agent";
import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import Icon from "../../components/Icon";
import { ClientFields } from ".";
import {
  CustomField,
  CustomFieldInPersonOrClient,
  Email,
  Client,
  DefUser,
  Tag,
  PersonInClient,
  GroupInClient,
  TagInClient
} from "../../helpers/types";
import { formatDate } from "../../helpers/formatDate";
import { validEmail, validPAN, validTAN } from "../../helpers/regex";
import MultiSelect from "../../components/MultiSelect";
import MultiSelectCheckbox from "../../components/MultiSelectCheckbox";
import { clientTypesList, designationList } from "../../constants/clients";
import validGSTIN from "../../helpers/GSTValidationFunction";

const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: <T,>(payload: T) => dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: string) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends Partial<PropsFromRedux & WithRouterProps> {
  index: number;
  clientData: Client;
  getClientById: (id: string) => void;
  selectedFieldToUpdate: ClientFields;
  selectedCustomField: CustomField | { _id: ""; name: ""; type: "" };
  customFields: CustomField[];
  openCustomFieldModal: () => void;
  contactPersonList: PersonInClient[];
  groupList: GroupInClient[];
}

type AddMoreType = "contactPerson" | "groups";

interface State {
  initialClientData: Client;
  clientData: Client;
  loading: boolean;
  isUpdated: boolean;
  isError: boolean;
  message: string;
  fieldType: "password" | "text";
  iconType: "eye-open" | "eye-close";
  originalCustomField: CustomFieldInPersonOrClient[];
  formattedCustomField: (CustomFieldInPersonOrClient & { value: string })[];
  selectedUsers: DefUser[];
  selectedTags: TagInClient[];
  selectedGroupToAdd: GroupInClient | null;
  selectedGroups: GroupInClient[];
  selectedPersonToAdd: PersonInClient | null;
  selectedPersons: PersonInClient[];
}

class ClientUpdateRow extends Component<Props, State> {
  state: State = {
    initialClientData: this.props.clientData,
    clientData: this.props.clientData,
    loading: false,
    isUpdated: false,
    isError: false,
    message: "",
    fieldType: "password",
    iconType: "eye-open",
    originalCustomField: [],
    formattedCustomField: [],
    selectedUsers: this.props.clientData.defUsers.map(user => ({
      ...user,
      _id: user.userId
    })),
    selectedTags: this.props.clientData.tags.map(tag => ({
      ...tag,
      _id: tag.tagId
    })),
    selectedGroupToAdd: null,
    selectedGroups: this.props.clientData?.groups.map(group => ({
      ...group,
      _id: group.groupId
    })),
    selectedPersonToAdd: null,
    selectedPersons: this.props.clientData.contactPerson.map(person => ({
      ...person,
      _id: person.personId
    }))
  };

  onEnterKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      this.updateClient();
    }
  };

  formatCustomField = () => {
    const formattedCustomField = this.props.customFields.map(
      (customField: CustomField) => {
        const customFieldInClient = this.props.clientData.customFields.find(
          (customFieldInClient: CustomFieldInPersonOrClient) =>
            customFieldInClient.customFieldId === customField._id
        );

        const customFieldValue = customFieldInClient?.value;

        return {
          customFieldId: customField._id,
          name: customField.name,
          value:
            customFieldValue === true
              ? "Yes"
              : customFieldValue === false
              ? "No"
              : customFieldValue ?? ""
        };
      }
    );
    this.setState({
      originalCustomField: formattedCustomField,
      formattedCustomField
    });
  };

  componentDidMount() {
    this.formatCustomField();
    this.props.clientData.contactPerson.length === 0 &&
      this.addMore("contactPerson");
    this.props.clientData.groups.length === 0 && this.addMore("groups");
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      (prevProps.selectedFieldToUpdate._id !==
        this.props.selectedFieldToUpdate._id &&
        this.props.selectedFieldToUpdate._id === "customFields") ||
      prevProps.selectedCustomField._id !== this.props.selectedCustomField._id
    ) {
      this.formatCustomField();
    }
    if (
      prevProps.selectedFieldToUpdate._id !==
        this.props.selectedFieldToUpdate._id ||
      prevProps.selectedCustomField !== this.props.selectedCustomField
    ) {
      this.resetErrorAndUpdateState();
    }
    if (prevProps.clientData !== this.props.clientData) {
      this.setState({
        initialClientData: this.props.clientData,
        clientData: this.props.clientData
      });
    }

    if (
      prevState.clientData.type !== this.state.clientData.type ||
      prevState.selectedUsers !== this.state.selectedUsers ||
      prevState.selectedTags !== this.state.selectedTags ||
      prevState.selectedGroupToAdd !== this.state.selectedGroupToAdd ||
      (prevState.selectedPersonToAdd !== this.state.selectedPersonToAdd &&
        this.state.selectedPersonToAdd?._id)
    ) {
      this.updateClient();
    }
  }

  resetErrorAndUpdateState = () => {
    this.setState({
      isError: false,
      message: "",
      isUpdated: false
    });
  };

  updateFieldData = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.resetErrorAndUpdateState();
    this.setState({
      clientData: {
        ...this.state.clientData,
        [e.target.name]: e.target.value
      }
    });
  };

  onViewPassword = () => {
    if (this.state.fieldType === "password") {
      this.setState({ fieldType: "text", iconType: "eye-close" });
    } else {
      this.setState({ fieldType: "password", iconType: "eye-open" });
    }
  };

  updateCustomFieldData = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    this.resetErrorAndUpdateState();
    const updatedCustomField = [...this.state.formattedCustomField];

    updatedCustomField[index].value = e.target.value;
    this.setState({ formattedCustomField: updatedCustomField });
  };

  handleClientTypeChange = (item: { _id: string; name: string }) => {
    this.resetErrorAndUpdateState();
    this.setState({
      clientData: { ...this.state.clientData, type: item.name }
    });
  };

  handleUserChange = (user: DefUser) => {
    this.resetErrorAndUpdateState();
    const index = this.state.selectedUsers.findIndex(
      item => item._id === user._id
    );
    if (index === -1) {
      this.setState({ selectedUsers: [...this.state.selectedUsers, user] });
    } else {
      const updatedUsers = this.state.selectedUsers.filter(
        item => item._id !== user._id
      );
      this.setState({ selectedUsers: updatedUsers });
    }
  };

  handleTagChange = (tag: Tag) => {
    this.resetErrorAndUpdateState();
    const index = this.state.selectedTags.findIndex(
      item => item._id === tag._id
    );
    if (index === -1) {
      this.setState({ selectedTags: [...this.state.selectedTags, tag] });
    } else {
      const updatedTags = this.state.selectedTags.filter(
        item => item._id !== tag._id
      );
      this.setState({ selectedTags: updatedTags });
    }
  };

  handlePersonChange = (person: PersonInClient, selectedIndex: number) => {
    this.resetErrorAndUpdateState();
    const index = this.state.selectedPersons.findIndex(
      item => item._id === person._id
    );

    if (index === -1) {
      const updatedPersons = this.state.selectedPersons.map((item, i) => {
        if (selectedIndex === i) {
          return {
            _id: person._id,
            name: person.name,
            personId: person._id,
            designation: person.designation,
            isPrimary: person.isPrimary ?? false
          };
        } else {
          return item;
        }
      });
      this.setState({
        selectedPersons: updatedPersons,
        selectedPersonToAdd: {
          _id: person._id,
          name: person.name,
          personId: person._id,
          designation: person.designation,
          isPrimary: person.isPrimary ?? false
        }
      });
    } else {
      const updatedPersons = this.state.selectedPersons.filter(
        item => item._id !== person._id
      );
      this.setState({
        selectedPersons:
          updatedPersons.length === 0
            ? [
                {
                  _id: "",
                  personId: "",
                  name: "",
                  designation: "",
                  isPrimary: false
                }
              ]
            : updatedPersons,
        selectedPersonToAdd: {
          _id: "",
          personId: "",
          name: "",
          designation: "",
          isPrimary: false
        }
      });
    }
  };

  handlePersonDataChange = (
    type: "designation" | "isPrimary",
    selectedIndex: number,
    value: string | boolean
  ) => {
    this.resetErrorAndUpdateState();
    const updatedPersons = this.state.selectedPersons.map((item, i) => {
      if (selectedIndex === i) {
        return { ...item, [type]: value };
      } else {
        return type === "isPrimary" && item.isPrimary
          ? { ...item, isPrimary: false }
          : item;
      }
    });
    this.setState({
      selectedPersons: updatedPersons,
      selectedPersonToAdd: updatedPersons[selectedIndex]
    });
  };

  handleGroupChange = (group: GroupInClient, selectedIndex: number) => {
    this.resetErrorAndUpdateState();
    const index = this.state.selectedGroups.findIndex(
      item => item._id === group._id
    );
    if (index === -1) {
      const updatedGroups = this.state.selectedGroups.map((item, i) => {
        if (selectedIndex === i) {
          return group;
        } else {
          return item;
        }
      });
      this.setState({
        selectedGroups: updatedGroups,
        selectedGroupToAdd: group
      });
    } else {
      const updatedGroups = this.state.selectedGroups.filter(
        item => item._id !== group._id
      );
      this.setState({
        selectedGroups:
          updatedGroups.length === 0
            ? [{ groupId: "", name: "" }]
            : updatedGroups,
        selectedGroupToAdd: { groupId: "", name: "" }
      });
    }
  };

  addMore = (field: AddMoreType) => {
    this.resetErrorAndUpdateState();
    if (field === "contactPerson") {
      this.state.selectedPersons.length === 0
        ? this.setState({
            selectedPersons: [
              {
                _id: "",
                personId: "",
                name: "",
                designation: "",
                isPrimary: false
              }
            ]
          })
        : this.setState({
            selectedPersons: [
              ...this.state.selectedPersons,
              {
                _id: "",
                personId: "",
                name: "",
                designation: "",
                isPrimary: false
              }
            ]
          });
    } else if (field === "groups") {
      this.state.selectedGroups.length === 3
        ? this.setState({
            message: "You cannot add more than 3 groups in a client."
          })
        : this.state.selectedGroups.length === 0
        ? this.setState({
            selectedGroups: [{ groupId: "", name: "" }]
          })
        : this.setState({
            selectedGroups: [
              ...this.state.selectedGroups,
              { groupId: "", name: "" }
            ]
          });
    }
  };

  checkEmail = (email: Email[]) => {
    let isValid = true;
    email.forEach(item => {
      const isEmailValid = validEmail.test(item.emailId);
      if (!isEmailValid) {
        this.props.addNotification?.(
          "Invalid email",
          `${item.emailId} is Invalid email!`,
          "danger"
        );
        isValid = false;
      }
    });

    return isValid;
  };

  isEqual = (a: any, b: any) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };

  checkIfUpdated = () => {
    let {
      _id,
      name,
      fileNo,
      tradeName,
      notes,
      dob,
      companyCIN,
      LLP_RegNo,
      pan,
      tan,
      tanPassword,
      itPortalPassword,
      tracesUsername,
      tracesPassword,
      tracesTaxPayerPassword,
      mcaV2Username,
      mcaV2Password,
      mcaV3Username,
      mcaV3Password,
      dgftUsername,
      dgftPassword,
      eWayBillUsername,
      eWayPassword,
      gstUsername,
      gstPassword,
      type,
      gstin,
      gstApplicable,
      itApplicable,
      regDate,
      filedITreturns,
      companyINC_Date,
      canDate
    } = this.state.clientData;
    const {
      selectedUsers,
      selectedTags,
      selectedGroups,
      selectedPersons,
      formattedCustomField
    } = this.state;

    dob = dob ? new Date(dob).toISOString() : "";

    companyINC_Date = companyINC_Date
      ? new Date(companyINC_Date).toISOString()
      : "";

    regDate = regDate ? new Date(regDate).toISOString() : "";
    canDate = canDate ? new Date(canDate).toISOString() : "";

    const editUsers = selectedUsers?.map(user => user._id);
    const editTags = selectedTags?.map(tag => tag._id);

    const editCustomFields = formattedCustomField
      ?.filter(({ customFieldId }) => customFieldId)
      ?.map(({ customFieldId, value }) => ({
        customFieldId,
        value
      }));
    const editContactPerson = selectedPersons
      ?.map(({ personId, designation, isPrimary }) => ({
        personId,
        designation: designation ?? "",
        isPrimary: isPrimary ?? false
      }))
      .filter(item => item.personId);
    const editGroups = selectedGroups?.map(group => group._id).filter(Boolean);

    const isUpdated = () => {
      switch (this.props.selectedFieldToUpdate._id) {
        case "name":
          return name !== this.state.initialClientData.name;
        case "fileNo":
          return fileNo !== this.state.initialClientData.fileNo;
        case "tradeName":
          return tradeName !== this.state.initialClientData.tradeName;
        case "notes":
          return notes !== this.state.initialClientData.notes;
        case "dob":
          return dob !== this.state.initialClientData.dob;
        case "companyCIN":
          return companyCIN !== this.state.initialClientData.companyCIN;
        case "LLP_RegNo":
          return LLP_RegNo !== this.state.initialClientData.LLP_RegNo;
        case "pan":
          return pan !== this.state.initialClientData.pan;
        case "tan":
          return tan !== this.state.initialClientData.tan;
        case "tanPassword":
          return tanPassword !== this.state.initialClientData.tanPassword;
        case "itPortalPassword":
          return (
            itPortalPassword !== this.state.initialClientData.itPortalPassword
          );
        case "tracesUsername":
          return tracesUsername !== this.state.initialClientData.tracesUsername;
        case "tracesPassword":
          return tracesPassword !== this.state.initialClientData.tracesPassword;
        case "tracesTaxPayerPassword":
          return (
            tracesTaxPayerPassword !==
            this.state.initialClientData.tracesTaxPayerPassword
          );
        case "mcaV2Username":
          return mcaV2Username !== this.state.initialClientData.mcaV2Username;
        case "mcaV2Password":
          return mcaV2Password !== this.state.initialClientData.mcaV2Password;
        case "mcaV3Username":
          return mcaV3Username !== this.state.initialClientData.mcaV3Username;
        case "mcaV3Password":
          return mcaV3Password !== this.state.initialClientData.mcaV3Password;
        case "dgftUsername":
          return dgftUsername !== this.state.initialClientData.dgftUsername;
        case "dgftPassword":
          return dgftPassword !== this.state.initialClientData.dgftPassword;
        case "eWayBillUsername":
          return (
            eWayBillUsername !== this.state.initialClientData.eWayBillUsername
          );
        case "eWayPassword":
          return eWayPassword !== this.state.initialClientData.eWayPassword;
        case "gstUsername":
          return gstUsername !== this.state.initialClientData.gstUsername;
        case "gstPassword":
          return gstPassword !== this.state.initialClientData.gstPassword;
        case "type":
          return type !== this.state.initialClientData.type;
        case "defUsers":
          return !this.isEqual(
            editUsers,
            this.state.initialClientData.defUsers.map(user => user._id)
          );
        case "tags":
          return !this.isEqual(
            editTags,
            this.state.initialClientData.tags.map(tag => tag._id)
          );
        case "contactPerson":
          return !this.isEqual(
            editContactPerson,
            this.state.initialClientData.contactPerson.map(
              ({ _id, designation, isPrimary }) => ({
                personId: _id,
                designation: designation ?? "",
                isPrimary
              })
            )
          );
        case "customFields":
          return !this.isEqual(
            editCustomFields,
            this.state.initialClientData.customFields.map(
              ({ customFieldId, value }) => ({
                customFieldId,
                value
              })
            )
          );
        case "groups":
          return !this.isEqual(
            editGroups,
            this.state.initialClientData.groups.map(group => group.groupId)
          );
        default:
          return;
      }
    };

    return {
      isUpdated: isUpdated(),
      clientData: {
        clientId: _id,
        name,
        fileNo,
        tradeName,
        notes,
        dob,
        companyCIN,
        LLP_RegNo,
        pan,
        tan,
        tanPassword,
        itPortalPassword,
        tracesUsername,
        tracesPassword,
        tracesTaxPayerPassword,
        mcaV2Username,
        mcaV2Password,
        mcaV3Username,
        mcaV3Password,
        dgftUsername,
        dgftPassword,
        eWayBillUsername,
        eWayPassword,
        gstUsername,
        gstPassword,
        gstin,
        gstApplicable,
        itApplicable,
        regDate,
        filedITreturns,
        companyINC_Date,
        canDate,
        type,
        defUsers: editUsers,
        contactPerson: editContactPerson,
        customFields: editCustomFields,
        groupIds: editGroups,
        tags: editTags
      }
    };
  };

  updateClient = () => {
    const workSpaceId = this.props.params?.firmId;
    const { clientData, isUpdated } = this.checkIfUpdated();
    if (!isUpdated) return;
    let {
      clientId,
      name,
      pan,
      tan,
      type,
      defUsers,
      gstin,
      gstApplicable,
      itApplicable,
      regDate,
      filedITreturns
    } = clientData;

    const taxesApplicable = [
      "other",
      ...(gstApplicable ? ["gst"] : []),
      ...(itApplicable ? ["income-tax"] : [])
    ];

    const checkGSTIN = !taxesApplicable.includes("gst")
      ? true
      : gstin === ""
      ? false
      : true;
    const isgstinValid = !taxesApplicable.includes("gst")
      ? true
      : validGSTIN(gstin);

    const checkPAN = !pan ? true : validPAN.test(pan);
    const checkTAN = !tan ? true : validTAN.test(tan);
    const checkRegDate = !taxesApplicable.includes("gst")
      ? true
      : regDate === ""
      ? false
      : true;

    const isITRPresent = !taxesApplicable.includes("income-tax")
      ? true
      : filedITreturns.length > 0;

    if (
      workSpaceId &&
      clientId &&
      name &&
      type &&
      defUsers.length > 0 &&
      checkGSTIN &&
      isgstinValid &&
      checkRegDate &&
      checkPAN &&
      checkTAN &&
      isITRPresent
    ) {
      this.setState({ loading: true });
      agent.Clients.editClient(workSpaceId, clientData, clientId)
        .then(() => {
          this.setState({
            isUpdated: true,
            message: `${this.props.selectedFieldToUpdate.name} updated successfully`
          });
          this.props.getClientById(clientId);
        })
        .catch(err => {
          this.setState({
            isError: true,
            isUpdated: false,
            message: err.response.data.message
          });
          this.props.selectedFieldToUpdate._id &&
            this.setState({
              clientData: {
                ...this.state.clientData,
                [this.props.selectedFieldToUpdate._id]:
                  this.state.initialClientData[
                    this.props.selectedFieldToUpdate._id
                  ]
              }
            });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    } else {
      this.props.selectedFieldToUpdate._id &&
        this.setState({
          clientData: {
            ...this.state.clientData,
            [this.props.selectedFieldToUpdate._id]:
              this.state.initialClientData[this.props.selectedFieldToUpdate._id]
          }
        });
      if (!type) {
        this.setState({
          isError: true,
          isUpdated: false,
          message: "Client Type is required"
        });
      } else if (name === "") {
        this.setState({
          isError: true,
          isUpdated: false,
          message: "Client Name is required"
        });
      } else if (defUsers.length === 0) {
        this.setState({
          isError: true,
          isUpdated: false,
          message: "Default User is required"
        });
      } else if (!checkGSTIN) {
        this.setState({
          isError: true,
          isUpdated: false,
          message: "GSTIN is required"
        });
      } else if (!isgstinValid) {
        this.setState({
          isError: true,
          isUpdated: false,
          message: "GSTIN is Invalid"
        });
      } else if (!checkRegDate) {
        this.setState({
          isError: true,
          isUpdated: false,
          message: "Registration Date is required"
        });
      } else if (!checkPAN) {
        this.setState({
          isError: true,
          isUpdated: false,
          message: "PAN is Invalid"
        });
      } else if (!checkTAN) {
        this.setState({
          isError: true,
          isUpdated: false,
          message: "TAN is Invalid"
        });
      } else if (!isITRPresent) {
        this.setState({
          isError: true,
          isUpdated: false,
          message: "ITR is required"
        });
      } else {
        this.setState({
          isError: true,
          isUpdated: false,
          message: "Something went wrong"
        });
      }
    }
  };

  render() {
    const {
      selectedFieldToUpdate,
      selectedCustomField,
      contactPersonList,
      groupList
    } = this.props;

    const {
      initialClientData,
      clientData,
      selectedPersons,
      selectedGroups,
      isError,
      loading,
      message,
      isUpdated: updated,
      formattedCustomField,
      fieldType,
      iconType
    } = this.state;

    return (
      <>
        <tr className="even:bg-gray-100 group">
          <td className="min-w-[20ch] max-w-[30ch] whitespace-nowrap py-2 px-4 text-sm text-gray-900">
            <Popup
              content={initialClientData.name}
              className="w-full max-w-fit"
            >
              <p className="hover:underline font-bold truncate">
                {initialClientData.name}
              </p>
            </Popup>
          </td>
          <td className="py-2 px-4 text-sm text-gray-900 min-w-[25ch] max-w-[40ch]">
            <div className="space-y-2">
              {selectedFieldToUpdate.fieldType === "customFields" ? (
                formattedCustomField.map((item, index) => {
                  return selectedCustomField.type === "date" &&
                    item.customFieldId === selectedCustomField._id ? (
                    <div
                      key={`${item.customFieldId}-${clientData._id}-${index}`}
                      className="flex rounded-md shadow-sm w-full"
                    >
                      <input
                        type="date"
                        id="date_custom_field"
                        name="date_custom_field"
                        value={item.value ? formatDate(item.value, true) : ""}
                        onBlur={this.updateClient}
                        onKeyDown={this.onEnterKeyPress}
                        onChange={e => this.updateCustomFieldData(e, index)}
                        className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                      />
                    </div>
                  ) : selectedCustomField.type === "shorttext" &&
                    item.customFieldId === selectedCustomField._id ? (
                    <div
                      key={`${item.customFieldId}-${clientData._id}-${index}`}
                      className="flex rounded-md shadow-sm w-full"
                    >
                      <input
                        type="text"
                        id="short_text_custom_field"
                        name="short_text_custom_field"
                        placeholder="Enter text"
                        value={item.value}
                        onBlur={this.updateClient}
                        onKeyDown={this.onEnterKeyPress}
                        onChange={e => this.updateCustomFieldData(e, index)}
                        className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                      />
                    </div>
                  ) : selectedCustomField.type === "longtext" &&
                    item.customFieldId === selectedCustomField._id ? (
                    <div
                      key={`${item.customFieldId}-${clientData._id}-${index}`}
                      className="w-full"
                    >
                      <textarea
                        rows={2}
                        id="long_text_custom_field"
                        name="long_text_custom_field"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        value={item.value}
                        placeholder="Enter text"
                        onBlur={this.updateClient}
                        onKeyDown={this.onEnterKeyPress}
                        onChange={e => this.updateCustomFieldData(e, index)}
                      />
                    </div>
                  ) : (
                    selectedCustomField.type === "boolean" &&
                    item.customFieldId === selectedCustomField._id && (
                      <div
                        key={`${item.customFieldId}-${clientData._id}-${index}`}
                        className="flex items-center gap-12 w-full justify-center py-1"
                      >
                        <div className="flex items-center">
                          <input
                            id={`boolean_custom_field_yes-${item.customFieldId}-${clientData._id}`}
                            name={`boolean_custom_field-${item.customFieldId}-${clientData._id}`}
                            type="radio"
                            value="Yes"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                            checked={item.value === "Yes"}
                            onBlur={this.updateClient}
                            onKeyDown={this.onEnterKeyPress}
                            onChange={e => this.updateCustomFieldData(e, index)}
                          />
                          <label
                            htmlFor={`boolean_custom_field_yes-${item.customFieldId}-${clientData._id}`}
                            className="ml-3 block text-sm font-medium text-gray-700 cursor-pointer"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            id={`boolean_custom_field_no-${item.customFieldId}-${clientData._id}`}
                            name={`boolean_custom_field-${item.customFieldId}-${clientData._id}`}
                            type="radio"
                            value="No"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                            checked={item.value === "No"}
                            onBlur={this.updateClient}
                            onKeyDown={this.onEnterKeyPress}
                            onChange={e => this.updateCustomFieldData(e, index)}
                          />
                          <label
                            htmlFor={`boolean_custom_field_no-${item.customFieldId}-${clientData._id}`}
                            className="ml-3 block text-sm font-medium text-gray-700 cursor-pointer"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    )
                  );
                })
              ) : selectedFieldToUpdate.fieldType === "clientType" ? (
                <MultiSelect
                  items={clientTypesList.map(item => {
                    return { _id: item, name: item };
                  })}
                  selected={{ name: clientData.type }}
                  type="type"
                  onChange={this.handleClientTypeChange}
                  placeholder="Select Ownership Type"
                />
              ) : selectedFieldToUpdate.fieldType === "users" ? (
                this.props.users && (
                  <MultiSelectCheckbox
                    items={this.props.users}
                    selected={this.state.selectedUsers}
                    type="usercheckbox"
                    onChange={this.handleUserChange}
                    placeholder="Select User"
                  />
                )
              ) : selectedFieldToUpdate.fieldType === "tags" ? (
                this.props.tags && (
                  <MultiSelectCheckbox
                    items={this.props.tags}
                    selected={this.state.selectedTags}
                    type="tagcheckbox"
                    onChange={this.handleTagChange}
                    placeholder="Select Tags"
                  />
                )
              ) : selectedFieldToUpdate.fieldType === "contactPerson" ? (
                selectedPersons.map((person, index) => (
                  <MultiSelect
                    key={`${person._id}-${clientData._id}-${index}`}
                    items={contactPersonList}
                    selected={person}
                    type="person"
                    onChange={selected =>
                      this.handlePersonChange(selected, index)
                    }
                    placeholder="Select Person"
                  />
                ))
              ) : selectedFieldToUpdate.fieldType === "groups" ? (
                selectedGroups.map((group, index) => (
                  <MultiSelect
                    key={`${group._id}-${clientData._id}-${index}`}
                    items={groupList}
                    selected={group}
                    type="group"
                    onChange={selected =>
                      this.handleGroupChange(selected, index)
                    }
                    placeholder="Select Group"
                  />
                ))
              ) : selectedFieldToUpdate.fieldType === "textarea" ? (
                <div className="w-full">
                  <textarea
                    rows={2}
                    name={selectedFieldToUpdate._id}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    value={clientData[selectedFieldToUpdate._id]}
                    placeholder={`Enter ${selectedFieldToUpdate.name}`}
                    onBlur={this.updateClient}
                    onKeyDown={this.onEnterKeyPress}
                    onChange={this.updateFieldData}
                  />
                </div>
              ) : (
                <div className="relative rounded-md shadow-sm">
                  <input
                    name={selectedFieldToUpdate._id}
                    type={
                      selectedFieldToUpdate.fieldType === "password"
                        ? fieldType
                        : selectedFieldToUpdate.fieldType
                    }
                    placeholder={`Enter ${selectedFieldToUpdate.name}`}
                    value={
                      selectedFieldToUpdate.fieldType === "date"
                        ? formatDate(
                            (clientData[
                              selectedFieldToUpdate._id as keyof Client
                            ] as string) ?? "",
                            true
                          )
                        : (clientData[
                            selectedFieldToUpdate._id as keyof Client
                          ] as string) ?? ""
                    }
                    // autoFocus={this.props.index === 0} // will do it later as it is not working everytime
                    onBlur={this.updateClient}
                    onKeyDown={this.onEnterKeyPress}
                    onChange={this.updateFieldData}
                    className="w-full focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                  />
                  {selectedFieldToUpdate.fieldType === "password" && (
                    <button
                      type="button"
                      onClick={this.onViewPassword}
                      className="focus:outline focus:outline-2 focus:outline-indigo-500 absolute top-2 right-3 rounded-md"
                    >
                      <Icon name={iconType} className="h-6 w-6 text-gray-500" />
                    </button>
                  )}
                </div>
              )}
            </div>
          </td>
          {selectedFieldToUpdate._id === "contactPerson" && (
            <td className="min-w-[20ch] max-w-[30ch] whitespace-nowrap py-2 px-4 text-sm text-gray-900 space-y-2">
              {selectedPersons.map(({ _id, designation }, index) => (
                <MultiSelect
                  key={`designation-${_id}-${designation}-${index}`}
                  items={designationList?.map(designation => {
                    return { _id: designation, name: designation };
                  })}
                  selected={{ name: designation }}
                  type="designation"
                  onChange={selected =>
                    this.handlePersonDataChange(
                      "designation",
                      index,
                      selected.name
                    )
                  }
                  placeholder="Select Designation"
                />
              ))}
            </td>
          )}
          {selectedFieldToUpdate._id === "contactPerson" && (
            <td className="w-6 whitespace-nowrap py-2 px-4 text-sm text-gray-900 text-center">
              <div
                className="grid items-center gap-6 justify-center"
                style={{
                  gridTemplateRows: `repeat(${selectedPersons.length}, 1fr)`
                }}
              >
                {selectedPersons.map(({ _id, isPrimary }, index) => (
                  <input
                    key={`isPrimary-${_id}-${isPrimary}-${index}`}
                    aria-describedby="primary-email"
                    name={`isPrimary-${_id}`}
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-400 cursor-pointer text-indigo-600 focus:ring-indigo-500"
                    checked={isPrimary ?? false}
                    onBlur={_id ? this.updateClient : undefined}
                    onChange={e =>
                      this.handlePersonDataChange(
                        "isPrimary",
                        index,
                        e.target.checked
                      )
                    }
                  />
                ))}
              </div>
            </td>
          )}
          {(selectedFieldToUpdate._id === "contactPerson" ||
            selectedFieldToUpdate._id === "groups") && (
            <td className="px-2 min-w-[8ch] max-w-[10ch]">
              <button
                type="button"
                className={`inline-flex items-center justify-center rounded border border-transparent px-2 py-1.5 text-xs font-medium text-blue-600 hover:underline focus:outline-none ml-auto whitespace-nowrap ${
                  selectedFieldToUpdate._id === "contactPerson" ||
                  (selectedFieldToUpdate._id === "groups" &&
                    this.state.selectedGroups.length < 3)
                    ? "visible"
                    : "invisible"
                }`}
                onClick={() =>
                  this.addMore(selectedFieldToUpdate.fieldType as AddMoreType)
                }
              >
                Add More
              </button>
            </td>
          )}
          <td className="py-2 text-sm text-gray-500 w-6">
            <Popup content={message} className="w-5 h-5 shrink-0">
              {updated ? (
                <CheckIcon className="h-5 w-5 text-green-500" />
              ) : isError ? (
                <XMarkIcon className="h-5 w-5 text-red-500" />
              ) : (
                loading && (
                  <Icon name="loading" className="h-5 w-5 animate-spin" />
                )
              )}
            </Popup>
          </td>
          <td className="min-w-[30rem] py-2 px-4 text-sm text-gray-900 font-bold">
            {loading ? (
              <Icon name="loading" className="h-5 w-5 animate-spin mx-auto" />
            ) : (
              message || (
                <span className="group-focus-within:visible invisible text-gray-600 font-normal italic">
                  Press Enter, Tab or click outside to update
                </span>
              )
            )}
          </td>
        </tr>
      </>
    );
  }
}

export default compose(
  connector,
  withRouter
)(ClientUpdateRow) as React.ComponentType<Props>;
