type SortableItem = { name: string };

export const sortFunction = <T extends SortableItem>(a: T, b: T) => {
  const nameA = a.name.toLowerCase();
  const nameB = b.name.toLowerCase();
  // sort in an ascending order
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

const sortByName = <T extends SortableItem>(
  array: T[],
  descending?: boolean
) => {
  if (descending) {
    return array.sort(sortFunction).reverse();
  }
  return array.sort(sortFunction);
};

export default sortByName;
