import timeTillMidnight, { timeFromMidnight } from "./timeTillMidnight";

export const daysInWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const dueDateList = [
  {
    _id: "dueToday",
    name: "Due Today",
    days: "0"
  },
  {
    _id: "dueTommorow",
    name: "Due Tommorow",
    days: "1"
  },
  {
    _id: "dueInNext3Days",
    name: "Due In Next 3 Days",
    days: "3"
  },
  {
    _id: "dueInNext7Days",
    name: "Due In Next 7 Days",
    days: "7"
  },
  {
    _id: "dueInNext15Days",
    name: "Due In Next 15 Days",
    days: "15"
  },
  {
    _id: "dueInNext30Days",
    name: "Due In Next 30 Days",
    days: "30"
  },
  {
    _id: "overdue",
    name: "Overdue",
    days: "overdue"
  }
] as const;

export type DueDateListOptionType = (typeof dueDateList)[number];

export function getDaysInMonth(month: number, year: number) {
  const date = new Date(year, month, 1);
  const todaysDate = new Date();
  let days: any[] = [];
  while (date.getMonth() === month) {
    const genralDate = new Date(date);
    const splitDate = genralDate.toUTCString().split(" ");
    const isToday = todaysDate.toUTCString() === genralDate.toUTCString();

    const dateValue = {
      value: genralDate.toUTCString(),
      date: splitDate[1],
      day: splitDate[0].split(",")[0],
      isToday: isToday
    };
    days = [...days, dateValue];
    date.setDate(date.getDate() + 1);
  }
  const startingDay = days[0].day;
  const startingDayIndex = daysInWeek.indexOf(startingDay);
  const emptyArray = new Array(startingDayIndex);
  days.unshift(...emptyArray);
  return days;
}

export type DueDateType =
  | DueDateListOptionType["days"]
  | "overdue"
  | "overdueupto7days"
  | "overduemorethan7days";

export function getDueDateFromDays(dueDate: DueDateType) {
  const date = new Date();

  switch (dueDate) {
    case "1": {
      const from = timeFromMidnight(
        new Date(date.setDate(date.getDate() + 1))
      ).toISOString();
      const to = timeTillMidnight(date.setDate(date.getDate())).toISOString();
      return { from, to };
    }
    case "3": {
      const from = timeFromMidnight(
        new Date(date.setDate(date.getDate() + 1))
      ).toISOString();
      const to = timeTillMidnight(
        new Date(date.setDate(date.getDate() + 2))
      ).toISOString();
      return { from, to };
    }
    case "7": {
      const from = timeFromMidnight(
        new Date(date.setDate(date.getDate() + 1))
      ).toISOString();
      const to = timeTillMidnight(
        new Date(date.setDate(date.getDate() + 6))
      ).toISOString();
      return { from, to };
    }
    case "15": {
      const from = timeFromMidnight(
        new Date(date.setDate(date.getDate() + 1))
      ).toISOString();
      const to = timeTillMidnight(
        new Date(date.setDate(date.getDate() + 14))
      ).toISOString();
      return { from, to };
    }
    case "30": {
      const from = timeFromMidnight(
        new Date(date.setDate(date.getDate() + 1))
      ).toISOString();
      const to = timeTillMidnight(
        new Date(date.setDate(date.getDate() + 29))
      ).toISOString();
      return { from, to };
    }
    case "overdue": {
      const from = timeFromMidnight(new Date(2001, 0, 1)).toISOString();
      const to = timeTillMidnight(new Date()).toISOString();
      return { from, to };
    }
    case "overdueupto7days": {
      const from = timeFromMidnight(
        new Date(date.setDate(date.getDate() - 7))
      ).toISOString();
      const to = timeTillMidnight(
        new Date(date.setDate(date.getDate() + 6))
      ).toISOString();
      return { from, to };
    }
    case "overduemorethan7days": {
      const from = timeFromMidnight(new Date(2001, 0, 1)).toISOString();
      const to = timeTillMidnight(
        new Date(date.setDate(date.getDate() - 7))
      ).toISOString();
      return { from, to };
    }
    case "0":
    default: {
      const from = timeFromMidnight(new Date()).toISOString();
      const to = timeTillMidnight(new Date()).toISOString();
      return { from, to };
    }
  }
}

export function getDueDateRange(days: number, next: boolean = false) {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const todayDate = date.getDate();
  const dueDays = new Date(year, month, todayDate + days);

  if (next && days >= 3) {
    const starDate = new Date(year, month, todayDate + 1);
    return { from: starDate, to: dueDays };
  }

  return date < dueDays
    ? { from: date, to: dueDays }
    : { from: dueDays, to: date };
}
