import { useState, ChangeEvent, FormEvent, FC } from "react";
import { connect, ConnectedProps } from "react-redux";
import { compose } from "redux";
import agent from "../../agent";
import Icon from "../../components/Icon";
import ShowHidePassword from "../../components/ShowHidePassword";
import { validPassword } from "../../helpers/regex";
import { withRouter, WithRouterProps } from "../../helpers/withRouter";
import { AppDispatch, RootState } from "../../store";
import { CommonAction } from "../../store/reducers/common";
import { NotifyType } from "../../store/reducers/notification";
import { UPDATE_COMMON, ADD_NOTIFICATION } from "../../store/types";

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateCommon: (payload: CommonAction["payload"]) =>
    dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: NotifyType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = Partial<PropsFromRedux & WithRouterProps>;

const passwordFormFields = [
  { label: "Enter Your Current Password", fieldName: "oldPassword" },
  { label: "Enter Your New Password", fieldName: "newPassword" },
  { label: "Re-Enter Your New Password", fieldName: "confirmPassword" }
] as const;

type PasswordFormFields = (typeof passwordFormFields)[number]["fieldName"];

const passwordValuesInitialState = {
  ...passwordFormFields.reduce(
    (acc, { fieldName }) => ({ ...acc, [fieldName]: "" }),
    {} as Record<PasswordFormFields, string>
  ),
  logoutFromAllOtherDevices: false
};

const formFieldsInititalState = passwordFormFields.reduce(
  (acc, { fieldName }) => ({ ...acc, [fieldName]: false }),
  {} as Record<PasswordFormFields, boolean>
);

function ChangePasswordForm(props: Props) {
  const { addNotification, currentUser } = props;
  const { loggedInWithGoogle } = currentUser || {};

  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState(formFieldsInititalState);

  const [password, setPassword] = useState(passwordValuesInitialState);

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fieldName = e.target.name as
      | PasswordFormFields
      | "logoutFromAllOtherDevices";
    const fieldValue = e.target.value;

    setPassword(prevState => ({
      ...prevState,
      [fieldName]:
        fieldName === "logoutFromAllOtherDevices"
          ? !prevState.logoutFromAllOtherDevices
          : fieldValue
    }));
  };

  const [showPassword, setShowPassword] = useState(formFieldsInititalState);

  const handleShowPassword = (field: keyof typeof showPassword) => {
    setShowPassword(prevState => ({
      ...prevState,
      [field]: !prevState[field]
    }));
  };

  const handlePasswordUpdate = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const {
      oldPassword,
      newPassword,
      confirmPassword,
      logoutFromAllOtherDevices
    } = password;

    const isOldPasswordValid = loggedInWithGoogle
      ? true
      : validPassword.test(oldPassword);
    const isNewPasswordValid = validPassword.test(newPassword);

    if (!isOldPasswordValid || !isNewPasswordValid) {
      setPasswordError(prevState => ({
        ...prevState,
        oldPassword: !isOldPasswordValid,
        newPassword: !isNewPasswordValid
      }));

      return addNotification?.(
        `Invalid ${!isOldPasswordValid ? "Old" : "New"} Password`,
        "Password should be minimum 8 characters long and should contain at least one uppercase letter, one lowercase letter, one number and one special character",
        "danger"
      );
    }

    if (newPassword === oldPassword) {
      setPasswordError(prevState => ({ ...prevState, newPassword: true }));

      return addNotification?.(
        "Password is same",
        "New password and old password cannot be same",
        "danger"
      );
    }

    if (newPassword !== confirmPassword) {
      setPasswordError(prevState => ({ ...prevState, confirmPassword: true }));

      return addNotification?.(
        "Password does not match",
        "New password and confirm password do not match",
        "danger"
      );
    }

    setPasswordError(formFieldsInititalState);
    setLoading(true);
    agent.Auth.changePassword(
      oldPassword,
      newPassword,
      logoutFromAllOtherDevices
    )
      .then(res => {
        setPassword(passwordValuesInitialState);
        setPasswordError(formFieldsInititalState);

        addNotification?.(
          "Success",
          "Password updated successfully",
          "success"
        );
      })
      .catch(err => {
        const errMessage =
          err.response?.data?.message ||
          err.response?.data.error ||
          "Error in updating password";

        addNotification?.("Error", errMessage, "danger");
      })
      .finally(() => setLoading(false));
  };

  return (
    <form
      onSubmit={handlePasswordUpdate}
      onReset={() => setPassword(passwordValuesInitialState)}
      className="mt-8 divide-y divide-gray-200"
    >
      <div className="space-y-1">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Reset Password
        </h3>
      </div>
      <div className="mt-6">
        <dl className="divide-y divide-gray-200">
          {passwordFormFields.map(({ label, fieldName }) => {
            if (loggedInWithGoogle && fieldName === "oldPassword") {
              return null;
            }

            return (
              <div
                key={fieldName}
                className="py-4 grid sm:grid-cols-2 md:grid-cols-3 items-center gap-4 sm:py-5"
              >
                <dt className="text-sm font-medium text-gray-500">{label}</dt>
                <dd className="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0">
                  <div className="grid items-center relative">
                    <input
                      type={showPassword[fieldName] ? "text" : "password"}
                      name={fieldName}
                      required
                      value={password[fieldName]}
                      onChange={onPasswordChange}
                      className={`block w-full sm:min-w-[16rem] pr-10 border shadow-sm appearance-none px-3 py-2 sm:text-sm rounded-md ${
                        passwordError[fieldName]
                          ? "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:border-red-500"
                          : "border-gray-300 placeholder-gray-400 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500"
                      }`}
                    />

                    <ShowHidePassword
                      showPassword={showPassword[fieldName]}
                      onClick={() => handleShowPassword(fieldName)}
                    />
                  </div>
                </dd>
              </div>
            );
          })}
          <div className="py-4 grid sm:grid-cols-2 md:grid-cols-3 items-center gap-4 sm:py-5 sm:pt-5">
            <dt></dt>
            <dd className="mt-1 flex justify-between text-sm text-gray-900 sm:mt-0">
              <span className="flex-grow flex gap-3 items-center">
                <input
                  type="checkbox"
                  name="logoutFromAllOtherDevices"
                  id="logoutFromAllOtherDevices"
                  className="appearance-none rounded-md border-gray-300 shadow-sm text-indigo-600 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  checked={password.logoutFromAllOtherDevices}
                  onChange={onPasswordChange}
                />
                <label
                  htmlFor="logoutFromAllOtherDevices"
                  className="cursor-pointer"
                >
                  Logout from all other devices
                </label>
              </span>
            </dd>
          </div>
        </dl>
      </div>
      <div className="py-6 sm:flex sm:justify-end">
        <button
          type="reset"
          className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:border-indigo-500 focus:ring-indigo-500 sm:w-32 sm:text-sm"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={loading}
          className="mt-3 sm:ml-4 w-full inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-32 sm:text-sm"
        >
          <span className="w-full flex justify-end">
            {loading ? <Icon name="loading" className="mr-2" /> : null}
          </span>
          <span>Update</span>
          <span className="w-full"></span>
        </button>
      </div>
    </form>
  );
}

export default compose<FC<Props>>(connector, withRouter)(ChangePasswordForm);
