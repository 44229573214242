import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import TagManager from "react-gtm-module";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { NotifyType, onNotify } from "../../store/reducers/notification";
import agent from "../../agent";
import Button from "../../components/Button";
import RadioButtonGroup from "../../components/RadioButtonGroup";
import { formatDate } from "../../helpers/formatDate";
import getCommaSeparatedValue from "../../helpers/getCommaSeparatedValue";
import validGSTIN from "../../helpers/GSTValidationFunction";
import Plans from "./Plans";
import { loadScript } from "../../lib/loadScript";
import Dashboard, { getAllFirms } from "../../components/Dashboard";
import { CommonAction } from "../../store/reducers/common";
import { UPDATE_COMMON, ADD_NOTIFICATION } from "../../store/types";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Subscription"
  },
  dataLayerName: "PageDataLayer"
};

type AvailablePaymentType = {
  title: "Purchase" | "Renewal" | "Upgrade";
  description: string;
};

const initialPurchaseType: AvailablePaymentType = {
  title: "Purchase",
  description: "Purchase a new plan for your firm."
};

export type PurchaseType = AvailablePaymentType["title"];

interface CurrentSubscription {
  planName: string;
  currentPlanId: string;
  expiryDate: string;
  planStartDate: string;
  paymentTypesAvailable: AvailablePaymentType[];
}

const clientLimitHeader = ["Particulars", "GST Clients", "Total Clients"];

const clientLimitRows = [
  "Limit as per your Plan",
  "Currently Used (Only Active Clients)",
  "Available Limit",
  "Inactive Clients"
] as const;

interface ClientLimits {
  gstClientsActive: number;
  gstClientsInactive: number;
  gstClientLimit: number;
  otherClientsActive: number;
  otherClientsInactive: number;
  itAndOtherClientLimit: number;
}

export type PlanName = "Free" | "Basic" | "Premium" | "Enterprise";

export interface AvailablePlan {
  _id: string;
  name: PlanName;
  type: "paid";
  price: number;
  active: boolean;
  expiry: number;
  gstClientLimit: number;
  itAndOtherClientLimit: number;
}

export interface PriceDetails {
  totalAmount: number;
  totalGST: number;
  totalAmountWithGST: number;
  grossAmountExcludingGST: number;
  newExpiryDate: Date;
  creditsLeft: number | null;
  planPrice: number;
  currentExpiryDate: Date;
  daysDifference: number;
}

export interface OrderDetails {
  _id: string;
  status: "PENDING" | "TXN_SUCCESS" | "TXN_FAILURE";
  userId: string;
  workSpaceId: string;
  gstin: string;
  gstinName: string;
  amount: number;
  expiryDate: Date;
  planId: number;
  orderId: string;
  paymentType: NonNullable<PurchaseType>;
  respmsg?: string;
}

interface State {
  loading: boolean;
  currentSubscription: CurrentSubscription;
  clientLimits: ClientLimits;
  purchaseType: AvailablePaymentType | undefined;
  availablePlans: AvailablePlan[];
  selectedPlan: AvailablePlan | null;
  priceDetails: PriceDetails;
  gstin: string;
  legalName: string;
  address: string;
  [key: string]: any;
}

const initialState = {
  loading: false,
  currentSubscription: {
    planName: "",
    currentPlanId: "",
    expiryDate: "",
    planStartDate: "",
    paymentTypesAvailable: []
  },
  clientLimits: {
    gstClientsActive: 0,
    gstClientsInactive: 0,
    gstClientLimit: 0,
    otherClientsActive: 0,
    otherClientsInactive: 0,
    itAndOtherClientLimit: 0
  },
  purchaseType: undefined as AvailablePaymentType | undefined,
  availablePlans: [],
  selectedPlan: null,
  priceDetails: {} as PriceDetails,
  gstin: "",
  legalName: "",
  address: ""
};

const Subscription = () => {
  const commonState = useSelector((state: RootState) => state.common);
  const [state, setState] = useState<State>(initialState);
  const pathParams = useParams();
  const dispatch = useDispatch();
  const redirect = useNavigate();

  const paymentDetailsRef = useRef<HTMLDivElement | null>(null);
  const plansRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    document.title = "Subscriptions - TaxPido PMS";
  }, []);

  useEffect(() => {
    getCurrentSubscription();
    getClientLimits();
  }, [pathParams.firmId, commonState?.currentFirm?._id]);

  useEffect(() => {
    setState(prev => ({
      ...prev,
      purchaseType: undefined,
      availablePlans: [],
      selectedPlan: null
    }));
  }, [commonState?.currentFirm?._id]);

  useEffect(() => {
    plansRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [state.availablePlans]);

  useEffect(() => {
    paymentDetailsRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [state.priceDetails.totalAmount]);

  useEffect(() => {
    setState(prev => ({ ...prev, availablePlans: [], selectedPlan: null }));
  }, [state.purchaseType]);

  useEffect(() => {
    setState(prev => ({
      ...prev,
      priceDetails: {} as PriceDetails,
      gstin: "",
      legalName: "",
      address: ""
    }));
  }, [commonState?.currentFirm?._id, state.selectedPlan, state.purchaseType]);

  const getCurrentSubscription = () => {
    const workSpaceId = pathParams.firmId;

    setState(prev => ({ ...prev, loading: true }));
    workSpaceId &&
      agent.Payment.getCurrentSubscription(workSpaceId)
        .then((res: CurrentSubscription) => {
          setState(prev => ({
            ...prev,
            currentSubscription: res,
            loading: false
          }));
          res.paymentTypesAvailable.length > 0 &&
            handlePurchaseTypeSelection(res.paymentTypesAvailable[0]);
        })
        .catch(() => {
          setState(prev => ({ ...prev, loading: false }));
          dispatch(
            onNotify(
              "Error while fetching current subscription",
              "Could not fetch current subscription",
              "error"
            )
          );
        });
  };

  const getClientLimits = () => {
    const workSpaceId = pathParams.firmId;

    setState(prev => ({ ...prev, loading: true }));
    workSpaceId &&
      agent.Payment.getClientLimits(workSpaceId)
        .then((res: ClientLimits) => {
          setState(prev => ({ ...prev, clientLimits: res, loading: false }));
        })
        .catch(() => {
          setState(prev => ({ ...prev, loading: false }));
          dispatch(
            onNotify(
              "Error while fetching client limits",
              "Could not fetch client limits",
              "error"
            )
          );
        });
  };

  const getClientLimitRowData = (
    row: (typeof clientLimitRows)[number],
    header: "GST" | "ITAndOther"
  ) => {
    const { clientLimits } = state;

    switch (row) {
      case "Limit as per your Plan":
        return header === "GST"
          ? clientLimits.gstClientLimit
          : clientLimits.itAndOtherClientLimit;
      case "Currently Used (Only Active Clients)":
        return header === "GST"
          ? clientLimits.gstClientsActive
          : clientLimits.otherClientsActive;
      case "Available Limit":
        return header === "GST"
          ? clientLimits.gstClientLimit - clientLimits.gstClientsActive
          : clientLimits.itAndOtherClientLimit -
              clientLimits.otherClientsActive;
      case "Inactive Clients":
        return header === "GST"
          ? clientLimits.gstClientsInactive
          : clientLimits.otherClientsInactive;
    }
  };

  const getPlansList = () => {
    setState(prev => ({ ...prev, loading: true }));
    const workSpaceId = pathParams.firmId;

    workSpaceId &&
      state.purchaseType &&
      agent.Payment.getPlansList(workSpaceId, state.purchaseType.title)
        .then((res: { availablePlans: AvailablePlan[] }) => {
          let filteredPlans = res.availablePlans;

          setState(prev => ({
            ...prev,
            availablePlans: filteredPlans,
            loading: false
          }));
        })
        .catch(() => {
          setState(prev => ({ ...prev, loading: false }));
          dispatch(
            onNotify(
              "Error while fetching plans list",
              "Could not fetch plans list",
              "error"
            )
          );
        });
  };

  const handlePurchaseTypeSelection = (item: AvailablePaymentType) => {
    setState(prev => ({ ...prev, purchaseType: item }));
  };

  useEffect(() => {
    getPlansList();
  }, [state.purchaseType]);

  const handlePlanSelection = (plan: AvailablePlan) => {
    setState(prev => ({ ...prev, loading: true, selectedPlan: plan }));
    const workSpaceId = pathParams.firmId;

    workSpaceId &&
      state.purchaseType &&
      agent.Payment.getCalculatedPrice(
        workSpaceId,
        plan._id,
        state.purchaseType.title
      )
        .then((res: PriceDetails) => {
          setState(prev => ({ ...prev, priceDetails: res, loading: false }));
        })
        .catch(() => {
          setState(prev => ({ ...prev, loading: false }));
          dispatch(
            onNotify(
              "Error while fetching price details",
              "Could not fetch price details",
              "error"
            )
          );
        });
  };

  const convertToFreePlan = () => {
    const workSpaceId = pathParams.firmId;
    setState(prev => ({ ...prev, loading: true }));

    workSpaceId &&
      agent.Payment.convertToFreePlan(workSpaceId)
        .then((res: { message: string }) => {
          setState(prev => ({ ...prev, loading: false }));
          dispatch(
            onNotify(
              "Plan Downgraded",
              res.message === "success"
                ? "Plan downgraded successfully"
                : res.message,
              "success"
            )
          );
          getCurrentSubscription();
          getAllFirms(
            (payload: CommonAction["payload"]) =>
              dispatch({ type: UPDATE_COMMON, payload }),
            (title: string, message: string, type: NotifyType) =>
              dispatch({
                type: ADD_NOTIFICATION,
                payload: { title, message, type }
              }),
            redirect
          );
        })
        .catch(err => {
          setState(prev => ({ ...prev, loading: false }));
          dispatch(
            onNotify(
              "Could not downgrade plan",
              err.response?.data?.message || err.message || err,
              "error"
            )
          );
        });
  };

  /*   const validateAndGetGSTINDetails = () => {
    const workSpaceId = pathParams.firmId;

    if (state.gstin.length === 15) {
      const isValid = validGSTIN(state.gstin);

      if (isValid) {
        setState(prev => ({ ...prev, loading: true }));
        workSpaceId &&
          agent.Clients.getGSTdetails(workSpaceId, state.gstin)
            .then(response => {
              setState(prev => ({
                ...prev,
                loading: false,
                legalName:
                  response.gstDetails?.name || response.gstDetails?.lgnm,
                address: response.gstDetails?.address
              }));
            })
            .catch((err: any) => {
              setState(prev => ({ ...prev, loading: false }));
              dispatch(
                onNotify(
                  "Could not get GSTIN Data",
                  err?.response?.data?.message || err?.message || err,
                  "error"
                )
              );
            });
      } else {
        dispatch(
          onNotify(
            "Incorrect GSTIN",
            "Please enter a valid GSTIN to proceed.",
            "error"
          )
        );
      }
    } else {
      setState(prev => ({ ...prev, legalName: "", address: "" }));
    }
  }; */

  /*  const handleGstin = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState(prev => ({ ...prev, [name]: value }));
  }; */

  /* useEffect(() => {
    state.gstin && validateAndGetGSTINDetails();
  }, [state.gstin]); */

  async function displayRazorpay() {
    const workSpaceId = pathParams?.firmId;
    const {
      selectedPlan,
      purchaseType,
      priceDetails,
      gstin,
      legalName,
      address
    } = state;

    if (state.gstin) {
      const isValidGSTIN = validGSTIN(state.gstin);

      if (!isValidGSTIN) {
        dispatch(
          onNotify(
            "Invalid GSTIN",
            "Please enter a valid GSTIN to proceed",
            "danger"
          )
        );
        return;
      }
    }

    if (!workSpaceId || !selectedPlan || !purchaseType || !priceDetails) {
      dispatch(
        onNotify("Error", "Invalid data to proceed with payment", "danger")
      );
      return;
    }

    setState(prev => ({ ...prev, loading: true }));

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      dispatch(onNotify("Error", "Razorpay SDK failed to load!", "danger"));
      return;
    }

    agent.Payment.initializePayment(
      workSpaceId,
      selectedPlan?._id as string,
      purchaseType.title,
      priceDetails.totalAmountWithGST,
      gstin || "",
      legalName || "",
      address || "",
      priceDetails.newExpiryDate
    )
      .then((result: any) => {
        setState(prev => ({ ...prev, loading: false }));
        const { amount, orderId } = result;

        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY_ID,
          amount: amount,
          currency: "INR",
          name: "TaxPido",
          description: `Payment for ${purchaseType.title} of ${selectedPlan.name} Plan`,
          order_id: orderId,
          prefill: {
            email: commonState.currentUser?.email || "",
            contact: commonState.currentUser?.mobileNumber || ""
          },
          readonly: { email: true },
          notes: { appName: "PMS" },
          handler: async function (response: any) {
            const data = {
              orderId,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature
            };

            agent.Payment.updateOrder(data)
              .then((res: any) => {
                if (res.success) {
                  dispatch(
                    onNotify(
                      "Payment Captured",
                      "Payment has been successfully captured",
                      "success"
                    )
                  );
                  redirect(`/paymentresponse?orderid=${orderId}`);
                }
              })
              .catch((err: any) => {
                console.log(err);
              });
          },
          modal: { confirm_close: true }
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      })
      .catch(err => {
        console.log(err);

        setState(prev => ({ ...prev, loading: false }));
        dispatch(
          onNotify(
            "Error while fetching price details",
            "Could not fetch price details",
            "error"
          )
        );
      });
  }

  TagManager.dataLayer(tagManagerArgs);

  return (
    <Dashboard>
      <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 space-y-8">
        <div className="flex items-center justify-between gap-6">
          <h1 className="text-2xl font-semibold text-gray-900 ">
            Subscription Details
          </h1>
          {/* <Button
            name="Invoices"
            type="link"
            to={`/${pathParams.firmId}/invoices`}
            icon={DocumentTextIcon}
          /> */}
        </div>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-8 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Current Subscription
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Shows your current subscriptions
            </p>
          </div>
          <div className="border-t border-gray-200 px-8 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-gray-200 pb-4">
              <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Current FIrm
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {commonState?.currentFirm?.name}
                </dd>
              </div>
              <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Current Plan
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {state.currentSubscription.planName}
                </dd>
              </div>
              <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Plan Start Date
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {formatDate(state.currentSubscription.planStartDate, false)}
                </dd>
              </div>
              <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Plan Expiry Date
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {formatDate(state.currentSubscription.expiryDate, false)}
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg py-4 px-6">
          <div className="pb-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Client limits
            </h3>
          </div>
          <div className="bg-white rounded-b-lg overflow-hidden">
            <div className="sm:flex-auto border-2 border-gray-200 rounded-md">
              <div id="table-scroll" className="overflow-auto">
                <table className="w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      {clientLimitHeader.map(header => (
                        <th
                          key={header}
                          className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider whitespace-nowrap border-b border-gray-200"
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {clientLimitRows.map(row => (
                      <tr key={row} className="even:bg-gray-50">
                        <td
                          className={`px-6 py-4 whitespace-nowrap text-sm text-gray-900 ${
                            row === "Available Limit" && "font-bold"
                          }`}
                        >
                          {row}
                        </td>
                        <td
                          className={`px-6 py-4 whitespace-nowrap text-sm text-gray-900 ${
                            row === "Available Limit" && "font-bold"
                          }`}
                        >
                          {state.loading ? (
                            <Skeleton className="w-full" />
                          ) : (
                            getClientLimitRowData(row, "GST")
                          )}
                        </td>
                        <td
                          className={`px-6 py-4 whitespace-nowrap text-sm text-gray-900 ${
                            row === "Available Limit" && "font-bold"
                          }`}
                        >
                          {state.loading ? (
                            <Skeleton className="w-full" />
                          ) : (
                            getClientLimitRowData(row, "ITAndOther")
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {state.currentSubscription.paymentTypesAvailable.length > 0 && (
          <div className="bg-white shadow overflow-hidden sm:rounded-lg py-4 px-6">
            <div className="pb-2">
              <RadioButtonGroup
                label="Select Purchase Type"
                options={state.currentSubscription.paymentTypesAvailable}
                selectedOption={state.purchaseType}
                setSelectedOption={handlePurchaseTypeSelection}
              />
            </div>
          </div>
        )}
        {state.purchaseType && state.availablePlans.length > 0 && (
          <div
            ref={node => (plansRef.current = node)}
            className="bg-white shadow overflow-hidden sm:rounded-lg"
          >
            <Plans
              availablePlans={state.availablePlans}
              onPlanSelection={handlePlanSelection}
              convertToFreePlan={convertToFreePlan}
            />
            <p className="my-4 mx-12 text-sm">
              <span className="font-bold">Note</span> -{" "}
              <span className="italic">
                All of the plans have the same features, only the limit of GST
                and Non-GST clients are different in each of the above plans.
              </span>
            </p>
          </div>
        )}
        {state.priceDetails.totalAmount > 0 && (
          <>
            <div
              ref={node => (paymentDetailsRef.current = node)}
              className="bg-white shadow overflow-hidden sm:rounded-lg py-4 px-6"
            >
              <div className="pb-4">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Purchase Details
                </h3>
              </div>
              <div className="border-t border-gray-200 px-8 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200 pb-4">
                  <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Payment For
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {state.purchaseType?.title}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Selected Plan
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {state.selectedPlan?.name}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Current Expiry Date
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {state.priceDetails.currentExpiryDate &&
                        formatDate(state.priceDetails.currentExpiryDate, false)}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      New Expiry Date
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {state.priceDetails.newExpiryDate &&
                        formatDate(state.priceDetails.newExpiryDate, false)}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <div className="bg-white shadow overflow-hidden sm:rounded-lg py-4 px-6">
              <div className="pb-4">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Payment Calculation
                </h3>
              </div>
              <div className="border-t border-gray-200 px-8 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200 pb-4">
                  {state.purchaseType?.title === "Upgrade" ? (
                    <>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-semibold text-gray-700 sm:col-span-2">
                          Calculation of Plan Charges
                        </dt>
                      </div>
                      <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Gross Plan Charges (A)
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          ₹{" "}
                          {getCommaSeparatedValue(
                            state.priceDetails.planPrice,
                            true
                          )}
                        </dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-semibold text-gray-700 sm:col-span-2">
                          Calculation of Proportionate Amount to be Deducted
                        </dt>
                      </div>
                      <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Current Expiry Date
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {state.priceDetails.currentExpiryDate &&
                            formatDate(
                              state.priceDetails.currentExpiryDate,
                              false
                            )}
                        </dd>
                      </div>
                      <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Days Left
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {state.priceDetails.daysDifference}
                        </dd>
                      </div>
                      <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Credits Left (B)
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {state.priceDetails.creditsLeft
                            ? `₹ ${getCommaSeparatedValue(
                                state.priceDetails.creditsLeft,
                                true
                              )}`
                            : ""}
                        </dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-semibold text-gray-700 sm:col-span-2">
                          Calculation of Payable Amount
                        </dt>
                      </div>
                      <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Net Plan Charges (A-B)
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          ₹{" "}
                          {getCommaSeparatedValue(
                            state.priceDetails.totalAmount,
                            true
                          )}
                        </dd>
                      </div>
                    </>
                  ) : (
                    <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Plan Charges
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        ₹{" "}
                        {getCommaSeparatedValue(
                          state.priceDetails.planPrice,
                          true
                        )}
                      </dd>
                    </div>
                  )}
                  {/* <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 items-center sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Add GST @ 18%
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <div className="flex flex-col sm:flex-row sm:items-center gap-4 lg:gap-16">
                        <div className="whitespace-nowrap">
                          ₹{" "}
                          {getCommaSeparatedValue(
                            state.priceDetails.totalGST,
                            true
                          )}
                        </div>
                        <div className="flex gap-4 items-center md:gap-6 flex-wrap">
                          <div className="min-w-[15rem]">
                            <input
                              type="text"
                              placeholder="Enter Your GSTIN"
                              value={state.gstin}
                              onChange={handleGstin}
                              className="border border-gray-300 rounded-md px-3 py-2 mt-1 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm"
                            />
                          </div>
                          <div className="font-semibold">{state.legalName}</div>
                        </div>
                      </div>
                    </dd>
                  </div> */}
                  <div className="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Net Payable Amount
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      ₹{" "}
                      {getCommaSeparatedValue(
                        state.priceDetails.totalAmountWithGST,
                        true
                      )}
                    </dd>
                  </div>
                </dl>
              </div>

              {/* the state.priceDetails.totalAmountWithGST value is same as the state.priceDetails.totalAmount because the GST is not added to the total amount in the backend */}

              {state.purchaseType && state.priceDetails.totalAmountWithGST && (
                <Button
                  name="Proceed to Payment"
                  icon={state.loading ? "loading" : "outline/rupee"}
                  className={`inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                    state.priceDetails.totalAmountWithGST >= 0
                      ? "bg-indigo-600 hover:bg-indigo-700"
                      : "bg-indigo-300"
                  } disabled:cursor-not-allowed disabled:opacity-50`}
                  onClick={displayRazorpay}
                  disabled={
                    state.priceDetails.totalAmountWithGST < 0 || state.loading
                  }
                />
              )}
            </div>
          </>
        )}
      </div>
    </Dashboard>
  );
};

export default Subscription;
