import React, { Fragment, Component } from "react";
import { Menu, Transition } from "@headlessui/react";
import { connect, ConnectedProps } from "react-redux";
import Icon from "../../components/Icon";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import Dashboard from "../../components/Dashboard";
import Pagination from "../../components/Pagination";
import Skeleton from "react-loading-skeleton";
import DeleteModal from "../../components/DeleteModal";
import { withRouter } from "../../helpers/withRouter";
import { compose } from "redux";
import Popup from "../../components/Popup";
import Button from "../../components/Button";
import { MessageType } from "../../helpers/types";
import agent from "../../agent";
import MultiSelect from "../../components/MultiSelect";
import AddEditTemplateModal from "./AddEditTemplateModal";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Message Template List"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common,
  ...state.notification
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: (payload: any) => dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ModalType = "add" | "edit" | "";

interface State {
  loading: boolean;
  posX: any;
  posY: any;
  hoverX: any;
  hoverY: any;
  showBackDrop: boolean;
  active: boolean;
  messageType: MessageType;
  headers: string[];
  templateList: any[];
  totalRecords: number;
  selectedRow: any;
  showAddEditModal: boolean;
  modalType: ModalType;
  showDeleteModal: boolean;
  currPage: number;
  chunkSize: number;
}

class TemplateList extends Component<PropsFromRedux, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      posX: null,
      posY: null,
      hoverX: null,
      hoverY: null,
      showBackDrop: false,
      active: true,
      messageType: "WhatsApp",
      headers: ["name", "template", "action"],
      templateList: [],
      totalRecords: 0,
      selectedRow: null,
      showAddEditModal: false,
      modalType: "",
      showDeleteModal: false,
      currPage: 0,
      chunkSize: 25
    };
  }

  messageTypes = ["Whatsapp", "Email"/* , "SMS" */];

  openAddEditModal = (modalType: ModalType) => {
    this.setState({
      showAddEditModal: true,
      modalType,
      showBackDrop: false
    });
  };

  closeAddEditModal = () => {
    this.setState({ showAddEditModal: false, modalType: "" });
  };

  onActionClick = (e: any) => {
    const screenClosness = window.innerHeight - e.clientY;
    const positionY = screenClosness < 125 ? e.clientY - 125 : e.clientY;
    this.setState({
      posX: e.clientX,
      posY: positionY,
      showBackDrop: true
    });
  };

  onDropdownClick = () => {
    this.setState({ showBackDrop: false });
  };

  openDeleteModal = (template: any) => {
    this.setState({ selectedRow: template, showBackDrop: false });
    this.deleteModalSetOpen(true);
  };

  deleteModalSetOpen = (open: boolean) => {
    this.setState({
      showDeleteModal: open
    });
  };

  handlePageClick = (data: any) => {
    this.setState({ currPage: data.selected });
  };

  handleItemPerPage = (value: any) => {
    this.setState({ chunkSize: value.name, currPage: 0 });
  };

  getTemplateList = () => {
    const workSpaceId = (this.props as any).params.firmId;
    const messageType = this.state.messageType.toUpperCase() as MessageType;

    this.setState({ loading: true });
    agent.Messages.getTemplatesList(workSpaceId, messageType)
      .then((res: any) => {
        this.setState({
          templateList: res.messageTemplates,
          totalRecords: res.messageTemplates.length,
          loading: false
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).addNotification(
          "Error",
          typeof err?.response?.data?.message === "object"
            ? "Error while fetching templates"
            : err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  handlemessageTypeChange = (value: any) => {
    this.setState(
      { messageType: value.name, currPage: 0 },
      this.getTemplateList
    );
  };

  onLoad = () => {
    this.getTemplateList();
  };

  componentDidMount() {
    document.title = "Message Templates - TaxPido PMS";
    this.getTemplateList();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.messageType !== this.state.messageType) {
      this.state.messageType === "Email"
        ? this.setState({ headers: ["name", "subject", "template", "action"] })
        : this.setState({ headers: ["name", "template", "action"] });
    }
  }

  render() {
    TagManager.dataLayer(tagManagerArgs);

    return (
      <Dashboard>
        {this.state.showAddEditModal && (
          <AddEditTemplateModal
            type={this.state.modalType}
            selectedRow={this.state.selectedRow}
            showModal={this.state.showAddEditModal}
            closeModal={this.closeAddEditModal}
            onLoad={this.onLoad}
          />
        )}
        {this.state.showDeleteModal && (
          <DeleteModal
            type="message template"
            state={this.state}
            onLoad={this.onLoad}
            deleteModalSetOpen={this.deleteModalSetOpen}
          />
        )}
        <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">
            Message Templates
          </h1>
        </div>
        <div className="px-4 sm:px-6 md:px-8 flex justify-between mt-6">
          <Button
            name="Add Template"
            onClick={() => this.openAddEditModal("add")}
            icon="add"
          />
          <MultiSelect
            type="select message template"
            placeholder="Select Message Template"
            items={this.messageTypes.map(messageType => ({
              _id: messageType.toUpperCase(),
              name: messageType
            }))}
            selected={{ name: this.state.messageType }}
            onChange={this.handlemessageTypeChange}
          />
          <Button
            type="link"
            to={`/${(this.props as any).params.firmId}/bulk-messages`}
            name="Go back to messages"
            icon="arrow-left"
          />
        </div>

        {this.state.totalRecords > 0 ? (
          <div className="max-w-full mx-auto">
            <div className="mt-6 lg:mx-8 flex flex-col">
              <div id="table-scroll" className="overflow-auto">
                <div className="inline-block min-w-full py-2 px-1 align-middle">
                  <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                    <table className="min-w-full border-collapse border shadow-sm">
                      <thead className="bg-gray-50 border-b sticky top-0 z-[8]">
                        <tr>
                          {this.state.headers.map(header => (
                            <th
                              key={`${header}-list`}
                              scope="col"
                              className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${
                                header.toLowerCase() === "action"
                                  ? "flex justify-center"
                                  : ""
                              }`}
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      {this.state.totalRecords === 0 ? (
                        <tr className="w-full bg-white">
                          <td
                            colSpan={this.state.headers.length}
                            className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900"
                          >
                            No record found matching your search criteria
                          </td>
                        </tr>
                      ) : (
                        <tbody className="bg-white">
                          {!this.state.loading && this.state.templateList
                            ? this.state.templateList.map(
                                (template: any, index: any) => (
                                  <tr
                                    key={template._id}
                                    className={
                                      index % 2 === 0
                                        ? undefined
                                        : "bg-gray-100"
                                    }
                                  >
                                    <td className="max-w-[20ch] px-6 py-4 whitespace-nowrap text-sm font-bold text-gray-900">
                                      <Popup
                                        content={template.name}
                                        className="w-fit max-w-full"
                                      >
                                        <p className="truncate">
                                          {template.name}
                                        </p>
                                      </Popup>
                                    </td>
                                    {this.state.messageType === "Email" && (
                                      <td className="max-w-[20ch] px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        <Popup
                                          content={template?.emailSubject}
                                          className="w-fit max-w-full"
                                        >
                                          <p className="truncate">
                                            {template?.emailSubject}
                                          </p>
                                        </Popup>
                                      </td>
                                    )}
                                    <td className="max-w-[20ch] px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                      <Popup
                                        content={template?.template}
                                        className="w-fit max-w-full"
                                      >
                                        <p className="truncate">
                                          {template?.template}
                                        </p>
                                      </Popup>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex justify-center">
                                      <Menu as="div" className="inline-block">
                                        <Menu.Button
                                          onClick={this.onActionClick}
                                        >
                                          <span className="sr-only">
                                            Open options
                                          </span>
                                          <Icon
                                            name="horizontal-dots"
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </Menu.Button>
                                        {this.state.showBackDrop && (
                                          <div
                                            className="fixed top-0 left-0 z-10 w-full h-screen"
                                            onClick={this.onDropdownClick}
                                          ></div>
                                        )}

                                        <Transition
                                          as={Fragment}
                                          enter="transition ease-out duration-100"
                                          enterFrom="transform opacity-0 scale-95"
                                          enterTo="transform opacity-100 scale-100"
                                          leave="transition ease-in duration-75"
                                          leaveFrom="transform opacity-100 scale-100"
                                          leaveTo="transform opacity-0 scale-95"
                                        >
                                          <div
                                            style={{
                                              position: "fixed",
                                              zIndex: 100,
                                              top: `${this.state.posY}px`,
                                              left: `${
                                                this.state.posX - 230
                                              }px`,
                                              margin: "0.5rem"
                                            }}
                                          >
                                            <Menu.Items className="overscroll-none mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                              <div className="py-1">
                                                <Menu.Item>
                                                  <button
                                                    className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                                                    onClick={() => {
                                                      this.openAddEditModal(
                                                        "edit"
                                                      );
                                                      this.setState({
                                                        selectedRow: template
                                                      });
                                                    }}
                                                  >
                                                    <Icon
                                                      name="edit"
                                                      className="h-5 w-5 mr-2"
                                                    />
                                                    <span>Edit</span>
                                                  </button>
                                                </Menu.Item>
                                                <Menu.Item>
                                                  <button
                                                    className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                                                    onClick={() =>
                                                      this.openDeleteModal(
                                                        template
                                                      )
                                                    }
                                                  >
                                                    <Icon
                                                      name="delete"
                                                      className="h-5 w-5 mr-2"
                                                    />
                                                    <span>Delete</span>
                                                  </button>
                                                </Menu.Item>
                                              </div>
                                            </Menu.Items>
                                          </div>
                                        </Transition>
                                      </Menu>
                                    </td>
                                  </tr>
                                )
                              )
                            : [...Array(5)].map((e, i) => (
                                <tr
                                  key={"skeleton-row-" + i}
                                  className="bg-white"
                                >
                                  {[...Array(this.state.headers.length)].map(
                                    (e, i) => (
                                      <td
                                        key={"skeleton-col-" + i}
                                        className="w-3/10 px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                      >
                                        <Skeleton />
                                      </td>
                                    )
                                  )}
                                </tr>
                              ))}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center my-10 border-2 border-gray-300 border-dashed p-16 md:mx-40 sm:mx-0 rounded-lg">
            <Icon
              name="outline/document-add"
              className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
              strokeWidth="1"
            />
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              No Templates Found
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Get started by adding a new Template.
            </p>
            <div className="mt-6">
              <button
                type="button"
                className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                onClick={() => this.setState({ showAddEditModal: true })}
              >
                <Icon name="add" className="h-4 w-4 mr-2" />
                Add Template
              </button>
            </div>
          </div>
        )}
        <Pagination
          displayRecords={this.state.templateList}
          totalRecords={this.state.totalRecords}
          currPage={this.state.currPage}
          chunkSize={this.state.chunkSize}
          handlePageClick={this.handlePageClick}
          handleItemPerPage={this.handleItemPerPage}
          className="my-4"
        />
      </Dashboard>
    );
  }
}

export default compose(
  connector,
  withRouter
)(TemplateList) as React.ComponentType;
