import { ChangeEvent, Component } from "react";
import Popup from "../../components/Popup";
// Import to Display skeleton while loading data
import "react-loading-skeleton/dist/skeleton.css";
import { connect, ConnectedProps } from "react-redux";
import { compose } from "redux";
import { withRouter, WithRouterProps } from "../../helpers/withRouter";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import { RootState } from "../../store";
import agent from "../../agent";
import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import Icon from "../../components/Icon";
import { PersonFields } from ".";
import {
  CustomField,
  CustomFieldInPersonOrClient,
  Email,
  Person
} from "../../helpers/types";
import { phoneCode } from "../../constants/countryPhoneCodes";
import { formatDate } from "../../helpers/formatDate";
import { validEmail, validPAN } from "../../helpers/regex";
import ActiveModal from "../../components/ActiveModal";
import { isValidDate } from "../../helpers/TimeValidationFunction";

const minAllowedDate = "1900-01-01";
const maxAllowedDate = "2099-12-31";

const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: <T,>(payload: T) => dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: string) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends Partial<PropsFromRedux & WithRouterProps> {
  index: number;
  personData: Person;
  getPersonById: (id: string) => void;
  selectedFieldToUpdate: PersonFields;
  customFields: CustomField[];
  selectedCustomField: CustomField | { _id: ""; name: ""; type: "" };
  openCustomFieldModal: () => void;
}

type AddMoreType = "email" | "mobile" | "customFields";

interface State {
  initialPersonData: Person;
  personData: Person;
  loading: boolean;
  isUpdated: boolean;
  isError: boolean;
  message: string;
  fieldType: "password" | "text";
  iconType: "eye-open" | "eye-close";
  errorMessage: string;
  showBackDrop: boolean;
  showActiveModal: boolean;
  originalCustomField: CustomFieldInPersonOrClient[];
  formattedCustomField: (CustomFieldInPersonOrClient & { value: string })[];
  typingTimeout?: NodeJS.Timeout;
}

class ContactUpdateRow extends Component<Props, State> {
  state: State = {
    initialPersonData: this.props.personData,
    personData: this.props.personData,
    loading: false,
    isUpdated: false,
    isError: false,
    message: "",
    fieldType: "password",
    iconType: "eye-open",
    errorMessage: "",
    showBackDrop: false,
    showActiveModal: false,
    originalCustomField: [],
    formattedCustomField: []
  };

  onEnterKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      this.updatePerson();
    }
  };

  formatCustomField = () => {
    const formattedCustomField = this.props.customFields.map(
      (customField: CustomField) => {
        const customFieldInClient = this.props.personData.customFields.find(
          (customFieldInClient: CustomFieldInPersonOrClient) =>
            customFieldInClient.customFieldId === customField._id
        );

        const customFieldValue = customFieldInClient?.value;

        return {
          customFieldId: customField._id,
          name: customField.name,
          value:
            customFieldValue === true
              ? "Yes"
              : customFieldValue === false
              ? "No"
              : customFieldValue ?? ""
        };
      }
    );
    this.setState({
      originalCustomField: formattedCustomField,
      formattedCustomField
    });
  };

  // addEmailField = () => {
  //   const { personData } = this.state;

  //   (personData.email.length === 0 || !("email" in personData)) &&
  //     this.setState({
  //       personData: {
  //         ...personData,
  //         email: [{ emailId: "", isPrimary: true }]
  //       }
  //     });
  // };

  // addMobileField = () => {
  //   const { personData } = this.state;
  //   (personData.mobile.length === 0 || !("mobile" in personData)) &&
  //     this.setState({
  //       personData: {
  //         ...personData,
  //         mobile: [
  //           {
  //             mobileNumber: "",
  //             isPrimary: true,
  //             countryCode: "+91",
  //             isWhatsapp: false
  //           }
  //         ]
  //       }
  //     });
  // };

  componentDidMount() {
    this.formatCustomField();
    // this.addEmailField();
    // this.addMobileField();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      (prevProps.selectedFieldToUpdate._id !==
        this.props.selectedFieldToUpdate._id &&
        this.props.selectedFieldToUpdate._id === "customFields") ||
      prevProps.selectedCustomField._id !== this.props.selectedCustomField._id
    ) {
      this.formatCustomField();
    }
    if (
      prevProps.selectedFieldToUpdate._id !==
        this.props.selectedFieldToUpdate._id ||
      prevProps.selectedCustomField !== this.props.selectedCustomField
    ) {
      this.resetErrorAndUpdateState();
    }
    if (prevProps.personData !== this.props.personData) {
      this.setState({
        initialPersonData: this.props.personData,
        personData: this.props.personData
      });
    }

    const isPersonBooleanDataUpdated = () => {
      const previousPersonData = prevState.personData;
      const currentPersonData = this.state.personData;

      const isPrimaryMobileUpdated = previousPersonData.mobile.some(
        (mobile, index) =>
          mobile?.isPrimary !== currentPersonData.mobile[index]?.isPrimary
      );
      const isPrimaryEmailUpdated = previousPersonData.email.some(
        (email, index) => {
          return email?.isPrimary !== currentPersonData.email[index]?.isPrimary;
        }
      );
      const isWhatsappUpdated = previousPersonData.mobile.some(
        (mobile, index) =>
          mobile?.isWhatsapp !== currentPersonData.mobile[index]?.isWhatsapp
      );

      return (
        isPrimaryMobileUpdated || isPrimaryEmailUpdated || isWhatsappUpdated
      );
    };

    if (isPersonBooleanDataUpdated()) {
      this.updatePerson();
    }
  }

  resetErrorAndUpdateState = () => {
    this.setState({
      isError: false,
      message: "",
      isUpdated: false,
      errorMessage: ""
    });
  };

  updateFieldData = (e: ChangeEvent<HTMLInputElement>) => {
    this.resetErrorAndUpdateState();
    switch (e.target.name) {
      case "dscExpiryDate":
      case "dateOfBirth":
      case "dateOfAnniversary":
        if (!isValidDate(e.target.value)) {
          this.setState({
            personData: {
              ...this.state.personData,
              [e.target.name]: this.state.initialPersonData[e.target.name]
            }
          });
          return this.props.addNotification?.(
            "Invalid date",
            `Please enter a valid date in the ${this.props.selectedFieldToUpdate.name} field`,
            "danger"
          );
        }
        break;
      default:
        break;
    }

    this.setState({
      personData: { ...this.state.personData, [e.target.name]: e.target.value }
    });
  };

  handlePinCodeChange = (ev: any) => {
    this.resetErrorAndUpdateState();
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      personData: {
        ...this.state.personData,
        pincode: ev.target.value
      },
      typingTimeout: setTimeout(() => {
        this.getPinCodeData();
      }, 700)
    });
  };

  getPinCodeData = () => {
    const pincode = this.state.personData.pincode;
    pincode &&
      agent.ContactPerson.getPinCodeData(pincode)
        .then(response => {
          const state = response.data.state;
          const city = response.data.city;
          this.setState({
            personData: { ...this.state.personData, state, city }
          });
        })
        .catch(err => {
          this.setState({
            isError: true,
            message:
              err?.error || err?.response?.data?.message || err?.message || err,
            isUpdated: false
          });
        });
  };

  onViewPassword = () => {
    if (this.state.fieldType === "password") {
      this.setState({ fieldType: "text", iconType: "eye-close" });
    } else {
      this.setState({ fieldType: "password", iconType: "eye-open" });
    }
  };

  updateEmailMobileData = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    field: "email" | "mobile",
    index: number
  ) => {
    type FieldName =
      | "emailId"
      | "countryCode"
      | "mobileNumber"
      | "isPrimary"
      | "isWhatsapp";

    this.resetErrorAndUpdateState();
    const { personData } = this.state;
    const fieldName = e.target.name.split("-")[0] as FieldName;
    const checkbox = e.target.type === "checkbox";
    const value = checkbox
      ? (e.target as HTMLInputElement).checked
      : fieldName === "mobileNumber"
      ? e.target.value.replace(/\D/g, "").slice(0, 10) // only allow 10 digits numbers
      : e.target.value;

    const data = personData[field].map((item, itemIndex) => {
      if (itemIndex === index) {
        return { ...item, [fieldName]: value };
      } else if (checkbox && (e.target as HTMLInputElement).checked) {
        const newValue =
          fieldName === "isWhatsapp"
            ? (e.target as HTMLInputElement).checked
            : false;

        return { ...item, [fieldName]: newValue };
      }
      return item;
    });
    this.setState({
      personData: { ...this.state.personData, [field]: data }
    });
  };

  updateCustomFieldData = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    this.resetErrorAndUpdateState();

    const updatedCustomField = [...this.state.formattedCustomField];

    if (e.target.type === "date" && !isValidDate(e.target.value)) {
      this.setState({ formattedCustomField: updatedCustomField });

      return this.props.addNotification?.(
        "Invalid date",
        `Please enter a valid date in the ${this.props.selectedFieldToUpdate.name} field`,
        "danger"
      );
    }

    updatedCustomField[index].value = e.target.value;
    this.setState({ formattedCustomField: updatedCustomField });
  };

  addMore = (field: AddMoreType) => {
    this.resetErrorAndUpdateState();
    if (field === "email") {
      this.setState({
        personData: {
          ...this.state.personData,
          email: [
            ...this.state.personData.email,
            { emailId: "", isPrimary: false }
          ]
        }
      });
    } else if (field === "mobile") {
      this.setState({
        personData: {
          ...this.state.personData,
          mobile: [
            ...this.state.personData.mobile,
            {
              mobileNumber: "",
              isPrimary: false,
              isWhatsapp: false,
              countryCode: "+91"
            }
          ]
        }
      });
    }
  };

  checkEmail = (email: Email[]) => {
    let isValid = true;
    email.forEach(item => {
      const isEmailValid = validEmail.test(item.emailId);
      if (!isEmailValid) {
        this.props.addNotification?.(
          "Invalid email",
          `${item.emailId} is Invalid email!`,
          "danger"
        );
        isValid = false;
      }
    });

    return isValid;
  };

  isEqual = (a: any, b: any) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };

  checkIfUpdated = () => {
    let {
      _id: contactPersonId,
      name,
      email,
      mobile,
      dscPassword,
      addressLine1,
      addressLine2,
      pincode,
      city,
      state,
      customFields,
      dscExpiryDate,
      dateOfBirth,
      dateOfAnniversary,
      pan: PAN,
      din
    } = this.state.personData;

    email = email.map(({ emailId, isPrimary }) => ({ emailId, isPrimary }));
    mobile = mobile.map(
      ({ mobileNumber, isPrimary, isWhatsapp, countryCode }) => ({
        mobileNumber,
        isPrimary,
        isWhatsapp,
        countryCode
      })
    );

    customFields = this.state.formattedCustomField
      ?.filter(({ customFieldId }) => customFieldId)
      ?.map(({ name, customFieldId, value }) => ({
        name,
        customFieldId,
        value
      }));

    const isUpdated = () => {
      switch (this.props.selectedFieldToUpdate._id) {
        case "name":
          return name !== this.props.personData.name;
        case "email":
          return !this.isEqual(
            email,
            this.props.personData.email.map(({ emailId, isPrimary }) => ({
              emailId,
              isPrimary
            }))
          );
        case "mobile":
          return !this.isEqual(
            mobile,
            this.props.personData.mobile.map(
              ({ mobileNumber, isPrimary, isWhatsapp, countryCode }) => ({
                mobileNumber,
                isPrimary,
                isWhatsapp,
                countryCode
              })
            )
          );
        case "dscPassword":
          return dscPassword !== this.props.personData.dscPassword;
        case "addressLine":
          return (
            addressLine1 !== this.props.personData.addressLine1 ||
            addressLine2 !== this.props.personData.addressLine2 ||
            pincode !== this.props.personData.pincode ||
            city !== this.props.personData.city ||
            state !== this.props.personData.state
          );
        case "customFields":
          return !this.isEqual(
            customFields,
            this.state.initialPersonData.customFields.map(
              ({ customFieldId, value }) => ({
                customFieldId,
                value
              })
            )
          );
        case "dscExpiryDate":
          return dscExpiryDate !== this.props.personData.dscExpiryDate;
        case "dateOfBirth":
          return dateOfBirth !== this.props.personData.dateOfBirth;
        case "dateOfAnniversary":
          return dateOfAnniversary !== this.props.personData.dateOfAnniversary;
        case "pan":
          return PAN !== this.props.personData.pan;
        case "din":
          return din !== this.props.personData.din;
        default:
          return;
      }
    };

    return {
      isUpdated: isUpdated(),
      personData: {
        contactPersonId,
        name,
        email,
        mobile,
        dscPassword,
        addressLine1,
        addressLine2,
        pincode,
        city,
        state,
        customFields,
        dscExpiryDate,
        dateOfBirth,
        dateOfAnniversary,
        PAN,
        din
      }
    };
  };

  updatePerson = (allowDuplicates = false) => {
    const workSpaceId = this.props.params?.firmId;

    if (!workSpaceId) return console.error("No workspace id found");

    if (!this.props.selectedFieldToUpdate._id) return;

    const selectedFieldToUpdate = this.props.selectedFieldToUpdate._id;

    const { personData, isUpdated } = this.checkIfUpdated();
    if (!isUpdated) return;
    let {
      contactPersonId,
      name,
      email,
      mobile,
      dscPassword,
      addressLine1,
      addressLine2,
      pincode,
      city,
      state,
      customFields,
      dscExpiryDate,
      dateOfBirth,
      dateOfAnniversary,
      PAN,
      din
    } = personData;

    const isEmailValid = this.checkEmail(email);
    const checkPAN = !PAN ? true : validPAN.test(PAN);

    //checking whether the mobile number entered by the user is of 10 digits or not before hitting the API
    const isMobileInvalid = mobile
      .map(({ mobileNumber }) => mobileNumber.length === 10)
      .includes(false);

    const isMobileBlank = mobile
      .map(({ mobileNumber }) => mobileNumber.length === 0)
      .includes(true);

    if (selectedFieldToUpdate === "name" && !name) {
      return this.setState({
        isError: true,
        isUpdated: false,
        message: "Name is required",
        personData: {
          ...this.state.personData,
          name: this.state.initialPersonData.name
        }
      });
    }

    if (selectedFieldToUpdate === "mobile" && isMobileBlank) {
      return this.setState({
        isError: true,
        isUpdated: false,
        message: "Mobile number is required. Please enter mobile number.",
        personData: {
          ...this.state.personData,
          mobile:
            this.state.initialPersonData.mobile.length === 0
              ? [
                  {
                    mobileNumber: "",
                    isPrimary: true,
                    isWhatsapp: false,
                    countryCode: "+91"
                  }
                ]
              : this.state.initialPersonData.mobile
        }
      });
    }

    if (selectedFieldToUpdate === "mobile" && isMobileInvalid) {
      return this.setState({
        isError: true,
        isUpdated: false,
        message: "Mobile number must be of 10 digits. Please check again.",
        personData: {
          ...this.state.personData,
          mobile:
            this.state.initialPersonData.mobile.length === 0
              ? [
                  {
                    mobileNumber: "",
                    isPrimary: true,
                    isWhatsapp: false,
                    countryCode: "+91"
                  }
                ]
              : this.state.initialPersonData.mobile
        }
      });
    }

    if (selectedFieldToUpdate === "pan" && !checkPAN) {
      return this.setState({
        isError: true,
        isUpdated: false,
        message: "PAN number is invalid. Please check again.",
        personData: {
          ...this.state.personData,
          pan: this.state.initialPersonData.pan
        }
      });
    }

    if (selectedFieldToUpdate === "email" && !isEmailValid) {
      return this.setState({
        isError: true,
        isUpdated: false,
        message: "Email is not valid. Please check again.",
        personData: {
          ...this.state.personData,
          email:
            this.state.initialPersonData.email.length === 0
              ? [{ emailId: "", isPrimary: true }]
              : this.state.initialPersonData.email
        }
      });
    }

    this.setState({ isUpdated: false, message: "", loading: true });

    return agent.ContactPerson.editPerson(
      contactPersonId,
      workSpaceId,
      name,
      email,
      mobile,
      dscPassword,
      addressLine1,
      addressLine2,
      pincode ?? "",
      city,
      state,
      customFields.filter(({ customFieldId }) => customFieldId),
      dscExpiryDate ? new Date(dscExpiryDate).toISOString() : "",
      dateOfBirth ? new Date(dateOfBirth).toISOString() : "",
      dateOfAnniversary ? new Date(dateOfAnniversary).toISOString() : "",
      PAN,
      din,
      allowDuplicates,
      true,
      this.props.selectedFieldToUpdate._id === "name" ||
        this.props.selectedFieldToUpdate._id === "email" ||
        this.props.selectedFieldToUpdate._id === "mobile"
        ? this.props.selectedFieldToUpdate._id
        : ""
    )
      .then(() => {
        this.setState({
          isUpdated: true,
          message: `${this.props.selectedFieldToUpdate.name} updated successfully`
        });
        this.props.getPersonById(contactPersonId);
      })
      .catch(err => {
        const error = err?.response?.data?.message || err?.message || err;
        if (
          error.includes("same email ") ||
          error.includes("same mobile number")
        ) {
          this.openActiveModal(error);
        } else {
          this.setState({
            isError: true,
            isUpdated: false,
            message: error
          });
          this.props.selectedFieldToUpdate._id === "addressLine"
            ? this.setState({
                personData: {
                  ...this.state.personData,
                  addressLine1: this.state.initialPersonData.addressLine1,
                  addressLine2: this.state.initialPersonData.addressLine2,
                  pincode: this.state.initialPersonData.pincode,
                  city: this.state.initialPersonData.city,
                  state: this.state.initialPersonData.state
                }
              })
            : this.props.selectedFieldToUpdate._id &&
              this.setState({
                personData: {
                  ...this.state.personData,
                  [this.props.selectedFieldToUpdate._id]:
                    this.state.initialPersonData[
                      this.props.selectedFieldToUpdate._id
                    ]
                }
              });
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  openActiveModal = (error: string) => {
    this.setState({ errorMessage: error, showBackDrop: false });
    this.activeModalSetOpen(true);
  };

  activeModalSetOpen = (open: boolean) => {
    this.setState({ showActiveModal: open });
  };

  onLoadModal = () => this.setState({ showActiveModal: false, loading: false });

  render() {
    const { selectedFieldToUpdate, selectedCustomField } = this.props;
    const {
      initialPersonData,
      personData,
      showActiveModal,
      errorMessage,
      isError,
      loading,
      message,
      isUpdated,
      formattedCustomField,
      fieldType,
      iconType
    } = this.state;

    return (
      <>
        <tr className="even:bg-gray-100 group">
          <td className="min-w-[20ch] max-w-[30ch] whitespace-nowrap py-2 px-4 text-sm text-gray-900">
            {/* Keeping it here to avoid the DOM validation warning */}
            {showActiveModal && (
              <ActiveModal
                type="bulkEditDuplicateContactDetails"
                state={{
                  showActiveModal,
                  errorMessage,
                  ...personData,
                  contactPersonId: personData._id,
                  selectedRow: personData
                }}
                activeModalSetOpen={this.activeModalSetOpen}
                confirmAction={() => this.updatePerson(true)}
              />
            )}
            <Popup
              content={initialPersonData.name}
              className="w-full max-w-fit"
            >
              <p className="hover:underline font-bold truncate">
                {initialPersonData.name}
              </p>
            </Popup>
          </td>
          <td className="py-2 px-4 text-sm text-gray-900 min-w-[25ch] max-w-[40ch]">
            <div className="space-y-2">
              {selectedFieldToUpdate.fieldType === "customFields" ? (
                formattedCustomField.map((item, index) => {
                  return selectedCustomField.type === "date" &&
                    item.customFieldId === selectedCustomField._id ? (
                    <div className="flex rounded-md shadow-sm w-full">
                      <input
                        type="date"
                        id="date_custom_field"
                        name="date_custom_field"
                        value={item.value ? formatDate(item.value, true) : ""}
                        min={minAllowedDate}
                        max={maxAllowedDate}
                        onBlur={() => this.updatePerson()}
                        onKeyDown={this.onEnterKeyPress}
                        onChange={e => this.updateCustomFieldData(e, index)}
                        className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                      />
                    </div>
                  ) : selectedCustomField.type === "shorttext" &&
                    item.customFieldId === selectedCustomField._id ? (
                    <div className="flex rounded-md shadow-sm w-full">
                      <input
                        type="text"
                        id="short_text_custom_field"
                        name="short_text_custom_field"
                        placeholder="Enter text"
                        value={item.value}
                        onBlur={() => this.updatePerson()}
                        onKeyDown={this.onEnterKeyPress}
                        onChange={e => this.updateCustomFieldData(e, index)}
                        className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                      />
                    </div>
                  ) : selectedCustomField.type === "longtext" &&
                    item.customFieldId === selectedCustomField._id ? (
                    <div className="w-full">
                      <textarea
                        rows={2}
                        id="long_text_custom_field"
                        name="long_text_custom_field"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        value={item.value}
                        placeholder="Enter text"
                        onBlur={() => this.updatePerson()}
                        onKeyDown={this.onEnterKeyPress}
                        onChange={e => this.updateCustomFieldData(e, index)}
                      />
                    </div>
                  ) : (
                    selectedCustomField.type === "boolean" &&
                    item.customFieldId === selectedCustomField._id && (
                      <div className="flex items-center gap-12 w-full justify-center py-1">
                        <div className="flex items-center">
                          <input
                            id={`boolean_custom_field_yes-${item.customFieldId}-${personData._id}`}
                            name={`boolean_custom_field-${item.customFieldId}-${personData._id}`}
                            type="radio"
                            value="Yes"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                            checked={item.value === "Yes"}
                            onBlur={() => this.updatePerson()}
                            onKeyDown={this.onEnterKeyPress}
                            onChange={e => this.updateCustomFieldData(e, index)}
                          />
                          <label
                            htmlFor={`boolean_custom_field_yes-${item.customFieldId}-${personData._id}`}
                            className="ml-3 block text-sm font-medium text-gray-700 cursor-pointer"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            id={`boolean_custom_field_no-${item.customFieldId}-${personData._id}`}
                            name={`boolean_custom_field-${item.customFieldId}-${personData._id}`}
                            type="radio"
                            value="No"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                            checked={item.value === "No"}
                            onBlur={() => this.updatePerson()}
                            onKeyDown={this.onEnterKeyPress}
                            onChange={e => this.updateCustomFieldData(e, index)}
                          />
                          <label
                            htmlFor={`boolean_custom_field_no-${item.customFieldId}-${personData._id}`}
                            className="ml-3 block text-sm font-medium text-gray-700 cursor-pointer"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    )
                  );
                })
              ) : selectedFieldToUpdate.fieldType === "email" ? (
                personData.email.map((item, emailIndex) => (
                  <div key={`${item._id}-${emailIndex}`} className="w-full">
                    <div className="rounded-md shadow-sm grow">
                      <input
                        type="email"
                        name={`emailId-${item._id}`}
                        value={item.emailId}
                        onBlur={() => this.updatePerson()}
                        onKeyDown={this.onEnterKeyPress}
                        onChange={e =>
                          this.updateEmailMobileData(e, "email", emailIndex)
                        }
                        className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                ))
              ) : selectedFieldToUpdate.fieldType === "mobile" ? (
                personData.mobile.map((item, mobileIndex) => (
                  <div key={`${item._id}-${mobileIndex}`}>
                    <div className="relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 flex items-center">
                        <label
                          className="sr-only"
                          htmlFor={`country-${item._id}`}
                        >
                          Country
                        </label>
                        <select
                          id={`country-${item._id}`}
                          name={`countryCode-${item._id}`}
                          autoComplete="country"
                          className="h-full rounded-md border-transparent bg-transparent py-0 pl-2.5 pr-7 text-gray-500 sm:text-sm"
                          value={item.countryCode}
                          onBlur={() => this.updatePerson()}
                          onChange={e =>
                            this.updateEmailMobileData(e, "mobile", mobileIndex)
                          }
                        >
                          {phoneCode.map(phone => (
                            <option key={phone.iso} value={phone.code}>
                              {phone.iso + " " + phone.code}
                            </option>
                          ))}
                        </select>
                      </div>
                      <input
                        type="text"
                        name={`mobileNumber-${item._id}`}
                        className="block w-full rounded-md border-gray-300 pl-24 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm min-w-[calc(10ch+7rem)]"
                        placeholder="Mobile Number"
                        value={item.mobileNumber}
                        onBlur={() => this.updatePerson()}
                        onKeyDown={this.onEnterKeyPress}
                        onChange={e =>
                          this.updateEmailMobileData(e, "mobile", mobileIndex)
                        }
                      />
                    </div>
                  </div>
                ))
              ) : selectedFieldToUpdate.fieldType === "address-fields" ? (
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Address
                  </label>
                  <div className="flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="addressLine1"
                      placeholder="House No. / Street / Area"
                      value={personData.addressLine1 ?? ""}
                      onBlur={() => this.updatePerson()}
                      onKeyDown={this.onEnterKeyPress}
                      onChange={this.updateFieldData}
                      className="w-full focus:ring-indigo-500 focus:border-indigo-500 flex-1 block rounded-md sm:text-sm border-gray-300"
                    />
                  </div>
                  <div className="mt-3 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="addressLine2"
                      value={personData.addressLine2 ?? ""}
                      onBlur={() => this.updatePerson()}
                      onKeyDown={this.onEnterKeyPress}
                      onChange={this.updateFieldData}
                      className="w-full focus:ring-indigo-500 focus:border-indigo-500 flex-1 block rounded-md sm:text-sm border-gray-300"
                      placeholder="Locality / Town"
                    />
                  </div>
                  <div className="mt-3 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="pincode"
                      value={personData.pincode ?? ""}
                      onBlur={() => this.updatePerson()}
                      onKeyDown={this.onEnterKeyPress}
                      onChange={this.handlePinCodeChange}
                      className="w-full focus:ring-indigo-500 focus:border-indigo-500 flex-1 block rounded-md sm:text-sm border-gray-300"
                      placeholder="Pin Code"
                    />
                  </div>
                  <div className="sm:flex sm:items-center space-y-2 sm:space-y-0 sm:space-x-2.5 mt-3 rounded-md shadow-sm">
                    <input
                      type="text"
                      name="city"
                      value={personData.city ?? ""}
                      onBlur={() => this.updatePerson()}
                      onKeyDown={this.onEnterKeyPress}
                      onChange={this.updateFieldData}
                      className="w-full focus:ring-indigo-500 focus:border-indigo-500 flex-1 block rounded-md sm:text-sm border-gray-300"
                      placeholder="City"
                    />
                    <input
                      type="text"
                      name="state"
                      value={personData.state ?? ""}
                      onBlur={() => this.updatePerson()}
                      onKeyDown={this.onEnterKeyPress}
                      onChange={this.updateFieldData}
                      className="w-full focus:ring-indigo-500 focus:border-indigo-500 flex-1 block rounded-md sm:text-sm border-gray-300"
                      placeholder="State"
                    />
                  </div>
                </div>
              ) : (
                <div className="relative rounded-md shadow-sm">
                  <input
                    name={selectedFieldToUpdate._id}
                    type={
                      selectedFieldToUpdate.fieldType === "password"
                        ? fieldType
                        : selectedFieldToUpdate.fieldType
                    }
                    placeholder={`Enter ${selectedFieldToUpdate.name}`}
                    value={
                      selectedFieldToUpdate.fieldType === "date"
                        ? formatDate(
                            (personData[
                              selectedFieldToUpdate._id as keyof Person
                            ] as string) ?? "",
                            true
                          )
                        : (personData[
                            selectedFieldToUpdate._id as keyof Person
                          ] as string) ?? ""
                    }
                    min={
                      selectedFieldToUpdate.fieldType === "date"
                        ? minAllowedDate
                        : undefined
                    }
                    max={
                      selectedFieldToUpdate.fieldType === "date"
                        ? maxAllowedDate
                        : undefined
                    }
                    onBlur={() => this.updatePerson()}
                    onKeyDown={this.onEnterKeyPress}
                    onChange={this.updateFieldData}
                    className="w-full focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
                  />
                  {selectedFieldToUpdate.fieldType === "password" && (
                    <button
                      type="button"
                      onClick={this.onViewPassword}
                      className="focus:outline focus:outline-2 focus:outline-indigo-500 absolute top-2 right-3 rounded-md"
                    >
                      <Icon name={iconType} className="h-6 w-6 text-gray-500" />
                    </button>
                  )}
                </div>
              )}
            </div>
          </td>
          {selectedFieldToUpdate.fieldType === "email" ? (
            <td className="py-2 px-4 text-sm text-gray-500 w-6">
              <div
                className="grid items-center gap-6 justify-center"
                style={{
                  gridTemplateRows: `repeat(${personData.email.length}, 1fr)`
                }}
              >
                {personData.email.map((item, emailIndex) => (
                  <input
                    key={item._id || emailIndex}
                    id={`primary-email-${item._id}`}
                    aria-describedby="primary-email"
                    name={`isPrimary-${item._id}`}
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-400 cursor-pointer text-indigo-600 focus:ring-indigo-500"
                    checked={item.isPrimary ?? false}
                    onBlur={() => this.updatePerson()}
                    onKeyDown={this.onEnterKeyPress}
                    onChange={e =>
                      this.updateEmailMobileData(e, "email", emailIndex)
                    }
                  />
                ))}
              </div>
            </td>
          ) : selectedFieldToUpdate.fieldType === "mobile" ? (
            <td className="py-2 px-4 text-sm text-gray-500 w-6">
              <div
                className="grid items-center gap-6 justify-center"
                style={{
                  gridTemplateRows: `repeat(${personData.mobile.length}, 1fr)`
                }}
              >
                {personData.mobile.map((item, mobileIndex) => (
                  <input
                    key={item._id || mobileIndex}
                    id={`primary-phone-${item._id}-${mobileIndex}`}
                    aria-describedby="primary-phone"
                    name={`isPrimary-${item._id}`}
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-400 cursor-pointer text-indigo-600 focus:ring-indigo-500"
                    checked={item.isPrimary}
                    onBlur={() => this.updatePerson()}
                    onKeyDown={this.onEnterKeyPress}
                    onChange={e =>
                      this.updateEmailMobileData(e, "mobile", mobileIndex)
                    }
                  />
                ))}
              </div>
            </td>
          ) : null}
          {selectedFieldToUpdate.fieldType === "mobile" ? (
            <td className="py-2 px-4 text-sm text-gray-500 w-6">
              <div
                className="grid items-center gap-6 justify-center"
                style={{
                  gridTemplateRows: `repeat(${personData.mobile.length}, 1fr)`
                }}
              >
                {personData.mobile.map((item, mobileIndex) => (
                  <input
                    key={item._id || mobileIndex}
                    id={`primary-whatsapp-${item._id}-${mobileIndex}`}
                    aria-describedby="primary-whatsapp"
                    name={`isWhatsapp-${item._id}`}
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-400 cursor-pointer text-indigo-600 focus:ring-indigo-500"
                    checked={item.isWhatsapp}
                    onBlur={() => this.updatePerson()}
                    onKeyDown={this.onEnterKeyPress}
                    onChange={e =>
                      this.updateEmailMobileData(e, "mobile", mobileIndex)
                    }
                  />
                ))}
              </div>
            </td>
          ) : null}
          {(selectedFieldToUpdate.fieldType === "mobile" ||
            selectedFieldToUpdate.fieldType === "email") && (
            <td className="px-2 min-w-[8ch] max-w-[10ch]">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded border border-transparent px-2 py-1.5 text-xs font-medium text-blue-600 hover:underline focus:outline-none ml-auto focus:underline"
                onClick={() =>
                  this.addMore(selectedFieldToUpdate.fieldType as AddMoreType)
                }
              >
                Add More
              </button>
            </td>
          )}
          <td className="py-2 text-sm text-gray-500 w-6">
            <Popup content={message} className="w-5 h-5 shrink-0">
              {isUpdated ? (
                <CheckIcon className="h-5 w-5 text-green-500" />
              ) : isError ? (
                <XMarkIcon className="h-5 w-5 text-red-500" />
              ) : (
                loading && (
                  <Icon name="loading" className="h-5 w-5 animate-spin" />
                )
              )}
            </Popup>
          </td>
          <td className="min-w-[30rem] py-2 px-4 text-sm text-gray-900 font-bold">
            {loading ? (
              <Icon name="loading" className="h-5 w-5 animate-spin mx-auto" />
            ) : (
              message || (
                <span className="group-focus-within:visible invisible text-gray-600 font-normal italic">
                  Press Enter, Tab or click outside to update
                </span>
              )
            )}
          </td>
        </tr>
      </>
    );
  }
}

export default compose(
  connector,
  withRouter
)(ContactUpdateRow) as React.ComponentType<Props>;
