import React from "react";
// Import to Display skeleton while loading data
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { connect, ConnectedProps } from "react-redux";
// Routing imports
// Link backend
import agent from "../../agent";
// Dashboard import
import Dashboard from "../../components/Dashboard";
// Icons and styling
import Icon from "../../components/Icon";
// Redux Notify
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import "../style.css";

import { withRouter } from "../../helpers/withRouter";
import { compose } from "redux";

import { downloadFile } from "../../helpers/downloadFile";
import { Link } from "react-router-dom";
import convertNumber from "../../helpers/convertNumber";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Receipt Payment Pending Balance Report"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: (payload: any) => dispatch({ type: UPDATE_COMMON, payload }),
  onNotify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class PendingBalanceReport extends React.Component<PropsFromRedux> {
  state: {
    logging: boolean;
    downloading: boolean;
    record: any;
    balance: string;
    totalRecords: number;
  };

  constructor(props: any) {
    super(props);
    this.state = {
      logging: false,
      downloading: false,
      record: [],
      balance: "",
      totalRecords: 0
    };
  }
  headers = ["Client", "Receivable Balance", "Payable Balance"];

  getPendingBalanceReport = (download: boolean) => {
    this.setState({ logging: true, downloading: download });
    agent.Receipt.pendingBalanceReport(
      (this.props as any).params?.firmId,
      download
    )
      .then((res: any) => {
        if (download) {
          downloadFile(res, "PendingBalanceReport.xlsx");
          this.setState({ downloading: false });
        } else {
          this.setState({
            record: res,
            balance: res.balance,
            totalRecords: res.totalRecords,
            clientList: res.clientList,
            logging: false
          });
        }
      })
      .catch((err: any) => {
        this.setState({ logging: false });
        (this.props as any).onNotify(
          "Error",
          "Error while fetching pending balance report",
          "error"
        );
      });
  };

  componentDidMount() {
    document.title = "Pending Balance Report - TaxPido PMS";
    this.getPendingBalanceReport(false);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const prevFirmId = prevProps.params?.firmId;
    const currFirmId = (this.props as any).params?.firmId;
    if (prevFirmId !== currFirmId) {
      this.getPendingBalanceReport(false);
    }
  }

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard>
        <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">
            Pending Balance Report
          </h1>
        </div>
        <div className="px-4 sm:px-6 md:px-8 flex justify-between mt-6 items-center">
          <Link
            to={`/${(this.props as any).params?.firmId}/receipts-payments/list`}
            className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
          >
            <Icon name="arrow-left" className="h-4 w-4 mr-2" />
            Back to Receipts
          </Link>
          <button
            type="button"
            className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
            onClick={() => this.getPendingBalanceReport(true)}
          >
            {this.state.downloading ? (
              <Icon name="loading" className="h-4 w-4 mr-2" />
            ) : (
              <Icon name="outline/download" className="h-4 w-4 mr-2" />
            )}
            Download Report
          </button>
        </div>
        {this.state.record.length > 0 ? (
          <div className="max-w-full mx-auto">
            <div className="mt-6 lg:mx-8 flex flex-col">
              <div id="table-scroll" className="overflow-auto">
                <div className="inline-block min-w-full py-2 px-1 align-middle">
                  <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                    <table className="min-w-full border-collapse border shadow-sm">
                      <thead className="bg-gray-50">
                        <tr>
                          {this.headers.map((header, index) => (
                            <th
                              key={header}
                              style={{ zIndex: 8 }}
                              scope="col"
                              className={`sticky top-0 border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-6 ${
                                index !== 0 && "text-center"
                              }`}
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {!this.state.logging
                          ? this.state.record?.map(
                              (record: any, index: any) => (
                                <tr
                                  key={record?.clientId}
                                  className={
                                    index % 2 === 0 ? undefined : "bg-gray-100"
                                  }
                                >
                                  <td className="w-3/12 whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 font-bold">
                                    {record?.clientName}
                                  </td>
                                  <td className="w-3/12 whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 text-center">
                                    {convertNumber(
                                      record?.balance > 0 ? record?.balance : 0
                                    )}
                                  </td>
                                  <td className="w-3/12 whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 text-center">
                                    {convertNumber(
                                      record?.balance < 0 ? record?.balance : 0
                                    )}
                                  </td>
                                </tr>
                              )
                            )
                          : [...Array(5)].map((e, i) => (
                              <tr key={`skeleton-tr-${i}`} className="bg-white">
                                {[...Array(this.headers.length)].map((e, i) => (
                                  <td
                                    key={`skeleton-td-${i}`}
                                    className="w-3/10 px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                  >
                                    <Skeleton />
                                  </td>
                                ))}
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center my-10 border-2 border-gray-300 border-dashed p-16 md:mx-40 sm:mx-0 rounded-lg">
            <Icon
              name="outline/document-add"
              className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
              strokeWidth="1"
            />
            <h3 className="text-sm font-medium text-gray-900">
              No Record Found.
            </h3>
          </div>
        )}
      </Dashboard>
    );
  }
}

export default compose(
  connector,
  withRouter
)(PendingBalanceReport) as React.ComponentType;
