export const itemsPerPage = [
  { name: 10 },
  { name: 25 },
  { name: 50 },
  { name: 100 },
  { name: 250 },
  { name: 500 }
];

export type ItemsPerPage = (typeof itemsPerPage)[number];
