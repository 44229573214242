function getTimeInMinSec(totalRecords: number, timePerRecord: number) {
	const totalTimeInSeconds = totalRecords * timePerRecord;
	return totalTimeInSeconds > 60
		? `${Math.floor(totalTimeInSeconds / 60)} min ${
				totalTimeInSeconds % 60
		  } sec`
		: `0 min ${totalTimeInSeconds} sec`;
}

export default getTimeInMinSec;
