import axios from "axios";

type IpLocationInfo = {
  ip: string;
  version: string;
  city: string;
  region: string;
  region_code: string;
  country_code: string;
  country_code_iso3: string;
  country_name: string;
  country_capital: string;
  country_tld: string;
  continent_code: string;
  in_eu: boolean;
  postal: string;
  latitude: number;
  longitude: number;
  timezone: string;
  utc_offset: string;
  country_calling_code: string;
  currency: string;
  currency_name: string;
  languages: string;
  country_area: number;
  country_population: number;
  asn: string;
  org: string;
  hostname: string;
};

export async function getLocationInfo(ip: string) {
  try {
    const url = `https://ipapi.co/${ip}/json/`;
    const response = await axios.get<IpLocationInfo>(url);

    const { city, region } = response.data;

    return `${city ? city + ", " : ""}${region ? region + ", " : ""}`;
  } catch (error) {
    return null;
  }
}
