import React, { Fragment } from "react";
// Import to Display skeleton while loading data
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// Pagination
import Pagination from "../../components/Pagination";
import { connect, ConnectedProps } from "react-redux";
// Routing imports
// Link backend
import agent from "../../agent";
// Dashboard import
import Dashboard from "../../components/Dashboard";
// Icons and styling
import Icon from "../../components/Icon";
// Redux Notify
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import "../style.css";
import { Menu, Transition } from "@headlessui/react";
import DeleteModal from "../../components/DeleteModal";

import { withRouter } from "../../helpers/withRouter";
import { compose } from "redux";

import EditReceiptModal from "./Edit";
import { formatDate } from "../../helpers/formatDate";
import convertNumber from "../../helpers/convertNumber";
import MultiSelect from "../../components/MultiSelect";
import { downloadFile } from "../../helpers/downloadFile";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import { ArrowDownTrayIcon, PlusIcon } from "@heroicons/react/24/outline";
import Popup from "../../components/Popup";
import TagManager from "react-gtm-module";
import { formatClientName } from "../../helpers/formatClientName";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Receipt Payments List"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: (payload: any) => dispatch({ type: UPDATE_COMMON, payload }),
  onNotify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const amountRegex = /^\d+(\.\d{0,2})?$/;

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class ReceiptPayments extends React.Component<PropsFromRedux> {
  state: {
    loading: boolean;
    downloading: boolean;
    rotate: boolean;
    posX: any;
    posY: any;
    hoverX: any;
    hoverY: any;
    showBackDrop: boolean;
    searchText: string;
    showAdvancedSearch: boolean;
    searchTemp: any;
    clientList: any[];
    sourceList: any[];
    typeList: any[];
    categoryList: any[];
    receipt: any;
    totalRecords: number;
    modalOpen: boolean;
    typingTimeout: number;
    selectedRow: any;
    showDeleteModal: boolean;
    showActiveModal: boolean;
    showInActiveModal: boolean;
    showEditModal: boolean;
    currPage: number;
    chunkSize: number;
    receiptPaymentsRights: any;
  };
  amountToRef: React.RefObject<HTMLInputElement>;

  constructor(props: any) {
    super(props);
    this.amountToRef = React.createRef();

    this.state = {
      loading: false,
      downloading: false,
      rotate: false,
      posX: null,
      posY: null,
      hoverX: null,
      hoverY: null,
      showBackDrop: false,
      clientList: [],
      sourceList: [],
      categoryList: [],
      typeList: [
        { name: "Paid" },
        { name: "Received" },
        { name: "Payable" },
        { name: "Receivable" }
      ],
      searchText: "",
      showAdvancedSearch: false,
      searchTemp: {
        dateFrom: "",
        dateTo: "",
        type: "all",
        notes: "",
        amountFrom: "",
        amountTo: "",
        clientId: "",
        sourceId: "",
        categoryId: ""
      },
      receipt: [],
      totalRecords: 0,
      modalOpen: false,
      typingTimeout: 0,
      selectedRow: undefined,
      showDeleteModal: false,
      showActiveModal: false,
      showInActiveModal: false,
      showEditModal: false,
      currPage: 0,
      chunkSize: 25,
      receiptPaymentsRights: (this.props as any).rights?.reciptsAndPayments
    };
  }

  headers = [
    "DATE",
    "AMOUNT",
    "TYPE",
    "SOURCE",
    "CLIENT",
    "CATEGORY",
    "VERIFIED",
    "ACTION"
  ];

  reports = [
    {
      text: "Client Wise",
      path: "client-wise-reports",
      icon: "outline/user"
    },
    {
      text: "Pending Balance",
      path: "pending-balance-reports",
      icon: "outline/document-report"
    }
  ];

  handlePageClick = (data: any) => {
    this.setState({ currPage: data.selected });
  };

  handleItemPerPage = (value: any) => {
    this.setState({ chunkSize: value.name, currPage: 0 });
  };

  fetchRequired = () => {
    this.setState({ requireFetch: true });
  };

  onActionClick = (e: any) => {
    const screenClosness = window.innerHeight - e.clientY;
    const positionY = screenClosness < 125 ? e.clientY - 125 : e.clientY;
    this.setState({
      posX: e.clientX,
      posY: positionY,
      showBackDrop: true
    });
  };

  onDropdownClick = (showBackDrop: boolean, rotate: boolean) => {
    this.setState({ showBackDrop: showBackDrop, rotate: rotate });
  };

  getClientsList = () => {
    this.setState({ loading: true });
    agent.Clients.getClientList(
      (this.props as any).params?.firmId,
      0,
      100000,
      "",
      true
    )
      .then((res: any) => {
        this.setState({
          clientList: res.clients,
          loading: false
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).onNotify(
          "Error",
          "Error while fetching clients",
          "error"
        );
      });
  };

  getCategoryList = () => {
    this.setState({ loading: true });
    agent.Receipt.getCategoryList(
      (this.props as any).params?.firmId,
      true,
      "",
      0,
      100000
    )
      .then((res: any) => {
        this.setState({
          categoryList: res.categories,
          loading: false
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).onNotify(
          "Could not load Category Details",
          err?.response?.data?.error || err?.message || err,
          "danger"
        );
      });
  };

  getSourceList = () => {
    this.setState({ loading: true });
    agent.Receipt.getSourceList(
      (this.props as any).params?.firmId,
      true,
      "",
      0,
      100000
    )
      .then((res: any) => {
        this.setState({
          sourceList: res.sources,
          loading: false
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).onNotify(
          "Could not load Source Details",
          err?.response?.data?.error || err?.message || err,
          "danger"
        );
      });
  };

  openAddReceiptModal = () => {
    if ((this.props as any).rights?.reciptsAndPayments) {
      this.setState({ modalOpen: true, showBackDrop: false });
      (this.props as any).updateCommon({
        currentModal: { modalName: "ADD_RECEIPT_MODAL", fetchAgain: false }
      });
    } else {
      (this.props as any).onNotify(
        "Rights Not Avilable",
        "Ask Admin to change your user rights.",
        "danger"
      );
    }
  };

  closeModal = (fetchAgain: boolean) => {
    (this.props as any).updateCommon({
      currentModal: { modalName: "", fetchAgain }
    });
  };

  openActiveModal = (receipt: any) => {
    this.setState({ selectedRow: receipt, showBackDrop: false });
    this.activeModalSetOpen(true);
  };

  activeModalSetOpen = (open: boolean) => {
    this.setState({
      showActiveModal: open
    });
  };

  openInActiveModal = (receipt: any) => {
    this.setState({ selectedRow: receipt, showBackDrop: false });
    this.inActiveModalSetOpen(true);
  };

  inActiveModalSetOpen = (open: boolean) => {
    this.setState({
      showInActiveModal: open
    });
  };

  openDeleteModal = (receipt: any) => {
    this.setState({ selectedRow: receipt, showBackDrop: false });
    this.deleteModalSetOpen(true);
  };

  deleteModalSetOpen = (open: boolean) => {
    this.setState({
      showDeleteModal: open
    });
  };

  openEditModal = (receipt: any) => {
    if ((this.props as any).rights?.reciptsAndPayments) {
      this.setState({ selectedRow: receipt, showBackDrop: false });
      this.editModalSetOpen(true);
    } else {
      (this.props as any).onNotify(
        "Rights Not Avilable",
        "Ask Admin to change your user rights.",
        "danger"
      );
    }
  };

  editModalSetOpen = (open: boolean) => {
    this.setState({
      showEditModal: open
    });
  };

  getReceiptList = (forAdvancedSearch: boolean) => {
    const workSpaceId = (this.props as any).params?.firmId;
    const skip = this.state.currPage * this.state.chunkSize;
    this.setState({ loading: true });
    agent.Receipt.getReceiptList(
      workSpaceId,
      skip,
      this.state.chunkSize,
      forAdvancedSearch ? this.state.searchTemp.dateFrom : "",
      forAdvancedSearch ? this.state.searchTemp.dateTo : "",
      this.state.searchTemp.type,
      forAdvancedSearch ? this.state.searchTemp.notes : "",
      forAdvancedSearch ? this.state.searchTemp.amountFrom : "",
      forAdvancedSearch ? this.state.searchTemp.amountTo : "",
      forAdvancedSearch ? this.state.searchTemp.clientId : "",
      forAdvancedSearch ? this.state.searchTemp.sourceId : "",
      forAdvancedSearch ? this.state.searchTemp.categoryId : "",
      false
    )
      .then((res: any) => {
        this.setState({
          totalRecords: res.count,
          receipt: res.receiptsPayment,
          loading: false
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).onNotify(
          "Could not load Firm Details",
          err?.response?.data?.error || err?.message || err,
          "danger"
        );
      });
  };

  exportReceipts = () => {
    if ((this.props as any).rights?.reciptsAndPayments) {
      this.setState({ downloading: true });
      const workSpaceId = (this.props as any).params?.firmId;
      const skip = this.state.currPage * this.state.chunkSize;
      agent.Receipt.getReceiptList(
        workSpaceId,
        skip,
        this.state.chunkSize,
        this.state.searchTemp.dateFrom || "",
        this.state.searchTemp.dateTo || "",
        this.state.searchTemp.type,
        this.state.searchTemp.notes || "",
        this.state.searchTemp.amountFrom || "",
        this.state.searchTemp.amountTo || "",
        this.state.searchTemp.clientId || "",
        this.state.searchTemp.sourceId || "",
        this.state.searchTemp.categoryId || "",
        true
      )
        .then((res: any) => {
          downloadFile(res, "Receipts.xlsx");
          this.setState({ downloading: false });
          (this.props as any).onNotify(
            "Receipts Exported Successfully",
            "Receipts Exported Successfully",
            "success"
          );
        })
        .catch((err: any) => {
          this.setState({ downloading: false });
          (this.props as any).onNotify(
            "Could not export Receipts",
            err?.response?.data?.error || err?.message || err,
            "danger"
          );
        });
    } else {
      (this.props as any).onNotify(
        "Rights Not Avilable",
        "Ask Admin to change your user rights.",
        "danger"
      );
    }
  };

  // handleSearchTextChange = (e: any) => {
  //   if (this.state.typingTimeout) {
  //     clearTimeout(this.state.typingTimeout);
  //   }

  //   if (e.target.value === "" || amountRegex.test(e.target.value)) {
  //     this.setState({
  //       searchText: e.target.value
  //     });
  //   }

  //   this.setState({
  //     typingTimeout: setTimeout(() => {
  //       if (e.target.value && amountRegex.test(e.target.value)) {
  //         this.getReceiptList(true, false);
  //         this.setState({
  //           searchText:
  //             e.target.value.split(".")[1] > 0
  //               ? e.target.value.split(".")[1].length < 2
  //                 ? e.target.value.split(".")[0] +
  //                   "." +
  //                   e.target.value.split(".")[1] +
  //                   "0"
  //                 : e.target.value.split(".")[0] +
  //                   "." +
  //                   e.target.value.split(".")[1]
  //               : e.target.value.split(".")[0] + ".00"
  //         });
  //       } else if (e.target.value === "") {
  //         this.getReceiptList(false, false);
  //       }
  //     }, 800)
  //   });
  // };

  onChange = (e: any) => {
    this.setState({
      searchTemp: {
        ...this.state.searchTemp,
        [e.target.name]: e.target.value
      }
    });
  };

  onAmountChange = (e: any) => {
    if (e.target.value === "" || amountRegex.test(e.target.value)) {
      this.setState({
        searchTemp: {
          ...this.state.searchTemp,
          [e.target.name]: e.target.value
        }
      });
    }
  };

  onAmountBlur = (e: any) => {
    if (e.target.value === "") {
      return;
    } else if (e.target.value && amountRegex.test(e.target.value)) {
      this.setState({
        searchTemp: {
          ...this.state.searchTemp,
          [e.target.name]:
            e.target.value.split(".")[1] > 0
              ? e.target.value.split(".")[1].length < 2
                ? e.target.value.split(".")[0] +
                  "." +
                  e.target.value.split(".")[1] +
                  "0"
                : e.target.value.split(".")[0] +
                  "." +
                  e.target.value.split(".")[1]
              : e.target.value.split(".")[0] + ".00"
        }
      });
    }
  };

  onAdvancedSearchClick = () => {
    this.getCategoryList();
    this.getClientsList();
    this.getSourceList();
    this.setState({ showAdvancedSearch: !this.state.showAdvancedSearch });
  };

  handleCancel = () => {
    this.setState({
      showAdvancedSearch: false,
      searchTemp: {
        dateFrom: "",
        dateTo: "",
        type: "all",
        notes: "",
        amountFrom: "",
        amountTo: "",
        clientId: "",
        sourceId: "",
        categoryId: ""
      }
    });
    this.getReceiptList(false);
  };

  // onMount Load data
  componentDidMount() {
    document.title = "Receipts & Payments - TaxPido PMS";
    if ((this.props as any).rights?.reciptsAndPayments) {
      this.getReceiptList(false);
    } else if ((this.props as any).rights?.reciptsAndPayments === false) {
      (this.props as any).onNotify(
        "Rights Not Avilable",
        "Ask Admin to change your user rights.",
        "danger"
      );
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const prevFirmId = prevProps.params?.firmId;
    const currFirmId = (this.props as any).params?.firmId;
    if (prevFirmId !== currFirmId) {
      this.getReceiptList(false);
    }

    if (
      (prevProps as any).rights?.reciptsAndPayments === undefined &&
      (this.props as any).rights?.reciptsAndPayments
    ) {
      this.getReceiptList(false);
    }
    const prevModal = prevProps.currentModal;
    const currentModal = (this.props as any)?.currentModal;
    if (
      prevModal?.modalName === "ADD_RECEIPT_MODAL" &&
      prevModal?.modalName !== currentModal?.modalName &&
      currentModal?.modalName === "" &&
      currentModal?.fetchAgain
    ) {
      this.getReceiptList(false);
    }
    if (
      prevState.currPage !== this.state.currPage ||
      prevState.chunkSize !== this.state.chunkSize
    ) {
      this.state.searchTemp.dateFrom ||
      this.state.searchTemp.dateTo ||
      this.state.searchTemp.notes ||
      this.state.searchTemp.amountFrom ||
      this.state.searchTemp.amountTo ||
      this.state.searchTemp.clientId ||
      this.state.searchTemp.sourceId ||
      this.state.searchTemp.categoryId
        ? this.getReceiptList(true)
        : this.getReceiptList(false);
    }
  }

  onLoad = () => {
    this.setState({
      searchTemp: {
        dateFrom: "",
        dateTo: "",
        type: "all",
        notes: "",
        amountFrom: "",
        amountTo: "",
        clientId: "",
        sourceId: "",
        categoryId: ""
      }
    });
    this.getReceiptList(false);
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard>
        {this.state.showEditModal && (
          <EditReceiptModal
            state={this.state}
            showEditModal={this.state.showEditModal}
            onLoad={() => this.getReceiptList(true)}
            editModalSetOpen={this.editModalSetOpen}
          />
        )}
        {this.state.showDeleteModal && (
          <DeleteModal
            type={"receipt"}
            state={this.state}
            onLoad={this.onLoad}
            deleteModalSetOpen={this.deleteModalSetOpen}
          />
        )}
        <div className="max-w-full mx-auto lg:mx-8">
          <h1 className="text-2xl font-semibold text-gray-900">
            Receipts & Payments
          </h1>
        </div>
        <div className="flex gap-4 justify-between mt-6 items-center lg:mx-8">
          <div className="hidden md:block">
            <Button
              name="Add Receipt"
              icon={
                (this.props as any).rights?.reciptsAndPayments
                  ? PlusIcon
                  : "outline/lock-closed"
              }
              onClick={this.openAddReceiptModal}
            />
          </div>
          {/* <span className="md:hidden mr-auto"></span> */}
          {!this.state.showAdvancedSearch && (
            <button
              className="w-64 flex gap-y-1 md:gap-3 items-center flex-col md:flex-row hover:border-indigo-700 focus:border-indigo-700 px-6 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none text-gray-500 text-sm whitespace-nowrap cursor-pointer"
              onClick={this.onAdvancedSearchClick}
            >
              Search
            </button>
          )}
          <div className="md:hidden ml-auto">
            <Menu as="div" className="inline-block relative">
              <Menu.Button
                onClick={() => this.onDropdownClick(true, true)}
                className="relative inline-flex gap-x-2 items-center justify-between px-2 py-2 sm:py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white  bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:bg-indigo-700 disabled:bg-indigo-500 w-fit sm:pl-4 sm:pr-2"
                disabled={!(this.props as any).rights?.reciptsAndPayments}
              >
                {(this.props as any).rights?.reciptsAndPayments ? (
                  <Icon
                    name="outline/settings"
                    className={`h-5 w-5`}
                    aria-hidden="true"
                  />
                ) : (
                  <Icon
                    name="outline/lock-closed"
                    className={`h-5 w-5`}
                    aria-hidden="true"
                  />
                )}

                <span>Options</span>
                <Icon
                  name="dropdown-arrow"
                  className={`h-5 w-5 ${this.state.rotate ? "rotate-90" : ""}`}
                  aria-hidden="true"
                />
              </Menu.Button>
              {this.state.showBackDrop && (
                <div
                  className="fixed top-0 left-0 z-10 w-full h-screen"
                  onClick={() => this.onDropdownClick(false, false)}
                ></div>
              )}

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div className="absolute z-[100] right-0">
                  <Menu.Items className="overscroll-none mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item
                        as={"div"}
                        onClick={() => this.onDropdownClick(false, false)}
                      >
                        <Button
                          name="Add Receipt"
                          icon={
                            (this.props as any).rights?.reciptsAndPayments
                              ? PlusIcon
                              : "outline/lock-closed"
                          }
                          onClick={this.openAddReceiptModal}
                          className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900 disabled:cursor-not-allowed"
                        />
                      </Menu.Item>
                      {this.reports.map(report => (
                        <Menu.Item
                          as={"div"}
                          onClick={() => this.onDropdownClick(false, false)}
                        >
                          <Link
                            to={`/${
                              (this.props as any).params?.firmId
                            }/receipts-payments/${report.path}`}
                            className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                          >
                            <Icon name={report.icon} className="h-5 w-5 mr-2" />
                            <span>{report.text}</span>
                          </Link>
                        </Menu.Item>
                      ))}
                      <Menu.Item
                        as={"div"}
                        onClick={() => this.onDropdownClick(false, false)}
                      >
                        <Button
                          name="Export Receipts"
                          icon={ArrowDownTrayIcon}
                          onClick={this.exportReceipts}
                          className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                        />
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </div>
              </Transition>
            </Menu>
          </div>
          <div className="hidden md:block">
            {this.state.totalRecords > 0 ? (
              <div className="flex gap-2">
                <Menu as="div" className="inline-block relative">
                  <Menu.Button
                    onClick={() => this.onDropdownClick(true, true)}
                    className="relative inline-flex gap-x-2 items-center justify-between px-2 py-2 sm:py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none disabled:bg-indigo-500 focus:bg-indigo-700"
                    disabled={!(this.props as any).rights?.reciptsAndPayments}
                  >
                    <Icon
                      name={
                        (this.props as any).rights?.reciptsAndPayments
                          ? "outline/document-text"
                          : "outline/lock-closed"
                      }
                      className="h-4 w-4"
                    />
                    <span>Reports</span>
                    <Icon
                      name="dropdown-arrow"
                      className={`h-4 w-4 ${
                        this.state.rotate ? "rotate-90" : ""
                      }`}
                      aria-hidden="true"
                    />
                  </Menu.Button>
                  {this.state.showBackDrop && (
                    <div
                      className="fixed top-0 left-0 z-10 w-full h-screen"
                      onClick={() => this.onDropdownClick(false, false)}
                    ></div>
                  )}
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <div className="absolute z-[100]">
                      <Menu.Items className="overscroll-none mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {this.reports.map(report => (
                            <Menu.Item>
                              <Link
                                to={`/${
                                  (this.props as any).params?.firmId
                                }/receipts-payments/${report.path}`}
                                className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                              >
                                <Icon
                                  name={report.icon}
                                  className="h-5 w-5 mr-2"
                                />
                                <span>{report.text}</span>
                              </Link>
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </div>
                  </Transition>
                </Menu>
                <Button
                  name="Export"
                  icon={ArrowDownTrayIcon}
                  onClick={this.exportReceipts}
                />
              </div>
            ) : (
              <span aria-hidden="true" className="sm:w-[14.5rem]">
                &#8203;
              </span>
            )}
          </div>
        </div>
        <div
          className={`max-w-full mx-auto lg:mx-8 ${
            this.state.showAdvancedSearch
              ? "visible relative z-auto h-auto"
              : "invisible absolute -z-50 h-0"
          } transition-[top] duration-500`}
        >
          <form>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-x-4 gap-y-4 mt-6">
              <div id="date-from">
                <label
                  htmlFor="dateFrom"
                  className="block text-sm font-medium text-gray-700"
                >
                  Date From
                </label>
                <div className="mt-1">
                  <input
                    type="date"
                    name="dateFrom"
                    id="dateFrom"
                    value={formatDate(this.state.searchTemp.dateFrom, true)}
                    onChange={(e: any) =>
                      this.setState({
                        searchTemp: {
                          ...this.state.searchTemp,
                          dateFrom: new Date(e.target.value).toISOString()
                        }
                      })
                    }
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div id="date-to" className="md:row-start-2">
                <label
                  htmlFor="dateTo"
                  className="block text-sm font-medium text-gray-700"
                >
                  Date To
                </label>
                <div className="mt-1 relative">
                  <input
                    type="date"
                    name="dateTo"
                    id="dateTo"
                    min={formatDate(this.state.searchTemp.dateFrom, true)}
                    value={formatDate(
                      this.state.searchTemp.dateTo ||
                        this.state.searchTemp.dateFrom,
                      true
                    )}
                    onChange={(e: any) =>
                      this.setState({
                        searchTemp: {
                          ...this.state.searchTemp,
                          dateTo: new Date(e.target.value).toISOString()
                        }
                      })
                    }
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div id="amount-from" className="row-start-2 md:row-auto">
                <label
                  htmlFor="amountFrom"
                  className="block text-sm font-medium text-gray-700"
                >
                  Amount From
                </label>
                <div className="mt-1">
                  <input
                    type="number"
                    name="amountFrom"
                    id="amountFrom"
                    value={this.state.searchTemp.amountFrom}
                    onChange={e => {
                      this.onAmountChange(e);
                      e.target.value > this.state.searchTemp.amountTo
                        ? this.amountToRef.current!.classList.add(
                            ...[
                              "peer",
                              "invalid:border-2",
                              "invalid:border-red-500",
                              "focus:invalid:ring-1",
                              "focus:invalid:ring-red-500"
                            ]
                          )
                        : e.target.classList.remove(
                            ...[
                              "peer",
                              "invalid:border-2",
                              "invalid:border-red-500",
                              "focus:invalid:ring-1",
                              "focus:invalid:ring-red-500"
                            ]
                          );
                    }}
                    onBlur={this.onAmountBlur}
                    placeholder="Amount From"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div id="amount-to" className="md:row-start-2">
                <label
                  htmlFor="amountTo"
                  className="block text-sm font-medium text-gray-700"
                >
                  Amount To
                </label>
                <div className="mt-1 relative">
                  <input
                    ref={this.amountToRef}
                    type="number"
                    name="amountTo"
                    id="amountTo"
                    min={parseFloat(this.state.searchTemp.amountFrom) || 0}
                    title="Amount To Value must be greater than or equal to Amount
										From."
                    value={
                      this.state.searchTemp.amountTo ||
                      this.state.searchTemp.amountFrom
                    }
                    onChange={e => {
                      this.onAmountChange(e);
                      this.state.searchTemp.amountFrom > e.target.value
                        ? e.target.classList.add(
                            ...[
                              "peer",
                              "invalid:border-2",
                              "invalid:border-red-500",
                              "focus:invalid:ring-1",
                              "focus:invalid:ring-red-500"
                            ]
                          )
                        : e.target.classList.remove(
                            ...[
                              "peer",
                              "invalid:border-2",
                              "invalid:border-red-500",
                              "focus:invalid:ring-1",
                              "focus:invalid:ring-red-500"
                            ]
                          );
                    }}
                    onBlur={this.onAmountBlur}
                    placeholder="Amount To"
                    className="shadow-sm focus:ring-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                  <span className="invisible peer-invalid:visible absolute -bottom-5 left-1/2 -translate-x-1/2 text-xs whitespace-nowrap bg-white">
                    Amount To Value must be greater than or equal to Amount
                    From.
                  </span>
                </div>
              </div>
              <div id="client">
                <label
                  htmlFor="client"
                  className="block text-sm font-medium text-gray-700"
                >
                  Client
                </label>
                <div className="my-1">
                  <MultiSelect
                    items={this.state.clientList.map(item => {
                      return {
                        ...item,
                        _id: item._id,
                        name: formatClientName(item)
                      };
                    })}
                    selected={{
                      name:
                        formatClientName(
                          this.state.clientList.find(
                            item => item._id === this.state.searchTemp?.clientId
                          )
                        ) || ""
                    }}
                    type="type"
                    onChange={(selected: any) => {
                      this.setState({
                        searchTemp: {
                          ...this.state.searchTemp,
                          clientId: selected._id
                        }
                      });
                    }}
                    placeholder="Select Client"
                  />
                </div>
              </div>
              <div id="category" className="md:row-start-2">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-700"
                >
                  Category
                </label>
                <div className="my-1">
                  <MultiSelect
                    items={this.state.categoryList}
                    selected={{
                      name:
                        this.state.categoryList.find(
                          item => item._id === this.state.searchTemp?.categoryId
                        )?.name || ""
                    }}
                    type="type"
                    onChange={(selected: any) => {
                      this.setState({
                        searchTemp: {
                          ...this.state.searchTemp,
                          categoryId: selected._id
                        }
                      });
                    }}
                    placeholder="Select Category"
                  />
                </div>
              </div>
              <div id="type">
                <label
                  htmlFor="type"
                  className="block text-sm font-medium text-gray-700"
                >
                  Type
                </label>
                <div className="mt-1">
                  <MultiSelect
                    items={this.state.typeList?.map((item: any) => {
                      return {
                        ...item,
                        _id: item.name,
                        name: item.name
                      };
                    })}
                    selected={{
                      name: this.state.searchTemp.type
                    }}
                    type="type"
                    onChange={selected => {
                      this.setState({
                        searchTemp: {
                          ...this.state.searchTemp,
                          type: selected.name
                        }
                      });
                    }}
                    placeholder="Select Type"
                  />
                </div>
              </div>
              <div id="source">
                <label
                  htmlFor="source"
                  className="block text-sm font-medium text-gray-700"
                >
                  Source
                </label>
                <div className="my-1">
                  <MultiSelect
                    items={this.state.sourceList}
                    selected={{
                      name:
                        this.state.sourceList.find(
                          item => item._id === this.state.searchTemp?.sourceId
                        )?.name || ""
                    }}
                    type="type"
                    onChange={(selected: any) => {
                      this.setState({
                        searchTemp: {
                          ...this.state.searchTemp,
                          sourceId: selected._id
                        }
                      });
                    }}
                    placeholder="Select Source"
                  />
                </div>
              </div>
              <div className="mt-1 col-span-2">
                <label
                  htmlFor="notes"
                  className="block text-sm font-medium text-gray-700"
                >
                  Notes
                </label>
                <div className="mt-1">
                  <textarea
                    id="notes"
                    name="notes"
                    rows={1}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Notes"
                    value={this.state.searchTemp?.notes}
                    onChange={this.onChange}
                  />
                </div>
              </div>
              <div className="w-full mt-1 place-self-end md:col-start-3 md:row-start-3">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none w-full sm:text-sm"
                  onClick={this.handleCancel}
                >
                  Cancel
                </button>
              </div>
              <div className="w-full mt-1 place-self-end">
                <button
                  type="button"
                  disabled={this.state.loading}
                  className={
                    "inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:bg-indigo-700 focus:outline-none sm:mt-0 w-full sm:text-sm"
                  }
                  onClick={() => this.getReceiptList(true)}
                >
                  <span className="w-full flex justify-end">
                    {this.state.loading ? (
                      <Icon name="loading" className="mr-2" />
                    ) : null}
                  </span>
                  <span>Search</span>
                  <span className="w-full"></span>
                </button>
              </div>
            </div>
          </form>
        </div>

        <div className="max-w-full mx-auto lg:mx-8">
          {/* Receipt Payment List Cards */}
          <div className="sm:hidden mt-6">
            {!this.state.loading && this.state.receipt ? (
              this.state.totalRecords > 0 ||
              this.state.searchText ||
              this.state.searchTemp.amountFrom.length > 0 ||
              this.state.searchTemp.dateFrom.length > 0 ||
              this.state.searchTemp.dateTo.length > 0 ||
              this.state.searchTemp.notes.length > 0 ||
              this.state.searchTemp.amountTo.length > 0 ||
              this.state.searchTemp.clientId.length > 0 ||
              this.state.searchTemp.sourceId.length > 0 ||
              this.state.searchTemp.categoryId.length > 0 ? (
                <ul className="divide-y divide-gray-200 space-y-4">
                  {this.state.totalRecords === 0 ? (
                    <div className="bg-white shadow-md rounded-lg">
                      <div className="px-4 py-4 sm:px-6">
                        <p className="text-sm text-gray-800 text-center font-semibold">
                          No record found matching your search criteria
                        </p>
                      </div>
                    </div>
                  ) : (
                    this.state.receipt.map((receipt: any, index: any) => (
                      <li
                        key={receipt._id}
                        className="bg-white shadow-md rounded-lg"
                      >
                        <div className="px-4 py-4 sm:px-6 grid gap-x-4 gap-y-2 grid-cols-2 relative">
                          <span className="rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800 absolute -translate-y-1/2 -right-4 shadow">
                            {receipt?.type ? receipt?.type : "-"}
                          </span>
                          <p className="text-sm text-gray-800 text-center font-semibold">
                            <time dateTime={receipt?.date}>
                              {receipt?.date
                                ? formatDate(receipt?.date, false)
                                : "-"}
                            </time>
                          </p>
                          <div className="place-self-end mt-1">
                            <ListOptions
                              {...this.props}
                              state={this.state}
                              receipt={receipt}
                              onActionClick={this.onActionClick}
                              onDropdownClick={this.onDropdownClick}
                              openEditModal={this.openEditModal}
                              openDeleteModal={this.openDeleteModal}
                              smallScreen={true}
                            />
                          </div>
                          <div>
                            <p className="text-sm text-gray-800">
                              <span>Client - </span>
                              <span
                                className={`font-bold ${
                                  receipt?.clientName
                                    ? ""
                                    : "inline-block w-20 text-center"
                                }`}
                              >
                                {receipt?.clientName
                                  ? receipt?.clientName
                                  : "-"}
                              </span>
                            </p>
                            <p className="text-sm text-gray-800">
                              <span>Source - </span>
                              <span className="font-bold">
                                {receipt?.sourceName
                                  ? receipt?.sourceName
                                  : "-"}
                              </span>
                            </p>
                          </div>
                          <p className="truncate text-lg font-bold text-indigo-600 m-auto">
                            {receipt?.amount
                              ? convertNumber(receipt?.amount)
                              : "-"}
                          </p>
                        </div>
                      </li>
                    ))
                  )}
                </ul>
              ) : (
                <div className="text-center my-10 border-2 border-gray-300 border-dashed p-16 md:mx-40 sm:mx-0 rounded-lg">
                  <Icon
                    name="outline/document-add"
                    className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                    strokeWidth="1"
                  />
                  <h3 className="text-sm font-medium text-gray-900">
                    No Receipt Entry
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Get started by adding a new Receipt.
                  </p>
                  <div className="mt-6">
                    <button
                      type="button"
                      className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none disabled:bg-indigo-500 disabled:cursor-not-allowed"
                      onClick={this.openAddReceiptModal}
                    >
                      <Icon
                        name={
                          (this.props as any).rights?.reciptsAndPayments
                            ? "add"
                            : "outline/lock-closed"
                        }
                        className="h-4 w-4 mr-2"
                      />
                      Add Receipt
                    </button>
                  </div>
                </div>
              )
            ) : (
              <div className="space-y-4">
                {[...Array(5)].map((e, i) => (
                  <div key={i} className="bg-white shadow-md rounded-lg">
                    <div
                      key={i}
                      className={`whitespace-wrap text-sm font-medium text-gray-900 px-4 py-4 sm:px-6 grid gap-4 grid-cols-3 items-center relative ${
                        i === 0 ? "text-left" : "text-center"
                      }`}
                    >
                      <span className="px-2 absolute top-0 -translate-y-1/2 -right-4 w-24">
                        <Skeleton
                          height="1rem"
                          className="w-full bg-green-100"
                        />
                      </span>
                      <Skeleton height="1rem" />
                      <Skeleton height="1rem" />
                      <Skeleton height="1rem" />
                      <Skeleton height="1rem" />
                      <Skeleton height="1rem" />
                      <Skeleton height="1rem" />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          {/* Receipt Payment List Table */}
          <div className="mt-6 hidden sm:block">
            {this.state.totalRecords > 0 ||
            this.state.searchText ||
            this.state.searchTemp.amountFrom.length > 0 ||
            this.state.searchTemp.dateFrom.length > 0 ||
            this.state.searchTemp.dateTo.length > 0 ||
            this.state.searchTemp.notes.length > 0 ||
            this.state.searchTemp.amountTo.length > 0 ||
            this.state.searchTemp.clientId.length > 0 ||
            this.state.searchTemp.sourceId.length > 0 ||
            this.state.searchTemp.categoryId.length > 0 ? (
              <div id="table-scroll" className="overflow-auto">
                <div className="inline-block min-w-full py-2 px-1 align-middle">
                  <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                    <table className="min-w-full border-collapse border shadow-sm">
                      <thead className="bg-gray-50">
                        <tr>
                          {this.headers.map((header, index) => (
                            <th
                              key={header}
                              style={{ zIndex: 8 }}
                              scope="col"
                              className={`sticky top-0 border-b border-gray-300 bg-gray-50 ${
                                index === 0 ? "px-6" : "px-4"
                              } py-3 ${
                                index === this.headers.length - 1
                                  ? "text-center"
                                  : "text-left"
                              } text-xs font-bold text-gray-500 uppercase tracking-wider`}
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {!this.state.loading ? (
                          this.state.totalRecords === 0 ? (
                            <tr>
                              <td
                                colSpan={this.headers.length}
                                className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900"
                              >
                                No record found matching your search criteria
                              </td>
                            </tr>
                          ) : (
                            this.state.receipt.map(
                              (receipt: any, index: any) => (
                                <tr
                                  key={receipt?._id}
                                  className={`${
                                    index % 2 === 0 ? undefined : "bg-gray-100"
                                  }`}
                                >
                                  <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm text-gray-900 font-bold">
                                    {receipt?.date
                                      ? formatDate(receipt?.date, false)
                                      : "-"}
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-900">
                                    {receipt?.amount
                                      ? convertNumber(receipt?.amount)
                                      : "-"}
                                  </td>
                                  <td className="max-w-[20ch] whitespace-nowrap py-4 px-3 text-sm text-gray-900 font-bold capitalize">
                                    <Popup
                                      content={
                                        receipt?.type ? receipt?.type : "-"
                                      }
                                      className="w-full max-w-fit"
                                    >
                                      <p className="truncate">
                                        {receipt?.type ? receipt?.type : "-"}
                                      </p>
                                    </Popup>
                                  </td>
                                  <td className="max-w-[12ch] whitespace-nowrap py-4 px-3 text-sm text-gray-900">
                                    <Popup
                                      content={
                                        receipt?.sourceName
                                          ? receipt?.sourceName
                                          : "-"
                                      }
                                      className="w-full max-w-fit"
                                    >
                                      <p className="truncate">
                                        {receipt?.sourceName
                                          ? receipt?.sourceName
                                          : "-"}
                                      </p>
                                    </Popup>
                                  </td>
                                  <td className="max-w-[15ch] whitespace-nowrap py-4 px-3 text-sm text-gray-900 font-bold">
                                    <Popup
                                      content={
                                        receipt?.clientName
                                          ? receipt?.clientName
                                          : "-"
                                      }
                                      className="w-full max-w-fit"
                                    >
                                      <p className="truncate">
                                        {receipt?.clientName
                                          ? receipt?.clientName
                                          : "-"}
                                      </p>
                                    </Popup>
                                  </td>
                                  <td className="max-w-[15ch] whitespace-nowrap py-4 px-3 text-sm text-gray-900">
                                    <Popup
                                      content={
                                        receipt?.categoryName
                                          ? receipt?.categoryName
                                          : "-"
                                      }
                                      className="w-full max-w-fit"
                                    >
                                      <p className="truncate">
                                        {receipt?.categoryName
                                          ? receipt?.categoryName
                                          : "-"}
                                      </p>
                                    </Popup>
                                  </td>
                                  <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900">
                                    {receipt?.isVerified ? "Verified" : ""}
                                  </td>
                                  <td className="px-6 py-3 mx-4 text-center whitespace-nowrap text-sm text-gray-500">
                                    <ListOptions
                                      {...this.props}
                                      state={this.state}
                                      receipt={receipt}
                                      onActionClick={this.onActionClick}
                                      onDropdownClick={this.onDropdownClick}
                                      openEditModal={this.openEditModal}
                                      openDeleteModal={this.openDeleteModal}
                                      smallScreen={false}
                                    />
                                  </td>
                                </tr>
                              )
                            )
                          )
                        ) : (
                          [...Array(5)].map((e, i) => (
                            <tr key={i} className="bg-white">
                              {[...Array(this.headers.length)].map((e, i) => (
                                <td
                                  key={i}
                                  className={`px-3 py-3 whitespace-wrap text-sm font-medium text-gray-900 ${
                                    i === 0 ? "text-left" : "text-center"
                                  }`}
                                >
                                  <Skeleton />
                                </td>
                              ))}
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-center my-10 border-2 border-gray-300 border-dashed p-16 md:mx-40 sm:mx-0 rounded-lg bg-gray-100">
                <div>
                  <Icon
                    name="outline/document-add"
                    className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                    strokeWidth="1"
                  />
                  <h3 className="text-sm font-medium text-gray-900">
                    No Receipt Entry
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Get started by adding a new Receipt.
                  </p>
                  <div className="mt-6">
                    <button
                      type="button"
                      className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none disabled:bg-indigo-500 disabled:cursor-not-allowed"
                      onClick={this.openAddReceiptModal}
                    >
                      <Icon
                        name={
                          (this.props as any).rights?.reciptsAndPayments
                            ? "add"
                            : "outline/lock-closed"
                        }
                        className="h-4 w-4 mr-2"
                      />
                      Add Receipt
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <Pagination
          displayRecords={this.state.receipt}
          totalRecords={this.state.totalRecords}
          currPage={this.state.currPage}
          chunkSize={this.state.chunkSize}
          handlePageClick={this.handlePageClick}
          handleItemPerPage={this.handleItemPerPage}
          className="my-4"
        />
      </Dashboard>
    );
  }
}

class ListOptions extends React.Component<any> {
  render() {
    return (
      <Menu as="div" className="inline-block">
        <Menu.Button onClick={this.props.onActionClick}>
          <span className="sr-only">Open options</span>
          {this.props.smallScreen ? (
            <Icon name="vertical-dots" className="h-4 w-4" aria-hidden="true" />
          ) : (
            <Icon
              name="horizontal-dots"
              className="h-5 w-5"
              aria-hidden="true"
            />
          )}
        </Menu.Button>
        {this.props.state.showBackDrop && (
          <div
            className="fixed top-0 left-0 z-10 w-full h-screen"
            onClick={() => this.props.onDropdownClick(false, false)}
          ></div>
        )}

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div
            style={{
              position: "fixed",
              zIndex: 100,
              top: `${this.props.state.posY}px`,
              left: `${this.props.state.posX - 230}px`,
              margin: "0.5rem"
            }}
          >
            <Menu.Items className="overscroll-none mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  <button
                    className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                    onClick={() => this.props.openEditModal(this.props.receipt)}
                  >
                    <Icon name="edit" className="h-5 w-5 mr-2" />
                    <span>Edit</span>
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                    onClick={() =>
                      this.props.openDeleteModal(this.props.receipt)
                    }
                  >
                    <Icon name="delete" className="h-5 w-5 mr-2" />
                    <span>Delete</span>
                  </button>
                </Menu.Item>
              </div>
            </Menu.Items>
          </div>
        </Transition>
      </Menu>
    );
  }
}

export default compose(
  connector,
  withRouter
)(ReceiptPayments) as React.ComponentType;
