import React from "react";
import { connect, ConnectedProps } from "react-redux";
import agent from "../../agent";
import Dashboard from "../../components/Dashboard";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import TagManager from "react-gtm-module";
import { compose } from "redux";
import {
  managerDefaultRights,
  employeeDefaultRights,
  userRights
} from "../../constants/defaultUserRights";
import { withRouter } from "../../helpers/withRouter";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "gstadd"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: (payload: any) => dispatch({ type: UPDATE_COMMON, payload }),
  onNotify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

//Main Classs
class SelfRights extends React.Component<any, PropsFromRedux> {
  state: {
    email: string;
    userRole: "admin" | "manager" | "employee";
    logging: boolean;
    managerRights: any;
    employeeRights: any;
  };

  constructor(props: any) {
    const currentUser = (props as any)?.currentUser;
    super(props);
    this.state = {
      email: currentUser?.email ? currentUser.email : "",
      userRole: "admin",
      logging: false,
      managerRights: managerDefaultRights,
      employeeRights: employeeDefaultRights
    };
  }

  getUserRights = () => {
    const workSpaceId = (this.props as any).params?.firmId;
    const userId = (this.props as any).currentUser?._id;
    if (workSpaceId) {
      agent.User.getUserRights(workSpaceId, userId)
        .then((response: any) => {
          // console.log({ response });
          if (response.hasOwnProperty("allRights") && response.allRights) {
            this.setState({ userRole: "admin" });
          } else if (response.rights.role === "manager") {
            this.setState({
              userRole: "manager",
              managerRights: response.rights
            });
          } else if (response.rights.role === "employee") {
            this.setState({
              userRole: "employee",
              employeeRights: response.rights
            });
          }
        })
        .catch((err: any) => {
          (this.props as any).onNotify(
            "Could not fetch user rights",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
          // console.log({ err });
        });
    }
  };

  componentDidMount() {
    document.title = "User Self-Rights - TaxPido PMS";
    this.getUserRights();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const prevFirmId = prevProps.params?.firmId;
    const currFirmId = (this.props as any).params?.firmId;
    if (prevFirmId !== currFirmId) {
      this.getUserRights();
    }
    if (prevProps?.currentUser !== (this.props as any)?.currentUser) {
      const currentUser = (this.props as any)?.currentUser;
      this.setState({
        email: currentUser?.email ? currentUser.email : ""
      });
    }
  }

  cancelHandler = () => {
    const firmId =
      (this.props as any).params.firmId || (this.props as any).currentFirm._id;
    this.props.navigate(`/${firmId}/user/list`);
  };

  userRole = [
    { id: "admin", title: "Admin" },
    { id: "manager", title: "Manager" },
    { id: "employee", title: "Employee" }
  ];

  isRightChecked = (first: string, second: string) => {
    const role = this.state.userRole;
    if (role === "manager") {
      const rights = this.state.managerRights;
      if (second) {
        return rights[first][second];
      }
      return rights[first];
    } else if (role === "employee") {
      const rights = this.state.employeeRights;
      if (second) {
        return rights[first][second];
      }
      return rights[first];
    } else {
      return false;
    }
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);
    // console.log("ADD USER STATE", this.state.managerRights);
    return (
      <Dashboard>
        <div className="w-full mx-auto px-4 sm:px-6 md:px-8 gstadd">
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form onSubmit={e => e.preventDefault()}>
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Your Rights
                  </h3>
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 lg:col-span-2">
                      <label
                        htmlFor="company_website"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email <span className="text-red-600">*</span>
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="company_website"
                          defaultValue={this.state.email}
                          disabled
                          id="company_website"
                          className="focus:ring-indigo-500 cursor-not-allowed bg-gray-100 opacity-70 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      User Role
                    </label>
                    <fieldset className="mt-2">
                      <legend className="sr-only">User Role</legend>
                      <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                        {this.userRole.map(user => (
                          <div key={user.id} className="flex items-center">
                            <input
                              id={user.id}
                              name="user-role"
                              type="radio"
                              checked={user.id === this.state.userRole}
                              className="focus:ring-indigo-500 cursor-not-allowed h-4 w-4 text-indigo-600 border-gray-400"
                              disabled
                            />
                            <label
                              htmlFor={user.id}
                              className="ml-3 block cursor-not-allowed text-sm font-medium text-gray-700"
                            >
                              {user.title}
                            </label>
                          </div>
                        ))}
                      </div>
                    </fieldset>
                  </div>
                  <div>
                    {this.state.userRole === "admin" && (
                      <div className="mt-4 text-sm leading-5 text-gray-500">
                        Admin has all the rights including change in settings,
                        change in plan and add another users.
                      </div>
                    )}
                    {(this.state.userRole === "manager" ||
                      this.state.userRole === "employee") && (
                      <div className="mt-4 grid grid-cols-3 gap-6">
                        {/* Task Rights */}
                        <div className="col-span-3 lg:col-span-2">
                          <table className="min-w-full divide-y divide-gray-300 border border-gray-300">
                            <thead>
                              <tr className="divide-x divide-gray-300">
                                <th
                                  scope="col"
                                  className="py-3 pl-6 text-left text-sm font-medium text-gray-900"
                                >
                                  Tasks
                                </th>
                                <th
                                  scope="col"
                                  className="py-3 pl-6 pr-4 text-center text-sm font-medium text-gray-900"
                                >
                                  Rights
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="divide-x divide-gray-300">
                                <td className="w-10/12 pt-2 pl-6 pr-4 pb-3 text-sm text-gray-700">
                                  Create
                                </td>
                                <td className="w-2/12 ">
                                  <div className="flex justify-center">
                                    <input
                                      id={"create-task"}
                                      name={"create-task"}
                                      type="checkbox"
                                      className="focus:ring-indigo-500 cursor-not-allowed h-4 w-4 text-indigo-600 border-gray-400 rounded"
                                      checked={this.isRightChecked(
                                        "taskRights",
                                        "create"
                                      )}
                                      disabled
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr className="divide-x divide-gray-300">
                                <td className="w-10/12 pt-2 pl-6 pr-4 py-3 text-sm text-gray-700">
                                  Edit
                                </td>
                                <td className="w-2/12 ">
                                  <div className="flex justify-center">
                                    <input
                                      id={"edit-task"}
                                      name={"edit-task"}
                                      type="checkbox"
                                      className="focus:ring-indigo-500 cursor-not-allowed h-4 w-4 text-indigo-600 border-gray-400 rounded"
                                      checked={this.isRightChecked(
                                        "taskRights",
                                        "edit"
                                      )}
                                      disabled
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr className="divide-x divide-gray-300">
                                <td className="w-10/12 pt-2 pl-6 pr-4 py-3 text-sm text-gray-700">
                                  Delete
                                </td>
                                <td className="w-2/12 ">
                                  <div className="flex justify-center">
                                    <input
                                      id={"delete-task"}
                                      name={"delete-task"}
                                      type="checkbox"
                                      className="focus:ring-indigo-500 cursor-not-allowed h-4 w-4 text-indigo-600 border-gray-400 rounded"
                                      checked={this.isRightChecked(
                                        "taskRights",
                                        "delete"
                                      )}
                                      disabled
                                    />
                                  </div>
                                </td>
                              </tr>

                              <tr className="divide-x divide-gray-300">
                                <td className="w-10/12 pt-2 pl-6 pr-4 py-3 text-sm text-gray-700">
                                  View
                                  {this.state.userRole === "manager" && (
                                    <span> (Select one option)</span>
                                  )}
                                </td>
                                <td className="w-2/12 "></td>
                              </tr>
                              <>
                                {this.state.userRole === "manager" && (
                                  <>
                                    <tr className="divide-x divide-gray-300">
                                      <td className="w-10/12 pb-4 pl-10 pr-4 text-sm text-gray-700">
                                        <ul className="list-disc list-inside">
                                          <li> All Task</li>
                                        </ul>
                                      </td>
                                      <td className="w-2/12 ">
                                        <div className="flex justify-center">
                                          <input
                                            id={"all-task"}
                                            name={"all-task"}
                                            type="checkbox"
                                            className="focus:ring-indigo-500 cursor-not-allowed h-4 w-4 text-indigo-600 border-gray-400 rounded"
                                            checked={
                                              this.isRightChecked(
                                                "taskRights",
                                                "view"
                                              ) === "all tasks"
                                            }
                                            disabled
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                    <tr className="divide-x divide-gray-300">
                                      <td className="w-10/12 pb-4 pl-10 pr-4 text-sm text-gray-700">
                                        <ul className="list-disc list-inside">
                                          <li>
                                            All tasks other than those alloted
                                            to admins only
                                          </li>
                                        </ul>
                                      </td>
                                      <td className="w-2/12 ">
                                        <div className="flex justify-center">
                                          <input
                                            id={"task"}
                                            name={"task"}
                                            type="checkbox"
                                            className="focus:ring-indigo-500 cursor-not-allowed h-4 w-4 text-indigo-600 border-gray-400 rounded"
                                            checked={
                                              this.isRightChecked(
                                                "taskRights",
                                                "view"
                                              ) ===
                                              "all tasks other than those alloted to admins only"
                                            }
                                            disabled
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                )}

                                {this.state.userRole === "employee" && (
                                  <tr className="divide-x divide-gray-300">
                                    <td className="w-10/12 pb-4 pl-10 pr-4 text-sm text-gray-700">
                                      <ul className="list-disc list-inside">
                                        <li>Alloted to him/her</li>
                                      </ul>
                                    </td>
                                    <td className="w-2/12 ">
                                      <div className="flex justify-center">
                                        <input
                                          id={"task"}
                                          name={"task"}
                                          type="checkbox"
                                          className="focus:ring-indigo-500 cursor-not-allowed h-4 w-4 text-indigo-600 border-gray-400 rounded"
                                          checked={
                                            this.isRightChecked(
                                              "taskRights",
                                              "view"
                                            ) === "him or her"
                                          }
                                          onChange={() =>
                                            (this.props as any).onNotify(
                                              "Change Not Allowed",
                                              "You cannot change the view of employee.",
                                              "danger"
                                            )
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </>

                              <tr className="divide-x divide-gray-300">
                                <td className="w-10/12 pt-2 pl-6 pr-4 py-3 text-sm text-gray-700">
                                  Mark tasks as complete, ignore tracking or not
                                  required
                                </td>
                                <td className="w-2/12 ">
                                  <div className="flex justify-center">
                                    <input
                                      id={"mark-task"}
                                      name={"mark-task"}
                                      type="checkbox"
                                      className="focus:ring-indigo-500 cursor-not-allowed h-4 w-4 text-indigo-600 border-gray-400 rounded"
                                      checked={
                                        this.isRightChecked(
                                          "taskRights",
                                          "markTasksAs"
                                        )
                                          ? !Object.values(
                                              this.isRightChecked(
                                                "taskRights",
                                                "markTasksAs"
                                              )
                                            ).includes(false)
                                          : false
                                      }
                                      disabled
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        {/* Client, Tag, Status,  Users */}
                        {userRights.map((rights: any) => {
                          return (
                            <div
                              key={rights.id}
                              className="col-span-3 lg:col-span-2"
                            >
                              <table className="min-w-full divide-y divide-gray-300 border border-gray-300">
                                <thead>
                                  <tr className="divide-x divide-gray-300">
                                    <th
                                      scope="col"
                                      className="py-3 pl-6 text-left text-sm font-medium text-gray-900"
                                    >
                                      {rights.name}
                                    </th>
                                    <th
                                      scope="col"
                                      className="py-3 pl-6 pr-4 text-center text-sm font-medium text-gray-900"
                                    >
                                      Rights
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {rights.options.map((option: any) => {
                                    return (
                                      <tr
                                        key={option.id}
                                        className="divide-x divide-gray-300"
                                      >
                                        <td className="w-10/12 pt-2 pl-6 pr-4 py-3 text-sm text-gray-700">
                                          {option.name}
                                        </td>
                                        <td className="w-2/12 ">
                                          <div className="flex justify-center">
                                            <input
                                              id={option.id}
                                              name={option.id}
                                              type="checkbox"
                                              className="focus:ring-indigo-500 cursor-not-allowed h-4 w-4 text-indigo-600 border-gray-400 rounded"
                                              checked={this.isRightChecked(
                                                rights.id,
                                                option.action
                                              )}
                                              disabled
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          );
                        })}

                        {/* Receipts and payments */}
                        <div className="col-span-3 lg:col-span-2">
                          <table className="min-w-full divide-y divide-gray-300 border border-gray-300">
                            <thead>
                              <tr className="divide-x divide-gray-300">
                                <th
                                  scope="col"
                                  className="py-3 pl-6 text-left text-sm font-medium text-gray-900"
                                >
                                  Receipts and Payments
                                </th>
                                <th
                                  scope="col"
                                  className="py-3 pl-6 pr-4 text-center text-sm font-medium text-gray-900"
                                >
                                  Rights
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="divide-x divide-gray-300">
                                <td className="w-10/12 pt-2 pl-6 pr-4 py-3 text-sm text-gray-700">
                                  Receipts and Payments
                                </td>
                                <td className="w-2/12 ">
                                  <div className="flex justify-center">
                                    <input
                                      id={"receipts-and-payments"}
                                      name={"receipts-and-payments"}
                                      type="checkbox"
                                      className="focus:ring-indigo-500 cursor-not-allowed h-4 w-4 text-indigo-600 border-gray-400 rounded"
                                      checked={this.isRightChecked(
                                        "reciptsAndPayments",
                                        ""
                                      )}
                                      disabled
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {/* Register in and out  */}
                        <div className="col-span-3 lg:col-span-2">
                          <table className="min-w-full divide-y divide-gray-300 border border-gray-300">
                            <thead>
                              <tr className="divide-x divide-gray-300">
                                <th
                                  scope="col"
                                  className="py-3 pl-6 text-left text-sm font-medium text-gray-900"
                                >
                                  Register In and Out
                                </th>
                                <th
                                  scope="col"
                                  className="py-3 pl-6 pr-4 text-center text-sm font-medium text-gray-900"
                                >
                                  Rights
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="divide-x divide-gray-300">
                                <td className="w-10/12 pt-2 pl-6 pr-4 py-3 text-sm text-gray-700">
                                  Register In and Out
                                </td>
                                <td className="w-2/12 ">
                                  <div className="flex justify-center">
                                    <input
                                      id={"register-in-and-out"}
                                      name={"register-in-and-out"}
                                      type="checkbox"
                                      className="focus:ring-indigo-500 cursor-not-allowed h-4 w-4 text-indigo-600 border-gray-400 rounded"
                                      checked={this.isRightChecked(
                                        "registerInAndOut",
                                        ""
                                      )}
                                      disabled
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:justify-end">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:w-32 sm:text-sm"
                    onClick={this.cancelHandler}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Dashboard>
    );
  }
}

export default compose(
  connector,
  withRouter
)(SelfRights) as React.ComponentType;
