import { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { compose } from "redux";
import agent from "../../agent";
import Button from "../../components/Button";
import { handleLogout } from "../../components/Dashboard";
import Icon from "../../components/Icon";
import MessageModal from "../../components/MessageModal";
import { formatDateTimeString } from "../../helpers/formatDate";
import { getLocationInfo } from "../../helpers/getLocationByIp";
import { withRouter, WithRouterProps } from "../../helpers/withRouter";
import { RootState, AppDispatch } from "../../store";
import { CommonAction } from "../../store/reducers/common";
import { NotifyType } from "../../store/reducers/notification";
import { UPDATE_COMMON, ADD_NOTIFICATION } from "../../store/types";
import { skeleton } from "./Index";

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateCommon: (payload: CommonAction["payload"]) =>
    dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: NotifyType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Session = {
  _id: string;
  ip: string;
  lasUpdatedIp: string;
  userId: string;
  accessType: string;
  createdAt: string;
  updatedAt: string;
  current: boolean;
};

type LogoutType = "allOther" | "session";

type Props = Partial<PropsFromRedux & WithRouterProps>;

export default compose<FC<Props>>(connector, withRouter)(SessionsList);

function SessionsList(props: Props) {
  const { params, addNotification } = props;

  const firmId = params?.firmId;

  const [loading, setLoading] = useState(false);
  const [sessions, setSessions] = useState<Session[]>([]);

  const getSessionsList = () => {
    setLoading(true);
    agent.Auth.sessions()
      .then(({ sessions }: { sessions: Session[] }) => {
        setSessions(sessions);
      })
      .catch(error => {
        addNotification?.(
          "Couldn't fetch Sessions details.",
          error.message,
          "danger"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (firmId) getSessionsList();
  }, [firmId]);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const [type, setType] = useState<LogoutType>("session");
  const [sessionId, setSessionId] = useState<string | undefined>(undefined);

  const handleLogoutSuccess = () => {
    addNotification?.(
      "Session Logged out successfully.",
      "Logout action is being processed. The session will get ended within maximum 15 minutes.",
      "success"
    );
    getSessionsList();
    setShowConfirmation(false);
  };

  const handleError = (message: string) => {
    addNotification?.(
      `Couldn't logout ${
        type === "session" ? "session" : "from all other devices"
      }.`,
      message,
      "danger"
    );
  };

  const handleLogoutClick = (type: LogoutType, sessionId?: string) => () => {
    setType(type);
    setSessionId(sessionId);
    setShowConfirmation(true);
  };

  return (
    <div className="mt-8 divide-y divide-gray-200">
      <MessageModal
        show={showConfirmation}
        data={{
          title: `Logout ${
            type === "session" ? "session" : "from all other devices"
          }`,
          message: `Are you sure you want to logout ${
            type === "session" ? "the session" : "from all other devices"
          }?`,
          type: "warning"
        }}
        confirmText="Logout"
        handleConfirm={() =>
          handleLogout(type, sessionId, handleLogoutSuccess, handleError)
        }
        closeText="Cancel"
        handleClose={() => setShowConfirmation(false)}
      />
      <div className="space-y-1 flex gap-4 flex-wrap items-center justify-between">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Your Sessions
        </h3>
        {sessions.length > 1 && (
          <Button
            type="button"
            name="Logout from all other devices"
            disabled={loading}
            onClick={handleLogoutClick("allOther")}
            icon={loading ? "loading" : "delete"}
            className="inline-flex items-center gap-x-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:bg-red-700 focus:outline-none disabled:bg-red-500 disabled:cursor-not-allowed"
          />
        )}
      </div>
      {loading ? (
        <div className="mt-6">
          <div className="divide-y divide-gray-200">{skeleton(5, 3)}</div>
        </div>
      ) : (
        <div className="mt-6">
          <dl className="divide-y divide-gray-200">
            <div className="py-4 hidden lg:grid lg:grid-cols-4 items-center lg:gap-4 lg:py-5 font-bold text-sm text-gray-900">
              <dt className="font-mediumcapitalize">Location (IP)</dt>
              <dd className="mt-1 lg:mt-0">Created At</dd>
              <dd className="mt-1 lg:mt-0">Last Used At</dd>
              <dd className="mt-1 lg:mt-0 w-full text-center">Action</dd>
            </div>
            {sessions.map(session => (
              <SessionRow
                key={session._id}
                {...session}
                loading={loading}
                handleLogout={handleLogoutClick}
              />
            ))}
            <div className="py-4">
              <p className="text-sm">
                <span className="font-bold">Note:</span> The Location is based
                on the IP address of the device used to access the account. It
                may not be accurate.
              </p>
            </div>
          </dl>
        </div>
      )}
    </div>
  );
}

type SessionRowProps = Session & {
  loading: boolean;
  handleLogout: (type: LogoutType, session: string) => () => void;
};

function SessionRow({
  _id,
  loading,
  lasUpdatedIp,
  createdAt,
  updatedAt,
  current,
  handleLogout
}: SessionRowProps) {
  const [location, setLocation] = useState<string | null>(null);

  useEffect(() => {
    getLocationInfo(lasUpdatedIp).then(setLocation);
  }, [lasUpdatedIp]);

  return (
    <div className="py-4 grid sm:grid-cols-2 lg:grid-cols-4 items-center gap-4 lg:py-5 text-sm text-gray-900">
      <dt className="font-medium text-gray-900 capitalize">
        <span className="lg:hidden">Location (IP) : </span>
        <span>
          {location} {location ? `(${lasUpdatedIp})` : lasUpdatedIp}
        </span>
        {current && (
          <span className="ml-4 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
            <Icon name="check" className="h-4 w-4" />
            <span className="ml-1">Current</span>
          </span>
        )}
      </dt>
      <dd className="mt-1 lg:mt-0">
        <span className="lg:hidden">Created At : </span>
        <span>{formatDateTimeString(createdAt)}</span>
      </dd>
      <dd className="mt-1 lg:mt-0">
        <span className="lg:hidden">Last Used At : </span>
        <span>{formatDateTimeString(updatedAt)}</span>
      </dd>
      <dd className="mt-1 lg:mt-0 sm:col-start-2 lg:col-start-auto sm:place-self-end lg:place-self-auto grid">
        {!current && (
          <Button
            type="button"
            name="Logout"
            disabled={loading}
            onClick={handleLogout("session", _id)}
            className="underline underline-offset-2 text-blue-600"
          />
        )}
      </dd>
    </div>
  );
}
