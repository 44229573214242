import { Quarter } from "./types";

export const getCurrentMonth = () => {
  const todayDate1 = new Date();
  todayDate1.setMonth(todayDate1.getMonth());
  const currentMonth = todayDate1
    .toLocaleString("default", { month: "short" })
    .slice(0, 3);
  const currentYear = todayDate1.getFullYear() - 2000;
  const currentMonthYear = currentMonth + " " + currentYear;
  return currentMonthYear;
};

export const nextMonth = (quarter: string) => {
  const parts = quarter.split(" ");
  if (parts[0] === "Jan") {
    return `Feb ${parts[1]}`;
  }
  if (parts[0] === "Feb") {
    return `Mar ${parts[1]}`;
  }
  if (parts[0] === "Mar") {
    return `Apr ${parts[1]}`;
  }
  if (parts[0] === "Apr") {
    return `May ${parts[1]}`;
  }
  if (parts[0] === "May") {
    return `Jun ${parts[1]}`;
  }
  if (parts[0] === "Jun") {
    return `Jul ${parts[1]}`;
  }
  if (parts[0] === "Jul") {
    return `Aug ${parts[1]}`;
  }
  if (parts[0] === "Aug") {
    return `Sep ${parts[1]}`;
  }
  if (parts[0] === "Sep") {
    return `Oct ${parts[1]}`;
  }
  if (parts[0] === "Oct") {
    return `Nov ${parts[1]}`;
  }
  if (parts[0] === "Nov") {
    return `Dec ${parts[1]}`;
  }
  if (parts[0] === "Dec") {
    return `Jan ${parseInt(parts[1]) + 1}`;
  }
};

export const getCurrentQuarter = () => {
  const todayDate3 = new Date();
  todayDate3.setMonth(todayDate3.getMonth());
  const currentYear = todayDate3.getFullYear() - 2000;
  const month = todayDate3
    .toLocaleString("default", { month: "short" })
    .slice(0, 3);
  if (month === "Jan" || month === "Feb" || month === "Mar") {
    return "Jan " + currentYear + " to Mar " + currentYear;
  }
  if (month === "Apr" || month === "May" || month === "Jun") {
    return "Apr " + currentYear + " to Jun " + currentYear;
  }
  if (month === "Jul" || month === "Aug" || month === "Sep") {
    return "Jul " + currentYear + " to Sep " + currentYear;
  }
  if (month === "Oct" || month === "Nov" || month === "Dec") {
    return "Oct " + currentYear + " to Dec " + currentYear;
  }
};

export const getCurrentFinYear = () => {
  const todayDate5 = new Date();
  todayDate5.setMonth(todayDate5.getMonth());
  const currentYear = todayDate5.getFullYear();
  const month = todayDate5
    .toLocaleString("default", { month: "short" })
    .slice(0, 3);
  if (month === "Jan" || month === "Feb" || month === "Mar") {
    return currentYear - 1 + "-" + (currentYear - 2000);
  }
  return currentYear + "-" + (currentYear + 1 - 2000);
};

export const monthTillCurrentMonth = () => {
  const currentMonth = getCurrentMonth();
  let startingMonth = "Jul 17";
  let months: string[] = ["Jul 17"];

  for (let i = 0; i < 1000; i++) {
    const nextMon = nextMonth(startingMonth);
    nextMon && months.push(nextMon);

    if (nextMon === currentMonth) {
      break;
    }
    startingMonth = nextMon as string;
  }
  months = months.slice(0, months.length);

  return months;
};

export const monthTillLastMonth = () => {
  const months = monthTillCurrentMonth();
  return months.slice(0, months.length - 1);
};

export const nextQuarter = (quarter: string) => {
  const parts = quarter.split(" ");
  if (parts[0] === "Jan") {
    return `Apr ${parts[1]} to Jun ${parts[1]}`;
  }
  if (parts[0] === "Apr") {
    return `Jul ${parts[1]} to Sep ${parts[1]}`;
  }
  if (parts[0] === "Jul") {
    return `Oct ${parts[1]} to Dec ${parts[1]}`;
  }
  if (parts[0] === "Oct") {
    return `Jan ${parseInt(parts[1]) + 1} to Mar ${parseInt(parts[1]) + 1}`;
  }
};

export const quarterTillCurrentQuarter = () => {
  const currentQuarter = getCurrentQuarter();
  let startingQuarter = "Jul 17 to Sep 17";
  let quarters: string[] = ["Jul 17 to Sep 17"];

  for (let i = 0; i < 1000; i++) {
    const nextQuar = nextQuarter(startingQuarter);
    nextQuar && quarters.push(nextQuar);

    if (nextQuar === currentQuarter) {
      break;
    }
    startingQuarter = nextQuar as string;
  }

  return quarters;
};

export const quarterTillLastQuarter = () => {
  const quarters = quarterTillCurrentQuarter();
  return quarters.slice(0, quarters.length - 1);
};

export const nextFinYear = (year: string) => {
  const parts = year.split("-");

  return `${parseInt(parts[0]) + 1}-${parseInt(parts[1]) + 1}`;
};

export const yearTillCurrentYear = () => {
  const currentYear = getCurrentFinYear();
  let startingYear = "2017-18";
  let years: string[] = ["2017-18"];

  for (let i = 0; i < 1000; i++) {
    const nextYear = nextFinYear(startingYear);
    years.push(nextYear);

    if (nextYear === currentYear) {
      break;
    }
    startingYear = nextYear;
  }
  years = years.slice(0, years.length);

  return years;
};

export const yearTillLastYear = () => {
  const years = yearTillCurrentYear();
  return years.slice(0, years.length - 1);
};

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
] as const;

export const financialMonths = [
  ...months.slice(3),
  ...months.slice(0, 3) // Jan, Feb, Mar
];

export type Month = (typeof months)[number];

export type ReturnPeriod = `${Month}-${number}`;

export function getFinancialMonthAndYear(
  year: string,
  isQrmpOpted: boolean,
  quarter?: Quarter
) {
  const [startYear, endYear] = year.split("-");
  const startYearNumber = Number(startYear);
  const endYearNumber = Number(endYear) + 2000;

  const monthYear = financialMonths.map(month =>
    month === "Jan" || month === "Feb" || month === "Mar"
      ? (`${month}-${endYearNumber}` as const)
      : (`${month}-${startYearNumber}` as const)
  );

  const returnMonths = () => {
    switch (quarter) {
      case "Q1":
        return isQrmpOpted ? [monthYear[2]] : monthYear.slice(0, 3);
      case "Q2":
        return isQrmpOpted ? [monthYear[5]] : monthYear.slice(3, 6);
      case "Q3":
        return isQrmpOpted ? [monthYear[8]] : monthYear.slice(6, 9);
      case "Q4":
        return isQrmpOpted ? [monthYear[11]] : monthYear.slice(9, 12);
      default:
        return monthYear;
    }
  };

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  return returnMonths().slice(
    0,
    currentYear <= endYearNumber ? 9 + currentMonth : 12
  );
}
