import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import ReactQuill from "react-quill";
import { connect, ConnectedProps } from "react-redux";
import agent from "../../agent";
import { modules } from "../../components/Editor";
import Icon from "../../components/Icon";
import MultiSelect from "../../components/MultiSelect";
import MultiSelectCheckbox from "../../components/MultiSelectCheckbox";
import capitalize from "../../helpers/capitalize";
import { ADD_NOTIFICATION } from "../../store/types";
import { decodeHtml } from "../../helpers/htmlToString";

const period = ["One Time", "Monthly", "Quarterly", "Half-Yearly", "Yearly"];
const afterEndOfPeriod = ["1", "2", "3", "4", "5", "6", "7"];
const beforeEndOfPeriod = ["24", "25", "26", "27", "28", "29", "30"];

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

type Props = {
  type: "add" | "edit";
  editTaskData: any;
  taskName: string;
  showModal: boolean;
  closeModal: (show: boolean) => void;
  onLoad: () => void;
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class AddOtherTask extends React.Component<Props, PropsFromRedux> {
  state: {
    logging: boolean;
    name: string;
    description: string;
    period: any;
    dueDays: any;
    tags: any;
    beforeAfter: string;
  };

  constructor(props: any) {
    super(props);
    this.state = {
      logging: false,
      name: this.props.taskName || this.props.editTaskData?.name || "",
      description: decodeHtml(this.props.editTaskData?.description?.html) ?? "",
      period: { name: this.props.editTaskData?.period ?? "" },
      dueDays: this.props.editTaskData?.dueDays ?? "",
      tags: [],
      beforeAfter: this.props.editTaskData?.beforeAfter ?? "after"
    };
  }

  onKeyUpFunction = (event: any) => {
    if (event.keyCode === 27) {
      this.closeFieldModal(false);
    }

    if (event.keyCode === 13) {
      this.onSave();
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyUpFunction, false);
    if ((this.props as any)?.tags && this.props.editTaskData) {
      this.setState({
        tags: (this.props as any).tags.filter((item: any) =>
          (this.props.editTaskData?.tags || []).includes(item._id)
        )
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyUpFunction, false);
  }

  convertHtmlToText = (value: string) => {
    const plainText = value.replace(/<\/?[^>]+>/gi, "");
    const finalPlainText = plainText.replace(/&(nbsp|amp|quot|lt|gt);/g, " ");
    return finalPlainText;
  };

  onSave = () => {
    if (this.props.type === "add") {
      this.addOtherTask();
    } else {
      this.editOtherTask();
    }
  };

  addOtherTask = () => {
    const workSpaceId = (this.props as any).currentFirm._id;
    const name = this.state.name;
    const dueDays = this.state.dueDays
      ? { dueDays: parseInt(this.state.dueDays, 10) }
      : {};
    const period = this.state.period?.name;
    const beforeAfter = this.state.beforeAfter;
    const tags = this.state.tags.map((item: any) => item._id);
    const plainText = this.convertHtmlToText(this.state.description);
    const description = this.state.description
      ? {
          description: {
            text: plainText,
            html: this.state.description
          }
        }
      : {};
    const data = {
      name,
      period,
      beforeAfter,
      tags,
      ...dueDays,
      ...description
    };
    if (name !== "" && period && tags.length > 0 && data.dueDays) {
      this.setState({ logging: true });
      agent.Tasks.addOtherTask(workSpaceId, data)
        .then(() => {
          this.setState({ logging: false });
          (this.props as any).addNotification(
            "Other Task Added",
            "Successfully added a task.",
            "success"
          );
          this.onLoad();
          this.closeFieldModal(false);
        })
        .catch((err: any) => {
          this.setState({ logging: false });
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    } else {
      if (!name) {
        (this.props as any).addNotification(
          "Empty Name Field",
          "Name Field is Required!.",
          "danger"
        );
      } else if (!period) {
        (this.props as any).addNotification(
          "Period not Selected",
          "Period is Required!.",
          "danger"
        );
      } else if (!data.dueDays) {
        (this.props as any).addNotification(
          "Due Days not Selected",
          "Due Days is Required!.",
          "danger"
        );
      } else if (tags.length === 0) {
        (this.props as any).addNotification(
          "Tags not Selected",
          "Tags is Required!.",
          "danger"
        );
      }
    }
  };

  editOtherTask = () => {
    const workSpaceId = (this.props as any).currentFirm._id;
    const name = this.state.name;
    const dueDays = this.state.dueDays
      ? { dueDays: parseInt(this.state.dueDays, 10) }
      : {};
    const period = capitalize(this.state.period?.name);
    const beforeAfter = this.state.beforeAfter;
    const tags = this.state.tags.map((item: any) => item._id);
    const plainText = this.convertHtmlToText(this.state.description);
    const description = this.state.description
      ? {
          description: {
            text: plainText,
            html: this.state.description
          }
        }
      : {};
    const data = {
      name,
      period,
      beforeAfter,
      tags,
      ...dueDays,
      ...description
    };
    if (name !== "" && period && tags.length > 0 && data.dueDays) {
      this.setState({ logging: true });
      agent.Tasks.editOtherTask(workSpaceId, this.props.editTaskData?._id, data)
        .then(() => {
          this.setState({ logging: false });
          (this.props as any).addNotification(
            "Other Task Edited",
            "Successfully edited a task.",
            "success"
          );
          this.onLoad();
          this.closeFieldModal(false);
        })
        .catch((err: any) => {
          this.setState({ logging: false });
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    } else {
      if (!name) {
        (this.props as any).addNotification(
          "Empty Name Field",
          "Name Field is Required!.",
          "danger"
        );
      } else if (!period) {
        (this.props as any).addNotification(
          "Period not Selected",
          "Period is Required!.",
          "danger"
        );
      } else if (!data.dueDays) {
        (this.props as any).addNotification(
          "Due Days not Selected",
          "Due Days is Required!.",
          "danger"
        );
      } else if (tags.length === 0) {
        (this.props as any).addNotification(
          "Tags not Selected",
          "Tags is Required!.",
          "danger"
        );
      }
    }
  };

  onLoad = () => {
    this.props.onLoad();
  };

  closeFieldModal = (show: boolean) => {
    this.props.closeModal(show);
  };

  updateState = (field: string) => (ev: any) => {
    this.setState({
      [field]: capitalize(ev.target.value)
    });
  };

  onPeriodChange = (period: any) => {
    if (period.name === "One Time") {
      this.setState({
        period,
        dueDaysList: afterEndOfPeriod,
        beforeAfter: "after"
      });
    } else {
      this.setState({ period });
    }
  };

  onTagsChange = (tag: any) => {
    const { tags } = this.state;
    const index = tags.findIndex((item: any) => item?._id === tag?._id);
    if (index === -1) {
      this.setState({
        tags: [...tags, tag]
      });
    } else {
      const updatedTags = tags.filter((item: any) => item._id !== tag._id);
      this.setState({ tags: updatedTags });
    }
  };

  onBeforeAfterChange = (e: any) => {
    const value = e.target.value;
    if (value === "after") {
      this.setState({
        beforeAfter: e.target.value,
        dueDaysList: afterEndOfPeriod
      });
    } else {
      this.setState({
        beforeAfter: e.target.value,
        dueDaysList: beforeEndOfPeriod
      });
    }
  };

  onDueDaysChange = (days: any) => {
    this.setState({ dueDays: days });
  };

  onDescriptionChange = (editedValue: any) => {
    this.setState({ description: editedValue });
  };

  render() {
    return (
      <>
        <Transition.Root show={this.props.showModal} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={() => null}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div>
                      <h3 className="text-lg font-medium leading-6 text-gray-900 mb-2">
                        {this.props.type === "add" ? "Add" : "Edit"} Other Task
                      </h3>
                    </div>
                    <div>
                      <form onSubmit={e => e.preventDefault()}>
                        <div className="mb-4">
                          <div className="mb-4">
                            <label
                              htmlFor="name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Task Name <span className="text-red-600">*</span>
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="text"
                                name="name"
                                value={this.state.name}
                                onChange={this.updateState("name")}
                                id="name"
                                className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                                placeholder="Task Name"
                              />
                            </div>
                          </div>

                          <div className="mb-4">
                            <label
                              htmlFor="company_website"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Period <span className="text-red-600">*</span>
                            </label>
                            <div>
                              <MultiSelect
                                items={period?.map((item: any) => {
                                  return {
                                    _id: item,
                                    name: item
                                  };
                                })}
                                selected={this.state.period}
                                type="period"
                                onChange={this.onPeriodChange}
                                placeholder="Select Period"
                              />
                            </div>
                          </div>

                          <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">
                              Due Day Period{" "}
                              <span className="text-red-600">*</span>
                            </label>
                            <fieldset className="mt-2">
                              <legend className="sr-only">
                                Due Day Period
                              </legend>
                              <div className="space-y-2 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                <div className="flex items-center">
                                  <input
                                    id={"after"}
                                    name="afterbefore"
                                    type="radio"
                                    value={"after"}
                                    checked={this.state.beforeAfter === "after"}
                                    className={`focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer ${
                                      this.state.period?.name === "One Time"
                                        ? "opacity-60 cursor-not-allowed"
                                        : ""
                                    }`}
                                    onChange={this.onBeforeAfterChange}
                                    disabled={
                                      this.state.period?.name === "One Time"
                                    }
                                  />
                                  <label
                                    htmlFor={"after"}
                                    className={`ml-3 block text-sm font-medium text-gray-700 cursor-pointer ${
                                      this.state.period?.name === "One Time"
                                        ? "opacity-60 cursor-not-allowed"
                                        : ""
                                    }`}
                                  >
                                    After End of the Period
                                  </label>
                                </div>
                                <div className="flex items-center">
                                  <input
                                    id={"before"}
                                    name="afterbefore"
                                    type="radio"
                                    value={"before"}
                                    disabled={
                                      this.state.period?.name === "One Time"
                                    }
                                    checked={
                                      this.state.beforeAfter === "before"
                                    }
                                    className={`focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer ${
                                      this.state.period?.name === "One Time"
                                        ? "opacity-60 cursor-not-allowed"
                                        : ""
                                    }`}
                                    onChange={this.onBeforeAfterChange}
                                  />
                                  <label
                                    htmlFor={"before"}
                                    className={`ml-3 block text-sm font-medium text-gray-700 cursor-pointer ${
                                      this.state.period?.name === "One Time"
                                        ? "opacity-60 cursor-not-allowed"
                                        : ""
                                    }`}
                                  >
                                    Before Start of the Period
                                  </label>
                                </div>
                              </div>
                            </fieldset>
                          </div>

                          <div className="mb-4">
                            <label
                              htmlFor="name"
                              className="block text-sm font-medium text-gray-700 mb-1"
                            >
                              Due Days <span className="text-red-600">*</span>
                            </label>
                            <div>
                              <input
                                type="number"
                                name="dueDays"
                                value={this.state.dueDays}
                                onChange={this.updateState("dueDays")}
                                id="due-days"
                                className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                                placeholder="Due Days"
                              />
                              {/* <MultiSelect
                                items={this.state.dueDaysList?.map(
                                  (item: any) => {
                                    return {
                                      _id: item,
                                      name: item,
                                    };
                                  }
                                )}
                                selected={{
                                  name: this.state.dueDays?.name,
                                }}
                                type="period"
                                onChange={this.onDueDaysChange}
                                placeholder="Select Due Days"
                              /> */}
                            </div>
                          </div>

                          <div className="mb-4">
                            <label
                              htmlFor="company_website"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Tags <span className="text-red-600">*</span>
                            </label>
                            <div>
                              <MultiSelectCheckbox
                                items={(this.props as any)?.tags?.map(
                                  (user: any) => ({
                                    ...user,
                                    _id: user._id,
                                    name: user.name
                                  })
                                )}
                                selected={this.state.tags}
                                type="tags"
                                onChange={this.onTagsChange}
                                placeholder={"Select Tags"}
                              />
                            </div>
                          </div>

                          <div className="mb-4">
                            <label
                              htmlFor="comment"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Description
                            </label>
                            <div className="mt-1">
                              <ReactQuill
                                theme="snow"
                                modules={modules}
                                defaultValue={this.state.description}
                                onChange={this.onDescriptionChange}
                                placeholder={"Add Description..."}
                                className="h-[8rem] mb-20"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="mt-5 sm:mt-4 sm:flex sm:justify-end">
                          <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:w-32 sm:text-sm"
                            onClick={() => this.closeFieldModal(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            disabled={this.state.logging}
                            className={
                              "mt-3 sm:ml-4 w-full inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:mt-0 sm:w-32 sm:text-sm"
                            }
                            onClick={this.onSave}
                          >
                            <span className="w-full flex justify-end">
                              {this.state.logging ? (
                                <Icon name="loading" className="mr-2" />
                              ) : null}
                            </span>
                            <span>
                              {this.props.type === "add" ? "Save" : "Update"}
                            </span>
                            <span className="w-full"></span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

export default connector(AddOtherTask);
