import {
  ManagerDefaultRights,
  EmployeeDefaultRights,
  AdminRights
} from "../../constants/defaultUserRights";
import { Client, Invitation, User, Workspace } from "../../helpers/types";
import { UPDATE_COMMON } from "../types";

const initialState = {
  bulkCopyDone: false,
  lock: undefined,
  currentFirm: undefined as Workspace | undefined,
  currentUser: undefined as User | undefined,
  firms: [] as Workspace[],
  isFirmPresent: false,
  rights: undefined as
    | ManagerDefaultRights
    | EmployeeDefaultRights
    | AdminRights
    | undefined,
  status: [] as any[],
  statusApplicableToAllTasks: [] as any[],
  tags: [] as any[],
  taskStatus: {} as any,
  timeTrackingRunning: false,
  urlInfo: "",
  users: [] as User[],
  invitationReceivedDetails: [] as Invitation[],
  editClient: undefined as Client | undefined,
  clientViewMode: "edit" as "edit" | "view",
  showMaintenance: false,
  maintenanceRunningMessage: "",
  upcomingMaintenanceMessage: "",
  adminUrl: "",
  adminError: "",
  currentModal: undefined as
    | { modalName: string; fetchAgain: boolean; data?: any }
    | undefined
};

type CommonState = typeof initialState;

export type CommonAction = {
  type: typeof UPDATE_COMMON;
  payload: Partial<CommonState>;
};

const commonReducer = (state = initialState, action: CommonAction) => {
  switch (action.type) {
    case UPDATE_COMMON:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export default commonReducer;
