import { useState, useRef } from "react";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

type Props = {
  heading?: string;
  items: { [key: string]: any }[];
  selected: { [key: string]: any };
  type: string;
  defaultValue?: string;
  placeholder?: string;
  onChange: (item: any) => void;
  onBackDropClick?: () => void;
};

export default function TableMultiSelectCheckbox(props: Props) {
  const [query, setQuery] = useState("");
  const [position, setPosition] = useState({ posX: 0, posY: 0 });
  const comboBtn = useRef<HTMLButtonElement | null>(null);
  const comboInput = useRef<HTMLInputElement | null>(null);
  const options = props.items;

  const handleInputClick: any = () => comboBtn.current?.click();

  const onChange = (item: any) => {
    props.onChange(item[0]);
  };

  const filteredoptions =
    query === ""
      ? options
      : options.filter(option => {
          return option.name.toLowerCase().includes(query.toLowerCase());
        });

  function setPositionOfOptions() {
    const posX = comboInput.current?.getBoundingClientRect().left || 0;
    const posY = comboInput.current?.getBoundingClientRect().bottom || 0;
    setPosition({ posX, posY });
  }

  const displayCheckedValue = () => {
    return props.selected.map((item: any) => item.name).join(", ");
  };

  return (
    <Combobox as="div" value={[]} onChange={onChange} multiple>
      <Combobox.Label className="block text-sm font-medium text-gray-700">
        {props.heading}
      </Combobox.Label>
      <div className={`relative ${props.heading && "mt-1"}`}>
        <Combobox.Input
          ref={comboInput}
          onClick={handleInputClick}
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          onChange={event => {
            setQuery(event.target.value);
          }}
          displayValue={() => displayCheckedValue()}
          placeholder={props.placeholder}
          onFocus={(e: any) => {
            setQuery("");
            return e.target.select();
          }}
        />
        <Combobox.Button
          ref={comboBtn}
          onClick={setPositionOfOptions}
          className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
        >
          {({ open }) =>
            open ? (
              <>
                <XMarkIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <div
                  className="fixed left-0 md:left-64 top-0 w-full h-full bg-transparent z-10"
                  onClick={props.onBackDropClick}
                />
              </>
            ) : (
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            )
          }
        </Combobox.Button>
        <Combobox.Options
          style={{
            position: "fixed",
            zIndex: 100,
            top: `${position.posY}px`,
            left: `${position.posX}px`,
            marginTop: "0.5rem",
            marginRight: "-0.5rem",
            width:
              comboInput.current?.clientWidth || comboInput.current?.offsetWidth
          }}
          className="max-h-40 mt-1 rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm overflow-auto vertical-scroll"
        >
          {filteredoptions?.length === 0 ? (
            <Combobox.Option
              disabled={true}
              value={""}
              className="relative z-20 cursor-default select-none py-2 pl-3 pr-9 text-sm text-gray-900 sm:font-bold"
            >
              No Record Available
            </Combobox.Option>
          ) : (
            filteredoptions?.map(option => (
              <Combobox.Option
                key={option._id}
                value={option}
                className={({ active }) =>
                  classNames(
                    "relative z-20 cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                <>
                  <div className="flex items-center">
                    <span>
                      <input
                        id={"checkbox"}
                        name={"checkbox"}
                        type="checkbox"
                        style={{ border: "solid 1px #d1d5db" }}
                        className="focus:ring-indigo-500 mr-3 h-4 w-4 text-indigo-600 rounded border-gray-400"
                        checked={
                          props.selected.findIndex(
                            (item: any) => item._id === option._id
                          ) !== -1
                        }
                        onChange={() => null}
                      />
                    </span>

                    <span className={"block truncate"}>{option.name}</span>
                  </div>
                </>
              </Combobox.Option>
            ))
          )}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}
