import React, { ChangeEvent, Fragment, KeyboardEvent } from "react";
import { connect, ConnectedProps } from "react-redux";
import agent from "../../../agent";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../../store/types";
import { withRouter, WithRouterProps } from "../../../helpers/withRouter";
import { compose } from "redux";
import TableMultiSelectCheckbox from "../../../components/TableMultiSelectCheckbox";
import { AppDispatch, RootState } from "../../../store";
import { NotifyType } from "../../../store/reducers/notification";
import { CommonAction } from "../../../store/reducers/common";
import { ErrorRowHeader } from "./ImportErrors";

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateCommon: (payload: CommonAction["payload"]) =>
    dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: NotifyType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends Partial<PropsFromRedux & WithRouterProps> {
  row: {
    _id: string;
    name: string;
    primaryEmail: string;
    primaryMobile: {
      mobile: string;
    };
    city: string;
    row: number;
    fieldType: string;
    error?: any;
  };
  headers: ErrorRowHeader;
  requestId: string;
  importErrors: {
    [key: string]: any;
  };
  errorField: string;
  errLogs: {
    [key: string]: any;
  };
  err: any;
  getColumnErrors: () => void;
  getImportErrors: () => void;
  logs: {
    rowsWithErr: number;
    rowsWithoutErr: number;
  };
  setLogs: (rowsWithErr: number, rowsWithoutErr: number) => void;
  customFieldLists: any;
}

type State = {
  workSpaceId: string | undefined;
  errorData: any;
  error: any;
  loading: boolean;
  dataChanged: boolean;
};

class ErrorRow extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      workSpaceId: this.props.params?.firmId,
      loading: false,
      error: this.props.row?.[this.errField()] ?? "",
      errorData: this.props.row?.[this.errField()] ?? "",
      dataChanged: false
    };
  }

  // errorDataList = () => {
  //   switch (this.props?.errorField) {
  //     case "Custom Fields":
  //       return this.props?.customFieldLists;
  //     default:
  //       return [];
  //   }
  // };

  onChange = (e: any) => {
    this.setState({
      errorData: e.target.value
    });
  };

  handleCustomFieldValuesChange =
    (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
      this.setState({
        errorData: { name: name, value: e.target.value }
      });
    };

  onErrorDataListChange = (clickedItem: any) => {
    this.setState({ dataChanged: true });
    const index = this.state.errorData.findIndex(
      (item: any) => item._id === clickedItem._id
    );
    if (index === -1) {
      this.setState({
        errorData: [...this.state?.errorData, clickedItem]
      });
    } else {
      const updatedData = this.state.errorData.filter(
        (item: any) => item._id !== clickedItem._id
      );
      this.setState({
        errorData: updatedData
      });
    }
  };

  onSave = () => {
    const firmId = this.props.params?.firmId;

    if (!firmId) return;

    this.setState({
      loading: true,
      dataChanged: false
    });

    agent.ContactPerson.updateRowField(
      firmId,
      this.props.requestId,
      this.props.row._id,
      this.props.errorField,
      this.props.row.fieldType.toLowerCase().includes("list of custom fields")
        ? [this.state.errorData]
        : this.state.errorData
    )
      .then((res: any) => {
        this.props.getImportErrors();
        this.props.getColumnErrors();
        this.props.setLogs(
          (this.props.logs.rowsWithErr ?? 0) - 1,
          (this.props?.logs?.rowsWithoutErr ?? 0) + 1
        );
        this.props.addNotification?.("Success", res.message, "success");
      })
      .catch((err: any) => {
        this.props.addNotification?.(
          "Error",
          err?.response?.data?.errors?.[0]?.message ||
            (Object.keys(err?.response?.data?.error).length === 0
              ? " "
              : err?.response?.data?.error),
          "danger"
        );
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  onEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.onSave();
    }
  };

  errField = () => {
    switch (this.props.errorField) {
      case "Name":
        return "name";
      case "Primary Email":
        return "primaryEmail";
      case "Primary Mobile":
        return "primaryMobile";
      case "City":
        return "city";
      default:
        return "error";
    }
  };
  componentDidMount() {
    // console.log("errorData", this.state.errorData);
  }

  render() {
    return (
      <tr className="divide-x divide-gray-200">
        {this.props.headers.map((header, index) => {
          switch (header) {
            case "Row":
              return (
                <td
                  key={`${this.props.err}-${this.props.row?._id}-${header}`}
                  className={`${
                    index === 0 ? "px-6" : "px-4"
                  } text-center py-2 whitespace-nowrap text-sm text-gray-900`}
                >
                  {this.props.row?.row || "-"}
                </td>
              );
            case "Update":
              return (
                <td key={`${this.props.err}-${this.props.row?._id}-${header}`}>
                  <button
                    onClick={this.onSave}
                    disabled={
                      this.props.row.fieldType.toLowerCase().includes("list of")
                        ? this.state.errorData.value === this.state.error.value
                        : this.state.errorData === this.state.error
                    }
                    className={`text-sm font-semibold w-full text-blue-500 underline disabled:text-gray-500`}
                  >
                    Update
                  </button>
                </td>
              );
            case "Name":
              return index === this.props.headers.length - 2 ? (
                <td key={`${this.props.err}-${this.props.row?._id}-${header}`}>
                  <input
                    className={`w-[10rem] min-w-full text-sm text-gray-900 bg-red-100 focus:ring-red-500 focus:border-red-500 border-red-300`}
                    type={"text"}
                    value={this.state.errorData}
                    onChange={this.onChange}
                    onKeyDown={this.onEnter}
                  />
                </td>
              ) : (
                <td
                  key={`${this.props.err}-${this.props.row?._id}-${header}`}
                  className={`px-4 text-center py-2 whitespace-nowrap text-sm text-gray-900`}
                >
                  {this.props.row?.name?.length > 25
                    ? this.props.row?.name.slice(0, 25) + "..."
                    : this.props.row?.name || "-"}
                </td>
              );
            case "Primary Email":
              return index === this.props.headers.length - 2 ? (
                <td key={`${this.props.err}-${this.props.row?._id}-${header}`}>
                  <input
                    className={`w-[10rem] min-w-full text-sm text-gray-900 bg-red-100 focus:ring-red-500 focus:border-red-500 border-red-300`}
                    type={"email"}
                    value={this.state.errorData}
                    onChange={this.onChange}
                    onKeyDown={this.onEnter}
                  />
                </td>
              ) : (
                <td
                  key={`${this.props.err}-${this.props.row?._id}-${header}`}
                  className={`px-4 text-center py-2 whitespace-nowrap text-sm text-gray-900`}
                >
                  {this.props.row?.primaryEmail?.length > 25
                    ? this.props.row?.primaryEmail.slice(0, 25) + "..."
                    : this.props.row?.primaryEmail || "-"}
                </td>
              );
            case "Primary Mobile":
              return index === this.props.headers.length - 2 ? (
                <td key={`${this.props.err}-${this.props.row?._id}-${header}`}>
                  <input
                    className={`w-[10rem] min-w-full text-sm text-gray-900 bg-red-100 focus:ring-red-500 focus:border-red-500 border-red-300`}
                    type={"number"}
                    value={this.state.errorData.mobile}
                    onChange={this.onChange}
                    onKeyDown={this.onEnter}
                  />
                </td>
              ) : (
                <td
                  key={`${this.props.err}-${this.props.row?._id}-${header}`}
                  className={`px-4 text-center py-2 whitespace-nowrap text-sm text-gray-900`}
                >
                  {this.props.row?.primaryMobile.mobile || "-"}
                </td>
              );
            case "City":
              return index === this.props.headers.length - 2 ? (
                <td key={`${this.props.err}-${this.props.row?._id}-${header}`}>
                  <input
                    className={`w-[10rem] min-w-full text-sm text-gray-900 bg-red-100 focus:ring-red-500 focus:border-red-500 border-red-300`}
                    type={"text"}
                    value={this.state.errorData}
                    onChange={this.onChange}
                    onKeyDown={this.onEnter}
                  />
                </td>
              ) : (
                <td
                  key={`${this.props.err}-${this.props.row?._id}-${header}`}
                  className={`px-4 text-center py-2 whitespace-nowrap text-sm text-gray-900`}
                >
                  {this.props.row?.city || "-"}
                </td>
              );
            case "Error":
              const str =
                "Value type of custom field: Date field should be date";
              const fieldName = str
                .split("custom field: ")[1]
                .split(" field")[0]
                .trim();

              return index === this.props.headers.length - 2 ? (
                <td
                  key={`${this.props.err}-${this.props.row?._id}-${header}`}
                  className="bg-red-100 focus:ring-red-500 focus:border-red-500 border-red-300"
                >
                  {this.props.row.fieldType === "boolean (Yes/No)" ? (
                    <div
                      className={`w-[10rem] min-w-full text-sm text-gray-900 bg-red-100 focus:ring-red-500 focus:border-red-500 border-red-300 flex justify-around items-center`}
                    >
                      <label htmlFor="yes">
                        {" "}
                        Yes
                        <input
                          type="radio"
                          name={`${this.props.errorField}-${this.props.row?._id}`}
                          value="true"
                          checked={this.state.errorData === "true"}
                          onChange={this.onChange}
                          onKeyDown={this.onEnter}
                          className="ml-2"
                        />
                      </label>
                      <label htmlFor="no">
                        {" "}
                        No
                        <input
                          type="radio"
                          name={`${this.props.errorField}-${this.props.row?._id}`}
                          value="false"
                          checked={this.state.errorData === "false"}
                          onChange={this.onChange}
                          onKeyDown={this.onEnter}
                          className="ml-2"
                        />
                      </label>
                    </div>
                  ) : this.props.row.fieldType.includes(
                      "list of custom fields"
                    ) ? (
                    <input
                      className={`w-[10rem] min-w-full text-sm text-gray-900 bg-red-100 focus:ring-red-500 focus:border-red-500 border-red-300`}
                      type={this.props.err.includes("date") ? "date" : "text"}
                      value={this.state.errorData.value}
                      onChange={this.handleCustomFieldValuesChange(fieldName)}
                      onKeyDown={this.onEnter}
                    />
                  ) : (
                    <input
                      className={`w-[10rem] min-w-full text-sm text-gray-900 bg-red-100 focus:ring-red-500 focus:border-red-500 border-red-300`}
                      type={
                        this.props.row.fieldType === "text" ||
                        this.props.row.fieldType === "number/text"
                          ? "text"
                          : this.props.row.fieldType
                      }
                      value={this.state.errorData}
                      onChange={this.onChange}
                      onKeyDown={this.onEnter}
                    />
                  )}
                </td>
              ) : (
                <Fragment
                  key={`${this.props.err}-${this.props.row?._id}-${header}`}
                ></Fragment>
              );
            default:
              return (
                <Fragment
                  key={`${this.props.err}-${this.props.row?._id}-${header}`}
                ></Fragment>
              );
          }
        })}
      </tr>
    );
  }
}

export default compose(
  connector,
  withRouter
)(ErrorRow) as React.ComponentType<Props>;
