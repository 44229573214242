import { Component } from "react";
// Import to Display skeleton while loading data
import "react-loading-skeleton/dist/skeleton.css";
import { connect, ConnectedProps } from "react-redux";
import { compose } from "redux";
import { withRouter, WithRouterProps } from "../../helpers/withRouter";
// Redux Notify
import TagManager from "react-gtm-module";
import Dashboard from "../../components/Dashboard";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import MultiSelect from "../../components/MultiSelect";
import { RootState } from "../../store";
import agent from "../../agent";
import Skeleton from "react-loading-skeleton";
import ContactUpdateRow from "./ContactUpdateRow";
import Pagination from "../../components/Pagination";
import { Client, CustomField, Group, Person } from "../../helpers/types";
import AddCustomField from "../CustomField/Add";
import ClientUpdateRow from "./ClientUpdateRow";
import sortByName from "../../helpers/sortByName";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Bulk edit page"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: <T,>(payload: T) => dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: string) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends Partial<PropsFromRedux & WithRouterProps> {}

const contactPersonfields = [
  { _id: "name", name: "Name", required: true, fieldType: "text" },
  { _id: "email", name: "Email", required: true, fieldType: "email" },
  { _id: "mobile", name: "Mobile Number", required: true, fieldType: "mobile" },
  { _id: "pan", name: "PAN", fieldType: "text" },
  { _id: "din", name: "DIN", fieldType: "text" },
  { _id: "dscExpiryDate", name: "DSC Expiry Date", fieldType: "date" },
  { _id: "dscPassword", name: "DSC  Password", fieldType: "password" },
  { _id: "dateOfBirth", name: "Date of Birth", fieldType: "date" },
  {
    _id: "dateOfAnniversary",
    name: "Date of Anniversary",
    fieldType: "date"
  },
  { _id: "addressLine", name: "Address", fieldType: "address-fields" },
  { _id: "customFields", name: "Custom Fields", fieldType: "customFields" }
] as const;

const clientFields = [
  { _id: "name", name: "Name", required: true, fieldType: "text" },
  { _id: "tradeName", name: "Trade Name", fieldType: "text" },
  { _id: "fileNo", name: "File No", fieldType: "text" },
  {
    _id: "type",
    name: "Type of Client",
    required: true,
    fieldType: "clientType"
  },
  { _id: "notes", name: "Notes", fieldType: "textarea" },
  {
    _id: "dob",
    name: "Date of Birth/Date of Incorporation",
    fieldType: "date"
  },
  { _id: "companyCIN", name: "CIN", fieldType: "text" },
  { _id: "LLP_RegNo", name: "LLP", fieldType: "text" },
  { _id: "pan", name: "PAN", fieldType: "text" },
  { _id: "tan", name: "TAN", fieldType: "text" },
  {
    _id: "tanPassword",
    name: "IT Portal Deductor Password",
    fieldType: "password"
  },
  {
    _id: "itPortalPassword",
    name: "IT Portal Password",
    fieldType: "password"
  },
  { _id: "tracesUsername", name: "Traces Username", fieldType: "text" },
  {
    _id: "tracesPassword",
    name: "Traces Deductor Password",
    fieldType: "password"
  },
  {
    _id: "tracesTaxPayerPassword",
    name: "Traces Taxpayer Password",
    fieldType: "password"
  },
  { _id: "mcaV2Username", name: "MCA V2 Username", fieldType: "text" },
  { _id: "mcaV2Password", name: "MCA V2 Password", fieldType: "password" },
  { _id: "mcaV3Username", name: "MCA V3 Username", fieldType: "text" },
  { _id: "mcaV3Password", name: "MCA V3 Password", fieldType: "password" },
  { _id: "dgftUsername", name: "DGFT Username", fieldType: "text" },
  { _id: "dgftPassword", name: "DGFT Password", fieldType: "password" },
  { _id: "eWayBillUsername", name: "E-Way Bill Username", fieldType: "text" },
  { _id: "eWayPassword", name: "E-Way Password", fieldType: "password" },
  { _id: "gstUsername", name: "GST Username", fieldType: "text" },
  { _id: "gstPassword", name: "GST Password", fieldType: "password" },
  { _id: "defUsers", name: "Other Users", required: true, fieldType: "users" },
  { _id: "contactPerson", name: "Contact Person", fieldType: "contactPerson" },
  { _id: "customFields", name: "Custom Fields", fieldType: "customFields" },
  { _id: "groups", name: "Groups", fieldType: "groups" },
  { _id: "tags", name: "Tags", fieldType: "tags" }
] as const;

export type PersonFields =
  | (typeof contactPersonfields)[number]
  | { _id: ""; name: ""; fieldType: "" };
export type ClientFields =
  | (typeof clientFields)[number]
  | { _id: ""; name: ""; fieldType: "" };

export type FieldToUpdate = PersonFields | ClientFields;

export type FieldType =
  | (typeof contactPersonfields)[number]["fieldType"]
  | (typeof clientFields)[number]["fieldType"];

interface State {
  loading: boolean;
  selectedFieldToUpdate: FieldToUpdate;
  selectedCustomField: CustomField | { _id: ""; name: ""; type: "" };
  typingTimeout?: NodeJS.Timeout;
  searchText: string;
  contactPerson: Person[];
  clients: Client[];
  customFields: CustomField[];
  groupList: Group[];
  totalRecords: number;
  skip: number;
  currPage: number;
  chunkSize: number;
  showFieldModal: boolean;
}

class BulkUpdate extends Component<Props, State> {
  state: State = {
    loading: false,
    selectedFieldToUpdate: { _id: "", name: "", fieldType: "" },
    selectedCustomField: { _id: "", name: "", type: "" },
    searchText: "",
    contactPerson: [],
    clients: [],
    customFields: [],
    groupList: [],
    totalRecords: 0,
    skip: 0,
    currPage: 0,
    chunkSize: 500,
    showFieldModal: false
  };

  fieldsToUpdate() {
    switch (this.props.params?.update) {
      case "contact-person":
        return contactPersonfields;
      case "clients":
        return clientFields;
      default:
        return [];
    }
  }

  handleFieldToUpdateChange = (selectedFieldToUpdate: FieldToUpdate) => {
    this.setState({
      selectedFieldToUpdate,
      selectedCustomField: { _id: "", name: "", type: "" }
    });
  };

  getRecords = (forSearch = false) => {
    switch (this.props.params?.update) {
      case "contact-person":
        this.getPersonsList(forSearch);
        break;
      case "clients":
        this.getClientsList(forSearch);
        break;
      default:
        break;
    }
  };

  recordsToShow() {
    switch (this.props.params?.update) {
      case "contact-person":
        return this.state.contactPerson;
      case "clients":
        return this.state.clients;
      default:
        return [];
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const prevFirmId = prevProps.params?.firmId;
    const currFirmId = this.props.params?.firmId;

    if (
      (prevFirmId !== currFirmId ||
        prevState.selectedFieldToUpdate._id !==
          this.state.selectedFieldToUpdate._id) &&
      this.state.selectedFieldToUpdate._id
    ) {
      this.getRecords();
    }
    if (
      prevState.selectedFieldToUpdate !== this.state.selectedFieldToUpdate &&
      this.state.selectedFieldToUpdate._id
    ) {
      this.getCustomField();
      this.getGroupList();
      this.getPersonsList(false);
    }
  }

  getPersonsList = (forSearch: boolean) => {
    const workSpaceId = this.props.params?.firmId;
    const searchText = forSearch ? this.state.searchText : "";
    let limit = this.state.chunkSize;
    let skip = this.state.chunkSize * this.state.currPage;
    this.setState({ loading: true });
    workSpaceId &&
      agent.ContactPerson.getPersonList(
        workSpaceId,
        true,
        searchText,
        limit,
        skip
      )
        .then((response: { contactPerson: Person[]; count: number }) => {
          const updatedContactPersonList = response.contactPerson.map(item => {
            const updatedEmailArray =
              item.email.length === 0 || !("email" in item)
                ? [{ emailId: "", isPrimary: true }]
                : item.email;

            const updatedMobileArray =
              item.mobile.length === 0 || !("mobile" in item)
                ? [
                    {
                      mobileNumber: "",
                      isPrimary: true,
                      countryCode: "+91",
                      isWhatsapp: false
                    }
                  ]
                : item.mobile;

            return {
              ...item,
              email: updatedEmailArray,
              mobile: updatedMobileArray
            };
          });

          this.setState({
            contactPerson: updatedContactPersonList,
            loading: false,
            totalRecords: response.count
          });
        })
        .catch((err: any) => {
          this.setState({ loading: false });

          const errMessage =
            typeof err?.response?.data?.message === "object"
              ? "Could not load Contact Person List"
              : err?.response?.data?.message || err?.message || err;

          this.props.addNotification?.(
            "Could not load Contact Person List",
            errMessage,
            "danger"
          );
        });
  };

  getPersonById = (personId: string) => {
    const workSpaceId = this.props.params?.firmId;
    workSpaceId &&
      agent.ContactPerson.getPersonById(workSpaceId, personId)
        .then((res: { contactPerson: Person }) => {
          const { contactPerson } = this.state;
          const updatedContactPersonList = contactPerson.map(item => {
            if (item._id === personId) {
              return res.contactPerson;
            }
            return item;
          });

          this.setState({ contactPerson: updatedContactPersonList });
        })
        .catch(err => {
          const errMessage =
            typeof err?.response?.data?.message === "object"
              ? "Could not load Contact Person Details"
              : err?.response?.data?.message || err?.message || err;

          this.props.addNotification?.(
            "Could not load Contact Person Details",
            errMessage,
            "danger"
          );
        });
  };

  getClientsList = (forSearch: boolean) => {
    const workSpaceId = this.props.params?.firmId;
    const searchText = forSearch ? this.state.searchText : "";
    const limit = this.state.chunkSize;
    const skip = this.state.chunkSize * this.state.currPage;
    const filters = {};

    this.setState({ loading: true });
    workSpaceId &&
      agent.Clients.getClientList(
        workSpaceId,
        skip,
        limit,
        searchText,
        true,
        filters,
        false
      )
        .then((response: any) => {
          this.setState({
            clients: response.clients,
            loading: false,
            totalRecords: response.count
          });
        })
        .catch((err: any) => {
          this.setState({ loading: false });

          const errMessage =
            typeof err?.response?.data?.message === "object"
              ? "Could not load Client List"
              : err?.response?.data?.message || err?.message || err;

          this.props.addNotification?.(
            "Could not load Client List",
            errMessage,
            "danger"
          );
        });
  };

  getClientById = (clientId: string) => {
    const workSpaceId = this.props.params?.firmId;
    workSpaceId &&
      agent.Clients.getOneClientById(workSpaceId, clientId)
        .then((response: { client: Client[] }) => {
          const client = response.client[0];

          const gstApplicable = client?.gstApplicable ? ["gst"] : [];
          const itApplicable = client?.itApplicable ? ["income-tax"] : [];

          const updatedClientList = this.state.clients.map(item => {
            if (item._id === clientId) {
              return {
                ...client,
                applicableTaxes: [...gstApplicable, ...itApplicable]
              };
            }
            return item;
          });

          this.setState({ clients: updatedClientList });
        })
        .catch((err: any) => {
          this.props.addNotification?.(
            "Could not load Client Details",
            typeof err?.response?.data?.message === "object"
              ? "Could not load Client Details"
              : err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
  };

  getCustomField = () => {
    const workSpaceId = this.props.params?.firmId;
    workSpaceId &&
      agent.CustomField.getCustomFieldList(workSpaceId, true, "", 100000, 0)
        .then((res: { customFields: CustomField[] }) => {
          const filterCustomField = res.customFields.filter(
            item =>
              item.applicableFor ===
              (this.props.params?.update === "clients"
                ? "client"
                : "contactperson")
          );

          this.setState({ customFields: filterCustomField });
        })
        .catch(err => {
          this.props.addNotification?.(
            "Could not load Custom Field Details",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
  };

  getGroupList = () => {
    const workSpaceId = this.props.params?.firmId;
    workSpaceId &&
      agent.ClientGroups.getClientGroupList(workSpaceId, true, "", 0, 100000)
        .then((res: { groups: Group[] }) => {
          this.setState({ groupList: res.groups });
        })
        .catch(err => {
          this.props.addNotification?.(
            "Could not load Group List",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
  };

  handleSearchTextChange = (ev: any) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      searchText: ev.target.value,
      currPage: 0,
      typingTimeout: setTimeout(() => this.getRecords(true), 700)
    });
  };

  handlePageClick = (data: any) => {
    this.setState({ currPage: data.selected }, () => {
      this.state.searchText ? this.getRecords(true) : this.getRecords(false);
    });
  };

  handleItemPerPage = (value: any) => {
    this.setState({ chunkSize: value.name, currPage: 0 }, () =>
      this.state.searchText ? this.getRecords(true) : this.getRecords(false)
    );
  };

  openCustomFieldModal = () => {
    this.setState({ showFieldModal: true });
  };

  closeCustomFieldModal = () => {
    this.setState({ showFieldModal: false });
  };

  customFieldData = (newField: CustomField) => {
    this.setState({ customFields: [...this.state.customFields, newField] });
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);

    return (
      <Dashboard>
        {this.state.showFieldModal && (
          <AddCustomField
            showFieldModal={this.state.showFieldModal}
            closeModal={this.closeCustomFieldModal}
            customFieldData={this.customFieldData}
            from="contactperson"
          />
        )}
        <div className="max-w-screen-2xl mx-auto">
          <h1 className="text-2xl font-semibold text-gray-900 capitalize">
            {this.props.params?.update?.replace("-", " ")} Bulk Update
          </h1>
          <div className="mt-6 space-y-6 sm:divide-y-2 divide-gray-200 bg-white rounded-md shadow-md px-4 py-6">
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Select Field to Update
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                <MultiSelect
                  placeholder="Select Field to Update"
                  type="bulkUpdateField"
                  items={sortByName([...this.fieldsToUpdate()])}
                  selected={this.state.selectedFieldToUpdate}
                  onChange={this.handleFieldToUpdateChange}
                />
              </div>
            </div>
            {this.state.selectedFieldToUpdate.fieldType === "customFields" && (
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-6">
                <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Select Custom Field Type
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                  <MultiSelect
                    items={this.state.customFields}
                    selected={this.state.selectedCustomField}
                    type="fields"
                    onChange={selectedCustomField =>
                      this.setState({ selectedCustomField })
                    }
                    placeholder="Select customFieldType"
                  />
                </div>
              </div>
            )}

            {(this.state.selectedFieldToUpdate._id !== "customFields" ||
              (this.state.selectedFieldToUpdate._id === "customFields" &&
                this.state.selectedCustomField.name)) && (
              <div className="sm:pt-6">
                <div
                  className={`grid md:grid-cols-3 gap-4 ${
                    this.state.totalRecords > 0 ||
                    this.state.searchText.length > 0
                      ? "visible"
                      : "invisible"
                  }`}
                >
                  <div className="w-full col-start-2 col-end-3">
                    <input
                      id="search"
                      name="search"
                      type="search"
                      value={this.state.searchText}
                      placeholder="Search by Name, Email, or Mobile"
                      className="appearance-none block md:w-full max-w-xs px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 text-sm"
                      onChange={this.handleSearchTextChange}
                    />
                  </div>
                </div>
                {!this.state.loading ? (
                  this.recordsToShow().length > 0 ? (
                    <div id="table-scroll" className="mt-6 overflow-auto">
                      <div className="inline-block min-w-full py-2 px-1 align-middle">
                        <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                          <table className="min-w-full border-collapse border shadow-sm">
                            <thead className="bg-gray-50">
                              {this.recordsToShow().length > 0 && (
                                <tr>
                                  <th className="border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                                    {this.props.params?.update?.replace(
                                      "-",
                                      " "
                                    )}{" "}
                                    Name
                                  </th>
                                  <th className="border-b border-gray-300 bg-gray-50 px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider">
                                    {this.state.selectedFieldToUpdate?.name}{" "}
                                    {"required" in
                                      this.state.selectedFieldToUpdate &&
                                      this.state.selectedFieldToUpdate
                                        ?.required && (
                                        <span className="text-red-500">*</span>
                                      )}
                                    {this.state.selectedFieldToUpdate._id ===
                                      "customFields" &&
                                    this.state.selectedCustomField.name
                                      ? `(${this.state.selectedCustomField.name})`
                                      : ""}
                                  </th>
                                  {(this.state.selectedFieldToUpdate._id ===
                                    "mobile" ||
                                    this.state.selectedFieldToUpdate._id ===
                                      "email") && (
                                    <th className="border-b border-gray-300 bg-gray-50 px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider">
                                      Primary
                                    </th>
                                  )}

                                  {this.state.selectedFieldToUpdate._id ===
                                    "mobile" && (
                                    <th className="border-b border-gray-300 bg-gray-50 px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider">
                                      Whatsapp
                                    </th>
                                  )}
                                  {this.state.selectedFieldToUpdate._id ===
                                    "contactPerson" && (
                                    <>
                                      <th className="border-b border-gray-300 bg-gray-50 px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider">
                                        Designation
                                      </th>
                                      <th className="border-b border-gray-300 bg-gray-50 px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider">
                                        Primary
                                      </th>
                                    </>
                                  )}
                                  {((this.props.params?.update ===
                                    "contact-person" &&
                                    (this.state.selectedFieldToUpdate._id ===
                                      "mobile" ||
                                      this.state.selectedFieldToUpdate._id ===
                                        "email")) ||
                                    (this.props.params?.update === "clients" &&
                                      (this.state.selectedFieldToUpdate._id ===
                                        "contactPerson" ||
                                        this.state.selectedFieldToUpdate
                                          ?._id === "groups"))) && (
                                    <th className="border-b border-gray-300 bg-gray-50 px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider">
                                      {/* Add more button */}
                                    </th>
                                  )}
                                  <th className="border-b border-gray-300 bg-gray-50 px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider">
                                    {/* Updated */}
                                  </th>
                                  <th className="border-b border-gray-300 bg-gray-50 px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider">
                                    Message
                                  </th>
                                </tr>
                              )}
                            </thead>
                            <tbody className="bg-white">
                              {this.recordsToShow()?.map(
                                (record: Person | Client, index) =>
                                  this.props.params?.update ===
                                  "contact-person" ? (
                                    <ContactUpdateRow
                                      key={record._id}
                                      index={index}
                                      selectedFieldToUpdate={
                                        this.state
                                          .selectedFieldToUpdate as PersonFields
                                      }
                                      personData={record as Person}
                                      getPersonById={this.getPersonById}
                                      customFields={this.state.customFields}
                                      selectedCustomField={
                                        this.state.selectedCustomField
                                      }
                                      openCustomFieldModal={
                                        this.openCustomFieldModal
                                      }
                                    />
                                  ) : (
                                    <ClientUpdateRow
                                      key={record._id}
                                      index={index}
                                      selectedFieldToUpdate={
                                        this.state
                                          .selectedFieldToUpdate as ClientFields
                                      }
                                      clientData={record as Client}
                                      getClientById={this.getClientById}
                                      customFields={this.state.customFields}
                                      selectedCustomField={
                                        this.state.selectedCustomField
                                      }
                                      openCustomFieldModal={
                                        this.openCustomFieldModal
                                      }
                                      contactPersonList={this.state.contactPerson.map(
                                        ({ _id, name }) => ({
                                          _id,
                                          personId: _id,
                                          name,
                                          designation: "",
                                          isPrimary: false
                                        })
                                      )}
                                      groupList={this.state.groupList}
                                    />
                                  )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="block text-center my-10 border-2 border-gray-300 border-dashed p-16 md:mx-40 sm:mx-0 rounded-lg">
                      <h3 className="mt-2 text-sm font-medium text-gray-900">
                        {this.state.selectedFieldToUpdate?.name
                          ? "No record found"
                          : "No Field Selected"}
                      </h3>
                      {!this.state.selectedFieldToUpdate?.name && (
                        <p className="mt-2 text-sm text-gray-500">
                          Please select a field to update
                        </p>
                      )}
                    </div>
                  )
                ) : (
                  <div
                    style={{ gridTemplateColumns: `repeat(3, minmax(0, 1fr))` }}
                    className="bg-white grid"
                  >
                    {[...Array(10)].map(() =>
                      [...Array(3)].map((_e, i) => (
                        <div key={i} className="px-3 py-3">
                          <Skeleton />
                        </div>
                      ))
                    )}
                  </div>
                )}
                <Pagination
                  displayRecords={this.recordsToShow()}
                  totalRecords={this.state.totalRecords}
                  chunkSize={this.state.chunkSize}
                  currPage={this.state.currPage}
                  handlePageClick={this.handlePageClick}
                  handleItemPerPage={this.handleItemPerPage}
                  className="my-4"
                />
              </div>
            )}
          </div>
        </div>
      </Dashboard>
    );
  }
}

export default compose(
  connector,
  withRouter
)(BulkUpdate) as React.ComponentType<Props>;
