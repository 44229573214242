/* This example requires Tailwind CSS v2.0+ */

import { formatDate } from "../../helpers/formatDate";
import Icon from "../../components/Icon";
import { MouseEvent, useState } from "react";

import { compose } from "redux";
import { withRouter } from "../../helpers/withRouter";
import { connect, ConnectedProps } from "react-redux";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import DeleteModal from "../../components/DeleteModal";
import MultiSelect from "../../components/MultiSelect";
import { Todo, TodoEditData, todoList, TodoTemp } from "./Index";
import agent from "../../agent";
import Popup from "../../components/Popup";

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: (payload: any) => dispatch({ type: UPDATE_COMMON, payload }),
  onNotify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface FormProps {
  todo?: Todo;
  visibility?: any;
  setVisibility?: any;
  todoTemp?: any;
  setTodoData?: any;
  editTodo?: (todoData: TodoEditData, message?: string) => void;
  getUpdatedTodos: any;
  params: any;
  loading: boolean;
  setLoading: any;
  state: any;
  setState: any;
}

function EditTodoForm(props: FormProps & PropsFromRedux) {
  const {
    todo,
    visibility,
    setVisibility,
    editTodo,
    getUpdatedTodos,
    loading,
    setLoading,
    state,
    setState
  } = props;

  const editTodoTemp: TodoEditData = {
    toDoId: todo?._id ?? "",
    workSpaceId: todo?.workSpaceId ?? "",
    toDoListId: todo?.toDoListId ?? "",
    toDo: todo?.toDo ?? "",
    description: todo?.description ?? "",
    date: todo?.date ?? "" ?? "",
    star: todo?.star ?? false,
    order: todo?.order,
    reminderDate: todo?.reminderDate ?? new Date(),
    recurring: todo?.recurring ?? false,
    recurringPeriodCount: todo?.recurringPeriodCount ?? 1
  };
  const [editTemp, setEditTemp] = useState(editTodoTemp);

  const onChange = (e: any) => {
    setEditTemp({
      ...editTemp,
      [e.target.name]: e.target.value
    });
  };

  const handleSave = () => {
    setLoading(true);
    editTodo?.(editTemp);
    setVisibility(false);

    setEditTemp({
      toDoId: "",
      toDo: "",
      description: "",
      date: "",
      star: false,
      order: todo?.order,
      reminderDate: new Date(),
      recurring: false,
      recurringPeriodCount: 1
    });
    getUpdatedTodos();
    setLoading(false);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
  };

  const openDeleteModal = () => {
    setState({ ...state, showBackDrop: true });
    deleteModalSetOpen(true);
  };
  const deleteModalSetOpen = (open: boolean) => {
    setState({ ...state, showDeleteModal: open });
  };

  const [showTodoList, setShowTodoList] = useState(false);

  const showTodoListSelector = () => {
    setShowTodoList(true);
  };

  const hideTodoListSelector = () => {
    setShowTodoList(false);
  };

  const changeTodoList = (todoList: any) => {
    const { workSpaceId, toDoListId } = todo || {};

    if (toDoListId === todoList._id) return setShowTodoList(false);

    if (!workSpaceId || !toDoListId || !todo?._id) return;

    setLoading(true);
    agent.Todo.changeTodoList(workSpaceId, todoList._id, todo._id)
      .then(res => {
        (props as any).onNotify("Success", res.message, "success");
        getUpdatedTodos();
      })
      .catch(err => {
        (props as any).onNotify(
          "Could not edit Todo",
          err?.response?.data?.message || err?.error || err,
          "danger"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form
      className={`${
        visibility ? "flex" : "hidden"
      } w-full py-2 bg-transparent bg-white flex-col sm:items-center`}
      onSubmit={onSubmit}
    >
      <div className="w-full group relative pl-5 pr-6 py-3">
        <div className="flex gap-4">
          <label htmlFor={`toDo-${editTemp?.toDoId}`} className="sr-only">
            Edit Todo
          </label>
          <input
            type="text"
            name="toDo"
            id={`toDo-${editTemp?.toDoId}`}
            value={editTemp?.toDo}
            onChange={onChange}
            placeholder="Edit Todo"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm placeholder-gray-600 pr-8 xs:pr-40"
            onKeyPress={(e: any) => {
              if (e.charCode === 13) {
                handleSave();
              }
            }}
          />
          <button
            className="grid place-items-center rounded-md px-2 border-[1px] border-red-400 shadow-sm focus:outline-none focus-within:ring-2 focus-within:ring-red-500 hover:ring-1 hover:ring-red-400 focus:ring-1 focus:ring-red-400"
            onClick={openDeleteModal}
          >
            <Icon name="delete" className="h-4 w-4" />
          </button>
          {state.showDeleteModal && (
            <DeleteModal
              type={"todo item"}
              state={state}
              onLoad={getUpdatedTodos}
              deleteModalSetOpen={deleteModalSetOpen}
            />
          )}
        </div>
        <label htmlFor={`description-${editTemp?.toDoId}`} className="sr-only">
          Todo Description
        </label>
        <textarea
          name="description"
          id={`description-${editTemp?.toDoId}`}
          value={editTemp?.description}
          onChange={onChange}
          placeholder={`Description 2000 chars max.\n\n(Shift + Enter for new line.)`}
          className={`block min-w-full min-h-[9rem] sm:min-h-[6rem] rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm mt-2 placeholder-gray-600`}
          onKeyPress={(e: any) => {
            if (e.charCode === 13 && !e.shiftKey) {
              // Don't generate a new line
              e.preventDefault();
              handleSave();
            }
          }}
        />
        <div className={`flex items-center absolute top-5 xs:top-3 right-20`}>
          <input
            type="date"
            name="date"
            id={`date-${editTemp?.toDoId}`}
            value={formatDate(editTemp?.date, true)}
            onChange={onChange}
            className={`hidden xs:inline-block placeholder-gray-600 text-sm bg-transparent outline-none cursor-pointer text-gray-600 w-[7rem] border-none focus:border-none focus:outline-none focus:ring-2 focus:ring-indigo-500 rounded-md focus:bg-white px-1 mr-1`}
            onClick={e => (e.target as HTMLInputElement).showPicker()}
            onFocus={e => e.target.showPicker()}
          />
          <span
            className={`relative isolate grid place-items-center rounded-md`}
          >
            <input
              type="checkbox"
              name="star"
              id="star"
              checked={editTemp?.star}
              onChange={() =>
                setEditTemp({
                  ...editTemp,
                  star: !editTemp?.star
                })
              }
              className="row-span-full col-span-full rounded-full border-transparent z-10 focus:ring-2 focus:ring-yellow-500 cursor-pointer peer !bg-transparent !bg-gradient-to-r from-transparent to-transparent"
            />
            <Icon
              name="outline/star"
              className={`row-span-full col-span-full h-4 w-4 ${
                editTemp?.star
                  ? "fill-yellow-500 stroke-yellow-500"
                  : "fill-none stroke-gray-500"
              }`}
            />
          </span>
        </div>
        <div className="xs:hidden">
          <input
            type="date"
            name="date"
            id={`date`}
            value={formatDate(editTemp?.date, true)}
            onChange={onChange}
            className="mt-3 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm placeholder-gray-600"
          />
        </div>
        <div className={`flex items-center justify-between mt-2 ml-3 relative`}>
          <div className="flex gap-2 flex-col xs:flex-row xs:w-full justify-between items-center h-8">
            <p
              onClick={hideTodoListSelector}
              className={`text-[11px] font-medium text-gray-600 order-last xs:order-first w-full h-full flex items-center`}
            >
              Press Enter To Save Todo
            </p>

            {showTodoList ? (
              <div className="w-64 lg:w-72">
                <Popup content="Select Todo List">
                  <MultiSelect
                    type="todoList"
                    items={todoList}
                    selected={{ name: todo?.toDoListName }}
                    onChange={changeTodoList}
                    placeholder="Select Todo List"
                  />
                </Popup>
              </div>
            ) : (
              <Popup content="Change Todo List">
                <button
                  className="text-indigo-600 text-center whitespace-nowrap font-medium text-[12px] cursor-pointer flex items-center gap-x-2 w-fit underline underline-offset-2"
                  onClick={
                    todo?.status !== "completed"
                      ? showTodoListSelector
                      : undefined
                  }
                >
                  {todo?.toDoListName}
                </button>
              </Popup>
            )}
          </div>
          <button
            type="button"
            className="mt-3 sm:ml-4 inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 font-medium text-white hover:bg-indigo-700 disabled:bg-indigo-700 focus:outline-none sm:mt-0 w-32 text-sm xs:absolute right-3 bottom-14"
            onClick={handleSave}
          >
            <span className="w-full flex justify-end">
              {loading ? <Icon name="loading" className="mr-2" /> : null}
            </span>
            <span>Save</span>
            <span className="w-full"></span>
          </button>
        </div>
      </div>
    </form>
  );
}

export default compose(
  connector,
  withRouter
)(EditTodoForm) as React.ComponentType<FormProps>;
