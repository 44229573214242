import { CheckIcon } from "@heroicons/react/24/solid";
import React, { Component, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import Pagination from "../../../components/Pagination";
import { connect, ConnectedProps } from "react-redux";
import agent from "../../../agent";
import Dashboard from "../../../components/Dashboard";
import Icon from "../../../components/Icon";
import FileUploader from "../../../components/Import/FileUploader";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../../store/types";
import { Tab } from "@headlessui/react";
import { withRouter, WithRouterProps } from "../../../helpers/withRouter";
import { compose } from "redux";
import { downloadFile } from "../../../helpers/downloadFile";
import ImportErrors from "./ImportErrors";
import CancleModal from "../../../components/CancleModal";
import { formatDateAndTime } from "../../../helpers/formatDate";
import { Navigate } from "react-router";
import getTimeInMinSec from "../../../helpers/getTimeInMinSec";
import TagManager from "react-gtm-module";
import Message from "../../../components/Message";
import { RootState } from "../../../store";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Contact Person Import"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: <T,>(payload: T) => dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: string) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const headers = ["File", "Uploaded By", "Status", "Date"];

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends Partial<PropsFromRedux & WithRouterProps> {}

interface BulkUploadRequest {
  _id: string;
  fileName: string;
  originalFileName: string;
  totalRecords: 1;
  processedRecords: 0;
  status: string;
  remainingRecords: 1;
  createdAt: Date;
  updatedAt: Date;
}

interface State {
  workSpaceId: string;
  file: File | undefined;
  importRequests: any;
  displayImportRequests: any;
  totalRecords: number;
  totalRecordsToValidate: number;
  requestId: string;
  requestDate: string;
  importErrors: any;
  logs: {
    rowsWithErr: number;
    rowsWithoutErr: number;
  };
  downloading: boolean;
  logging: boolean;
  importing: boolean;
  uploading: boolean;
  showCancelModal: boolean;
  formSteps: {
    id: string;
    name: string;
    description: string;
    status: string;
  }[];
  currentStep: number;
  currPage: number;
  chunkSize: number;
  importStartedRequests: BulkUploadRequest;
  fetchInterval: NodeJS.Timer | null;
  fetching: boolean;
  estimatedTimeForOneRecord: number;
  importStatusClosed: boolean;
  importedContactPerson: number;
  errorReportDownloaded: boolean;
}

class Imports extends Component<Props, State> {
  state: State = {
    workSpaceId: (this.props as any).params?.firmId,
    file: undefined,
    importRequests: [],
    displayImportRequests: [],
    totalRecords: 0,
    totalRecordsToValidate: 0,
    requestId: "",
    requestDate: "",
    importErrors: {},
    logs: {
      rowsWithErr: 0,
      rowsWithoutErr: 0
    },
    downloading: false,
    logging: false,
    uploading: false,
    importing: false,
    showCancelModal: false,
    currPage: 0,
    chunkSize: 25,
    formSteps: [
      {
        id: "01",
        name: "Upload File",
        description: "Upload the Excel template to import Contact Person",
        status: "current"
      },
      {
        id: "02",
        name: "Review Contact Person",
        description: "Review/resolve errors before importing Contact Person",
        status: "upcoming"
      },
      {
        id: "03",
        name: "Import Status",
        description: "Shows current status of your import",
        status: "upcoming"
      }
    ],
    currentStep: 0,
    importStartedRequests: {} as BulkUploadRequest,
    fetchInterval: null,
    fetching: false,
    estimatedTimeForOneRecord: 3,
    importStatusClosed: false,
    importedContactPerson: 0,
    errorReportDownloaded: false
  };

  setErrorRecordDownloadStatus = (status: boolean) => {
    this.setState({ errorReportDownloaded: status });
  };

  formatAMPM(date: any) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  setCurrentStep = (step: number) => {
    this.setState({
      formSteps: [
        ...this.state.formSteps.map((s: any, i: number) => {
          if (i < step) {
            return {
              ...s,
              status: "complete"
            };
          } else if (i === step) {
            return {
              ...s,
              status: "current"
            };
          } else {
            return {
              ...s,
              status: "upcoming"
            };
          }
        })
      ],
      currentStep: step
    });
  };

  getBulkUploadSheet = () => {
    this.setState({ downloading: true });
    agent.ContactPerson.getBulkUploadSheet(this.state.workSpaceId)
      .then((response: any) => {
        this.setState({ downloading: false });
        downloadFile(
          response,
          "TaxPido PMS - Contact Person Import Template.xlsx"
        );
      })
      .catch((err: any) => {
        this.setState({ downloading: false });
        (this.props as any).onNotify(
          "Could not download Contact Person Import Template",
          typeof err?.response?.data?.message === "object"
            ? "Something went wrong"
            : err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  getAllBulkUploadRequests = () => {
    const skip = this.state.currPage * this.state.chunkSize;
    this.setState({ logging: true });
    agent.ContactPerson.getAllBulkUploadRequests(this.state.workSpaceId)
      .then((res: any) => {
        this.setState({
          importRequests: res.data.reverse(),
          displayImportRequests: res.data.slice(
            skip,
            this.state.currPage * this.state.chunkSize + this.state.chunkSize
          ),
          totalRecords: res.data.length,
          logging: false
        });
      })
      .catch((err: any) => {
        this.setState({ logging: false });
        (this.props as any).addNotification(
          "Error",
          err?.res?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  getImportErrors = (id: string, status?: string) => {
    this.setState({ fetching: true });
    agent.ContactPerson.getErrorColumns((this.props as any).params.firmId, id)
      .then((res: any) => {
        // console.log("res", res);
        this.setState({
          importErrors: res.errorColumns,
          logs: res.errLogs,
          fetching: false
        });
        if (!status || status?.toLowerCase() === "pending") {
          this.setCurrentStep(1);
        } else if (
          (Object.keys(res.errorColumns).length === 0 &&
            this.state.currentStep === 0) ||
          status?.toLowerCase() === "importing"
        ) {
          this.startImport(id);
        }
      })
      .catch((err: any) => {
        this.setState({ fetching: false });
        (this.props as any).addNotification(
          "Error",
          err?.res?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  handlePageClick = (data: any) => {
    this.setState({ currPage: data.selected });
  };

  handleItemPerPage = (value: any) => {
    this.setState({ chunkSize: value.name, currPage: 0 });
  };

  import = () => {
    this.setState({ uploading: true });
    let data = new FormData();
    data.append("file", this.state.file as File);
    data.append("workSpaceId", this.state.workSpaceId);
    agent.ContactPerson.validateUpload(data)
      .then((res: any) => {
        if (res.response.validationRes.success) {
          this.setState({
            requestId: res.response.validationRes.requestId,
            logs: res.response.validationRes.logs,
            uploading: false
          });
          if (res.response.validationRes.logs.rowsWithErr > 0) {
            this.setCurrentStep(1);
            this.getImportErrors(res.response.validationRes.requestId);
          } else {
            this.startImport(res.response.validationRes.requestId);
          }
          return (this.props as any).addNotification(
            "Success",
            "File uploaded successfully",
            "success"
          );
        } else {
          this.setState({
            uploading: false
          });
          return (this.props as any).addNotification(
            "Error",
            res.response.validationRes?.error || res?.message,
            "danger"
          );
        }
      })
      .catch((err: any) => {
        this.setState({ uploading: false });
        (this.props as any).addNotification(
          "Could not upload file",
          typeof err?.response?.data?.message === "object"
            ? "Please check the file and try again"
            : err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  getOneBulkUploadRequests = (id: string) => {
    this.setState({ fetching: true });
    agent.ContactPerson.getOnebulkUploadRequest(this.state.workSpaceId, id)
      .then((res: { data: BulkUploadRequest }) => {
        this.setState({
          importStartedRequests: res.data,
          fetching: false
        });
      })
      .catch((err: any) => {
        this.setState({ fetching: false });
        (this.props as any).addNotification(
          "Error",
          err?.res?.data?.error || err?.error || err,
          "danger"
        );
      });
  };

  startImport = (id: string) => {
    this.setCurrentStep(2);
    this.showCancelModal(false);
    this.setState({ logging: true, importing: true, fetching: true });
    agent.ContactPerson.startImport(this.state.workSpaceId, id)
      .then((res: any) => {
        this.state.fetchInterval &&
          this.state.currentStep === 2 &&
          clearInterval(this.state.fetchInterval);

        this.setState({
          fetchInterval:
            this.state.fetchInterval && this.state.currentStep === 2
              ? null
              : setInterval(() => {
                  this.state.importStartedRequests?.status.toLowerCase() ===
                  "completed"
                    ? this.setState({
                        formSteps: [
                          ...this.state.formSteps.map((step: any) => ({
                            ...step,
                            status: "complete"
                          }))
                        ]
                      })
                    : this.getOneBulkUploadRequests(id);
                }, 3000),

          fetching: false,
          importing: false,
          logging: false
        });
        this.getOneBulkUploadRequests(id);
        (this.props as any).addNotification("Success", res?.message, "success");
      })
      .catch((err: any) => {
        this.setState({ logging: true, importing: false, fetching: false });
        (this.props as any).addNotification(
          "Could not start import",
          typeof err?.response?.data?.message === "object"
            ? "Something went wrong"
            : err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  showCancelModal = (show: boolean) => {
    this.setState({ showCancelModal: show });
  };

  onCancelImport = () => {
    this.setState({ file: undefined, showCancelModal: false });
    this.setCurrentStep(0);
  };

  onFileChange = (file: File) => {
    this.setState({ file });
  };

  componentDidMount() {
    this.getAllBulkUploadRequests();
    document.title = "Contact Person Import - TaxPido PMS";
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const prevFirmId = prevProps.params.firmId;
    const currFirmId = (this.props as any).params.firmId;

    if (prevFirmId !== currFirmId) {
      this.setState({ workSpaceId: currFirmId });
    }
    if (
      (prevState.currentStep !== this.state.currentStep &&
        this.state.currentStep === 0) ||
      this.state.workSpaceId !== prevState.workSpaceId
    ) {
      this.setState({ currPage: 0 });
      this.getAllBulkUploadRequests();
    }

    if (this.state.fetchInterval && this.state.currentStep === 0) {
      clearInterval(this.state.fetchInterval);
      this.setState({ fetchInterval: null });
    }

    if (
      this.state.currPage !== prevState.currPage ||
      this.state.chunkSize !== prevState.chunkSize
    ) {
      this.setState({
        displayImportRequests: this.state.importRequests.slice(
          this.state.currPage * this.state.chunkSize,
          this.state.currPage * this.state.chunkSize + this.state.chunkSize
        )
      });
    }
  }

  componentWillUnmount() {
    if (this.state.fetchInterval) {
      clearInterval(this.state.fetchInterval);
      this.setState({ fetchInterval: null });
    }
  }

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard>
        {/* <div
          className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 px-4 py-2 mb-4 max-w-full mx-auto lg:mx-8 flex justify-between"
          role="alert"
        >
          <p className="font-bold">This page is currently in test mode.</p>
        </div> */}
        {this.showCancelModal && (
          <CancleModal
            message="Are you sure you want to Cancel the Import?"
            loading={this.state.logging}
            showModal={this.state.showCancelModal}
            hideModal={() => this.showCancelModal(false)}
            onCancel={this.onCancelImport}
          />
        )}
        <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
          <Tab.Group
            selectedIndex={this.state.currentStep}
            onChange={this.setCurrentStep}
          >
            <div className="my-5 lg:mt-0 lg:col-span-2">
              <div className="rounded-t-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6 rounded-t-md">
                  <h1 className="text-2xl font-semibold text-gray-900">
                    Import Contact Person
                  </h1>
                  <nav aria-label="Progress">
                    <Tab.List as={Fragment}>
                      <ol className="divide-y divide-gray-300 rounded-md border border-gray-300 lg:flex lg:divide-y-0">
                        {this.state.formSteps.map(
                          (step: any, stepIdx: number) => (
                            <li
                              key={step.name}
                              className="relative lg:flex lg:flex-1 focus-within:outline-none"
                            >
                              <Tab
                                className="w-full"
                                disabled={
                                  (stepIdx === 1 &&
                                    (this.state.currentStep === 0 ||
                                      this.state.currentStep === 2)) ||
                                  (stepIdx === 2 &&
                                    (this.state.currentStep === 0 ||
                                      this.state.currentStep === 1))
                                }
                              >
                                {step?.status === "complete" ? (
                                  <span className="flex items-center px-6 py-4 text-sm font-medium">
                                    <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                                      <CheckIcon
                                        className="h-6 w-6 text-white"
                                        aria-hidden="true"
                                      />
                                    </span>
                                    <span className="ml-4 flex flex-col items-start gap-y-1 font-medium text-gray-900">
                                      <span className="text-sm">
                                        {step.name}
                                      </span>
                                      <span className="text-xs text-left">
                                        {step.description}
                                      </span>
                                    </span>
                                  </span>
                                ) : step?.status === "current" ? (
                                  <span className="flex items-center px-6 py-4 text-sm font-medium">
                                    <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                                      <span className="text-indigo-600">
                                        {step.id}
                                      </span>
                                    </span>
                                    <span className="ml-4 flex flex-col items-start gap-y-1 font-medium text-indigo-600">
                                      <span className="text-sm">
                                        {step.name}
                                      </span>
                                      <span className="text-xs text-left">
                                        {step.description}
                                      </span>
                                    </span>
                                  </span>
                                ) : (
                                  <span className="flex items-center px-6 py-4 text-sm font-medium">
                                    <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                      <span className="text-gray-500 group-hover:text-gray-900">
                                        {step.id}
                                      </span>
                                    </span>
                                    <span className="ml-4 flex flex-col items-start gap-y-1 font-medium text-gray-500 group-hover:text-gray-900">
                                      <span className="text-sm">
                                        {step.name}
                                      </span>
                                      <span className="text-xs text-left">
                                        {step.description}
                                      </span>
                                    </span>
                                  </span>
                                )}
                                {stepIdx !== this.state.formSteps.length - 1 ? (
                                  <>
                                    {/* Arrow separator for lg screens and up */}
                                    <div
                                      className="absolute top-0 right-0 hidden h-full w-5 lg:block"
                                      aria-hidden="true"
                                    >
                                      <svg
                                        className="h-full w-full text-gray-300"
                                        viewBox="0 0 22 80"
                                        fill="none"
                                        preserveAspectRatio="none"
                                      >
                                        <path
                                          d="M0 -2L20 40L0 82"
                                          vectorEffect="non-scaling-stroke"
                                          stroke="currentcolor"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </div>
                                  </>
                                ) : null}
                              </Tab>
                            </li>
                          )
                        )}
                      </ol>
                    </Tab.List>
                  </nav>
                </div>
              </div>
              <Tab.Panels>
                <Tab.Panel>
                  <div className="mb-6">
                    <div className="sm:overflow-hidden rounded-b-md">
                      <div className="px-4 rounded-b-md bg-white space-y-4 sm:px-6">
                        <span
                          onClick={this.getBulkUploadSheet}
                          className="text-indigo-600 text-center whitespace-nowrap font-medium text-sm cursor-pointer flex items-center gap-x-2 w-fit underline underline-offset-2"
                        >
                          Download Your Import Template
                          {this.state.downloading && (
                            <Icon name="loading" className="h-3 w-3" />
                          )}
                        </span>
                        {/* File Uploading Area */}
                        <FileUploader
                          addNotification={(this.props as any).addNotification}
                          file={this.state.file}
                          onChange={this.onFileChange}
                          disabled={false}
                        />
                        {/* Action Buttons */}
                        <div className="pb-5 sm:flex sm:justify-end">
                          <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:w-32 sm:text-sm disabled:bg-white"
                            disabled={this.state.logging}
                            onClick={() => {
                              this.state.file === undefined
                                ? (this.props as any).navigate(
                                    `/${this.state.workSpaceId}/contact-person/list`
                                  )
                                : this.showCancelModal(true);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="mt-3 sm:ml-4 w-full inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:mt-0 sm:w-32 sm:text-sm disabled:bg-indigo-600"
                            onClick={this.import}
                            disabled={
                              this.state.file === undefined ||
                              this.state.uploading === true
                            }
                          >
                            <span className="w-full flex justify-end">
                              {this.state.uploading ? (
                                <Icon name="loading" className="mr-2" />
                              ) : null}
                            </span>
                            <span>Import</span>
                            <span className="w-full"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white pt-6 shadow rounded-md">
                    <div className="px-4 sm:px-6 rounded-md">
                      <h2 className="text-lg leading-6 font-medium text-gray-900">
                        Import Requests
                      </h2>
                    </div>
                    <div className="mt-8 flex flex-col">
                      <div id="table-scroll" className="overflow-x-auto">
                        <div className="inline-block min-w-full align-middle">
                          <div className="shadow ring-1 ring-black ring-opacity-5 lg:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-50 sticky top-0 z-[8]">
                                <tr>
                                  {headers.map(header => (
                                    <th
                                      key={header}
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      {header}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody className="bg-white divide-y divide-gray-200">
                                {this.state.totalRecords > 0 ? (
                                  !this.state.logging &&
                                  this.state.displayImportRequests ? (
                                    this.state.displayImportRequests.map(
                                      (record: any) => (
                                        <tr key={record._id}>
                                          <td
                                            className={`whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6 font-medium ${
                                              record.status.toLowerCase() !==
                                              "completed"
                                                ? "text-indigo-600 cursor-pointer underline underline-offset-2"
                                                : "text-gray-600"
                                            }`}
                                            onClick={() => {
                                              if (
                                                record.status.toLowerCase() !==
                                                "completed"
                                              ) {
                                                this.getImportErrors(
                                                  record._id,
                                                  record.status
                                                );
                                                this.setState({
                                                  requestId: record._id,
                                                  requestDate: record.createdAt
                                                });
                                              }
                                            }}
                                          >
                                            {record.originalFileName || "-"}
                                          </td>
                                          <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6 font-medium text-gray-900">
                                            {(this.props as any)?.users?.find(
                                              (user: any) =>
                                                user._id === record.userId
                                            )?.name || "-"}
                                          </td>
                                          <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6 uppercase">
                                            <span
                                              className={
                                                record.status.toLowerCase() ===
                                                "completed"
                                                  ? "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                                                  : record.status.toLowerCase() ===
                                                    "failed"
                                                  ? "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
                                                  : record.status.toLowerCase() ===
                                                    "pending"
                                                  ? "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-700"
                                                  : "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-700"
                                              }
                                            >
                                              {record.status || "-"}
                                            </span>
                                          </td>
                                          <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                            {new Date(
                                              record.createdAt
                                            ).toLocaleDateString() +
                                              " - " +
                                              this.formatAMPM(
                                                new Date(record.createdAt)
                                              ) || "-"}
                                          </td>
                                        </tr>
                                      )
                                    )
                                  ) : (
                                    [...Array(4)].map((e, i) => (
                                      <tr key={`tr-${i}`} className="bg-white">
                                        {[...Array(headers.length)].map(
                                          (e, i) => (
                                            <td
                                              key={`td-${i}`}
                                              className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6"
                                            >
                                              <Skeleton />
                                            </td>
                                          )
                                        )}
                                      </tr>
                                    ))
                                  )
                                ) : (
                                  <tr>
                                    <td
                                      colSpan={headers.length}
                                      className="px-6 py-8 whitespace-nowrap text-sm text-gray-500"
                                    >
                                      * Import Request Data Not Available.
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Pagination
                    displayRecords={this.state.displayImportRequests}
                    totalRecords={this.state.totalRecords}
                    currPage={this.state.currPage}
                    chunkSize={this.state.chunkSize}
                    handlePageClick={this.handlePageClick}
                    handleItemPerPage={this.handleItemPerPage}
                    className="my-4"
                  />
                </Tab.Panel>
                <Tab.Panel>
                  <ImportErrors
                    importErrors={this.state.importErrors}
                    requestId={this.state.requestId}
                    requestDate={this.state.requestDate}
                    showCancelModal={this.showCancelModal}
                    logs={this.state.logs}
                    setLogs={(rowsWithErr: number, rowsWithoutErr: number) =>
                      this.setState({
                        logs: {
                          ...this.state.logs,
                          rowsWithErr,
                          rowsWithoutErr
                        }
                      })
                    }
                    startImport={() => this.startImport(this.state.requestId)}
                    getImportErrors={() =>
                      this.getImportErrors(this.state.requestId)
                    }
                    setErrorRecordDownloadStatus={
                      this.setErrorRecordDownloadStatus
                    }
                  />
                </Tab.Panel>
                <Tab.Panel>
                  <div className="grid gap-y-6">
                    <div className="bg-white py-2 rounded-b-lg overflow-hidden">
                      <div className="flex flex-col">
                        <div className="sm:flex-auto mx-6 py-2 border-2 border-gray-200 rounded-md">
                          <div className="p-4 space-y-4">
                            <h2 className="leading-6 font-semibold text-gray-900 text-lg">
                              Import Request Status
                            </h2>
                            {!this.state.fetching ? (
                              <div className="flex flex-col gap-y-3">
                                <div className="flex gap-x-4">
                                  <span className="font-bold">
                                    Current Status:{" "}
                                  </span>
                                  <span className="capitalize">
                                    {this.state.importStartedRequests?.status?.toLowerCase()}
                                  </span>
                                  {/* {this.state.fetching ? (
																	<Icon name="loading" className="w-4 h-4" />
																) : null} */}
                                </div>
                                <div>
                                  <span className="font-bold">
                                    Created at:{" "}
                                  </span>
                                  <span>
                                    {formatDateAndTime(
                                      this.state.importStartedRequests.createdAt
                                    )}
                                  </span>
                                </div>
                                <div>
                                  <span className="font-bold">
                                    Last Updated:{" "}
                                  </span>
                                  <span>{formatDateAndTime(new Date())}</span>
                                </div>
                                <div className="bg-gray-200 rounded-md h-2 w-full">
                                  <div
                                    style={{
                                      transform: `scaleX(${
                                        ((this.state.importStartedRequests
                                          .processedRecords ?? 0) /
                                          this.state.logs.rowsWithoutErr) *
                                        100
                                      }%)`
                                    }}
                                    className="bg-indigo-600 rounded-md h-full w-full origin-left"
                                  ></div>
                                </div>
                                <div className="flex justify-between">
                                  {this.state.logs.rowsWithoutErr > 0 &&
                                    this.state.importStartedRequests?.status?.toLowerCase() !==
                                      "completed" && (
                                      <div>
                                        <span className="font-bold">
                                          Estimated time:{" "}
                                        </span>
                                        <span>
                                          {getTimeInMinSec(
                                            this.state.logs.rowsWithoutErr,
                                            this.state.estimatedTimeForOneRecord
                                          )}
                                        </span>
                                      </div>
                                    )}
                                  <div className="ml-auto">
                                    <span className="font-bold">
                                      Processed -{" "}
                                    </span>
                                    <span>
                                      {this.state.importStartedRequests
                                        ?.processedRecords ?? 0}
                                      /{this.state.logs.rowsWithoutErr}
                                    </span>
                                  </div>
                                </div>
                                <div className="grid gap-4">
                                  <p className="text-sm font-medium text-gray-600">
                                    Note:- You can close this page if you want.
                                    Your request will be processed in background
                                    and you will get an email once the status
                                    get updated.
                                  </p>
                                  {this.state.errorReportDownloaded && (
                                    <p className="text-sm font-medium text-gray-600">
                                      Error Report Downloaded for the remaining
                                      records.
                                    </p>
                                  )}
                                  <button
                                    className="bg-indigo-600 text-white px-4 py-2 rounded-md sm:place-self-end"
                                    onClick={() =>
                                      this.setState({
                                        importStatusClosed: true
                                      })
                                    }
                                  >
                                    Close
                                  </button>
                                  {this.state.importStatusClosed && (
                                    <Navigate
                                      to={`/${this.state.workSpaceId}/contact-person/list`}
                                    />
                                  )}
                                </div>
                              </div>
                            ) : (
                              <>
                                <div className="flex flex-col gap-y-4">
                                  {[...Array(5)].map((_, i) => (
                                    <div
                                      key={i}
                                      className="flex flex-col gap-y-2"
                                    >
                                      <Skeleton />
                                    </div>
                                  ))}
                                </div>
                                <div className="flex justify-end">
                                  <Skeleton className="w-20 h-8" />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </div>
          </Tab.Group>
        </div>
      </Dashboard>
    );
  }
}

export default compose(connector, withRouter)(Imports) as React.ComponentType;
