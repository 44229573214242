import { Component } from "react";
import { OrderDetails } from ".";
import agent from "../../agent";
import Skeleton from "react-loading-skeleton";
import { ConnectedProps, connect } from "react-redux";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import { AppDispatch, RootState } from "../../store";
import { WithRouterProps, withRouter } from "../../helpers/withRouter";
import Dashboard from "../../components/Dashboard";
import { compose } from "redux";
import TagManager from "react-gtm-module";
import { CommonAction } from "../../store/reducers/common";
import { NotifyType } from "../../store/reducers/notification";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Payment Response Page"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateCommon: (payload: CommonAction["payload"]) =>
    dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: NotifyType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = Partial<PropsFromRedux & WithRouterProps>;

interface State {
  loading: boolean;
  paymentStatus: OrderDetails["status"] | "";
  paymentFailureReason: string | undefined;
  redirectTimeout: NodeJS.Timeout | null;
}

class PaymentReponse extends Component<Props, State> {
  state: State = {
    loading: false,
    paymentStatus: "",
    paymentFailureReason: "",
    redirectTimeout: null
  };

  orderId = this.props.searchParams?.get("orderid");

  getPaymentStatus = () => {
    if (!this.props?.currentFirm?._id) {
      return this.props.addNotification?.(
        "Could not load payment status",
        "Firm ID not found",
        "danger"
      );
    }

    if (!this.orderId)
      return this.props.navigate?.(
        `/${this.props?.currentFirm?._id}/subscriptions`
      );

    this.setState({ loading: true });
    agent.Payment.getPaymentStatus(this.orderId)
      .then((res: { order: OrderDetails[] }) => {
        if (res.order.length === 0) {
          this.setState({ loading: false });
          this.props.addNotification?.(
            "Could not load payment status",
            "Please try again later",
            "danger"
          );
          return;
        }

        const paymentStatus = res.order[0].status;
        const paymentFailureReason = res.order[0].respmsg;

        this.setState({
          loading: false,
          paymentStatus,
          paymentFailureReason
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        this.props.addNotification?.(
          "Could not load payment status",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  componentDidMount() {
    this.getPaymentStatus();
  }

  componentWillUnmount(): void {
    if (this.state.redirectTimeout) {
      clearTimeout(this.state.redirectTimeout);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.state.paymentStatus !== prevState.paymentStatus &&
      this.state.paymentStatus &&
      this.props?.currentFirm?._id
    ) {
      this.setState({
        redirectTimeout: setTimeout(() => {
          this.state.paymentStatus === "TXN_SUCCESS"
            ? this.props.navigate?.(
                `/${this.props?.currentFirm?._id}/dashboard`
              )
            : this.props.navigate?.(
                `/${this.props?.currentFirm?._id}/subscriptions`
              );
        }, 5000)
      });
    }
  }

  render() {
    TagManager.dataLayer(tagManagerArgs);

    return (
      <Dashboard>
        <div className="min-h-[75vh] grid gap-6 place-content-center">
          {this.state.loading ? (
            <div className="text-center">
              <Skeleton className="h-6 w-40" />
              <Skeleton className="h-6 w-96" />
            </div>
          ) : (
            <div className="text-center">
              {this.orderId ? (
                this.state.paymentStatus === "TXN_SUCCESS" ? (
                  <div>
                    <h3 className="capitalize text-2xl">Payment Successful</h3>
                    <p>
                      Thanks for making the payment. Your account has been
                      successfully updated.
                    </p>
                  </div>
                ) : (
                  <div>
                    <h3 className="capitalize text-2xl">Payment Failed</h3>
                    <p>
                      {this.state.paymentFailureReason ||
                        "Your payment could not be processed. Please try again."}
                    </p>
                  </div>
                )
              ) : (
                <div>
                  <h3 className="capitalize text-2xl">No payment found</h3>
                  <p>Please return to the subscriptions page and try again.</p>
                </div>
              )}
            </div>
          )}
          {/* <div className="flex justify-center">
            {this.state.paymentStatus === "TXN_SUCCESS" ? (
              <Link
                to={`/${(this.props )?.currentFirm?._id}/dashboard`}
                className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Go back home
              </Link>
            ) : (
              <Link
                to={`/${(this.props )?.currentFirm?._id}/subscriptions`}
                className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Go to subscriptions page
              </Link>
            )}
          </div> */}
        </div>
      </Dashboard>
    );
  }
}

export default compose(
  connector,
  withRouter
)(PaymentReponse) as React.ComponentType<Props>;
