import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import React, { ChangeEvent, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import Pagination from "../../components/Pagination";
import { connect, ConnectedProps } from "react-redux";
import agent from "../../agent";
import Editor from "../../components/Editor";
import Icon from "../../components/Icon";
import MultiSelect from "../../components/MultiSelect";
import MultiSelectCheckbox from "../../components/MultiSelectCheckbox";
import capitalize from "../../helpers/capitalize";
import {
  formatDate,
  formatDateAndTime,
  formatTime
} from "../../helpers/formatDate";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import parse from "html-react-parser";
import DeleteModal from "../../components/DeleteModal";
import { withRouter } from "../../helpers/withRouter";
import { compose } from "redux";
import Popup from "../../components/Popup";
import Dashboard from "../../components/Dashboard";
import Button from "../../components/Button";
import { ClockIcon } from "@heroicons/react/24/outline";
import TagManager from "react-gtm-module";
import MessageModal, {
  MessageModalType
} from "../../components/MessageModal";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Task Details"
  },
  dataLayerName: "PageDataLayer"
};

const headers = ["Type", "From", "To", "Date", "User"];

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: (payload: any) => dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class TaskDetailModal extends React.Component<PropsFromRedux> {
  state: {
    selectedStatus: any;
    details: any;
    usersInTask: any;
    currentUser: any;
    description: string;
    dueDate: string;
    star: boolean;
    showDescriptionEditor: boolean;
    showCommentEditor: boolean;
    descriptionLoading: boolean;
    commentsLoading: boolean;
    taskEdited: boolean;
    taskLoading: boolean;
    records: any;
    totalRecords: number;
    displayRecords: any;
    showActivity: boolean;
    statusLoading: boolean;
    logLoading: boolean;
    commentsList: any;
    editCommentId: string;
    addCommentLoading: boolean;
    editCommentLoading: boolean;
    showDeleteModal: boolean;
    deleteModalType: "comment" | "task" | "";
    selectedComment: any;
    selectedTask: any;
    typingTimeout: number;
    chunkSize: number;
    currPage: number;
    showStatusChangeModal: boolean;
    statusChangeModalData: {
      title: string;
      message: string;
      otherData: string;
      type: MessageModalType;
    };
  };

  constructor(props: any) {
    super(props);
    this.state = {
      selectedStatus: null,
      details: {},
      usersInTask: [],
      currentUser: null,
      description: "",
      dueDate: "",
      star: false,
      showDescriptionEditor: false,
      showCommentEditor: false,
      descriptionLoading: false,
      commentsLoading: false,
      taskEdited: false,
      taskLoading: false,
      records: [],
      totalRecords: 0,
      displayRecords: [],
      showActivity: false,
      statusLoading: false,
      logLoading: false,
      commentsList: [],
      editCommentId: "",
      addCommentLoading: false,
      editCommentLoading: false,
      showDeleteModal: false,
      deleteModalType: "",
      selectedComment: null,
      selectedTask: null,
      typingTimeout: 0,
      chunkSize: 25,
      currPage: 0,
      showStatusChangeModal: false,
      statusChangeModalData: {
        title: "",
        message: "",
        otherData: "",
        type: "success"
      }
    };
  }

  onKeyUpFunction = (event: any) => {
    if (event.keyCode === 27) {
      this.setClose();
    }
  };

  changeTaskStatus = (prevStatusName: string) => {
    const workSpaceId = (this.props as any).params?.firmId;
    const {
      _id: taskId,
      name: taskName,
      type: taskType,
      period: taskPeriod
    } = this.state.details;
    const { name: statusName, _id: statusId } = this.state.selectedStatus;

    // if (
    //   taskType === "GST" &&
    //   taskName !== "PMT-06" &&
    //   taskName !== "GSTR-4" &&
    //   prevStatusName.toLowerCase() === "completed"
    // ) {
    //   (this.props as any).addNotification(
    //     "Could not change task status",
    //     "You cannot change the status of GST task once it is marked as completed",
    //     "danger"
    //   );
    // } else {
    agent.Tasks.changeStatus(workSpaceId, [taskId], statusId)
      .then((response: any) => {
        const tasksNotCompletedIds = response?.tasksNotCompletedIds;
        if (
          tasksNotCompletedIds?.length > 0 &&
          taskType === "GST" &&
          taskName !== "PMT-06" &&
          statusName.toLowerCase() === "completed"
        ) {
          this.setState({
            showStatusChangeModal: true,
            statusChangeModalData: {
              title: "Return not filed",
              message: `Task ${taskName} (${taskPeriod}) could not be changed to ${statusName} as return is not filed on GSTIN server.`,
              type: "error"
            }
          });
        } else if (
          tasksNotCompletedIds?.length === 0 &&
          taskType === "GST" &&
          taskName !== "PMT-06" &&
          statusName.toLowerCase() === "completed"
        ) {
          this.setState({
            showStatusChangeModal: true,
            statusChangeModalData: {
              title: "Return filed successfully",
              message:
                taskName === "GSTR-4"
                  ? `We have marked ${taskName} (${taskPeriod}) as ${statusName} but we cannot confirm the filing on GSTN Servers as ${taskName} filing details are not shared by GSTN Servers`
                  : `Task ${taskName} (${taskPeriod}) has been changed to ${statusName} as return filing is successfully confirmed from GSTN server.`,
              otherData:
                taskName === "GSTR-4"
                  ? ""
                  : `ARN - ${response?.gstArn}\nDate of filing - ${response.gstDateOfFiling[0]}`,
              type: "success"
            }
          });
        } else if (taskType !== "GST") {
          (this.props as any).addNotification(
            "Task status changed successfully",
            "All selected tasks status changed successfully.",
            "success"
          );
        }
        this.setState({ taskEdited: true });
        this.getTaskDetails();
      })
      .catch((err: any) => {
        (this.props as any).addNotification(
          "Could not change task status",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
    // }
  };

  changeTaskStar = () => {
    const workSpaceId = (this.props as any).params?.firmId;
    const taskId = this.state.details?._id;
    const star = this.state.star;
    agent.Tasks.changeTaskStar(workSpaceId, [taskId], star)
      .then(() => {
        this.setState({ taskEdited: true });
      })
      .catch((err: any) => {
        (this.props as any).addNotification(
          "Could not change task star",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  changeCurrentlyWorkingUser = (oldUserId: string) => {
    const workSpaceId = (this.props as any).params?.firmId;
    const taskId = this.state.details?._id;
    const currentUserId = this.state.currentUser?._id;
    const changeType = this.state.details?.currentUserId ? "replace" : "add";
    const presentUserId =
      changeType === "replace" ? { presentUserId: oldUserId } : {};

    // const actions = { changeType, ...presentUserId };
    agent.Tasks.changeCurrentUser(
      workSpaceId,
      [taskId],
      currentUserId,
      presentUserId
    )
      .then(() => {
        this.setState({
          taskEdited: true,
          details: { ...this.state.details, currentUserId }
        });
        (this.props as any).addNotification(
          "Success",
          "Woking User changed successfully",
          "success"
        );
      })
      .catch((err: any) => {
        this.setState({ logging: false });
        (this.props as any).addNotification(
          "Error",
          err?.response?.data?.error || err?.error || err,
          "danger"
        );
      });
  };

  changeTaskDueDate = () => {
    const workSpaceId = (this.props as any).params?.firmId;
    const taskId = this.state.details?._id;
    const dueDate = this.state.dueDate
      ? new Date(this.state.dueDate).toISOString()
      : "";
    if (dueDate && dueDate.toString() !== "Invalid Date") {
      agent.Tasks.changeDueDate(workSpaceId, [taskId], dueDate)
        .then(() => {
          this.setState({ taskEdited: true });
          (this.props as any).addNotification(
            "Success",
            "Due date changed successfully",
            "success"
          );
        })
        .catch((err: any) => {
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    }
  };

  changeAllotedUser = (updateType: string, userId: string) => {
    const workSpaceId = (this.props as any).params?.firmId;
    const taskId = this.state.details?._id;
    const userIds = [userId];
    agent.Tasks.updateTaskUsers(workSpaceId, [taskId], userIds, {
      updateType
    })
      .then(() => {
        this.setState({ taskEdited: false });
        (this.props as any).addNotification(
          "Success",
          "Alloted user changed successfully",
          "success"
        );
      })
      .catch((err: any) => {
        this.setState({ logging: false });
        (this.props as any).addNotification(
          "Error",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  decodeHTML = (input: string) => {
    let doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  };

  getTaskDetails = () => {
    const workSpaceId = (this.props as any).params?.firmId;
    const taskId = (this.props as any).params?.taskId;
    this.setState({ taskLoading: true });

    agent.Tasks.getTaskfromTaskId(workSpaceId, taskId)
      .then((response: any) => {
        const task = response?.task;
        this.setState(
          {
            details: task,
            currentUser:
              task?.currentUserName && task?.currentUserId
                ? { name: task?.currentUserName, _id: task?.currentUserId }
                : null,
            selectedStatus:
              task?.statusName && task?.statusId
                ? { name: task?.statusName, _id: task?.statusId }
                : null,
            description: task?.description?.html
              ? this.decodeHTML(task.description.html)
              : "",
            dueDate: task?.dueDate ? formatDate(task.dueDate, true) : "",
            star: task?.star ? true : false,
            taskLoading: false
          },
          () => {
            this.getCommentList();
          }
        );
      })
      .catch((err: any) => {
        this.setState({ taskLoading: false, details: null });
        (this.props as any).addNotification(
          "Could not load Task Details",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  getTaskLogs = () => {
    const workSpaceId = (this.props as any).params?.firmId;
    const taskId = this.state.details?._id;
    this.setState({ logLoading: true });
    agent.Logs.getTaskLogs(workSpaceId, taskId)
      .then((response: any) => {
        this.setState({
          records: response.data,
          totalRecords: response.data.length,
          displayRecords: response.data.slice(
            this.state.currPage * this.state.chunkSize,
            this.state.currPage * this.state.chunkSize + this.state.chunkSize
          ),
          logLoading: false
        });
      })
      .catch((err: any) => {
        this.setState({ logLoading: false });
        (this.props as any).addNotification(
          "Could not load Task Logs",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  getCommentList = () => {
    const workSpaceId = (this.props as any).params?.firmId;
    const taskId = this.state.details?._id;
    const limit = 25;
    const skip = 0;
    this.setState({ commentsLoading: true });
    if (workSpaceId && taskId) {
      agent.Tasks.getTaskComment(workSpaceId, taskId, limit, skip)
        .then((response: any) => {
          this.setState({
            commentsList: response.comments,
            commentsLoading: false
          });
        })
        .catch((err: any) => {
          this.setState({ commentsLoading: false });
          (this.props as any).addNotification(
            "Could not load comments",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    }
  };

  componentDidMount() {
    this.getTaskDetails();
    document.addEventListener("keydown", this.onKeyUpFunction, false);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.currPage !== this.state.currPage ||
      prevState.chunkSize !== this.state.chunkSize
    ) {
      this.setState({
        displayRecords: this.state.records.slice(
          this.state.currPage * this.state.chunkSize,
          this.state.currPage * this.state.chunkSize + this.state.chunkSize
        )
      });
    }
    if (
      this.state.details !== prevState.details &&
      (this.props as any).users &&
      (this.props as any).users.length > 0
    ) {
      this.setTaskUsers();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyUpFunction, false);
  }

  setClose = () => {
    const from = (this.props as any).location?.state?.from;
    if (from) {
      (this.props as any).navigate(from);
    } else {
      (this.props as any).navigate(
        `/${(this.props as any).params.firmId}/tasks/list`
      );
    }
  };

  updateState = (field: string) => (ev: any) => {
    this.setState({
      [field]: capitalize(ev.target.value)
    });
  };

  openTimeTrackingModal = () => {
    (this.props as any).updateCommon({
      currentModal: {
        modalName: "TIME_TRACKING_MODAL",
        data: { selectedTask: this.state.details }
      }
    });
  };

  onStatusChange = (status: any) => {
    if (status._id === this.state.details?.statusId) return;
    this.setState({ selectedStatus: status }, () =>
      this.changeTaskStatus(this.state.details?.statusName)
    );
  };

  setTaskUsers = () => {
    const users = this.state.details.users;
    const user = (this.props as any).users.filter((user: any) =>
      users.includes(user._id)
    );
    this.setState({ usersInTask: user });
  };

  onUserChange = (user: any) => {
    const { usersInTask } = this.state;
    const index = usersInTask.findIndex((item: any) => item._id === user._id);
    if (index === -1) {
      this.setState({ usersInTask: [...usersInTask, user] }, () =>
        this.changeAllotedUser("add", user._id)
      );
    } else {
      const updatedUsers = usersInTask.filter(
        (item: any) => item._id !== user._id
      );
      this.setState({ usersInTask: updatedUsers }, () =>
        this.changeAllotedUser("remove", user._id)
      );
    }
  };

  changeCurrentUser = (user: any) => {
    const oldUserId = this.state.currentUser?._id;
    if (user._id === oldUserId) return;
    this.setState({ currentUser: user }, () =>
      this.changeCurrentlyWorkingUser(oldUserId)
    );
  };

  onDescriptionClick = () => {
    this.setState({ showDescriptionEditor: !this.state.showDescriptionEditor });
  };

  onStarClick = () => {
    this.setState(
      {
        star: !this.state.star
      },
      () => this.changeTaskStar()
    );
  };

  onDueDateChange = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({ dueDate: ev.target.value });
  };

  handlePageClick = (data: any) => {
    this.setState({ currPage: data.selected });
  };

  toggleActivity = () => {
    const { showActivity } = this.state;
    if (!showActivity) {
      this.getTaskLogs();
    }
    this.setState({ showActivity: !this.state.showActivity });
  };

  onAddCommentClick = () => {
    this.setState({ showCommentEditor: !this.state.showCommentEditor });
  };

  userDetail = (userId: string) => {
    const user = (this.props as any)?.users?.find(
      (item: any) => item._id === userId
    );
    if (user) {
      return user;
    }
    return { name: "Unknown", shortname: "UN" };
  };

  onEditClick = (comment: any) => {
    this.setState({ editCommentId: comment._id });
  };

  onCancelEdit = () => {
    this.setState({ editCommentId: "" });
  };

  saveDescription = (value: string) => {
    const workSpaceId = (this.props as any).params?.firmId;
    const taskIds = [this.state.details._id];
    const plainText = this.convertHtmlToText(value);
    const description = { text: plainText, html: value };
    this.setState({ descriptionLoading: true });
    agent.Tasks.changeDescription(workSpaceId, taskIds, description)
      .then((response: any) => {
        this.setState({
          descriptionLoading: false,
          showDescriptionEditor: false,
          description: value
        });
      })
      .catch((err: any) => {
        this.setState({ descriptionLoading: false });
        (this.props as any).addNotification(
          "Could not update description.",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  convertHtmlToText = (value: string) => {
    const plainText = value.replace(/<\/?[^>]+>/gi, "");
    const finalPlainText = plainText.replace(/&(nbsp|amp|quot|lt|gt);/g, " ");
    return finalPlainText;
  };

  saveNewComment = (value: string) => {
    const workSpaceId = (this.props as any).params?.firmId;
    const taskId = this.state.details._id;
    const plainText = this.convertHtmlToText(value);
    const comment = { text: plainText, html: value };
    this.setState({ addCommentLoading: true });
    agent.Tasks.addComment(workSpaceId, taskId, comment)
      .then((response: any) => {
        this.setState({
          addCommentLoading: false,
          showCommentEditor: false
        });
        this.getCommentList();
      })
      .catch((err: any) => {
        this.setState({ addCommentLoading: false });
        (this.props as any).addNotification(
          "Could not add the comment",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  saveEditedComment = (value: string) => {
    const workSpaceId = (this.props as any).params?.firmId;
    const commentId = this.state.editCommentId;
    const plainText = this.convertHtmlToText(value);
    const comment = { text: plainText, html: value };
    this.setState({ editCommentLoading: true });
    agent.Tasks.editComment(workSpaceId, commentId, comment)
      .then((response: any) => {
        this.setState({
          editCommentLoading: false,
          editCommentId: ""
        });
        this.getCommentList();
      })
      .catch((err: any) => {
        this.setState({ editCommentLoading: false });
        (this.props as any).addNotification(
          "Could not load Users Details",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  onDeleteComment = (comment: any) => {
    this.setState({
      showDeleteModal: true,
      selectedComment: comment,
      deleteModalType: "comment"
    });
  };

  onDeleteTask = (task: any) => {
    this.setState({
      showDeleteModal: true,
      selectedTask: task,
      deleteModalType: "task"
    });
  };

  deleteModalSetClose = (deleted: boolean) => {
    this.state.deleteModalType === "task" && deleted && this.setClose();
    this.setState({
      showDeleteModal: false,
      selectedComment: null,
      selectedTask: null,
      deleteModalType: ""
    });
  };

  onConfirm = () => this.setState({ showStatusChangeModal: false });

  onClose = () => this.setState({ showStatusChangeModal: false });

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard>
        <>
          {this.state.showStatusChangeModal && (
            <MessageModal
              show={this.state.showStatusChangeModal}
              data={this.state.statusChangeModalData}
              confirmText="Close"
              handleConfirm={this.onConfirm}
              handleClose={this.onClose}
            />
          )}
          {this.state.showDeleteModal && (
            <DeleteModal
              type={this.state.deleteModalType}
              state={this.state}
              onLoad={this.getCommentList}
              deleteModalSetOpen={this.deleteModalSetClose}
            />
          )}
          <Transition.Root show={true} as={Fragment}>
            <Dialog
              as="div"
              className="fixed z-10 inset-0 overflow-y-auto"
              onClose={() => null}
            >
              <div className="flex items-center justify-center h-full py-8 px-2">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="vertical-scroll inline-block bg-white rounded-lg w-11/12 lg:w-10/12 h-full px-6 md:px-8 py-8 overflow-auto shadow-xl transform transition-all">
                    <div className="flex items-center justify-between">
                      <h3 className="text-xl font-medium leading-6 text-gray-900">
                        Task Details
                      </h3>
                      <button type="button" onClick={this.setClose}>
                        <span className="sr-only">Close</span>
                        <XMarkIcon
                          className="h-6 w-6 text-gray-500"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                    {this.state.taskLoading || this.state.statusLoading ? (
                      <div className="space-x-2">
                        <div className="flex items-center gap-4 justify-between flex-wrap mt-6">
                          <div className="py-2 space-x-2">
                            <Skeleton height={40} />
                          </div>
                          <div className="sm:w-48 w-full ml-auto">
                            <Skeleton height={40} />
                          </div>
                        </div>
                        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-x-8 mt-4">
                          <div className="lg:col-span-2 space-y-4">
                            <div className="rounded py-2 px-3">
                              <Skeleton height={80} />
                            </div>
                            <div className="rounded py-2 px-3">
                              <Skeleton height={80} />
                            </div>
                            <div className="rounded py-2 px-3 mb-4">
                              <Skeleton height={60} />
                            </div>
                          </div>
                          <div className="bg-white rounded">
                            <Skeleton height={240} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {this.state.details === null ? (
                          <div className="flex items-center justify-center flex-col h-4/5">
                            <div>
                              <Icon
                                name="warning"
                                className={"w-16 h-16 text-gray-700"}
                              />
                            </div>
                            <p className="text-4xl font-bold text-gray-700">
                              Task Not Available!
                            </p>
                          </div>
                        ) : (
                          <>
                            <div className="sm:overflow-auto min-h-[80%] space-y-6">
                              <div
                                className={`space-y-4 md:space-y-0 md:grid md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-y-6 mt-4`}
                              >
                                <div className="lg:col-span-2 space-y-4">
                                  <div className="flex items-center gap-4 justify-between flex-wrap">
                                    <div className="text-lg text-gray-800 py-2 space-x-2 font-bold">
                                      <span>
                                        {this.state.details?.clientName
                                          ? `${this.state.details?.clientName}`
                                          : ""}
                                      </span>
                                      <span>
                                        {this.state.details?.name
                                          ? `/ ${this.state.details?.name}`
                                          : ""}
                                      </span>
                                      <span>
                                        {this.state.details?.period
                                          ? `/ ${this.state.details?.period}`
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                  {/* Description */}
                                  <div className="rounded shadow border border-gray-300 p-3">
                                    <div className="flex items-center justify-between">
                                      <label className="block text-base underline font-bold text-gray-700">
                                        Description
                                      </label>
                                      {!this.state.showDescriptionEditor && (
                                        <Popup content="Click to Edit">
                                          <button
                                            type="button"
                                            onClick={this.onDescriptionClick}
                                            className="p-1 rounded hover:bg-gray-200"
                                          >
                                            <Icon
                                              name="edit"
                                              className="w-4 h-4 text-gray-700"
                                            />
                                          </button>
                                        </Popup>
                                      )}
                                    </div>
                                    <div className="mt-3">
                                      {!this.state.showDescriptionEditor ? (
                                        this.state.description ? (
                                          <div
                                            onClick={this.onDescriptionClick}
                                            className="editor-style text-sm hover:bg-gray-200 w-full py-1 px-4 cursor-pointer rounded"
                                          >
                                            {parse(this.state.description)}
                                          </div>
                                        ) : (
                                          <button
                                            type="button"
                                            onClick={this.onDescriptionClick}
                                            className="text-sm hover:bg-gray-200 w-full text-start py-1 px-4 cursor-pointer rounded"
                                          >
                                            Add Description...
                                          </button>
                                        )
                                      ) : (
                                        <Editor
                                          initialValue={this.state.description}
                                          placeholder={"Add Description..."}
                                          cancelHandler={
                                            this.onDescriptionClick
                                          }
                                          saveHandler={this.saveDescription}
                                          loading={
                                            this.state.descriptionLoading
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                  {/* Comment */}
                                  <div className="border rounded shadow border-gray-300 p-3">
                                    <label className="block text-base underline font-bold text-gray-700">
                                      Comments
                                    </label>
                                    <div className="mt-3">
                                      {!this.state.showCommentEditor ? (
                                        <button
                                          type="button"
                                          onClick={this.onAddCommentClick}
                                          className="text-sm hover:bg-gray-200 w-full text-start py-1 px-4 cursor-pointer rounded"
                                        >
                                          Add Comments....
                                        </button>
                                      ) : (
                                        <Editor
                                          initialValue={""}
                                          placeholder={"Add Comment..."}
                                          cancelHandler={this.onAddCommentClick}
                                          saveHandler={this.saveNewComment}
                                          loading={this.state.addCommentLoading}
                                        />
                                      )}
                                    </div>
                                    <div className="mt-1 pl-4">
                                      <div className="w-full">
                                        {this.state.commentsLoading ? (
                                          <div>
                                            <div className="flex flex-col gap-4">
                                              <Skeleton height={70} />
                                              <Skeleton height={70} />
                                              <Skeleton height={70} />
                                            </div>
                                          </div>
                                        ) : (
                                          this.state.commentsList.map(
                                            (item: any) => {
                                              const decodedHtml =
                                                this.decodeHTML(
                                                  item.comment.html
                                                );
                                              return (
                                                <div
                                                  key={item._id}
                                                  className="my-4"
                                                >
                                                  <div className="flex items-start gap-3">
                                                    <span className="w-8 h-8 flex items-center justify-center p-2 rounded-full text-sm font-bold cursor-default bg-gray-800 text-white">
                                                      {
                                                        this.userDetail(
                                                          item.userId
                                                        )?.shortname
                                                      }
                                                    </span>
                                                    <div className="w-full">
                                                      <div className="flex gap-2 text-sm text-gray-600">
                                                        <p className="font-bold">
                                                          {
                                                            this.userDetail(
                                                              item.userId
                                                            )?.name
                                                          }
                                                        </p>
                                                        <p>|</p>
                                                        <p>
                                                          {formatTime(
                                                            item.createdAt
                                                              ? item.createdAt
                                                              : item.updatedAt
                                                          )}
                                                        </p>
                                                      </div>
                                                      <div className="my-2">
                                                        {this.state
                                                          .editCommentId ===
                                                        item._id ? (
                                                          <Editor
                                                            initialValue={
                                                              decodedHtml || ""
                                                            }
                                                            placeholder={
                                                              "Edit Comment..."
                                                            }
                                                            cancelHandler={
                                                              this.onCancelEdit
                                                            }
                                                            saveHandler={
                                                              this
                                                                .saveEditedComment
                                                            }
                                                            loading={
                                                              this.state
                                                                .editCommentLoading
                                                            }
                                                          />
                                                        ) : (
                                                          <div className="editor-style text-sm">
                                                            {decodedHtml
                                                              ? parse(
                                                                  decodedHtml
                                                                )
                                                              : ""}
                                                          </div>
                                                        )}
                                                        {localStorage.getItem(
                                                          "userId"
                                                        ) === item.userId && (
                                                          <>
                                                            {this.state
                                                              .editCommentId ===
                                                            item._id ? null : (
                                                              <div className="flex items-center gap-2 text-gray-600 text-sm mt-2">
                                                                <button
                                                                  type="button"
                                                                  className="hover:text-indigo-700"
                                                                  onClick={() =>
                                                                    this.onEditClick(
                                                                      item
                                                                    )
                                                                  }
                                                                >
                                                                  Edit
                                                                </button>
                                                                <span>|</span>
                                                                <button
                                                                  type="button"
                                                                  className="hover:text-indigo-700"
                                                                  onClick={() =>
                                                                    this.onDeleteComment(
                                                                      item
                                                                    )
                                                                  }
                                                                >
                                                                  Delete
                                                                </button>
                                                              </div>
                                                            )}
                                                          </>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="border border-gray-300 rounded shadow py-2 px-3 mb-4 max-w-full overflow-x-auto">
                                    <Activity
                                      {...this.props}
                                      state={this.state}
                                      toggleActivity={this.toggleActivity}
                                      handlePageClick={this.handlePageClick}
                                    />
                                  </div>
                                  <div className="pb-2 flex items-center justify-between">
                                    <Button
                                      name="Delete Task"
                                      icon="delete"
                                      onClick={() =>
                                        this.onDeleteTask(this.state.details)
                                      }
                                      className="relative inline-flex items-center gap-x-2 pl-4 pr-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:bg-red-700 focus:outline-none disabled:bg-red-500 disabled:cursor-not-allowed"
                                    />
                                    <Button
                                      name="Close"
                                      onClick={this.setClose}
                                    />
                                  </div>
                                </div>
                                <div className="h-max grid gap-4">
                                  <div className="w-full ml-auto grid grid-cols-2 items-center gap-4 flex-wrap">
                                    <div className="min-w-48 font-bold">
                                      <MultiSelect
                                        items={
                                          (this.props as any).taskStatus?.[
                                            this.state.details?.name
                                          ] ||
                                          (this.props as any)
                                            ?.statusApplicableToAllTasks
                                        }
                                        type="task-status"
                                        selected={{
                                          name: this.state.details?.statusName,
                                          color: this.state.details?.statusColor
                                        }}
                                        onChange={this.onStatusChange}
                                        placeholder="Select Status"
                                      />
                                    </div>
                                    <div className="w-fit ml-auto">
                                      {(this.props as any)
                                        .timeTrackingRunning ? (
                                        <Button
                                          name="Stop Time"
                                          icon={ClockIcon}
                                          onClick={this.openTimeTrackingModal}
                                        />
                                      ) : (
                                        <Button
                                          name="Start Time"
                                          icon={ClockIcon}
                                          onClick={this.openTimeTrackingModal}
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <div className="h-max border border-gray-300 bg-white shadow rounded pb-2">
                                    <div className="border-b border-gray-300 py-2.5 px-3 text-sm text-gray-700 font-medium">
                                      Details
                                    </div>
                                    <div className="py-3 px-3 flex items-center w-full">
                                      <p className="text-sm text-gray-600 font-medium w-2/4">
                                        Users
                                      </p>
                                      <div className="w-2/4">
                                        <div className="w-full">
                                          <MultiSelectCheckbox
                                            items={(
                                              this.props as any
                                            )?.users?.map((user: any) => {
                                              return {
                                                ...user,
                                                _id: user._id,
                                                name: user.name
                                              };
                                            })}
                                            selected={this.state.usersInTask}
                                            type="users"
                                            onChange={this.onUserChange}
                                            placeholder="Select Users"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="py-3 px-3 flex items-center w-full">
                                      <p className="text-sm text-gray-600 font-medium w-2/4">
                                        Due Date
                                      </p>
                                      <div className="w-2/4">
                                        <div>
                                          <input
                                            type="date"
                                            name="registration"
                                            id="registration"
                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                            value={this.state.dueDate}
                                            onChange={this.onDueDateChange}
                                            onBlur={this.changeTaskDueDate}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="py-3 px-3 flex items-center w-full">
                                      <p className="text-sm text-gray-600 font-medium w-2/4">
                                        Working
                                      </p>
                                      <div className="w-2/4">
                                        <div className="w-full">
                                          <MultiSelect
                                            items={(
                                              this.props as any
                                            )?.users?.map((user: any) => {
                                              return {
                                                ...user,
                                                _id: user._id,
                                                name: user.name
                                              };
                                            })}
                                            selected={{
                                              name: this.state.currentUser?.name
                                            }}
                                            type="working-users"
                                            onChange={this.changeCurrentUser}
                                            placeholder="Select Working User"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="py-3 px-3 flex items-center w-full">
                                      <p className="text-sm text-gray-600 font-medium w-2/4">
                                        Star
                                      </p>
                                      <div>
                                        <span className="relative isolate grid place-items-center">
                                          <input
                                            type="checkbox"
                                            name="star"
                                            id="star"
                                            checked={this.state.star}
                                            onChange={this.onStarClick}
                                            className="row-span-full col-span-full rounded-full border-transparent z-10 focus:ring-2 focus:ring-yellow-500 cursor-pointer peer !bg-transparent !bg-gradient-to-r from-transparent to-transparent"
                                          />
                                          <Icon
                                            name="outline/star"
                                            className={`row-span-full col-span-full h-4 w-4 ${
                                              this.state.star
                                                ? "fill-yellow-500 stroke-yellow-500"
                                                : "fill-none stroke-gray-500"
                                            }`}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  {this.state.details.type === "GST" &&
                                    this.state.details.gstReturnFiled && (
                                      <div className="h-max border border-gray-300 bg-white shadow rounded pb-2">
                                        <div className="border-b border-gray-300 py-2.5 px-3 text-sm text-gray-700 font-medium">
                                          GST Filing Details
                                        </div>
                                        <div className="py-3 px-3 flex items-center w-full">
                                          <p className="text-sm text-gray-600 font-medium w-2/4">
                                            ARN
                                          </p>
                                          <div className="text-sm text-gray-600">
                                            {this.state.details.gstArn || "-"}
                                          </div>
                                        </div>
                                        <div className="py-3 px-3 flex items-center w-full">
                                          <p className="text-sm text-gray-600 font-medium w-2/4">
                                            Filing Date
                                          </p>
                                          <div className="text-sm text-gray-600">
                                            {formatDate(
                                              this.state.details.gstFilingDate,
                                              false
                                            ) || "-"}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </>
      </Dashboard>
    );
  }
}

export default compose(
  connector,
  withRouter
)(TaskDetailModal) as React.ComponentType;

interface ActivityProps {
  state: any;
  toggleActivity: () => void;
  handlePageClick: (data: any) => void;
}

class Activity extends React.Component<ActivityProps> {
  getLogValue = (logKey: string, logs: any, type: string) => {
    if (logKey === "currentUser") {
      return logs[logKey]?.[type]?.currentUserName ?? "-";
    } else if (logKey === "description") {
      return logs[logKey]?.[type]?.text ?? "-";
    } else if (logKey === "dueDate") {
      return logs[logKey]?.[type]
        ? formatDate(logs[logKey]?.[type], false)
        : "-";
    } else if (logKey === "star") {
      return logs[logKey]?.[type] === true
        ? "Mark"
        : logs[logKey]?.[type] === false
        ? "Unmark"
        : logs[logKey]?.[type] ?? "-";
    } else {
      return logs[logKey]?.[type] === true
        ? "Active"
        : logs[logKey]?.[type] === false
        ? "Inactive"
        : logs[logKey]?.[type] ?? "-";
    }
  };

  render() {
    return (
      <>
        <div
          className="flex items-center justify-between gap-4 w-full py-3"
          onClick={this.props.toggleActivity}
        >
          <p className="text-base underline font-bold text-gray-700">
            Activity
          </p>
          <button
            type="button"
            className="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 w-fit text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={this.props.toggleActivity}
          >
            {this.props.state.showActivity ? "Hide" : "Show"}
          </button>
        </div>
        {this.props.state.showActivity && (
          <div>
            <div className="bg-white my-2 shadow rounded-md sm:overflow-hidden">
              <div className="flex flex-col">
                <div id="table-scroll" className="overflow-x-auto">
                  <div className="inline-block min-w-full align-middle">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full border divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            {headers.map((header, index) => (
                              <th
                                key={header}
                                scope="col"
                                className={`px-4 py-3 text-xs font-bold text-gray-500 text-start uppercase tracking-wider`}
                              >
                                {header}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {!this.props.state.logLoading ? (
                            this.props.state.totalRecords > 0 ? (
                              this.props.state.displayRecords.map(
                                (record: any, index: number) => {
                                  return record?.type === "add" ? (
                                    <tr key={record._id} className="bg-white">
                                      <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize">
                                        {`Task Added`}
                                      </td>
                                      <td></td>
                                      <td></td>
                                      <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 capitalize">
                                        {formatDateAndTime(record?.createdAt) ??
                                          "-"}
                                      </td>
                                      <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize">
                                        {record?.userId?.name ?? "-"}
                                      </td>
                                    </tr>
                                  ) : (
                                    <Fragment key={record._id}>
                                      {Object.keys(record?.logs).map(
                                        (logKey, index) => {
                                          return (
                                            <tr
                                              key={`${record?._id}-${logKey}`}
                                              className={
                                                index % 2 === 0
                                                  ? "bg-gray-50"
                                                  : "bg-white"
                                              }
                                            >
                                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold text-left capitalize">
                                                {logKey.toLowerCase() ===
                                                "statusname"
                                                  ? "Status"
                                                  : logKey}{" "}
                                                {logKey.toLowerCase() ===
                                                "statusname"
                                                  ? "Changed"
                                                  : "Updated"}
                                              </td>
                                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 capitalize max-w-[25ch]">
                                                <Popup
                                                  content={this.getLogValue(
                                                    logKey,
                                                    record?.logs,
                                                    "from"
                                                  )}
                                                  className="w-full max-w-fit"
                                                >
                                                  <p className="truncate">
                                                    {this.getLogValue(
                                                      logKey,
                                                      record?.logs,
                                                      "from"
                                                    )}
                                                  </p>
                                                </Popup>
                                              </td>
                                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize max-w-[25ch]">
                                                <Popup
                                                  content={this.getLogValue(
                                                    logKey,
                                                    record?.logs,
                                                    "to"
                                                  )}
                                                  className="w-full max-w-fit"
                                                >
                                                  <p className="truncate">
                                                    {this.getLogValue(
                                                      logKey,
                                                      record?.logs,
                                                      "to"
                                                    )}
                                                  </p>
                                                </Popup>
                                              </td>
                                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 capitalize">
                                                {formatDateAndTime(
                                                  record?.createdAt
                                                ) ?? "-"}
                                              </td>
                                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize">
                                                {record?.userId?.name ?? "-"}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </Fragment>
                                  );
                                }
                              )
                            ) : (
                              <div className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 w-full bg-white">
                                No Logs found.
                              </div>
                            )
                          ) : (
                            [...Array(5)].map((e, i) => (
                              <tr key={`tr-${i}`} className="bg-white">
                                {[...Array(headers.length)].map((e, i) => (
                                  <td
                                    key={`td-${i}`}
                                    className="px-2 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                  >
                                    <Skeleton />
                                  </td>
                                ))}
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Pagination
              displayRecords={this.props.state.displayRecords}
              totalRecords={this.props.state.totalRecords}
              currPage={this.props.state.currPage}
              chunkSize={this.props.state.chunkSize}
              handlePageClick={this.props.handlePageClick}
              hidechunkSizeSelector={true}
              className="my-4"
            />
          </div>
        )}
      </>
    );
  }
}
