import React, { Fragment } from "react";
// Import to Display skeleton while loading data
import Skeleton from "react-loading-skeleton";
// Pagination
import Pagination from "../../../components/Pagination";
import { connect, ConnectedProps } from "react-redux";
// Link backend
import agent from "../../../agent";
// Dashboard import
import Dashboard from "../../../components/Dashboard";
// Icons and styling
import Icon from "../../../components/Icon";

// Redux Notify
import { ADD_NOTIFICATION } from "../../../store/types";
import { withRouter, WithRouterProps } from "../../../helpers/withRouter";
import { compose } from "redux";
import "../../style.css";
import TagManager from "react-gtm-module";
import { downloadFile } from "../../../helpers/downloadFile";
import MultiSelect from "../../../components/MultiSelect";
import { quarterTillCurrentQuarter as quarters } from "../../../helpers/timePeriod";
import RequestProgressModal from "./RequestProgressModal";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Menu, Transition } from "@headlessui/react";
import Button from "../../../components/Button";
import Popup from "../../../components/Popup";
import MultiSelectCheckbox from "../../../components/MultiSelectCheckbox";
import { AppDispatch, RootState } from "../../../store";
import { NotifyType } from "../../../store/reducers/notification";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "QRMP"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping

const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addNotification: (title: string, message: string, type: NotifyType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const frequencyList = [
  { _id: "All", name: "All" },
  { _id: "M", name: "Monthly" },
  { _id: "Q", name: "Quarterly" },
  { _id: "NA", name: "Not Checked" },
  { _id: "N", name: "Not Opted" }
] as const;

const frequencyTypeList = [...frequencyList];

export type FrequencyItem = (typeof frequencyList)[number];
export type FrequencyType = FrequencyItem["_id"];

interface Props extends Partial<PropsFromRedux & WithRouterProps> {}

export type ImportRequest = {
  _id: string;
  importType: "QRMP";
  status: "Running" | "Completed";
  gstin: [];
  result: {
    alreadyFiled: number;
    statusUpdated: number;
    totalToRecordsToUpdate: number;
  };
  period: string;
  createdAt: Date;
  updatedAt: Date;
};

interface State {
  loading: boolean;
  downloading: boolean;
  updating: boolean;
  totalRecords: number;
  displayQRMPDetails: any;
  selectedQuarter: string;
  currPage: number;
  chunkSize: number;
  searchText: string;
  typing: boolean;
  typingTimeout: NodeJS.Timeout | null;
  showRequestModal: boolean;
  requestId: string;
  selectedFrequency: FrequencyItem[];
  importRequestDetails: ImportRequest | null;
  fetchInterval: any;
  rotate: boolean;
  showBackDrop: boolean;
}

class QRMP extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let frequencyFromParams = this.props.searchParams
      ?.get("frequency")
      ?.split(",")
      .map(frequency => frequencyList.find(item => item._id === frequency))
      .filter(item => item) as FrequencyItem[];

    this.state = {
      loading: false,
      downloading: false,
      updating: false,
      totalRecords: 0,
      displayQRMPDetails: [],
      selectedQuarter:
        this.props?.searchParams?.get("quarter") || quarters().reverse()[0],
      currPage: 0,
      chunkSize: 25,
      searchText: "",
      typing: false,
      typingTimeout: null,
      showRequestModal: false,
      requestId: "",
      selectedFrequency: frequencyFromParams?.length
        ? frequencyFromParams
        : frequencyTypeList,
      importRequestDetails: null,
      fetchInterval: null,
      rotate: false,
      showBackDrop: false
    };
  }

  headers = [
    "File no.",
    "Legal Name",
    "Trade Name",
    "GSTIN",
    "Frequency",
    "Last Checked",
    "Check Status"
  ];

  onOptionsDropdownClick = () => {
    this.setState({
      rotate: !this.state.rotate,
      showBackDrop: !this.state.showBackDrop
    });
  };

  handlePageClick = (data: any) => {
    this.setState({ currPage: data.selected });
  };

  handleItemPerPage = (value: any) => {
    this.setState({ chunkSize: value.name, currPage: 0 });
  };

  openRequestModal = (open: boolean) => {
    this.setState({ showRequestModal: open });
  };

  handleFrequencyChange = (frequency: FrequencyItem) => {
    const { selectedFrequency } = this.state;
    const frequencyNotAlreadySelected =
      selectedFrequency.findIndex((item: any) => item._id === frequency._id) ===
      -1;
    const allFrequencySelected =
      selectedFrequency.findIndex((item: any) => item._id === "All") !== -1;

    if (frequencyNotAlreadySelected) {
      if (
        frequency._id === "All" ||
        selectedFrequency.length === frequencyList.length - 2
      ) {
        this.setState({ selectedFrequency: frequencyTypeList });
      } else {
        this.setState({ selectedFrequency: [...selectedFrequency, frequency] });
      }
    } else {
      if (frequency._id === "All") {
        this.setState({ selectedFrequency: [] });
      } else if (
        allFrequencySelected &&
        selectedFrequency.length === frequencyList.length
      ) {
        this.setState({
          selectedFrequency: selectedFrequency.filter(
            (item: any) => item._id !== frequency._id && item._id !== "All"
          )
        });
      } else {
        this.setState({
          selectedFrequency: selectedFrequency.filter(
            (item: any) => item._id !== frequency._id
          )
        });
      }
    }
  };

  getQRMPlist = (download: boolean) => {
    const workSpaceId = this.props?.params?.firmId;

    if (!workSpaceId) {
      return this.props.addNotification?.(
        "Error",
        "Workspace ID not found. Please try again later.",
        "danger"
      );
    }

    const frequency = this.state.selectedFrequency
      .map(item => item._id)
      .filter(item => item !== "All");
    const skip = this.state.currPage * this.state.chunkSize;

    download
      ? this.setState({ downloading: true })
      : this.setState({ loading: true });

    agent.QRMP.getGstinQrmpList(
      workSpaceId,
      skip,
      this.state.chunkSize,
      this.state.searchText,
      this.state.selectedQuarter,
      frequency,
      download
    )
      .then((res: any) => {
        if (download) {
          downloadFile(
            res,
            `QRMP_${this.state.selectedQuarter}-${this.state.selectedFrequency}.xlsx`
          );
          this.setState({ downloading: false });
        } else {
          this.setState({
            displayQRMPDetails: res.clients,
            totalRecords: res.count,
            loading: false
          });
        }
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        this.props.addNotification?.(
          "Error",
          err.response.data.error,
          "danger"
        );
      });
  };

  getQrmpUpdateRequestDetails = (requestId: string) => {
    const workSpaceId = this.props?.params?.firmId;

    if (!workSpaceId) {
      return this.props.addNotification?.(
        "Error",
        "Workspace ID not found. Please try again later.",
        "danger"
      );
    }

    this.setState({ loading: true });
    agent.QRMP.getQrmpUpdateRequestDetails(workSpaceId, requestId)
      .then((res: any) => {
        this.setState({
          loading: false,
          importRequestDetails: res.importRequest
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        this.props.addNotification?.(
          "Error",
          err.response.data.error,
          "danger"
        );
      });
  };

  updateGstinQrmp = (gstin?: string) => {
    const workSpaceId = this.props?.params?.firmId;

    if (!workSpaceId) {
      return this.props.addNotification?.(
        "Error",
        "Workspace ID not found. Please try again later.",
        "danger"
      );
    }

    const clientRights = this.props?.rights?.clientRights;
    const createRight = clientRights?.create;
    if (createRight) {
      this.setState({ updating: true });
      agent.QRMP.updateGstinQrmp(
        workSpaceId,
        this.state.selectedQuarter,
        gstin ? [gstin] : []
      )
        .then((res: any) => {
          this.setState({
            updating: false,
            requestId: res.importRequest,
            fetchInterval: setInterval(() => {
              this.state.importRequestDetails?.status?.toLowerCase() !==
                "completed" && !gstin
                ? this.getQrmpUpdateRequestDetails(res.importRequest)
                : clearInterval(this.state.fetchInterval);
            }, 2000)
          });
          gstin ? this.getQRMPlist(false) : this.openRequestModal(true);
        })
        .catch((err: any) => {
          this.setState({ updating: false });
          this.props.addNotification?.(
            "Error",
            err?.response?.data?.error || err?.response?.data?.message,
            "danger"
          );
        });
    } else {
      this.props.addNotification?.(
        "Rights Not Avilable",
        "Ask Admin to change your user rights.",
        "danger"
      );
    }
  };

  handleSearchTextChange = (ev: any) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      searchText: ev.target.value,
      typing: false,
      currPage: 0,
      typingTimeout: setTimeout(() => {
        this.getQRMPlist(false);
      }, 700)
    });
  };

  lastDayofQRMP = () => {
    let monthString: string = this.state.selectedQuarter.slice(0, 3);
    let month: number =
      monthString === "Jan"
        ? 1
        : monthString === "Feb"
        ? 2
        : monthString === "Mar"
        ? 3
        : monthString === "Apr"
        ? 4
        : monthString === "May"
        ? 5
        : monthString === "Jun"
        ? 6
        : monthString === "Jul"
        ? 7
        : monthString === "Aug"
        ? 8
        : monthString === "Sep"
        ? 9
        : monthString === "Oct"
        ? 10
        : monthString === "Nov"
        ? 11
        : monthString === "Dec"
        ? 12
        : 0;

    let year: number = Number(
      "20".concat(this.state.selectedQuarter.slice(4, 6))
    );
    let date: number = 1;
    return new Date(year, month, date).toISOString();
  };

  // onMount Load data
  componentDidMount() {
    document.title = "QRMP - TaxPido PMS";
    const clientRights = this.props?.rights?.clientRights;
    const importClientRight = clientRights?.importClient;
    if (importClientRight) {
      this.getQRMPlist(false);
    } else if (importClientRight === false) {
      this.props.addNotification?.(
        "Rights Not Avilable",
        "Ask Admin to change your user rights.",
        "danger"
      );
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const prevFirmId = prevProps.params?.firmId;
    const currFirmId = this.props.params?.firmId;
    if (prevFirmId !== currFirmId && currFirmId) {
      this.getQRMPlist(false);
    }
    const prevClientRights = prevProps?.rights?.clientRights;
    const clientRights = this.props?.rights?.clientRights;
    const importClientRight = clientRights?.importClient;

    if (prevClientRights === undefined && clientRights && importClientRight) {
      this.getQRMPlist(false);
    }

    if (
      prevState.currPage !== this.state.currPage ||
      prevState.chunkSize !== this.state.chunkSize ||
      prevState.selectedQuarter !== this.state.selectedQuarter ||
      prevState.selectedFrequency !== this.state.selectedFrequency
    ) {
      this.getQRMPlist(false);
    }
  }

  componentWillUnmount() {
    if (this.state.fetchInterval) {
      clearInterval(this.state.fetchInterval);
    }
  }

  onRequestModalClose = () => {
    this.getQRMPlist(false);
    this.setState({ importRequestDetails: null });
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard>
        <RequestProgressModal
          setOpen={this.openRequestModal}
          showRequestModal={this.state.showRequestModal}
          importRequestDetails={this.state.importRequestDetails}
          onClose={this.onRequestModalClose}
        />
        <div className="max-w-full mx-auto lg:mx-8">
          <h1 className="text-2xl font-semibold text-gray-900">
            QRMP status of the period {this.state.selectedQuarter}
          </h1>
          <div className="mt-6 flex gap-x-4 items-end">
            <MultiSelect
              items={quarters()
                .reverse()
                ?.map((item: any) => {
                  return {
                    _id: item,
                    name: item
                  };
                })}
              selected={{
                name: this.state.selectedQuarter
              }}
              type="qrmp-period"
              onChange={(selected: any) => {
                this.setState({
                  selectedQuarter: selected.name
                });
              }}
              heading="Select QRMP Period"
              placeholder="Select QRMP Period"
            />
            <MultiSelectCheckbox
              items={frequencyTypeList}
              selected={this.state.selectedFrequency}
              type="frequency"
              onChange={this.handleFrequencyChange}
              heading="Select Frequency"
              placeholder="Select Frequency"
            />
          </div>
          <div className="mt-6 max-w-full mx-auto">
            <div className="flex justify-between items-center gap-4">
              <div className="hidden md:inline-flex">
                <Button
                  name="Update QRMP Status"
                  icon={
                    this.props?.rights?.clientRights.create
                      ? ArrowPathIcon
                      : "outline/lock-closed"
                  }
                  onClick={() => this.updateGstinQrmp()}
                />
              </div>
              <div className="w-64 sm:w-80">
                <input
                  id="search"
                  name="search"
                  type="search"
                  placeholder="Search by Legal Name, Trade Name or GSTIN"
                  value={this.state.searchText}
                  onChange={(ev: any) => {
                    this.handleSearchTextChange(ev);
                  }}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 sm:text-sm"
                />
              </div>

              <div className="md:hidden ml-auto flex gap-2">
                <Menu as="div" className="inline-block relative">
                  <Menu.Button
                    onClick={this.onOptionsDropdownClick}
                    className="relative inline-flex gap-x-2 items-center justify-between px-2 py-2 sm:py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white  bg-indigo-600 hover:bg-indigo-700 focus:outline-none disabled:bg-indigo-600 w-fit sm:pl-4 sm:pr-2"
                  >
                    <Icon
                      name="outline/settings"
                      className={`h-5 w-5`}
                      aria-hidden="true"
                    />
                    <span>Options</span>
                    <Icon
                      name="dropdown-arrow"
                      className={`h-5 w-5 ${
                        this.state.rotate ? "rotate-90" : ""
                      }`}
                      aria-hidden="true"
                    />
                  </Menu.Button>
                  {this.state.showBackDrop && (
                    <div
                      className="fixed top-0 left-0 z-10 w-full h-screen"
                      onClick={this.onOptionsDropdownClick}
                    ></div>
                  )}
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <div className="absolute z-[100] right-0">
                      <Menu.Items className="overscroll-none mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item as="div">
                            <Button
                              name="Update QRMP Status"
                              icon={
                                this.props?.rights?.clientRights.create
                                  ? ArrowPathIcon
                                  : "outline/lock-closed"
                              }
                              onClick={() => {
                                this.updateGstinQrmp();
                                this.onOptionsDropdownClick();
                              }}
                              className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900 disabled:cursor-not-allowed disabled:text-gray-400"
                            />
                          </Menu.Item>
                          <Menu.Item as="div">
                            <Button
                              name="Export"
                              icon={
                                this.props?.rights?.clientRights.importClient
                                  ? "outline/download"
                                  : "outline/lock-closed"
                              }
                              onClick={() => {
                                this.getQRMPlist(true);
                                this.onOptionsDropdownClick();
                              }}
                              className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900 disabled:cursor-not-allowed disabled:text-gray-400"
                            />
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </div>
                  </Transition>
                </Menu>
              </div>

              <div className="w-32 hidden md:flex justify-end">
                <Button
                  name="Export"
                  icon={
                    this.props?.rights?.clientRights.importClient
                      ? this.state.downloading
                        ? "loading"
                        : "outline/download"
                      : "outline/lock-closed"
                  }
                  onClick={() => {
                    this.getQRMPlist(true);
                  }}
                />
              </div>
            </div>
            <div id="table-scroll" className="mt-6 overflow-auto">
              <div className="inline-block min-w-full py-2 px-1 align-middle">
                <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                  <table className="min-w-full border-collapse border shadow-sm">
                    <thead className="bg-gray-50 min-w-full border border-gray-300 shadow-sm">
                      <tr>
                        {this.headers.map((header, index) => {
                          return (
                            <th
                              key={header}
                              className={`border-b border-gray-300 bg-gray-50 first:px-6 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider whitespace-nowrap`}
                            >
                              {header}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {!this.state.loading ? (
                        this.state.displayQRMPDetails.length > 0 ? (
                          this.state.displayQRMPDetails.map(
                            (record: any, index: number) => (
                              <tr
                                key={`${record.name}-${record.gstin}`}
                                className={
                                  index % 2 === 0 ? "bg-white" : "bg-gray-100"
                                }
                              >
                                <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-900 even:font-bold uppercase">
                                  {record.fileNo || "-"}
                                </td>
                                <td className="max-w-[20ch] px-4 sm:pl-6 py-4 whitespace-nowrap text-sm even:font-bold text-gray-900">
                                  <Popup
                                    content={record.tradeName}
                                    className="w-full max-w-fit truncate"
                                  >
                                    {record.tradeName}
                                  </Popup>
                                </td>
                                <td className="max-w-[20ch] px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                                  <Popup
                                    content={record.name}
                                    className="w-full max-w-fit truncate"
                                  >
                                    {record.name}
                                  </Popup>
                                </td>
                                <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-900 even:font-bold uppercase">
                                  {record.gstin}
                                </td>
                                <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                                  {record.qrmpPreferance === "Q"
                                    ? "Quarterly"
                                    : record.qrmpPreferance === "M"
                                    ? "Monthly"
                                    : record.qrmpPreferance === "N"
                                    ? "Not Opted"
                                    : record.qrmpPreferance === "NA"
                                    ? "Not Checked"
                                    : "-"}
                                </td>
                                <td className="w-1/10px-4  py-4 whitespace-nowrap text-sm text-gray-900 even:font-bold">
                                  {record.lastChecked
                                    ? new Date(
                                        record.lastChecked
                                      ).toLocaleDateString("en-In") +
                                      " - " +
                                      new Date(
                                        record.lastChecked
                                      ).toLocaleTimeString("en-In", {
                                        hour: "2-digit",
                                        minute: "2-digit"
                                      })
                                    : "-"}
                                </td>
                                <td className="px-4 py-4 text-center text-sm whitespace-nowrap text-blue-500 font-semibold">
                                  {record.qrmpPreferance === "N" ||
                                  record.qrmpPreferance === "NA" ? (
                                    <Button
                                      name="Check"
                                      onClick={() =>
                                        this.updateGstinQrmp(record.gstin)
                                      }
                                      className="underline"
                                    />
                                  ) : (record.qrmpPreferance === "M" ||
                                      record.qrmpPreferance === "Q") &&
                                    record.lastChecked <
                                      this.lastDayofQRMP() ? (
                                    <Button
                                      name="Check"
                                      onClick={() =>
                                        this.updateGstinQrmp(record.gstin)
                                      }
                                      className="underline"
                                    />
                                  ) : (
                                    "-"
                                  )}
                                </td>
                              </tr>
                            )
                          )
                        ) : this.state.searchText ? (
                          <tr className="bg-white">
                            <td
                              colSpan={this.headers.length}
                              className="w-full px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                            >
                              No Record Found for the search criteria
                            </td>
                          </tr>
                        ) : (
                          <tr className="bg-white">
                            <td
                              colSpan={this.headers.length}
                              className="w-full px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                            >
                              No Records Found for the selected quarter
                            </td>
                          </tr>
                        )
                      ) : (
                        [...Array(4)].map((e, i) => (
                          <tr key={i} className="bg-white">
                            {[...Array(this.headers.length)].map((e, i) => (
                              <td
                                key={i}
                                className="w-full px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                              >
                                <Skeleton />
                              </td>
                            ))}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Pagination
          displayRecords={this.state.displayQRMPDetails}
          totalRecords={this.state.totalRecords}
          currPage={this.state.currPage}
          chunkSize={this.state.chunkSize}
          handlePageClick={this.handlePageClick}
          handleItemPerPage={this.handleItemPerPage}
          className="my-4"
        />
      </Dashboard>
    );
  }
}

export default compose(connector, withRouter)(QRMP) as React.ComponentType;
