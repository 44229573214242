import { Fragment, useEffect, useState } from "react";
import Dashboard from "../../components/Dashboard";
import agent from "../../agent";
import { useNavigate, useParams } from "react-router";
import { NotifyType } from "../../store/reducers/notification";
import { AppDispatch, RootState } from "../../store";
import { CommonAction } from "../../store/reducers/common";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import { ConnectedProps, connect } from "react-redux";
import {
  GstReturnStatusAndQrmpDetailsResponse,
  Preference,
  QrmpDetails
} from "../../helpers/types";
import Button from "../../components/Button";
import { Table, TableHead, TableRow, TableCell } from "../../components/Table";
import {
  formatDateAsDdMmmYyyy,
  formatDateTimeString
} from "../../helpers/formatDate";
import { GstinDetails } from "./GstinDetails";
import { cn } from "../../lib/className";
import {
  ReturnPeriod,
  getFinancialMonthAndYear,
  yearTillLastYear,
  yearTillCurrentYear
} from "../../helpers/timePeriod";
import Skeleton from "react-loading-skeleton";
import { ArrowDownCircleIcon } from "@heroicons/react/20/solid";
import { compose } from "redux";
import MultiSelect from "../../components/MultiSelect";

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateCommon: (payload: CommonAction["payload"]) =>
    dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: NotifyType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = Partial<PropsFromRedux>;

const preferenceMap = {
  M: "Monthly",
  Q: "Quarterly",
  N: "NA",
  "-": "-"
} as const;

type FormattedQrmpDetails = Record<ReturnPeriod, Preference>;

function formatQrmpDetails(financialYear: string, qrmpDetails: QrmpDetails[]) {
  const formattedQrmpDetails: FormattedQrmpDetails = {};

  qrmpDetails.flatMap(item => {
    const returnPeriods = getFinancialMonthAndYear(
      financialYear,
      false,
      item.quarter
    );
    return returnPeriods.forEach(
      returnPeriod => (formattedQrmpDetails[returnPeriod] = item.preference)
    );
  });

  return formattedQrmpDetails;
}

export default compose(connector)(GstReturnAndQrmpDetails);

function GstReturnAndQrmpDetails(props: Props) {
  const { addNotification } = props;

  const params = useParams();
  const navigate = useNavigate();

  const { gstin, firmId } = params;

  const yearsList =
    new Date().getMonth() <= 3
      ? yearTillLastYear().reverse()
      : yearTillCurrentYear().reverse();

  const [financialYear, setFinancialYear] = useState("");

  const [loading, setLoading] = useState(false);
  const [gstReturnAndQrmpDetails, setGstReturnAndQrmpDetails] =
    useState<GstReturnStatusAndQrmpDetailsResponse>();
  const [showGstDetails, setShowGstDetails] = useState(false);
  const [formattedQrmpDetails, setFormattedQrmpDetails] =
    useState<FormattedQrmpDetails>();

  const fetchGstReturnAndQrmpDetails = async (
    update?: "returnDetails" | "gstDetails"
  ) => {
    if (!gstin) {
      addNotification?.("Error", "GSTIN not found", "danger");

      setTimeout(() => {
        navigate(`${firmId}/clients`);
      }, 2000);

      return;
    }

    setLoading(true);
    agent.ReturnStatus.getGstReturnAndQrmpDetails(gstin, financialYear, update)
      .then(data => {
        setGstReturnAndQrmpDetails(data);

        const { qrmpDetails } = data;

        if (qrmpDetails) {
          setFormattedQrmpDetails(
            formatQrmpDetails(financialYear, qrmpDetails)
          );
        }
      })
      .catch(error => {
        setGstReturnAndQrmpDetails(undefined);
        setFormattedQrmpDetails(undefined);

        addNotification?.(
          "Unable to fetch GST details",
          error?.response?.data?.error || error?.response?.data?.message,
          "danger"
        );
      })

      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    financialYear && fetchGstReturnAndQrmpDetails();
  }, [gstin, firmId, financialYear]);

  return (
    <Dashboard>
      <div className="space-y-6 max-w-8xl mx-auto lg:mx-8 mb-6">
        <div className="flex flex-wrap items-center justify-between gap-4">
          <h1 className="text-2xl font-semibold text-gray-900">
            Return Status
          </h1>
          <MultiSelect
            items={yearsList.map(item => {
              return {
                _id: item,
                name: item
              };
            })}
            selected={{
              name: financialYear
            }}
            type="financialYear"
            onChange={item => setFinancialYear(item.name)}
            placeholder="Select Financial Year"
          />
          {gstReturnAndQrmpDetails?.updatedAt && (
            <div className="flex items-center gap-4 text-sm">
              <span className="font-semibold">Last updated:</span>{" "}
              {formatDateTimeString(gstReturnAndQrmpDetails?.updatedAt)}
              <Button
                name="Update"
                icon={loading ? "loading" : "refresh"}
                onClick={() => fetchGstReturnAndQrmpDetails("returnDetails")}
              />
            </div>
          )}
        </div>
        <Table
          headers={[]}
          headerComponent={
            <thead>
              <tr className="divide-x-2 divide-gray-400 bg-gray-200">
                {[
                  "Months",
                  "QRMP Filling Frequency",
                  ...(gstReturnAndQrmpDetails?.returnTypes || ["GST Return"])
                ].map((header, index) => (
                  <TableHead
                    key={header}
                    colSpan={index === 0 || index === 1 ? 1 : 2}
                    className={cn(
                      "text-center text-base",
                      index === 1 && "w-[20ch] whitespace-nowrap"
                    )}
                  >
                    {header === "GSTR1" ? "GSTR1/IFF" : header}
                  </TableHead>
                ))}
              </tr>
            </thead>
          }
        >
          {!financialYear ? (
            <TableRow className="divide-x-2 divide-gray-400">
              <TableCell className="text-center" colSpan={4}>
                Select a financial year to view data
              </TableCell>
            </TableRow>
          ) : (
            <>
              <TableRow className="divide-x-2 divide-gray-400 bg-gray-100 font-semibold">
                <TableCell></TableCell>
                <TableCell></TableCell>
                {Array(gstReturnAndQrmpDetails?.returnTypes.length)
                  .fill("")
                  .map((_, index) => (
                    <Fragment key={index}>
                      <TableCell className="w-[20ch] text-center">
                        Status
                      </TableCell>
                      <TableCell className="w-[20ch] text-center">
                        Date of Filing
                      </TableCell>
                    </Fragment>
                  ))}
              </TableRow>
              {!loading ? (
                gstReturnAndQrmpDetails?.returnStatus &&
                Object.keys(gstReturnAndQrmpDetails?.returnStatus).length >
                  0 ? (
                  Object.entries(gstReturnAndQrmpDetails?.returnStatus).map(
                    ([returnPeriod, returnDetails]) => (
                      <TableRow
                        key={returnPeriod}
                        className="divide-x-2 divide-gray-400"
                      >
                        <TableCell className="text-center">
                          {returnPeriod}
                        </TableCell>
                        <TableCell className="text-center">
                          {
                            preferenceMap[
                              formattedQrmpDetails?.[
                                returnPeriod as ReturnPeriod
                              ] || "-"
                            ]
                          }
                        </TableCell>
                        {gstReturnAndQrmpDetails?.returnTypes.map(
                          returnType => {
                            const returnStatus = (returnDetails as any).find(
                              (returnDetail: any) =>
                                returnDetail.returnType === returnType
                            );
                            return (
                              <Fragment key={returnType}>
                                <TableCell className="text-center">
                                  {returnStatus?.status || "-"}
                                </TableCell>
                                <TableCell className="text-center">
                                  {returnStatus
                                    ? formatDateAsDdMmmYyyy(
                                        returnStatus.dateOfFiling
                                      )
                                    : "-"}
                                </TableCell>
                              </Fragment>
                            );
                          }
                        )}
                      </TableRow>
                    )
                  )
                ) : (
                  <TableRow className="divide-x-2 divide-gray-400">
                    <TableCell className="text-center" colSpan={4}>
                      No data found
                    </TableCell>
                  </TableRow>
                )
              ) : (
                [...Array(12)].map((e, i) => (
                  <TableRow key={i} className="divide-x-2 divide-gray-400">
                    {[
                      ...Array(gstReturnAndQrmpDetails?.returnTypes ? 6 : 4)
                    ].map((e, i) => (
                      <TableCell key={i} className="text-center">
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              )}
            </>
          )}
        </Table>
        {gstReturnAndQrmpDetails?.gstDetails && (
          <>
            {showGstDetails && (
              <GstinDetails
                gstinDetails={gstReturnAndQrmpDetails?.gstDetails}
                loading={loading}
                fetchGstReturnAndQrmpDetails={() =>
                  fetchGstReturnAndQrmpDetails("gstDetails")
                }
                gstLastUpdated={
                  gstReturnAndQrmpDetails?.gstDetails.gstDetailsLastUpdated
                }
              />
            )}
            <div className="flex items-center justify-end gap-4">
              <Button
                name={`${showGstDetails ? "Hide" : "Show"} GST Details`}
                onClick={() => setShowGstDetails(!showGstDetails)}
                icon={showGstDetails ? "subtract" : "add"}
              />
            </div>
          </>
        )}
      </div>
    </Dashboard>
  );
}
