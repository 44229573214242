const getCommaSeparatedValue = (
  value: number | string,
  withDecimal = false
): string => {
  const parsedValue = Number(value);
  if (isNaN(parsedValue)) {
    return "";
  }
  return withDecimal
    ? parsedValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
    : parsedValue.toFixed(0).replace(/\d(?=(\d{3})+$)/g, "$&,");
};

export default getCommaSeparatedValue;
