import { PeriodType } from "../pages/RecurringTask/Index";

const months = [
  { Jan: { days: 31 } },
  { Feb: { days: 28 } },
  { Mar: { days: 31 } },
  { Apr: { days: 30 } },
  { May: { days: 31 } },
  { Jun: { days: 30 } },
  { Jul: { days: 31 } },
  { Aug: { days: 31 } },
  { Sep: { days: 30 } },
  { Oct: { days: 31 } },
  { Nov: { days: 30 } },
  { Dec: { days: 31 } }
];

type Month = keyof (typeof months)[number];

const getMonthIndex = (month: Month) => {
  return months.findIndex(m => Object.keys(m).includes(month));
};

export default function getDueDate(
  periodType: PeriodType,
  period: string,
  after: boolean,
  days: number
) {
  switch (periodType) {
    case "yearly": {
      const year = parseInt(period.split("-")[1]) + 2000;
      const month = after ? 4 : 3;
      const day = after ? days : 31 - days;

      return `${year}-${month}-${day}`;
    }
    case "half-yearly":
    case "quarterly": {
      const year = parseInt(period.slice(-2)) + 2000;
      const monthString = period.slice(-6, -3) as Month;
      const monthIndex = getMonthIndex(monthString);
      const month = after ? monthIndex + 2 : monthIndex + 1;
      const daysInMonth = months[monthIndex][monthString]?.days;
      const day = after ? days : daysInMonth ? daysInMonth - days : 0;

      return `${month > 12 ? year + 1 : year}-${
        month > 12 ? month - 12 : month
      }-${day}`;
    }
    case "monthly": {
      const year = parseInt(period.slice(-2)) + 2000;
      const monthString = period.slice(0, 3) as Month;
      const monthIndex = getMonthIndex(monthString);
      const month = after ? monthIndex + 2 : monthIndex + 1;
      const daysInMonth = months[monthIndex][monthString]?.days;
      const day = after ? days : daysInMonth ? daysInMonth - days : 0;

      return `${month > 12 ? year + 1 : year}-${
        month > 12 ? month - 12 : month
      }-${day}`;
    }
    case "one time":
    default:
      return "";
  }
}
