import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import Popup from "../../components/Popup";
import TableMultiSelect from "../../components/TableMultiSelect";
import { formatDate } from "../../helpers/formatDate";
import { withRouter } from "../../helpers/withRouter";
import { compose } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { ADD_NOTIFICATION } from "../../store/types";
import { CurrentTab, UserRightsFilter, UserTypeFilter } from "./Index";

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props {
  state: {
    loading: boolean;
    workSpaceId?: string;
    currentTab: CurrentTab;
    dueDaysReport: any;
    showDueDaysReport: string;
    fetchingTaskBasedOnDueDays: boolean;
    taskRecordsBasedOnDueDays: any[];
    taskRecordsBasedOnDueDaysLength: number;
    userTypeFilter: UserTypeFilter;
    userRightsFilter: UserRightsFilter;
    selectedUser: { _id: string; name: string };
  };
  currentUser?: { _id: string; name: string };
  setShowDueDaysReport: any;
  onTableStatusChange: (
    task: any,
    prevStatusName: string
  ) => (status: any) => void;
  searchParameters: any;
}

class DueDaysReport extends Component<Props & PropsFromRedux> {
  state: {
    taskBasedOnDueDaysHeaders: string[];
    users: any[];
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      taskBasedOnDueDaysHeaders: [
        "File",
        "Client (Trade Name)",
        "Task",
        "Period",
        "Status",
        "Due Date"
      ],
      users: []
    };
  }

  openClientDetailsPage = (task: any) => {
    const firmId = (this.props as any).params?.firmId;
    const clientId = task.clientId;
    const clientName = task.clientName;
    (this.props as any).navigate(`/${firmId}/client-profile/${clientId}`, {
      state: { clientName }
    });
  };

  openTaskDetailsModal = (task: any) => {
    const firmId = (this.props as any).params?.firmId;
    const taskId = task.taskId;
    const location = (this.props as any).location.pathname;
    (this.props as any).navigate(`/${firmId}/taskId=${taskId}`, {
      state: { from: location }
    });
  };

  componentDidMount(): void {
    if (
      (this.props.state.userRightsFilter === "admin" ||
        this.props.state.userRightsFilter === "manager") &&
      !this.state.taskBasedOnDueDaysHeaders.includes("Users")
    ) {
      this.setState({
        taskBasedOnDueDaysHeaders: [
          ...this.state.taskBasedOnDueDaysHeaders,
          "Users"
        ]
      });
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevProps.state.userRightsFilter !== this.props.state.userRightsFilter
    ) {
      if (
        (this.props.state.userRightsFilter === "admin" ||
          this.props.state.userRightsFilter === "manager") &&
        !this.state.taskBasedOnDueDaysHeaders.includes("Users")
      ) {
        this.setState({
          taskBasedOnDueDaysHeaders: [
            ...this.state.taskBasedOnDueDaysHeaders,
            "Users"
          ]
        });
      }
      if (this.props.state.userRightsFilter === "employee") {
        this.setState({
          taskBasedOnDueDaysHeaders:
            this.state.taskBasedOnDueDaysHeaders.filter(
              (header: string) => header !== "Users"
            )
        });
      }
    }
    if (prevProps.users !== (this.props as any).users) {
      this.setState({
        users: (this.props as any).users
      });
    }
  }

  render() {
    return (
      <>
        <div className="flex flex-wrap gap-2 lg:gap-x-6 items-stretch w-full">
          {!this.props.state.loading
            ? Object.keys(this.props.state.dueDaysReport).map(heading => (
                <button
                  key={`dueDaysHeading-${heading}`}
                  className={`divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow basis-32 flex-grow shrink focus:outline-none hover:shadow-xl focus:shadow-2xl focus:ring-2 focus:ring-offset-2 ${
                    heading.includes("upto7")
                      ? "text-red-600 focus:ring-red-600"
                      : heading.includes("morethan7")
                      ? "text-red-800 focus:ring-red-800"
                      : heading.includes("Today")
                      ? "text-yellow-600 focus:ring-yellow-500"
                      : heading.includes("Tomorrow")
                      ? "text-green-600 focus:ring-green-600"
                      : "text-indigo-600 focus:ring-indigo-600"
                  }`}
                  onClick={() => {
                    this.props.setShowDueDaysReport(heading);
                  }}
                >
                  <div className="flex flex-col items-stretch justify-between px-3 py-4">
                    <span className="capitalize text-gray-600 font-medium">
                      {heading.includes("Today")
                        ? "due"
                        : heading.includes("Tomorrow")
                        ? "due"
                        : heading.includes("upto7")
                        ? "overdue"
                        : heading.includes("morethan7")
                        ? "overdue"
                        : "due"}
                    </span>
                    <span className={`text-6xl font-medium capitalize`}>
                      {this.props.state.dueDaysReport[heading as keyof object]}
                    </span>
                    <span className="capitalize text-gray-600 font-medium">
                      {heading.includes("Today")
                        ? "today"
                        : heading.includes("Tomorrow")
                        ? "tomorrow"
                        : heading.includes("upto7")
                        ? "up to 7 days"
                        : heading.includes("morethan7")
                        ? "more than 7 days"
                        : "in 7 days"}
                    </span>
                  </div>
                </button>
              ))
            : [...Array(5)].map((_, index) => (
                <button
                  key={`dueDaysSkeleton-${index}`}
                  className={`divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow basis-32 flex-grow shrink focus:outline-none hover:shadow-xl focus:shadow-2xl focus:ring-2 focus:ring-offset-2`}
                >
                  <div className="flex flex-col items-stretch justify-between p-3 gap-y-2">
                    <Skeleton />
                    <Skeleton className="text-5xl" />
                    <Skeleton />
                  </div>
                </button>
              ))}
        </div>
        {this.props.state.showDueDaysReport && (
          <div
            className={`divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow ${
              this.props.state.showDueDaysReport ? "block" : "hidden"
            }`}
          >
            <header className="py-3 px-4 md:px-6">
              <h3 className="text-base leading-6 font-semibold text-gray-900 capitalize">
                Tasks{" "}
                {this.props.state.showDueDaysReport.includes("Today")
                  ? "due today"
                  : this.props.state.showDueDaysReport.includes("Tomorrow")
                  ? "due tomorrow"
                  : this.props.state.showDueDaysReport.includes("upto7")
                  ? "overdue up to 7 days"
                  : this.props.state.showDueDaysReport.includes("morethan7")
                  ? "overdue more than 7 days"
                  : "due in 7 days"}
              </h3>
            </header>
            <div className="p-4">
              <div className="bg-white shadow rounded-md sm:overflow-hidden">
                <div className="flex flex-col">
                  <div id="tab-scroll" className="overflow-x-auto">
                    <div className="inline-block min-w-full align-middle">
                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              {this.state.taskBasedOnDueDaysHeaders.map(
                                (header: string) =>
                                  header !== "" && (
                                    <th
                                      key={`taskBasedOnDueDaysHeaders-${header}`}
                                      scope="col"
                                      className={`px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider text-left`}
                                    >
                                      {header}
                                    </th>
                                  )
                              )}
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {!this.props.state.fetchingTaskBasedOnDueDays ? (
                              this.props.state.taskRecordsBasedOnDueDays
                                .length > 0 ? (
                                <>
                                  {this.props.state.taskRecordsBasedOnDueDays.map(
                                    (record: any, index: number) => (
                                      <tr
                                        key={`taskRecordsBasedOnDueDays-${record._id}`}
                                        className={
                                          index % 2 === 0
                                            ? "bg-white"
                                            : "bg-gray-50"
                                        }
                                      >
                                        <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize">
                                          {record.clientFileNo || "-"}
                                        </td>
                                        <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 capitalize truncate max-w-[12rem]">
                                          <Popup
                                            content={`${record.clientName} ${
                                              record.clientTradeName
                                                ? `(${record.clientTradeName})`
                                                : ""
                                            }`}
                                            className="w-full max-w-fit"
                                          >
                                            <p
                                              className="hover:underline truncate cursor-pointer"
                                              onClick={() =>
                                                this.openClientDetailsPage(
                                                  record
                                                )
                                              }
                                            >
                                              {`${record.clientName} ${
                                                record.clientTradeName
                                                  ? `(${record.clientTradeName})`
                                                  : ""
                                              }`}
                                            </p>
                                          </Popup>
                                        </td>
                                        <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize truncate max-w-[20ch]">
                                          <Popup
                                            content={`${record.name} (${record.type})`}
                                            className="w-full max-w-fit"
                                          >
                                            <button
                                              className="truncate"
                                              onClick={() =>
                                                this.openTaskDetailsModal(
                                                  record
                                                )
                                              }
                                            >
                                              {record.name} ({record.type})
                                            </button>
                                          </Popup>
                                        </td>
                                        <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 capitalize max-w-[20ch]">
                                          <Popup
                                            content={record.period}
                                            className="w-full max-w-fit"
                                          >
                                            <button
                                              className="truncate"
                                              onClick={() =>
                                                this.openTaskDetailsModal(
                                                  record
                                                )
                                              }
                                            >
                                              {record.period}
                                            </button>
                                          </Popup>
                                        </td>
                                        <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-900 font-bold capitalize">
                                          <Popup
                                            content={record.statusName}
                                            className="w-full max-w-fit"
                                          >
                                            <div className="w-32">
                                              <TableMultiSelect
                                                items={
                                                  (this.props as any)
                                                    ?.taskStatus?.[
                                                    record.name
                                                  ] ||
                                                  (this.props as any)
                                                    ?.statusApplicableToAllTasks
                                                }
                                                type="task-status"
                                                selected={{
                                                  name: record.statusName,
                                                  color: record.statusColor
                                                }}
                                                onChange={this.props.onTableStatusChange(
                                                  record,
                                                  record.statusName
                                                )}
                                                placeholder="Select New Status"
                                              />
                                            </div>
                                          </Popup>
                                        </td>
                                        <td
                                          className={`px-3 py-4 whitespace-nowrap text-sm text-gray-900 capitalize ${
                                            this.props.state
                                              .userRightsFilter === "admin" &&
                                            "font-bold"
                                          }`}
                                        >
                                          {record.dueDate ? (
                                            <Popup
                                              content={`Task Due Date: ${formatDate(
                                                record.dueDate,
                                                false
                                              )}\n${
                                                record.govtDueDate
                                                  ? `Government Due Date: ${formatDate(
                                                      record.govtDueDate,
                                                      false
                                                    )}`
                                                  : ""
                                              }`}
                                              className="hover:underline"
                                            >
                                              {formatDate(
                                                record.dueDate,
                                                false
                                              )}
                                            </Popup>
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                        {(this.props.state.userRightsFilter ===
                                          "admin" ||
                                          this.props.state.userRightsFilter ===
                                            "manager") && (
                                          <td
                                            className={`px-3 py-4 whitespace-nowrap text-sm text-gray-900 capitalize`}
                                          >
                                            <Popup
                                              content={
                                                record.currentUserName
                                                  ? record.currentUserName
                                                  : "-"
                                              }
                                              className="w-full max-w-fit"
                                            >
                                              {record.currentUserName
                                                ? record.currentUserName
                                                : "-"}
                                            </Popup>
                                          </td>
                                        )}
                                      </tr>
                                    )
                                  )}
                                  {this.props.state
                                    .taskRecordsBasedOnDueDaysLength > 5 && (
                                    <tr className="bg-white">
                                      <td
                                        colSpan={
                                          this.state.taskBasedOnDueDaysHeaders
                                            .length
                                        }
                                        className="px-3 py-2 whitespace-nowrap text-gray-900 font-semibold capitalize text-center"
                                      >
                                        <Link
                                          className="text-sm inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 font-medium text-indigo-700 hover:bg-indigo-200 focus:bg-indigo-200 focus:outline-none"
                                          to={`/${
                                            this.props.state.workSpaceId
                                          }/tasks/list?${
                                            this.props.state.currentTab ===
                                            "Common"
                                              ? ""
                                              : this.props.searchParameters(
                                                  "type",
                                                  this.props.state.currentTab
                                                )
                                          }&${this.props.searchParameters(
                                            "dueDate"
                                          )}${
                                            this.props.state.userTypeFilter ===
                                              "otherUser" ||
                                            this.props.state.userTypeFilter ===
                                              "any"
                                              ? `&${this.props.searchParameters(
                                                  "otherUser",
                                                  this.props.state
                                                    .userRightsFilter ===
                                                    "employee"
                                                    ? this.props.currentUser
                                                        ?._id
                                                    : this.props.state
                                                        .selectedUser._id
                                                )}`
                                              : this.props.state
                                                  .userRightsFilter ===
                                                  "employee" &&
                                                this.props.state
                                                  .userTypeFilter ===
                                                  "currentUser"
                                              ? `&${this.props.searchParameters(
                                                  "user",
                                                  this.props.currentUser?._id
                                                )}`
                                              : `&${this.props.searchParameters(
                                                  "user",
                                                  this.props.state.selectedUser
                                                    ._id
                                                )}`
                                          }`}
                                        >
                                          View All
                                        </Link>
                                      </td>
                                    </tr>
                                  )}
                                </>
                              ) : (
                                <tr className="bg-white">
                                  <td
                                    colSpan={
                                      this.state.taskBasedOnDueDaysHeaders
                                        .length
                                    }
                                    className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize"
                                  >
                                    No Record Found
                                  </td>
                                </tr>
                              )
                            ) : (
                              [...Array(5)].map((e, i) => (
                                <tr
                                  key={`tr-taskBasedOnDueDays-${i}`}
                                  className="bg-white"
                                >
                                  {[
                                    ...Array(
                                      this.state.taskBasedOnDueDaysHeaders
                                        .length
                                    )
                                  ].map((e, i) => (
                                    <td
                                      key={`td-taskBasedOnDueDays-${i}`}
                                      className="px-2 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                    >
                                      <Skeleton />
                                    </td>
                                  ))}
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default compose(
  connector,
  withRouter
)(DueDaysReport) as React.ComponentType<Props>;
