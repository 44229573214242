import React from "react";
// Import to Display skeleton while loading data
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "../../helpers/withRouter";
import { compose } from "redux";
// Redux Notify
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import Dashboard from "../../components/Dashboard";
import agent from "../../agent";
import Button from "../../components/Button";
import Popup from "../../components/Popup";
import Pagination from "../../components/Pagination";
import { formatDateAndTime } from "../../helpers/formatDate";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Notifications List"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: (payload: any) => dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const headers = ["", "Notification", "Description", "Time", "Action"];

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class Notification extends React.Component<PropsFromRedux> {
  state: {
    read: boolean;
    displayNotifications: any[];
    loading: boolean;
    currPage: number;
    chunkSize: number;
    totalRecords: number;
    checked: string[];
  };

  constructor(props: any) {
    super(props);
    this.state = {
      totalRecords: 0,
      read: false,
      displayNotifications: [],
      loading: false,
      currPage: 0,
      chunkSize: 25,
      checked: []
    };
  }

  handleItemPerPage = (value: any) => {
    this.setState({ chunkSize: value.name, currPage: 0 });
  };

  handlePageClick = (data: any) => {
    this.setState({ currPage: data.selected });
  };

  getNotificationsList = () => {
    this.setState({ loading: true });
    const workSpaceId = (this.props as any).params.firmId;
    const { read, chunkSize, currPage } = this.state;
    const skip = currPage * chunkSize;
    agent.Notifications.listInAppNotifications(
      workSpaceId,
      read,
      skip,
      chunkSize
    )
      .then((res: any) => {
        this.setState({
          displayNotifications: res.notifications,
          totalRecords: res.count,
          loading: false
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).addNotification(
          "Could not fetch notifications",
          err?.response?.data?.error || err?.message || err,
          "error"
        );
      });
  };

  showReadNotifications = () => {
    this.setState({ read: !this.state.read, currPage: 0 });
  };

  onCheck = (id: any) => {
    if (this.state.checked.includes(id)) {
      const checked = this.state.checked.filter((item: any) => item !== id);
      this.setState({ checked });
    } else {
      const checked = [...this.state.checked, id];
      this.setState({ checked });
    }
  };

  onCheckAll = () => {
    if (this.state.checked.length === this.state.displayNotifications.length) {
      this.setState({ checked: [] });
    } else {
      const checked = this.state.displayNotifications.map(
        (notification: any) => notification._id
      );
      this.setState({ checked });
    }
  };

  markAsRead = (id: any) => {
    const workSpaceId = (this.props as any).params.firmId;
    this.setState({ loading: true });
    agent.Notifications.markAsRead(workSpaceId, id)
      .then((res: any) => {
        this.getNotificationsList();
        (this.props as any).addNotification(
          "Notification marked as read successfully",
          res.message,
          "success"
        );
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).addNotification(
          "Could not mark notification as read",
          err?.response?.data?.error || err?.message || err,
          "error"
        );
      });
  };

  markAllAsRead = () => {
    this.setState({ loading: true });
    const workSpaceId = (this.props as any).params.firmId;
    agent.Notifications.markAllAsRead(workSpaceId)
      .then((res: any) => {
        this.getNotificationsList();
        (this.props as any).addNotification(
          "All notifications marked as read successfully",
          res.message,
          "success"
        );
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).addNotification(
          "Could not mark all notifications as read",
          err?.response?.data?.error || err?.message || err,
          "error"
        );
      });
  };

  deleteNotifications = () => {
    this.setState({ loading: true });
    const workSpaceId = (this.props as any).params.firmId;
    agent.Notifications.deleteNotification(workSpaceId, this.state.checked)
      .then((res: any) => {
        this.getNotificationsList();
        (this.props as any).addNotification(
          "Notifications deleted successfully",
          res.message,
          "success"
        );
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).addNotification(
          "Could not delete notifications",
          err?.response?.data?.error || err?.message || err,
          "error"
        );
      });
  };

  componentDidMount() {
    document.title = "Notifications - TaxPido PMS";
    this.getNotificationsList();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const prevFirmId = prevProps.params.firmId;
    const currFirmId = (this.props as any).params.firmId;
    if (
      prevFirmId !== currFirmId ||
      prevState.chunkSize !== this.state.chunkSize ||
      prevState.currPage !== this.state.currPage ||
      prevState.read !== this.state.read
    ) {
      this.getNotificationsList();
    }
  }

  render() {
    TagManager.dataLayer(tagManagerArgs)
    return (
      <Dashboard>
        <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-semibold text-gray-900">
              Notifications
            </h1>
            <div className="flex items-center gap-x-4">
              {this.state.checked.length > 0 && (
                <Button
                  name="Delete"
                  onClick={() => this.deleteNotifications()}
                />
              )}
              <Button
                name="Mark All as read"
                onClick={this.markAllAsRead}
                disabled={
                  this.state.displayNotifications.filter(
                    (notification: any) => notification?.read === false
                  ).length === 0
                }
              />
            </div>
          </div>
          <div className="flex gap-4 h-5 items-center my-6">
            <input
              id="comments"
              aria-describedby="comments-description"
              name="comments"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-400 cursor-pointer text-indigo-600 focus:ring-indigo-500"
              checked={this.state.read === true}
              onChange={this.showReadNotifications}
            />
            <label
              htmlFor="comments"
              className="font-medium cursor-pointer text-gray-700 text-sm"
            >
              Show Read Notifications
            </label>
          </div>
          <div className="mt-6 flex flex-col">
            <div id="table-scroll" className="overflow-x-auto">
              <div className="inline-block min-w-full align-middle">
                <div className="shadow ring-1 ring-black ring-opacity-5 lg:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        {headers.map(header => (
                          <th
                            key={header}
                            scope="col"
                            className={`py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${
                              header === "Action" ? "text-center" : "text-left"
                            } ${header === "" ? "pl-4" : "px-4"}`}
                          >
                            {header === "" ? (
                              <input
                                id="header-checkbox"
                                name="header-checkbox"
                                type="checkbox"
                                checked={
                                  this.state.checked.length ===
                                  this.state.totalRecords
                                }
                                disabled={this.state.totalRecords === 0}
                                onChange={this.onCheckAll}
                                className="h-4 w-4 rounded border-gray-400 cursor-pointer text-indigo-600 focus:ring-indigo-500"
                              />
                            ) : (
                              header
                            )}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {this.state.totalRecords > 0 ? (
                        !this.state.loading &&
                        this.state.displayNotifications ? (
                          this.state.displayNotifications.map(
                            (notification: any) => (
                              <tr key={notification._id}>
                                <td className="pl-4 py-2 w-4">
                                  <input
                                    id={notification._id}
                                    name={notification._id}
                                    type="checkbox"
                                    checked={this.state.checked.includes(
                                      notification._id
                                    )}
                                    onChange={() =>
                                      this.onCheck(notification._id)
                                    }
                                    className="h-4 w-4 rounded border-gray-400 cursor-pointer text-indigo-600 focus:ring-indigo-500"
                                  />
                                </td>
                                <td
                                  className={`whitespace-nowrap py-2 px-4 text-sm font-medium max-w-lg lg:max-w-xl ${
                                    notification.read
                                      ? "text-gray-500"
                                      : "text-indigo-600"
                                  }`}
                                >
                                  <Popup
                                    content={notification.title}
                                    className="w-fit max-w-full"
                                  >
                                    {notification.title || "-"}
                                  </Popup>
                                </td>
                                <td
                                  className={`whitespace-nowrap py-2 px-4 text-sm font-medium max-w-lg lg:max-w-xl truncate`}
                                >
                                  <Popup
                                    content={notification.description}
                                    className="w-fit max-w-full"
                                  >
                                    {notification.description || "-"}
                                  </Popup>
                                </td>
                                <td
                                  className={`w-2/12 whitespace-nowrap py-2 px-4 text-sm font-medium`}
                                >
                                  {formatDateAndTime(notification.timestamp) ||
                                    "-"}
                                </td>
                                <td
                                  className={`w-1/12 whitespace-nowrap py-2 px-4 text-sm font-medium`}
                                >
                                  {!notification.read && (
                                    <button
                                      className={`text-xs place-self-end px-2 py-1 focus-visible:outline-indigo-600 rounded border border-transparent hover:outline hover:outline-2 hover:outline-indigo-600 w-full ${
                                        notification.read
                                          ? "text-gray-500"
                                          : "text-indigo-600"
                                      }`}
                                      onClick={() => {
                                        this.markAsRead(notification._id);
                                      }}
                                    >
                                      Mark as Read
                                    </button>
                                  )}
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          [...Array(5)].map((e, i) => (
                            <tr key={`tr-${i}`} className="bg-white">
                              {[...Array(headers.length)].map((e, i) => (
                                <td
                                  key={`td-${i}`}
                                  className="whitespace-nowrap py-2 px-4 text-sm text-gray-500"
                                >
                                  <Skeleton />
                                </td>
                              ))}
                            </tr>
                          ))
                        )
                      ) : (
                        <tr>
                          <td
                            colSpan={headers.length}
                            className="px-4 py-4 whitespace-nowrap text-sm text-gray-500"
                          >
                            You are all caught up! No new notifications here.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Pagination
          displayRecords={this.state.displayNotifications}
          totalRecords={this.state.totalRecords}
          currPage={this.state.currPage}
          chunkSize={this.state.chunkSize}
          handlePageClick={this.handlePageClick}
          handleItemPerPage={this.handleItemPerPage}
          className="my-4"
        />
      </Dashboard>
    );
  }
}

export default compose(
  connector,
  withRouter
)(Notification) as React.ComponentType<any>;
