/* This example requires Tailwind CSS v2.0+ */

import { useEffect, useRef, useState } from "react";
import { formatDate } from "../../helpers/formatDate";
import Icon from "../../components/Icon";
import EditTodoForm from "./Edit";
import agent from "../../agent";
// For Drag And Drop
import { useDrag, useDrop } from "react-dnd";
import type { Identifier } from "dnd-core";
import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "../../helpers/withRouter";
import { compose } from "redux";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import Popup from "../../components/Popup";

export interface CardProps {
  id: any;
  text: string;
  order: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number;
  order: number;
  id: string;
  type: string;
}

interface collectedPropTypes {
  isOver: boolean;
  handlerId: Identifier | null;
}

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: (payload: any) => dispatch({ type: UPDATE_COMMON, payload }),
  onNotify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function TodoListItem(props: any | PropsFromRedux) {
  const {
    todo,
    getUpdatedTodos,
    index,
    moveItem,
    todoTemp,
    setTodoData,
    editTodo,
    loading,
    setLoading,
    onNotify,
    toDoListId,
    searchText,
    customSort
  } = props;

  const [visibility, setVisibility] = useState(false);
  const todoItem = useRef<HTMLLIElement>(null);

  type State = {
    showDeleteModal?: boolean;
    selectedRow?: any;
    showBackDrop?: boolean;
  };

  const [state, setState] = useState<State>({
    showDeleteModal: false,
    selectedRow: todo,
    showBackDrop: false
  });

  useEffect(() => {
    const onKeyPress = (e: any) => {
      if (e.keyCode === 27 && visibility) {
        setVisibility(false);
      }
    };

    const onClickOutside = (e: any) => {
      if (
        todoItem?.current &&
        !todoItem?.current?.contains(e.target) &&
        visibility
      ) {
        setVisibility(false);
      }
    };

    document.addEventListener("keydown", onKeyPress);
    !state.showDeleteModal &&
      document.addEventListener("mousedown", onClickOutside);
    return () => {
      document.removeEventListener("keydown", onKeyPress);
      document.removeEventListener("mousedown", onClickOutside);
    };
  }, [visibility, todoItem, state.showDeleteModal]);

  let ItemTypes = {
    TODO: "Todo",
    order: todo?.order
  };

  const ref = useRef<HTMLDivElement>(null);
  const [{ isOver, handlerId }, drop] = useDrop<
    DragItem,
    void,
    collectedPropTypes
  >({
    accept: ItemTypes.TODO,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: !!monitor.isOver()
      };
    },
    drop(item: DragItem) {
      if (!ref.current) {
        return;
      }
      let dragIndex = item.index;
      let hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Time to actually perform the action
      !customSort && moveItem(dragIndex, hoverIndex);

      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.TODO,
    item: () => {
      return { index: index, id: todo?._id, order: todo?.order };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  });

  toDoListId && searchText === "" && !customSort && drag(drop(ref));

  const temp = {
    toDoId: todo?._id,
    toDoListId: todo.toDoListId,
    workSpaceId: todo?.workSpaceId,
    todo: todo?.todo,
    toDo: todo?.toDo,
    description: todo?.description,
    date: todo?.date ?? "",
    order: todo?.order,
    reminderDate: todo?.reminderDate,
    recurring: todo?.recurring,
    recurringPeriodCount: todo?.recurringPeriodCount
  };
  const onStarClick = () => {
    editTodo({ ...temp, star: !todo?.star }, todo?.star ? "unstar" : "star");
    todo.star = !todo?.star;
    getUpdatedTodos();
  };

  const [status, setStatus] = useState(todo?.status);

  const onCheck = () => {
    if (status === "completed") {
      setStatus("pending");
      agent.Todo.markPending({
        toDoId: todo?._id,
        workSpaceId: todo?.workSpaceId
      })
        .then((res: any) => {
          setLoading({ loading: false });
          onNotify("Success", res.message, "success");
        })
        .catch((err: any) => {
          setLoading({ loading: false });
          onNotify(
            "Could not get Todos",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
      getUpdatedTodos();
    } else if (status === "pending") {
      setStatus("completed");
      agent.Todo.markAsComplete({
        toDoId: todo?._id,
        workSpaceId: todo?.workSpaceId
      })
        .then((res: any) => {
          setLoading({ loading: false });
          onNotify("Success", res.message, "success");
        })
        .catch((err: any) => {
          setLoading({ loading: false });
          onNotify(
            "Could not get Todos",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
      getUpdatedTodos();
    }
  };

  return (
    <li
      id={todo?._id}
      ref={todoItem}
      className={`group relative before:absolute before:-left-0.5 before:h-full sm:before:w-0.5 before:rounded-l-lg ${
        visibility ? "" : "hover:before:bg-indigo-400"
      }`}
    >
      <div
        ref={customSort ? undefined : ref}
        data-handler-id={handlerId}
        className={`${index % 2 === 0 ? "sm:bg-white" : "sm:bg-gray-100"} ${
          isDragging ? "bg-gray-200 opacity-0" : "opacity-100"
        } ${
          isOver ? "border-2 border-dotted border-black" : ""
        } flex items-center justify-between bg-white shadow-md sm:shadow-none rounded-lg sm:rounded-none`}
      >
        {toDoListId && searchText === "" && customSort === false && (
          <span className="invisible group-hover:visible cursor-move opacity-50 flex ml-1 absolute">
            <Icon name="vertical-dots" className="w-1.5 h-3" />
            <Icon name="vertical-dots" className="w-1.5 h-3" />
          </span>
        )}
        {visibility ? (
          <EditTodoForm
            visibility={visibility}
            setVisibility={setVisibility}
            todo={todo}
            todoTemp={todoTemp}
            setTodoData={setTodoData}
            editTodo={editTodo}
            getUpdatedTodos={getUpdatedTodos}
            params={props.params}
            loading={loading}
            setLoading={setLoading}
            state={state}
            setState={setState}
          />
        ) : (
          <>
            <div
              className="pl-6 pr-4 py-4 flex-grow grid gap-2 grid-cols-2 relative sm:hidden"
              onClick={() => {
                setVisibility(true);
              }}
            >
              {!toDoListId && (
                <span className="rounded-full bg-green-100 px-2 text-xs font-bold leading-5 text-green-800 absolute -translate-y-1/2 -right-4 shadow uppercase">
                  {todo?.toDoListName}
                </span>
              )}
              <div className="h-5 inline-flex gap-x-2 items-center">
                <CheckBox todo={todo} onCheck={onCheck} status={status} />
                <StarButton todo={todo} onStarClick={onStarClick} />
              </div>
              <p className="text-sm justify-self-end">
                {todo?.date && formatDate(todo?.date, false)}
              </p>
              <p className="text-sm text-gray-800 font-semibold mt-auto col-span-2 truncate">
                <span>Todo :</span>{" "}
                <span className="font-bold">{todo?.toDo}</span>
              </p>
              <p className="text-sm text-gray-800 font-semibold mt-auto col-span-2 max-w-full truncate">
                {todo?.description}
              </p>
            </div>

            <div className="relative w-full hidden sm:flex items-center justify-between pl-4 pr-3">
              <div className="flex-auto cursor-pointer overflow-hidden px-1 flex gap-x-2 items-center">
                <div className="h-5 flex gap-x-2 items-center">
                  <CheckBox todo={todo} onCheck={onCheck} status={status} />
                  <StarButton todo={todo} onStarClick={onStarClick} />
                </div>
                <div
                  className="flex-1 flex flex-col gap-2 overflow-hidden"
                  onClick={() => {
                    setVisibility(true);
                  }}
                >
                  <Popup content={todo?.toDo} className="w-fit max-w-full">
                    <p className="py-4 text-sm text-gray-900 w-full truncate">
                      {todo?.toDo}
                    </p>
                  </Popup>
                  <p className="hidden text-sm text-gray-600">
                    {todo?.description}
                  </p>
                </div>
              </div>
              <div className="flex-shrink-0 flex items-center">
                {todo?.date && (
                  <span className="text-xs px-2">
                    {formatDate(todo?.date, false)}
                  </span>
                )}
                {!toDoListId && (
                  <div>
                    <p className="text-xs font-bold cursor-pointer text-gray-700 mr-3">
                      {todo?.toDoListName}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </li>
  );
}

interface CheckBoxProps {
  todo: any;
  status: string;
  onCheck: (e: any) => void;
}

function CheckBox({ todo, status, onCheck }: CheckBoxProps) {
  return (
    <input
      id={`todo-${todo?._id}`}
      name={`todo-${todo?._id}`}
      type="checkbox"
      checked={status === "completed"}
      onChange={onCheck}
      className="h-4 w-4 rounded border-gray-400 cursor-pointer text-indigo-600 focus:ring-indigo-500"
    />
  );
}

interface StarButtonProps {
  todo: any;
  onStarClick: (e: any) => void;
}

function StarButton({ todo, onStarClick }: StarButtonProps) {
  return (
    <span className="relative isolate inline-grid place-items-center">
      <input
        type="checkbox"
        name="star"
        id="star"
        checked={todo?.star}
        onChange={onStarClick}
        className="row-span-full col-span-full rounded-full border-transparent z-10 focus:ring-2 focus:ring-yellow-500 cursor-pointer peer !bg-transparent !bg-gradient-to-r from-transparent to-transparent"
      />
      <Icon
        name="outline/star"
        className={`row-span-full col-span-full h-4 w-4 ${
          todo?.star
            ? "fill-yellow-500 stroke-yellow-500"
            : "fill-none stroke-gray-500"
        }`}
      />
    </span>
  );
}

export default compose(
  connector,
  withRouter
)(TodoListItem) as React.ComponentType<any>;
