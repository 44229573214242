import React, { Fragment } from "react";
// Import to Display skeleton while loading data
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// Pagination
import Pagination from "../../components/Pagination";
import { connect, ConnectedProps } from "react-redux";
// Link backend
import agent from "../../agent";
// Dashboard import
import Dashboard, { getAllTagsList } from "../../components/Dashboard";
// Icons and styling
import Icon from "../../components/Icon";
// Redux Notify
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import "../style.css";
import TagManager from "react-gtm-module";
import { Menu, Transition } from "@headlessui/react";
import DeleteModal from "../../components/DeleteModal";
import ActiveModal from "../../components/ActiveModal";
import InActiveModal from "../../components/InActiveModal";
import { compose } from "redux";
import { withRouter } from "../../helpers/withRouter";
import {
  clientTypesList,
  filterList,
  returnFileList
} from "../../constants/clients";
import MultiSelect from "../../components/MultiSelect";
import LogsModal from "../../components/LogsModal";
import { downloadFile } from "../../helpers/downloadFile";
import Button from "../../components/Button";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Client List"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: (payload: any) => dispatch({ type: UPDATE_COMMON, payload }),
  onNotify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class Clients extends React.Component<PropsFromRedux> {
  state: {
    loading: boolean;
    posX: any;
    posY: any;
    rotate: boolean;
    showOptionBackDrop: boolean;
    showBackDrop: boolean;
    searchText: string;
    clients: any;
    totalRecords: number;
    displayClientsDetails: any;
    selectedGstId: string;
    selectedFirm: any;
    modalOpen: boolean;
    typingTimeout: number;
    selectedRow: any;
    hoverX: any;
    hoverY: any;
    showDeleteModal: boolean;
    showActiveModal: boolean;
    showInActiveModal: boolean;
    showEditModal: boolean;
    showLogsModal: boolean;
    active: boolean;
    initalFilterSelect: any;
    gstFilterSelect: any;
    incomeTaxFilterSelect: any;
    itrFileReturnSelect: any;
    ownershipFilterSelect: any;
    filterGstStatus: string;
    groupLoading: boolean;
    groupFilterList: any;
    clientGroupFilterSelect: any;
    tagLoading: boolean;
    tagsFilterSelect: any;
    registrationStateFilterSelect: any;
    showFilter: boolean;
    chunkSize: number;
    currPage: number;
    downloading: boolean;
    filters: any;
  };

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      posX: null,
      posY: null,
      rotate: false,
      showOptionBackDrop: false,
      showBackDrop: false,
      searchText: "",
      clients: [],
      totalRecords: 0,
      displayClientsDetails: [],
      selectedGstId: "",
      selectedFirm: undefined,
      modalOpen: false,
      typingTimeout: 0,
      selectedRow: undefined,
      hoverX: null,
      hoverY: null,
      showDeleteModal: false,
      showActiveModal: false,
      showInActiveModal: false,
      showEditModal: false,
      showLogsModal: false,
      active: true,
      initalFilterSelect: {
        _id: "ALL",
        name: "ALL"
      },
      gstFilterSelect: null,
      incomeTaxFilterSelect: null,
      itrFileReturnSelect: null,
      ownershipFilterSelect: null,
      filterGstStatus: "",
      groupFilterList: [],
      groupLoading: false,
      clientGroupFilterSelect: null,
      tagsFilterSelect: null,
      tagLoading: false,
      registrationStateFilterSelect: null,
      showFilter: false,
      chunkSize: 25,
      currPage: 0,
      downloading: false,
      filters: {}
    };
  }

  headers = [
    "FILE NO.",
    "CLIENT NAME",
    "TRADE NAME",
    /*  "GSTIN", */ "ACTIONS"
  ];

  // Chunk Size for number of table data displayed in each page during pagination
  // chunkSize = 12;
  // Selected pagination value
  // currPage = 0;

  onOptionsDropdownClick = () => {
    this.setState({
      rotate: !this.state.rotate,
      showOptionBackDrop: !this.state.showOptionBackDrop
    });
  };

  //Get Firm Data

  getClientsList = (forSearch: boolean, download?: boolean) => {
    const workSpaceId = (this.props as any).params?.firmId;
    const searchText = forSearch ? this.state.searchText : "";
    const active = this.state.active;
    let limit: number = this.state.chunkSize;
    let skip: number = this.state.chunkSize * this.state.currPage;
    let filters = this.state.filters;
    download
      ? this.setState({ downloading: true })
      : this.setState({ loading: true });
    agent.Clients.getClientList(
      workSpaceId,
      skip,
      limit,
      searchText,
      active,
      filters,
      download ? true : false
    )
      .then((response: any) => {
        if (download) {
          this.setState({ downloading: false });
          downloadFile(response, "TaxPido PMS - Client List.xlsx");
        } else {
          this.setState({
            clients: response.clients,
            loading: false,
            totalRecords: response.count,
            displayClientsDetails: response.clients
          });
        }
      })
      .catch((err: any) => {
        if (download) {
          this.setState({ downloading: false });
          const data = new Blob([err.response.data], {
            type: err.response.data.type
          });
          data.text().then(text => {
            const error = JSON.parse(text);
            (this.props as any).onNotify(
              "Could not export Client list",
              error?.message || error,
              "danger"
            );
          });
        } else {
          this.setState({ loading: false });
          (this.props as any).onNotify(
            "Could not load Client Details",
            typeof err?.response?.data?.message === "object"
              ? "Could not load Client Details"
              : err?.response?.data?.message || err?.message || err,
            "danger"
          );
        }
      });
  };

  getGroupList = () => {
    const workSpaceId = (this.props as any).params?.firmId;
    const searchText = "";
    const active = true;
    this.setState({ groupLoading: true });
    agent.ClientGroups.getClientGroupList(
      workSpaceId,
      active,
      searchText,
      0,
      100000
    )
      .then((response: any) => {
        this.setState({
          groupFilterList: response.groups,
          groupLoading: false
        });
      })
      .catch((err: any) => {
        this.setState({ groupLoading: false });
        (this.props as any).onNotify(
          "Could not load Firm Details",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  // onMount Load data
  componentDidMount() {
    document.title = "Clients - TaxPido PMS";
    this.getClientsList(false);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const prevFirmId = prevProps.params.firmId;
    const currFirmId = (this.props as any).params.firmId;
    if (prevFirmId !== currFirmId) {
      // console.log("FIRM ID CHANGED", { prevFirmId, currFirmId });
      this.setState({ searchText: "" });
      this.getClientsList(false);
    }

    if (prevState.active !== this.state.active) {
      this.setState({ searchText: "" });
      this.getClientsList(false);
    }

    const { initalFilterSelect } = this.state;
    const prevInitialFilterSelect = prevState.initalFilterSelect;
    if (
      prevInitialFilterSelect?.name !== initalFilterSelect?.name &&
      initalFilterSelect?.name === "Client Group"
    ) {
      this.getGroupList();
    }

    if (
      prevInitialFilterSelect?.name !== initalFilterSelect?.name &&
      initalFilterSelect?.name === "Tags"
    ) {
      getAllTagsList(
        (this.props as any).currentFirm._id,
        (this.props as any).updateCommon,
        (this.props as any).onNotify
      );
    }
  }

  handlePageClick = (data: any) => {
    this.setState({ currPage: data.selected }, () => {
      this.state.searchText
        ? this.getClientsList(true)
        : this.getClientsList(false);
    });
  };

  handleItemPerPage = (value: any) => {
    this.setState({ chunkSize: value.name, currPage: 0 }, () =>
      this.state.searchText
        ? this.getClientsList(true)
        : this.getClientsList(false)
    );
  };

  fetchRequired = () => {
    this.setState({ requireFetch: true });
  };

  onFirmChange = (item: any) => {
    this.setState({ selectedFirm: item });
  };

  onActionClick = (e: any) => {
    const screenClosness = window.innerHeight - e.clientY;
    const positionY = screenClosness < 155 ? e.clientY - 155 : e.clientY;
    this.setState({
      posX: e.clientX,
      posY: positionY,
      showBackDrop: true
    });
  };

  updateActive = () => {
    this.setState({ active: !this.state.active, currPage: 0 });
  };

  onDropdownClick = () => {
    this.setState({ showBackDrop: false });
  };

  handleSearchTextChange = (ev: any) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      searchText: ev.target.value,
      currPage: 0,
      typingTimeout: setTimeout(() => {
        this.getClientsList(true);
      }, 700)
    });
  };

  openAddClientPage = () => {
    const clientRights = (this.props as any)?.rights?.clientRights;
    const createRight = clientRights.create;
    const currentFirmId = (this.props as any).params?.firmId;
    if (createRight) {
      (this.props as any).navigate(`/${currentFirmId}/clients/add`);
    } else {
      (this.props as any).onNotify(
        "Rights Not Avilable",
        "Ask Admin to change your user rights.",
        "danger"
      );
    }
  };

  closeModal = (fetchAgain: boolean) => {
    (this.props as any).updateCommon({
      currentModal: { modalName: "", fetchAgain }
    });
  };

  onDescriptionHover = (e: any) => {
    const pos = e.target.getClientRects()[0];
    this.setState({ hoverX: pos.x, hoverY: pos.y });
  };

  openActiveModal = (tag: any) => {
    this.setState({ selectedRow: tag, showBackDrop: false });
    this.activeModalSetOpen(true);
  };

  activeModalSetOpen = (open: boolean) => {
    this.setState({
      showActiveModal: open
    });
  };

  openInActiveModal = (client: any) => {
    this.setState({ selectedRow: client, showBackDrop: false });
    this.inActiveModalSetOpen(true);
  };

  inActiveModalSetOpen = (open: boolean) => {
    this.setState({
      showInActiveModal: open
    });
  };

  openDeleteModal = (client: any) => {
    this.setState({ selectedRow: client, showBackDrop: false });
    this.deleteModalSetOpen(true);
  };

  deleteModalSetOpen = (open: boolean) => {
    this.setState({
      showDeleteModal: open
    });
  };

  openLogsModal = (tag: any) => {
    this.setState({ selectedRow: tag, showBackDrop: false });
    this.logsModalSetOpen(true);
  };

  logsModalSetOpen = (open: boolean) => {
    this.setState({
      showLogsModal: open
    });
  };

  editClient = (client: any) => {
    const clientRights = (this.props as any)?.rights?.clientRights;
    const editRight = clientRights.edit;
    const currentFirmId = (this.props as any).params?.firmId;
    if (editRight) {
      (this.props as any).updateCommon({ editClient: client });
      (this.props as any).navigate(
        `/${currentFirmId}/clients/edit/${client._id}`
      );
    } else {
      (this.props as any).onNotify(
        "Rights Not Avilable",
        "Ask Admin to change your user rights.",
        "danger"
      );
    }
  };

  toggleFilter = () => {
    const { showFilter } = this.state;
    if (showFilter) {
      this.resetFilter();
    }
    this.setState({ showFilter: !showFilter });
  };

  onFilterChange = (item: any) => {
    if (item.name === "ALL") {
      this.setState(
        {
          initalFilterSelect: item,
          gstFilterSelect: null,
          incomeTaxFilterSelect: null,
          itrFileReturnSelect: null,
          ownershipFilterSelect: null,
          clientGroupFilterSelect: null,
          tagsFilterSelect: null,
          registrationStateFilterSelect: null,
          filters: {},
          currPage: 0
        },
        () => this.getClientsList(false)
      );
    } else if (item.name === "Only Other Client") {
      this.setState(
        {
          initalFilterSelect: item,
          gstFilterSelect: null,
          incomeTaxFilterSelect: null,
          itrFileReturnSelect: null,
          ownershipFilterSelect: null,
          clientGroupFilterSelect: null,
          tagsFilterSelect: null,
          registrationStateFilterSelect: null,
          currPage: 0,
          filters: { otherClient: true }
        },
        () => this.getClientsList(false)
      );
    } else {
      this.setState({
        initalFilterSelect: item,
        gstFilterSelect: null,
        incomeTaxFilterSelect: null,
        itrFileReturnSelect: null,
        ownershipFilterSelect: null,
        clientGroupFilterSelect: null,
        tagsFilterSelect: null,
        registrationStateFilterSelect: null
      });
    }
  };

  onGstFilterChange = (item: any) => {
    if (item.name === "ALL GST") {
      this.setState(
        {
          gstFilterSelect: item,
          registrationStateFilterSelect: null,
          filterGstStatus: "",
          currPage: 0,
          filters: {
            gst: {
              sub: [{ key: "gstApplicable", value: "true" }]
            }
          }
        },
        () => this.getClientsList(false)
      );
    } else if (item.name === "GST Composition Clients") {
      this.setState(
        {
          gstFilterSelect: item,
          registrationStateFilterSelect: null,
          filterGstStatus: "",
          filters: {
            gst: {
              sub: [{ key: "registrationType", value: "composition" }]
            }
          }
        },
        () => this.getClientsList(false)
      );
    } else if (item.name === "GST Regular Clients") {
      this.setState(
        {
          gstFilterSelect: item,
          registrationStateFilterSelect: null,
          filterGstStatus: "",
          filters: {
            gst: {
              sub: [{ key: "registrationType", value: "regular" }]
            }
          }
        },
        () => this.getClientsList(false)
      );
    } /* else if (item.name === "GST Audit Applicable") {
      this.setState(
        {
          gstFilterSelect: item,
          registrationStateFilterSelect: null,
          filterGstStatus: "",
          filters: {
            gst: {
              sub: [{ key: "gstApplicable", value: "true" }]
            }
          }
        },
        () => this.getClientsList(false)
      );
    } else if (item.name === "GSTR-9 Compulsory") {
      this.setState(
        {
          gstFilterSelect: item,
          registrationStateFilterSelect: null,
          filterGstStatus: "",
          filters: {
            gst: {
              sub: [{ key: "gstr9_compulsory", value: "true" }]
            }
          }
        },
        () => this.getClientsList(false)
      );
    } else if (item.name === "GSTR-9C Applicable") {
      this.setState(
        {
          gstFilterSelect: item,
          registrationStateFilterSelect: null,
          filterGstStatus: "",
          filters: {
            gst: {
              sub: [{ key: "gstr9c_compulsory", value: "true" }]
            }
          }
        },
        () => this.getClientsList(false)
      );
    }  */ else {
      this.setState(
        {
          gstFilterSelect: item,
          registrationStateFilterSelect: null,
          filterGstStatus: ""
        },
        () => this.getClientsList(false)
      );
    }
  };

  onGstStatusChange = (event: any) => {
    this.setState(
      {
        filterGstStatus: event.target.value,
        currPage: 0,
        filters: {
          gst: {
            sub: [{ key: "gstinStatus", value: event.target.value }]
          }
        }
      },
      () => this.getClientsList(false)
    );
  };

  onIncomeTaxFilterChange = (item: any) => {
    if (item.name === "Income Tax Audit Applicable") {
      this.setState(
        {
          incomeTaxFilterSelect: item,
          itrFileReturnSelect: null,
          currPage: 0,
          filters: {
            incomeTax: {
              sub: [{ key: "itApplicable", value: "true" }]
            }
          }
        },
        () => this.getClientsList(false)
      );
    } else {
      this.setState({ incomeTaxFilterSelect: item, itrFileReturnSelect: null });
    }
  };

  onItrRuturnFilterChange = (item: any) => {
    this.setState(
      {
        itrFileReturnSelect: item,
        currPage: 0,
        filters: {
          incomeTax: {
            sub: [
              {
                key: "filedITreturns",
                value: item.name
              }
            ]
          }
        }
      },
      () => this.getClientsList(false)
    );
  };

  onOwnershipFilterChange = (item: any) => {
    this.setState(
      {
        ownershipFilterSelect: item,
        currPage: 0,
        filters: {
          ownershipType: { sub: [{ key: "type", value: item.name }] }
        }
      },
      () => this.getClientsList(false)
    );
  };

  onClientGroupFilterChange = (item: any) => {
    this.setState(
      {
        clientGroupFilterSelect: item,
        currPage: 0,
        filters: {
          clientGroup: [item._id]
        }
      },
      () => this.getClientsList(false)
    );
  };

  onTagsFilterChange = (item: any) => {
    this.setState(
      {
        tagsFilterSelect: item,
        currPage: 0,
        filters: {
          tags: [item._id]
        }
      },
      () => this.getClientsList(false)
    );
  };

  onRegistrationStateFilterChange = (item: any) => {
    this.setState({ registrationStateFilterSelect: item });
  };

  resetFilter = () => {
    this.setState(
      {
        initalFilterSelect: { _id: "ALL", name: "ALL" },
        gstFilterSelect: null,
        incomeTaxFilterSelect: null,
        ownershipFilterSelect: null,
        clientGroupFilterSelect: null,
        tagsFilterSelect: [],
        registrationStateFilterSelect: null,
        itrFileReturnSelect: null,
        filterGstStatus: "",
        filters: {}
      },
      () => this.getClientsList(false)
    );
  };

  importClients = () => {
    const clientRights = (this.props as any)?.rights?.clientRights;
    const importClientRight = clientRights.importClient;
    const currentFirmId = (this.props as any).params?.firmId;
    if (importClientRight) {
      (this.props as any).navigate(`/${currentFirmId}/clients/import`);
    } else {
      (this.props as any).onNotify(
        "Rights Not Avilable",
        "Ask Admin to change your user rights.",
        "danger"
      );
    }
  };

  bulkUpdate = () => {
    const clientRights = (this.props as any)?.rights?.clientRights;
    const bulkUpdateRight = clientRights.edit;
    const currentFirmId = (this.props as any).params?.firmId;
    if (bulkUpdateRight) {
      (this.props as any).navigate(`/${currentFirmId}/clients/bulk-edit`);
    } else {
      (this.props as any).onNotify(
        "Rights Not Avilable",
        "Ask Admin to change your user rights.",
        "danger"
      );
    }
  };

  onLoad = () => {
    this.setState({ searchText: "" });
    this.getClientsList(false);
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard>
        <div className="gsts">
          {this.state.showInActiveModal && (
            <InActiveModal
              type={"client"}
              state={this.state}
              onLoad={this.onLoad}
              inActiveModalSetOpen={this.inActiveModalSetOpen}
            />
          )}

          {this.state.showActiveModal && (
            <ActiveModal
              type={"client"}
              state={this.state}
              onLoad={this.onLoad}
              activeModalSetOpen={this.activeModalSetOpen}
            />
          )}
          {this.state.showDeleteModal && (
            <DeleteModal
              type={"client"}
              state={this.state}
              onLoad={this.onLoad}
              deleteModalSetOpen={this.deleteModalSetOpen}
            />
          )}

          {this.state.showLogsModal && (
            <LogsModal
              type={"client"}
              showLogsModal={this.state.showLogsModal}
              selectedRow={this.state.selectedRow}
              setOpen={this.logsModalSetOpen}
            />
          )}

          <div className="max-w-8xl mx-auto lg:mx-8 ">
            <h1 className="text-2xl font-semibold text-gray-900">Clients</h1>
          </div>

          <div className="flex justify-between gap-3 mt-6 lg:mx-8">
            <div className="hidden lg:inline-flex items-center gap-4">
              <Button
                name="Add Clients"
                icon={
                  (this.props as any)?.rights?.clientRights.create
                    ? "add"
                    : "outline/lock-closed"
                }
                onClick={this.openAddClientPage}
              />
              <Button
                name="Bulk Update"
                icon={
                  (this.props as any)?.rights?.clientRights.edit
                    ? "edit"
                    : "outline/lock-closed"
                }
                onClick={this.bulkUpdate}
                className="relative hidden xl:inline-flex items-center gap-x-2 pl-4 pr-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:bg-indigo-700 focus:outline-none disabled:bg-indigo-500 disabled:cursor-not-allowed"
              />
            </div>

            <div className="flex items-center gap-4">
              {(this.state.totalRecords > 0 ||
                this.state.searchText.length > 0) &&
                !this.state.showFilter && (
                  <>
                    <div className="sm:w-64 lg:w-fit">
                      <input
                        id="search"
                        name="search"
                        type="search"
                        value={this.state.searchText}
                        placeholder="Search by File No, Client Name or Trade Name"
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 text-xs sm:text-sm"
                        onChange={this.handleSearchTextChange}
                      />
                    </div>
                    <button
                      type="button"
                      className="hidden lg:inline-block text-sm text-gray-600 hover:text-indigo-700 underline"
                      onClick={this.toggleFilter}
                    >
                      Advance Search
                    </button>
                  </>
                )}
            </div>

            <Menu as="div" className="inline-block xl:hidden relative">
              <Menu.Button
                onClick={this.onOptionsDropdownClick}
                className="relative inline-flex gap-x-2 items-center justify-between px-2 py-2 sm:py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white  bg-indigo-600 hover:bg-indigo-700 focus:outline-none disabled:bg-indigo-600 w-fit sm:pl-4 sm:pr-2"
              >
                <Icon
                  name="outline/settings"
                  className={`h-5 w-5`}
                  aria-hidden="true"
                />
                <span>Options</span>
                <Icon
                  name="dropdown-arrow"
                  className={`h-5 w-5 ${this.state.rotate ? "rotate-90" : ""}`}
                  aria-hidden="true"
                />
              </Menu.Button>
              {this.state.showOptionBackDrop && (
                <div
                  className="fixed top-0 left-0 z-10 w-full h-screen"
                  onClick={this.onOptionsDropdownClick}
                ></div>
              )}
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div className="absolute z-[100] right-0">
                  <Menu.Items className="overscroll-none mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item as="div" onClick={this.onOptionsDropdownClick}>
                        <Button
                          name="Add Clients"
                          className="flex lg:hidden items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                          icon={
                            (this.props as any)?.rights?.clientRights.create
                              ? "add"
                              : "outline/lock-closed"
                          }
                          onClick={this.openAddClientPage}
                        />
                      </Menu.Item>
                      <Menu.Item as="div">
                        <Button
                          name="Bulk Update"
                          className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900 disabled:cursor-not-allowed"
                          onClick={this.bulkUpdate}
                          icon={
                            (this.props as any)?.rights?.clientRights.edit
                              ? "edit"
                              : "outline/lock-closed"
                          }
                        />
                      </Menu.Item>
                      <Menu.Item as="div" onClick={this.onOptionsDropdownClick}>
                        <Button
                          className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                          onClick={this.toggleFilter}
                          name="Advance Search"
                          icon={MagnifyingGlassIcon}
                        />
                      </Menu.Item>
                      <Menu.Item as="div" onClick={this.onOptionsDropdownClick}>
                        <Button
                          className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                          name="Import"
                          onClick={this.importClients}
                          icon={
                            (this.props as any)?.rights?.clientRights
                              .importClient
                              ? "outline/user-plus"
                              : "outline/lock-closed"
                          }
                        />
                      </Menu.Item>
                      <Menu.Item as="div" onClick={this.onOptionsDropdownClick}>
                        <Button
                          className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                          disabled={
                            this.state.displayClientsDetails.length === 0
                          }
                          name="Export"
                          onClick={() => this.getClientsList(false, true)}
                          icon={
                            (this.props as any)?.rights?.clientRights
                              .importClient
                              ? "outline/download"
                              : "outline/lock-closed"
                          }
                        />
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </div>
              </Transition>
            </Menu>

            <div className="hidden xl:flex justify-between gap-x-2">
              <Button
                name="Import"
                onClick={this.importClients}
                icon={
                  (this.props as any)?.rights?.clientRights.importClient
                    ? "outline/user-plus"
                    : "outline/lock-closed"
                }
              />
              <Button
                name="Export"
                onClick={() => this.getClientsList(false, true)}
                disabled={this.state.displayClientsDetails.length === 0}
                icon={
                  (this.props as any)?.rights?.clientRights.importClient
                    ? this.state.downloading
                      ? "loading"
                      : "outline/download"
                    : "outline/lock-closed"
                }
              />
            </div>
          </div>

          <div className="relative flex items-start max-w-8xl mx-auto mt-6 lg:mx-8">
            <div className="flex h-5 items-center">
              <input
                id="comments"
                aria-describedby="comments-description"
                name="comments"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-400 cursor-pointer text-indigo-600 focus:ring-indigo-500"
                checked={this.state.active === false}
                onChange={this.updateActive}
              />
            </div>
            <div className="ml-3 text-sm">
              <label
                htmlFor="comments"
                className="font-medium cursor-pointer text-gray-700"
              >
                Show Inactive Clients
              </label>
            </div>
          </div>

          {/* Client Filter ComboBoxes */}
          {this.state.showFilter && (
            <div className="max-w-8xl mx-auto mt-4 lg:mx-8 shadow bg-white border px-6 pb-6 mb-4">
              <h4 className="block text-lg font-medium text-gray-800 py-3">
                Search Clients
              </h4>
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="user"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Clients Type
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                    <MultiSelect
                      items={filterList.initialList("All")?.map((item: any) => {
                        return {
                          _id: item,
                          name: item
                        };
                      })}
                      selected={{
                        name: this.state.initalFilterSelect?.name
                      }}
                      type="filterclients"
                      onChange={this.onFilterChange}
                      placeholder="Select Type of Clients"
                    />
                  </div>
                </div>
                {this.state.initalFilterSelect?.name === "GST" && (
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="user"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      GST Type
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                      <MultiSelect
                        items={filterList.gstSlectionList?.map(
                          (item: any, index: number) => {
                            return {
                              _id: `${item}-${index}`,
                              name: item
                            };
                          }
                        )}
                        selected={{
                          name: this.state.gstFilterSelect?.name
                        }}
                        type="filterclients"
                        onChange={this.onGstFilterChange}
                        placeholder="Select GST Type"
                      />
                    </div>
                  </div>
                )}
                {/* {this.state.gstFilterSelect?.name ===
                  "GST Registration State" && (
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="user"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      GST Registration State
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                      <MultiSelect
                        items={filterList.stateList.map(
                          (item: any, index: number) => {
                            return {
                              _id: `${item}-${index}`,
                              name: item
                            };
                          }
                        )}
                        selected={{
                          name: this.state.registrationStateFilterSelect?.name
                        }}
                        type="filterClients"
                        onChange={this.onRegistrationStateFilterChange}
                        placeholder="Select GST Registration State"
                      />
                    </div>
                  </div>
                )} */}

                {this.state.gstFilterSelect?.name === "GSTIN Status" && (
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="user"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      GST Status
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                      <legend className="sr-only">Create Task</legend>
                      <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                        <div className="flex items-center">
                          <input
                            id="active"
                            name="active"
                            type="radio"
                            value="active"
                            checked={this.state.filterGstStatus === "active"}
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                            onChange={this.onGstStatusChange}
                          />
                          <label
                            htmlFor="active"
                            className="ml-3 block text-sm font-medium text-gray-700 cursor-pointer"
                          >
                            Active
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            id="cancelled"
                            name="cancelled"
                            type="radio"
                            value="cancelled"
                            checked={this.state.filterGstStatus === "cancelled"}
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                            onChange={this.onGstStatusChange}
                          />
                          <label
                            htmlFor="cancelled"
                            className="ml-3 block text-sm font-medium text-gray-700 cursor-pointer"
                          >
                            Cancelled
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            id="suspensed"
                            name="suspensed"
                            type="radio"
                            value="suspensed"
                            checked={this.state.filterGstStatus === "suspensed"}
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                            onChange={this.onGstStatusChange}
                          />
                          <label
                            htmlFor="suspensed"
                            className="ml-3 block text-sm font-medium text-gray-700 cursor-pointer"
                          >
                            Suspensed
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.initalFilterSelect?.name === "Income Tax" && (
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="user"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Returns You File in IT
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                      <MultiSelect
                        items={returnFileList.map(
                          (item: any, index: number) => {
                            return {
                              _id: `${item}-${index}`,
                              name: item
                            };
                          }
                        )}
                        selected={{
                          name: this.state.itrFileReturnSelect?.name
                        }}
                        type="itrfiles"
                        onChange={this.onItrRuturnFilterChange}
                        placeholder="Select Return Type"
                      />
                    </div>
                  </div>
                )}

                {this.state.initalFilterSelect?.name === "Ownership Type" && (
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="user"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Ownership Type
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                      <MultiSelect
                        items={clientTypesList.map(
                          (item: any, index: number) => {
                            return {
                              _id: `${item}-${index}`,
                              name: item
                            };
                          }
                        )}
                        selected={{
                          name: this.state.ownershipFilterSelect?.name
                        }}
                        type="filterClients"
                        onChange={this.onOwnershipFilterChange}
                        placeholder="Select Ownership Type"
                      />
                    </div>
                  </div>
                )}

                {this.state.initalFilterSelect?.name === "Client Group" && (
                  <>
                    {this.state.groupLoading ? (
                      <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                        <Icon
                          name="loading"
                          className="text-indigo-600 mx-auto"
                        />
                      </div>
                    ) : (
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="user"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Client Group
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                          <MultiSelect
                            items={this.state.groupFilterList?.map(
                              (item: any) => {
                                return {
                                  ...item,
                                  _id: item._id,
                                  name: item.name
                                };
                              }
                            )}
                            selected={{
                              name: this.state.clientGroupFilterSelect?.name
                            }}
                            type="filterClients"
                            onChange={this.onClientGroupFilterChange}
                            placeholder="Select Client Group"
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}

                {this.state.initalFilterSelect?.name === "Tags" && (
                  <>
                    {this.state.tagLoading ? (
                      <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                        <Icon
                          name="loading"
                          className="text-indigo-600 mx-auto"
                        />
                      </div>
                    ) : (
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="user"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Tags
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                          <MultiSelect
                            items={(this.props as any)?.tags?.map(
                              (item: any) => {
                                return {
                                  ...item,
                                  _id: item._id,
                                  name: item.name
                                };
                              }
                            )}
                            selected={{
                              name: this.state.tagsFilterSelect?.name
                            }}
                            type="filterClients"
                            onChange={this.onTagsFilterChange}
                            placeholder="Select Tags"
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                  <label
                    htmlFor="user"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  ></label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                    <button
                      type="button"
                      className="w-full whitespace-nowrap inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:w-32 sm:text-sm"
                      onClick={this.toggleFilter}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Client List Cards */}
          <div className="sm:hidden px-2 sm:px-6 mt-6">
            {!this.state.loading ? (
              this.state.totalRecords > 0 ||
              this.state.searchText.length > 0 ||
              this.state.active === false ||
              this.state.showFilter ? (
                <ul className="divide-y divide-gray-200 space-y-4">
                  {(this.state.active === false &&
                    this.state.totalRecords === 0) ||
                  (this.state.searchText && this.state.totalRecords === 0) ? (
                    <div className="bg-white shadow-md rounded-lg">
                      <div className="px-4 py-4 sm:px-6">
                        <p className="text-sm text-gray-800 text-center font-semibold">
                          {this.state.searchText &&
                            "No record found matching your search criteria"}
                          {this.state.active === false &&
                            this.state.searchText === "" &&
                            "No record of inactive clients"}
                        </p>
                      </div>
                    </div>
                  ) : (
                    this.state.displayClientsDetails.map((client: any) => (
                      <li
                        key={client._id}
                        className="bg-white shadow-md rounded-lg"
                      >
                        <div className="relative">
                          <span className="rounded-full bg-green-100 px-2 text-xs font-bold leading-5 text-green-800 absolute -translate-y-1/2 -right-4 shadow uppercase">
                            {client.active ? "Active" : "Inactive"}
                          </span>
                          <div className="px-4 py-4 space-y-2">
                            <div className="flex items-center justify-between">
                              <p className="text-sm text-gray-800 font-semibold mt-auto">
                                <span>File no. : </span>
                                <span className="font-bold">
                                  {client.fileNo ? client.fileNo : "-"}
                                </span>
                              </p>
                              <div className="justify-self-end mt-1">
                                <ClientListOptions
                                  {...this.props}
                                  state={this.state}
                                  smallScreen={true}
                                  client={client}
                                  onActionClick={this.onActionClick}
                                  onDropdownClick={this.onDropdownClick}
                                  openLogsModal={this.openLogsModal}
                                  editClient={this.editClient}
                                  openInActiveModal={this.openInActiveModal}
                                  openActiveModal={this.openActiveModal}
                                  openDeleteModal={this.openDeleteModal}
                                />
                              </div>
                            </div>
                            <p className="text-sm text-gray-800 font-semibold">
                              <span>Name : </span>
                              <span className="font-bold">{client.name}</span>
                            </p>
                            <p className="text-sm text-gray-800 font-semibold">
                              <span>Trade name : </span>
                              <span className="font-bold">
                                {client.tradeName ? client.tradeName : "-"}
                              </span>
                            </p>
                            {/* <p className="text-sm text-gray-800 font-semibold">
                              <span>GSTIN : </span>
                              <span className="font-bold">
                                {client.gstin ? client.gstin : "-"}
                              </span>
                            </p> */}
                          </div>
                        </div>
                      </li>
                    ))
                  )}
                </ul>
              ) : (
                <div className="text-center w-full max-w-2xl mx-auto my-10 border-2 border-gray-300 border-dashed p-16 rounded-lg">
                  <Icon
                    name="outline/document-add"
                    className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                    strokeWidth="1"
                  />
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    No Clients Entry
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Get started by adding a new Client.
                  </p>
                  <div className="mt-6">
                    <Button
                      name="Add Clients"
                      icon={
                        (this.props as any)?.rights?.clientRights.create
                          ? "add"
                          : "outline/lock-closed"
                      }
                      onClick={this.openAddClientPage}
                    />
                  </div>
                </div>
              )
            ) : (
              <div className="space-y-4">
                {[...Array(5)].map((e, i) => (
                  <div
                    key={`wrapper-${i}`}
                    className="bg-white shadow-md rounded-lg"
                  >
                    <div
                      key={`items-${i}`}
                      className={`whitespace-wrap text-sm font-medium text-gray-900 px-4 py-4 sm:px-6 grid gap-4 grid-cols-2 items-center relative ${
                        i === 0 ? "text-left" : "text-center"
                      }`}
                    >
                      <span className="px-2 absolute top-0 -translate-y-1/2 -right-4 w-24">
                        <Skeleton
                          height="1rem"
                          className="w-full bg-green-100"
                        />
                      </span>
                      {[...Array(4)].map((e, i) => (
                        <Skeleton
                          key={`clientlist-skeleton-${i}`}
                          height="1rem"
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          {/* Client List Table */}
          <div className="hidden sm:block">
            {!this.state.loading && this.state.displayClientsDetails ? (
              this.state.totalRecords > 0 ||
              this.state.searchText.length > 0 ||
              this.state.active === false ||
              this.state.showFilter ? (
                <div className="max-w-8xl mx-auto">
                  <div className="mt-6 lg:mx-8 flex flex-col">
                    <div id="table-scroll" className="overflow-auto">
                      <div className="inline-block min-w-full py-2 px-1 align-middle">
                        <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                          <table className="min-w-full border-collapse border shadow-sm">
                            <thead className="bg-gray-50">
                              <tr>
                                {this.headers.map(header => (
                                  <th
                                    key={header}
                                    className="border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider md:px-6"
                                  >
                                    {header}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            {(this.state.active === false &&
                              this.state.totalRecords === 0) ||
                            (this.state.searchText &&
                              this.state.totalRecords === 0) ||
                            (this.state.showFilter &&
                              this.state.totalRecords === 0) ? (
                              <tbody>
                                <tr>
                                  <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {(this.state.searchText ||
                                      this.state.showFilter) &&
                                      "No record found matching your search criteria"}

                                    {this.state.active === false &&
                                      this.state.searchText === "" &&
                                      "No record of inactive clients"}
                                  </td>
                                </tr>
                              </tbody>
                            ) : (
                              <tbody className="bg-white">
                                {this.state.displayClientsDetails?.map(
                                  (client: any, index: any) => (
                                    <tr
                                      key={client._id}
                                      className="even:bg-gray-100"
                                    >
                                      <td className="w-2/12 whitespace-nowrap py-4 pl-4 pr-3 even:font-bold text-sm text-gray-900 sm:pl-6">
                                        {client.fileNo ? client.fileNo : "-"}
                                      </td>
                                      <td className="w-4/12 px-6 py-3 even:font-bold text-sm text-gray-900 relative">
                                        {client.name}
                                      </td>
                                      <td className="w-3/12 whitespace-nowrap py-4 pl-4 pr-3 even:font-bold text-sm text-gray-900 sm:pl-6">
                                        {client.tradeName
                                          ? client.tradeName
                                          : "-"}
                                      </td>
                                      {/* <td className="w-2/12 whitespace-nowrap py-4 pl-4 pr-3 even:font-bold text-sm text-gray-900 sm:pl-6">
                                        {client.gstin ? client.gstin : "-"}
                                      </td> */}
                                      <td className="w-4/10 px-6 py-3 mx-4 text-center whitespace-nowrap text-sm text-gray-500">
                                        <ClientListOptions
                                          {...this.props}
                                          state={this.state}
                                          smallScreen={false}
                                          client={client}
                                          onActionClick={this.onActionClick}
                                          onDropdownClick={this.onDropdownClick}
                                          openLogsModal={this.openLogsModal}
                                          editClient={this.editClient}
                                          openInActiveModal={
                                            this.openInActiveModal
                                          }
                                          openActiveModal={this.openActiveModal}
                                          openDeleteModal={this.openDeleteModal}
                                        />
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-center w-full max-w-2xl mx-auto my-10 border-2 border-gray-300 border-dashed p-16 rounded-lg">
                  <Icon
                    name="outline/document-add"
                    className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                    strokeWidth="1"
                  />
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    No Clients Entry
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Get started by adding a new Client.
                  </p>
                  <div className="mt-6">
                    <Button
                      name="Add Clients"
                      icon={
                        (this.props as any)?.rights?.clientRights.create
                          ? "add"
                          : "outline/lock-closed"
                      }
                      onClick={this.openAddClientPage}
                    />
                  </div>
                </div>
              )
            ) : (
              <div className={"max-w-8xl mx-auto lg:mx-8"}>
                <div className="py-6">
                  <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                          <table className="overflow-scroll table-auto w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                {this.headers.map(header => (
                                  <th
                                    key={header}
                                    className="w-2/12 px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                                  >
                                    {header}
                                  </th>
                                ))}
                              </tr>
                            </thead>

                            <tbody>
                              {[...Array(5)].map((e, i) => (
                                <tr key={`row-${i}`} className="bg-white">
                                  {[...Array(this.headers.length)].map(
                                    (e, i) => (
                                      <td
                                        key={`col-${i}`}
                                        className="w-3/10 px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                      >
                                        <Skeleton />
                                      </td>
                                    )
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Pagination
            displayRecords={this.state.displayClientsDetails}
            totalRecords={this.state.totalRecords}
            currPage={this.state.currPage}
            chunkSize={this.state.chunkSize}
            handlePageClick={this.handlePageClick}
            handleItemPerPage={this.handleItemPerPage}
            className="my-4"
          />
        </div>
      </Dashboard>
    );
  }
}

interface ClientListOptionsProps {
  state: any;
  smallScreen: boolean;
  onActionClick: any;
  client: any;
  onDropdownClick: any;
  editClient: any;
  openInActiveModal: any;
  openActiveModal: any;
  openDeleteModal: any;
  openLogsModal: any;
}

class ClientListOptions extends React.Component<ClientListOptionsProps> {
  render() {
    return (
      <Menu as="div" className="inline-block">
        <Menu.Button onClick={this.props.onActionClick}>
          <span className="sr-only">Open options</span>
          {this.props.smallScreen ? (
            <Icon name="vertical-dots" className="h-4 w-4" aria-hidden="true" />
          ) : (
            <Icon
              name="horizontal-dots"
              className="h-5 w-5"
              aria-hidden="true"
            />
          )}
        </Menu.Button>
        {this.props.state.showBackDrop && (
          <div
            className="fixed top-0 left-0 z-10 w-full h-screen"
            onClick={this.props.onDropdownClick}
          ></div>
        )}

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div
            style={{
              position: "fixed",
              zIndex: 100,
              top: `${this.props.state.posY}px`,
              left: `${this.props.state.posX - 230}px`,
              margin: "0.5rem"
            }}
          >
            <Menu.Items className="overscroll-none mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  <button
                    className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                    onClick={() => this.props.editClient(this.props.client)}
                  >
                    <Icon name="edit" className="h-5 w-5 mr-2" />
                    <span>Edit</span>
                  </button>
                </Menu.Item>
                <Menu.Item>
                  {this.props.client.active ? (
                    <button
                      className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                      onClick={() =>
                        this.props.openInActiveModal(this.props.client)
                      }
                    >
                      <Icon name="warning" className="h-5 w-5 mr-2" />
                      <span>Mark Inactive</span>
                    </button>
                  ) : (
                    <button
                      className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                      onClick={() =>
                        this.props.openActiveModal(this.props.client)
                      }
                    >
                      <Icon name="warning" className="h-5 w-5 mr-2" />
                      <span>Mark Active</span>
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  <button
                    className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                    onClick={() =>
                      this.props.openDeleteModal(this.props.client)
                    }
                  >
                    <Icon name="delete" className="h-5 w-5 mr-2" />
                    <span>Delete</span>
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                    onClick={() => this.props.openLogsModal(this.props.client)}
                  >
                    <Icon
                      name="outline/document-report"
                      className="h-5 w-5 mr-2"
                    />
                    <span>Activity</span>
                  </button>
                </Menu.Item>
              </div>
            </Menu.Items>
          </div>
        </Transition>
      </Menu>
    );
  }
}

export default compose(connector, withRouter)(Clients) as React.ComponentType;
