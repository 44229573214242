import React from "react";
import Skeleton from "react-loading-skeleton";
import agent from "../../agent";
import Dashboard from "../../components/Dashboard";
import { connect, ConnectedProps } from "react-redux";
import { ADD_NOTIFICATION } from "../../store/types";
import { withRouter } from "../../helpers/withRouter";
import { compose } from "redux";
import MultiSelect from "../../components/MultiSelect";
import TaskItem from "./TaskItem";
import { getArrayOfUniqueValues } from "../../helpers/getArrayOfUniqueValues";
import TagManager from "react-gtm-module";
import { RootState } from "../../store";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Recuring Task"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const taskTypes = [
  { _id: "GST", name: "GST" },
  { _id: "IT", name: "Income Tax" },
  { _id: "OTHER", name: "Other" }
];

type TaskType = (typeof taskTypes)[0];

export type BeforeAfterType = "before" | "after";
export type PeriodType =
  | "yearly"
  | "half-yearly"
  | "quarterly"
  | "monthly"
  | "one time";

export interface RecurringTaskSettings {
  tasktype: string;
  days: number;
  nextDate: string;
  messageToShow: string;
}

export type RecurringTask =
  | (
      | {
          type: "GST" | "IT";
          recurringType: BeforeAfterType;
          recurringDays: number;
          startPeriod: string;
        }
      | {
          type: "OTHER";
          beforeAfter: BeforeAfterType;
          dueDays: number;
          clientsCount: number;
        }
    ) & {
      _id: string;
      name: string;
      periodType: PeriodType;
    };

interface State {
  selectedTaskType: TaskType;
  loading: boolean;
  recuringTasks: RecurringTask[];
  totalRecords: number;
  recurringTaskSettings: RecurringTaskSettings[];
}

class ReccurringTask extends React.Component<PropsFromRedux, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      selectedTaskType:
        taskTypes.find(
          item => item._id === (this.props as any).params.taskType.toUpperCase()
        ) ?? taskTypes[0],
      loading: false,
      recuringTasks: [],
      totalRecords: 0,
      recurringTaskSettings: []
    };
  }

  handleTaskTypeChange = (selected: TaskType) => {
    this.setState({ selectedTaskType: selected });
    (this.props as any).navigate(
      `/${
        (this.props as any).params.firmId
      }/recurring-task/list/${selected._id.toLowerCase()}`
    );
  };

  getRecurringTasksSettings = () => {
    this.setState({ loading: true });
    agent.RecurringTask.getRecurringTaskSettings(
      (this.props as any).params.firmId,
      this.state.selectedTaskType._id
    )
      .then(res => {
        this.setState({
          loading: false,
          recurringTaskSettings: res.recurringTask
        });
      })
      .catch(err => {
        this.setState({ loading: false });
        (this.props as any).addNotification(
          "Error",
          "Error getting recurring tasks settings",
          "error"
        );
      });
  };

  getRecurringTasks = () => {
    this.setState({ loading: true });
    agent.RecurringTask.getTaskTypes(
      (this.props as any).params.firmId,
      this.state.selectedTaskType._id,
      ""
    )
      .then((res: { tasks: any[] }) => {
        const filteredTasks = res.tasks
          .map((task: any): RecurringTask => {
            return {
              ...task,
              periodType:
                task.periodType?.toLowerCase() ?? task.period?.toLowerCase()
            };
          })
          .filter(
            item =>
              item.type === this.state.selectedTaskType._id &&
              item.periodType !== "one time"
          );
        this.setState({
          recuringTasks: getArrayOfUniqueValues(filteredTasks, "name"),
          totalRecords: getArrayOfUniqueValues(filteredTasks, "name").length,
          loading: false
        });
      })
      .catch(err => {
        this.setState({ loading: false });
        (this.props as any).addNotification(
          "Error",
          "Error getting Recurring tasks.",
          "error"
        );
      });
  };

  componentDidMount() {
    if ((this.props as any)?.rights?.taskRights.create) {
      this.getRecurringTasksSettings();
    } else if ((this.props as any)?.rights?.taskRights.create === false) {
      (this.props as any).addNotification(
        "Rights Not Avilable",
        "Ask Admin to change your user rights.",
        "danger"
      );
    }
    document.title = "Recurring Task - TaxPido PMS";
  }

  componentDidUpdate(prevProps: any, prevState: State) {
    const prevFirmId = prevProps.params?.firmId;
    const currFirmId = (this.props as any).params?.firmId;
    if (prevFirmId !== currFirmId) {
      this.getRecurringTasksSettings();
    }
    if (
      (prevProps as any).rights?.reciptsAndPayments === undefined &&
      (this.props as any)?.rights?.taskRights.create
    ) {
      this.getRecurringTasksSettings();
    }

    if (prevState.selectedTaskType !== this.state.selectedTaskType) {
      this.getRecurringTasksSettings();
    }
    if (prevState.recurringTaskSettings !== this.state.recurringTaskSettings) {
      this.getRecurringTasks();
    }
  }

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard>
        <div
          className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 px-4 py-2 mb-4 max-w-full mx-auto lg:mx-8 flex justify-between"
          role="alert"
        >
          <p className="font-bold">
            Currently, recurring tasks have only an 'After Period' option, we
            will enable 'Before Period' option soon.{" "}
          </p>
        </div>

        <div className="max-w-full mx-auto lg:mx-8">
          <h1 className="text-2xl font-semibold text-gray-900">
            Recurring Tasks
          </h1>
        </div>
        <div className="flex mt-6 lg:mx-8">
          <MultiSelect
            placeholder="Select Task Type"
            type="task-type"
            items={taskTypes}
            selected={{
              name: this.state.selectedTaskType.name
            }}
            onChange={this.handleTaskTypeChange}
            disabled={!(this.props as any)?.rights?.taskRights.create}
          />
        </div>

        <div className={"max-w-full mx-auto lg:mx-8 mb-6 min-h-full"}>
          {/* Recurring Tasks Cards*/}
          <div className="sm:hidden px-2 sm:px-6 mt-6">
            {!this.state.loading &&
            this.state.recurringTaskSettings.length > 0 ? (
              this.state.recuringTasks.length > 0 ? (
                <ul className="divide-y divide-gray-200 space-y-4">
                  {this.state.recuringTasks.map((task, index) => (
                    <TaskItem
                      key={task._id}
                      smallScreen={true}
                      task={task}
                      index={index}
                      recurringTaskSetting={this.state.recurringTaskSettings.find(
                        setting => setting.tasktype === task.name
                      )}
                    />
                  ))}
                </ul>
              ) : (
                <div className="bg-white shadow-md rounded-lg">
                  <div className="px-4 py-4 sm:px-6">
                    <p className="text-sm text-gray-800 text-center font-semibold">
                      No Task Type Selected
                    </p>
                    <p className="text-sm text-gray-800 text-center font-semibold">
                      Get started by selecting a Task Type.
                    </p>
                  </div>
                </div>
              )
            ) : (
              <div className="space-y-4">
                {[...Array(5)].map((e, i) => (
                  <div key={i} className="bg-white shadow-md rounded-lg">
                    <div
                      key={i}
                      className={`whitespace-wrap text-sm font-medium text-gray-900 px-4 py-4 sm:px-6 grid gap-4 grid-cols-2 items-center relative ${
                        i === 0 ? "text-left" : "text-center"
                      }`}
                    >
                      <span className="px-2 absolute top-0 -translate-y-1/2 -right-4 w-24">
                        <Skeleton
                          height="1rem"
                          className="w-full bg-green-100"
                        />
                      </span>
                      {[...Array(4)].map((e, i) => (
                        <Skeleton
                          key={`recurring-task-skeleton-${i}`}
                          height="1rem"
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Recurring Tasks List Table */}
        <div className="hidden sm:block">
          {this.state.totalRecords > 0 ? (
            <div className={"max-w-full mx-auto lg:mx-8 mb-6 min-h-full"}>
              <div className="mt-6">
                <div id="table-scroll" className="overflow-auto">
                  <div className="inline-block min-w-full py-2 px-1 align-middle">
                    <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                      <table className="min-w-full border-collapse border shadow-sm">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="sticky top-0 z-[8] border-b border-gray-300 bg-gray-50 px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider text-left sm:pl-6"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="sticky top-0 z-[8] border-b border-gray-300 bg-gray-50 px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider text-center"
                            >
                              Recurring
                            </th>
                            <th
                              scope="col"
                              className="sticky top-0 z-[8] border-b border-gray-300 bg-gray-50 px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider min-w-[10rem]"
                            >
                              After/Before the end of the period
                            </th>
                            <th
                              scope="col"
                              className="sticky top-0 z-[8] border-b border-gray-300 bg-gray-50 px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider"
                            >
                              Days
                            </th>
                            {this.state.selectedTaskType._id === "OTHER" && (
                              <th
                                scope="col"
                                className="sticky top-0 z-[8] border-b border-gray-300 bg-gray-50 px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider text-center"
                              >
                                No. of Clients
                              </th>
                            )}
                            <th
                              scope="col"
                              className="sticky top-0 z-[8] border-b border-gray-300 bg-gray-50 px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider w-1/2 text-center"
                            >
                              Message
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white">
                          {!this.state.loading &&
                          this.state.recuringTasks.length > 0
                            ? this.state.recuringTasks?.map((task, index) => (
                                <TaskItem
                                  key={task._id}
                                  smallScreen={false}
                                  task={task}
                                  index={index}
                                  recurringTaskSetting={this.state.recurringTaskSettings.find(
                                    setting => setting.tasktype === task.name
                                  )}
                                />
                              ))
                            : [...Array(5)].map((e, i) => (
                                <tr key={i} className="bg-white">
                                  {[
                                    ...Array(
                                      this.state.selectedTaskType._id ===
                                        "OTHER"
                                        ? 6
                                        : 5
                                    )
                                  ].map((e, i) => (
                                    <td
                                      key={i}
                                      className="w-3/10 px-3 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                    >
                                      <Skeleton />
                                    </td>
                                  ))}
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center my-10 border-2 border-gray-300 border-dashed p-16 md:mx-40 sm:mx-0 rounded-lg">
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                No Task Type Selected
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                Get started by selecting a Task Type.
              </p>
            </div>
          )}
        </div>
      </Dashboard>
    );
  }
}

export default compose(
  connector,
  withRouter
)(ReccurringTask) as React.ComponentType;
