import { Component } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import MultiSelect from "../../components/MultiSelect";
import ViewAllModal from "./ViewAllModal";
import { CurrentTab } from "./Index";

interface Props {
  state: {
    currentTab: CurrentTab;
    loading: boolean;
    userWiseReport: any[];
    workSpaceId?: string;
    selectedUser: { _id: string; name: string };
    userType: { _id: string; name: string };
  };
  handleUserTypeChange: (item: any) => void;
  searchParams: (
    key: string,
    filter?: string | undefined
  ) => "" | URLSearchParams;
  users: any[] | undefined;
}

interface State {
  showViewAllModal: boolean;
}

class UserwiseTaskSummary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showViewAllModal: false
    };
  }

  showViewAllModal = () => {
    this.setState({ showViewAllModal: true });
  };

  getUserIdFromUserName = (userName: string) => {
    const user = this.props.users?.find(user => user.name === userName);
    return user?._id;
  };

  render() {
    return (
      <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow hover:shadow-xl">
        <ViewAllModal
          selectedUser={this.props.state.selectedUser}
          workSpaceId={this.props.state.workSpaceId}
          currentTab={this.props.state.currentTab}
          searchParams={this.props.searchParams}
          showViewAllModal={this.state.showViewAllModal}
          reportType="userWiseReport"
          reportData={this.props.state.userWiseReport}
          onClose={() => this.setState({ showViewAllModal: false })}
          userType={this.props.state.userType}
          getUserIdFromUserName={this.getUserIdFromUserName}
        />
        <header className="py-3 px-4 md:px-6 flex gap-x-4 gap-y-3 justify-between items-center">
          <h3 className="text-base leading-6 font-semibold text-gray-900 capitalize">
            {this.props.state.currentTab} Task Summary - Userwise{" "}
          </h3>

          <div className="text-sm font-normal capitalize flex items-right gap-2 px-0.5 py-0.5">
            <MultiSelect
              placeholder="Select User"
              type="Dashboard Type"
              items={[
                { _id: "current", name: "Working" },
                { _id: "other", name: "Other" }
              ]}
              selected={this.props.state.userType}
              onChange={this.props.handleUserTypeChange}
            />
          </div>
        </header>
        <div className="p-4">
          <div className="bg-white shadow rounded-md sm:overflow-hidden">
            <div className="flex flex-col">
              <div id="tab-scroll" className="overflow-auto">
                <div className="inline-block min-w-full align-middle vertical-scroll overflow-auto">
                  <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className={`px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider text-left`}
                          >
                            User
                          </th>
                          <th
                            scope="col"
                            className={`px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider text-center`}
                          >
                            Total Tasks
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {!this.props.state.loading ? (
                          this.props.state.userWiseReport.length > 0 ? (
                            <>
                              {this.props.state.userWiseReport
                                .slice(0, 5)
                                .map((record: any, index: number) => (
                                  <tr
                                    key={`userWiseReport-${record.userName}-${index}`}
                                    className={
                                      index % 2 === 0
                                        ? "bg-white"
                                        : "bg-gray-50"
                                    }
                                  >
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize">
                                      {record.userName}
                                    </td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize text-center">
                                      <Link
                                        className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                                        to={`/${
                                          this.props.state.workSpaceId
                                        }/tasks/list?${
                                          this.props.state.currentTab ===
                                          "Common"
                                            ? ""
                                            : this.props.searchParams(
                                                "type",
                                                this.props.state.currentTab
                                              )
                                        }${
                                          this.props.state.userType._id ===
                                          "current"
                                            ? record.userName === "Unassigned"
                                              ? `&${this.props.searchParams(
                                                  "user",
                                                  record.userName
                                                )}`
                                              : `&${this.props.searchParams(
                                                  "userName",
                                                  record.userName
                                                )}`
                                            : `&${this.props.searchParams(
                                                "otherUser",
                                                this.getUserIdFromUserName(
                                                  record.userName
                                                )
                                              )}`
                                        }`}
                                      >
                                        {record.count}
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                              <tr className="bg-white">
                                <td className="px-3 py-2 whitespace-nowrap text-gray-900 font-medium text-sm capitalize">
                                  Total
                                </td>
                                <td className="px-3 py-2 whitespace-nowrap text-gray-900 font-semibold text-sm capitalize text-center">
                                  {this.props.state.userWiseReport.reduce(
                                    (total: number, record: any) =>
                                      total + record.count,
                                    0
                                  )}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <tr className="bg-white">
                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize">
                                No Record Found
                              </td>
                            </tr>
                          )
                        ) : (
                          [...Array(5)].map((e, i) => (
                            <tr
                              key={`tr-userWiseReport-${i}`}
                              className="bg-white"
                            >
                              {[...Array(2)].map((e, i) => (
                                <td
                                  key={`td-userWiseReport-${i}`}
                                  className="px-2 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                >
                                  <Skeleton />
                                </td>
                              ))}
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.state.userWiseReport.length > 5 && (
          <div className="bg-white px-3 py-2 whitespace-nowrap text-gray-900 font-semibold capitalize text-center">
            <button
              className="text-sm inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 font-medium text-indigo-700 hover:bg-indigo-200 focus:bg-indigo-200 focus:outline-none"
              onClick={this.showViewAllModal}
            >
              View All
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default UserwiseTaskSummary;
