/* This example requires Tailwind CSS v2.0+ */

import { formatDate } from "../../helpers/formatDate";
import Icon from "../../components/Icon";
import { ChangeEvent, ComponentType, useEffect, useRef, useState } from "react";

import { compose } from "redux";
import { withRouter, WithRouterProps } from "../../helpers/withRouter";
import { connect, ConnectedProps } from "react-redux";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import { RootState, AppDispatch } from "../../store";
import { CommonAction } from "../../store/reducers/common";
import { NotifyType } from "../../store/reducers/notification";
import { Todo, TodoTemp } from "./Index";

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateCommon: (payload: CommonAction["payload"]) =>
    dispatch({ type: UPDATE_COMMON, payload }),
  onNotify: (title: string, message: string, type: NotifyType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface FormProps extends Partial<PropsFromRedux & WithRouterProps> {
  todoTemp: TodoTemp;
  setTodoData: (data: TodoTemp) => void;
  addTodo: (data: TodoTemp) => void;
  todos: Todo[] | null;
  todoListId?: string;
}

function TodoForm(props: FormProps) {
  const { todoTemp, setTodoData, addTodo, todos, todoListId } = props;

  const [logging, setLogging] = useState(false);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTodoData({ ...todoTemp, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    if (todoTemp?.toDo === "") {
      props.onNotify?.("Error", "Todo is required", "danger");
      return;
    } else if (todoTemp?.description && todoTemp?.description.length > 2000) {
      props.onNotify?.(
        "Error",
        "Description must be less than 2000 characters",
        "danger"
      );
      return;
    }
    setLogging(true);
    addTodo(todoTemp);
    setLogging(false);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
  };

  const newTodo = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    todos?.length === 0 && todoListId && newTodo.current?.focus();
  }, [todoListId, todos?.length]);

  return (
    <form
      className={`w-full mt-5 p-2 bg-white flex-col sm:items-center sm:ring-2 ring-black ring-opacity-10 rounded-lg sm:rounded-none shadow-md sm:shadow-none`}
      onSubmit={onSubmit}
    >
      <div className="w-full group relative p-2">
        <div className="flex gap-4 flex-col">
          <label htmlFor={`toDo`} className="sr-only">
            Add New Todo
          </label>
          <input
            ref={newTodo}
            type="text"
            name="toDo"
            id={`toDo`}
            value={todoTemp?.toDo}
            onChange={onChange}
            placeholder="Add New Todo"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm placeholder-gray-600 pr-10 xs:pr-40"
            onKeyPress={(e: any) => {
              if (e.charCode === 13) {
                handleSave();
              }
            }}
          />
        </div>
        <label htmlFor={`description`} className="sr-only">
          Todo Description
        </label>
        <textarea
          name="description"
          id={`description`}
          value={todoTemp?.description}
          onChange={onChange}
          placeholder={`Description 2000 chars max.\n\n(Shift + Enter for new line.)`}
          className={`hidden group-focus-within:block min-w-full min-h-[10rem] sm:min-h-[8rem] rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm mt-2 placeholder-gray-600`}
          onKeyPress={(e: any) => {
            if (e.charCode === 13 && !e.shiftKey) {
              // Don't generate a new line
              e.preventDefault();
              handleSave();
            }
          }}
        />
        <div
          className={`hidden group-focus-within:flex absolute top-[1.1rem] xs:top-2 right-6`}
        >
          <input
            type="date"
            name="date"
            id={`date`}
            value={formatDate(todoTemp?.date, true)}
            onChange={onChange}
            className={`hidden xs:inline-block placeholder-gray-600 text-sm bg-transparent outline-none cursor-pointer text-gray-600 w-[1.65rem] transition-[width] duration-300 border-none focus:border-none focus:outline-none focus:ring-2 focus:ring-indigo-500 rounded-md focus:bg-white px-1 mr-1`}
            onClick={e => {
              (e.target as HTMLInputElement).style.width = "7rem";
              (e.target as HTMLInputElement).showPicker();
            }}
            onFocus={e => {
              e.target.style.width = "7rem";
              e.target.showPicker();
            }}
            onBlur={e => !e.target.value && (e.target.style.width = "1.65rem")}
          />
          <span
            className={`relative isolate grid place-items-center rounded-md`}
          >
            <input
              type="checkbox"
              name="star"
              id="star"
              checked={todoTemp?.star}
              onChange={() =>
                setTodoData({
                  ...todoTemp,
                  star: !todoTemp?.star
                })
              }
              className="row-span-full col-span-full rounded-full border-transparent z-10 focus:ring-2 focus:ring-yellow-500 cursor-pointer peer !bg-transparent !bg-gradient-to-r from-transparent to-transparent"
            />
            <Icon
              name="outline/star"
              className={`row-span-full col-span-full h-4 w-4 ${
                todoTemp?.star
                  ? "fill-yellow-500 stroke-yellow-500"
                  : "fill-none stroke-gray-500"
              }`}
            />
          </span>
        </div>
        <div className="xs:hidden">
          <input
            type="date"
            name="date"
            id={`date`}
            value={formatDate(todoTemp?.date, true)}
            onChange={onChange}
            className="mt-3 hidden group-focus-within:block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm placeholder-gray-600"
          />
        </div>
        <div
          className={`hidden group-focus-within:flex items-center justify-between mt-2 ml-3 relative`}
        >
          <p className={`text-[11px] font-medium text-gray-600`}>
            Press Enter To Save Todo
          </p>
          <button
            type="button"
            className="mt-3 sm:ml-4 inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 font-medium text-white hover:bg-indigo-700 disabled:bg-indigo-700 focus:outline-none sm:mt-0 w-32 text-sm sm:absolute right-3 bottom-10"
            onClick={handleSave}
          >
            <span className="w-full flex justify-end">
              {logging ? <Icon name="loading" className="mr-2" /> : null}
            </span>
            <span>Save</span>
            <span className="w-full"></span>
          </button>
        </div>
      </div>
    </form>
  );
}

export default compose<ComponentType<FormProps>>(
  connector,
  withRouter
)(TodoForm);
