import { Component, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import { formatDateTimeString } from "../../helpers/formatDate";
import Icon from "../../components/Icon";
import { ADD_NOTIFICATION } from "../../store/types";
import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "../../helpers/withRouter";
import { compose } from "redux";
import Dashboard from "../../components/Dashboard";
import agent from "../../agent";
import Button from "../../components/Button";
import capitalize from "../../helpers/capitalize";
import Switch from "../../components/switch";
import { downloadFile } from "../../helpers/downloadFile";
import Pagination from "../../components/Pagination";
import { LogsTable } from "../../components/LogsModal";
import ReportFilters, { Filter, GroupBy } from "./ReportFilters";
import TimeTrackingReport from "./TimeTrackingReport";

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface State {
  loading: boolean;
  exporting: boolean;
  showReport: boolean;
  filter: Filter;
  report: any[];
  displayReport: { [key: string]: any[] };
  totalRecords: number;
  groupBy: GroupBy;
  displayTaskLogs: any[];
  groupedTaskLogs: { [key: string]: any[] };
  totalTaskLogs: number;
  displayLogs: any[];
  totalLogs: number;
  showHide: { [key: string]: boolean };
  collapseAll: boolean;
  skip: number;
  currPage: number;
  chunkSize: number;
  currPageTask: number;
  chunkSizeTask: number;
  showTimeInHrMin: boolean;
}

class ReportListing extends Component<PropsFromRedux, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      exporting: false,
      showReport: false,
      filter: {} as Filter,
      report: [],
      displayReport: {},
      totalRecords: 0,
      groupBy:
        (this.props as any)?.params?.reportType === "activity-logs"
          ? "report"
          : (this.props as any).params.report === "advance-report"
          ? "user"
          : "none",
      displayTaskLogs: [],
      groupedTaskLogs: {},
      totalTaskLogs: 0,
      displayLogs: [],
      totalLogs: 0,
      showHide: {},
      collapseAll: false,
      skip: 0,
      currPage: 0,
      chunkSize: 500,
      currPageTask: 0,
      chunkSizeTask: 500,
      showTimeInHrMin: localStorage.getItem("showTimeInHrMin") === "true"
    };
  }

  handleGroupByChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ groupBy: e.target.value as GroupBy }, () => {
      if (this.state.report.length > 0) {
        this.group(this.state.report);
      } else {
        this.group(this.state.displayTaskLogs, "task");
        this.group(this.state.displayLogs);
      }
    });
  };

  getCurrentlyWorkingReport = () => {
    this.setState({ showReport: true, loading: true });
    const workSpaceId = (this.props as any).params?.firmId;
    agent.TimeTracking.currentlyWorking(workSpaceId)
      .then((res: any) => {
        this.group(res.allRecords);
        this.setState({
          report: res.allRecords,
          loading: false
        });
      })
      .catch(() => {
        this.setState({ loading: false });
        (this.props as any).addNotification(
          "Error",
          "Error while fetching report",
          "error"
        );
      });
  };

  generateReport = (filter: Filter, download: boolean) => {
    const reportType = (this.props as any).params.reportType;
    this.setState({ filter, groupedTaskLogs: {}, displayReport: {} }, () => {
      const { logTypes } = this.state.filter;

      if (reportType === "time-tracking") {
        return this.timeTrackingReport(download);
      } else if (reportType === "activity-logs") {
        if (logTypes.length === 1 && logTypes[0] === "Task") {
          return this.getTaskLogsReport(download);
        } else if (logTypes.length > 1 && logTypes.includes("Task")) {
          return (
            this.getSettingsLogsReport(download),
            this.getTaskLogsReport(download)
          );
        } else {
          return this.getSettingsLogsReport(download);
        }
      }
    });
  };

  timeTrackingReport = (download: boolean) => {
    this.setState({ showReport: true });
    const workSpaceId = (this.props as any).params?.firmId;
    const {
      dateFrom,
      dateTo,
      trackingType,
      users,
      selectedUsers,
      clients,
      selectedClients
    } = this.state.filter;
    const allUsersSelected =
      ((selectedUsers.length === 1 && selectedUsers[0]._id === "allUsers") ||
        selectedUsers.length === users.length) &&
      !(this.props as any).params.report.includes("your");

    const allClientsSelected =
      (selectedClients.length === 1 &&
        selectedClients[0]._id === "allClients") ||
      selectedClients.length === clients.length;

    const limit = this.state.chunkSize;
    const skip = this.state.chunkSize * this.state.currPage;

    download
      ? this.setState({ exporting: true })
      : this.setState({ loading: true });
    agent.TimeTracking.getTrackingHistory(
      workSpaceId,
      dateFrom,
      dateTo,
      skip,
      limit,
      download ? true : false,
      allClientsSelected ? [] : selectedClients.map((item: any) => item._id),
      allUsersSelected
        ? []
        : (this.props as any).params.report.includes("your")
        ? (this.props as any).currentUser?._id
        : selectedUsers.map((item: any) => item._id),
      trackingType === "both" ? "" : trackingType
    )
      .then((res: any) => {
        if (download) {
          this.setState({ exporting: false });
          return downloadFile(
            res,
            `TaxPido PMS Time Tracking ${capitalize(
              (this.props as any).params.report
            )}-${formatDateTimeString(new Date())}.xlsx`
          );
        } else {
          this.group(res.finalList.reverse());
          this.setState({
            report: res.finalList.reverse(),
            totalRecords: res.count,
            loading: false
          });
        }
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).addNotification(
          "Error",
          typeof err?.response?.data?.message === "object"
            ? "Error while fetching report"
            : err?.response?.data?.message ||
                err?.response?.data?.error ||
                err?.message,
          "error"
        );
      });
  };

  getTaskLogsReport = (download: boolean) => {
    this.setState({ showReport: true });
    const workSpaceId = (this.props as any).params?.firmId;
    const {
      dateFrom,
      dateTo,
      changeType,
      users,
      selectedUsers,
      clients,
      selectedClients
    } = this.state.filter;
    const allUsersSelected =
      ((selectedUsers.length === 1 && selectedUsers[0]._id === "allUsers") ||
        selectedUsers.length === users.length) &&
      !(this.props as any).params.report.includes("your");

    /* const allUsersSelected =
      (selectedUsers.length === 1 && selectedUsers[0]._id === "allUsers") ||
      selectedUsers.length === users.length; */

    const allClientsSelected =
      (selectedClients.length === 1 &&
        selectedClients[0]._id === "allClients") ||
      selectedClients.length === clients.length;

    const limit = this.state.chunkSizeTask;
    const skip = this.state.chunkSizeTask * this.state.currPageTask;

    download
      ? this.setState({ exporting: true })
      : this.setState({ loading: true });
    agent.Reports.getTaskLogsReport(
      workSpaceId,
      dateFrom,
      dateTo,
      skip,
      limit,
      download ? true : false,
      changeType.filter(item => item !== "all"),
      allUsersSelected
        ? []
        : (this.props as any).params.report.includes("your")
        ? (this.props as any).currentUser?._id
        : selectedUsers.map((item: any) => item._id),
      //allUsersSelected ? [] : selectedUsers.map((item: any) => item._id),
      allClientsSelected ? [] : selectedClients.map((item: any) => item._id)
    )
      .then((res: any) => {
        if (download) {
          this.setState({ exporting: false });
          return downloadFile(
            res,
            `TaxPido PMS ${capitalize(
              (this.props as any).params.reportType
            )} Report-${formatDateTimeString(new Date())}.xlsx`
          );
        } else {
          const taskLogs = res.logs.map((item: any) => ({
            ...item,
            logType: "task"
          }));
          this.group(taskLogs, "task");
          this.setState({
            displayTaskLogs: taskLogs,
            totalTaskLogs: res.count,
            loading: false
          });
        }
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).addNotification(
          "Error",
          typeof err?.response?.data?.message === "object"
            ? "Error while fetching report"
            : err?.response?.data?.message ||
                err?.response?.data?.error ||
                err?.message,
          "error"
        );
      });
  };

  getSettingsLogsReport = (download: boolean) => {
    this.setState({ showReport: true });
    const workSpaceId = (this.props as any).params?.firmId;
    const {
      dateFrom,
      dateTo,
      logTypes: logType,
      changeType,
      users,
      selectedUsers,
      clients,
      selectedClients
    } = this.state.filter;
    const allUsersSelected =
      (selectedUsers.length === 1 && selectedUsers[0]._id === "allUsers") ||
      selectedUsers.length === users.length;

    const allClientsSelected =
      (selectedClients.length === 1 &&
        selectedClients[0]._id === "allClients") ||
      selectedClients.length === clients.length;

    const limit = this.state.chunkSize;
    const skip = this.state.chunkSize * this.state.currPage;

    download
      ? this.setState({ exporting: true })
      : this.setState({ loading: true });
    agent.Reports.getSettingsLogsReport(
      workSpaceId,
      dateFrom,
      dateTo,
      skip,
      limit,
      download ? true : false,
      logType.filter(item => item !== "All" && item !== "Task"),
      changeType.filter(item => item !== "all"),
      allUsersSelected ? [] : selectedUsers.map((item: any) => item._id),
      allClientsSelected ? [] : selectedClients.map((item: any) => item._id)
    )
      .then((res: any) => {
        if (download) {
          this.setState({ exporting: false });
          return downloadFile(
            res,
            `TaxPido PMS ${capitalize(
              (this.props as any).params.reportType
            )} Report-${formatDateTimeString(new Date())}.xlsx`
          );
        } else {
          this.group(res.logs);
          this.setState({
            displayLogs: res.logs,
            totalLogs: res.count,
            loading: false
          });
        }
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).addNotification(
          "Error",
          typeof err?.response?.data?.message === "object"
            ? "Error while fetching report"
            : err?.response?.data?.message ||
                err?.response?.data?.error ||
                err?.message,
          "error"
        );
      });
  };

  handlePageClick = (data: any) => {
    this.setState({ currPage: data.selected });
  };

  handleItemPerPage = (value: any) => {
    this.setState({ chunkSize: value.name, currPage: 0 });
  };

  handlePageClickTask = (data: any) => {
    this.setState({ currPageTask: data.selected });
  };

  handleItemPerPageTask = (value: any) => {
    this.setState({ chunkSizeTask: value.name, currPageTask: 0 });
  };

  group = (report: any[], reportType?: "task") => {
    const type =
      (this.props as any).params.report.includes("your") ||
      (this.props as any).params.report === "currently-working-report"
        ? "none"
        : (this.props as any).params.report === "user-wise-report"
        ? "user"
        : (this.props as any).params.report === "client-wise-report"
        ? "client"
        : this.state.groupBy;

    const groupedReport: { [key: string]: any[] } = {};
    report.forEach((item: any) => {
      if (type === "report") {
        if (groupedReport[item.logType]) {
          groupedReport[item.logType].push(item);
        } else {
          groupedReport[item.logType] = [item];
        }
      } else if (type === "client") {
        const key =
          reportType === "task" ? item.clientName + " task" : item.clientName;
        if (groupedReport[key]) {
          groupedReport[key].push(item);
        } else {
          groupedReport[key] = [item];
        }
      } else if (type === "user") {
        const key =
          typeof item.userId === "object" ? item.userId.name : item.userName;
        const userKey = reportType === "task" ? key + " task" : key;
        if (groupedReport[userKey]) {
          groupedReport[userKey].push(item);
        } else {
          groupedReport[userKey] = [item];
        }
      } else {
        if (groupedReport["all"]) {
          groupedReport["all"].push(item);
        } else {
          groupedReport["all"] = [item];
        }
      }
    });
    reportType === "task"
      ? this.setState({ groupedTaskLogs: groupedReport })
      : this.setState({ displayReport: groupedReport });

    this.setState({
      groupBy: type,
      showHide: {
        ...this.state.showHide,
        ...Object.keys(groupedReport).reduce((acc: any, curr: any) => {
          acc[curr] = true;
          return acc;
        }, {})
      }
    });
  };

  showHide = (id: string) =>
    this.setState({
      showHide: {
        ...this.state.showHide,
        [id]: !this.state.showHide[id]
      }
    });

  setCollapseAll = () => {
    const collapseAll =
      Object.keys(this.state.showHide).reduce(
        (acc, curr) => acc + (this.state.showHide[curr] ? 1 : 0),
        0
      ) > 0;
    this.setState({ collapseAll });
  };

  expandCollapseAll = () => {
    const { showHide } = this.state;
    const showHideKeys = Object.keys(showHide);
    const newShowHide = showHideKeys.reduce((acc: any, curr: any) => {
      acc[curr] = this.state.collapseAll ? false : true;
      return acc;
    }, {});
    this.setState({ showHide: newShowHide });
  };

  resetList = () => {
    this.setState({
      currPage: 0,
      showReport: false,
      report: [],
      displayReport: {}
    });
  };

  onCancel = () => {
    this.resetList();
    this.setState({ groupBy: "user", showHide: {} });
  };

  handleTimeFormatChange = () => {
    this.setState({ showTimeInHrMin: !this.state.showTimeInHrMin }, () =>
      localStorage.setItem(
        "showTimeInHrMin",
        this.state.showTimeInHrMin?.toString() || "false"
      )
    );
  };

  componentDidMount(): void {
    document.title = `${capitalize(
      (this.props as any).params.report.replace(/(-)/g, " ")
    )} - TaxPido PMS`;
    // (this.props as any).params.report === "currently-working-report" &&
    //   this.getCurrentlyWorkingReport();

    const notAdmin = (this.props as any)?.rights?.role !== "admin";
    const notManager = (this.props as any)?.rights?.role !== "manager";

    if (
      (this.props as any)?.rights?.role &&
      notAdmin &&
      notManager &&
      ((this.props as any).params.report === "client-wise-report" ||
        (this.props as any).params.report === "user-wise-report" ||
        (this.props as any).params.report === "advance-report")
    ) {
      (this.props as any)?.addNotification(
        "Error",
        "You are not authorized to view this page",
        "error"
      );
    }
  }

  componentDidUpdate(prevProps: any, prevState: State) {
    const notAdmin = (this.props as any)?.rights?.role !== "admin";
    const notManager = (this.props as any)?.rights?.role !== "manager";

    if (
      prevProps?.rights?.role !== (this.props as any)?.rights?.role &&
      (this.props as any)?.rights?.role &&
      notAdmin &&
      notManager &&
      ((this.props as any).params.report === "client-wise-report" ||
        (this.props as any).params.report === "user-wise-report" ||
        (this.props as any).params.report === "advance-report")
    ) {
      (this.props as any)?.addNotification(
        "Error",
        "You are not authorized to view this page",
        "error"
      );
    }

    const prevFirmId = prevProps.params?.firmId;
    const currFirmId = (this.props as any).params?.firmId;
    if (prevFirmId !== currFirmId) {
      // (this.props as any).params.report === "currently-working-report" &&
      //   this.getCurrentlyWorkingReport();
    }

    if (prevState.showHide !== this.state.showHide) {
      this.setCollapseAll();
    }
    if (prevState.groupBy !== this.state.groupBy) {
      this.setState({ currPage: 0 });
    }
    if (
      prevState.currPage !== this.state.currPage ||
      prevState.chunkSize !== this.state.chunkSize ||
      prevState.currPageTask !== this.state.currPageTask ||
      prevState.chunkSizeTask !== this.state.chunkSizeTask
    ) {
      this.generateReport(this.state.filter, false);
    }
  }

  onLoad = () => {
    (this.props as any).params.report === "currently-working-report"
      ? this.getCurrentlyWorkingReport()
      : this.generateReport(this.state.filter, false);
  };

  render() {
    return (
      <Dashboard>
        <div className="max-w-8xl mx-auto">
          <h1 className="text-2xl font-semibold text-gray-900 capitalize">
            {(this.props as any).params.report.replace(/(-)/g, " ")}
          </h1>
          {(this.props as any)?.rights?.role &&
          (this.props as any)?.rights?.role !== "admin" &&
          (this.props as any)?.rights?.role !== "manager" &&
          ((this.props as any).params.report === "client-wise-report" ||
            (this.props as any).params.report === "user-wise-report" ||
            (this.props as any).params.report === "advance-report") ? (
            <></>
          ) : (
            <ReportFilters
              groupBy={this.state.groupBy}
              handleGroupByChange={this.handleGroupByChange}
              generateReport={this.generateReport}
              resetList={this.resetList}
              onCancel={this.onCancel}
              params={(this.props as any).params}
              rights={(this.props as any)?.rights}
              users={(this.props as any)?.users}
              addNotification={(this.props as any).addNotification}
            />
          )}
          {this.state.showReport && (
            <>
              <div className="my-6 flex items-center gap-8 justify-between">
                {(this.props as any).params.report !==
                  "currently-working-report" && (
                  <>
                    {!(this.props as any).params.report.includes("your") ? (
                      <Button
                        name={
                          this.state.collapseAll ? "Collapse All" : "Expand All"
                        }
                        onClick={this.expandCollapseAll}
                        icon={this.state.collapseAll ? "subtract" : "add"}
                      />
                    ) : (
                      (this.props as any)?.params?.reportType ===
                        "time-tracking" && (
                        <div className="relative flex items-start">
                          <div className="flex h-5 items-center">
                            <input
                              id="time-duration-format"
                              name="time-duration-format"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-400 cursor-pointer text-indigo-600 focus:ring-indigo-500"
                              checked={this.state.showTimeInHrMin}
                              onChange={this.handleTimeFormatChange}
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              htmlFor="time-duration-format"
                              className="font-medium cursor-pointer text-gray-700"
                            >
                              Show Time in Hr:Min
                            </label>
                          </div>
                        </div>
                      )
                    )}
                    <div className="ml-auto">
                      <Button
                        name="Export"
                        icon={
                          this.state.exporting ? "loading" : "outline/download"
                        }
                        onClick={() =>
                          this.generateReport(this.state.filter, true)
                        }
                      />
                    </div>
                  </>
                )}
              </div>
              {(this.props as any)?.params?.reportType === "time-tracking" &&
                !(this.props as any).params.report.includes("your") && (
                  <div className="relative flex items-start mb-4">
                    <div className="flex h-5 items-center">
                      <input
                        id="time-duration-format"
                        name="time-duration-format"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-400 cursor-pointer text-indigo-600 focus:ring-indigo-500"
                        checked={this.state.showTimeInHrMin}
                        onChange={this.handleTimeFormatChange}
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="time-duration-format"
                        className="font-medium cursor-pointer text-gray-700"
                      >
                        Show Time in Hr:Min
                      </label>
                    </div>
                  </div>
                )}

              {/* Task logs Report */}
              {(this.props as any).params.reportType === "activity-logs" &&
                (Object.entries(this.state.groupedTaskLogs).length > 0 ? (
                  <>
                    {Object.entries(this.state.groupedTaskLogs).map(
                      ([logKey, logs]) => (
                        <>
                          {logKey !== "all" && (
                            <>
                              <h2
                                className={`${
                                  this.state.showHide[logKey] &&
                                  "px-4 border-2 w-[97%] relative top-8 -mt-4 mx-auto"
                                } rounded-md py-4 mt-4 flex gap-4 items-center justify-between bg-white px-4 text-left text-sm font-semibold text-gray-900 sm:px-6 border border-gray-200 divide-x divide-gray-200 transition-[border-color,padding,width] duration-200 ease-in-out`}
                                onClick={() => this.showHide(logKey)}
                              >
                                <span className="flex gap-x-3 items-center">
                                  <span
                                    className={`capitalize ${
                                      this.state.showHide[logKey]
                                        ? "font-bold"
                                        : "font-semibold"
                                    }`}
                                  >
                                    {logKey} Activity
                                  </span>
                                </span>
                                <span className="flex gap-3 items-center place-self-end mr-4">
                                  {this.state.showHide[logKey] &&
                                    this.state.loading && (
                                      <Icon
                                        name="loading"
                                        className="h-3.5 w-3.5"
                                      />
                                    )}
                                  <span>Hide</span>
                                  <Switch
                                    openIcon="subtract"
                                    closeIcon="add"
                                    label={`Expand Collapse ${this.state.groupBy} ${logKey} group`}
                                    enabled={this.state.showHide[logKey]}
                                    onChange={() => this.showHide(logKey)}
                                  />
                                  <span>Show</span>
                                </span>
                              </h2>
                            </>
                          )}

                          <div
                            className={`${
                              this.state.showHide[logKey] && logKey !== "all"
                                ? "border-2 px-4 pt-12 pb-4"
                                : ""
                            } border-gray-300 rounded-lg transition-[border-color,padding] duration-200 ease-in-out`}
                          >
                            {this.state.showHide[logKey] && (
                              <div>
                                <LogsTable
                                  forReport={true}
                                  type="task"
                                  loading={this.state.loading}
                                  displayRecords={logs ?? []}
                                  totalRecords={this.state.totalTaskLogs}
                                  users={(this.props as any)?.users}
                                  groupedByReport={
                                    this.state.groupBy === "report"
                                  }
                                />
                                <div className="bg-gray-50 border-y-2 border-t-gray-200 px-4 py-4 whitespace-nowrap text-sm text-gray-900 font-semibold capitalize">
                                  Total - {logs.length}
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )
                    )}
                    <Pagination
                      displayRecords={this.state.displayTaskLogs}
                      totalRecords={this.state.totalTaskLogs}
                      currPage={this.state.currPageTask}
                      chunkSize={this.state.chunkSizeTask}
                      handlePageClick={this.handlePageClickTask}
                      handleItemPerPage={this.handleItemPerPageTask}
                      className="my-4"
                    />
                  </>
                ) : (
                  !this.state.loading && (
                    <div className="bg-white px-4 py-4 mb-12 whitespace-nowrap text-sm text-gray-900 font-semibold capitalize">
                      No Record Found
                    </div>
                  )
                ))}

              {!this.state.loading ? (
                Object.entries(this.state.displayReport).length > 0 ? (
                  <div className="rounded-md sm:overflow-hidden">
                    <div className="flex flex-col">
                      <div id="tab-scroll" className="overflow-x-auto">
                        <div className="inline-block min-w-full align-middle">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            {Object.entries(this.state.displayReport).map(
                              ([logKey, logs]) => {
                                const heading =
                                  logKey === "contactperson"
                                    ? "Contact Person"
                                    : logKey === "customfield"
                                    ? "Custom Field"
                                    : logKey;
                                return (
                                  <Fragment key={logKey}>
                                    {logKey !== "all" && (
                                      <h2
                                        className={`${
                                          this.state.showHide[logKey] &&
                                          "px-4 border-2 w-[97%] relative top-8 -mt-4 mx-auto"
                                        } rounded-md py-4 mt-4 flex gap-4 items-center justify-between bg-white px-4 text-left text-sm font-semibold text-gray-900 sm:px-6 border border-gray-200 divide-x divide-gray-200 transition-[border-color,padding,width] duration-200 ease-in-out`}
                                        onClick={() => this.showHide(logKey)}
                                      >
                                        <span className="flex gap-x-3 items-center">
                                          <span
                                            className={`capitalize ${
                                              this.state.showHide[logKey]
                                                ? "font-bold"
                                                : "font-semibold"
                                            }`}
                                          >
                                            {heading}{" "}
                                            {(this.props as any)?.params
                                              ?.reportType ===
                                              "activity-logs" &&
                                              (this.state.groupBy === "report"
                                                ? "Activity"
                                                : "Other Activity")}
                                          </span>
                                        </span>
                                        <span className="flex gap-3 items-center place-self-end mr-4">
                                          {this.state.showHide[logKey] &&
                                            this.state.loading && (
                                              <Icon
                                                name="loading"
                                                className="h-3.5 w-3.5"
                                              />
                                            )}
                                          <span>Hide</span>
                                          <Switch
                                            openIcon="subtract"
                                            closeIcon="add"
                                            label={`Expand Collapse ${this.state.groupBy} ${logKey} group`}
                                            enabled={
                                              this.state.showHide[logKey]
                                            }
                                            onChange={() =>
                                              this.showHide(logKey)
                                            }
                                          />
                                          <span>Show</span>
                                        </span>
                                      </h2>
                                    )}

                                    <div
                                      className={`${
                                        this.state.showHide[logKey] &&
                                        logKey !== "all"
                                          ? "border-2 px-4 pt-12 pb-4"
                                          : ""
                                      } border-gray-300 rounded-lg transition-[border-color,padding] duration-200 ease-in-out`}
                                    >
                                      {this.state.showHide[logKey] &&
                                        ((this.props as any).params
                                          .reportType === "time-tracking" ? (
                                          <TimeTrackingReport
                                            logs={logs}
                                            onLoad={this.onLoad}
                                            params={(this.props as any).params}
                                            users={(this.props as any)?.users}
                                            showTimeInHrMin={
                                              this.state.showTimeInHrMin
                                            }
                                          />
                                        ) : (
                                          (this.props as any).params
                                            .reportType === "activity-logs" && (
                                            <>
                                              <LogsTable
                                                forReport={true}
                                                type={heading}
                                                loading={this.state.loading}
                                                displayRecords={logs}
                                                totalRecords={
                                                  this.state.totalLogs
                                                }
                                                users={
                                                  (this.props as any)?.users
                                                }
                                                groupedByReport={
                                                  this.state.groupBy ===
                                                  "report"
                                                }
                                              />
                                              <div className="bg-gray-50 border-y-2 border-y-gray-300 px-4 py-4 whitespace-nowrap text-sm text-gray-900 font-semibold capitalize">
                                                Total - {logs.length}
                                              </div>
                                            </>
                                          )
                                        ))}
                                    </div>
                                  </Fragment>
                                );
                              }
                            )}
                            <Pagination
                              displayRecords={
                                this.state.report.length > 0
                                  ? this.state.report
                                  : this.state.displayLogs
                              }
                              totalRecords={
                                this.state.totalRecords || this.state.totalLogs
                              }
                              currPage={this.state.currPage}
                              chunkSize={this.state.chunkSize}
                              handlePageClick={this.handlePageClick}
                              handleItemPerPage={this.handleItemPerPage}
                              className="my-4"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  (((this.props as any).params.reportType === "activity-logs" &&
                    !this.state.filter.logTypes.includes("Task")) ||
                    (this.props as any).params.reportType ===
                      "time-tracking") && (
                    <div className="bg-white px-4 py-4 mb-12 whitespace-nowrap text-sm text-gray-900 font-semibold capitalize">
                      No Record Found
                    </div>
                  )
                )
              ) : (
                [...Array(5)].map((e, i) => (
                  <div
                    key={`div-${(this.props as any).params.report}-${i}`}
                    className="bg-white grid"
                    style={{
                      gridTemplateColumns: `repeat(8, minmax(0, 1fr))`
                    }}
                  >
                    {[...Array(8)].map((e, i) => (
                      <div
                        key={`div-${(this.props as any).params.report}-${i}`}
                        className="px-4 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                      >
                        <Skeleton />
                      </div>
                    ))}
                  </div>
                ))
              )}
            </>
          )}
        </div>
      </Dashboard>
    );
  }
}

export default compose(
  connector,
  withRouter
)(ReportListing) as React.ComponentType;
