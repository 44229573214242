import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { cn } from "../../lib/className";
import Button from "../Button";

interface MessageModalProps {
  show: boolean;
  title: string;
  ModalContent: JSX.Element;
  className?: string;
  confirmText?: string;
  handleConfirm?: () => void;
  closeText?: string;
  handleClose: () => void;
}

export function Modal(props: MessageModalProps) {
  const {
    show,
    title,
    className,
    ModalContent,
    confirmText,
    handleConfirm,
    closeText,
    handleClose
  } = props;

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-30 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={cn(
                  "relative max-w-5xl transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:px-8 sm:py-6",
                  className
                )}
              >
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white px-2 py-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                    onClick={handleClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon
                      className="h-6 w-6 text-black"
                      aria-hidden="true"
                    />
                  </button>
                </div>
                <div className="w-full space-y-6">
                  <Dialog.Title
                    as="h1"
                    className="text-xl font-semibold leading-6 text-gray-900"
                  >
                    {title}
                  </Dialog.Title>

                  {ModalContent}

                  <div className="flex items-center justify-end gap-4">
                    {closeText && (
                      <Button
                        type="button"
                        className="w-fit"
                        onClick={handleClose}
                        name={closeText}
                      />
                    )}
                    {confirmText && (
                      <Button
                        type="button"
                        className="w-fit"
                        onClick={handleConfirm}
                        name={confirmText}
                      />
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
