import load from "load-script";

type LoadScriptOptions = typeof load extends (
  src: string,
  options: infer T,
  cb: () => void
) => any
  ? T
  : never;

export async function loadScript(src: string, options: LoadScriptOptions = {}) {
  return new Promise((resolve, reject) => {
    load(src, options, (err, script) => {
      if (err) reject(err);
      else resolve(script);
    });
  });
}
