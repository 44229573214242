import React, { ChangeEvent, Fragment } from "react";
import { connect, ConnectedProps } from "react-redux";
import agent from "../../../agent";
import { ADD_NOTIFICATION } from "../../../store/types";
import { withRouter } from "../../../helpers/withRouter";
import { compose } from "redux";
import TableMultiSelect from "../../../components/TableMultiSelect";
import TableMultiSelectCheckbox from "../../../components/TableMultiSelectCheckbox";
import { formatDate } from "../../../helpers/formatDate";

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

interface Props {
  row: any;
  headers: any;
  requestId: string;
  importErrors: any;
  errorField: string;
  errLogs: any;
  err: any;
  getColumnErrors: any;
  getImportErrors: any;
  logs: any;
  setLogs: any;
  customFieldLists: any;
  contactPersonList: any;
  groupLists: any;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

class ErrorRow extends React.Component<Props, PropsFromRedux> {
  state: {
    workSpaceId: string;
    errorData: any;
    error: any;
    loading: boolean;
    dataChanged: boolean;
  };

  constructor(props: Props) {
    super(props);

    const errorData = props.row.fieldType.includes("CustomField")
      ? {
          ...this.errorDataList().find(
            (item: any) =>
              props?.row?.[this.errField()]
                ?.map((errObj: any) => errObj.name)
                ?.includes(item.name) && {
                name: item.name,
                _id: item._id
              }
          ),
          value: props.row?.error?.[0].value ?? ""
        }
      : props.row.fieldType.includes("{ name: text, value: text }")
      ? this.errorDataList().filter(
          (item: any) =>
            props?.row?.[this.errField()]
              ?.map((errObj: any) => errObj.name)
              ?.includes(item.name) && {
              name: item.name,
              _id: item._id
            }
        )
      : props.row.fieldType.includes("List of") &&
        !props.row.fieldType.includes("CustomField")
      ? this.errorDataList().filter(
          (item: any) =>
            props?.row?.[this.errField()]?.includes(item.name) && {
              name: item.name,
              _id: item._id
            }
        )
      : props.row?.[this.errField()] ?? "";

    this.state = {
      workSpaceId: (props as any).params?.firmId,
      loading: false,
      error: errorData,
      errorData,
      dataChanged: false
    };
  }

  errorDataList = () => {
    switch (this.props?.errorField) {
      case "Tags":
        return (this.props as any)?.tags;
      case "Users*":
        return (this.props as any)?.users;
      case "Custom Fields":
        return this.props?.customFieldLists;
      case "Contact Person" || "Primary Contact Person":
        return this.props?.contactPersonList;
      case "Group 1" || "Group 2" || "Group 3":
        return this.props?.groupLists;
      case "Designation" || "Primary Contact Designation":
        return [
          { name: "Individual", _id: "Individual" },
          { name: "Proprietor", _id: "Proprietor" },
          { name: "Partner", _id: "Partner" },
          { name: "Accountant", _id: "Accountant" },
          { name: "Director", _id: "Director" },
          { name: "Managing Director", _id: "Managing Director" },
          { name: "CFO", _id: "CFO" },
          { name: "Karta", _id: "Karta" },
          { name: "Other", _id: "Other" }
        ];
      case "ITR & Audit Type":
        return [
          { name: "ITR - Unaudited", _id: "ITR - Unaudited" },
          { name: "ITR - Stat Audit", _id: "ITR - Stat Audit" },
          { name: "ITR - Income Tax Audit", _id: "ITR - Income Tax Audit" }
        ];
      default:
        return [];
    }
  };

  onChange = (e: any) => {
    this.setState({
      errorData: e.target.value
    });
  };

  handleCustomFieldDataChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      errorData: { ...this.state.errorData, value: e.target.value },
      dataChanged: this.state.errorData.value !== e.target.value
    });
  };

  onErrorDataListChange = (clickedItem: any) => {
    this.setState({ dataChanged: true });
    const index = this.state.errorData.findIndex(
      (item: any) => item._id === clickedItem._id
    );
    if (index === -1) {
      this.setState({
        errorData: [...this.state?.errorData, clickedItem]
      });
    } else {
      const updatedData = this.state.errorData.filter(
        (item: any) => item._id !== clickedItem._id
      );
      this.setState({
        errorData: updatedData
      });
    }
  };

  onSave = () => {
    this.setState({
      loading: true,
      dataChanged: false
    });
    agent.Clients.updateRowField(
      (this.props as any).params.firmId,
      this.props.requestId,
      this.props.row._id,
      this.props.errorField,
      this.props.row.fieldType.includes("CustomField")
        ? [
            {
              name: this.state.errorData.name,
              value: this.state.errorData.value
            }
          ]
        : this.props.row.fieldType.includes("{ name: text, value: text }")
        ? this.state.errorData.map((item: any) => {
            return { name: item.name, value: "yes" };
          })
        : this.props.row.fieldType.includes("List of") &&
          !this.props.row.fieldType.includes("CustomField")
        ? this.state.errorData.map((item: any) => item.name)
        : this.state.errorData
    )
      .then((res: any) => {
        this.setState({
          loading: false,
          errorColor: false
        });
        this.props.getImportErrors();
        this.props.getColumnErrors();
        this.props.setLogs(
          (this.props.logs.rowsWithErr ?? 0) - 1,
          (this.props?.logs?.rowsWithoutErr ?? 0) + 1
        );
        (this.props as any).addNotification("Success", res.message, "success");
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).addNotification(
          "Error",
          err?.response?.data?.message ||
            err?.response?.data?.errors?.[0]?.message ||
            (Object.keys(err?.response?.data?.error).length === 0
              ? " "
              : err?.response?.data?.error),
          "error"
        );
      });
  };

  onEnter = (e: any) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.onSave();
    }
  };

  errField = () => {
    switch (this.props.errorField) {
      case "Client Name":
        return "name";
      case "Trade Name":
        return "tradename";
      case "File no.":
        return "fileno";
      case "GSTIN":
        return "gstin";
      case "PAN":
        return "pan";
      default:
        return "error";
    }
  };

  render() {
    return (
      <tr className="divide-x divide-gray-200">
        {this.props.headers.map((header: any, index: number) => {
          switch (header) {
            case "Row":
              return (
                <td
                  key={`${this.props.err}-${this.props.row?._id}-${header}`}
                  className={`${
                    index === 0 ? "px-6" : "px-4"
                  } text-center py-2 whitespace-nowrap text-sm text-gray-900`}
                >
                  {this.props.row?.row || "-"}
                </td>
              );
            case "Update":
              return (
                <td key={`${this.props.err}-${this.props.row?._id}-${header}`}>
                  <button
                    onClick={this.onSave}
                    disabled={
                      this.props.row.fieldType.includes("List of") &&
                      !this.props.row.fieldType.includes("CustomField")
                        ? !this.state.dataChanged
                        : this.state.errorData === this.state.error
                    }
                    className={`text-sm font-semibold w-full text-blue-500 underline disabled:text-gray-500`}
                  >
                    Update
                  </button>
                </td>
              );
            case "Client Name":
              return index === this.props.headers.length - 2 ? (
                <td key={`${this.props.err}-${this.props.row?._id}-${header}`}>
                  <input
                    className={`w-[10rem] min-w-full text-sm text-gray-900 bg-red-100 focus:ring-red-500 focus:border-red-500 border-red-300`}
                    type={"text"}
                    value={this.state.errorData}
                    onChange={this.onChange}
                    onKeyDown={this.onEnter}
                  />
                </td>
              ) : (
                <td
                  key={`${this.props.err}-${this.props.row?._id}-${header}`}
                  className={`px-4 text-center py-2 whitespace-nowrap text-sm text-gray-900`}
                >
                  {this.props.row?.name?.length > 25
                    ? this.props.row?.name.slice(0, 25) + "..."
                    : this.props.row?.name || "-"}
                </td>
              );
            case "Trade Name":
              return index === this.props.headers.length - 2 ? (
                <td key={`${this.props.err}-${this.props.row?._id}-${header}`}>
                  <input
                    className={`w-[10rem] min-w-full text-sm text-gray-900 bg-red-100 focus:ring-red-500 focus:border-red-500 border-red-300`}
                    type={"text"}
                    value={this.state.errorData}
                    onChange={this.onChange}
                    onKeyDown={this.onEnter}
                  />
                </td>
              ) : (
                <td
                  key={`${this.props.err}-${this.props.row?._id}-${header}`}
                  className={`px-4 text-center py-2 whitespace-nowrap text-sm text-gray-900`}
                >
                  {this.props.row?.tradename?.length > 25
                    ? this.props.row?.tradename.slice(0, 25) + "..."
                    : this.props.row?.tradename || "-"}
                </td>
              );
            case "File no.":
              return index === this.props.headers.length - 2 ? (
                <td key={`${this.props.err}-${this.props.row?._id}-${header}`}>
                  <input
                    className={`w-[10rem] min-w-full text-sm text-gray-900 bg-red-100 focus:ring-red-500 focus:border-red-500 border-red-300`}
                    type={"text"}
                    value={this.state.errorData}
                    onChange={this.onChange}
                    onKeyDown={this.onEnter}
                  />
                </td>
              ) : (
                <td
                  key={`${this.props.err}-${this.props.row?._id}-${header}`}
                  className={`px-4 text-center py-2 whitespace-nowrap text-sm text-gray-900`}
                >
                  {this.props.row?.fileNo || "-"}
                </td>
              );
            case "GSTIN":
              return index === this.props.headers.length - 2 ? (
                <td key={`${this.props.err}-${this.props.row?._id}-${header}`}>
                  <input
                    className={`w-[10rem] min-w-full text-sm text-gray-900 bg-red-100 focus:ring-red-500 focus:border-red-500 border-red-300`}
                    type={"text"}
                    value={this.state.errorData}
                    onChange={this.onChange}
                    onKeyDown={this.onEnter}
                  />
                </td>
              ) : (
                <td
                  key={`${this.props.err}-${this.props.row?._id}-${header}`}
                  className={`px-4 text-center py-2 whitespace-nowrap text-sm text-gray-900`}
                >
                  {this.props.row?.gstin || "-"}
                </td>
              );
            case "PAN":
              return index === this.props.headers.length - 2 ? (
                <td key={`${this.props.err}-${this.props.row?._id}-${header}`}>
                  <input
                    className={`w-[10rem] min-w-full text-sm text-gray-900 bg-red-100 focus:ring-red-500 focus:border-red-500 border-red-300`}
                    type={"text"}
                    value={this.state.errorData}
                    onChange={this.onChange}
                    onKeyDown={this.onEnter}
                  />
                </td>
              ) : (
                <td
                  key={`${this.props.err}-${this.props.row?._id}-${header}`}
                  className={`px-4 text-center py-2 whitespace-nowrap text-sm text-gray-900`}
                >
                  {this.props.row?.pan || "-"}
                </td>
              );
            case "Error":
              return index === this.props.headers.length - 2 ? (
                <td
                  key={`${this.props.err}-${this.props.row?._id}-${header}`}
                  className="bg-red-100 focus:ring-red-500 focus:border-red-500 border-red-300"
                >
                  {this.props.row.fieldType === "boolean (Yes/No)" ? (
                    <div
                      className={`w-[10rem] min-w-full text-sm text-gray-900 bg-red-100 focus:ring-red-500 focus:border-red-500 border-red-300 flex justify-around items-center`}
                    >
                      <label htmlFor="yes">
                        {" "}
                        Yes
                        <input
                          type="radio"
                          name={`${this.props.errorField}-${this.props.row?._id}`}
                          value="Yes"
                          checked={
                            this.state.errorData === "true" ||
                            this.state.errorData === true
                          }
                          onChange={this.onChange}
                          onKeyDown={this.onEnter}
                          className="ml-2"
                        />
                      </label>
                      <label htmlFor="no">
                        {" "}
                        No
                        <input
                          type="radio"
                          name={`${this.props.errorField}-${this.props.row?._id}`}
                          value="No"
                          checked={
                            this.state.errorData === "false" ||
                            this.state.errorData === false
                          }
                          onChange={this.onChange}
                          onKeyDown={this.onEnter}
                          className="ml-2"
                        />
                      </label>
                    </div>
                  ) : this.props.row.fieldType.includes("CustomField") ? (
                    <div className="w-[10rem] min-w-full">
                      <input
                        className={`w-[10rem] min-w-full text-sm text-gray-900 bg-red-100 focus:ring-red-500 focus:border-red-500 border-red-300`}
                        type={this.state.errorData.type}
                        value={this.state.errorData.value}
                        onChange={this.handleCustomFieldDataChange}
                        onKeyDown={this.onEnter}
                      />
                    </div>
                  ) : this.props.row.fieldType.includes("List of") &&
                    !this.props.row.fieldType.includes("CustomField") ? (
                    <div className="min-w-[12rem]">
                      <TableMultiSelectCheckbox
                        placeholder={`Edit ${this.props.errorField}`}
                        type={`Select-${this.props.errorField}`}
                        items={this.errorDataList().map((item: any) => {
                          return {
                            name: item.name,
                            _id: item._id
                          };
                        })}
                        selected={this.state.errorData.map((item: any) => {
                          return {
                            name: item.name,
                            _id: item._id
                          };
                        })}
                        onChange={this.onErrorDataListChange}
                        onBackDropClick={
                          this.state.errorData.length > 0
                            ? this.onSave
                            : undefined
                        }
                      />
                    </div>
                  ) : this.props.errorField === "Contact Person" ||
                    this.props.errorField === "Primary Contact Person" ||
                    this.props.errorField === "Status" ||
                    this.props.errorField === "Designation" ||
                    this.props.errorField === "Primary Contact Designation" ||
                    this.props.errorField === "ITR & Audit Type" ||
                    this.props.errorField === "Group 1" ||
                    this.props.errorField === "Group 2" ||
                    this.props.errorField === "Group 3" ? (
                    <div className="w-[10rem] min-w-full">
                      <TableMultiSelect
                        placeholder={`Edit ${this.props.errorField}`}
                        type={`Select-${this.props.errorField}`}
                        items={this.errorDataList()}
                        selected={{ name: this.state.errorData }}
                        onChange={(selected: any) => {
                          this.setState({ errorData: selected.name });
                        }}
                      />
                    </div>
                  ) : (
                    <input
                      className={`w-[10rem] min-w-full text-sm text-gray-900 bg-red-100 focus:ring-red-500 focus:border-red-500 border-red-300`}
                      type={
                        this.props.row.fieldType === "text" ||
                        this.props.row.fieldType === "number/text"
                          ? "text"
                          : this.props.row.fieldType
                      }
                      value={
                        this.props.row.fieldType === "date"
                          ? this.state.errorData
                            ? formatDate(this.state.errorData, true)
                            : ""
                          : this.state.errorData
                      }
                      onChange={this.onChange}
                      onKeyDown={this.onEnter}
                    />
                  )}
                </td>
              ) : (
                <Fragment
                  key={`${this.props.err}-${this.props.row?._id}-${header}`}
                ></Fragment>
              );
            default:
              return (
                <Fragment
                  key={`${this.props.err}-${this.props.row?._id}-${header}`}
                ></Fragment>
              );
          }
        })}
      </tr>
    );
  }
}

export default compose(
  connector,
  withRouter
)(ErrorRow) as React.ComponentType<Props>;
