import React from "react";
// Import to Display skeleton while loading data
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { connect, ConnectedProps } from "react-redux";
// Link backend
import agent from "../agent";
// Icons and styling
import Icon from "./Icon";
// Redux Notify
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../store/types";
import "../pages/style.css";
import TagManager from "react-gtm-module";
import { formatDate } from "../helpers/formatDate";
import TableMultiSelect from "./TableMultiSelect";
import Popup from "./Popup";
import MessageModal, { MessageModalType } from "./MessageModal";
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/solid";
import { NavigateFunction } from "react-router";
import { Link } from "react-router-dom";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "gsts"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: (payload: any) => dispatch({ type: UPDATE_COMMON, payload }),
  onNotify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type UserTypeToShow = "working" | "others";

type Props = {
  type: string;
  loading: boolean;
  displayTaskDetails: any;
  showAllTasks?: () => void;
  filters: any;
  totalRecords: number;
  onLoad: () => void;
  selectedTasks: any;
  onSelectAllTask: () => void;
  onTaskCheckBoxChange: (task: any) => void;
  navigate: NavigateFunction | undefined;
  location: any;
  params: any;
  setSearchParams: any;
  userTypeToShow: UserTypeToShow;
};

class TaskTable extends React.Component<Props, PropsFromRedux> {
  state: {
    loading: boolean;
    displayTaskDetails: any;
    totalRecords: number;
    showBulkActionModal: boolean;
    selectedTasks: any;
    selectedTableStatus: any;
    showStatusChangeModal: boolean;
    statusChangeModalData: {
      title: string;
      message: string;
      otherData: string;
      type: MessageModalType;
    };
  };

  constructor(props: any) {
    super(props);

    this.state = {
      loading: this.props.loading,
      displayTaskDetails: this.props.displayTaskDetails,
      totalRecords: 0,
      showBulkActionModal: false,
      selectedTasks: [],
      selectedTableStatus: { taskId: null, status: null },
      showStatusChangeModal: false,
      statusChangeModalData: {
        title: "",
        message: "",
        otherData: "",
        type: "success"
      }
    };
  }

  changeTaskStar = (task: any) => {
    const workSpaceId = (this.props as any).params?.firmId;
    const taskId = task?._id;
    const star = !task?.star;
    agent.Tasks.changeTaskStar(workSpaceId, [taskId], star)
      .then((response: any) => {
        this.onLoad();
      })
      .catch((err: any) => {
        (this.props as any).onNotify(
          "Could not change task star",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  changeTaskStatus = (task: any, status: any, prevStatusName: string) => {
    const workSpaceId = (this.props as any).params?.firmId;
    const {
      _id: taskId,
      name: taskName,
      type: taskType,
      period: taskPeriod
    } = task;

    // if (
    //   taskType === "GST" &&
    //   taskName !== "PMT-06" &&
    //   taskName !== "GSTR-4" &&
    //   prevStatusName.toLowerCase() === "completed"
    // ) {
    //   (this.props as any).onNotify(
    //     "Could not change task status",
    //     "You cannot change the status of GST task once it is marked as completed",
    //     "danger"
    //   );
    // } else {
    const { name: statusName, _id: statusId } = status;
    this.setState({
      selectedTableStatus: { taskId: task._id, status }
    });
    agent.Tasks.changeStatus(workSpaceId, [taskId], statusId)
      .then((response: any) => {
        const tasksNotCompletedIds = response?.tasksNotCompletedIds;
        if (
          tasksNotCompletedIds?.length > 0 &&
          task.type === "GST" &&
          taskName !== "PMT-06" &&
          status?.name.toLowerCase() === "completed"
        ) {
          this.setState({
            showStatusChangeModal: true,
            statusChangeModalData: {
              title: "Return not filed",
              message: `Task ${taskName} (${taskPeriod}) could not be changed to ${statusName} as return is not filed on GSTIN server.`,
              type: "error"
            }
          });
        } else if (
          tasksNotCompletedIds?.length === 0 &&
          taskType === "GST" &&
          taskName !== "PMT-06" &&
          statusName.toLowerCase() === "completed"
        ) {
          this.setState({
            showStatusChangeModal: true,
            statusChangeModalData: {
              title: "Return filed successfully",
              message:
                taskName === "GSTR-4"
                  ? `We have marked ${taskName} (${taskPeriod}) as ${statusName} but we cannot confirm the filing on GSTN Servers as ${taskName} filing details are not shared by GSTN Servers`
                  : `Task ${taskName} (${taskPeriod}) has been changed to ${statusName} as return filing is successfully confirmed from GSTN server.`,
              otherData:
                taskName === "GSTR-4"
                  ? ""
                  : `ARN - ${response?.gstArn}\nDate of filing - ${response.gstDateOfFiling[0]}`,
              type: "success"
            }
          });
        } else {
          (this.props as any).onNotify(
            "Task status changed successfully",
            "All selected tasks status changed successfully.",
            "success"
          );
        }
        this.onLoad();
        (this.props as any).taskCreated && this.props.showAllTasks?.();
        this.setState({
          selectedTableStatus: { taskId: null, status: null }
        });
      })
      .catch((err: any) => {
        this.setState({
          selectedTableStatus: { taskId: null, status: prevStatusName }
        });
        (this.props as any).onNotify(
          "Could not change task status",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
    // }
  };

  onLoad = () => {
    this.props.onLoad();
  };

  onSelectAllTask = () => {
    this.props.onSelectAllTask();
  };

  onTaskCheckBoxChange = (task: any) => {
    this.props.onTaskCheckBoxChange(task);
  };

  openAddTaskPage = () => {
    const taskRights = (this.props as any)?.rights?.taskRights;
    const createRight = taskRights.create;
    const currentFirmId = (this.props as any).params?.firmId;
    if (createRight) {
      (this.props as any).navigate(`/${currentFirmId}/tasks/add`);
    } else {
      (this.props as any).onNotify(
        "Rights Not Avilable",
        "Ask Admin to change your user rights.",
        "danger"
      );
    }
  };

  bulkActionModalSetOpen = (open: boolean) => {
    this.setState({
      showBulkActionModal: open,
      selectedTasks: []
    });
  };

  openBulkActionModal = () => {
    const { selectedTasks } = this.state;
    if (selectedTasks.length === 0) {
      (this.props as any).onNotify(
        "No Task Selected",
        "Please select at least one task to perform bulk action.",
        "danger"
      );
    } else {
      this.setState({
        showBulkActionModal: true
      });
    }
  };

  openEditModal = (user: any) => {
    const userRights = (this.props as any)?.rights?.userRights;
    const editRight = userRights.edit;
    const currentFirmId = (this.props as any).params?.firmId;
    if (editRight) {
      (this.props as any).updateCommon({ editUser: user });
      (this.props as any).navigate(`/${currentFirmId}/user/edit`);
    } else {
      (this.props as any).onNotify(
        "Rights Not Avilable",
        "Ask Admin to change your user rights.",
        "danger"
      );
    }
  };

  onStarClick = (task: any) => {
    const { displayTaskDetails } = this.state;
    const updateClientStar = displayTaskDetails.map((item: any) =>
      item._id === task._id ? { ...item, star: !item.star } : item
    );

    this.setState({
      displayTaskDetails: updateClientStar
    });

    this.changeTaskStar(task);
  };

  onTableStatusChange =
    (task: any, prevStatusName: string) => (status: any) => {
      this.changeTaskStatus(task, status, prevStatusName);
    };

  tableRowStatus = (task: any) => {
    const { selectedTableStatus } = this.state;
    if (selectedTableStatus.taskId === task._id) {
      return selectedTableStatus.status.name;
    } else {
      return task.statusName;
    }
  };

  tableRowStatusColor = (task: any) => {
    const { selectedTableStatus } = this.state;
    if (selectedTableStatus.taskId === task._id) {
      return selectedTableStatus.status.color;
    } else {
      return task.statusColor;
    }
  };

  openTaskDetailsModal = (task: any) => {
    const firmId = (this.props as any).params?.firmId;
    const taskId = task.taskId;
    const location = (this.props as any).location;
    (this.props as any).navigate(`/${firmId}/taskId=${taskId}`, {
      state: { from: `${location.pathname}${location.search}` }
    });
  };

  // openClientDetailsPage = (task: any) => {
  //   const firmId = (this.props as any).params?.firmId;
  //   const clientId = task.clientId;
  //   const clientName = task.clientName;
  //   (this.props as any).navigate(`/${firmId}/client-profile/${clientId}`, {
  //     state: { clientName }
  //   });
  // };

  onConfirm = () => this.setState({ showStatusChangeModal: false });

  onClose = () => this.setState({ showStatusChangeModal: false });

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <>
        {this.state.showStatusChangeModal && (
          <MessageModal
            show={this.state.showStatusChangeModal}
            data={this.state.statusChangeModalData}
            confirmText="Close"
            handleConfirm={this.onConfirm}
            handleClose={this.onClose}
          />
        )}
        <div className="sm:hidden px-2 sm:px-6 mt-6">
          <ul className="divide-y divide-gray-200 space-y-4">
            {!this.props.loading ? (
              (this.props.totalRecords > 0 ||
                Object.keys(this.props.filters)?.length > 1) &&
              this.props.displayTaskDetails.length > 0 ? (
                this.props.displayTaskDetails?.map((task: any, index: any) => {
                  const taskUsers: {
                    _id: string;
                    name: string;
                    shortname: string;
                  }[] = (this.props as any)?.users?.filter((user: any) =>
                    task.users.includes(user._id)
                  );

                  return (
                    <li
                      key={task._id}
                      className="bg-white shadow-md rounded-lg"
                    >
                      <div className="whitespace-wrap text-sm font-medium text-gray-900 px-6 py-6 sm:px-6 grid gap-4 grid-cols-2 items-center relative">
                        <div className="px-2 absolute top-0 right-0 space-y-3">
                          <input
                            type="checkbox"
                            className="h-4 w-4 rounded-full border-gray-400 text-indigo-600 focus:ring-indigo-500"
                            checked={this.props.selectedTasks.some(
                              (item: any) => item._id === task._id
                            )}
                            onChange={() => this.onTaskCheckBoxChange(task)}
                          />

                          <div className="h-5 flex gap-x-2 items-center">
                            <span className="relative isolate grid place-items-center">
                              <input
                                type="checkbox"
                                name="star"
                                id="star"
                                checked={task?.star}
                                onChange={() => this.changeTaskStar(task)}
                                className="row-span-full col-span-full rounded-full border-transparent z-10 focus:ring-2 focus:ring-yellow-500 cursor-pointer peer !bg-transparent !bg-gradient-to-r from-transparent to-transparent"
                              />
                              <Icon
                                name="outline/star"
                                className={`row-span-full col-span-full h-4 w-4 ${
                                  task?.star
                                    ? "fill-yellow-500 stroke-yellow-500"
                                    : "fill-none stroke-gray-500"
                                }`}
                              />
                            </span>
                          </div>
                        </div>
                        {(this.props.type === "task" ||
                          this.props.type === "group") && (
                          <p className="text-sm text-gray-800 font-semibold mt-auto col-span-2">
                            <span>Client Name : </span>
                            <span className="font-bold">
                              <Link
                                to={`/${
                                  (this.props as any).params?.firmId
                                }/client-profile/${task.clientId}`}
                                className="hover:underline cursor-pointer"
                              >
                                {(task.clientFileNo
                                  ? `${task.clientFileNo} - `
                                  : "") +
                                  (task.clientName
                                    ? `${task.clientName}`
                                    : "") +
                                  (task.clientTradeName
                                    ? ` - ${task.clientTradeName}`
                                    : "")}
                              </Link>
                            </span>
                          </p>
                        )}
                        <p className="text-sm text-gray-800 font-semibold mt-auto col-span-2 xs:col-span-1">
                          <span>Task : </span>
                          <span
                            className="font-bold hover:underline truncate cursor-pointer"
                            onClick={() => this.openTaskDetailsModal(task)}
                          >
                            {task.name}
                          </span>
                        </p>
                        <p className="text-sm text-gray-800 font-semibold mt-auto col-span-2 xs:col-span-1">
                          <span>Period : </span>
                          <span
                            className="font-bold hover:underline truncate cursor-pointer"
                            onClick={() => this.openTaskDetailsModal(task)}
                          >
                            {task.period}
                          </span>
                        </p>
                        <p className="text-sm text-gray-800 font-semibold mt-auto col-span-2 xs:col-span-1">
                          <span>Due Date : </span>
                          <span
                            className="font-bold hover:underline truncate cursor-pointer"
                            onClick={() => this.openTaskDetailsModal(task)}
                          >
                            {task.dueDate
                              ? formatDate(task.dueDate, false)
                              : "-"}
                          </span>
                        </p>
                        {this.props.userTypeToShow === "working" ? (
                          <p className="text-sm text-gray-800 font-semibold mt-auto col-span-2 xs:col-span-1">
                            <span>Working User : </span>
                            <span className="font-bold">
                              {task.currentUserShortName || "-"}
                            </span>
                          </p>
                        ) : (
                          <p className="text-sm text-gray-800 font-semibold mt-auto col-span-2 xs:col-span-1">
                            <span>Other Users : </span>
                            <span className="font-bold">
                              {taskUsers?.length > 0
                                ? taskUsers
                                    .map(user => user.shortname)
                                    .join(", ")
                                : "-"}
                            </span>
                          </p>
                        )}
                        <div className="text-sm text-gray-800 font-semibold mt-auto col-span-2">
                          <span>Status : </span>
                          <div className="inline-block w-40 xs:w-auto">
                            <TableMultiSelect
                              items={
                                (this.props as any)?.taskStatus?.[task.name] ||
                                (this.props as any)?.taskStatus?.["Other"] ||
                                (this.props as any)?.statusApplicableToAllTasks
                              }
                              type="task-status"
                              selected={{
                                name: this.tableRowStatus(task),
                                color: this.tableRowStatusColor(task)
                              }}
                              onChange={this.onTableStatusChange(
                                task,
                                this.tableRowStatus(task)
                              )}
                              placeholder="Select New Status"
                            />
                          </div>
                        </div>
                        <div className="col-span-2 place-self-center">
                          <button
                            type="button"
                            className="w-fit mx-auto px-8 py-2 rounded-lg bg-indigo-600 text-white hover:bg-indigo-700"
                            onClick={() => this.openTaskDetailsModal(task)}
                          >
                            View Details
                          </button>
                        </div>
                      </div>
                    </li>
                  );
                })
              ) : (
                <div className="bg-white shadow-md rounded-lg">
                  <div className="px-4 py-4 sm:px-6">
                    <p className="text-sm text-gray-800 text-center font-semibold">
                      No record found matching your search criteria
                    </p>
                  </div>
                </div>
              )
            ) : (
              <div className="space-y-4">
                {[...Array(5)].map((e, i) => (
                  <div
                    key={`wrapper-${i}`}
                    className="bg-white shadow-md rounded-lg"
                  >
                    <div
                      key={`items-${i}`}
                      className={`whitespace-wrap text-sm font-medium text-gray-900 px-4 py-4 sm:px-6 grid gap-4 grid-cols-2 items-center relative ${
                        i === 0 ? "text-left" : "text-center"
                      }`}
                    >
                      <span className="px-2 absolute top-0 -translate-y-1/2 -right-4 w-24">
                        <Skeleton
                          height="1rem"
                          className="w-full bg-green-100"
                        />
                      </span>
                      {[...Array(4)].map((e, i) => (
                        <Skeleton
                          key={`tasklist-skeleton-${i}`}
                          height="1rem"
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </ul>
        </div>
        <div className="hidden sm:block">
          {!this.props.loading && this.props.displayTaskDetails ? (
            this.props.totalRecords > 0 ||
            Object.keys(this.props.filters)?.length > 1 ? (
              <div className={"max-w-screen-2xl mx-auto"}>
                {/* Task List Table */}
                <div className="mt-6 flex flex-col">
                  <div id="table-scroll" className="overflow-auto">
                    <div className="inline-block min-w-full py-2 px-1 align-middle">
                      <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                        <table className="min-w-full border-collapse border shadow-sm">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                style={{ zIndex: 8 }}
                                scope="col"
                                className="sticky top-0 whitespace-nowrap border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-4"
                              >
                                <div className="flex items-center gap-4">
                                  <div>
                                    <input
                                      type="checkbox"
                                      className="h-4 w-4 rounded border-gray-400 text-indigo-600 focus:ring-indigo-500"
                                      checked={
                                        this.props.displayTaskDetails.length >
                                          0 &&
                                        this.props.displayTaskDetails.length ===
                                          this.props.selectedTasks.length
                                      }
                                      onChange={this.onSelectAllTask}
                                    />
                                  </div>
                                </div>
                              </th>
                              {(this.props.type === "task" ||
                                this.props.type === "group") && (
                                <th
                                  style={{ zIndex: 8 }}
                                  scope="col"
                                  className="sticky top-0 whitespace-nowrap border-l border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-5"
                                >
                                  FILE N0 - CLIENT - TRADE NAME
                                </th>
                              )}
                              <th
                                style={{ zIndex: 8 }}
                                scope="col"
                                className="sticky top-0 whitespace-nowrap border-l border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-5"
                              >
                                TASK
                              </th>
                              <th
                                style={{ zIndex: 8 }}
                                scope="col"
                                className="sticky top-0 whitespace-nowrap border-l border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-5"
                              >
                                PERIOD
                              </th>
                              <th
                                style={{ zIndex: 8 }}
                                scope="col"
                                className="sticky top-0 whitespace-nowrap border-l border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-5"
                              >
                                STATUS
                              </th>
                              <th
                                style={{ zIndex: 8 }}
                                scope="col"
                                className="sticky top-0 whitespace-nowrap border-l border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-5"
                              >
                                DUE DATE
                              </th>
                              {this.props.userTypeToShow === "working" ? (
                                <th
                                  style={{ zIndex: 8 }}
                                  scope="col"
                                  className="sticky top-0 whitespace-nowrap border-l border-b border-gray-300 bg-gray-50 px-4 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-5"
                                >
                                  WORKING
                                </th>
                              ) : (
                                <th
                                  style={{ zIndex: 8 }}
                                  scope="col"
                                  className="sticky top-0 whitespace-nowrap border-l border-b border-gray-300 bg-gray-50 px-4 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-5"
                                >
                                  Other Users
                                </th>
                              )}
                              <th
                                style={{ zIndex: 8 }}
                                scope="col"
                                className="w-[3ch] sticky top-0 whitespace-nowrap border-l border-b border-gray-300 bg-gray-50 px-4 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider sm:pl-5"
                              >
                                <ChatBubbleOvalLeftEllipsisIcon className="h-6 w-6" />
                              </th>
                            </tr>
                          </thead>
                          {this.props.displayTaskDetails.length === 0 ||
                          (Object.keys(this.props.filters).length > 1 &&
                            this.props.displayTaskDetails.length === 0) ? (
                            <tbody>
                              <tr>
                                <td className="px-5 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                                  No record found
                                </td>
                              </tr>
                            </tbody>
                          ) : (
                            <tbody className="bg-white">
                              {this.props.displayTaskDetails?.map(
                                (task: any, index: any) => {
                                  const taskUsers: {
                                    _id: string;
                                    name: string;
                                    shortname: string;
                                  }[] = (this.props as any)?.users?.filter(
                                    (user: any) => task.users.includes(user._id)
                                  );

                                  return (
                                    <tr
                                      key={task._id}
                                      className={
                                        index % 2 === 0
                                          ? undefined
                                          : "bg-gray-100"
                                      }
                                    >
                                      <td className="w-3/10 whitespace-nowrap py-4 pl-4 pr-3 font-bold text-sm text-gray-900 sm:pl-4">
                                        <div className="flex items-center gap-3">
                                          <div>
                                            <input
                                              type="checkbox"
                                              className="h-4 w-4 rounded border-gray-400 text-indigo-600 focus:ring-indigo-500"
                                              checked={this.props.selectedTasks.some(
                                                (item: any) =>
                                                  item._id === task._id
                                              )}
                                              onChange={() =>
                                                this.onTaskCheckBoxChange(task)
                                              }
                                            />
                                          </div>
                                          <div>
                                            <div className="h-5 flex gap-x-2 items-center">
                                              <span className="relative isolate grid place-items-center">
                                                <input
                                                  type="checkbox"
                                                  name="star"
                                                  id="star"
                                                  checked={task?.star}
                                                  onChange={() =>
                                                    this.onStarClick(task)
                                                  }
                                                  className="row-span-full col-span-full rounded-full border-transparent z-10 focus:ring-2 focus:ring-yellow-500 cursor-pointer peer !bg-transparent !bg-gradient-to-r from-transparent to-transparent"
                                                />
                                                <Icon
                                                  name="outline/star"
                                                  className={`row-span-full col-span-full h-4 w-4 ${
                                                    task?.star
                                                      ? "fill-yellow-500 stroke-yellow-500"
                                                      : "fill-none stroke-gray-500"
                                                  }`}
                                                />
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      {(this.props.type === "task" ||
                                        this.props.type === "group") && (
                                        <td className="w-4/12 max-w-[5rem] pl-5 border-l border-gray-300 pr-4 py-3 whitespace-nowrap text-sm text-gray-500">
                                          <Popup
                                            content={
                                              (task.clientFileNo
                                                ? `${task.clientFileNo} - `
                                                : "") +
                                              (task.clientName
                                                ? `${task.clientName}`
                                                : "") +
                                              (task.clientTradeName
                                                ? ` - ${task.clientTradeName}`
                                                : "")
                                            }
                                            className="w-full max-w-fit truncate"
                                          >
                                            <Link
                                              to={`/${
                                                (this.props as any).params
                                                  ?.firmId
                                              }/client-profile/${
                                                task.clientId
                                              }`}
                                              className="hover:underline cursor-pointer"
                                            >
                                              {/* {`${task.clientFileNo} - ${
                                              task.clientName
                                            } ${
                                              task.clientTradeName &&
                                              "(" + task.clientTradeName + ")"
                                            }`} */}
                                              {(task.clientFileNo
                                                ? `${task.clientFileNo} - `
                                                : "") +
                                                (task.clientName
                                                  ? `${task.clientName}`
                                                  : "") +
                                                (task.clientTradeName
                                                  ? ` - ${task.clientTradeName}`
                                                  : "")}
                                            </Link>
                                          </Popup>
                                        </td>
                                      )}
                                      <td className="w-3/12 max-w-[30ch] pl-3 pr-2 border-l border-gray-300 py-3 whitespace-nowrap text-sm text-[#0000EE] font-bold relative">
                                        <Popup
                                          content={task.name}
                                          className="w-full max-w-fit cursor-default"
                                        >
                                          <p
                                            className="hover:underline truncate cursor-pointer"
                                            onClick={() =>
                                              this.openTaskDetailsModal(task)
                                            }
                                          >
                                            {task.name}
                                          </p>
                                        </Popup>
                                      </td>
                                      <td className="w-1/12 border-l border-gray-300 px-3 py-3 whitespace-nowrap text-sm text-gray-500">
                                        <Popup
                                          content={task.period}
                                          className="w-full max-w-fit"
                                        >
                                          <p
                                            className="hover:underline truncate cursor-pointer"
                                            onClick={() =>
                                              this.openTaskDetailsModal(task)
                                            }
                                          >
                                            {task.period}
                                          </p>
                                        </Popup>
                                      </td>
                                      <td className="w-4/10 px-2 py-3 whitespace-nowrap border-l border-gray-300 text-sm text-gray-900 font-bold relative">
                                        <Popup
                                          content={this.tableRowStatus(task)}
                                          className="w-full"
                                        >
                                          <div className="min-w-[14rem]">
                                            <TableMultiSelect
                                              items={
                                                (this.props as any)
                                                  ?.taskStatus?.[task.name] ||
                                                (this.props as any)
                                                  ?.taskStatus?.["Other"] ||
                                                (this.props as any)
                                                  ?.statusApplicableToAllTasks
                                              }
                                              type="task-status"
                                              selected={{
                                                name: this.tableRowStatus(task),
                                                color:
                                                  this.tableRowStatusColor(task)
                                              }}
                                              onChange={this.onTableStatusChange(
                                                task,
                                                this.tableRowStatus(task)
                                              )}
                                              placeholder="Select New Status"
                                            />
                                          </div>
                                        </Popup>
                                      </td>
                                      <td className="w-4/10 px-3 py-3 whitespace-nowrap border-l border-gray-300 text-sm text-gray-500">
                                        {task.dueDate ? (
                                          <Popup
                                            content={`Task Due Date: ${formatDate(
                                              task.dueDate,
                                              false
                                            )}\n${
                                              task.govtDueDate
                                                ? `Government Due Date: ${formatDate(
                                                    task.govtDueDate,
                                                    false
                                                  )}`
                                                : ""
                                            }`}
                                          >
                                            <button
                                              type="button"
                                              className="hover:underline"
                                              onClick={() =>
                                                this.openTaskDetailsModal(task)
                                              }
                                            >
                                              {formatDate(task.dueDate, false)}
                                            </button>
                                          </Popup>
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                      {this.props.userTypeToShow ===
                                      "working" ? (
                                        <td className="w-4/10 px-3 py-3 border-l border-gray-300 text-sm relative font-bold">
                                          <div className="flex items-center justify-center">
                                            {task.currentUserShortName ? (
                                              <Popup
                                                content={task.currentUserName}
                                              >
                                                <button
                                                  type="button"
                                                  className="w-8 h-8 p-2 rounded-full cursor-default flex items-center justify-center bg-gray-700 text-white"
                                                >
                                                  {task.currentUserShortName}
                                                </button>
                                              </Popup>
                                            ) : (
                                              "-"
                                            )}
                                          </div>
                                        </td>
                                      ) : (
                                        <td className="w-4/10 px-3 py-3 border-l border-gray-300 text-sm relative font-bold">
                                          <div className="flex items-center justify-center gap-2">
                                            {taskUsers?.length > 0
                                              ? taskUsers.map(user => (
                                                  <Popup
                                                    key={user._id}
                                                    content={user.name}
                                                  >
                                                    <button
                                                      type="button"
                                                      className="w-8 h-8 p-2 rounded-full cursor-default flex items-center justify-center bg-gray-700 text-white"
                                                    >
                                                      {user.shortname}
                                                    </button>
                                                  </Popup>
                                                ))
                                              : "-"}
                                          </div>
                                        </td>
                                      )}
                                      <td className="w-4/10 px-3 py-3 border-l border-gray-300 text-sm relative font-bold">
                                        <div className="flex items-center justify-center">
                                          {task.noOfComments ? (
                                            <button
                                              type="button"
                                              className="hover:underline"
                                              onClick={() =>
                                                this.openTaskDetailsModal(task)
                                              }
                                            >
                                              {task.noOfComments}
                                            </button>
                                          ) : (
                                            "-"
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {this.props.type === "task" && (
                  <div className="text-center w-full max-w-2xl mx-auto my-10 border-2 border-gray-300 border-dashed p-16 rounded-lg">
                    <Icon
                      name="outline/document-add"
                      className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                      strokeWidth="1"
                    />
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      No Tasks Entry
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Get started by adding a new Task.
                    </p>
                    <div className="mt-6">
                      <button
                        type="button"
                        className="relative whitespace-nowrap inline-flex items-center sm:px-4 px-3 py-2 border border-transparent shadow-sm sm:text-sm text-xs font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                        onClick={this.openAddTaskPage}
                      >
                        {(this.props as any)?.rights?.taskRights?.create ? (
                          <Icon name="add" className="h-4 w-4 mr-2" />
                        ) : (
                          <Icon
                            name="outline/lock-closed"
                            className="h-4 w-4 mr-2"
                          />
                        )}
                        Add Task
                      </button>
                    </div>
                  </div>
                )}
              </>
            )
          ) : (
            <div className={"max-w-screen-2xl mx-auto"}>
              <div className="py-6">
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="overflow-scroll table-auto w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="w-2/12 whitespace-nowrap px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                              >
                                <div className="flex items-center gap-4">
                                  <div>
                                    <input
                                      type="checkbox"
                                      className="h-4 w-4 rounded border-gray-400 text-indigo-600 focus:ring-indigo-500"
                                      disabled
                                    />
                                  </div>
                                </div>
                              </th>
                              {(this.props.type === "task" ||
                                this.props.type === "group") && (
                                <th
                                  scope="col"
                                  className="w-2/12 whitespace-nowrap px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                                >
                                  FILE N0 - CLIENT - TRADE NAME
                                </th>
                              )}
                              <th
                                scope="col"
                                className="w-2/12 whitespace-nowrap px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                              >
                                TASK
                              </th>
                              <th
                                scope="col"
                                className="w-2/12 whitespace-nowrap px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                              >
                                PERIOD
                              </th>

                              <th
                                scope="col"
                                className="w-2/12 whitespace-nowrap px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                              >
                                STATUS
                              </th>
                              <th
                                scope="col"
                                className="w-2/12 whitespace-nowrap px-4 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider"
                              >
                                DUE DATE
                              </th>
                              {this.props.userTypeToShow === "working" ? (
                                <th
                                  scope="col"
                                  className="w-2/12 whitespace-nowrap px-4 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider"
                                >
                                  WORKING
                                </th>
                              ) : (
                                <th
                                  scope="col"
                                  className="w-2/12 whitespace-nowrap px-4 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider"
                                >
                                  Other Users
                                </th>
                              )}
                              <th
                                scope="col"
                                className="w-2/12 whitespace-nowrap px-4 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider"
                              >
                                <ChatBubbleOvalLeftEllipsisIcon className="h-6 w-6" />
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {[...Array(5)].map((e, i) => {
                              const columns =
                                this.props.type === "task"
                                  ? 8
                                  : this.props.type === "group"
                                  ? 8
                                  : 7;
                              return (
                                <tr key={i} className="bg-white">
                                  {[...Array(columns)].map((e, i) => (
                                    <td
                                      key={i}
                                      className="w-3/10 px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                    >
                                      <Skeleton />
                                    </td>
                                  ))}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default connector(TaskTable);
