import { ChevronDoubleRightIcon } from "@heroicons/react/24/solid";
import { formatDateTimeString } from "../helpers/formatDate";
import { cn } from "../lib/className";
import Button from "./Button";

type Details = {
  title: string | number | JSX.Element;
  value: string | number | JSX.Element;
};

type ResultsProps = {
  heading: string;
  mainDetails: Details[];
  otherDetails?: Partial<Details>[];
  loading: boolean;
  fetchGstReturnAndQrmpDetails: () => void;
  gstLastUpdated: string;
};

export const Results = ({
  heading,
  mainDetails,
  otherDetails,
  loading,
  fetchGstReturnAndQrmpDetails,
  gstLastUpdated
}: ResultsProps) => {
  return (
    <div className="space-y-4">
      <div className="flex w-full flex-wrap justify-between gap-4 pb-4">
        <h2 className="text-2xl font-semibold text-gray-900">{heading}</h2>
        {gstLastUpdated && (
          <div className="flex items-center gap-4 text-sm">
            <span className="font-semibold">Last updated:</span>{" "}
            {formatDateTimeString(gstLastUpdated)}
            <Button
              name="Update"
              icon={loading ? "loading" : "refresh"}
              onClick={fetchGstReturnAndQrmpDetails}
            />
          </div>
        )}
      </div>
      <div className="shadow-md hover:shadow-lg lg:col-start-3 lg:row-end-1">
        <div className="rounded-lg bg-slate-100 px-4 py-6 shadow-sm ring-1 ring-gray-900/20">
          <dl className="flex flex-wrap">
            <div className="grid flex-auto gap-6 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
              {mainDetails.map(({ title, value }, index) => (
                <div key={index} className="px-6">
                  <dt className="text-balance text-sm font-semibold leading-6 text-gray-900">
                    {title}
                  </dt>
                  <dd className="mt-1 text-lg font-semibold leading-6 text-gray-900">
                    {value}
                  </dd>
                </div>
              ))}
            </div>
            {otherDetails?.map(({ title, value }, index) => (
              <div
                key={index}
                className="flex w-full flex-none items-center gap-x-4 px-6 pt-6"
              >
                <dt
                  className={cn(
                    "flex-none text-balance",
                    !value && "mt-2 font-semibold"
                  )}
                >
                  {title || <ChevronDoubleRightIcon className="w-4" />}
                </dt>
                <dd className="text-sm font-medium leading-6 text-gray-900">
                  {value}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};
