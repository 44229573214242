import { Component, Fragment } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import agent from "../../agent";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "../../helpers/withRouter";
import { compose } from "redux";
import Button from "../../components/Button";
import Skeleton from "react-loading-skeleton";
import { PlayIcon, StopIcon } from "@heroicons/react/24/outline";
import CancleModal from "../../components/CancleModal";
import {
  formatDate,
  formatDateAndTime,
  formatDateTimeString
} from "../../helpers/formatDate";
import { minutesToHour } from "../../helpers/minutesToHour";
import Popup from "../../components/Popup";
import Icon from "../../components/Icon";
import { getCurrentlyTrackingTime } from "../../components/Dashboard";
import DeleteModal from "../../components/DeleteModal";
import EditCommentModal from "./EditCommentModal";
import { downloadFile } from "../../helpers/downloadFile";
import timeTillMidnight, {
  timeFromMidnight
} from "../../helpers/timeTillMidnight";

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: (payload: any) => dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
interface Props {
  closeModal: () => void;
}

type TrackingType = "automatic" | "manual";

const curentlyWorkingInitialState = {
  _id: "",
  taskId: "",
  taskName: "",
  period: "",
  clientId: "",
  clientName: "",
  startTime: "",
  stopTime: "",
  totalTime: "",
  isRunning: false,
  userId: "",
  type: "",
  comment: ""
};

type CurrentlyWorking = typeof curentlyWorkingInitialState;

const selectedTaskInitialState = {
  _id: "",
  name: "",
  period: "",
  clientId: "",
  clientName: ""
};

type SelectedTask = typeof selectedTaskInitialState;

type ManualTrackingListItem = {
  startTime: string;
  stopTime: string;
  totalTime: string;
  comment: string;
};

interface State {
  loading: boolean;
  exporting: boolean;
  selectedTask: SelectedTask;
  taskLoading: boolean;
  tasksList: any[];
  trackingType: TrackingType;
  currentTrackedTimeLoading: boolean;
  currentlyWorking: CurrentlyWorking;
  manualTrackingList: ManualTrackingListItem[];
  cancelType: string | undefined;
  showCancelModal: boolean;
  posX: any;
  posY: any;
  showBackDrop: boolean;
  selectedRow: any;
  showEditModal: boolean;
  showDeleteModal: boolean;
  showBulkUpload: boolean;
  selectedBulkUploadPeriod: string;
  activitiesList: any[];
  manuallyTrackedList: any[];
  autoTrackedList: any[];
  totalTimeTracked: number;
  showTimeInHrMin: boolean;
}

class TimeTrackingModal extends Component<Props & PropsFromRedux, State> {
  state: State = {
    loading: false,
    exporting: false,
    selectedTask: selectedTaskInitialState,
    taskLoading: false,
    tasksList: [],
    trackingType: "automatic",
    currentTrackedTimeLoading: false,
    currentlyWorking: curentlyWorkingInitialState,
    manualTrackingList: [
      {
        startTime: formatDateAndTime(new Date(), true),
        stopTime: formatDateAndTime(new Date(), true),
        totalTime: "0",
        comment: ""
      }
    ],
    cancelType: undefined,
    showCancelModal: false,
    posX: 0,
    posY: 0,
    showBackDrop: false,
    selectedRow: null,
    showEditModal: false,
    showDeleteModal: false,
    showBulkUpload: false,
    selectedBulkUploadPeriod: formatDate(new Date(), true),
    activitiesList: [],
    manuallyTrackedList: [],
    autoTrackedList: [],
    totalTimeTracked: 0,
    showTimeInHrMin: localStorage.getItem("showTimeInHrMin") === "true"
  };

  timeTrackingListingheaders = [
    "User",
    "Start Time",
    "End Time",
    "Total Time",
    "Comment",
    "Action"
  ];

  manualTrackingHeaders = ["Start Time", "End Time", "Total Time", "Comment"];

  bulkUploadListingHeaders = [
    "Client Name",
    "Task Name (period)",
    "Start Time",
    "Stop Time",
    "Total Time",
    "Comment"
  ];

  trackingHistory = ["Automatically Tracked", "Manually Tracked"];

  handleClose = () => {
    this.props.closeModal();
    this.saveCommentToLocalStorage();
  };

  onActionClick = (e: any) => {
    const screenClosness = window.innerHeight - e.clientY;
    const positionY = screenClosness < 145 ? "-4rem" : "1rem";
    this.setState({ posY: positionY, showBackDrop: true });
  };

  onDropdownClick = () => {
    this.setState({ showBackDrop: false });
  };

  openEditModal = (item: { _id: string }) => {
    this.setState({ selectedRow: item, showBackDrop: false });
    this.editModalSetOpen(true);
  };

  editModalSetOpen = (open: boolean) => {
    this.setState({ showEditModal: open });
  };

  openDeleteModal = (item: { _id: string }) => {
    this.setState({ selectedRow: item, showBackDrop: false });
    this.deleteModalSetOpen(true);
  };

  deleteModalSetOpen = (open: boolean) => {
    this.setState({ showDeleteModal: open });
  };

  handleBulkUploadPeriodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedBulkUploadPeriod: e.target.value });
  };

  handleManualStartTimeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { manualTrackingList } = this.state;
    manualTrackingList[index].startTime = e.target.value;
    this.setState({ manualTrackingList });
  };

  handleManualStopTimeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { manualTrackingList } = this.state;
    manualTrackingList[index].stopTime = e.target.value;
    this.setState({ manualTrackingList });
  };

  handleManualTotalTimeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { manualTrackingList } = this.state;
    const totalTimeRegex = /^[0-9]*$/;

    if (!totalTimeRegex.test(e.target.value)) {
      return;
    } else {
      this.setState({
        manualTrackingList: [
          ...manualTrackingList.slice(0, index),
          ...[
            {
              ...manualTrackingList[index],
              startTime: "",
              stopTime: "",
              totalTime: e.target.value
            }
          ],
          ...manualTrackingList.slice(index + 1)
        ]
      });
    }
  };

  handleManualCommentChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => {
    const { manualTrackingList } = this.state;
    this.setState({
      manualTrackingList: [
        ...manualTrackingList.slice(0, index),
        ...[
          {
            ...manualTrackingList[index],
            comment: e.target.value
          }
        ],
        ...manualTrackingList.slice(index + 1)
      ]
    });
  };

  handleTimeBlur = (e: React.FocusEvent<HTMLInputElement>, index: number) => {
    const { manualTrackingList } = this.state;
    const { startTime, stopTime } = manualTrackingList[index];

    if (startTime && stopTime) {
      const totalTime =
        new Date(stopTime).getTime() - new Date(startTime).getTime();
      manualTrackingList[index].totalTime = (totalTime / 1000 / 60).toString();
      this.setState({ manualTrackingList });
    }

    const selectedStartTime = new Date(
      manualTrackingList[index].startTime
    ).getTime();

    const selectedStopTime = new Date(
      manualTrackingList[index].stopTime
    ).getTime();

    const selectedTime = new Date(e.target.value).getTime();

    if (selectedTime > selectedStopTime) {
      return (this.props as any).addNotification(
        "Please select a valid time",
        "Start time cannot be greater than stop time",
        "error"
      );
    }

    if (selectedTime < selectedStartTime) {
      return (this.props as any).addNotification(
        "Please select a valid time",
        "Stop time cannot be less than start time",
        "error"
      );
    }
  };

  handleTotalTimeBlur = (
    e: React.FocusEvent<HTMLInputElement>,
    index: number
  ) => {
    const { manualTrackingList } = this.state;
    manualTrackingList[index].totalTime = e.target.value;
    this.setState({ manualTrackingList });
  };

  handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      currentlyWorking: {
        ...this.state.currentlyWorking,
        comment: e.target.value
      }
    });
  };

  handleTrackingTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      trackingType: e.target.value as TrackingType,
      currentlyWorking: curentlyWorkingInitialState,
      manualTrackingList: [
        {
          startTime: formatDateAndTime(new Date(), true),
          stopTime: formatDateAndTime(new Date(), true),
          totalTime: "0",
          comment: ""
        }
      ]
    });
  };

  handleStartTracking = () => {
    const workSpaceId = (this.props as any)?.currentFirm._id;
    this.setState({
      loading: true,
      currentlyWorking: curentlyWorkingInitialState
    });
    agent.TimeTracking.start(workSpaceId, this.state.selectedTask._id)
      .then((res: any) => {
        this.setState({ currentlyWorking: res.data, loading: false });
        getCurrentlyTrackingTime(
          workSpaceId,
          (this.props as any).updateCommon,
          (this.props as any).addNotification
        );
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).addNotification(
          "Could not start tracking",
          typeof err?.response?.data?.message === "object"
            ? "Something went wrong"
            : err?.response?.data?.message ||
                err?.response?.data?.error ||
                err?.message,
          "error"
        );
      });
  };

  handleStopTracking = () => {
    const { _id, comment } = this.state.currentlyWorking;
    if (_id && comment) {
      const workSpaceId = (this.props as any)?.currentFirm._id;
      this.setState({ loading: true });
      agent.TimeTracking.stop(workSpaceId, _id, comment)
        .then((res: any) => {
          this.setState({ loading: false, currentlyWorking: res.data });
          (this.props as any).addNotification(
            "Tracking saved",
            "Tracking saved successfully",
            "success"
          );
          this.getTrackingHistory();
          getCurrentlyTrackingTime(
            workSpaceId,
            (this.props as any).updateCommon,
            (this.props as any).addNotification
          );
          this.resetAutoTracking();
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          (this.props as any).addNotification(
            "Could not stop tracking",
            typeof err?.response?.data?.message === "object"
              ? "Something went wrong"
              : err?.response?.data?.message ||
                  err?.response?.data?.error ||
                  err?.message,
            "error"
          );
        });
    } else {
      (this.props as any).addNotification(
        "Comment cannot be empty",
        "Please enter a comment",
        "error"
      );
    }
  };

  saveCommentToLocalStorage = () => {
    this.state.currentlyWorking.isRunning &&
      this.state.currentlyWorking.comment &&
      localStorage.setItem("comment", this.state.currentlyWorking.comment);
  };

  // handleSaveComment = () => {
  //   const { _id, comment } = this.state.currentlyWorking;
  //   const workSpaceId = (this.props as any)?.currentFirm._id;
  //   if (_id && comment) {
  //     this.setState({ loading: true });
  //     agent.TimeTracking.editComment(workSpaceId, _id, comment)
  //       .then((res: any) => {
  //         this.setState({ loading: false, currentlyWorking: res.data });
  //         this.getTrackingHistory();
  //         (this.props as any).addNotification(
  //           "Comment saved",
  //           "Comment saved successfully",
  //           "success"
  //         );
  //         this.resetAutoTracking();
  //       })
  //       .catch((err: any) => {
  //         this.setState({ loading: false });
  //         (this.props as any).addNotification(
  //           "Could not save comment",
  //           typeof err?.response?.data?.message === "object"
  //             ? "Something went wrong"
  //             : err?.response?.data?.message ||
  //                 err?.response?.data?.error ||
  //                 err?.message,
  //           "error"
  //         );
  //       });
  //   } else {
  //     (this.props as any).addNotification(
  //       "Comment cannot be empty",
  //       "Please enter a comment",
  //       "error"
  //     );
  //   }
  // };

  resetAutoTracking = () => {
    this.setState({ currentlyWorking: curentlyWorkingInitialState });
  };

  handleManualListItemAdd = () => {
    const { manualTrackingList } = this.state;
    manualTrackingList.push({
      startTime: formatDateAndTime(new Date(), true),
      stopTime: formatDateAndTime(new Date(), true),
      totalTime: "0",
      comment: ""
    });
    this.setState({ manualTrackingList });
  };

  handleManualTracking = () => {
    const workSpaceId = (this.props as any)?.currentFirm._id;
    const { manualTrackingList } = this.state;
    const { selectedTask } = this.state;
    this.setState({ loading: true });
    const trackingData = manualTrackingList
      .filter(
        (item, index) =>
          item.totalTime &&
          item.comment &&
          item.startTime &&
          item.stopTime &&
          index !== manualTrackingList.length - 1
      )
      .map(item => ({
        startTime: item.startTime ? new Date(item.startTime).toISOString() : "",
        stopTime: item.stopTime ? new Date(item.stopTime).toISOString() : "",
        totalTime: parseInt(item.totalTime.split(" ")[0]),
        comment: item.comment
      }));

    const recordWithoutTime = trackingData.filter(
      item =>
        !item.totalTime || !item.comment || !item.startTime || !item.stopTime
    );

    const recordWithInvalidTime = trackingData.filter(
      item => item.totalTime <= 0
    );

    if (recordWithInvalidTime.length) {
      this.setState({ loading: false });
      return (this.props as any).addNotification(
        "Invalid time",
        "Total Time cannot be less than or equal to 0",
        "error"
      );
    }

    if (recordWithoutTime.length || !trackingData.length) {
      this.setState({ loading: false });
      return (this.props as any).addNotification(
        "Please enter time and comment",
        "Time and comment are mandatory for all records",
        "error"
      );
    }

    agent.TimeTracking.manualTracking(
      workSpaceId,
      selectedTask._id,
      trackingData
    )
      .then((res: any) => {
        this.setState({ loading: false });
        this.resetManualTracking();
        this.getTrackingHistory();
        (this.props as any).addNotification(
          "Time tracking successful",
          res?.data?.message || "Time tracking successful",
          "success"
        );
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).addNotification(
          "Could not track time",
          typeof err?.response?.data?.message === "object"
            ? "Something went wrong"
            : err?.response?.data?.message ||
                err?.response?.data?.error ||
                err?.message,
          "error"
        );
      });
  };

  resetManualTracking = () => {
    this.setState({
      manualTrackingList: [
        {
          startTime: formatDateAndTime(new Date(), true),
          stopTime: formatDateAndTime(new Date(), true),
          totalTime: "0",
          comment: ""
        }
      ]
    });
  };

  handleBulkTracking = () => {};

  cancelBulkTracking = () => {};

  showCancelModal = (type?: string) =>
    this.setState({ showCancelModal: true, cancelType: type });

  hideCancelModal = () => this.setState({ showCancelModal: false });

  handleCancel = () => {
    if (this.state.cancelType === "bulkTracking") {
      this.cancelBulkTracking();
    } else {
      this.resetManualTracking();
    }
    this.hideCancelModal();
  };

  getCurrentlyWorking = () => {
    const workSpaceId = (this.props as any)?.currentFirm._id;
    this.setState({ currentTrackedTimeLoading: true });
    agent.TimeTracking.currentlyWorking(workSpaceId)
      .then((res: { allRecords: CurrentlyWorking[] }) => {
        this.setState({
          currentTrackedTimeLoading: false,
          currentlyWorking: res.allRecords.length
            ? {
                ...res.allRecords[0],
                comment: localStorage.getItem("comment") || ""
              }
            : {
                ...curentlyWorkingInitialState,
                comment: localStorage.getItem("comment") || ""
              }
        });
        res.allRecords.length > 0 &&
          res.allRecords[0].isRunning &&
          this.setState({
            selectedTask: {
              _id: res.allRecords.length ? res.allRecords[0].taskId : "",
              name: res.allRecords.length ? res.allRecords[0].taskName : "",
              clientId: res.allRecords.length ? res.allRecords[0].clientId : "",
              clientName: res.allRecords.length
                ? res.allRecords[0].clientName
                : "",
              period: res.allRecords.length ? res.allRecords[0].period : ""
            }
          });
        getCurrentlyTrackingTime(
          workSpaceId,
          (this.props as any).updateCommon,
          (this.props as any).addNotification
        );
      })
      .catch((err: any) => {
        this.setState({ currentTrackedTimeLoading: false });
        (this.props as any).addNotification(
          "Could not fetch records",
          typeof err?.response?.data?.message === "object"
            ? "Something went wrong"
            : err?.response?.data?.message ||
                err?.response?.data?.error ||
                err?.message,
          "error"
        );
      });
  };

  getTrackingHistory = (download?: boolean) => {
    const workSpaceId = (this.props as any)?.currentFirm._id;
    download
      ? this.setState({ exporting: true })
      : this.setState({ loading: true });

    const selectedDate = new Date(this.state.selectedBulkUploadPeriod);
    const dateFrom = this.state.showBulkUpload
      ? timeFromMidnight(selectedDate).toISOString()
      : timeFromMidnight(new Date(2018, 0, 1)).toISOString();
    const dateTo = timeTillMidnight(selectedDate).toISOString();

    agent.TimeTracking.getTrackingHistory(
      workSpaceId,
      dateFrom,
      dateTo,
      0,
      100000,
      download ? true : false,
      [],
      [],
      "",
      this.state.selectedTask._id ? [this.state.selectedTask._id] : []
    )
      .then((res: any) => {
        if (download) {
          this.setState({ exporting: false });
          return downloadFile(
            res,
            `TaxPido PMS Time Tracking Report-${formatDateTimeString(
              new Date()
            )}.xlsx`
          );
        } else {
          const totalTimeTracked = res.finalList.reduce(
            (counter: number, item: any) => counter + item.totalTime,
            0
          );
          const manuallyTrackedList = res.finalList
            .filter((item: any) => item.type === "manual")
            .sort(
              (a: any, b: any) =>
                new Date(b.startTime).getTime() -
                new Date(a.startTime).getTime()
            );

          const autoTrackedList = res.finalList
            .filter((item: any) => item.type === "automatic")
            .sort(
              (a: any, b: any) =>
                new Date(b.startTime).getTime() -
                new Date(a.startTime).getTime()
            );

          this.setState({
            loading: false,
            manuallyTrackedList,
            autoTrackedList,
            totalTimeTracked
          });
        }
      })
      .catch((err: any) => {
        download
          ? this.setState({ exporting: false })
          : this.setState({ loading: false });
        (this.props as any).addNotification(
          "Could not fetch records",
          typeof err?.response?.data?.message === "object"
            ? "Something went wrong"
            : err?.response?.data?.message ||
                err?.response?.data?.error ||
                err?.message,
          "error"
        );
      });
  };

  showBulkTimeSheet = () => {
    this.setState({ showBulkUpload: true }, this.getTrackingHistory);
  };

  handleTimeFormatChange = () => {
    this.setState({ showTimeInHrMin: !this.state.showTimeInHrMin }, () =>
      localStorage.setItem(
        "showTimeInHrMin",
        this.state.showTimeInHrMin?.toString() || "false"
      )
    );
  };

  componentDidMount(): void {
    if ((this.props as any)?.currentModal?.data?.selectedTask) {
      this.setState({
        selectedTask: (this.props as any)?.currentModal?.data?.selectedTask
      });
    }
    this.getCurrentlyWorking();
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    if (
      prevState.selectedTask._id !== this.state.selectedTask._id &&
      this.state.selectedTask._id
    ) {
      this.getTrackingHistory();
    }
    if (
      prevState.selectedBulkUploadPeriod !== this.state.selectedBulkUploadPeriod
    ) {
      this.getTrackingHistory();
    }
    const manualLastIndex = this.state.manualTrackingList.length - 1;
    const prevTotalTime =
      prevState.manualTrackingList?.[manualLastIndex]?.totalTime;
    const prevComment =
      prevState.manualTrackingList?.[manualLastIndex]?.comment;
    const currTotalTime =
      this.state.manualTrackingList?.[manualLastIndex]?.totalTime;
    const currComment =
      this.state.manualTrackingList?.[manualLastIndex]?.comment;

    if (
      (prevTotalTime !== currTotalTime && currTotalTime && currComment) ||
      (prevComment !== currComment && currComment && currTotalTime)
    ) {
      this.handleManualListItemAdd();
    }
  }

  onLoad = () => {
    this.getTrackingHistory();
    this.resetAutoTracking();
  };

  onDeleteTimeTracking = () => {
    this.onLoad();
    const workSpaceId = (this.props as any)?.currentFirm._id;
    getCurrentlyTrackingTime(
      workSpaceId,
      (this.props as any).updateCommon,
      (this.props as any).addNotification
    );
  };

  render() {
    return (
      <Transition.Root
        show={
          (this.props as any)?.currentModal?.modalName === "TIME_TRACKING_MODAL"
        }
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="fixed z-40 inset-0 overflow-y-auto"
          onClose={() => null}
        >
          <div className="flex items-center justify-center h-full py-8 px-2">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className={`vertical-scroll inline-block bg-white rounded-lg ${
                  this.state.selectedTask._id ||
                  this.state.showBulkUpload ||
                  this.state.currentTrackedTimeLoading
                    ? "w-11/12 lg:w-10/12 h-full"
                    : "w-11/12 md:w-2/3 lg:w-1/2 h-fit min-h-[50vh]"
                } px-6 md:px-8 py-8 overflow-auto shadow-xl transform transition-all`}
              >
                {this.state.showCancelModal && (
                  <CancleModal
                    title="Clear All entered data"
                    message="Are you sure you want to Clear the entered data? This action cannot be undone."
                    loading={this.state.loading}
                    showModal={this.state.showCancelModal}
                    hideModal={this.hideCancelModal}
                    cancelText="Clear All"
                    onCancel={this.handleCancel}
                  />
                )}
                {this.state.showEditModal && (
                  <EditCommentModal
                    state={this.state}
                    onLoad={this.onLoad}
                    editModalSetOpen={this.editModalSetOpen}
                  />
                )}
                {this.state.showDeleteModal && (
                  <DeleteModal
                    type="time tracking"
                    state={this.state}
                    onLoad={this.onDeleteTimeTracking}
                    deleteModalSetOpen={this.deleteModalSetOpen}
                  />
                )}
                <div className="flex items-center justify-between">
                  <h1 className="text-xl font-medium leading-6 text-gray-900">
                    Time Tracking
                  </h1>
                  <button type="button" onClick={this.handleClose}>
                    <span className="sr-only">Close</span>
                    <XMarkIcon
                      className="h-6 w-6 text-gray-500"
                      aria-hidden="true"
                    />
                  </button>
                </div>
                {/* For showing loading skeleton */}
                {this.state.currentTrackedTimeLoading ? (
                  <div className="text-base text-gray-800 sm:overflow-auto min-h-[60vh] min-w-[70vw] space-y-6 mt-6">
                    <dl className="divide-y-2 divide-gray-200">
                      <div className="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-base font-medium text-gray-500">
                          <Skeleton className="h-5 w-32" />
                        </dt>
                        <dd className="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">
                          <span className="space-x-2 font-semibold">
                            <span className="inline-block">
                              <Skeleton className="h-6 w-52" />
                            </span>
                            <span>/</span>
                            <span className="inline-block">
                              <Skeleton className="h-6 w-40" />
                            </span>
                            <span>/</span>
                            <span className="inline-block">
                              <Skeleton className="h-6 w-40" />
                            </span>
                          </span>
                        </dd>
                      </div>
                      <div className="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-base font-medium text-gray-500">
                          <Skeleton className="h-5 w-32" />
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 flex items-center gap-8">
                          <Skeleton
                            className={`h-5 ${
                              (this.props as any).timeTrackingRunning
                                ? "w-40"
                                : "w-56"
                            }`}
                          />
                          {!(this.props as any).timeTrackingRunning && (
                            <Skeleton className="h-5 w-56" />
                          )}
                        </dd>
                      </div>
                      {(this.props as any).timeTrackingRunning ? (
                        <>
                          <div className="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-base font-medium text-gray-500">
                              <Skeleton className="h-5 w-32" />
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <Skeleton className="h-24 w-full" />
                            </dd>
                          </div>
                          <div className="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt></dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 flex items-center gap-8">
                              <Skeleton className="h-7 w-40" />
                              <Skeleton className="h-7 w-40" />
                            </dd>
                          </div>
                        </>
                      ) : (
                        <div className="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-base font-medium text-gray-500">
                            <Skeleton className="h-5 w-32" />
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <Skeleton className="h-8 w-28" />
                          </dd>
                        </div>
                      )}
                      {[...Array(5)].map((_, index) => (
                        <div
                          key={index}
                          className="w-full min-w-fit grid grid-cols-6 gap-4 py-3"
                        >
                          {[...Array(6)].map((_, index) => (
                            <div key={index}>
                              <Skeleton className="h-4" />
                            </div>
                          ))}
                        </div>
                      ))}
                    </dl>
                  </div>
                ) : this.state.selectedTask?._id ? (
                  /* For Showing Time Tracking Modal start and stop buttons and task's tracking history */
                  <div className="text-base text-gray-800 space-y-6 mt-6">
                    <dl className="divide-y-2 divide-gray-200">
                      <div className="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-base font-medium text-gray-500">
                          Task
                        </dt>
                        <dd className="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">
                          <span className="space-x-2 font-semibold">
                            <span>
                              {this.state.selectedTask?.clientName
                                ? `${this.state.selectedTask?.clientName}`
                                : ""}
                            </span>
                            <span>
                              {this.state.selectedTask?.name
                                ? `/ ${this.state.selectedTask?.name}`
                                : ""}
                            </span>
                            <span>
                              {this.state.selectedTask?.period
                                ? `/ ${this.state.selectedTask?.period}`
                                : ""}
                            </span>
                          </span>
                        </dd>
                      </div>

                      {this.state.trackingType === "automatic" &&
                      this.state.currentlyWorking?.startTime ? (
                        <div className="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-base font-medium text-gray-500">
                            {this.state.loading ? (
                              <Skeleton className="h-4 w-32" />
                            ) : (
                              "Start Time"
                            )}
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {this.state.loading ? (
                              <Skeleton className="h-4 w-64" />
                            ) : (
                              formatDateTimeString(
                                this.state.currentlyWorking?.startTime
                              )
                            )}
                          </dd>
                        </div>
                      ) : (
                        <div className="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-base font-medium text-gray-500">
                            {this.state.loading ? (
                              <Skeleton className="h-4 w-32" />
                            ) : (
                              "Type"
                            )}
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 flex items-center gap-4 md:gap-16">
                            {this.state.loading ? (
                              <Skeleton className="h-4 w-64" />
                            ) : (
                              <>
                                <div className="flex items-center gap-3">
                                  <input
                                    id="automatic"
                                    name="trackingType"
                                    type="radio"
                                    value="automatic"
                                    checked={
                                      this.state.trackingType === "automatic"
                                    }
                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                                    onChange={this.handleTrackingTypeChange}
                                  />
                                  <label
                                    htmlFor="automatic"
                                    className="text-sm font-medium text-gray-700 cursor-pointer"
                                  >
                                    Automatic Time Tracking
                                  </label>
                                </div>
                                <div className="flex items-center gap-3">
                                  <input
                                    id="manual"
                                    name="trackingType"
                                    type="radio"
                                    value="manual"
                                    checked={
                                      this.state.trackingType === "manual"
                                    }
                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                                    onChange={this.handleTrackingTypeChange}
                                  />
                                  <label
                                    htmlFor="manual"
                                    className="text-sm font-medium text-gray-700 cursor-pointer"
                                  >
                                    Manual Time Tracking
                                  </label>
                                </div>
                              </>
                            )}
                          </dd>
                        </div>
                      )}

                      {this.state.trackingType === "automatic" ? (
                        this.state.currentlyWorking?.stopTime ? (
                          <>
                            <div className="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                              <dt className="text-base font-medium text-gray-500">
                                {this.state.loading ? (
                                  <Skeleton className="h-4 w-32" />
                                ) : (
                                  "Stop Time"
                                )}
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {this.state.loading ? (
                                  <Skeleton className="h-4 w-64" />
                                ) : (
                                  formatDateTimeString(
                                    this.state.currentlyWorking?.stopTime
                                  )
                                )}
                              </dd>
                            </div>
                            {/* <div className="py-5 grid grid-cols-3 gap-4 items-center">
                              <dt></dt>
                              <dd className="text-sm text-gray-900 col-span-2 flex items-center gap-6">
                                <Button
                                  name="Save"
                                  onClick={this.handleSaveComment}
                                  disabled={this.state.loading}
                                />
                                <Button
                                  name="Cancel"
                                  onClick={() =>
                                    this.openDeleteModal(
                                      this.state.currentlyWorking
                                    )
                                  }
                                  className="w-fit inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2 px-4 text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:text-sm"
                                />
                              </dd>
                            </div> */}
                          </>
                        ) : (
                          <>
                            {this.state.currentlyWorking.isRunning && (
                              <div className="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                <dt className="text-base font-medium text-gray-500">
                                  Comment{" "}
                                  <span className="text-red-600">*</span>
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  <textarea
                                    className="w-full h-24 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                    placeholder="Comment"
                                    value={this.state.currentlyWorking?.comment}
                                    onChange={this.handleCommentChange}
                                  />
                                </dd>
                              </div>
                            )}
                            <div className="py-5 grid grid-cols-3 gap-4 items-center">
                              <dt className="text-base font-medium text-gray-500">
                                Track Time
                              </dt>
                              <dd className="text-sm text-gray-900 col-span-2 flex items-center gap-6">
                                {this.state.currentlyWorking.isRunning ? (
                                  <>
                                    <Button
                                      name="Stop"
                                      icon={StopIcon}
                                      onClick={this.handleStopTracking}
                                      disabled={this.state.loading}
                                    />
                                    <Button
                                      name="Cancel"
                                      onClick={() =>
                                        this.openDeleteModal(
                                          this.state.currentlyWorking
                                        )
                                      }
                                      className="w-fit inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2 px-4 text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:text-sm"
                                    />
                                  </>
                                ) : (
                                  <Button
                                    name="Start"
                                    icon={PlayIcon}
                                    onClick={this.handleStartTracking}
                                  />
                                )}
                              </dd>
                            </div>
                          </>
                        )
                      ) : (
                        this.state.trackingType === "manual" && (
                          <>
                            {this.state.manualTrackingList.map(
                              (item, index) => (
                                <div
                                  key={index}
                                  className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-9 gap-3 py-4 text-sm text-gray-500"
                                >
                                  <div className="lg:col-span-2">
                                    <label
                                      htmlFor="start-time"
                                      className="block text-sm font-medium text-gray-700 mb-2"
                                    >
                                      Start Time{" "}
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                      id="start-time"
                                      type="datetime-local"
                                      className="w-full text-sm border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                      required
                                      max={item.stopTime}
                                      value={item.startTime}
                                      step="60"
                                      onChange={e =>
                                        this.handleManualStartTimeChange(
                                          e,
                                          index
                                        )
                                      }
                                      onBlur={e =>
                                        this.handleTimeBlur(e, index)
                                      }
                                    />
                                  </div>
                                  <div className="lg:col-span-2">
                                    <label
                                      htmlFor="stop-time"
                                      className="block text-sm font-medium text-gray-700 mb-2"
                                    >
                                      Stop Time{" "}
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                      id="stop-time"
                                      type="datetime-local"
                                      className="w-full text-sm border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                      required
                                      min={item.startTime}
                                      value={item.stopTime}
                                      step="60"
                                      onChange={e =>
                                        this.handleManualStopTimeChange(
                                          e,
                                          index
                                        )
                                      }
                                      onBlur={e =>
                                        this.handleTimeBlur(e, index)
                                      }
                                    />
                                  </div>
                                  <div className="lg:col-span-1">
                                    <label
                                      htmlFor="total-time"
                                      className="block text-sm font-medium text-gray-700 mb-2"
                                    >
                                      Time (in Mins){" "}
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                      id="total-time"
                                      type="text"
                                      pattern="[0-9]*"
                                      required
                                      className="w-full text-sm border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                      placeholder="Total Time"
                                      value={item.totalTime}
                                      onChange={e =>
                                        this.handleManualTotalTimeChange(
                                          e,
                                          index
                                        )
                                      }
                                      onBlur={e =>
                                        this.handleTotalTimeBlur(e, index)
                                      }
                                    />
                                  </div>
                                  <div className="lg:col-span-4">
                                    <label
                                      htmlFor="comment"
                                      className="block text-sm font-medium text-gray-700 mb-2"
                                    >
                                      Comment{" "}
                                      <span className="text-red-600">*</span>
                                    </label>
                                    <textarea
                                      id="comment"
                                      rows={1}
                                      className="w-full text-sm border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                      placeholder="Comment"
                                      value={item.comment}
                                      onChange={e =>
                                        this.handleManualCommentChange(e, index)
                                      }
                                    />
                                  </div>
                                </div>
                              )
                            )}
                            <div className="py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                              <dt></dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 flex items-center gap-8 justify-end">
                                <Button
                                  name="Save"
                                  onClick={this.handleManualTracking}
                                  disabled={this.state.loading}
                                />
                                <Button
                                  name="Clear All"
                                  onClick={this.showCancelModal}
                                  disabled={
                                    this.state.loading ||
                                    (this.state.manualTrackingList.length ===
                                      1 &&
                                      this.state.manualTrackingList[
                                        this.state.manualTrackingList.length - 1
                                      ].startTime === "" &&
                                      this.state.manualTrackingList[
                                        this.state.manualTrackingList.length - 1
                                      ].stopTime === "" &&
                                      this.state.manualTrackingList[
                                        this.state.manualTrackingList.length - 1
                                      ].totalTime === "" &&
                                      this.state.manualTrackingList[
                                        this.state.manualTrackingList.length - 1
                                      ].comment === "")
                                  }
                                  className="w-fit inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2 px-4 text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:w-32 sm:text-sm"
                                />
                              </dd>
                            </div>
                          </>
                        )
                      )}
                    </dl>
                  </div>
                ) : this.state.showBulkUpload ? (
                  /* For showing bulk upload listing */
                  <div className="text-base text-gray-800 space-y-6 mt-6">
                    <div className="flex items-center justify-between">
                      <h2 className="text-base leading-6 font-medium text-gray-900">
                        Bulk Upload Time Sheet
                      </h2>
                      <div className="w-40">
                        <input
                          type="date"
                          className="w-full text-sm border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                          value={this.state.selectedBulkUploadPeriod}
                          onChange={this.handleBulkUploadPeriodChange}
                        />
                      </div>
                    </div>
                    <div
                      id="table-scroll"
                      className="overflow-auto m-1 bg-white my-2 shadow rounded-md"
                    >
                      <div className="inline-block min-w-full align-middle">
                        <div className="shadow ring-1 ring-black ring-opacity-5 rounded-lg">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-100">
                              <tr>
                                <th
                                  colSpan={this.bulkUploadListingHeaders.length}
                                  className="py-3 px-4 text-left text-xs font-medium text-gray-800 uppercase tracking-wider"
                                >
                                  Your Activities
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white">
                              <tr className="bg-gray-50 border-b">
                                {this.bulkUploadListingHeaders.map(
                                  (header: string) => (
                                    <td
                                      key={header}
                                      className="whitespace-nowrap py-3 px-4 text-sm text-gray-800 font-semibold"
                                    >
                                      {header}
                                    </td>
                                  )
                                )}
                              </tr>
                              {!this.state.loading ? (
                                this.state.activitiesList?.length > 0 ? (
                                  this.state.activitiesList.map(
                                    (item: any, index: number) => (
                                      <tr
                                        key={item._id}
                                        className={
                                          index % 2 === 0
                                            ? "bg-white"
                                            : "bg-gray-50"
                                        }
                                      >
                                        <td className="max-w-[20ch] whitespace-nowrap py-4 px-4 text-sm text-gray-800">
                                          <Popup
                                            content={item?.clientName ?? "-"}
                                            className="w-full max-w-fit"
                                          >
                                            <p className="hover:underline font-bold truncate">
                                              {item?.clientName ?? "-"}
                                            </p>
                                          </Popup>
                                        </td>
                                        <td className="max-w-[20ch] whitespace-nowrap py-4 px-4 text-sm text-gray-800">
                                          <Popup
                                            content={`${
                                              item?.taskName ?? "-"
                                            } ${
                                              item?.period
                                                ? `(${item?.period})`
                                                : ""
                                            }`}
                                            className="w-full max-w-fit"
                                          >
                                            <p className="hover:underline font-bold truncate">
                                              {`${item?.taskName ?? "-"} ${
                                                item?.period
                                                  ? `(${item?.period})`
                                                  : ""
                                              }`}
                                            </p>
                                          </Popup>
                                        </td>
                                        <td className="whitespace-nowrap py-4 px-4 text-sm text-gray-800">
                                          <input
                                            id="start-time"
                                            type="datetime-local"
                                            className="w-40 text-sm border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                            max={
                                              this.state.currentlyWorking
                                                ?.stopTime
                                            }
                                            value={
                                              this.state.currentlyWorking
                                                ?.startTime
                                            }
                                            // onChange={
                                            //   this.handleStartTimeChange
                                            // }
                                            // onBlur={this.handleTimeBlur}
                                          />
                                        </td>
                                        <td className="whitespace-nowrap py-4 px-4 text-sm text-gray-800">
                                          <input
                                            id="stop-time"
                                            type="datetime-local"
                                            className="w-40 text-sm border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                            min={
                                              this.state.currentlyWorking
                                                ?.startTime
                                            }
                                            value={
                                              this.state.currentlyWorking
                                                ?.stopTime
                                            }
                                            // onChange={
                                            //   this.handleStopTimeChange
                                            // }
                                            // onBlur={this.handleTimeBlur}
                                          />
                                        </td>
                                        <td className="whitespace-nowrap py-4 px-4 text-sm text-gray-800">
                                          <input
                                            id="total-time"
                                            type="text"
                                            pattern="[0-9]*"
                                            required
                                            className="w-40 text-sm border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                            placeholder="Total Time"
                                            value={
                                              this.state.currentlyWorking
                                                .totalTime
                                            }
                                            // onChange={
                                            //   this.handleTotalTimeChange
                                            // }
                                            // onBlur={this.handleTotalTimeBlur}
                                          />
                                        </td>
                                        <td className="whitespace-nowrap py-4 px-4 text-sm text-gray-800">
                                          <textarea
                                            id="comment"
                                            rows={2}
                                            className="w-56 text-sm border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                            placeholder="Comment"
                                            value={
                                              this.state.currentlyWorking
                                                .comment
                                            }
                                            onChange={this.handleCommentChange}
                                          />
                                        </td>
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <tr>
                                    <td
                                      colSpan={
                                        this.bulkUploadListingHeaders.length
                                      }
                                      className="px-4 py-4 whitespace-nowrap text-sm text-center text-gray-500"
                                    >
                                      No records found
                                    </td>
                                  </tr>
                                )
                              ) : (
                                [...Array(5)].map((e, i) => (
                                  <tr key={`tr-${i}`} className="bg-white">
                                    {[
                                      ...Array(
                                        this.bulkUploadListingHeaders.length
                                      )
                                    ].map((e, i) => (
                                      <td
                                        key={`td-${i}`}
                                        className="whitespace-nowrap py-4 px-4 text-sm text-gray-500"
                                      >
                                        <Skeleton />
                                      </td>
                                    ))}
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  /* For showing no timer running message */
                  !this.state.currentlyWorking.isRunning &&
                  !this.state.selectedTask._id && (
                    <div className="text-base text-gray-800 sm:overflow-auto space-y-6 mt-6 mx-6 md:mx-14 lg:mx-20">
                      <div className="text-center my-16 rounded-lg">
                        <h3 className="mt-2 text-sm font-medium text-gray-900">
                          Timer is not running.
                        </h3>
                        <p className="mt-2 text-sm text-gray-500">
                          You can start a timer from task details page for the
                          task or Insert time entries manually.
                        </p>
                        <div className="mt-6 w-full flex items-center justify-center">
                          <Button
                            type="link"
                            to={`/${
                              (this.props as any).currentFirm._id
                            }/tasks/list`}
                            name="Go to Task List"
                            onClick={this.props.closeModal}
                          />
                          {/* <Button
                            name="Add Bulk Time Entries"
                            onClick={this.showBulkTimeSheet}
                          /> */}
                        </div>
                      </div>
                    </div>
                  )
                )}
                {(this.state.showBulkUpload || this.state.selectedTask._id) && (
                  <>
                    {/* Time tracking history of the current task */}
                    {this.state.selectedTask._id && (
                      <>
                        <h2 className="text-lg font-medium leading-6 text-gray-900 mt-6">
                          Time Tracking Activity for the task
                        </h2>
                        <div className="flex items-center justify-between">
                          <div className="relative flex items-start mt-6">
                            <div className="flex h-5 items-center">
                              <input
                                id="time-duration-format"
                                name="time-duration-format"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-400 cursor-pointer text-indigo-600 focus:ring-indigo-500"
                                checked={this.state.showTimeInHrMin}
                                onChange={this.handleTimeFormatChange}
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label
                                htmlFor="time-duration-format"
                                className="font-medium cursor-pointer text-gray-700"
                              >
                                Show Time in Hr:Min
                              </label>
                            </div>
                          </div>
                          <Button
                            name="Export"
                            icon={
                              this.state.exporting
                                ? "loading"
                                : "outline/download"
                            }
                            onClick={() => this.getTrackingHistory(true)}
                          />
                        </div>
                      </>
                    )}
                    {this.trackingHistory.map((header: string) => {
                      const list = header.includes("Manually")
                        ? this.state.manuallyTrackedList
                        : this.state.autoTrackedList;

                      const totalTimeTracked = list.reduce(
                        (counter: number, item: any) =>
                          counter + item.totalTime,
                        0
                      );

                      const headers = this.state.showBulkUpload
                        ? this.bulkUploadListingHeaders
                        : this.timeTrackingListingheaders;

                      return (
                        <div
                          id="table-scroll"
                          className="overflow-auto mt-8 bg-white my-2 shadow rounded-md"
                        >
                          <div className="inline-block min-w-full align-middle">
                            <div className="shadow ring-1 ring-black ring-opacity-5 rounded-lg">
                              <div
                                key={header}
                                className="py-3 px-4 text-left text-xs font-bold bg-gray-100 text-gray-800 uppercase tracking-wider"
                              >
                                {header}
                              </div>
                              <table
                                key={`bulkUploadListing-${header}`}
                                className="min-w-full divide-y divide-gray-300"
                              >
                                <thead className="bg-gray-50">
                                  <tr>
                                    {headers.map((header: string) => (
                                      <th
                                        key={header}
                                        className={`whitespace-nowrap py-3 px-4 text-sm text-gray-800 font-semibold ${
                                          header === "Action"
                                            ? "text-center"
                                            : "text-left"
                                        }`}
                                      >
                                        {header}
                                      </th>
                                    ))}
                                  </tr>
                                </thead>
                                <tbody className="bg-white">
                                  {!this.state.loading ? (
                                    list?.length > 0 ? (
                                      <>
                                        {list.map(
                                          (item: any, index: number) => {
                                            const userName = (
                                              this.props as any
                                            ).users.find(
                                              (user: any) =>
                                                user._id === item.userId
                                            )?.name;

                                            return (
                                              <tr
                                                key={item._id}
                                                className="even:bg-white odd:bg-gray-50"
                                              >
                                                {this.state.showBulkUpload ? (
                                                  <>
                                                    <td className="max-w-[20ch] whitespace-nowrap py-4 px-4 text-sm text-gray-800">
                                                      <Popup
                                                        content={
                                                          item?.clientName ??
                                                          "-"
                                                        }
                                                        className="w-full max-w-fit"
                                                      >
                                                        <p className="hover:underline font-bold truncate">
                                                          {item?.clientName ??
                                                            "-"}
                                                        </p>
                                                      </Popup>
                                                    </td>
                                                    <td className="max-w-[20ch] whitespace-nowrap py-4 px-4 text-sm text-gray-800">
                                                      <Popup
                                                        content={`${
                                                          item?.taskName ?? "-"
                                                        } ${
                                                          item?.period
                                                            ? `(${item?.period})`
                                                            : ""
                                                        }`}
                                                        className="w-full max-w-fit"
                                                      >
                                                        <p className="hover:underline font-bold truncate">
                                                          {`${
                                                            item?.taskName ??
                                                            "-"
                                                          } ${
                                                            item?.period
                                                              ? `(${item?.period})`
                                                              : ""
                                                          }`}
                                                        </p>
                                                      </Popup>
                                                    </td>
                                                  </>
                                                ) : (
                                                  <td className="max-w-[20ch] whitespace-nowrap py-4 px-4 text-sm text-gray-800">
                                                    <Popup
                                                      content={userName ?? "-"}
                                                      className="w-full max-w-fit"
                                                    >
                                                      <p className="hover:underline font-bold truncate">
                                                        {userName ?? "-"}
                                                      </p>
                                                    </Popup>
                                                  </td>
                                                )}
                                                <td className="whitespace-nowrap py-4 px-4 text-sm text-gray-800">
                                                  {item.startTime
                                                    ? formatDateTimeString(
                                                        item.startTime
                                                      )
                                                    : "-"}
                                                </td>
                                                <td className="whitespace-nowrap py-4 px-4 text-sm text-gray-800">
                                                  {item.stopTime
                                                    ? formatDateTimeString(
                                                        item.stopTime
                                                      )
                                                    : "-"}
                                                </td>
                                                <td className="whitespace-nowrap py-4 px-4 text-sm text-gray-800">
                                                  {this.state.showTimeInHrMin &&
                                                  item.totalTime
                                                    ? `${minutesToHour(
                                                        item.totalTime
                                                      )}` ?? "-"
                                                    : item.totalTime +
                                                        " mins" ?? "-"}
                                                </td>
                                                <td className="max-w-[20ch] whitespace-nowrap py-4 px-4 text-sm text-gray-800">
                                                  <Popup
                                                    content={
                                                      item.comment ?? "-"
                                                    }
                                                    className="w-full max-w-fit"
                                                  >
                                                    <p className="hover:underline font-bold truncate">
                                                      {item.comment ?? "-"}
                                                    </p>
                                                  </Popup>
                                                </td>
                                                {this.state.selectedTask
                                                  ._id && (
                                                  <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 font-bold capitalize text-center">
                                                    <Menu
                                                      as="div"
                                                      className="inline-block relative"
                                                    >
                                                      <Menu.Button
                                                        onClick={
                                                          this.onActionClick
                                                        }
                                                      >
                                                        <span className="sr-only">
                                                          Open options
                                                        </span>
                                                        <Icon
                                                          name="horizontal-dots"
                                                          className="h-5 w-5"
                                                          aria-hidden="true"
                                                        />
                                                      </Menu.Button>
                                                      {this.state
                                                        .showBackDrop && (
                                                        <div
                                                          className="fixed top-0 left-0 z-10 w-full h-screen"
                                                          onClick={
                                                            this.onDropdownClick
                                                          }
                                                        ></div>
                                                      )}
                                                      <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                      >
                                                        <div
                                                          style={{
                                                            position: "fixed",
                                                            zIndex: 200,
                                                            top: `${this.state.posY}px`,
                                                            right: `${
                                                              this.state.posX +
                                                              70
                                                            }px`,
                                                            margin: "0.5rem"
                                                          }}
                                                        >
                                                          <Menu.Items className="overscroll-none mt-1 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                            <div className="py-1">
                                                              <Menu.Item>
                                                                <button
                                                                  className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                                                                  onClick={() =>
                                                                    this.openEditModal(
                                                                      item
                                                                    )
                                                                  }
                                                                >
                                                                  <Icon
                                                                    name="edit"
                                                                    className="h-5 w-5 mr-2"
                                                                  />
                                                                  <span>
                                                                    Edit comment
                                                                  </span>
                                                                </button>
                                                              </Menu.Item>
                                                              <Menu.Item>
                                                                <button
                                                                  className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                                                                  onClick={() =>
                                                                    this.openDeleteModal(
                                                                      item
                                                                    )
                                                                  }
                                                                >
                                                                  <Icon
                                                                    name="delete"
                                                                    className="h-5 w-5 mr-2"
                                                                  />
                                                                  <span>
                                                                    Delete
                                                                  </span>
                                                                </button>
                                                              </Menu.Item>
                                                            </div>
                                                          </Menu.Items>
                                                        </div>
                                                      </Transition>
                                                    </Menu>
                                                  </td>
                                                )}
                                              </tr>
                                            );
                                          }
                                        )}
                                        <tr className="even:bg-white odd:bg-gray-50">
                                          <td className="whitespace-nowrap py-4 px-4 text-sm text-gray-800">
                                            Total Time Tracked
                                          </td>
                                          <td></td>
                                          <td></td>
                                          <td className="whitespace-nowrap py-4 px-4 text-sm text-gray-800">
                                            {this.state.showTimeInHrMin
                                              ? minutesToHour(totalTimeTracked)
                                              : totalTimeTracked + " mins"}
                                          </td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </>
                                    ) : (
                                      <tr>
                                        <td
                                          colSpan={headers.length}
                                          className="px-4 py-4 whitespace-nowrap text-center text-sm text-gray-500"
                                        >
                                          No records found
                                        </td>
                                      </tr>
                                    )
                                  ) : (
                                    [...Array(5)].map((e, i) => (
                                      <tr key={`tr-${i}`} className="bg-white">
                                        {[...Array(headers.length)].map(
                                          (e, i) => (
                                            <td
                                              key={`td-${i}`}
                                              className="whitespace-nowrap py-4 px-4 text-sm text-gray-500"
                                            >
                                              <Skeleton />
                                            </td>
                                          )
                                        )}
                                      </tr>
                                    ))
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {this.state.showBulkUpload && (
                      <>
                        <div className="py-5 px-3 flex items-center justify-between gap-4">
                          <div className="text-base font-medium text-gray-500">
                            Total Time Tracked
                          </div>
                          <div className="text-sm text-gray-900">
                            {this.state.showTimeInHrMin
                              ? minutesToHour(this.state.totalTimeTracked)
                              : this.state.totalTimeTracked + " mins"}
                          </div>
                        </div>
                        <div className="flex items-center justify-end gap-6">
                          <Button
                            name="Save"
                            onClick={this.handleBulkTracking}
                          />
                          <Button
                            name="Cancel"
                            onClick={() => this.showCancelModal("bulkTracking")}
                            className="w-fit inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2 px-4 text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:w-32 sm:text-sm"
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}

export default compose(
  connector,
  withRouter
)(TimeTrackingModal) as React.ComponentType<Props>;
