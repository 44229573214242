import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import agent from "../../agent";
import Icon from "../../components/Icon";

import { ADD_NOTIFICATION } from "../../store/types";
import { compose } from "redux";
import { withRouter } from "../../helpers/withRouter";

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common,
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type,
      },
    }),
});

type Props = {
  modalType: string;
  showParentModal?: any;
  closeParentModal?: any;
  selectedRow?: any;
  showModal: boolean;
  closeModal: any;
  onLoad?: any;
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class Add extends React.Component<Props, PropsFromRedux> {
  state: {
    logging: boolean;
    workSpaceId: string;
    name: string;
    description: string;
  };

  constructor(props: any) {
    super(props);

    this.state = {
      logging: false,
      workSpaceId: (this.props as any).currentFirm._id,
      name: "",
      description: "",
    };
  }

  onKeyUpFunction = (event: any) => {
    if (event.keyCode === 27) {
      this.props.closeModal(false);
    }

    if (event.keyCode === 13) {
      this.handleSave();
    }
  };

  addSource = () => {
    if (this.state.name === "") {
      (this.props as any).addNotification(
        "Error",
        "Please enter a name for the source",
        "error"
      );
      return;
    } else {
      this.setState({ logging: true });
      agent.Receipt.addSource(
        this.state.name,
        this.state.description,
        this.state.workSpaceId
      )
        .then((res: any) => {
          (this.props as any).addNotification(
            "Success",
            "Source added successfully",
            "success"
          );
          this.props.onLoad();
          this.props.closeModal();
          this.props.showParentModal && this.props.showParentModal();
        })
        .catch((err: any) => {
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.error || err?.message || err,
            "danger"
          );
        })
        .finally(() => {
          this.setState({ logging: false });
        });
    }
  };

  editSource = () => {
    if (this.state.name === "") {
      (this.props as any).addNotification(
        "Error",
        "Please enter a name for the source",
        "error"
      );
      return;
    } else {
      this.setState({ logging: true });
      agent.Receipt.editSource(
        this.props.selectedRow._id,
        this.state.name,
        this.state.description,
        this.state.workSpaceId
      )
        .then((res: any) => {
          (this.props as any).addNotification(
            "Success",
            "Source updated successfully",
            "success"
          );
          this.props.onLoad();
          this.props.closeModal();
        })
        .catch((err: any) => {
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.error || err?.message || err,
            "danger"
          );
        })
        .finally(() => {
          this.setState({ logging: false });
        });
    }
  };

  addCategory = () => {
    if (this.state.name === "") {
      (this.props as any).addNotification(
        "Error",
        "Please enter a name for the category",
        "error"
      );
      return;
    } else {
      this.setState({ logging: true });
      agent.Receipt.addCategory(
        this.state.name,
        this.state.description,
        this.state.workSpaceId
      )
        .then((res: any) => {
          (this.props as any).addNotification(
            "Success",
            "Category added successfully",
            "success"
          );
          this.props.onLoad();
          this.props.closeModal();
          this.props.showParentModal && this.props.showParentModal();
        })
        .catch((err: any) => {
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.error || err?.message || err,
            "danger"
          );
        })
        .finally(() => {
          this.setState({ logging: false });
        });
    }
  };

  editCategory = () => {
    if (this.state.name === "") {
      (this.props as any).addNotification(
        "Error",
        "Please enter a name for the category",
        "error"
      );
      return;
    } else {
      this.setState({ logging: true });
      agent.Receipt.editCategory(
        this.props.selectedRow._id,
        this.state.name,
        this.state.description,
        this.state.workSpaceId
      )
        .then((res: any) => {
          (this.props as any).addNotification(
            "Success",
            "Category updated successfully",
            "success"
          );
          this.props.onLoad();
          this.props.closeModal();
        })
        .catch((err: any) => {
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.error || err?.message || err,
            "danger"
          );
        })
        .finally(() => {
          this.setState({ logging: false });
        });
    }
  };

  handleSave = () => {
    switch (this.props.modalType) {
      case "addSource":
        return this.addSource();
      case "addCategory":
        return this.addCategory();
      case "editSource":
        return this.editSource();
      case "editCategory":
        return this.editCategory();
      default:
        return;
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyUpFunction, false);
    this.props.modalType === "editSource" &&
      this.setState({ name: this.props.selectedRow.name });
    this.props.modalType === "editCategory" &&
      this.setState({ name: this.props.selectedRow.name });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyUpFunction, false);
  }

  render() {
    return (
      <>
        <Transition.Root show={this.props.showModal} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={() => null}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-2 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div className="flex justify-between items-center">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      {this.props.modalType === "addSource"
                        ? "Add Source"
                        : this.props.modalType === "addCategory"
                        ? "Add Category"
                        : this.props.modalType === "editSource"
                        ? "Edit Source"
                        : this.props.modalType === "editCategory"
                        ? "Edit Category"
                        : ""}
                    </h3>
                    {this.props.closeParentModal && (
                      <Link
                        to={`/${this.state.workSpaceId}/receipts-payments/${
                          (this.props.modalType === "addSource" && "source") ||
                          (this.props.modalType === "addCategory" && "category")
                        }`}
                        onClick={() => this.props.closeModal()}
                        className="text-gray-400 hover:text-gray-500 text-sm"
                      >
                        <span
                          onClick={() => {
                            this.props.closeModal();
                            this.props.closeParentModal();
                          }}
                        >
                          View{" "}
                          {this.props.modalType === "addSource" && "Sources"}
                          {this.props.modalType === "addCategory" &&
                            "Categories"}{" "}
                          List
                        </span>
                      </Link>
                    )}
                  </div>
                  <div>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="mt-2">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Name
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            value={this.state.name}
                            onChange={(e) =>
                              this.setState({ name: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="mt-2">
                        <label
                          htmlFor="desc"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Description
                        </label>
                        <div className="mt-1">
                          <textarea
                            id="desc"
                            name="description"
                            rows={3}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                            value={this.state.description}
                            onChange={(e) =>
                              this.setState({ description: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:justify-end">
                        <button
                          type="button"
                          className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-100 focus:bg-gray-50  focus:outline-none sm:w-32 sm:text-sm"
                          onClick={this.props.closeModal}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          disabled={this.state.logging}
                          className={
                            "mt-3 sm:ml-4 w-full inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:bg-indigo-800 sm:mt-0 sm:w-32 sm:text-sm"
                          }
                          onClick={this.handleSave}
                        >
                          <span className="w-full flex justify-end">
                            {this.state.logging ? (
                              <Icon name="loading" className="mr-2" />
                            ) : null}
                          </span>
                          <span>Save</span>
                          <span className="w-full"></span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

export default compose(
  connector,
  withRouter
)(Add) as React.ComponentType<Props>;
