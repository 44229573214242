import { Component, ComponentType, Fragment } from "react";
// Import to Display skeleton while loading data
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { connect, ConnectedProps } from "react-redux";
// Link backend
import agent from "../../agent";
// Dashboard import
import Dashboard, {
  checkIfPlanExpired,
  getInvitaionRecievedList,
  handleFirmChange
} from "../../components/Dashboard";
// Icons and styling
import Icon from "../../components/Icon";
import { Menu, Transition } from "@headlessui/react";
import "../style.css";
// Redux Notify
import { ADD_NOTIFICATION, LOGOUT, UPDATE_COMMON } from "../../store/types";
import { compose } from "redux";
//Modals for the firm page
import ActiveModal from "../../components/ActiveModal";
import InActiveModal from "../../components/InActiveModal";
import AddFirm from "./Add";
import EditFirm from "./Edit";
import DeleteModal from "../../components/DeleteModal";
import InvitationModal from "../../components/InvitationModal";
//Router
import { withRouter, WithRouterProps } from "../../helpers/withRouter";
//Tag manager
import TagManager from "react-gtm-module";
import Popup from "../../components/Popup";
import { Workspace } from "../../helpers/types";
import { formatDate } from "../../helpers/formatDate";
import { RootState, AppDispatch } from "../../store";
import { CommonAction } from "../../store/reducers/common";
import { NotifyType } from "../../store/reducers/notification";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Firms List"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.common,
  ...state.notification
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onLogout: () => dispatch({ type: LOGOUT }),
  updateCommon: (payload: CommonAction["payload"]) =>
    dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: NotifyType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = Partial<PropsFromRedux & WithRouterProps>;

type State = {
  firmLoading: boolean;
  posX: number;
  posY: number;
  showBackDrop: boolean;
  firmDetails: Workspace[];
  totalRecords: number;
  showAddModal: boolean;
  showEditModal: boolean;
  selectedRow: any;
  sentLoading: boolean;
  invitationSentDetails: any;
  showActiveModal: boolean;
  showInActiveModal: boolean;
  showDeleteModal: boolean;
  recieveLoading: boolean;
  showInvitationModal: boolean;
  invitationHeading: string;
  invitationType: string;
};

class Firms extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      firmLoading: false,
      posX: 0,
      posY: 0,
      showBackDrop: false,
      firmDetails: [],
      totalRecords: 0,
      showAddModal: false,
      showEditModal: false,
      selectedRow: null,
      sentLoading: false,
      invitationSentDetails: [],
      showActiveModal: false,
      showInActiveModal: false,
      showDeleteModal: false,
      recieveLoading: false,
      showInvitationModal: false,
      invitationHeading: "",
      invitationType: ""
    };
  }

  firmListHeaders = [
    "FIRM NAME",
    "OWNER'S EMAIL",
    "PLAN",
    // "Status",
    "STATUS",
    "EXPIRY DATE",
    "ACTIONS"
  ];

  firmInvitationHeaders = [
    "FIRM NAME",
    "SEND BY",
    "USER ACCESS RIGHT",
    "STATUS",
    "ACTIONS"
  ];

  firmInvitationSentHeaders = [
    "FIRM NAME",
    "SEND TO",
    "USER ACCESS RIGHT",
    "STATUS",
    "ACTIONS"
  ];

  //Get Firm Data
  getFirmData = () => {
    this.setState({ firmLoading: true });
    agent.Firm.getFirms()
      .then((response: { workspaces: Workspace[] }) => {
        this.setState({
          firmDetails: response.workspaces,
          firmLoading: false,
          totalRecords: response.workspaces.length
        });

        //filtering the active firms
        const activeFirms = response.workspaces.filter(
          (firm: any) => firm.active
        );
        //updating the active firms in redux
        this.props.updateCommon?.({
          firms: activeFirms,
          isFirmPresent: activeFirms.length > 0
        });
      })
      .catch((err: any) => {
        this.setState({ firmLoading: false });
        this.props.addNotification?.(
          "Could not load Firm Details",
          err?.response?.data?.message ||
            err?.response?.data?.error ||
            err?.message ||
            err,
          "danger"
        );
      });
  };

  getInvitaionSentList = () => {
    const workSpaceId = this.props?.currentFirm?._id;
    if (workSpaceId) {
      this.setState({ sentLoading: true });
      agent.Firm.listofInvitationSent(workSpaceId)
        .then((response: any) => {
          this.setState({
            invitationSentDetails: response.invitations,
            sentLoading: false
          });
        })
        .catch((err: any) => {
          this.setState({ sentLoading: false });
          this.props.addNotification?.(
            "Could not load Sent Invitaion Lists",
            err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            "danger"
          );
        });
    } else {
      this.setState({
        invitationSentDetails: []
      });
    }
  };

  getInvitaionsRecievedList = () => {
    getInvitaionRecievedList(
      this.props.updateCommon,
      this.props.addNotification
    );
  };

  // onMount Load data
  componentDidMount() {
    document.title = "Firms - TaxPido PMS";
    this.getFirmData();
    this.getInvitaionSentList();
    this.getInvitaionsRecievedList();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const prevFirmId = prevProps.currentFirm?._id;
    const currFirmId = this.props.currentFirm?._id;

    //RP Pending - To fetch the invitation sent list when user do not have any firm in his accounts and create a new firm and send invitation (not sure)
    if (prevFirmId === undefined && currFirmId) {
      this.getInvitaionSentList();
    }

    // To fetch the invitation sent list when the firm is changed
    if (prevFirmId !== currFirmId) {
      this.getInvitaionSentList();
    }

    //RP Pending - What we are doing here
    const prevModal = prevProps.currentModal;
    const currentModal = this.props?.currentModal;
    if (
      prevModal?.modalName === "ADD_FIRM_MODAL" &&
      prevModal?.modalName !== currentModal?.modalName &&
      currentModal?.modalName === "" &&
      currentModal?.fetchAgain
    ) {
      this.getFirmData();
    }
  }

  // Setting up the position of the menu on the screen on the basis of the mouse click
  // window.innerHeight is used to get the total height of the screen
  //clientY is used to get the vertical position of the mouse click on the screen
  onActionClick = (e: any, menuHeight: number) => {
    const screenClosness = window.innerHeight - e.clientY;
    const positionY =
      screenClosness < menuHeight ? e.clientY - menuHeight : e.clientY;
    this.setState({
      posX: e.clientX,
      posY: positionY,
      showBackDrop: true
    });
  };

  openAddFirmModal = (open: boolean) => {
    this.setState({
      showAddModal: open
    });
  };

  openEditModal = (firm: any) => {
    this.setState({ selectedRow: firm, showBackDrop: false });
    this.editModalSetOpen(true);
  };

  editModalSetOpen = (open: boolean) => {
    this.setState({
      showEditModal: open
    });
  };

  openDeleteModal = (firm: any) => {
    this.setState({ selectedRow: firm, showBackDrop: false });
    this.deleteModalSetOpen(true);
  };

  deleteModalSetOpen = (open: boolean) => {
    this.setState({
      showDeleteModal: open
    });
  };

  openActiveModal = (firm: any) => {
    this.setState({ selectedRow: firm, showBackDrop: false });
    this.activeModalSetOpen(true);
  };

  activeModalSetOpen = (open: boolean) => {
    this.setState({
      showActiveModal: open
    });
  };

  openInActiveModal = (firm: any) => {
    this.setState({ selectedRow: firm, showBackDrop: false });
    this.inActiveModalSetOpen(true);
  };

  inActiveModalSetOpen = (open: boolean) => {
    this.setState({
      showInActiveModal: open
    });
  };

  openRevokeModal = (item: any) => {
    this.setState({
      selectedRow: item,
      showBackDrop: false,
      invitationHeading: "Revoke Invitation",
      invitationType: "Revoke"
    });
    this.invitationModalSetOpen(true);
  };

  openRejectModal = (item: any) => {
    this.setState({
      selectedRow: item,
      showBackDrop: false,
      invitationHeading: "Reject Invitation",
      invitationType: "Reject"
    });
    this.invitationModalSetOpen(true);
  };

  openAcceptModal = (item: any) => {
    this.setState({
      selectedRow: item,
      showBackDrop: false,
      invitationHeading: "Accept Invitation",
      invitationType: "Accept"
    });
    this.invitationModalSetOpen(true);
  };

  openLeaveFirmModal = (item: any) => {
    this.setState({
      selectedRow: item,
      showBackDrop: false,
      invitationHeading: "Leave Firm",
      invitationType: "Leave"
    });
    this.invitationModalSetOpen(true);
  };

  invitationModalSetOpen = (open: boolean) => {
    this.setState({
      showInvitationModal: open
    });
  };

  onInvitationLoad = () => {
    const type = this.state.invitationType;
    if (type === "Revoke") {
      this.getInvitaionSentList();
    } else if (type === "Reject") {
      this.getInvitaionsRecievedList();
    } else if (type === "Accept") {
      this.getInvitaionsRecievedList();
      this.getFirmData();
    } else if (type === "Leave") {
      this.getFirmData();
    }
  };

  changeFirm = (firm: any) => {
    const currentFirm = this.props.currentFirm;
    if (currentFirm?._id === firm._id) {
      return this.props.navigate?.(`/${firm._id}/subscriptions`);
    }

    handleFirmChange(
      firm,
      false,
      this.props.updateCommon,
      this.props.addNotification
    ).then(() => {
      this.props.navigate?.(`/${firm._id}/subscriptions`);
    });
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard>
        <div className="mb-8 space-y-10">
          {this.state.showAddModal && (
            <AddFirm
              state={this.state}
              onLoad={this.getFirmData}
              openAddFirmModal={this.openAddFirmModal}
            />
          )}

          {this.state.showEditModal && (
            <EditFirm
              state={this.state}
              onLoad={this.getFirmData}
              editModalSetOpen={this.editModalSetOpen}
            />
          )}

          {this.state.showInActiveModal && (
            <InActiveModal
              type={"firm"}
              state={this.state}
              onLoad={this.getFirmData}
              inActiveModalSetOpen={this.inActiveModalSetOpen}
            />
          )}

          {this.state.showActiveModal && (
            <ActiveModal
              type={"firm"}
              state={this.state}
              onLoad={this.getFirmData}
              activeModalSetOpen={this.activeModalSetOpen}
            />
          )}

          {this.state.showDeleteModal && (
            <DeleteModal
              type={"firm"}
              state={this.state}
              onLoad={this.getFirmData}
              deleteModalSetOpen={this.deleteModalSetOpen}
            />
          )}

          {this.state.showInvitationModal && (
            <InvitationModal
              heading={this.state.invitationHeading}
              type={this.state.invitationType}
              state={this.state}
              onLoad={this.onInvitationLoad}
              invitationModalSetOpen={this.invitationModalSetOpen}
            />
          )}

          <div className="max-w-full mx-auto flex justify-between items-center lg:mx-8">
            <h1 className="text-2xl font-semibold text-gray-900">Firms</h1>
            <div>
              <button
                type="button"
                className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                onClick={() => this.openAddFirmModal(true)}
              >
                <Icon name="add" className="h-4 w-4 mr-2" />
                Add Firms
              </button>
            </div>
          </div>

          {/* Firm List */}
          <div className="max-w-full mx-auto lg:mx-8 space-y-6">
            {/* Firm List Cards */}
            <div className="sm:hidden px-2">
              {!this.state.firmLoading ? (
                this.state.totalRecords > 0 ? (
                  <ul className="space-y-4">
                    {this.state.firmDetails.map((firm: any, index: any) => (
                      <li
                        key={firm._id}
                        className="text-sm bg-white shadow-md rounded-lg"
                      >
                        <div className="px-4 py-4 relative space-y-2">
                          <span className="rounded-full bg-green-100 px-2 text-xs font-bold leading-5 text-green-800 absolute top-0 -translate-y-1/2 -right-4 shadow uppercase">
                            {/* {firm.active ? "Active" : "Inactive"} */}
                            {checkIfPlanExpired(firm) ? "Expired" : "Active"}
                          </span>
                          <div className="flex justify-between">
                            <p className="text-gray-800 font-semibold flex-grow">
                              <span className="font-bold">Name - </span>
                              {firm.isOwner ? (
                                <button
                                  className="hover:underline"
                                  onClick={() => this.openEditModal(firm)}
                                >
                                  {firm.name}
                                </button>
                              ) : (
                                <span>{firm.name}</span>
                              )}
                            </p>
                            <div className="justify-self-end">
                              <FirmOptions
                                {...this.props}
                                smallScreen={true}
                                state={this.state}
                                firm={firm}
                                changeFirm={this.changeFirm}
                                onActionClick={this.onActionClick}
                                openEditModal={this.openEditModal}
                                openInActiveModal={this.openInActiveModal}
                                openActiveModal={this.openActiveModal}
                                openDeleteModal={this.openDeleteModal}
                                openLeaveFirmModal={this.openLeaveFirmModal}
                              />
                            </div>
                          </div>
                          <p>
                            <span className="font-bold">Plan - </span>
                            <span className="capitalize text-indigo-600">
                              {firm?.type ? firm?.type : "-"}
                            </span>
                          </p>
                          <p>
                            <span className="font-bold">Email - </span>
                            <span className="break-words">{firm.owner}</span>
                          </p>
                          <p>
                            <span className="font-bold">Expiry Date - </span>
                            <span className="break-words">
                              {formatDate(
                                firm?.planExpiryDate || firm?.trialExpiryDate,
                                false
                              ) || "-"}
                            </span>
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="bg-white shadow-md rounded-lg">
                    <div className="px-4 py-4">
                      <p className="text-sm text-gray-800 text-center font-semibold">
                        No Firm
                      </p>
                    </div>
                  </div>
                )
              ) : (
                <div className="space-y-4">
                  {[...Array(5)].map((e, i) => (
                    <div
                      key={i}
                      className="bg-white shadow-md rounded-lg space-y-4"
                    >
                      <div
                        key={i}
                        className={`whitespace-wrap text-sm font-medium text-gray-900 px-4 py-4 items-center relative`}
                      >
                        <span className="px-2 absolute top-0 -translate-y-1/2 -right-4 w-24">
                          <Skeleton
                            height="1rem"
                            className="w-full bg-green-100"
                          />
                        </span>
                        {[...Array(3)].map((e, i) => (
                          <Skeleton
                            key={`firm-list-skeleton-${i}`}
                            height="1rem"
                            className="my-2"
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {/* Firm List Table */}
            <div
              id="table-scroll"
              className="hidden sm:block max-h-screen overflow-auto vertical-scroll"
            >
              <div className="inline-block min-w-full py-2 px-1 align-middle">
                <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                  <table className="min-w-full border-collapse border shadow-sm">
                    <thead className="bg-gray-50">
                      <tr>
                        {this.firmListHeaders.map((header, index) => (
                          <th
                            key={header}
                            scope="col"
                            className={`sticky top-0 z-[8] border-b whitespace-nowrap font-bold border-gray-300 bg-gray-50 px-4 py-3 text-xs text-gray-500 uppercase tracking-wider ${
                              index === 0 && "w-3/12 px-6"
                            } ${
                              index === this.firmListHeaders.length - 1
                                ? "text-center"
                                : "text-left"
                            }`}
                          >
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {!this.state.firmLoading ? (
                        this.state.totalRecords === 0 ? (
                          <tr>
                            <td
                              colSpan={this.firmListHeaders.length}
                              className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900"
                            >
                              No Firm
                            </td>
                          </tr>
                        ) : (
                          this.state.firmDetails?.map((firm, index) => (
                            <tr
                              key={firm._id}
                              className={
                                index % 2 === 0 ? undefined : "bg-gray-100"
                              }
                            >
                              <td className="max-w-[20ch] whitespace-nowrap py-4 px-6 font-bold text-sm text-gray-900">
                                <Popup
                                  content={firm.name}
                                  className="w-full max-w-fit"
                                >
                                  {firm.isOwner ? (
                                    <p
                                      className="hover:underline font-bold truncate"
                                      onClick={() => this.openEditModal(firm)}
                                    >
                                      {firm.name}
                                    </p>
                                  ) : (
                                    <p className="truncate">{firm.name}</p>
                                  )}
                                </Popup>
                              </td>
                              <td className="max-w-[22ch] px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                                <Popup
                                  content={firm.owner}
                                  className="w-full max-w-fit"
                                >
                                  <p className="truncate">{firm.owner}</p>
                                </Popup>
                              </td>
                              <td className="w-2/10 px-4 py-3 whitespace-nowrap text-sm font-bold text-gray-900">
                                {firm.type.toUpperCase()}
                              </td>
                              {/* <td className="w-2/10 px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                                {firm.active ? "Active" : "Inactive"}
                              </td> */}
                              <td className="w-2/10 px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                                {checkIfPlanExpired(firm)
                                  ? "Expired"
                                  : "Active"}
                              </td>
                              <td className="w-2/10 px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                                {formatDate(
                                  firm?.planExpiryDate || firm?.trialExpiryDate,
                                  false
                                ) || "-"}
                              </td>
                              <td className="w-2/10 px-4 py-3 text-center whitespace-nowrap text-sm text-gray-900">
                                <FirmOptions
                                  {...this.props}
                                  smallScreen={false}
                                  state={this.state}
                                  firm={firm}
                                  changeFirm={this.changeFirm}
                                  onActionClick={this.onActionClick}
                                  openEditModal={this.openEditModal}
                                  openInActiveModal={this.openInActiveModal}
                                  openActiveModal={this.openActiveModal}
                                  openDeleteModal={this.openDeleteModal}
                                  openLeaveFirmModal={this.openLeaveFirmModal}
                                />
                              </td>
                            </tr>
                          ))
                        )
                      ) : (
                        [...Array(5)].map((e, i) => (
                          <tr key={i} className="bg-white">
                            {[...Array(this.firmListHeaders.length)].map(
                              (e, i) => (
                                <td
                                  key={i}
                                  className="w-3/10 px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                >
                                  <Skeleton />
                                </td>
                              )
                            )}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/*   Firm Invitation Received */}
          <div className="max-w-full mx-auto lg:mx-8 space-y-6">
            <h1 className="text-2xl font-semibold text-gray-900">
              Firm Invitation Received
            </h1>
            {/*  Firm Invitation Received Cards*/}
            <div className="sm:hidden px-2">
              {!this.state.recieveLoading ? (
                this.props.invitationReceivedDetails &&
                this.props.invitationReceivedDetails.length > 0 ? (
                  <ul className="space-y-4">
                    {this.props.invitationReceivedDetails?.map(
                      (invitation: any, index: any) => (
                        <li
                          key={invitation._id}
                          className="text-sm bg-white shadow-md rounded-lg"
                        >
                          <div className="px-4 py-4 relative space-y-2">
                            <span className="rounded-full bg-green-100 px-2 text-xs font-bold leading-5 text-green-800 absolute top-0 -translate-y-1/2 -right-4 shadow uppercase">
                              {invitation.status}
                            </span>
                            <div className="flex justify-between">
                              <p className="text-sm text-gray-800 font-semibold">
                                <span className="font-bold">Name - </span>
                                <span>{invitation.workSpaceId.name}</span>
                              </p>
                              <div className="justify-self-end">
                                <InvitationOptions
                                  {...this.props}
                                  smallScreen={true}
                                  state={this.state}
                                  invitation={invitation}
                                  onActionClick={this.onActionClick}
                                  openAcceptModal={this.openAcceptModal}
                                  openRejectModal={this.openRejectModal}
                                />
                              </div>
                            </div>
                            <p>
                              <span className="font-bold">Role - </span>
                              <span className="capitalize text-indigo-600">
                                {invitation.userAccessRole[0].toUpperCase() +
                                  invitation.userAccessRole.slice(1)}
                              </span>
                            </p>
                            <p>
                              <span className="font-bold">Sent by - </span>
                              <span className="break-words">
                                {invitation.sentBy}
                              </span>
                            </p>
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                ) : (
                  <div className="bg-white shadow-md rounded-lg">
                    <div className="px-4 py-4">
                      <p className="text-sm text-gray-800 text-center font-semibold">
                        No Firms found
                      </p>
                    </div>
                  </div>
                )
              ) : (
                <div className="space-y-4">
                  {[...Array(5)].map((e, i) => (
                    <div
                      key={i}
                      className="bg-white shadow-md rounded-lg space-y-4"
                    >
                      <div
                        key={i}
                        className={`whitespace-wrap text-sm font-medium text-gray-900 px-4 py-4 items-center relative`}
                      >
                        <span className="px-2 absolute top-0 -translate-y-1/2 -right-4 w-24">
                          <Skeleton
                            height="1rem"
                            className="w-full bg-green-100"
                          />
                        </span>
                        {[...Array(3)].map((e, i) => (
                          <Skeleton
                            key={`firm-list-skeleton-${i}`}
                            height="1rem"
                            className="my-2"
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {/*  Firm Invitation Received Table*/}
            <div
              id="table-scroll"
              className="hidden sm:block max-h-screen overflow-auto vertical-scroll"
            >
              <div className="inline-block min-w-full py-2 px-1 align-middle">
                <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                  <table className="min-w-full border-collapse border shadow-sm">
                    <thead className="bg-gray-50">
                      <tr>
                        {this.firmInvitationHeaders.map((header, index) => (
                          <th
                            key={header}
                            scope="col"
                            className={`sticky top-0 z-[8] whitespace-nowrap border-b font-bold border-gray-300 bg-gray-50 px-4 py-3 text-xs text-gray-500 uppercase tracking-wider ${
                              index === 0 && "w-3/12 px-6"
                            } ${
                              index === this.firmInvitationHeaders.length - 1
                                ? "text-center"
                                : "text-left"
                            }`}
                          >
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {!this.state.recieveLoading ? (
                        this.props.invitationReceivedDetails?.length === 0 ? (
                          <tr>
                            <td
                              colSpan={this.firmInvitationHeaders.length}
                              className="pl-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900"
                            >
                              No Invitations Recieved
                            </td>
                          </tr>
                        ) : (
                          this.props.invitationReceivedDetails?.map(
                            (invitation: any, index: any) => (
                              <tr
                                key={invitation._id}
                                className={
                                  index % 2 === 0 ? undefined : "bg-gray-100"
                                }
                              >
                                <td className="max-w-[20ch] whitespace-nowrap py-4 px-4 font-bold text-sm text-gray-900">
                                  <Popup
                                    content={invitation.workSpaceId.name}
                                    className="w-full max-w-fit"
                                  >
                                    <p className="truncate">
                                      {invitation.workSpaceId.name}
                                    </p>
                                  </Popup>
                                </td>
                                <td className="max-w-[20ch] px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                                  <Popup
                                    content={invitation.sentBy}
                                    className="w-full max-w-fit"
                                  >
                                    <p className="truncate">
                                      {invitation.sentBy}
                                    </p>
                                  </Popup>
                                </td>
                                <td className="w-2/10 px-4 py-3 whitespace-nowrap text-sm font-bold text-gray-900">
                                  {invitation.userAccessRole[0].toUpperCase() +
                                    invitation.userAccessRole.slice(1)}
                                </td>
                                <td className="w-2/10 px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                                  {invitation.status}
                                </td>
                                <td className="w-2/10 px-4 py-3 text-center whitespace-nowrap text-sm text-gray-900">
                                  <InvitationOptions
                                    {...this.props}
                                    smallScreen={false}
                                    state={this.state}
                                    invitation={invitation}
                                    onActionClick={this.onActionClick}
                                    openAcceptModal={this.openAcceptModal}
                                    openRejectModal={this.openRejectModal}
                                  />
                                </td>
                              </tr>
                            )
                          )
                        )
                      ) : (
                        [...Array(6)].map((e, i) => (
                          <tr key={i} className="bg-white">
                            {[...Array(this.firmInvitationHeaders.length)].map(
                              (e, i) => (
                                <td
                                  key={i}
                                  className="w-3/12 px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                >
                                  <Skeleton />
                                </td>
                              )
                            )}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* User Invitation Sent */}
          <div className="max-w-full mx-auto lg:mx-8 space-y-6">
            <h1 className="text-2xl font-semibold text-gray-900">
              User Invitation Sent
            </h1>
            {/* User Invitation Sent Cards*/}
            <div className="sm:hidden px-2">
              {!this.state.sentLoading ? (
                this.state.invitationSentDetails.length > 0 ? (
                  <ul className="space-y-4">
                    {this.state.invitationSentDetails.map(
                      (invitation: any, index: any) => (
                        <li
                          key={invitation._id}
                          className="text-sm bg-white shadow-md rounded-lg"
                        >
                          <div className="px-4 py-4 relative space-y-2">
                            <span className="rounded-full bg-green-100 px-2 text-xs font-bold leading-5 text-green-800 absolute top-0 -translate-y-1/2 -right-4 shadow uppercase">
                              {invitation.status}
                            </span>
                            <div className="flex justify-between">
                              <p className="text-sm text-gray-800 font-semibold">
                                <span className="font-bold">Name - </span>
                                <span>{invitation.workSpaceId.name}</span>
                              </p>
                              <div className="justify-self-end">
                                <InvitationSentOptions
                                  {...this.props}
                                  smallScreen={true}
                                  state={this.state}
                                  invitation={invitation}
                                  onActionClick={this.onActionClick}
                                  openRevokeModal={this.openRevokeModal}
                                />
                              </div>
                            </div>
                            <p>
                              <span className="font-bold">Role - </span>
                              <span className="capitalize text-indigo-600">
                                {invitation.userAccessRole[0].toUpperCase() +
                                  invitation.userAccessRole.slice(1)}
                              </span>
                            </p>
                            <p>
                              <span className="font-bold">Sent to - </span>
                              <span className="break-words">
                                {invitation.email}
                              </span>
                            </p>
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                ) : (
                  <div className="bg-white shadow-md rounded-lg">
                    <div className="px-4 py-4">
                      <p className="text-sm text-gray-800 text-center font-semibold">
                        No Invitations Sent
                      </p>
                    </div>
                  </div>
                )
              ) : (
                <div className="space-y-4">
                  {[...Array(5)].map((e, i) => (
                    <div
                      key={i}
                      className="bg-white shadow-md rounded-lg space-y-4"
                    >
                      <div
                        key={i}
                        className={`whitespace-wrap text-sm font-medium text-gray-900 px-4 py-4 items-center relative`}
                      >
                        <span className="px-2 absolute top-0 -translate-y-1/2 -right-4 w-24">
                          <Skeleton
                            height="1rem"
                            className="w-full bg-green-100"
                          />
                        </span>
                        {[...Array(3)].map((e, i) => (
                          <Skeleton
                            key={`firm-list-skeleton-${i}`}
                            height="1rem"
                            className="my-2"
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {/* User Invitation Sent Table*/}
            <div
              id="table-scroll"
              className="hidden sm:block max-h-screen overflow-auto vertical-scroll"
            >
              <div className="inline-block min-w-full py-2 px-1 align-middle">
                <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                  <table className="min-w-full border-collapse border shadow-sm">
                    <thead className="bg-gray-50">
                      <tr>
                        {this.firmInvitationSentHeaders.map((header, index) => (
                          <th
                            key={header}
                            scope="col"
                            className={`sticky top-0 z-[8] whitespace-nowrap border-b font-bold border-gray-300 bg-gray-50 px-4 py-3 text-xs text-gray-500 uppercase tracking-wider ${
                              index === 0 && "w-3/12 px-6"
                            } ${
                              index ===
                              this.firmInvitationSentHeaders.length - 1
                                ? "text-center"
                                : "text-left"
                            }`}
                          >
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {!this.state.sentLoading ? (
                        this.state.invitationSentDetails.length === 0 ? (
                          <tr>
                            <td
                              colSpan={this.firmInvitationSentHeaders.length}
                              className="pl-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900"
                            >
                              <div className="w-fit m-0">
                                No Invitations Sent
                                <span className="invisible">hide</span>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          this.state.invitationSentDetails?.map(
                            (invitation: any, index: any) => (
                              <tr
                                key={invitation._id}
                                className={
                                  index % 2 === 0 ? undefined : "bg-gray-100"
                                }
                              >
                                <td className="max-w-[20ch] whitespace-nowrap py-4 px-6 font-bold text-sm text-gray-900">
                                  <Popup
                                    content={invitation.workSpaceId.name}
                                    className="w-full max-w-fit"
                                  >
                                    <p className="truncate">
                                      {invitation.workSpaceId.name}
                                    </p>
                                  </Popup>
                                </td>
                                <td className="max-w-[20ch] px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                                  <Popup
                                    content={invitation.email}
                                    className="w-full max-w-fit"
                                  >
                                    <p className="truncate">
                                      {invitation.email}
                                    </p>
                                  </Popup>
                                </td>
                                <td className="max-w-min px-4 py-3 whitespace-nowrap text-sm font-bold text-gray-900">
                                  {invitation.userAccessRole[0].toUpperCase() +
                                    invitation.userAccessRole.slice(1)}
                                </td>
                                <td className="w-2/10 px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                                  {invitation.status}
                                </td>
                                <td className="w-2/10 px-4 py-3 text-center whitespace-nowrap text-sm text-gray-900">
                                  <InvitationSentOptions
                                    {...this.props}
                                    smallScreen={false}
                                    state={this.state}
                                    invitation={invitation}
                                    onActionClick={this.onActionClick}
                                    openRevokeModal={this.openRevokeModal}
                                  />
                                </td>
                              </tr>
                            )
                          )
                        )
                      ) : (
                        [...Array(5)].map((e, i) => (
                          <tr key={i} className="bg-white">
                            {[
                              ...Array(this.firmInvitationSentHeaders.length)
                            ].map((e, i) => (
                              <td
                                key={i}
                                className="w-3/10 px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                              >
                                <Skeleton />
                              </td>
                            ))}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    );
  }
}

interface OptionsProps {
  smallScreen: boolean;
  state: any;
  onActionClick: any;
}

interface FirmOptionsProps extends OptionsProps {
  firm: any;
  changeFirm: (firm: any) => void;
  openEditModal: any;
  openInActiveModal: any;
  openActiveModal: any;
  openDeleteModal: any;
  openLeaveFirmModal: any;
}

class FirmOptions extends Component<FirmOptionsProps> {
  state: {
    showBackDrop: boolean;
  };
  constructor(props: any) {
    super(props);

    this.state = {
      showBackDrop: false
    };
  }

  render() {
    return (
      <Menu as="div" className="inline-block">
        <Menu.Button onClick={(e: any) => this.props.onActionClick(e, 145)}>
          <span className="sr-only">Open options</span>
          {this.props.smallScreen ? (
            <Icon name="vertical-dots" className="h-4 w-4" aria-hidden="true" />
          ) : (
            <Icon
              name="horizontal-dots"
              className="h-5 w-5"
              aria-hidden="true"
            />
          )}
        </Menu.Button>
        {this.state.showBackDrop && (
          <div
            className="fixed top-0 left-0 z-10 w-full h-screen"
            onClick={() =>
              this.setState({
                showBackDrop: false
              })
            }
          ></div>
        )}

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div
            style={{
              position: "fixed",
              top: `${this.props.state.posY}px`,
              left: `${this.props.state.posX - 230}px`,
              zIndex: 100,
              margin: "0.5rem"
            }}
          >
            <Menu.Items className="overscroll-none mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {this.props.firm.isOwner ? (
                  <>
                    <Menu.Item>
                      <button
                        className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                        onClick={() =>
                          this.props.openEditModal(this.props.firm)
                        }
                      >
                        <Icon name="edit" className="h-5 w-5 mr-2" />
                        <span>Edit</span>
                      </button>
                    </Menu.Item>
                    {/* <Menu.Item>
                      {this.props.firm.active ? (
                        <button
                          className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                          onClick={() =>
                            this.props.openInActiveModal(this.props.firm)
                          }
                        >
                          <Icon name="warning" className="h-5 w-5 mr-2" />
                          <span>Mark Inactive</span>
                        </button>
                      ) : (
                        <button
                          className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                          onClick={() =>
                            this.props.openActiveModal(this.props.firm)
                          }
                        >
                          <Icon name="warning" className="h-5 w-5 mr-2" />
                          <span>Mark Active</span>
                        </button>
                      )}
                    </Menu.Item> */}

                    <Menu.Item>
                      <button
                        className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                        onClick={() =>
                          this.props.openDeleteModal(this.props.firm)
                        }
                      >
                        <Icon name="delete" className="h-5 w-5 mr-2" />
                        <span>Delete</span>
                      </button>
                    </Menu.Item>
                  </>
                ) : (
                  <>
                    <Menu.Item>
                      <button
                        className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                        onClick={() =>
                          this.props.openLeaveFirmModal(this.props.firm)
                        }
                      >
                        <Icon name="outline/logout" className="h-5 w-5 mr-2" />
                        <span>Leave</span>
                      </button>
                    </Menu.Item>
                  </>
                )}
                <Menu.Item>
                  <button
                    onClick={() => this.props.changeFirm(this.props.firm)}
                    className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                  >
                    <Icon name="outline/card" className="h-5 w-5 mr-2" />
                    <span>Subscription</span>
                  </button>
                </Menu.Item>
              </div>
            </Menu.Items>
          </div>
        </Transition>
      </Menu>
    );
  }
}

interface InvitaionOptionsProps extends OptionsProps {
  invitation: any;
  openAcceptModal: any;
  openRejectModal: any;
}

class InvitationOptions extends Component<InvitaionOptionsProps> {
  state: {
    showBackDrop: boolean;
  };
  constructor(props: any) {
    super(props);

    this.state = {
      showBackDrop: false
    };
  }

  render() {
    return (
      <Menu as="div" className="inline-block">
        <Menu.Button onClick={(e: any) => this.props.onActionClick(e, 90)}>
          <span className="sr-only">Open options</span>
          {this.props.smallScreen ? (
            <Icon name="vertical-dots" className="h-4 w-4" aria-hidden="true" />
          ) : (
            <Icon
              name="horizontal-dots"
              className="h-5 w-5"
              aria-hidden="true"
            />
          )}
        </Menu.Button>
        {this.state.showBackDrop && (
          <div
            className="fixed top-0 left-0 z-10 w-full h-screen"
            onClick={() =>
              this.setState({
                showBackDrop: false
              })
            }
          ></div>
        )}

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div
            style={{
              position: "fixed",
              top: `${this.props.state.posY}px`,
              left: `${this.props.state.posX - 230}px`,
              zIndex: 100,
              margin: "0.5rem"
            }}
          >
            <Menu.Items className="overscroll-none mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  <button
                    className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                    onClick={() =>
                      this.props.openAcceptModal(this.props.invitation)
                    }
                  >
                    <Icon name="signin" className="h-5 w-5 mr-2" />
                    <span>Accept Invitation</span>
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                    onClick={() =>
                      this.props.openRejectModal(this.props.invitation)
                    }
                  >
                    <Icon name="x-circle" className="h-5 w-5 mr-2" />
                    <span>Reject Invitation</span>
                  </button>
                </Menu.Item>
              </div>
            </Menu.Items>
          </div>
        </Transition>
      </Menu>
    );
  }
}

//RP Pending - To be discussed with Ashutosh
interface InvitationSentOptionsProps extends OptionsProps {
  invitation: any;
  openRevokeModal: any;
}

class InvitationSentOptions extends Component<InvitationSentOptionsProps> {
  state: {
    showBackDrop: boolean;
  };
  constructor(props: any) {
    super(props);

    this.state = {
      showBackDrop: false
    };
  }

  render() {
    return (
      <Menu as="div" className="inline-block">
        <Menu.Button onClick={(e: any) => this.props.onActionClick(e, 55)}>
          <span className="sr-only">Open options</span>
          {this.props.smallScreen ? (
            <Icon name="vertical-dots" className="h-4 w-4" aria-hidden="true" />
          ) : (
            <Icon
              name="horizontal-dots"
              className="h-5 w-5"
              aria-hidden="true"
            />
          )}
        </Menu.Button>
        {this.state.showBackDrop && (
          <div
            className="fixed top-0 left-0 z-10 w-full h-screen"
            onClick={() =>
              this.setState({
                showBackDrop: false
              })
            }
          ></div>
        )}

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div
            style={{
              position: "fixed",
              top: `${this.props.state.posY}px`,
              left: `${this.props.state.posX - 230}px`,
              zIndex: 100,
              margin: "0.5rem"
            }}
          >
            <Menu.Items className="overscroll-none mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  <button
                    className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                    onClick={() =>
                      this.props.openRevokeModal(this.props.invitation)
                    }
                  >
                    <Icon name="warning" className="h-5 w-5 mr-2" />
                    <span>Revoke Invitation</span>
                  </button>
                </Menu.Item>
              </div>
            </Menu.Items>
          </div>
        </Transition>
      </Menu>
    );
  }
}

export default compose<ComponentType<Props>>(connector, withRouter)(Firms);
