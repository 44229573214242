import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Transition
} from "@headlessui/react";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/20/solid";
import React, { Fragment, ReactNode } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import agent, { LogoutType } from "../../agent";
import { adminRights } from "../../constants/defaultUserRights";
import { WithRouterProps, withRouter } from "../../helpers/withRouter";
import { ADD_NOTIFICATION, LOGOUT, UPDATE_COMMON } from "../../store/types";
import Icon from "../Icon";
import MultiSelect from "../MultiSelect";
import SearchNavigation from "../SearchNavigation";

import { todoCount, todoList } from "../../pages/Todo/Index";
import ScrollToBottom from "../ScrollToTopBottom";
import {
  ArrowDownTrayIcon,
  ArrowTopRightOnSquareIcon,
  BugAntIcon,
  ClockIcon,
  DocumentTextIcon
} from "@heroicons/react/24/outline";
import { formatDate, formatDateAndTime } from "../../helpers/formatDate";
import ErrorBoundary from "../ErrorBoundry";
import MessageBar from "../MessageBar";
import { Invitation, User, Workspace } from "../../helpers/types";
import Message from "../Message";
import { AppDispatch, RootState } from "../../store";
import { CommonAction } from "../../store/reducers/common";
import { NotifyType } from "../../store/reducers/notification";

const menuItems = (firmId: string) => {
  const list = todoList.map(item => {
    return {
      name: item.name || "Default",
      iconName: "outline/document-text",
      route: `/${firmId}/todo/${item._id}`
    };
  });

  return [
    {
      name: "Dashboard",
      iconName: "outline/home",
      route: `/${firmId}/dashboard`
    },
    {
      name: "Tasks",
      iconName: "outline/document-text",
      route: `/${firmId}/tasks/list`,
      children: [
        {
          name: "Add Task",
          iconName: "outline/document-add",
          route: `/${firmId}/tasks/add`
        },
        {
          name: "Task List",
          iconName: "outline/document-text",
          route: `/${firmId}/tasks/list`
        }
        // {
        // 	name: "Return Task Overview",
        // 	iconName: "outline/document-text",
        // 	route: `/${firmId}/tasks/return-task-overview`,
        // },
      ]
    },
    {
      name: "Clients",
      iconName: "outline/users",
      route: `/${firmId}/clients/list`,
      children: [
        {
          name: "Add Client",
          iconName: "outline/user-plus",
          route: `/${firmId}/clients/add`
        },
        {
          name: "All Clients",
          iconName: "outline/users",
          route: `/${firmId}/clients/list`
        },
        {
          name: "Import",
          iconName: "outline/user-plus",
          route: `/${firmId}/clients/import`
        },
        {
          name: "Bulk Update",
          iconName: "edit",
          route: `/${firmId}/clients/bulk-edit`
        },
        {
          name: "QRMP Prefrence",
          iconName: "outline/clipboard-list",
          route: `/${firmId}/clients/qrmp`
        }
      ]
    },
    {
      name: "Contact Person",
      iconName: "outline/user",
      route: `/${firmId}/contact-person/list`,
      children: [
        {
          name: "All Contact Person",
          iconName: "outline/users",
          route: `/${firmId}/contact-person/list`
        },
        {
          name: "Import",
          iconName: "outline/user-plus",
          route: `/${firmId}/contact-person/import`
        },
        {
          name: "Bulk Update",
          iconName: "edit",
          route: `/${firmId}/contact-person/bulk-edit`
        },
        {
          name: "DSC Status",
          iconName: "outline/document-text",
          route: `/${firmId}/contact-person/dsc-status?dscExpiryDate=expired&dscPassword=all`
        }
      ]
    },
    {
      name: "To Do List",
      iconName: "outline/document-text",
      route: `/${firmId}/todo/list/starred`,
      children: [
        {
          name: `Starred (${todoCount?.["starred"] ?? ""})`,
          iconName: "outline/star",
          route: `/${firmId}/todo/list/starred`
        },
        {
          name: `Today (${todoCount?.["today"] ?? ""})`,
          iconName: "outline/calendar",
          route: `/${firmId}/todo/list/today`
        },
        {
          name: `Overdue (${todoCount?.["overdue"] ?? ""})`,
          iconName: "outline/calendar",
          route: `/${firmId}/todo/list/overdue`
        },
        {
          name: `Week (${todoCount?.["next7Days"] ?? ""})`,
          iconName: "outline/calendar",
          route: `/${firmId}/todo/list/next7Days`
        },
        {
          name: `Without Due Date (${todoCount?.["withoutDueDate"] ?? ""})`,
          iconName: "outline/calendar",
          route: `/${firmId}/todo/list/withoutDueDate`
        },
        ...list
      ]
    },
    {
      name: "Receipts & Payments",
      iconName: "outline/card",
      route: `/${firmId}/receipts-payments/list`,
      children: [
        {
          name: "Receipts & Payments",
          iconName: "outline/card",
          route: `/${firmId}/receipts-payments/list`
        },
        {
          name: "Sources List",
          iconName: "outline/document-text",
          route: `/${firmId}/receipts-payments/source`
        },
        {
          name: "Category List",
          iconName: "outline/document-text",
          route: `/${firmId}/receipts-payments/category`
        }
      ]
    },
    {
      name: "Register in out",
      iconName: "outline/document-text",
      route: `/${firmId}/register-in-out/list`,
      children: [
        {
          name: "Register in out",
          iconName: "outline/document-text",
          route: `/${firmId}/register-in-out/list`
        },
        {
          name: "Document List",
          iconName: "outline/document-text",
          route: `/${firmId}/register-in-out/document`
        },
        {
          name: "Kept At List",
          iconName: "outline/document-text",
          route: `/${firmId}/register-in-out/kept-at`
        }
      ]
    },
    {
      name: "Bulk Messages",
      iconName: "outline/chat",
      route: `/${firmId}/bulk-messages`,
      children: [
        {
          name: "Bulk Messages",
          iconName: "outline/chat",
          route: `/${firmId}/bulk-messages`
        },
        {
          name: "Templates",
          iconName: "outline/document-text",
          route: `/${firmId}/bulk-message-template/list`
        }
      ]
    },
    {
      name: "Settings",
      iconName: "outline/settings",
      children: [
        {
          name: "Custom Field",
          iconName: "outline/document-add",
          route: `/${firmId}/custom-field/list`
        },
        {
          name: "Groups",
          iconName: "outline/group",
          route: `/${firmId}/groups/list`
        },
        {
          name: "Recuring Task",
          iconName: "outline/document-text",
          route: `/${firmId}/recurring-task/list/gst`
        },
        {
          name: "Status",
          iconName: "outline/document-text",
          route: `/${firmId}/status/list`
        },
        {
          name: "Tags",
          iconName: "outline/tag",
          route: `/${firmId}/tags/list`
        },
        {
          name: "User",
          iconName: "outline/user-plus",
          route: `/${firmId}/user/list`
        }
      ]
    },
    {
      name: "Reports",
      iconName: "outline/document-text",
      route: `/${firmId}/reports/list`
    }
  ];
};

type LinkProps = { onlyIcon?: boolean };

const BugReportLink = ({ onlyIcon }: LinkProps) => {
  return (
    <>
      {!onlyIcon && <hr className="my-4" />}
      <a
        href="https://forms.gle/P84dmjXdZ3UxrqwVA"
        target="_blank"
        rel="noopener noreferrer"
        className={`text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center text-xs font-medium rounded-md ${
          !onlyIcon && "px-2 py-2 gap-x-3"
        }`}
      >
        <BugAntIcon className="text-gray-400 flex-shrink-0 h-5 w-5" />
        {!onlyIcon && (
          <>
            <span>Bug report / Contact Support</span>
            <ArrowTopRightOnSquareIcon className="text-gray-400 flex-shrink-0 h-4 w-4 ml-auto" />
          </>
        )}
      </a>
    </>
  );
};

const ExtensionDownloadLink = ({ onlyIcon }: LinkProps) => {
  return (
    <a
      href="https://chromewebstore.google.com/detail/taxpido-pms/hpggipmkpnpdnojoojjpifijbgojhceb"
      target="_blank"
      rel="noopener noreferrer"
      className={`text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center text-xs font-medium rounded-md mb-10 ${
        !onlyIcon && "px-2 py-2 gap-x-3"
      }`}
    >
      <ArrowDownTrayIcon className="text-gray-400 flex-shrink-0 h-5 w-5" />
      {!onlyIcon && (
        <>
          <span>New PMS Extension</span>
          <ArrowTopRightOnSquareIcon className="text-gray-400 flex-shrink-0 h-4 w-4 ml-auto" />
        </>
      )}
    </a>
  );
};

const OtherLinks = ({ onlyIcon }: LinkProps) => {
  return (
    <div
      className={`absolute bottom-0 inset-x-0 px-4 flex ${
        onlyIcon ? "justify-center" : "justify-between"
      } gap-4`}
    >
      <a
        href="https://taxpido.com/pms-change-log/"
        target="_blank"
        rel="noopener noreferrer"
        className={`text-gray-300 hover:underline group flex items-center text-[0.6rem] font-medium rounded-md ${
          !onlyIcon ? "px-2 py-3 gap-x-3" : "py-2"
        }`}
      >
        {!onlyIcon && (
          <>
            <DocumentTextIcon className="text-gray-400 flex-shrink-0 h-4 w-4" />
            Change Log
          </>
        )}
      </a>
      <a
        href="https://taxpido.com/pms-roadmap/"
        target="_blank"
        rel="noopener noreferrer"
        className={`text-gray-300 hover:underline group flex items-center text-[0.6rem] font-medium rounded-md ${
          !onlyIcon ? "px-2 py-3 gap-x-3" : "py-2"
        }`}
      >
        {!onlyIcon && (
          <>
            <DocumentTextIcon className="text-gray-400 flex-shrink-0 h-4 w-4" />
            PMS Roadmap
          </>
        )}
      </a>
    </div>
  );
};

export const getAllStatusList = (
  workSpaceId: string,
  updateCommon: any,
  onNotify: any
) => {
  const searchText = "";
  const active = true;
  const limit = 100000;
  const skip = 0;
  if (workSpaceId) {
    agent.Status.getStatusList(workSpaceId, active, searchText, limit, skip)
      .then((response: any) => {
        const status = response.status;
        const tasks: string[] = [];
        status.forEach((item: any) => tasks.push(...item.tasks));
        const uniqueTasks = Array.from(new Set(tasks));
        const taskWiseStatus: any = {};
        uniqueTasks.forEach((name: string) => {
          const list = status.filter(
            (item: any) => item.tasks.includes(name) || item.tasks.length === 0
          );
          taskWiseStatus[name] = list;
        });
        const statusApplicableToAllTasks = status.filter(
          (item: any) => item.tasks.length === 0
          // tasks.length === 0 means status applicable for all task
        );
        updateCommon({
          status,
          statusApplicableToAllTasks,
          taskStatus: taskWiseStatus
        });
        //console.log("taskStatus", taskWiseStatus);
      })
      .catch((err: any) => {
        onNotify(
          "Could not fetch Status list",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  }
};

export const getAllTagsList = (
  workSpaceId: string,
  updateCommon: any,
  onNotify: any
) => {
  const limit = 100000;
  const skip = 0;
  agent.Tag.getTagList(workSpaceId, true, "", limit, skip)
    .then((response: any) => {
      updateCommon({
        tags: response.tags.map(({ _id, name, description }: any) => ({
          _id,
          name,
          description
        }))
      });
    })
    .catch((err: any) => {
      onNotify(
        "Could not load Tags List",
        err?.response?.data?.message || err?.message || err,
        "danger"
      );
    });
};

export const getAllUsersList = (
  workSpaceId: string,
  updateCommon: any,
  onNotify: any
) => {
  const active = true;
  const includeCurrent = true;
  agent.User.getUserList(workSpaceId, active, "", includeCurrent)
    .then((response: any) => {
      updateCommon({
        users: response.users.map(
          ({ _id, name, shortname, email, mobileNumber, role }: any) => ({
            _id,
            name,
            email,
            mobileNumber,
            role,
            shortname
          })
        )
      });
    })
    .catch((err: any) => {
      onNotify(
        "Could not load Users List",
        err?.response?.data?.message || err?.message || err,
        "danger"
      );
    });
};

export const getSelfDetails = (
  workSpaceId: string,
  updateCommon: any,
  onNotify: any
) => {
  agent.User.getSelfDetails(workSpaceId)
    .then((response: any) => {
      updateCommon({
        currentUser: response.user
      });
    })
    .catch((err: any) => {
      onNotify(
        "Could not load User Details",
        err?.response?.data?.message || err?.message || err,
        "danger"
      );
    });
};

export const removeRedirectStateFromLocalStorage = () => {
  localStorage.removeItem("redirectedToInvitation");
};

export const getInvitaionRecievedList = (
  updateCommon?: (payload: CommonAction["payload"]) => void,
  onNotify?: (title: string, message: string, type: NotifyType) => void,
  navigate?: (path: string) => void
) => {
  agent.Firm.listofInvitationReceived()
    .then(({ invitations }: { invitations: Invitation[] }) => {
      updateCommon?.({ invitationReceivedDetails: invitations });
      if (
        invitations.length > 0 /*  &&
        localStorage.getItem("redirectedToInvitation") !== "true" */
      ) {
        navigate?.("/firms");
        // localStorage.setItem("redirectedToInvitation", "true");
      }
    })
    .catch(err => {
      onNotify?.(
        "Could not load Received Invitaions List",
        err?.response?.data?.message ||
          err?.response?.data?.error ||
          err?.message ||
          err,
        "danger"
      );
    });
};

export const getUserRights = (
  workSpaceId: string | undefined,
  updateCommon: any,
  onNotify: any
) => {
  if (workSpaceId) {
    agent.User.getUserRights(workSpaceId)
      .then((response: any) => {
        // console.log({ righstsss: response });
        if (response.hasOwnProperty("allRights") && response.allRights) {
          updateCommon({ rights: adminRights });
        } else {
          updateCommon({ rights: response.rights });
        }
      })
      .catch((err: any) => {
        onNotify(
          "Could not fetch user rights",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  }
};

export const getCurrentlyTrackingTime = (
  workSpaceId: string,
  updateCommon: any,
  onNotify: any
) => {
  agent.TimeTracking.currentlyWorking(workSpaceId)
    .then((res: any) => {
      updateCommon({
        timeTrackingRunning: res.allRecords[0]?.isRunning ?? false
      });
    })
    .catch((err: any) => {
      onNotify(
        "Could not fetch records",
        typeof err?.response?.data?.message === "object"
          ? "Something went wrong"
          : err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message,
        "danger"
      );
    });
};

export const getAllFirms = (
  updateCommon: ((payload: CommonAction["payload"]) => void) | undefined,
  addNotification:
    | ((title: string, message: string, type: NotifyType) => void)
    | undefined,
  navigate: ((path: string) => void) | undefined
) => {
  agent.Firm.getFirms()
    .then((response: { workspaces: Workspace[] }) => {
      const activeFirms = response.workspaces.filter(firm => firm.active);
      // response.workspaces.filter returns an array of objects of workspace/firms and returns an empty array if there are no active firms

      updateCommon?.({
        firms: activeFirms,
        isFirmPresent: activeFirms.length > 0
        // isFirmPresent is true if there are active firms and false if there are no active firms
      });
      //this.setFirm();
      // moved to componentDidUpdate
      if (activeFirms.length === 0) {
        navigate?.("/firms");
      }
    })
    .catch(err => {
      addNotification?.(
        "Could not fetch Firms",
        err?.response?.data?.message || err?.message || err,
        "danger"
      );
    });
};

export const handleFirmChange = (
  item: any,
  onLoad: boolean,
  updateCommon: any,
  addNotification: any
) => {
  return new Promise(resolve => {
    updateCommon({ currentFirm: item });
    localStorage.setItem("currentFirm", JSON.stringify(item ?? "{}"));
    !onLoad &&
      addNotification("Firm Changed", `Current Firm ${item.name}`, "success");
    resolve(true);
  });
};

export const checkIfPlanExpired = (currentFirm?: Workspace) => {
  const currentPlanExpired =
    currentFirm &&
    (currentFirm?.planExpiryDate > currentFirm?.trialExpiryDate
      ? currentFirm?.planExpiryDate &&
        new Date(currentFirm.planExpiryDate).getTime() < new Date().getTime()
      : currentFirm?.trialExpiryDate &&
        new Date(currentFirm?.trialExpiryDate).getTime() <
          new Date().getTime());

  return currentPlanExpired;
};

export const handleLogout = (
  type: LogoutType,
  sessionId: string | undefined,
  onSuccess?: () => void,
  onError?: (message: string) => void
) => {
  agent.Auth.logout({ type, sessionId })
    .then(() => {
      onSuccess?.();
    })
    .catch(err => {
      const errMessage = err?.response?.data?.message || err?.message || err;
      if (errMessage === "Invalid token!") {
        return onSuccess?.();
      }

      onError?.(errMessage);
    });
};

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.common,
  ...state.notification
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onLogout: () => dispatch({ type: LOGOUT }),
  updateCommon: (payload: CommonAction["payload"]) =>
    dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: NotifyType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface DashboardProps extends Partial<PropsFromRedux & WithRouterProps> {
  children: ReactNode;
}

interface State {
  menuShow: boolean;
  loading: boolean;
  menuState: any;
  subMenuState: any;
  profileMenuShow: boolean;
  notifications: any;
  unreadNotifications: number;
  shrinkMenu: boolean;
  shrinkMenuHover: boolean;
  infoFooterHeight: number;
  showInfoFooter: boolean;
  planExpiringInFifteenDays: boolean;
  planExpiryMessage: string;
}

class Dashboard extends React.Component<DashboardProps, State> {
  infoFooterRef: React.RefObject<HTMLDivElement>;
  constructor(props: DashboardProps) {
    super(props);
    this.infoFooterRef = React.createRef();

    this.state = {
      loading: false,
      menuShow: false,
      menuState: {},
      subMenuState: {},
      profileMenuShow: false,
      shrinkMenu: true,
      shrinkMenuHover: false,
      notifications: [],
      unreadNotifications: 0,
      infoFooterHeight: 0,
      showInfoFooter: true,
      planExpiringInFifteenDays: false,
      planExpiryMessage: ""
    };
  }

  showHideInfoFooter = () => {
    this.setState({ showInfoFooter: !this.state.showInfoFooter });
  };

  markAsRead = (id: any) => {
    this.setState({ loading: true });
    const workSpaceId = (this.props as any).params.firmId;
    agent.Notifications.markAsRead(workSpaceId, id)
      .then((res: any) => {
        this.getNotificationsList();
        this.props.addNotification?.(
          "Notification marked as read successfully",
          res.message,
          "success"
        );
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        this.props.addNotification?.(
          "Could not mark notification as read",
          err?.response?.data?.error || err?.message || err,
          "danger"
        );
      });
  };

  getNotificationsList = () => {
    this.setState({ loading: true });
    const workSpaceId =
      (this.props as any)?.params?.firmId ||
      (this.props as any)?.currentFirm?._id;
    agent.Notifications.listInAppNotifications(workSpaceId, false, 0, 5)
      .then((res: any) => {
        this.setState({
          notifications: res.notifications,
          unreadNotifications: res.count,
          loading: false
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        this.props.addNotification?.(
          "Could not fetch notifications",
          err?.response?.data?.error || err?.message || err,
          "danger"
        );
      });
  };

  onFirmChange = (firm: any, onload: boolean) =>
    handleFirmChange(
      firm,
      onload,
      (this.props as any).updateCommon,
      this.props.addNotification
    );

  //Function to set the firm in user account on login as well as on firm change
  setFirm = () => {
    const firmId = (this.props as any).params?.firmId;
    const firms = (this.props as any).firms;
    // const currentFirmId = (this.props as any).currentFirm?._id;
    const localCurrentFirm = JSON.parse(
      localStorage.getItem("currentFirm") ?? "{}"
    );
    if (!firmId && firms?.length > 0) {
      const selectedLocalFirm = firms.find(
        (firm: any) => firm._id === localCurrentFirm._id
      );
      if (selectedLocalFirm) {
        this.onFirmChange(selectedLocalFirm, true);
      } else {
        this.onFirmChange(firms[0], true);
      }
    } else if (firmId) {
      // This is for when the user is coming on any page other than firms page
      const selectFirm = firms.find((firm: any) => firm._id === firmId);
      // selectFirm is undefined if the firmId is not present in the firms array
      selectFirm
        ? this.onFirmChange(selectFirm, true)
        : (this.props as any).navigate("/firms");
    }
  };

  getUserRights = () => {
    const workSpaceId =
      (this.props as any).params?.firmId ||
      (this.props as any).currentFirm?._id;
    if (workSpaceId) {
      agent.User.getUserRights(workSpaceId)
        .then((response: any) => {
          // console.log({ righstsss: response });
          if (response.hasOwnProperty("allRights") && response.allRights) {
            (this.props as any).updateCommon({ rights: adminRights });
          } else {
            (this.props as any).updateCommon({ rights: response.rights });
          }
        })
        .catch((err: any) => {
          this.props.addNotification?.(
            "Could not fetch user rights",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    }
  };

  menuListToggle = () => {
    const firmId = (this.props as any).params?.firmId;
    if (firmId) {
      let childrenMenus: any = [];
      menuItems(firmId).forEach((item: any) => {
        if (item.children) {
          item.children.forEach((child: any) => {
            const subMenu = { name: item.name, route: child.route };
            childrenMenus.push(subMenu);
          });
        }
      });
      const path = (this.props as any).location.pathname;
      const isSubMenu = childrenMenus.find((item: any) => item.route === path);
      if (isSubMenu) {
        this.setState({ menuState: { [isSubMenu.name]: true } });
      }
    }
  };

  // fullWidthPages = (pathname: string, firmId: string) =>
  //   pathname === `/${firmId}/tasks/list` ||
  //   pathname.includes("client-profile") ||
  //   pathname.includes("group-profile") ||
  //   (pathname.includes("/reports") && pathname.includes("-report")) ||
  //   pathname.includes("bulk-edit") ||
  //   pathname.includes("recurring-task/list") ||
  //   pathname.includes("subscription") ||
  //   pathname.includes("clients/list");

  // toShrinkPage = () => {
  //   const pathname = (this.props as any).location.pathname;
  //   const firmId =
  //     (this.props as any).params?.firmId ||
  //     (this.props as any).currentFirm?._id;
  //   if (this.fullWidthPages(pathname, firmId)) {
  //     this.setState({ shrinkMenu: true });
  //   }
  // };

  fetchOnLoad = () => {
    const workSpaceId =
      (this.props as any).params?.firmId ||
      (this.props as any).currentFirm?._id;

    getInvitaionRecievedList(
      this.props.updateCommon,
      this.props.addNotification,
      this.props.navigate
    );

    getUserRights(
      workSpaceId,
      (this.props as any).updateCommon,
      (this.props as any).onNotify
    );
    getAllStatusList(
      (this.props as any).currentFirm._id,
      (this.props as any).updateCommon,
      this.props.addNotification
    );
    getAllTagsList(
      (this.props as any).currentFirm._id,
      (this.props as any).updateCommon,
      this.props.addNotification
    );
    getAllUsersList(
      (this.props as any).currentFirm._id,
      (this.props as any).updateCommon,
      this.props.addNotification
    );
    getSelfDetails(
      (this.props as any).currentFirm._id,
      (this.props as any).updateCommon,
      this.props.addNotification
    );
    getCurrentlyTrackingTime(
      (this.props as any).currentFirm._id,
      (this.props as any).updateCommon,
      this.props.addNotification
    );
    this.getNotificationsList();
  };

  componentDidMount = () => {
    const firmId = (this.props as any).params?.firmId;
    if (!(this.props as any)?.isFirmPresent) {
      getAllFirms(
        this.props.updateCommon,
        this.props.addNotification,
        this.props.navigate
      );
    }
    if (
      this.props.currentFirm?._id &&
      checkIfPlanExpired(this.props.currentFirm)
    ) {
      this.setPlanExpiryMessage();
    }
    this.updateRoute();
    // this.toShrinkPage();
    this.menuListToggle();

    firmId && firmId.length === 24 && this.getNotificationsList();

    this.setState({
      infoFooterHeight: this.infoFooterRef.current
        ? this.infoFooterRef.current?.clientHeight
        : 0
    });
  };

  componentDidUpdate = (prevProps: any, prevState: any) => {
    if (
      prevProps.currentFirm?._id === undefined &&
      (this.props as any)?.currentFirm?._id
    ) {
      this.fetchOnLoad();
      //console.log("props", this.props); (we have to debug this as props don't get updated on firm change)
    }

    if (prevProps.firms !== (this.props as any).firms) {
      this.setFirm();
    }

    if (
      prevProps.currentFirm?._id !== (this.props as any)?.currentFirm?._id &&
      (this.props as any)?.currentFirm?._id
    ) {
      this.updatePathName();
      this.setPlanExpiryMessage();
    }

    const prevFirmId = prevProps.params.firmId;
    const currFirmId = (this.props as any).params.firmId;
    if (prevFirmId !== currFirmId && currFirmId.length === 24) {
      this.setFirm();
      this.fetchOnLoad();
    }

    const prevPathname = prevProps.location.pathname;
    const currPathname = (this.props as any).location.pathname;
    if (prevPathname !== currPathname) {
      this.menuListToggle();
      this.setState({ menuShow: false });
    }
    if (
      // this.infoFooterRef.current &&
      prevState.showInfoFooter !== this.state.showInfoFooter
    ) {
      this.setState({
        infoFooterHeight: this.state.showInfoFooter
          ? this.infoFooterRef.current?.clientHeight || 0
          : 0
      });
    }
  };

  setPlanExpiryMessage = () => {
    const currentFirm: Workspace = (this.props as any).currentFirm;
    const currentUser: User = (this.props as any).currentUser;

    let planExpiringInFifteenDays = false;
    let planExpiringInThreeDays = false;
    let planExpiryDate;
    let planExpiryMessage = "";

    if (currentFirm?.planExpiryDate) {
      planExpiringInFifteenDays =
        new Date(currentFirm.planExpiryDate).getTime() <
          new Date().getTime() + 15 * 24 * 60 * 60 * 1000 &&
        new Date(currentFirm.planExpiryDate).getTime() >= new Date().getTime();

      planExpiringInThreeDays =
        new Date(currentFirm.planExpiryDate).getTime() <
          new Date().getTime() + 3 * 24 * 60 * 60 * 1000 &&
        new Date(currentFirm.planExpiryDate).getTime() >= new Date().getTime();

      planExpiryDate = new Date(currentFirm.planExpiryDate);
    } else if (currentFirm?.trialExpiryDate) {
      planExpiringInFifteenDays =
        new Date(currentFirm.trialExpiryDate).getTime() <
          new Date().getTime() + 15 * 24 * 60 * 60 * 1000 &&
        new Date(currentFirm.trialExpiryDate).getTime() >= new Date().getTime();

      planExpiringInThreeDays =
        new Date(currentFirm.trialExpiryDate).getTime() <
          new Date().getTime() + 3 * 24 * 60 * 60 * 1000 &&
        new Date(currentFirm.trialExpiryDate).getTime() >= new Date().getTime();

      planExpiryDate = new Date(currentFirm.trialExpiryDate);
    }

    if (checkIfPlanExpired(currentFirm)) {
      planExpiryMessage = `Your subscription has already expired on ${formatDate(
        planExpiryDate,
        false
      )}. Click here to renew.`;
    } else if (
      currentFirm.isOwner ||
      (currentUser?.role &&
        (currentUser.role === "Super Admin" || currentUser.role === "admin"))
        ? planExpiringInFifteenDays
        : planExpiringInThreeDays
    ) {
      planExpiryMessage = `Your subscription is going to expire on ${formatDate(
        planExpiryDate,
        false
      )}. Click here to renew.`;
    }

    this.setState({ planExpiryMessage });
  };

  sendExpiryNotification = () => {
    this.props.addNotification?.(
      "Plan Expired",
      "Your plan has expired. Please renew or Upgrade your plan to continue using the application.",
      "danger"
    );
  };

  moveToSubscriptionPage = () => {
    const currentFirm: Workspace = (this.props as any).currentFirm;

    if (checkIfPlanExpired(currentFirm)) {
      this.sendExpiryNotification();
    }
    if (window.location.href.includes("/paymentresponse")) return;
    this.props.navigate?.(`/${currentFirm._id}/subscriptions`, {
      replace: true
    });
  };

  updatePathName = () => {
    const { currentFirm, location, params, navigate } = this.props;

    if (checkIfPlanExpired(currentFirm)) {
      return this.moveToSubscriptionPage();
    }

    const firmId = currentFirm?._id || "";

    if (
      location?.pathname === "/paymentresponse" ||
      location?.pathname === "/firms"
    )
      return;

    const prevParam = params?.firmId || "";
    const paramLength = prevParam?.length;
    let newPath = location?.pathname;
    const searchQuery = location?.search;

    if (newPath && !newPath.includes(firmId)) {
      newPath =
        "/" + firmId + newPath.substring(paramLength ? paramLength + 1 : 0);
    }

    const newSearchQuery = newPath?.includes("/dashboard") ? "" : searchQuery;

    newPath && navigate?.(newPath + newSearchQuery, { replace: true });
  };

  updateRoute = () => {
    let url = (this.props as any).location.pathname;
    let paramLength = (this.props as any).params?.firmId?.length;
    let path = url.substring(paramLength + 1);
    const selectedFirmId = (this.props as any).currentFirm?._id;

    let menuState: any = {};
    let subMenuState: any = {};
    menuItems(selectedFirmId).forEach((menuItem: any) => {
      if (path.indexOf(menuItem.route) === 0) {
        menuState[menuItem.name] = true;
      }
      menuItem.children?.forEach((subItem: any) => {
        if (path.indexOf(subItem.route) === 0) {
          subMenuState[subItem.name] = true;
        }
      });
    });
    this.setState({ menuState, subMenuState });
  };

  toggleDropdown = (name: any) => {
    if (this.state.menuState[name]) {
      this.setState({ menuState: {} });
    } else {
      this.setState({ menuState: { [name]: true } });
    }
  };

  toggleSubItemDropdown = (name: any) => {
    if (this.state.subMenuState[name]) {
      this.setState({ subMenuState: {} });
    } else {
      this.setState({ subMenuState: { [name]: true } });
    }
  };

  openModalHandler = (modalName: string) => {
    (this.props as any).updateCommon({
      currentModal: { modalName, fetchAgain: false }
    });
  };

  openTimeTrackingModal = () => {
    (this.props as any).updateCommon({
      currentModal: { modalName: "TIME_TRACKING_MODAL" }
    });
  };

  toggleMenu = () => {
    this.setState({ menuShow: !this.state.menuShow });
  };

  toggleProfileMenu = () => {
    this.setState({ profileMenuShow: !this.state.profileMenuShow });
  };

  noFirmClickHandler = (route: string) => {
    const currentFirm: Workspace = (this.props as any).currentFirm;
    if (!this.state.loading) {
      if (this.linkRouteDirect(route) === route) return;
      if (this.linkRouteDirect(route) === "/firms") {
        this.setState({ menuShow: false });
        (this.props as any).updateCommon({
          currentModal: { modalName: "ADD_FIRM_MODAL", fetchAgain: false }
        });
      }
      if (this.linkRouteDirect(route) === `/${currentFirm._id}/subscriptions`) {
        this.sendExpiryNotification();
      }
    }
  };

  linkRouteDirect = (route: string) => {
    const currentFirm: Workspace = (this.props as any).currentFirm;
    const firmsCount = (this.props as any).firms
      ? (this.props as any).firms?.length
      : 0;

    if (checkIfPlanExpired(currentFirm)) {
      return `/${currentFirm._id}/subscriptions`;
    } else if (firmsCount === 0) {
      return "/firms";
    } else {
      return route;
    }
  };

  onLogoutSuccess = () => {
    this.props.onLogout?.();
    localStorage.removeItem("currentFirm");
    this.props.updateCommon?.({
      firms: [],
      currentFirm: undefined,
      isFirmPresent: false,
      status: [],
      taskStatus: []
    });

    this.props.addNotification?.(
      "Successful",
      "You have been successfully logged out.",
      "success"
    );
    this.props.navigate?.("/");
  };

  logout = () => {
    handleLogout(
      "current",
      undefined,
      this.onLogoutSuccess,
      (message: string) =>
        this.props.addNotification?.("Couldn't Logout", message, "danger")
    );
  };

  onShrinkMenuHover = () => {
    this.setState({
      shrinkMenu: !this.state.shrinkMenu,
      shrinkMenuHover: !this.state.shrinkMenuHover
    });
  };

  shrinkedMenuPages = () => {
    return this.state.shrinkMenu;
  };

  render() {
    return (
      <div>
        <div
          style={
            {
              height: `calc(100vh - ${this.state.infoFooterHeight}px)`
            } as React.CSSProperties
          }
          className="flex overflow-hidden bg-gray-100"
        >
          {/* <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. --> */}

          <Transition.Root show={this.state.menuShow} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-40 md:hidden"
              onClose={this.toggleMenu}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>

              <div className="fixed inset-0 flex z-40">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                          type="button"
                          className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                          onClick={this.toggleMenu}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XMarkIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 flex items-center px-4">
                      <img
                        className="h-8 m-auto w-auto"
                        src="/images/Taxpido_logo_white.png"
                        alt="Taxpido logo"
                      />
                    </div>
                    <div className="mt-5 flex-1 max-h-[calc(100vh-7rem)] overflow-y-auto vertical-scroll">
                      <nav className="px-2 space-y-1">
                        <div className="sm:hidden mt-4 pb-4 mb-2 border-b border-white">
                          {((this.props as any).params.firmId ||
                            (this.props as any).location.pathname ===
                              "/firms") &&
                            (this.props as any).firms?.length > 1 && (
                              <div className="w-full px-3">
                                <MultiSelect
                                  items={(this.props as any).firms?.map(
                                    (firm: any) => {
                                      return {
                                        ...firm,
                                        name: firm.name
                                      };
                                    }
                                  )}
                                  selected={{
                                    name: (this.props as any).currentFirm?.name
                                  }}
                                  type="firms"
                                  onChange={selected =>
                                    this.onFirmChange(selected, false)
                                  }
                                  placeholder="Select Firm"
                                />
                              </div>
                            )}
                        </div>
                        {menuItems((this.props as any).currentFirm?._id).map(
                          (menuItem: any) =>
                            !menuItem.children ? (
                              <div key={menuItem.name}>
                                <Link
                                  to={this.linkRouteDirect(menuItem.route)}
                                  key={menuItem.name}
                                  className={`group flex items-center px-2 py-2 text-sm font-medium rounded-md text-white ${
                                    (this.props as any).location.pathname ===
                                    menuItem.route
                                      ? "bg-gray-900"
                                      : "text-gray-300 hover:text-white hover:bg-gray-700"
                                  }`}
                                  onClick={() =>
                                    this.noFirmClickHandler(menuItem.route)
                                  }
                                >
                                  <Icon
                                    name={menuItem.iconName}
                                    className={
                                      this.state.menuState[menuItem.name]
                                        ? "text-gray-500 mr-3 flex-shrink-0 h-5 w-5"
                                        : "text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-5 w-5"
                                    }
                                    aria-hidden="true"
                                  />
                                  {menuItem.name}
                                </Link>
                              </div>
                            ) : (
                              <div key={menuItem.name}>
                                <Disclosure
                                  as="div"
                                  key={menuItem.name}
                                  className="space-y-1"
                                >
                                  {({ open }) => (
                                    <div
                                      onClick={() =>
                                        this.toggleDropdown(menuItem.name)
                                      }
                                    >
                                      <Disclosure.Button
                                        as={Link}
                                        to={this.linkRouteDirect(
                                          menuItem.route ? menuItem.route : ""
                                        )}
                                        onClick={() =>
                                          this.noFirmClickHandler(
                                            menuItem.route
                                          )
                                        }
                                        className="text-gray-300 w-full justify-between hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                      >
                                        <span className="flex items-center">
                                          <Icon
                                            name={menuItem.iconName}
                                            className="mr-3 flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                            aria-hidden="true"
                                          />
                                          {menuItem.name}
                                        </span>
                                        <ChevronDownIcon
                                          className={
                                            !this.state.menuState[menuItem.name]
                                              ? "text-gray-400 -rotate-90 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                              : "text-gray-300 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                          }
                                        />
                                      </Disclosure.Button>
                                      {this.state.menuState[menuItem.name] ? (
                                        <Disclosure.Panel
                                          className="space-y-1 ml-11 border-l border-gray-700"
                                          static
                                        >
                                          {menuItem.children.map(
                                            (subItem: any) =>
                                              !subItem.children ? (
                                                <div key={subItem.name}>
                                                  <Link
                                                    to={this.linkRouteDirect(
                                                      subItem.route
                                                    )}
                                                    key={subItem.name}
                                                    className={
                                                      (this.props as any)
                                                        .location.pathname ===
                                                      subItem.route
                                                        ? "bg-gray-900 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                                        : "text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                                    }
                                                    onClick={() =>
                                                      this.noFirmClickHandler(
                                                        menuItem.route
                                                      )
                                                    }
                                                  >
                                                    <Icon
                                                      name={subItem.iconName}
                                                      className={
                                                        this.state.menuState[
                                                          menuItem.name
                                                        ]
                                                          ? "text-gray-500 mr-3 flex-shrink-0 h-5 w-5"
                                                          : "text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-5 w-5"
                                                      }
                                                    />
                                                    {subItem.name}
                                                  </Link>
                                                </div>
                                              ) : (
                                                <div key={subItem.name}>
                                                  <Disclosure
                                                    as="div"
                                                    key={subItem.name}
                                                    className="space-y-1"
                                                  >
                                                    {({ open }) => (
                                                      <div
                                                        onClick={() =>
                                                          this.toggleSubItemDropdown(
                                                            subItem.name
                                                          )
                                                        }
                                                      >
                                                        <Disclosure.Button className="text-gray-300 w-full justify-between hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                                                          <span className="flex items-center">
                                                            {subItem.name}
                                                          </span>
                                                          <ChevronDownIcon
                                                            className={
                                                              !this.state
                                                                .subMenuState[
                                                                subItem.name
                                                              ]
                                                                ? "text-gray-400 -rotate-90 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                                                : "text-gray-300 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                                            }
                                                          />
                                                        </Disclosure.Button>
                                                        {this.state
                                                          .subMenuState[
                                                          subItem.name
                                                        ] ? (
                                                          <Disclosure.Panel
                                                            className="space-y-1 ml-6 border-l border-gray-700"
                                                            static
                                                          >
                                                            {subItem.children.map(
                                                              (
                                                                subSubItem: any
                                                              ) => (
                                                                <Link
                                                                  to={this.linkRouteDirect(
                                                                    subSubItem.route
                                                                  )}
                                                                  key={
                                                                    subSubItem.name
                                                                  }
                                                                  className={
                                                                    (
                                                                      this
                                                                        .props as any
                                                                    ).location
                                                                      .pathname ===
                                                                    subSubItem.route
                                                                      ? "bg-gray-900 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                                                      : "text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                                                  }
                                                                  onClick={() =>
                                                                    this.noFirmClickHandler(
                                                                      menuItem.route
                                                                    )
                                                                  }
                                                                >
                                                                  {
                                                                    subSubItem.name
                                                                  }
                                                                </Link>
                                                              )
                                                            )}
                                                          </Disclosure.Panel>
                                                        ) : null}
                                                      </div>
                                                    )}
                                                  </Disclosure>
                                                </div>
                                              )
                                          )}
                                        </Disclosure.Panel>
                                      ) : null}
                                    </div>
                                  )}
                                </Disclosure>
                              </div>
                            )
                        )}
                        <BugReportLink />
                        <ExtensionDownloadLink />
                      </nav>
                    </div>
                    <OtherLinks />
                  </Dialog.Panel>
                </Transition.Child>
                <div className="flex-shrink-0 w-14" aria-hidden="true">
                  {/* Dummy element to force sidebar to shrink to fit close icon */}
                </div>
              </div>
            </Dialog>
          </Transition.Root>

          {/* <!-- Static sidebar for desktop --> */}
          <>
            {this.state.shrinkMenu ? (
              <div
                className="fixed inset-y-0 z-50 transition-[width] ease-in-out duration-300 hidden md:flex md:flex-col md:gap-5 p-4 bg-gray-800 w-16"
                onMouseEnter={this.onShrinkMenuHover}
              >
                <div className="h-12 mb-1">
                  <Icon name="point-right" className="h-6 w-6 text-gray-400" />
                </div>
                {menuItems((this.props as any).currentFirm?._id).map(
                  (item, index) => (
                    <div key={index + item.iconName}>
                      <Icon
                        name={item.iconName}
                        className={
                          "text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-5 w-5"
                        }
                        aria-hidden="true"
                      />
                    </div>
                  )
                )}
                <BugReportLink onlyIcon />
                <ExtensionDownloadLink onlyIcon />
                <OtherLinks onlyIcon />
              </div>
            ) : (
              <div
                className="hidden md:flex md:flex-shrink-0 fixed inset-0 h-full z-50 w-64"
                onMouseLeave={this.onShrinkMenuHover}
              >
                <div className="flex flex-col w-64 bg-gray-800">
                  {/* <!-- Sidebar component, swap this element with another sidebar if you like --> */}
                  <div className="grid grid-rows-[4rem,auto,3rem] min-h-screen relative">
                    <div className="flex items-center h-16 flex-shrink-0 px-4 bg-gray-900">
                      <img
                        className="h-8 m-auto w-auto"
                        src="/images/Taxpido_logo_white.png"
                        alt="Taxpido logo"
                      />
                    </div>
                    <div className="flex-1 flex flex-col overflow-y-auto vertical-scroll">
                      <nav className="flex-1 px-2 py-4 space-y-1">
                        {menuItems((this.props as any).currentFirm?._id).map(
                          (menuItem: any) =>
                            !menuItem.children ? (
                              <div key={menuItem.name}>
                                <Link
                                  to={this.linkRouteDirect(menuItem.route)}
                                  key={menuItem.name}
                                  className={`group flex items-center px-2 py-2 text-sm font-medium rounded-md text-white ${
                                    (this.props as any).location.pathname ===
                                    menuItem.route
                                      ? "bg-gray-900"
                                      : "text-gray-300 hover:text-white hover:bg-gray-700"
                                  }`}
                                  onClick={() =>
                                    this.noFirmClickHandler(menuItem.route)
                                  }
                                >
                                  <Icon
                                    name={menuItem.iconName}
                                    className={
                                      this.state.menuState[menuItem.name]
                                        ? "text-gray-500 mr-3 flex-shrink-0 h-5 w-5"
                                        : "text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-5 w-5"
                                    }
                                    aria-hidden="true"
                                  />
                                  {menuItem.name}
                                </Link>
                              </div>
                            ) : (
                              <div key={menuItem.name}>
                                <Disclosure
                                  as="div"
                                  key={menuItem.name}
                                  className="space-y-1"
                                >
                                  {({ open }) => (
                                    <div>
                                      <div
                                        onClick={() =>
                                          this.toggleDropdown(menuItem.name)
                                        }
                                      >
                                        <Disclosure.Button
                                          as={Link}
                                          to={this.linkRouteDirect(
                                            menuItem.route ? menuItem.route : ""
                                          )}
                                          onClick={() =>
                                            this.noFirmClickHandler(
                                              menuItem.route
                                            )
                                          }
                                          className="text-gray-300 w-full justify-between hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                        >
                                          <span className="flex items-center">
                                            <Icon
                                              name={menuItem.iconName}
                                              className="mr-3 flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                              aria-hidden="true"
                                            />
                                            {menuItem.name}
                                          </span>
                                          <ChevronDownIcon
                                            className={
                                              !this.state.menuState[
                                                menuItem.name
                                              ]
                                                ? "text-gray-400 -rotate-90 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                                : "text-gray-300 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                            }
                                          />
                                        </Disclosure.Button>
                                      </div>
                                      {this.state.menuState[menuItem.name] ? (
                                        <Disclosure.Panel
                                          className="space-y-1 ml-11 border-l border-gray-700"
                                          static
                                        >
                                          {menuItem.children.map(
                                            (subItem: any) =>
                                              !subItem.children ? (
                                                <div key={subItem.name}>
                                                  <Link
                                                    to={this.linkRouteDirect(
                                                      subItem.route
                                                    )}
                                                    key={subItem.name}
                                                    className={
                                                      (this.props as any)
                                                        .location.pathname ===
                                                      subItem.route
                                                        ? "bg-gray-900 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                                        : "text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                                    }
                                                    onClick={() =>
                                                      this.noFirmClickHandler(
                                                        menuItem.route
                                                      )
                                                    }
                                                  >
                                                    <Icon
                                                      name={subItem.iconName}
                                                      className={
                                                        this.state.menuState[
                                                          menuItem.name
                                                        ]
                                                          ? "text-gray-500 mr-3 flex-shrink-0 h-5 w-5"
                                                          : "text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-5 w-5"
                                                      }
                                                    />
                                                    {subItem.name}
                                                  </Link>
                                                </div>
                                              ) : (
                                                <div key={subItem.name}>
                                                  <Disclosure
                                                    as="div"
                                                    key={subItem.name}
                                                    className="space-y-1"
                                                  >
                                                    {({ open }) => (
                                                      <div
                                                        onClick={() =>
                                                          this.toggleSubItemDropdown(
                                                            subItem.name
                                                          )
                                                        }
                                                      >
                                                        <Disclosure.Button className="text-gray-300 w-full justify-between hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                                                          <span className="flex items-center">
                                                            <Icon
                                                              name={
                                                                subItem.iconName
                                                              }
                                                              className="mr-3 flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                              aria-hidden="true"
                                                            />
                                                            {subItem.name}
                                                          </span>
                                                          <ChevronDownIcon
                                                            className={
                                                              !this.state
                                                                .subMenuState[
                                                                subItem.name
                                                              ]
                                                                ? "text-gray-400 -rotate-90 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                                                : "text-gray-300 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                                            }
                                                          />
                                                        </Disclosure.Button>
                                                        {this.state
                                                          .subMenuState[
                                                          subItem.name
                                                        ] ? (
                                                          <Disclosure.Panel
                                                            className="space-y-1 ml-6 border-l border-gray-700"
                                                            static
                                                          >
                                                            {subItem.children.map(
                                                              (
                                                                subSubItem: any
                                                              ) => (
                                                                <Link
                                                                  to={this.linkRouteDirect(
                                                                    subSubItem.route
                                                                  )}
                                                                  key={
                                                                    subSubItem.name
                                                                  }
                                                                  className={
                                                                    (
                                                                      this
                                                                        .props as any
                                                                    ).location
                                                                      .pathname ===
                                                                    subSubItem.route
                                                                      ? "bg-gray-900 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                                                      : "text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                                                  }
                                                                  onClick={() =>
                                                                    this.noFirmClickHandler(
                                                                      menuItem.route
                                                                    )
                                                                  }
                                                                >
                                                                  {
                                                                    subSubItem.name
                                                                  }
                                                                </Link>
                                                              )
                                                            )}
                                                          </Disclosure.Panel>
                                                        ) : null}
                                                      </div>
                                                    )}
                                                  </Disclosure>
                                                </div>
                                              )
                                          )}
                                        </Disclosure.Panel>
                                      ) : null}
                                    </div>
                                  )}
                                </Disclosure>
                              </div>
                            )
                        )}
                        <BugReportLink onlyIcon={this.state.shrinkMenu} />
                        <ExtensionDownloadLink
                          onlyIcon={this.state.shrinkMenu}
                        />
                      </nav>
                    </div>
                    <OtherLinks onlyIcon={this.state.shrinkMenu} />
                  </div>
                </div>
              </div>
            )}
          </>

          <div className="flex flex-col w-0 flex-1 overflow-hidden">
            <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
              <button
                className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none md:hidden"
                onClick={this.toggleMenu}
              >
                <span className="sr-only">Open sidebar</span>
                <Icon
                  name="outline/menu-alt-2"
                  className="h-6 w-6"
                  onClick={this.toggleMenu}
                />
              </button>
              <div className="md:ml-16 flex-1 px-4 flex items-center">
                <div className="w-full flex items-center justify-end md:justify-between gap-4">
                  <div className="flex items-center gap-4 w-full justify-between">
                    <div className="flex items-center gap-4">
                      {((this.props as any).params.firmId ||
                        (this.props as any).location.pathname === "/firms") &&
                        (this.props as any).firms?.length > 1 && (
                          <div className="hidden sm:block ml-4 w-48 lg:w-64">
                            <MultiSelect
                              items={(this.props as any).firms?.map(
                                (firm: any) => {
                                  return {
                                    ...firm,
                                    name: firm.name
                                  };
                                }
                              )}
                              selected={{
                                name: (this.props as any).currentFirm?.name
                              }}
                              type="firms"
                              onChange={selected =>
                                this.onFirmChange(selected, false)
                              }
                              placeholder="Select Firm"
                            />
                          </div>
                        )}
                      <SearchNavigation
                        openModalHandler={this.openModalHandler}
                      />
                    </div>
                    <button
                      type="button"
                      className="relative"
                      onClick={this.openTimeTrackingModal}
                    >
                      <ClockIcon
                        className="h-7 w-7 text-gray-700"
                        aria-hidden="true"
                      />
                      {(this.props as any).timeTrackingRunning && (
                        <div className="w-3 h-3 bg-green-500 rounded-full animate-ping absolute top-0 right-0"></div>
                      )}
                      <div
                        className={`w-3 h-3 ${
                          (this.props as any).timeTrackingRunning
                            ? "bg-green-500"
                            : "bg-red-500"
                        } rounded-full absolute top-0 right-0`}
                      ></div>
                    </button>
                  </div>
                  <div className="flex items-center gap-4 md:gap-8">
                    {/* <Popover className="relative">
                      <Popover.Button>
                        <span className="sr-only">View notifications</span>
                        <Icon
                          name="outline/bell"
                          className="h-6 w-6 stroke-gray-500"
                        />
                        {this.state.unreadNotifications > 0 && (
                          <>
                            <div className="w-3 h-3 bg-green-500 rounded-full animate-ping absolute top-0 right-0"></div>
                            <div className="w-3 h-3 bg-green-500 rounded-full absolute top-0 right-0"></div>
                          </>
                        )}
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute right-0 z-10 mt-6 min-w-[22rem] max-w-sm sm:px-0 bg-white rounded-lg">
                          <div className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5">
                            <div className="rounded-lg transition ease-in-out duration-150 px-3 py-3 grid gap-4 max-h-[80vh] vertical-scroll overflow-auto">
                              <div className="divide-y-4 divide-white">
                                {this.state.unreadNotifications > 0 ? (
                                  this.state.notifications.map(
                                    (notification: any) => {
                                      return (
                                        <div
                                          key={notification._id}
                                          className={`text-sm space-y-2 py-3 px-3 hover:bg-gray-100 ${
                                            notification.read
                                              ? ""
                                              : "bg-gray-50"
                                          }`}
                                        >
                                          <div className="space-y-1">
                                            <p className="text-gray-900 font-medium">
                                              {notification.title}
                                            </p>
                                            <p className="text-xs">
                                              {notification.description}
                                            </p>
                                          </div>
                                          <div className="flex items-center justify-between">
                                            <p className="text-xs text-gray-500">
                                              {formatDateAndTime(
                                                notification.timestamp
                                              )}
                                            </p>
                                            {!notification.read && (
                                              <button
                                                className="text-xs text-right place-self-end whitespace-nowrap px-2 py-1 focus-visible:outline-indigo-600 rounded border border-transparent hover:outline hover:outline-2 hover:outline-indigo-600"
                                                onClick={() => {
                                                  this.markAsRead(
                                                    notification._id
                                                  );
                                                }}
                                              >
                                                Mark as Read
                                              </button>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )
                                ) : (
                                  <div className="text-center py-3 px-3">
                                    <p className="text-sm text-gray-900 font-medium">
                                      You are all caught up! No new
                                      notifications here.
                                    </p>
                                  </div>
                                )}
                              </div>
                              <div className="mx-2 mb-2 flex">
                                <Popover.Button
                                  as={Link}
                                  to={`/${
                                    (this.props as any).currentFirm?._id
                                  }/notification/list`}
                                  className="ml-auto px-4 inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 text-sm font-medium text-white hover:bg-indigo-700 disabled:bg-indigo-700 focus:outline-none focus:bg-indigo-700"
                                >
                                  View All
                                </Popover.Button>
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </Popover> */}

                    {/* <!-- Profile dropdown --> */}
                    <Menu as="div" className="inline-block relative">
                      <Menu.Button>
                        <div
                          className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none"
                          id="user-menu-button"
                          aria-expanded="false"
                          aria-haspopup="true"
                          onClick={this.toggleProfileMenu}
                        >
                          <span className="sr-only">Open user menu</span>
                          <span className="w-8 h-8 p-2 rounded-full cursor-pointer inline-flex items-center justify-center bg-gray-700 text-white mr-3 text-sm xs:text-base font-bold">
                            {(this.props as any)?.currentUser?.shortname}
                          </span>
                        </div>
                      </Menu.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <div>
                          <Menu.Items className="origin-top-right absolute right-0 mt-5 w-56 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                <Link
                                  to={`/${
                                    (this.props as any).currentFirm?._id
                                  }/profile/general`}
                                  className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                  role="menuitem"
                                  tabIndex={-1}
                                  id="user-menu-item-0"
                                >
                                  <Icon
                                    name="outline/user"
                                    className="h-5 w-5 mr-2 text-gray-700"
                                  />
                                  <span>Your Profile</span>
                                </Link>
                              </Menu.Item>
                              <Menu.Item>
                                <Link
                                  to="/firms"
                                  className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                  role="menuitem"
                                  tabIndex={-1}
                                  id="user-menu-item-0"
                                >
                                  <Icon
                                    name="building-office-2"
                                    className="h-5 w-5 mr-2 text-gray-700"
                                  />
                                  <span>Your Firms</span>
                                </Link>
                              </Menu.Item>
                              <Menu.Item>
                                <Link
                                  to={this.linkRouteDirect(
                                    `/${
                                      (this.props as any).currentFirm?._id
                                    }/subscriptions`
                                  )}
                                  className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                  role="menuitem"
                                  tabIndex={-1}
                                  id="user-menu-item-0"
                                >
                                  <Icon
                                    name="outline/card"
                                    className="h-5 w-5 mr-2 text-gray-700"
                                  />
                                  <span>Subscription</span>
                                </Link>
                              </Menu.Item>
                              <Menu.Item>
                                <button
                                  className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                  role="menuitem"
                                  tabIndex={-1}
                                  id="user-menu-item-0"
                                  onClick={this.logout}
                                >
                                  <Icon
                                    name="outline/logout"
                                    className="h-5 w-5 mr-2 text-gray-700"
                                  />
                                  <span>Sign out</span>
                                </button>
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </div>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>

            <main className="flex-1 relative overflow-y-auto overflow-x-hidden focus:outline-none">
              <div className="py-6 md:ml-20">
                <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
                  <ScrollToBottom
                    id="dashboard-page"
                    bottomOffset={this.state.infoFooterHeight}
                  >
                    <ErrorBoundary type="dashboard">
                      {this.state.planExpiryMessage && (
                        <Message
                          message={this.state.planExpiryMessage}
                          fullWidth={this.state.shrinkMenu}
                          closeIcon={
                            !checkIfPlanExpired(this.props.currentFirm)
                          }
                          color="red"
                          onClick={() => this.moveToSubscriptionPage()}
                          onClose={() =>
                            this.setState({ planExpiryMessage: "" })
                          }
                        />
                      )}
                      {this.props.children}
                    </ErrorBoundary>
                  </ScrollToBottom>
                </div>
              </div>
            </main>
          </div>
        </div>
        <div ref={this.infoFooterRef} className={`fixed md:relative`}>
          <MessageBar
            showInfoFooter={this.state.showInfoFooter}
            showHideInfoFooter={this.showHideInfoFooter}
            infoFooterHeight={this.state.infoFooterHeight}
          />
        </div>
      </div>
    );
  }
}

export default compose(
  connector,
  withRouter
)(Dashboard) as React.ComponentType<DashboardProps>;
