import { ChangeEvent, Component, ComponentType } from "react";
import { compose } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { UPDATE_COMMON, ADD_NOTIFICATION } from "../../../store/types";
import { WithRouterProps, withRouter } from "../../../helpers/withRouter";
import TagManager from "react-gtm-module";
import Dashboard from "../../../components/Dashboard";
import { ContactPerson, Email, Mobile } from "../../../helpers/types";
import Skeleton from "react-loading-skeleton";
import Icon from "../../../components/Icon";
import Popup from "../../../components/Popup";
import { formatDate } from "../../../helpers/formatDate";
import { emailTableContent, mobileTableContent } from "../Index";
import agent from "../../../agent";
import { AxiosError } from "axios";
import MultiSelect from "../../../components/MultiSelect";
import Pagination from "../../../components/Pagination";
import EditPersonModal from "../../ContactPerson/Edit";
import { downloadFile } from "../../../helpers/downloadFile";
import Button from "../../../components/Button";
import { CommonAction } from "../../../store/reducers/common";
import { NotifyType } from "../../../store/reducers/notification";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Contact Person DSC Status"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateCommon: (payload: CommonAction["payload"]) =>
    dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: NotifyType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const setSearchParams = (key: string, value: string) => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set(key, value);
  const newSearchParams = searchParams.toString();
  window.history.pushState(null, "", `?${newSearchParams}`);
};

type Props = PropsFromRedux & WithRouterProps;

const dscExpiryDateOptions = [
  { name: "All", _id: "" },
  { name: "available in contact details", _id: "present" },
  { name: "not available in contact details", _id: "notPresent" },
  { name: "expired", _id: "expired" },
  { name: "not Expired", _id: "notExpired" },
  { name: "expiring in 7 Days", _id: "expiring7Days" },
  { name: "expiring in 15 Days", _id: "expiring15Days" },
  { name: "expiring in 30 Days", _id: "expiring30Days" }
] as const;

const dscPasswordOptions = [
  { name: "All", _id: "" },
  { name: "available in contact details", _id: "present" },
  { name: "not available in contact details", _id: "notPresent" }
] as const;

export type DscExpiryDateOptionItem =
  (typeof dscExpiryDateOptions)[number]["_id"];
export type DscPasswordOptionItem = (typeof dscPasswordOptions)[number]["_id"];
export type DscExpiryDateOption = (typeof dscExpiryDateOptions)[number];
export type DscPasswordOption = (typeof dscPasswordOptions)[number];

interface State {
  loading: boolean;
  exporting: boolean;
  active: boolean;
  searchText: string;
  selectedDscExpiryDate: DscExpiryDateOption;
  selectedDscPassword: DscPasswordOption;
  contactPersonList: ContactPerson[];
  totalRecords: number;
  typingTimeout: NodeJS.Timeout | null;
  skip: number;
  currPage: number;
  chunkSize: number;
  selectedRow: ContactPerson | null;
  showEditModal: boolean;
}

class DSCStatus extends Component<Props, State> {
  state: State = {
    loading: false,
    exporting: false,
    active: true,
    searchText: "",
    selectedDscExpiryDate: dscExpiryDateOptions[1],
    selectedDscPassword: dscPasswordOptions[0],
    contactPersonList: [],
    totalRecords: 0,
    typingTimeout: null,
    skip: 0,
    currPage: 0,
    chunkSize: 25,
    selectedRow: null,
    showEditModal: false
  };

  headers = [
    "CONTACT NAME",
    "EMAIL",
    "MOBILE",
    "DSC EXPIRY DATE",
    "DSC PASSWORD"
  ];

  handlePageClick = (data: { selected: number }) => {
    this.setState({ currPage: data.selected }, () => {
      this.state.searchText ? this.getDscList(true) : this.getDscList();
    });
  };

  handleItemPerPage = (value: { name: number }) => {
    this.setState({ chunkSize: value.name, currPage: 0 }, () =>
      this.state.searchText ? this.getDscList(true) : this.getDscList()
    );
  };

  componentDidMount() {
    document.title = "DSC Status - TaxPido PMS";

    this.getQueryParams();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevState.active !== this.state.active ||
      prevState.selectedDscExpiryDate !== this.state.selectedDscExpiryDate ||
      prevState.selectedDscPassword !== this.state.selectedDscPassword
    ) {
      this.getDscList();
    }
  }

  getQueryParams = () => {
    const queryParams = this.props.searchParams.entries();
    if (queryParams) {
      const query: Partial<State> = {};
      for (const [key, value] of queryParams) {
        switch (key) {
          case "dscExpiryDate":
            query["selectedDscExpiryDate"] =
              dscExpiryDateOptions.find(item => item._id === value) ??
              dscExpiryDateOptions[1];
            break;
          case "dscPassword":
            query["selectedDscPassword"] =
              dscPasswordOptions.find(item => item._id === value) ??
              dscPasswordOptions[0];
            break;
        }
      }
      this.setState({ ...this.state, ...query }, () => this.getDscList());
    }
  };

  getDscList = (forSearch = false, download = false) => {
    const workSpaceId = this.props.params.firmId;
    const searchText = forSearch ? this.state.searchText : "";
    const active = this.state.active;
    let limit = this.state.chunkSize;
    let skip = this.state.chunkSize * this.state.currPage;
    const { selectedDscExpiryDate, selectedDscPassword } = this.state;

    download
      ? this.setState({ exporting: true })
      : this.setState({ loading: true });

    if (!workSpaceId) {
      return this.props.addNotification?.(
        "Could not fetch DSC Status List",
        "Workspace ID not found",
        "danger"
      );
    }

    agent.ContactPerson.getDscList(
      workSpaceId,
      active,
      skip,
      limit,
      searchText,
      selectedDscExpiryDate._id,
      selectedDscPassword._id,
      download
    )
      .then((res: { contactPerson: ContactPerson[]; count: number }) => {
        if (download) {
          this.setState({ exporting: false });
          downloadFile(res, "TaxAdda PMS DSC Status Report.xlsx");
          (this.props as any).addNotification(
            "DSC Status Report Downloaded Successfully",
            "DSC Status Report Downloaded Successfully",
            "success"
          );
        } else {
          this.setState({
            loading: false,
            contactPersonList: res.contactPerson,
            totalRecords: res.count
          });
        }
      })
      .catch((err: AxiosError<{ message?: string; error?: string }>) => {
        download
          ? this.setState({ exporting: false })
          : this.setState({ loading: false });
        (this.props as any).addNotification(
          "Could not fetch DSC Status List",
          typeof err?.response?.data?.message === "object"
            ? "Something went wrong"
            : err?.response?.data?.message ||
                err?.response?.data?.error ||
                err?.message,
          "error"
        );
      });
  };

  handleDscExpiryDateChange = (value: DscExpiryDateOption) => {
    this.setState({ selectedDscExpiryDate: value });
    setSearchParams("dscExpiryDate", value._id ? value._id : "all");
  };

  handleDscPasswordChange = (value: DscPasswordOption) => {
    this.setState({ selectedDscPassword: value });
    setSearchParams("dscPassword", value._id ? value._id : "all");
  };

  handleSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      searchText: e.target.value,
      currPage: 0,
      typingTimeout: setTimeout(() => {
        this.getDscList(true);
      }, 700)
    });
  };

  editModalSetOpen = (value: boolean) => {
    this.setState({ showEditModal: value });
  };

  onContactPersonClick = (contactPerson: ContactPerson) => {
    this.setState({ selectedRow: contactPerson, showEditModal: true });
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard>
        {this.state.showEditModal && (
          <EditPersonModal
            state={this.state}
            editModalSetOpen={this.editModalSetOpen}
          />
        )}
        <div className="max-w-full mx-auto lg:mx-8">
          <div className="flex items-center justify-between gap-4">
            <h1 className="text-2xl font-semibold text-gray-900">DSC Status</h1>
            <div className="block sm:hidden">
              <Button
                name="Export"
                icon={this.state.exporting ? "loading" : "outline/download"}
                onClick={() => this.getDscList(false, true)}
              />
            </div>
          </div>

          <p className="mt-3 text-xs">
            <span className="font-bold">Note</span> -{" "}
            <span className="italic">
              We are considering only those contacts which either have a DSC
              Expiry date or DSC Password for the purpose of this listing.
            </span>
          </p>

          <div className="flex gap-4 items-end justify-between mt-6">
            <div className="flex items-center gap-4">
              <MultiSelect
                items={[...dscExpiryDateOptions]}
                selected={this.state.selectedDscExpiryDate}
                type="dscExpiryDate"
                onChange={this.handleDscExpiryDateChange}
                placeholder="Select DSC Expiry Date"
                heading="DSC Expiry Date"
              />
              <MultiSelect
                items={[...dscPasswordOptions]}
                selected={this.state.selectedDscPassword}
                type="dscPassword"
                onChange={this.handleDscPasswordChange}
                placeholder="Select DSC Password"
                heading="DSC Password"
              />
            </div>

            <div className="hidden sm:block">
              <Button
                name="Export"
                icon={this.state.exporting ? "loading" : "outline/download"}
                onClick={() => this.getDscList(false, true)}
              />
            </div>
          </div>
          <div>
            {(this.state.totalRecords > 0 ||
              this.state.searchText.length > 0) && (
              <div className="w-64 sm:w-80 mx-auto mt-6">
                <input
                  id="search"
                  name="search"
                  type="search"
                  value={this.state.searchText}
                  placeholder="Search by Name, Email, or Mobile"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 text-sm"
                  onChange={this.handleSearchTextChange}
                />
              </div>
            )}
          </div>
          {!this.state.loading && this.state.contactPersonList ? (
            this.state.totalRecords > 0 ||
            this.state.searchText.length > 0 ||
            this.state.active === false ? (
              <div className="mt-6 flex flex-col">
                <div id="table-scroll" className="overflow-auto">
                  <div className="inline-block min-w-full py-2 px-1 align-middle">
                    <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                      <table className="min-w-full border-collapse border shadow-sm">
                        <thead className="bg-gray-50">
                          <tr>
                            {this.headers.map(header => (
                              <th
                                key={header}
                                scope="col"
                                className="border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider whitespace-nowrap"
                              >
                                {header}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        {(this.state.active === false &&
                          this.state.totalRecords === 0) ||
                        (this.state.searchText &&
                          this.state.totalRecords === 0) ? (
                          <tbody>
                            <tr>
                              <td className="odd:font-bold px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                                {this.state.searchText &&
                                  "No record found matching your search criteria"}

                                {this.state.active === false &&
                                  this.state.searchText === "" &&
                                  "No record of inactive contact person"}
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody className="bg-white">
                            {this.state.contactPersonList?.map(person => (
                              <tr key={person._id} className="odd:bg-gray-100">
                                <td className="odd:font-bold max-w-[20ch] whitespace-nowrap py-4 px-4 text-sm text-gray-900">
                                  <Popup
                                    content={person.name}
                                    className="w-full max-w-fit"
                                  >
                                    <p
                                      className="hover:underline font-bold truncate"
                                      onClick={() =>
                                        this.onContactPersonClick(person)
                                      }
                                    >
                                      {person.name}
                                    </p>
                                  </Popup>
                                </td>
                                <td className="odd:font-bold min-w-[30ch] px-4 py-3 whitespace-nowrap text-sm text-gray-500 relative">
                                  {person.email.length > 0 ? (
                                    <Popup
                                      content={emailTableContent(
                                        person.email,
                                        "popup"
                                      ).map((email: Email, i: number) => (
                                        <p
                                          key={`${email}-${i}`}
                                          className="my-1"
                                        >
                                          {email.emailId}
                                        </p>
                                      ))}
                                      className="flex gap-2 w-full max-w-fit"
                                    >
                                      <p className="hover:underline cursor-pointer truncate">
                                        {person.email.length === 0
                                          ? ""
                                          : emailTableContent(
                                              person.email,
                                              "primary"
                                            ).emailId}
                                      </p>
                                      {emailTableContent(person.email, "count")}
                                    </Popup>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                <td className="odd:font-bold max-w-fit whitespace-nowrap py-4 px-4 text-sm text-gray-900">
                                  {person.mobile.length > 0 ? (
                                    <Popup
                                      content={mobileTableContent(
                                        person.mobile,
                                        "popup"
                                      ).map((mobile: Mobile, i: number) => (
                                        <p
                                          key={`${mobile}-${i}`}
                                          className="my-1"
                                        >
                                          {mobile.mobileNumber}
                                        </p>
                                      ))}
                                      className="flex gap-2 w-full max-w-fit"
                                    >
                                      <p className="hover:underline cursor-pointer">
                                        {person.mobile.length === 0
                                          ? ""
                                          : mobileTableContent(
                                              person.mobile,
                                              "primary"
                                            ).mobileNumber}
                                      </p>
                                      {mobileTableContent(
                                        person.mobile,
                                        "count"
                                      )}
                                    </Popup>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                <td className="odd:font-bold max-w-fit whitespace-nowrap py-4 px-4 text-sm text-gray-900">
                                  {person.dscExpiryDate
                                    ? formatDate(person.dscExpiryDate, false)
                                    : "-"}
                                </td>
                                <td className="odd:font-bold max-w-fit whitespace-nowrap py-4 px-4 text-sm text-gray-900">
                                  {person.dscPassword || "-"}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-center w-full max-w-2xl mx-auto my-10 border-2 border-gray-300 border-dashed p-16 rounded-lg">
                <Icon
                  name="outline/document-add"
                  className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                  strokeWidth="1"
                />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  No Record Found
                </h3>
              </div>
            )
          ) : (
            <div className="py-6">
              <div className="flex flex-col">
                <div className="-my-1 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-4 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                      <table className="overflow-scroll table-auto w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            {this.headers.map(header => (
                              <th
                                key={header}
                                scope="col"
                                className="w-2/12 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                              >
                                {header}
                              </th>
                            ))}
                          </tr>
                        </thead>

                        <tbody>
                          {[...Array(5)].map((e, i) => (
                            <tr key={i} className="bg-white">
                              {[...Array(5)].map((e, i) => (
                                <td
                                  key={i}
                                  className="w-3/10 px-4 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                >
                                  <Skeleton />
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <Pagination
          displayRecords={this.state.contactPersonList}
          totalRecords={this.state.totalRecords}
          chunkSize={this.state.chunkSize}
          currPage={this.state.currPage}
          handlePageClick={this.handlePageClick}
          handleItemPerPage={this.handleItemPerPage}
          className="my-4"
        />
      </Dashboard>
    );
  }
}

export default compose(connector, withRouter)(DSCStatus) as ComponentType;
