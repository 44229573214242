import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import {
  ChangeEvent,
  Component,
  ComponentType,
  FocusEvent,
  FormEvent
} from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import agent from "../agent";
import Icon from "../components/Icon";
import MessageModal from "../components/MessageModal";
import config from "../config";
import routes from "../constants/routes";
import { isWebView } from "../helpers/deviceDetails";
import { verifyRecaptchaAndProceed } from "../helpers/recaptcha";
import { validEmail } from "../helpers/regex";
import { withRouter, WithRouterProps } from "../helpers/withRouter";
import { RootState, AppDispatch } from "../store";
import { CommonAction } from "../store/reducers/common";
import { NotifyType } from "../store/reducers/notification";
import { AuthAction } from "../store/reducers/userAuth";
import { ADD_NOTIFICATION, LOGIN, UPDATE_COMMON } from "../store/types";

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onLogin: (payload: AuthAction["payload"]) =>
    dispatch({ type: LOGIN, payload }),
  updateCommon: (payload: CommonAction["payload"]) =>
    dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: NotifyType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends Partial<PropsFromRedux & WithRouterProps> {}

export type LoginFormFields = "email" | "password";

// Type declaration for each State inside class
type State = {
  email: string;
  password: string;
  logging: boolean;
  emailErr: boolean;
  passwordErr: boolean;
  iconType: string;
  inputType: string;
  showMessageModal: boolean;
};

//Main Class
class Login extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    // Initializing State
    this.state = {
      email: "",
      password: "",
      logging: false,
      emailErr: false,
      passwordErr: false,
      iconType: "eye-open",
      inputType: "password",
      showMessageModal: false
    };
  }

  setLoading = (loading: boolean) => {
    this.setState({ logging: loading });
  };

  //Function to verify recaptcha and proceed to login when verified
  verifyRecaptchaAndLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    verifyRecaptchaAndProceed(
      "login",
      this.setLoading,
      this.login,
      this.props.addNotification
    );
  };

  componentDidMount() {
    const script = document.createElement("script");
    const url = "https://www.google.com/recaptcha/api.js?render=";
    script.src = url + process.env.REACT_APP_RECAPTCHA_KEY;
    script.async = true;

    document.body.appendChild(script);
    document.title = "Login - TaxPido PMS";

    const loggedIn = this.props.searchParams?.get("loggedIn");
    this.props.onLogin?.({ isAuthenticated: loggedIn === "true" });

    const userNotFound =
      this.props.searchParams?.get("userNotFound") === "true";

    if (userNotFound) {
      this.setState({ showMessageModal: true });
    }
  }

  // On Every chnage update state
  updateState =
    (field: LoginFormFields) => (ev: ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [field]: ev.target.value
      } as Pick<State, LoginFormFields>);

      //Testing if values of Entries are valid
      if (field === "email") {
        if (validEmail.test(ev.target.value)) {
          this.setState({ emailErr: false });
        }
      } else if (field === "password") {
        if (ev.target.value) {
          this.setState({ passwordErr: false });
        }
      }
    };

  // Testing if values entered are invaid onBlur
  onBlurEmail = (e: FocusEvent<HTMLInputElement>) => {
    if (!validEmail.test(e.target.value)) {
      this.setState({ emailErr: true });
    }
  };

  onBlurPassword = (e: FocusEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      this.setState({ passwordErr: true });
    }
  };

  onViewPassword = () => {
    if (this.state.inputType === "password") {
      this.setState({ inputType: "text", iconType: "eye-close" });
    } else {
      this.setState({ inputType: "password", iconType: "eye-open" });
    }
  };

  //Enable login button ony when all conditions satisfy
  isLogInEnabled = () => {
    return (
      this.state.email &&
      !this.state.emailErr &&
      this.state.password &&
      !this.state.passwordErr
    );
  };

  // On Login button click
  login = () => {
    if (!this.isLogInEnabled())
      return this.props.addNotification?.(
        "Incorrect/Missing Details",
        "Please enter valid details to proceed.",
        "danger"
      );

    this.setState({ logging: true });
    agent.Auth.login(this.state.email, this.state.password)
      .then((response: { loggedIn: boolean; user: { _id: string } }) => {
        const { loggedIn } = response;

        this.setState({ logging: false });
        this.props.onLogin?.({ isAuthenticated: loggedIn });

        this.props.navigate?.(routes.AgencyClients);
        localStorage.setItem("userId", response.user._id);
      })
      .catch(err => {
        this.setState({ logging: false });
        if (
          err.response.data.message ===
          "You email account is not verified. Please check for verification mail in your email."
        ) {
          this.props.navigate?.(
            `/verify/signup/otp/${err?.response?.data?._id}`
          );

          this.props.addNotification?.(
            "Unverified Email!",
            err?.response?.data?.message,
            "danger"
          );
        } else {
          this.props.addNotification?.(
            "Incorrect Details",
            err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            "danger"
          );
        }
      });
  };

  // Main Component render
  render() {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 px-6 lg:px-8">
        <MessageModal
          show={this.state.showMessageModal}
          data={{
            type: "warning",
            title: "User Not Found",
            message:
              "No user found with this email. Would you like to sign up instead?"
          }}
          confirmText="Sign Up"
          handleConfirm={() =>
            (window.location.href = `${config.baseURL}/auth/googleSignup/signup`)
          }
          closeText="Cancel"
          handleClose={() => {
            this.setState({ showMessageModal: false });
          }}
        />

        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="/images/Taxpido_logo_black.png"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Or&nbsp;
            <Link
              to="/signup"
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              create new account
            </Link>
          </p>
          {/* <p className="my-4 text-sm font-medium text-justify">
            Note - Please reset your password if you are logging in for the
            first time in this new PMS version.
          </p> */}
        </div>

        <form
          onSubmit={this.verifyRecaptchaAndLogin}
          className="mt-8 sm:mx-auto sm:w-full sm:max-w-md"
        >
          <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
            <div className="space-y-6">
              {/* Email */}
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    onBlur={this.onBlurEmail}
                    value={this.state.email}
                    onChange={this.updateState("email")}
                    autoComplete="email"
                    required
                    className={
                      this.state.emailErr
                        ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:border-red-500 sm:text-sm rounded-md"
                        : "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 sm:text-sm"
                    }
                  />
                  {
                    // On Error gives red warning
                    this.state.emailErr && (
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <ExclamationCircleIcon
                          className="h-5 w-5 text-red-500"
                          aria-hidden="true"
                        />
                      </div>
                    )
                  }
                </div>
                {this.state.emailErr ? (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    Invalid Email
                  </p>
                ) : null}
              </div>
              {/* Password */}
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="flex">
                    <input
                      id="password"
                      name="password"
                      type={this.state.inputType}
                      onBlur={this.onBlurPassword}
                      onChange={this.updateState("password")}
                      autoComplete="current-password"
                      required
                      className={
                        this.state.passwordErr
                          ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:border-red-500 sm:text-sm rounded-md"
                          : "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 sm:text-sm"
                      }
                    />
                    <button type="button" onClick={this.onViewPassword}>
                      <Icon
                        name={this.state.iconType}
                        className="h-6 w-6 text-gray-500 absolute top-2 right-3"
                      />
                    </button>
                  </div>
                  {this.state.passwordErr && (
                    <div className="absolute inset-y-0 right-6 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
                {this.state.passwordErr ? (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    Please enter a password
                  </p>
                ) : null}
              </div>
              {/* Forgot Password */}
              <div className="flex items-center justify-between">
                <div className="flex items-center"></div>

                <div className="text-sm">
                  <Link
                    to="/forgetPassword"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot your password?
                  </Link>
                </div>
              </div>
              {/* SignIn */}
              <div>
                <button
                  type="submit"
                  disabled={!this.isLogInEnabled() as boolean}
                  className={
                    this.isLogInEnabled()
                      ? "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                      : "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-300 cursor-not-allowed"
                  }
                >
                  {/* Loading animation */}
                  {this.state.logging ? (
                    <Icon name="loading" className="mr-2" />
                  ) : null}
                  Sign in
                </button>
              </div>
            </div>
            {/* Signin With Google */}
            {!isWebView() && (
              <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300"></div>
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">
                      Or continue with
                    </span>
                  </div>
                </div>

                <div className="mt-6 ">
                  <a href={config.baseURL + "/auth/googleSignup/login"}>
                    <button
                      type="button"
                      className="inline-flex w-full justify-center items-center py-2.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                    >
                      <span className="sr-only text-gray-500">
                        Sign in with Google
                      </span>
                      <Icon
                        name="solid/google"
                        className="w-5 h-5 text-gray-500"
                      />
                      <span className="text-gray-500 text-sm">
                        &nbsp; Sign in with Google
                      </span>
                    </button>
                  </a>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }
}

export default compose<ComponentType<Props>>(connector, withRouter)(Login);
