export const itrReturns = [
  "ITR - Unaudited",
  "ITR - Stat Audit",
  "ITR - Income Tax Audit"
];

export const advanceTaxReturns = [
  "Advance Tax",
  "Advance Tax - One Instalment"
];

export const returnFileList = [
  ...itrReturns,
  "TDS Return - Salary",
  "TDS Return - Non Salary",
  "TDS Return - Non Resident",
  "TCS Return",
  ...advanceTaxReturns,
  "SFT"
] as const;

export type ReturnFileList = typeof returnFileList;
export type ReturnsType = ReturnFileList[number];

export const clientTypesList = [
  "Individual",
  "Hindu Undivided Family",
  "Firm",
  "Private Limited Company",
  "Limited Liability Partnership",
  "One Person Company",
  "Trust",
  "Association Of Persons",
  "Artificial Juridical Person",
  "Body Of Individuals",
  "Government",
  "Local Authority",
  "Political Party",
  "Public Limited Company",
  "Foreign Company"
] as const;

export type ClientType = (typeof clientTypesList)[number];

export const designationList = [
  "Individual",
  "Proprietor",
  "Partner",
  "Accountant",
  "Director",
  "Managing Director",
  "Karta",
  "CFO"
];

export type TaskClientType =
  | "All"
  | ""
  | "GST"
  | "IT"
  | "other"
  | "Other"
  | "OTHER";

export const filterList = {
  initialList: (type: TaskClientType) => {
    switch (type) {
      case "GST":
        return ["GST", "Ownership Type", "Client Group", "Tags"];
      case "IT":
        return ["Income Tax", "Ownership Type", "Client Group", "Tags"];
      case "other":
      case "Other":
      case "OTHER":
        return ["Only Other Client", "Ownership Type", "Client Group", "Tags"];
      case "All":
      case "":
      default:
        return [
          "All",
          "GST",
          "Income Tax",
          "Only Other Client",
          "Ownership Type",
          "Client Group",
          "Tags"
        ];
    }
  },

  gstSlectionList: [
    "All GST",
    "GST Regular Clients",
    "GST Composition Clients",
    "GSTIN Status"
    // "GST Registration State",
  ],

  incomeTaxSelectionList: ["Returns You File in IT"],

  stateList: [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Jammu and Kashmir",
    "Ladakh",
    "Lakshadweep",
    "Puducherry"
  ]
};
