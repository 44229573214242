import { PeriodType } from "../pages/RecurringTask/Index";

type StartPeriod =
  | "January 2021"
  | "July 2017"
  | "Jul 17 To Sep 17"
  | "Apr 18 To Jun 18"
  | "Jan 21 To Mar 21"
  | "2017-18"
  | "";

type Result = {
  _id: string;
  name: string;
  value: string;
}[];

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

const quarterPeriod = [
  { first: "Jan", last: "Mar" },
  { first: "Apr", last: "Jun" },
  { first: "Jul", last: "Sep" },
  { first: "Oct", last: "Dec" }
];

export type Month = (typeof months)[number];

export type MonthPeriod = `${Month} ${number}`;
export type QuarterPeriod = `${Month} ${number} to ${Month} ${number}`;

export function getQuarterFromMonthOrQuarter(
  monthOrQuarter: Month | QuarterPeriod
) {
  const month = monthOrQuarter.split(" ")[0] as Month;
  const year = monthOrQuarter.split(" ")[1];
  const quarterIndex = Math.floor(months.indexOf(month) / 3);
  const startQuarter = quarterPeriod[quarterIndex];
  return `${startQuarter.first} ${year} to ${startQuarter.last} ${year}` as const;
}

export function getTaskPeriod(
  periodType: Exclude<PeriodType, "one time">,
  startPeriod: StartPeriod,
  endPeriod: string,
  taskType?: string
): Result {
  const currentYear = new Date().getFullYear() - 2000;
  //2000 is deducted to get the last 2 digit of the year

  function getYears(startYear: number, endYear: number) {
    let yearPeriod = [];
    for (let i = startYear; i <= endYear; i++) {
      yearPeriod.push(i);
    }
    return yearPeriod;
  }

  // for filtering the months for IFF and PMT-06
  function getMonthsPeriod(monthPeriod: string[], taskType?: string) {
    return taskType === "IFF" || taskType === "PMT-06"
      ? monthPeriod.filter(
          month =>
            month !== "Mar" &&
            month !== "Jun" &&
            month !== "Sep" &&
            month !== "Dec"
        )
      : monthPeriod;
  }

  // Monthly Period
  function getMothlyPeriod() {
    // Start Period
    const splitStartPeriod = startPeriod.split(" ");
    const startMonth = splitStartPeriod[0]
      ? splitStartPeriod[0]?.slice(0, 3)
      : "Jul";
    const startYear = splitStartPeriod[1]
      ? parseInt(splitStartPeriod[1], 10) - 2000
      : 17;

    // End Period
    const splitEndPeriod = endPeriod.split(" ");
    const endMonth = splitEndPeriod[0]
      ? splitEndPeriod[0]?.slice(0, 3)
      : months[new Date().getMonth()];
    const endYear = splitEndPeriod[1]
      ? parseInt(splitEndPeriod[1], 10) - 2000
      : currentYear;

    // Start and End Period Index
    const startMonthIndex = months.indexOf(startMonth);
    const endMonthIndex = months.indexOf(endMonth);

    if (startYear === endYear) {
      const monthPeriod = months.slice(startMonthIndex, endMonthIndex + 1);
      const reverseMonths = monthPeriod.reverse();
      //const slicedEndYear = endYear.toString().slice(2, 4);
      const slicedEndYear = endYear;
      return reverseMonths.map(month => {
        const slicedMonth = month.slice(0, 3);
        return {
          _id: `${month}-${slicedEndYear}`,
          name: `${slicedMonth} ${slicedEndYear}`,
          value: `${month} ${endYear}`
        };
      });
    } else {
      const startMonthPeriod = months.slice(startMonthIndex, 12);
      const endMonthPeriod = months.slice(0, endMonthIndex + 1);
      const yearPeriod = getYears(startYear, endYear);

      const result: Result = [];

      yearPeriod.forEach((year: number) => {
        //const slicedYear = year.toString().slice(2, 4);
        const slicedYear = year;
        if (year === startYear) {
          const filteredStartMonthPeriod = getMonthsPeriod(
            startMonthPeriod,

            taskType
          );
          filteredStartMonthPeriod.forEach(month => {
            const slicedMonth = month.slice(0, 3);

            result.push({
              _id: `${month}-${slicedYear}`,
              name: `${slicedMonth} ${slicedYear}`,
              value: `${month} ${year}`
            });
          });
        } else if (year === endYear) {
          const filteredEndMonthPeriod = getMonthsPeriod(
            endMonthPeriod,
            taskType
          );

          filteredEndMonthPeriod.forEach(month => {
            const slicedMonth = month.slice(0, 3);
            result.push({
              _id: `${month}-${slicedYear}`,
              name: `${slicedMonth} ${slicedYear}`,
              value: `${month} ${year}`
            });
          });
        } else {
          const filteredAllMonthPeriod = getMonthsPeriod(months, taskType);
          filteredAllMonthPeriod.forEach(month => {
            const slicedMonth = month.slice(0, 3);
            result.push({
              _id: `${month}-${slicedYear}`,
              name: `${slicedMonth} ${slicedYear}`,
              value: `${month} ${year}`
            });
          });
        }
      });
      return result.reverse();
    }
  }

  // Yearly Period
  function getYearlyPeriod() {
    // Start Period
    const splitStartPeriod = startPeriod.split("-");
    const startYear = splitStartPeriod[0]
      ? parseInt(splitStartPeriod[0], 10)
      : 2017;

    // End Period
    const splitEndPeriod = endPeriod.split("-");
    const endYear = splitEndPeriod[0]
      ? parseInt(splitEndPeriod[0], 10)
      : currentYear + 2001;

    const result: Result = [];
    const yearPeriod = getYears(startYear, endYear);
    yearPeriod.forEach((year: number, index: number) => {
      const slicedYear = year.toString().slice(2, 4);
      if (startYear === endYear) {
        result.push({
          _id: index.toString(),
          name: `${year - 1}-${slicedYear}`,
          value: `${year - 1}-${slicedYear}`
        });
      } else {
        if (year + 1 <= endYear) {
          result.push({
            _id: index.toString(),
            name: `${year}-${parseInt(slicedYear) + 1}`,
            value: `${year}-${parseInt(slicedYear) + 1}`
          });
        }
      }
    });

    return result.reverse();
  }

  // Quarterly Period
  function getQuarterlyPeriod() {
    const splitStartPeriod = startPeriod.split(" ");
    const startPeriodIndex = quarterPeriod.findIndex(
      item => item.first === splitStartPeriod[0]
    );
    const startQuarterPeriod =
      startPeriodIndex === -1
        ? quarterPeriod
        : quarterPeriod.slice(startPeriodIndex, 4);

    const splitEndPeriod = endPeriod.split(" ");
    const endPeriodIndex = quarterPeriod.findIndex(
      item => item.first === splitEndPeriod[0]
    );
    const currentMonthIndex = new Date().getMonth();
    const currentQuarterIndex = Math.floor(currentMonthIndex / 3);
    const currentQuarter = quarterPeriod.slice(0, currentQuarterIndex + 1);
    const endQuarterPeriod =
      endPeriodIndex === -1
        ? currentQuarter
        : quarterPeriod.slice(endPeriodIndex, 4);

    const startYear = splitStartPeriod[1]
      ? 2000 + parseInt(splitStartPeriod[1], 10)
      : 2017;
    const endYear = splitEndPeriod[1]
      ? 2000 + parseInt(splitEndPeriod[1], 10)
      : currentYear + 2000;
    if (startYear === endYear) {
      const reverseQuarterMonths = startQuarterPeriod.reverse();
      const stringYear = endYear.toString();
      const lastTwoDigit = stringYear.slice(stringYear.length - 2);
      return reverseQuarterMonths.map(quarter => ({
        _id: `${quarter.first}-${quarter.last}-${endYear}`,
        name: `${quarter.first} ${lastTwoDigit} to ${quarter.last} ${lastTwoDigit}`,
        value: `${quarter.first} ${lastTwoDigit} to ${quarter.last} ${lastTwoDigit}`
      }));
    } else {
      const yearPeriod = getYears(startYear, endYear);
      const result: {
        _id: string;
        name: string;
        value: string;
      }[] = [];
      yearPeriod.forEach((year: number) => {
        const stringYear = year.toString();
        const lastTwoDigit = stringYear.slice(stringYear.length - 2);
        if (year === startYear) {
          startQuarterPeriod.forEach(quarter => {
            result.push({
              _id: `${quarter.first}-${quarter.last}-${year}`,
              name: `${quarter.first} ${lastTwoDigit} to ${quarter.last} ${lastTwoDigit}`,
              value: `${quarter.first} ${lastTwoDigit} to ${quarter.last} ${lastTwoDigit}`
            });
          });
        } else if (year === endYear) {
          endQuarterPeriod.forEach(quarter => {
            result.push({
              _id: `${quarter.first}-${quarter.last}-${year}`,
              name: `${quarter.first} ${lastTwoDigit} to ${quarter.last} ${lastTwoDigit}`,
              value: `${quarter.first} ${lastTwoDigit} to ${quarter.last} ${lastTwoDigit}`
            });
          });
        } else {
          quarterPeriod.forEach(quarter => {
            result.push({
              _id: `${quarter.first}-${quarter.last}-${year}`,
              name: `${quarter.first} ${lastTwoDigit} to ${quarter.last} ${lastTwoDigit}`,
              value: `${quarter.first} ${lastTwoDigit} to ${quarter.last} ${lastTwoDigit}`
            });
          });
        }
      });
      return result.reverse();
    }
  }

  // Half Yearly Period
  function getHalfYearlyPeriod() {
    const result: Result = [];
    const halfYearlyPeriod = [
      { first: "Jan", last: "Jun" },
      { first: "Jul", last: "Dec" }
    ];

    const yearPeriod = getYears(2017, currentYear + 2000);
    yearPeriod.forEach((year: number) => {
      const stringYear = year.toString();
      const lastTwoDigit = stringYear.slice(stringYear.length - 2);
      halfYearlyPeriod.forEach(halfYearlt => {
        result.push({
          _id: `${halfYearlt.first}-${halfYearlt.last}-${year}`,
          name: `${halfYearlt.first} ${lastTwoDigit} to ${halfYearlt.last} ${lastTwoDigit}`,
          value: `${halfYearlt.first} ${lastTwoDigit} to ${halfYearlt.last} ${lastTwoDigit}`
        });
      });
    });

    return result.reverse();
  }
  switch (periodType?.toLowerCase()) {
    case "monthly":
      return getMothlyPeriod();

    case "quarterly":
      return getQuarterlyPeriod();

    case "half-yearly":
      return getHalfYearlyPeriod();

    case "yearly":
      return getYearlyPeriod();

    default:
      return [];
  }
}
