export function formatClientName(client?: {
  fileNo?: string;
  name: string;
  tradeName?: string;
}) {
  if (!client) return "";

  const { fileNo, name, tradeName } = client;

  return `${fileNo ? `${fileNo} - ` : ""}${name} ${
    tradeName ? `(${tradeName})` : ""
  }`;
}

export function getClientNameFromFormatedName(formattedName: string) {
  const containsFileName = formattedName.includes(" - ");
  const containsTradeName = formattedName.includes("(");

  if (containsFileName && containsTradeName) {
    const [fileNo, nameAndTradeName] = formattedName.split(" - ");
    const [name, tradeName] = nameAndTradeName.split(" (");

    return {
      fileNo,
      name,
      tradeName: tradeName.slice(0, -1)
    };
  } else if (containsFileName) {
    const [fileNo, name] = formattedName.split(" - ");

    return {
      fileNo,
      name
    };
  } else if (containsTradeName) {
    const [name, tradeName] = formattedName.split(" (");

    return {
      name,
      tradeName: tradeName.slice(0, -1)
    };
  } else {
    return {
      name: formattedName
    };
  }
}
