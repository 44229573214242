import { format } from "date-fns";
import { months } from "./timePeriod";

export const formatDate = (date: any, reversed: boolean) => {
  let formattedDate = new Date(date);
  if (formattedDate.toLocaleString() !== "Invalid Date") {
    return reversed
      ? format(formattedDate, "yyyy-MM-dd")
      : format(formattedDate, "dd-MM-yyyy");
  } else {
    return date;
  }
};

export const formatDateAndTime = (date: any, localIso?: boolean) => {
  let formattedDate = new Date(date);
  if (formattedDate.toLocaleString() !== "Invalid Date") {
    return localIso
      ? format(formattedDate, "yyyy-MM-dd'T'HH:mm")
      : format(formattedDate, "dd-MM-yyyy p");
  } else {
    return date;
  }
};

export const formatTime = (date: any) => {
  let formattedDate = new Date(date);
  if (formattedDate.toLocaleString() !== "Invalid Date") {
    return format(formattedDate, "dd MMM yyyy p");
  } else {
    return date;
  }
};

export const formatDateTimeString = (dateTime: string | Date) => {
  return new Date(dateTime).toLocaleDateString("en-In", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  });
};

export const formatDateAsLocalString = (dateToFormat: string | Date) => {
  const date = new Date(dateToFormat).getUTCDate();
  const month = new Date(dateToFormat).getUTCMonth() + 1;
  const year = new Date(dateToFormat).getUTCFullYear();
  return `${date}/${month}/${year}`;
};

/**
 * Function to format date in DD-MMM-YYYY format
 * @param date - date in MM-DD-YYYY or DD-MM-YYYY, or Date object
 * @returns date in DD-MMM-YYYY format
 * @example formatDate('12-31-2021') => '31 Dec 2021'
 * @example formatDate('31-12-2021') => '31 Dec 2021'
 */

export const formatDateAsDdMmmYyyy = (date: string | number | Date) => {
  if (!date) return "";

  if (
    typeof date === "string" &&
    (date.includes("/") || date.includes("-")) &&
    date.split(/[-/]/)[0].length !== 4 &&
    Number(date.split(/[-/]/)[0]) > 12
  ) {
    const [dateNumber, month, year] = date.split(/[-/]/);

    date = new Date(`${month}/${dateNumber}/${year}`);
  }

  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = months[dateObj.getMonth()];
  const year = dateObj.getFullYear();

  return `${day} ${month} ${year}` as const;
};
